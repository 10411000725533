@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.register-client-modal {
  width: 100%;
  padding: 0 2rem;
  box-sizing: border-box;

  & .register-client-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    border-bottom: 1px solid $blue-5;
    & .register-client-ereliable-logo {
      & img {
        max-width: 10rem;
        max-height: 2rem;
      }
    }
    & .register-client-close-btn {
      @include themify($themes) {
        color: themed('main');
      }
      font-size: 1.7rem;
    }
  }
  & .register-client-content {
    &.dz {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .dz-message-content {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        @include themify($themes) {
          background-color: themed('main');
        }
        color: $white;
        padding: 1rem 2rem;
        text-align: center;
        font-size: 1.4rem;
      }
      & .dz-client-list {
        display: flex;
        flex-direction: column;
        gap: 0.6rem;
        & .dz-client {
          @include themify($themes) {
            color: themed('main');
          }
          font-size: 1.2rem;
        }
      }
    }
    & .title {
      text-align: center;
      @include themify($themes) {
        color: themed('main');
      }
    }
    & .form-items {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 1rem;
      & .input-item {
        width: 100%;
        & .input-error-message {
          color: $red;
          font-size: 1.2rem;
          margin-top: 0.2rem;
        }
        & .input-label {
          @include themify($themes) {
            color: themed('main');
          }
        }
        & .select-dropdown {
          width: 100%;
          height: 4.4rem;
          border-radius: 0.5rem;
          font-size: 1.4rem;
          font-weight: 400;
          @include themify($themes) {
            color: themed('main');
          }
          border: none;
          &.dropdown-error {
            border: 0.1rem solid $red;
            border-radius: 0.5rem;
          }
          &.disabled {
            opacity: 30%;
            pointer-events: none;
            user-select: none;
          }

          [class$='-control'] {
            @include themify($themes) {
              color: themed('main');
            }
            border: none;
            background-color: $white;
            height: 4.2rem;
            box-shadow: none;

            &:hover {
              border: none;
            }
          }

          [class$='-placeholder'] {
            @include themify($themes) {
              color: themed('main');
            }
            font-size: 1.4rem;
            font-weight: 400;
          }

          [class$='-indicatorContainer'] {
            @include themify($themes) {
              color: themed('main');
            }
          }

          [class$='-singleValue'] {
            @include themify($themes) {
              color: themed('main');
            }
          }

          [class$='-indicatorSeparator'] {
            background: none;
            @include themify($themes) {
              color: themed('main');
            }
          }

          [class$='-indicator'] {
            @include themify($themes) {
              background: themed('main');
            }
            color: $white;
          }
        }
      }

      & .inputs-container {
        display: flex;
        gap: 1rem;
      }
    }
  }
  & .submitted-register-client {
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    & .submitted-message {
      @include themify($themes) {
        color: themed('main');
      }
      font-size: 2rem;
      text-align: center;
      max-width: 50rem;
    }
  }
}
