@import '../../assets/sass/variables';
@import 'src/assets/sass/themes';

.sidebar-container.retailer {
  min-height: calc(100vh - 12.6rem); // vh-header-footer-padding
}

.sidebar-container {
  display: flex;
  flex-flow: column nowrap;
  width: 31.5rem;
  min-width: 25rem;
  padding: 1rem;

  @include themify($themes) {
    background: themed('my-profile', 'bg');
  }

  @include themify($themes) {
    color: themed('tabActiveColor') !important;
  }

  @include themify($themes) {
    color: themed('main');
  }

  min-height: calc(100vh - 11.4rem); // vh-header-footer-padding

  @media screen and (max-width: 768px) {
    display: none;
  }

  @media screen and (max-width: 990px) and (min-width: 768px) {
    display: none;
  }

  >div {
    height: 100%;
    width: 31.5rem;
    min-width: 25rem;
    position: fixed;
  }

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.7rem;
    padding: 1.35rem 0.8rem 0.7rem;

    @include themify($themes) {
      color: themed('main');
    }
  }

  .sidebar-navbar {
    padding: 0.8rem;
    display: flex;
    flex-direction: column;
    gap: 1.168rem;

    .nav-link {
      display: inline-block;
      text-decoration: none;

      .wrapper {
        .iconWrapper {
          width: 4.082rem;
          height: 4.082rem;

          .icon {
            @include themify($themes) {
              fill: themed('disabled');
            }
          }
        }

        .text {
          font-size: 1.3rem;
          font-weight: 600;

          @include themify($themes) {
            color: themed('main');
          }

          padding-left: 1.318rem;
        }

        margin: 0;
      }

      &.active {
        .text {
          font-weight: 800;
          display: flex;
          flex-direction: row;
          flex: 1 1;

          &::after {
            content: '';
            flex: 1 0;

            @include themify($themes) {
              background-color: themed('main');
            }

            display: inline-block;
            height: 3px;
            align-self: center;
            margin: 3px -39px 0px 5px;
            position: relative;

            @media screen and (max-width: 990px) {
              display: none;
            }
          }
        }

        .iconWrapper {
          background-color: $white;
        }

        svg {
          @include themify($themes) {
            color: themed('main');
          }

          @include themify($themes) {
            fill: themed('main');
          }
        }
      }
    }
  }
}