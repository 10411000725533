@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.error-codes-layout {
  .error-codes-container {
    overflow: hidden;
    max-width: 93rem;
    margin: 0 auto;
  }

  .tdoc-content.container-fluid-medium {
    max-width: calc(100% - 33.5rem);
    width: 100%;
  }
}
