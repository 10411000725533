@import "src/assets/sass/variables";
@import "src/assets/sass/themes";

.part-list-item-oem {
  width: 35rem;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include themify($themes) {
    background: themed("secondary");
  }

  padding-top: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  max-height: 46rem;
  position: relative;

  &.best-price {
    border: 2px solid orange;
  }

  & .best-price-label {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-left: 0px solid transparent;
    border-right: 50px solid transparent;
    border-top: 50px solid orange;
    text-align: center;
    z-index: 10;

    & .best-price-text {
      position: absolute;
      top: -44px;
      left: 3px;
      rotate: -45deg;
      font-weight: bold;
      color: white;
      line-height: 10px;
      text-transform: uppercase;
    }
  }

  .part-list-item-favorite {
    align-self: flex-end;
    width: 100%;
    z-index: 2;
    position: relative;

    .favorite-button {
      position: absolute;
      right: 0;

      .iconButton {
        background: none;
      }

      svg {
        cursor: pointer;
      }

      .spinner-container {
        width: 2rem !important;
        height: 2rem !important;
        position: relative;

        .spinner {
          position: absolute;
          right: 1rem;
          top: 0rem;
          left: unset;
          background-color: inherit;
        }
      }

      button {
        svg {
          position: absolute;
          width: 2rem !important;
          height: 2rem !important;
          right: 1rem;
          top: 0rem;
          left: unset;

          @include themify($themes) {
            fill: themed("main");
          }
        }
      }
    }
  }

  .oem-header {
    display: flex;
    flex-direction: row;
    width: 90%;

    .img-zoom-container {
      position: relative;
      display: flex;
      gap: 1rem;
      z-index: 2;

      & .img-zoom {
        position: absolute !important;
        right: -20rem;
        background-color: #ffffff;
        border: 1px solid #dad8d8;
        // img {
        //   width: 100% !important;
        // }
      }
    }

    .img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 6.4rem;
      height: 6.4rem;
      border: 0.1rem solid $gray;

      img {
        max-width: 6.4rem;
        max-height: 6.4rem;
        margin: auto;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      margin-left: 1rem;

      @include themify($themes) {
        color: themed("main");
      }

      max-width: 180px;

      .title {
        text-align: left;
        margin-bottom: 0.3rem;
        font-size: 1.4rem;
        font-weight: 600;

        @include themify($themes) {
          color: themed("main");
        }

        text-decoration: none;

        &:hover {
          opacity: 80%;
          cursor: pointer;
        }
      }

      .reference {
        text-align: left;
        margin-bottom: 0.5rem;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.5;
      }
    }
  }

  .oem-body {
    margin-top: 0.8rem;
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;

    .description-short {
      text-align: left;
      margin-bottom: 0.5rem;
      font-size: 1.1rem;
      font-weight: 600;

      @include themify($themes) {
        color: themed("main");
      }

      .oe-replacements-btn {
        cursor: pointer;

        &:hover {
          opacity: 80%;
        }
      }
    }

    .description_attributes {
      text-align: left;
      margin-bottom: 0.5rem;
      font-size: 1.1rem;

      @include themify($themes) {
        color: themed("main");
      }

      line-height: 1.3;
      display: -webkit-box;
      -webkit-line-clamp: 15;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      border-bottom: 1px solid $gray;
      padding-bottom: 0.4rem;
    }

    .description {
      text-align: left;
      margin-bottom: 0.5rem;
      font-size: 1.1rem;

      @include themify($themes) {
        color: themed("main");
      }

      line-height: 1.3;
      display: -webkit-box;
      -webkit-line-clamp: 15;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      & .commercial-ref-value {
        color: $red;
        font-weight: bold;
      }
    }
  }

  .oem-footer {
    .cirrus-prices {
      display: flex;
      gap: 0.8rem;
      align-items: center;
      justify-content: center;

      .price-separator {
        border-left: 1px solid $gray;
        height: 2.5rem;
      }

      .cirrus-price {
        display: flex;
        gap: 0.2rem;
        color: $blue-1;
        align-items: center;

        .cirrus-price-label {
          font-size: 1rem;
          font-weight: 800;
        }

        .cirrus-price-value {
          font-size: 0.9rem;
          font-weight: 600;
        }
      }
    }

    .oem-footer-content {
      display: flex;
      flex-direction: row;
      border-top: 0.1rem solid $gray;
      align-items: center;
      margin-top: auto;
      width: 27.8rem;
      padding: 0.5rem 0;
      box-sizing: border-box;
      justify-content: space-between;

      .barcode {
        // width: 10.1rem;
        width: 9.5rem;
        object-fit: contain;
        display: block;
        margin: 1rem auto 0 0;
        float: left;

        & img {
          max-width: 10.1rem;
          height: 5.2rem;
        }

        & .barcode-number {
          text-align: center;
        }
      }

      .quantity {
        width: 11rem;
        flex-direction: column;
        padding: 1.1rem;
        box-sizing: border-box;
        display: flex;
        gap: 0.3rem;
      }

      .cart {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: flex-end;
        text-align: center;
        margin-bottom: 0.2rem;
        font-size: 1.8rem;
        font-weight: 600;

        .cart-price {
          margin-right: 0.5rem;
          font-size: 1.8rem;

          @include themify($themes) {
            color: themed("main");
          }
        }

        .cirrus-contact-us {
          white-space: nowrap;

          @include themify($themes) {
            color: themed("main");
          }

          font-size: 1.2rem;
          text-decoration: underline;
          cursor: pointer;
          min-width: 6.2rem;
          height: 1.6rem;
          margin-top: 0.2rem;
          font-size: 1rem;
          white-space: nowrap;

          & .extra-small {
            margin: auto;
          }

          &:hover {
            opacity: 80%;
          }
        }

        .oem-price_per_unit {
          @include themify($themes) {
            color: themed("main");
          }

          font-size: 1.2rem;
        }

        .cart-button {
          display: flex;
          align-items: center;
          justify-content: center;

          .button {
            padding: 0.3rem;
          }
        }
      }
    }
  }
}

/* Responsive CSS Styles */

.app-layout-mobile {}