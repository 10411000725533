@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.MainSearchInput.text-input-container {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  box-shadow: 0.2rem 0.2rem 1rem $white-9;
  border-radius: 1rem;
  overflow: hidden;

  .text-input {
    &--s {
      height: 1.4rem;
    }

    &--m {
      height: 3.8rem;
    }

    &--l {
      height: 6.4rem;
      border-radius: 1rem;
      padding: 0 1rem 0 1.6rem;
    }

    &::placeholder {
      color: rgba(39, 80, 159, 0.5);
      font-size: 1.4rem;
    }
  }

  & .input-search-icon {
    @include themify($themes) {
      color: themed('main');
    }

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 1rem;
    font-size: 2rem;
    border: 0;
    background-color: transparent;
  }

  & .cursor-pointer {
    cursor: pointer;
  }
}

/* Responsive CSS Styles */

.app-layout-desktop {}

.app-layout-tablet {}

.app-layout-tablet,
.app-layout-mobile {
  .text-input-container {
    & .text-input {
      @include themify($themes) {
        color: themed('main');
        background-color: themed('inputColor');

      }
    }
  }
}

.app-layout-mobile {
  .text-input-container {
    & .text-input {
      max-width: 100%;
    }
  }

  @media screen and (max-width: 360px) {
    .text-input-container {
      & .text-input {
        font-size: 1.4rem;
        line-height: 1.9rem;
      }
    }
  }
}