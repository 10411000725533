@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.universal-parts-navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $grey-1;
  padding: 1rem 0;
  width: 100%;

  .breadcrumbs {
    display: flex;
    align-items: center;
    gap: 2.3rem;

    @include themify($themes) {
      color: themed('main');
    }

    font-size: 2.5rem;
  }

  .options {
    .catalog-parts-filter {
      display: flex;
      align-items: center;
      gap: 20px;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      & .oil-search-input-container {
        position: relative;
      }

      .catalog-parts-desc {
        font-size: 11px;

        @include themify($themes) {
          color: themed('main');
        }
      }

      .catalog-parts-button {
        &.active {
          pointer-events: none;
        }

        padding: 5px;

        @include themify($themes) {
          background-color: themed('main');
        }

        color: $white;
        border: none;
        font-weight: 500;
        width: 180px;
        height: 40px;
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }
}

/* Responsive CSS Styles */

.app-layout-desktop {}

.app-layout-tablet {}

.app-layout-tablet,
.app-layout-mobile {
  .universal-parts-navbar-container {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    margin-bottom: 1rem;
    width: 100%;
    border-bottom: none;
    flex-direction: column;
    gap: 1.5rem;
    border-bottom: none;

    @include themify($themes) {
      background-color: themed('secondary');
    }

    .breadcrumbs {
      font-size: 2rem;
      text-align: center;
      padding: 2rem 2rem 0;
    }

    .options {
      position: relative;
      padding: 0 2rem;
      width: calc(100% - 4rem);
      display: flex;
      justify-content: center;

      &>div {
        position: relative;
        z-index: 1;
      }

      &::after {
        content: '';
        height: 2rem;
        width: 100%;
        position: absolute;
        display: block;
        bottom: 0;
        z-index: 0;
      }
    }
  }
}

.app-layout-mobile {}