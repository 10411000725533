.item-container {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;

  .item {
    height: auto;
    width: auto;
    max-height: 12rem;
    max-width: 10rem;
    cursor: pointer;

    img {
      max-height: 100%;
      max-width: 100%;
    }

    &.item-disabled {
      cursor: default;
    }
  }

  .enabled {
    cursor: auto;
  }

}