@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.side-news-item {
  display: flex;
  align-items: center;
  gap: 1.9rem;

  & img {
    max-width: 20.5rem;
    display: flex;
    height: 12rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
  }

  & .side-news-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    & .side-news-date {
      display: flex;
      align-items: center;
      justify-content: center;
      @include themify($themes) {
        color: themed('main');
      }
      font-weight: 400;
      width: 8rem;
      height: 2rem;
      font-size: 1.4rem;
    }

    & .side-news-title {
      @include themify($themes) {
        color: themed('main');
      }
      font-size: 2.5rem;
      text-decoration: none;
      font-weight: bold;

      &:hover {
        opacity: 75%;
      }
    }
  }
}

/* Responsive CSS Styles */

.app-layout-desktop {
  @media screen and (max-width: 1200px) {
    .news-video {
      .side-news-videos {
        gap: 1rem;

        .side-news-item {
          & img {
            width: 16.3rem;
            height: 10.9rem;
          }
        }
      }
    }
  }
}

.app-layout-tablet {
  .news-video {
    .side-news-videos {
      gap: 1rem;

      .side-news-item {
        & img {
          width: 16.3rem;
          height: 10.9rem;
        }
      }
    }
  }
}

.app-layout-mobile,
.app-layout-tablet {
  .side-news-item {
    gap: 1rem;

    & img {
      width: 15rem;
      height: auto;
      max-height: 10rem;
      margin: 0;
    }

    & .side-news-info {
      .side-news-title {
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: 700;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .side-news-item {
      & img {
        width: 13.2rem;
        height: 8.7rem;
      }
    }
  }
}

.app-layout-tablet {
  .side-news-item {
    & img {
      width: 16.3rem;
      height: 10.9rem;
    }
  }
}
