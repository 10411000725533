@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.textarea-container {
  width: 100%;
  position: relative;

  .textarea {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 0.5rem;
    border: none;
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: 400;

    @include themify($themes) {
      color: themed('main');
    }

    outline: none;
    transition: all 0.3s ease-in-out;
    background-color: $input-color;
    box-sizing: border-box;

    &.no-resize {
      resize: none;
    }

    &::placeholder {
      color: rgba(39, 80, 159, 0.5);
      font-size: 1.4rem;
    }
  }

  & .input-search-icon {
    @include themify($themes) {
      color: themed('main');
    }

    position: absolute;
    padding: 1rem;
    font-size: 2rem;
    top: 0;
    right: 0;
    cursor: pointer;
  }
}