@import '../../../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.modal-select-client {
  margin: 3rem 2rem;

  .red {
    color: #ca312a;
    font-size: 1.8rem;

  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  .modal-dialog {


    .select-dropdown {
      width: 15rem;
      height: 4.2rem;
      border-radius: 0.5rem;
      font-size: 1.4rem;
      font-weight: 400;

      @include themify($themes) {
        color: themed('main');
      }

      border: none;

      [class$='-control'] {
        @include themify($themes) {
          color: themed('main');
        }

        border: none;

        @include themify($themes) {
          background-color: themed('mainHover');
        }

        height: 4.2rem;
        box-shadow: none;

        &:hover {
          border: none;
        }
      }

      [class$='-placeholder'] {
        @include themify($themes) {
          color: themed('main');
        }

        font-size: 1.4rem;
        font-weight: 400;
      }

      [class$='-indicatorContainer'] {
        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-singleValue'] {
        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-indicatorSeparator'] {
        background: none;

        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-indicator'] {
        @include themify($themes) {
          background: themed('main');
        }

        color: $white;
      }
    }

    background-color: $white;

    .modal-header .close {
      margin-top: -2px;

      @include themify($themes) {
        color: themed('main');
      }
    }

    .close {
      float: right;
      font-size: 2.4rem;
      line-height: 1;

      @include themify($themes) {
        color: themed('main');
      }

      text-shadow: 0 1px 0 $blue;
    }

    button.close {
      -webkit-appearance: none;
      padding: 0;
      cursor: pointer;
      background: transparent;
      border: 0;
    }

    .close:hover,
    .close:focus {
      @include themify($themes) {
        color: themed('main');
      }

      text-decoration: none;
      cursor: pointer;
      filter: alpha(opacity=50);
      opacity: 0.5;
    }

    .modal-children {
      font-size: 1rem;
      line-height: 1.6;
      margin: 1rem;

      @include themify($themes) {
        color: themed('main');
      }

      padding: 1rem 0rem;
    }

    .modal-container {
      background-color: $white;

      .modal-heder {
        font-weight: 700;
        font-size: 2.2rem;
        line-height: 1.4rem;
        display: flex;
        align-items: center;
        text-align: center;

        @include themify($themes) {
          color: themed('main');
        }
      }

      .modal-body {
        padding: 1rem 0rem;
        margin: 0rem;
        min-height: 30rem;

        .tabs {
          .tab-list {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            list-style-type: none;
            padding: 1.6rem 0rem;
            height: 2.5rem;
            border-bottom: 0.2rem solid $blue-5;

            .tab {
              padding-bottom: 1.6rem;
              font-weight: 700;
              font-size: 1.8rem;
              height: 2.5rem;
              line-height: 25px;

              @include themify($themes) {
                color: themed('main');
              }

              &:hover {
                border-bottom: 0.4rem solid $blue;
                cursor: pointer;
              }
            }

            .react-tabs__tab--selected {
              border-bottom: 0.4rem solid $blue;
            }
          }

          .tab-body {
            padding-top: 0.5rem;

            .tab-row {
              display: flex;
              flex-direction: row;
              margin: 0.9rem;
              justify-content: space-between;

              .row-info {
                display: flex;
                width: 50%;
              }

              &.input-row {
                .tab-label {
                  width: 26rem;
                }
              }

              .tab-label {
                font-weight: 600;
                font-size: 1.8rem;
                line-height: 2.5rem;
                font-style: normal;
                display: flex;
                align-items: center;

                @include themify($themes) {
                  color: themed('main');
                }

                width: 13rem;

                @media screen and (max-width: 768px) {
                  width: 14rem;
                }

                input {
                  width: 25rem;
                  margin-right: 0.5rem;
                }
              }



              .tab-span {
                font-style: normal;
                font-weight: 400;
                font-size: 1.8rem;
                line-height: 2.5rem;
                display: flex;
                align-items: center;

                @include themify($themes) {
                  color: themed('main');
                }

                @media screen and (max-width: 768px) {
                  flex-direction: column;

                  input {
                    margin-top: 1rem;
                    background-color: $input-color;
                  }
                }

                input {
                  width: 25rem;
                  margin-right: 0.5rem;
                }

                .btn-test {
                  display: flex;
                  align-items: center;
                  flex-direction: row;
                  justify-content: center;
                  margin: 0rem 1rem;
                  width: 15.6rem;
                  height: 4.2rem;
                  color: $white;

                  /* blue */
                  @include themify($themes) {
                    color: themed('main');
                  }

                  border-radius: 0.5rem;
                  border: none;

                  &:hover {
                    opacity: 0.8;
                    cursor: pointer;
                  }

                  .container-spiner {
                    margin: 0rem 0.3rem 0rem 0rem;
                  }

                  circle {
                    stroke: $white;
                    stroke-width: 7;
                  }
                }
              }
            }
          }
        }
      }

      .modal-footer {
        padding: 1.5rem;
        text-align: right;
        border-top: 0.2rem solid $blue-2;

        @media screen and (max-width: 768px) {
          text-align: center;
        }

        .btn-submit {
          color: $white;

          @include themify($themes) {
            background-color: themed('main');
          }

          border: none;
          height: 4.2rem;
          width: 17.6rem;
          border-radius: 0.5rem;
          font-size: 1.4rem;
          font-weight: 600;
          margin: 0.5rem;
          cursor: pointer;
        }

        .btn-close {
          @include themify($themes) {
            color: themed('main');
          }

          background-color: $white;
          border: 1px solid $blue;

          height: 4.2rem;
          width: 17.6rem;
          border-radius: 0.5rem;
          font-size: 1.4rem;
          font-weight: 700;
          margin: 0.5rem;

          font-style: normal;
        }

        .btn-submit:hover,
        .btn-close:hover {
          background-color: $light-blue;
          color: $white;
        }
      }
    }
  }



  @media screen and (max-width: 990px) {
    .modal-dialog .modal-container .modal-body .tabs .tab-body .test-tab .tab-row .text-input {
      background-color: $input-color;
    }

    .modal-dialog .modal-container .modal-body .tabs .tab-body .tab-row .tab-span input {
      background-color: $input-color;
    }
  }

  @media screen and (max-width: 768px) {
    .modal-dialog .modal-container .modal-body .tabs .tab-body .test-tab .tab-row {
      flex-direction: column;

      &.input-row {
        gap: 1rem;
      }

      .row-info {
        width: 100%;
        margin: 0.9px;
      }

      .tab-label input {
        width: inherit;
      }

      .text-input {
        background-color: $input-color;
      }

      .tab-label {
        width: 100%;
      }


      .tab-span {
        flex-direction: column;
        width: 100%;
        gap: 1rem;
        align-items: end;

        &.btn-test-container {
          align-items: center;
        }

        .select-dropdown {
          width: 100%;
          font-size: 1.2rem;
        }

        .btn-test {
          height: 4.2rem;
          width: 17.6rem;
          font-size: 1.4rem;
          font-weight: 600;
        }
      }
    }
  }

  @media screen and (max-width: 440px) {
    .modal-dialog .modal-container .modal-body .tabs .tab-body .tab-row .tab-label {
      width: 12rem;
    }

    .modal-dialog .modal-container .modal-body .tabs .tab-body .tab-row .tab-span {
      font-size: 1.3rem;
      white-space: nowrap;
    }

    .modal-dialog .modal-container .modal-body .tabs .tab-body .tab-row .tab-span input {
      width: 100%;
    }
  }
}