@import 'assets/sass/variables';
@import 'src/assets/sass/themes';
@import 'assets/sass/_mixins.scss';

.wrapper-skeleton {
  max-width: 1370px;
  width: 100%;
  margin: 0 auto;

  .skeleton-line {
    height: 25px;
    border-radius: 5px;
    -webkit-animation: pulse 1s infinite ease-in-out;
    animation: pulse 1s infinite ease-in-out;

    @-webkit-keyframes pulse {
      0% {
        background-color: rgba($gray-10, 0.1);
      }

      50% {
        background-color: rgba($gray-10, 0.4);
      }

      100% {
        background-color: rgba($gray-10, 0.1);
      }
    }

    @keyframes pulse {
      0% {
        background-color: rgba($gray-10, 0.1);
      }

      50% {
        background-color: rgba($gray-10, 0.4);
      }

      100% {
        background-color: rgba($gray-10, 0.1);
      }
    }
  }
}
