@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.ReactModal__Overlay {
  .ReactModal__Content {
    &.error-codes-list-wrapper {
      padding: 2rem;
      height: calc(100% - 4rem);
      width: calc(100% - 4rem);

      .app-layout-tablet,
      .app-layout-mobile {
        .error-codes-list-modal-container {
          padding: 0;

          & .list-modal-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 2rem;
            margin-bottom: 2rem;
            @include themify($themes) {
              border-bottom: 0.1rem solid themed('separatorLine');
            }

            & .title {
              @include themify($themes) {
                color: themed('main');
              }
              font-size: 1.6rem;
            }

            & .header-actions {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 2rem;
              @include themify($themes) {
                color: themed('main');
              }
              font-size: 1.4rem;
              position: relative;

              & .close-modal {
                cursor: pointer;

                &:hover {
                  opacity: 80%;
                }
              }
            }
          }

          & .list-modal-content {
            padding: 0;
            box-sizing: border-box;
            overflow: auto;
            max-height: calc(100vh - 10.5rem);

            .items {
              display: flex;
              flex-direction: column;
              gap: 1.5rem;

              .item {
                font-size: 1.4rem;
                font-weight: bold;
                cursor: pointer;
                padding: 0;
                @include themify($themes) {
                  color: themed('main');
                }

                &.active {
                  color: $white;
                  @include themify($themes) {
                    background-color: themed('inputColor');
                  }
                  padding: 0.5rem 1rem;
                  border-radius: 1rem;
                  box-sizing: border-box;
                  width: fit-content;
                  pointer-events: none;
                }

                &.no-pointer-events {
                  pointer-events: all;
                }

                &:hover {
                  opacity: 80%;
                }
              }
            }
          }
        }
      }
    }
  }
}

.app-layout-tablet,
.app-layout-mobile {
  .error-codes-list {
    .list-modal-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 5rem;
      border-radius: 0.5rem;
      cursor: pointer;
      padding: 0 2rem;
      transition: background 0.2s ease-in-out;
      gap: 5rem;
      @include themify($themes) {
        background: themed('inputColor');
        color: themed('main');
      }
      width: calc(100% - 4rem);
      margin: 0 auto 2rem;

      .modules-modal-btn-text {
        font-size: 1.5rem;
        @include themify($themes) {
          color: themed('main');
        }
      }
    }
  }
}
