@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.default-button {
  width: 100%;
  height: 4.2rem;
  border-radius: 0.5rem;
  border: none;
  padding: 0 1rem;
  font-size: 1.6rem;
  font-weight: 400;
  color: white;
  outline: none;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  z-index: 0;

  @include themify($themes) {
    background-color: themed('main');
  }

  .default-btn-spinner {
    fill: white;

    svg {
      circle {
        stroke: white;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    font-size: 1.4rem;
    font-weight: 700;
  }

  &:hover {
    @include themify($themes) {
      color: themed('tabInactiveColor');
      background-color: themed('btnHover');
    }

    .default-btn-spinner {
      svg {
        circle {
          @include themify($themes) {
            stroke: themed('main');
          }
        }
      }
    }
  }

  &.red {
    background-color: $red;

    .default-btn-spinner {
      color: white;

      svg {
        circle {
          stroke: white;
        }
      }
    }

    &:hover {
      background-color: rgb(234, 173, 173);
      color: $red;

      .default-btn-spinner {
        svg {
          circle {
            stroke: $red;
          }
        }
      }
    }
  }

  &.blue {
    .default-btn-spinner {
      color: white;

      svg {
        circle {
          @include themify($themes) {
            fill: themed('main');
          }
        }
      }
    }

    &:hover {
      background-color: rgb(234, 173, 173);
      color: white;

      .default-btn-spinner {
        svg {
          circle {
            stroke: white;
          }
        }
      }
    }
  }

  .default-btn-spinner {
    color: white;

    svg {
      circle {
        stroke: white;
      }
    }
  }

  &:disabled {
    opacity: 0.5;

    @include themify($themes) {
      color: themed('tabInactiveColor');
    }

    cursor: not-allowed;
  }
}