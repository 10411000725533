@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.consumable-filter {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 0;
  width: 100%;

  @include themify($themes) {
    background-color: themed('mainHover');
  }

  gap: 1rem;
  padding-bottom: 5.2rem;
  z-index: 130;
  bottom: -100%;
  transition: bottom 0.3s;

  @media screen and (max-width: 768px) {
    height: calc(100% - 195px);
  }

  &.active {
    bottom: 0;
  }

  & .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 1.5rem 0 1.5rem;

    & .buttons-container {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 0.5rem;
    }

    & .icon {
      font-size: 2rem;
      font-weight: bold;

      @include themify($themes) {
        color: themed('main');
      }

      cursor: pointer;
    }
  }

  & .title {
    display: flex;
    justify-content: center;
    font-size: 2.3rem;
    font-weight: 300;
    gap: 0.5rem;
    margin-bottom: 3.7rem;

    @include themify($themes) {
      color: themed('main');
    }
  }

  & .filters {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1.5rem;

    & .categories-filter {
      display: flex;
      display: flex;
      flex-wrap: wrap;
      gap: 1.5rem;
      margin: 2rem;
      justify-content: center;

      @media screen and (max-width: 768px) {
        width: 100%;
        margin: 0rem;
      }

      & .tyre-filter-select-dropdown {
        width: 100%;
        border-radius: 0.5rem;
        font-size: 1.4rem;
        font-weight: 400;

        @include themify($themes) {
          color: themed('main');
        }

        border: none;

        &.disabled {
          opacity: 30%;
          pointer-events: none;
          user-select: none;
        }

        [class$='-control'] {
          @include themify($themes) {
            color: themed('main');
          }

          border: none;

          @include themify($themes) {
            background-color: themed('bgActive');
          }

          box-shadow: none;
          width: 40rem;

          @media screen and (max-width: 768px) {
            width: 100%;
          }

          &:hover {
            border: none;
          }
        }

        [class$='-placeholder'] {
          @include themify($themes) {
            color: themed('main');
          }

          font-size: 1.4rem;
          font-weight: 400;
        }

        [class$='-indicatorContainer'] {
          @include themify($themes) {
            color: themed('main');
          }
        }

        [class$='-singleValue'] {
          @include themify($themes) {
            color: themed('main');
          }
        }

        [class$='-indicatorSeparator'] {
          background: none;

          @include themify($themes) {
            color: themed('main');
          }
        }

        [class$='-indicator'] {
          @include themify($themes) {
            background: themed('main');
          }

          color: $white;
        }
      }

      & .dropdown-item {
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 768px) {
          width: 90%;
        }

        & .text-input-container {
          width: 40rem;

          @media screen and (max-width: 768px) {
            width: 100%;
          }

          & .text-input {
            @include themify($themes) {
              background-color: themed('bgActive');
            }
          }
        }

        & .dropdown-label {
          margin-bottom: 0.5rem;
          font-weight: 800;
          font-size: 1.2rem;

          @media screen and (max-width: 768px) {
            width: 90%;
          }
        }
      }
    }
  }

  & .apply-btn {
    align-self: center;
    width: 24.3rem;
    margin-top: 4rem;
  }
}