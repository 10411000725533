@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.toast-notification {
  display: flex;
  height: auto;

  .icon {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.8rem;
  }

  .title {
    display: block;
    width: 100%;
  }

  .message {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    white-space: normal;
    max-width: 100%;
    word-break: break-word;
  }

  .toast-icon {
    display: flex;
    flex-flow: row nowrap;
  }

  .toast-text {
    display: flex;
    flex-flow: row wrap;
    align-content: center;
  }
}