@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.search-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  @include themify($themes) {
    background-color: themed('secondary');
  }
  @media screen and (min-width: 1366px) {
    border-radius: 0;
    box-shadow: none;
  }
  @media screen and (min-width: 991px) and (max-width: 1366px) {
    box-shadow: 0.2rem 0.2rem 1rem #0000000a;
  }
  padding: 1.8rem;

  & .card-title {
    text-decoration: underline;
    margin-top: 2.1rem;
    font-size: 1.7rem;
    @include themify($themes) {
      color: themed('main');
    }
    font-weight: 600;
    margin-bottom: 3.5rem;
    letter-spacing: 0.3px;
  }

  & .vin-plate-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;

    & .vin {
      width: 100%;

      & .reference-label {
        font-weight: 800;
        font-size: 1.2rem;
        @include themify($themes) {
          color: themed('main');
        }
        padding: 0rem 1rem;
      }
    }

    & .or-separator {
      @include themify($themes) {
        color: themed('main');
      }
      font-size: 1.4rem;
    }

    & .plate-number {
      width: 100%;

      & .reference-label {
        font-weight: 800;
        font-size: 1.4rem;
        @include themify($themes) {
          color: themed('main');
        }
        padding: 0rem 1rem;
      }
    }
  }
}
