@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.espace-automotor-price {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 4rem 0rem;
  max-width: 132rem;
  margin: 0rem auto;
  width: 100%;

  & .price-list-container {
    width: 100%;

    .filter {
      height: 13rem;
      border-radius: 1rem;
      border: 1px solid #cfd9ee;
      margin-bottom: 2rem;
      margin-top: 2.5rem;

      .spinner.extra-small svg {
        width: 1.3rem;
      }

      @media screen and (max-width: 768px) {
        height: auto;
        border: none;
        margin-top: 5.5rem;
      }

      & .filter-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2.1rem 2.4rem;

        @media screen and (max-width: 768px) {
          flex-direction: column;
        }

        & .filter-title {
          font-size: 1.4rem;

          @include themify($themes) {
            color: themed('main');
          }

          font-weight: bold;
        }

        & .filter-reliquats {
          display: flex;
          align-items: center;
          font-size: 1.2rem;

          @include themify($themes) {
            color: themed('main');
          }
        }

        & .filter-link-btn {
          @media screen and (max-width: 450px) {
            width: 100%;
          }

          .add-btn {
            @include themify($themes) {
              background-color: themed('secondary-3');
            }

            @media screen and (max-width: 768px) {
              width: 100%;
              margin-top: 1rem;
              padding: 1.2rem 2rem;
            }

            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            border: 1px solid #7285b8;
            border-radius: 5rem;
            width: 100%;
            padding: 0.9rem 2rem;

            @include themify($themes) {
              color: themed('main');
            }

            font-size: 1.4rem;
            cursor: pointer;

            .icon {
              @include themify($themes) {
                color: themed('main');
              }

              font-size: 1.6rem;
            }
          }
        }
      }

      & .filter-body {
        display: flex;
        flex-direction: row;
        gap: 1.4rem;
        padding: 0 2.4rem;

        @media screen and (max-width: 768px) {
          flex-direction: column;
        }

        & .filter-input-item {
          width: 100%;
          position: relative;

          & .react-picker-dropdown {
            cursor: pointer;

            &.disable {
              pointer-events: none;
            }
          }

          & .date-picker {
            position: absolute;
            z-index: 101;
            top: 5rem;

            @media screen and (max-width: 768px) {
              width: 100%;
            }

            & .react-datepicker {
              display: flex;
              position: relative;
              border: none;
              box-shadow: 0.5rem 0.8rem 2.1rem rgb(0 0 0 / 15%);
              font-size: 1.3rem;

              &__month-container {
                & .react-datepicker__header {
                  background: none;
                  border-bottom: none;
                  padding: 1.2rem 1rem;

                  & .react-datepicker__current-month {
                    font-size: 1.4rem;
                  }

                  & .react-datepicker__day-names {
                    & .react-datepicker__day-name {
                      width: 3rem;
                      line-height: 4rem;
                    }
                  }
                }

                & .react-datepicker__week {
                  .react-datepicker__day {
                    width: 3rem;
                    line-height: 3rem;

                    &--in-range {
                      background-color: #d73c5a;
                    }

                    &--selected {
                      background-color: #d73c5a;
                    }

                    &--in-selecting-range {
                      background-color: #f5bec7;
                    }

                    &--keyboard-selected {
                      background-color: #d73c5a;
                    }
                  }
                }
              }

              .react-datepicker__month-container {
                @media screen and (max-width: 768px) {
                  width: 100%;
                }
              }
            }
          }

          & .select-dropdown {
            width: 100%;
            height: 4.2rem;
            border-radius: 0.5rem;
            font-size: 1.4rem;
            font-weight: 400;

            @include themify($themes) {
              color: themed('main');
            }

            border: none;

            &.disabled {
              opacity: 30%;
              pointer-events: none;
              user-select: none;

            }

            [class$='-control'] {
              @include themify($themes) {
                color: themed('main');
              }

              border: none;

              @include themify($themes) {
                background-color: themed('mainHover');
              }

              height: 4.2rem;
              box-shadow: none;

              &:hover {
                border: none;
              }
            }

            [class$='-placeholder'] {
              @include themify($themes) {
                color: themed('main');
              }

              font-size: 1.4rem;
              font-weight: 400;
            }

            [class$='-indicatorContainer'] {
              @include themify($themes) {
                color: themed('main');
              }
            }

            [class$='-singleValue'] {
              @include themify($themes) {
                color: themed('main');
              }
            }

            [class$='-indicatorSeparator'] {
              background: none;

              @include themify($themes) {
                color: themed('main');
              }
            }

            [class$='-indicator'] {
              @include themify($themes) {
                background: themed('main');
              }

              color: $white;
            }
          }
        }
      }
    }

    @media screen and (max-width: 990px) {
      width: 100%;
    }

    & .price-list-header {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 4rem;

      & .price-list-title {
        @include themify($themes) {
          color: themed('main');
        }

        font-size: 2rem;
        font-weight: bold;
      }

      & .add-new-price-btn {
        width: 18rem;
      }
    }

    & .price-items {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      position: relative;

      & .table-wrapper-tariff-default table .row:first-child .heading:last-child .table-title {
        height: 3.4rem !important;
      }

      .faFileDownload {
        font-size: 18px;
        cursor: pointer;


      }




      .disabled {
        pointer-events: none;
        color: $grey-13;
        visibility: hidden;
      }

      .status {

        color: $white;
        padding: 0.2rem 0.4rem;
        border-radius: 0.5rem;
        width: 100%;
        text-align: center;

      }

      .action {
        display: flex;
        justify-content: space-around;
        position: relative;
        gap: 0.4rem;

        .tooltip_lable {
          display: none;
          position: absolute;
          padding: 1rem 2rem;
          background-color: $black;
          color: $white;
          top: -4rem;
          left: -2.5rem;
          border-radius: 5px;
          z-index: 99999999999;
          width: max-content;

        }
      }

      .failed {
        background-color: #dc3545;
      }

      .pending {
        background-color: #ffc107;
      }

      .inprogress {
        background-color: #17a2b8;
      }

      .finished {
        background-color: #28a745;
      }

      & .prices-spinner {
        top: 10rem;
      }

      & .no-data {
        font-size: 2.4rem;
        font-weight: bold;

        @include themify($themes) {
          color: themed('main');
        }

        opacity: 60%;
        margin-top: 5rem;
        white-space: nowrap;
      }
    }
  }
}