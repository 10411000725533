@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.catalog-supplier-layout-container {
  max-width: 125.8rem;
  margin: 0 auto;
  padding: 0 3.4rem;

  & .add-tariff-btn {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 1rem;

    & div {
      width: 15rem;
    }
  }

  & .tariff-page-container {
    padding: 3rem 0;
    display: flex;
    justify-content: center;
    position: relative;
    & .tariff-item {
      & .no-data {
        @include themify($themes) {
          color: themed('main');
        }
        font-size: 2.5rem;
      }
    }
  }
}
