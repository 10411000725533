@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.wrapper {
  width: 100%;

  .comment {
    width: 16px;
    fill: $blue-icon-color;
  }
}
