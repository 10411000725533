@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.main-news-video {
  @media screen and (max-width: 768px) {
    width: 100%;

  }

  .main-news-item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 1rem;
      box-sizing: border-box;
    }

    & img {
      max-width: 61.5rem;
      max-height: 35.1rem;
      border-radius: 1rem;
      margin-bottom: 1rem;
    }

    & .news-date {
      display: flex;
      align-items: center;
      justify-content: center;

      @include themify($themes) {
        color: themed('main');
      }

      font-weight: 400;
      width: 8rem;
      height: 2rem;
      font-size: 1.4rem;
      margin-bottom: 0.3rem;
    }

    & .news-title {
      @include themify($themes) {
        color: themed('main');
      }

      font-size: 2.3rem;
      margin-bottom: 0.9rem;
      text-decoration: none;

      &:hover {
        opacity: 75%;
      }
    }

    & .news-description {
      font-size: 1.4rem;
      word-break: break-word;

      @include themify($themes) {
        color: themed('main');
      }
    }
  }
}


/* Responsive CSS Styles */

.app-layout-desktop {
  @media screen and (max-width: 1200px) {
    .news-video {
      .main-news-video {

        .main-news-item img {
          width: 41.5rem;
          height: 27.6rem;
        }
      }
    }
  }
}

.app-layout-tablet,
.app-layout-mobile {
  .main-news {
    margin-bottom: 2rem;

    .main-news-item {
      & img {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2rem;
      }
    }
  }
}

.app-layout-tablet {
  .news-video {
    .main-news-video {
      .main-news-item img {
        width: 41.5rem;
        height: 27.6rem;
      }
    }
  }
}

.app-layout-mobile {
  .main-news-item {
    & img {
      width: 100%;
      height: auto;
      max-width: 100%;
      max-height: 48rem;
      margin: 0 auto 1.5rem;
    }

    & .news-title {
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
  }

  @media screen and (max-width: 480px) {
    .main-news-item {
      & img {
        max-height: 29rem;
      }
    }
  }
}