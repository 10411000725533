@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.swiper-container {
  .promotion-container {
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 12.9rem;
    padding: 0 2rem;
    width: 100%;
    @include themify($themes) {
      color: themed('main');
      background-color: themed('inputColor');
    }

    & .promotion-title {
      font-size: 2.5rem;
      opacity: 30%;
      font-weight: 800;
      margin-top: 0.4rem;
    }

    & .promotion-info {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      margin-bottom: 1.6rem;

      & .information {
        display: flex;
        flex-direction: column;

        & .discount {
          font-weight: 800;
          font-size: 4rem;
        }

        & .availability {
          font-weight: 800;
          font-size: 1.6rem;
        }
      }

      .promotion-submit {
        height: 3.36rem;
        width: 14.187rem;

        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }

      .default-button {
        border-radius: 8px;
        @include themify($themes) {
          background-color: themed('main');
        }
        color: $white;
        font-size: 1.12rem;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 1.5rem;
        text-align: center;
      }
    }

    & .promotion-info-catalog {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      margin-bottom: 1.6rem;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 1rem;
      }

      & .information {
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 768px) {
          align-items: center;
          width: 100%;
        }

        & .discount {
          font-weight: 800;
          font-size: 4rem;

          @media screen and (max-width: 768px) {
            font-size: 2.5rem;
            margin-top: -1rem;
          }
        }

        & .availability {
          font-weight: 800;
          font-size: 1.6rem;
          width: 100%;

          @media screen and (max-width: 768px) {
            font-size: 1rem;
            white-space: nowrap;
            text-align: center;
          }
        }
      }

      .promotion-submit {
        height: 3.36rem;
        width: 14.187rem;

        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }

      .default-button {
        border-radius: 8px;
        @include themify($themes) {
          background-color: themed('main');
        }
        color: $white;
        font-size: 1.12rem;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 1.5rem;
        text-align: center;
      }
    }

    & .promotion-discount {
      display: flex;
      align-items: center;
      flex-direction: column;

      & .title {
        color: $red;
        font-size: 1.4rem;
        font-weight: bold;
      }

      & .discount {
        font-size: 2.8rem;
        @include themify($themes) {
          color: themed('main');
        }
      }
    }
  }
}
