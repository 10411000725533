@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.container-wrapper-v2.ads-module-intersitial-ad {
  height: calc(100% - 5rem);
}


.intersitial-ad-wrapper {
  padding: 2.5rem 0;
  max-width: 100%;
  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;
  height: 100%;

  .button-continue {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-shrink: 0;
    font-size: 1.5rem;
    margin: 0 auto;
    width: 15.6rem;
    height: 4rem;
    color: $white;

    @include themify($themes) {
      background-color: themed('main');
    }

    border-radius: 0.5rem;
    border: none;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .swiper-slide {
    width: 100% !important;
    max-width: 100% !important;

    img {
      object-fit: cover;
      display: block;
      width: 100%;
      height: 100%;
    }

    .ad-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.app-layout-mobile .swiper-wrapper.with-pagination,
.app-layout-tablet .swiper-wrapper.with-pagination {
  padding-bottom: 0;
}