@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.switch-hide {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding: 0rem;
    gap: 4rem;
    cursor: pointer;

    .switch {
        position: relative;
        display: inline-block;
        width: 3.6rem;
        height: 2rem;
        margin-left: 3rem;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .switch_slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(128, 128, 128, 0.475);
        -webkit-transition: 0.4s;
        transition: 0.4s;

    }



    .switch_slider:before {
        position: absolute;
        content: '';
        height: 1.6rem;
        width: 1.6rem;
        left: 0.1rem;
        bottom: 0.1rem;
        background-color: $white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    input:checked+.switch_slider {
        @include themify($themes) {
            background-color: themed('main');
        }
    }



    input:focus+.switch_slider {
        box-shadow: 0 0 1px #222d4a;
    }

    input:checked+.switch_slider:before {
        -webkit-transform: translateX(16px);
        -ms-transform: translateX(16px);
        transform: translateX(16px);
    }

    /* Rounded sliders */
    .switch_slider.round {
        border-radius: 3.4rem;
        border: transparent 1px solid;
    }

    :hover+.switch_slider.round {

        border: #fff 1px solid;
    }

    .switch_slider.round:before {
        border-radius: 50%;
    }



    .icon {
        padding: 0.1rem;
    }

    .text {
        min-width: 10rem;
        font-size: 1.2rem;
    }

    &:hover {
        @include themify($themes) {
            @media screen and (min-width: 990px) {
                color: #ebf0fb;
            }
        }
    }
}