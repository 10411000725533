@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.table-section-details {
  display: flex;
  flex-direction: column;

  & .section-info {
    display: flex;
    align-items: center;

    & .return-btn {
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 1.8rem;
      @include themify($themes) {
        color: themed('main');
      }
      cursor: pointer;
    }

    & .title {
      display: flex;
      justify-content: center;
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.7rem;
      @include themify($themes) {
        color: themed('main');
      }
      margin-top: 0rem;
      text-decoration: none;
      flex-grow: 1;

      & .icon-confirmed {
        margin-right: 7px;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        background-color: #4dc00c;
        display: flex;
        justify-content: center;
        align-items: center;

        & svg {
          font-size: 20px;
          color: white;
        }

        &.not-confirmed {
          background-color: $red1;
        }

        &.not-found {
          @include themify($themes) {
            background-color: themed('main');
          }
        }

        &.not-valid {
          background-color:  $red !important;
        }
      }
    }
  }

  // & .table-section {
  //     display: flex;
  //     flex-direction: column;
  //     gap: 2rem;

  & .automotor-order-table-container {
    margin-top: 2rem;
    display: flex;
    gap: 2.8rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .order-default-table-container {
      table {
        thead tr:nth-child(2) {
          th.heading {
            text-align: left;
            padding: 0 0.4rem 0 1.4rem;
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      gap: 0rem;
      margin: 2rem 1rem;
    }

    & .automotor-order-radiobox-container {
      display: flex;
      flex-direction: column;
    }

    .button-submit {
      display: flex;
      padding-top: 2rem;

      justify-content: center;
      align-items: center;

      .default-button {
        width: 30rem;
      }
    }
  }

  // }

  & .section-not-confirmed-cell-action {
    display: flex;

    & button {
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 1.6rem;
      height: 2rem;
      color: white;
      width: 25px;
      background-color: $red-3;
      margin-left: auto;
    }
  }
}
