@import '../../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.actia-page {
  padding: 2rem 0;

  .create-session {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width: 768px) {
      justify-content: center;
    }

    button {
      width: 20rem;

      @media screen and (max-width: 768px) {
        width: 15rem;
      }
    }
  }

  .table-wrapper {
    padding-top: 3rem;
  }

  .pagination-wrapper {
    padding-top: 3rem;
  }
}
