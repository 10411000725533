@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.upload-proforma-amerigo {
  background-color:  $white-5;
  @include themify($themes) {
    color: themed('main');
  }
  font-weight: normal;
  position: relative;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 990px) and (min-width: 768px) {
    width: 100%;
  }

  .modal-dialog {
    padding: 0 4.7rem 2rem;

    @media only screen and (max-width: 768px) {
      padding: 1rem;
      box-sizing: border-box;
    }

    .close {
      svg {
        position: absolute;
        top: 0;
        right: 1rem;

        @media only screen and (max-width: 768px) {
          top: 1rem;
        }
      }
    }

    svg {
      font-size: 2.4rem;
    }
  }
}
