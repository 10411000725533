.quantity-availability{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;

  .spinner {
    width: auto;
    flex-grow: 0;
    margin: 0 2.65rem;
  }
}