@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.navbar-item-container {
  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;

    @media only screen and (max-width: 500px) {
      justify-content: center;
      align-items: center;
      display: flex;
      height: 6rem;
    }

    @media only screen and (min-width: 500px) and (max-width: 768px) {
      justify-content: center;
      align-items: center;
      display: flex;
      height: 4rem;
    }
  }

  .icon-item {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @include themify($themes) {
      color: themed('main');
    }

    padding: 0 1rem;
    box-sizing: border-box;
    white-space: initial;
    width: 16rem;
    height: 3.4rem;

    @media screen and (max-width: 500px) {
      width: auto;
      min-width: 6.5rem;
      flex-direction: column;
      padding: 0 0.5rem;
      box-sizing: border-box;
      height: 6rem;
    }

    @media screen and (max-width: 659px) {
      width: auto;
      min-width: 6.5rem;
      flex-direction: column;
      padding: 0 0.5rem;
      box-sizing: border-box;
    }

    @media screen and (min-width: 660px) and (max-width: 768px) {
      width: 13rem;
      min-width: 7.5rem;
      flex-direction: row;
      padding: 0 0.5rem;
      height: 3.4rem;
    }

    @media screen and (min-width: 768px) and (max-width: 990px) {
      width: 16rem;
      height: 3.4rem;
    }

    &.active {
      @include themify($themes) {
        background-color: themed('iconTextItemActive');
      }

      @media screen and (max-width: 500px) {
        height: 6rem;
      }
    }

    .item-icon {
      position: relative;
      width: 2.7rem;
      height: 1.6rem;
      line-height: 1rem;
      font-size: 1.8rem;
      letter-spacing: 0;

      & .notification {
        font-size: 1.1rem;
        line-height: 1.5rem;
        letter-spacing: normal;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $red;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 35%;
        transform: translateY(-40%);
        text-align: center;
      }

      @media only screen and (max-width: 500px) {
        width: auto;
      }

      @media only screen and (max-width: 768px) {
        line-height: 0rem;
        height: auto;
        font-size: 1.6rem;
      }
    }

    .item-text {
      font-family: 'Open Sans';
      font-size: 1.3rem;
      letter-spacing: 0;
      line-height: 1.2rem;
      text-align: center;

      @media only screen and (max-width: 768px) {
        line-height: 1.2rem;
      }

      .item-label {
        @media only screen and (max-width: 768px) {
          line-height: 1.7rem;
          font-size: 1.1rem;
        }

        @media only screen and (max-width: 428px) {
          text-overflow: ellipsis;
          max-width: 90px;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
}