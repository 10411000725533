@import '../../assets/sass/variables';
@import 'src/assets/sass/themes';

.footer-container {
  @include themify($themes) {
    background-color: themed('secondary');
  }

  height: 2.4rem;
  padding: 0.5rem 1rem;
  display: flex;
  flex-flow: nowrap row;
  flex: 1;
  align-items: center;
  position: relative;
  margin-top: auto;
  z-index: 8;

  .logos {
    display: flex;
    flex: 0 0 auto;

    .logo {
      display: inline-block;
      height: 1.4rem;
      max-width: 12.2rem;

      img {
        height: 100%;
        width: auto;
      }
    }
  }

  .links {
    flex: 1 1 auto;
    text-align: center;

    @include themify($themes) {
      color: themed('main');
    }

    font-family: 'Open Sans Regular',
    'sans-serif';
    font-size: 1.1rem;
    letter-spacing: normal;
    line-height: inherit;

    .copyright {
      font-weight: bold;
    }

    .text {
      display: inline-block;
      margin: 0 1.5rem;
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding-bottom: 1.5rem;

    .logos,
    .links {
      padding: 1.5rem 0 0.5rem;
    }

    .links {
      display: contents;

      .text {
        margin-top: 1rem;
        // margin: 0 0.5rem;
      }

      .copyright {
        margin-top: 1rem;
        width: 100%;
      }
    }

    .language-container {
      display: none !important;
    }
  }

  @media screen and (max-width: 990px) and (min-width: 768px) {
    flex-direction: column;
    height: auto;
    padding-bottom: 1.5rem;

    .logos,
    .links {
      padding: 1.5rem 0 0.5rem;
    }

    .links {
      display: contents;

      .text {
        margin-top: 1rem;
        // margin: 0 0.5rem;
      }

      .copyright {
        margin-top: 1rem;
        width: 100%;
      }
    }

    .language-container {
      display: none !important;
    }
  }

  & .language-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    @include themify($themes) {
      color: themed('main');
    }
  }
}