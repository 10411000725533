@import "../../assets/sass/variables";
@import "src/assets/sass/themes";

.navbar {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include themify($themes) {
    background-color: themed("secondary");
  }

  @media only screen and (max-width: 990px) {
    @include themify($themes) {
      background-color: themed("mainMobile");
    }
  }

  height: 7.2rem;

  .sidebar-mobile {
    height: 58px;
    width: 38px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 990px) and (min-width: 768px) {
      width: 48px;
    }

    .icon {
      font-size: 1.8rem;

      @include themify($themes) {
        color: themed("main");
      }

      path {
        @media only screen and (max-width: 990px) {
          @include themify($themes) {
            fill: themed("navbarIconMobile");
          }
        }
      }

      font-weight: 900;
    }
  }

  & .logos {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 1rem;

    @media only screen and (max-width: 768px) {
      align-items: initial;
      margin-left: 0.2rem;
      max-width: 220px;
    }

    @media only screen and (max-width: 990px) and (min-width: 768px) {
      align-items: initial;
      margin-left: 0.2rem;
      max-width: 220px;
    }

    img {
      @media only screen and (max-width: 1150px) and (min-width: 990px) {
        display: none;
      }
    }

    & :hover {
      cursor: pointer;
    }

    & .garage-michel {
      display: flex;
      margin-left: 0.6rem;
      height: 100%;
      align-items: center;
      padding-right: 0.5rem;
      border-right: 1px solid rgba(39, 80, 159, 0.2);

      img {
        max-width: 9rem;
        max-height: 4rem;

        @media only screen and (max-width: 1340px) and (min-width: 990px) {
          display: inline;
        }
      }

      @media only screen and (max-width: 768px) {
        display: flex;
        align-items: center;
        width: 100%;
        border-right: none;
        margin-left: 0rem;
        column-gap: 1rem;
      }

      @media only screen and (max-width: 990px) and (min-width: 768px) {
        width: 100%;
        align-items: center;
        border-right: none;
        margin-left: 0rem;
      }
    }
  }

  & .tabs {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;

    & .tab {
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 0.5rem;

      @include themify($themes) {
        color: themed("main");
      }

      height: 72px;
      width: 136px;

      @media only screen and (max-width: 1340px) and (min-width: 990px) {
        min-width: 105px;
        max-width: 136px;
        width: auto;
      }

      & .tab-icon {
        @include themify($themes) {
          color: themed("main");
        }

        width: 18px;
        height: 18px;
        font-size: 1.5rem;
      }

      & .text {
        white-space: initial;
        text-align: center;
        font-size: 1.3rem;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 1.8rem;
      }

      & :hover {
        cursor: pointer;
      }
    }

    & .active {
      background-color: $blue-17;
    }
  }

  & .user-settings {
    display: flex;
    align-items: center;
    gap: 1.7rem;

    .logout-button {

      margin-right: 1rem;
    }

    & .mini-dropdowns {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 0.6rem;
      margin-right: 1rem;
    }
  }
}