@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.statistics-page {
  padding: 2rem 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  @media screen and (max-width: 768px) {
    padding: 1rem;
  }
  & .statistics-filters {
    width: 100%;
  }
  & .statistics-title {
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 2.3rem;
  }
  & .statistics-data {
    display: flex;
    gap: 3rem;
    width: 100%;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
    & .statistics-table {
      width: 100%;
      background-color:  $white-5;
      border-radius: 1rem;
      min-width: 25rem;
      max-width: 40rem;

      &.loading {
        width: 75rem;
        @media screen and (max-width: 768px) {
          width: 35rem;
        }
      }
    }

    & .statistics-chart {
      position: relative;
      padding: 4rem 0 2rem 0;
      box-sizing: border-box;
      display: flex;
      background-color:  $white-5;
      width: 100%;
      height: fit-content;
      border-radius: 1rem;
      &.loading {
        height: 46rem;
      }
    }
  }
}
