@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.filter-supplier {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  & .filter-supplier-header {
    padding-bottom: 1rem;
    box-sizing: border-box;
    width: 90%;
    border-bottom: 0.1rem solid $blue-5;

    .checkbox-container {
      input[type='checkbox'] {
        @include themify($themes) {
          color: themed('main');
        }
        width: 1.1em;
        height: 1.1em;
        cursor: pointer;
      }

      input[type='checkbox']::before {
        border: 0.2rem solid $blue;
      }

      span {
        height: 3.5rem;
        width: 24.1rem;
        @include themify($themes) {
          color: themed('main');
        }
        font-size: 1.4rem;
        font-weight: bold;
        cursor: pointer;
      }
    }

    & .all-suppliers-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: 4rem;
      width: 100%;

      & .add-supplier-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        @include themify($themes) {
          color: themed('main');
        }
        @include themify($themes) {
          background-color: themed('secondary-3');
        }
        gap: 0.5rem;
        border-radius: 0.7rem;
        padding: 0.8rem 1.3rem;
        cursor: pointer;

        &.disabled {
          user-select: none;
          pointer-events: none;
        }

        &:hover {
          opacity: 50%;
        }
      }

      & .all-suppliers-dropdown {
        position: absolute;
        @include themify($themes) {
          background-color: themed('secondary-3');
        }
        border-radius: 0.7rem;
        top: 3.5rem;
        max-height: 20rem;
        overflow-y: auto;
        min-width: 14.5rem;
        min-height: 2rem;
        box-sizing: border-box;
        z-index: 20;

        &.loading {
          padding: 0.5rem 0.7rem;
        }

        & .filter-supplier-no-data {
          @include themify($themes) {
            color: themed('main');
          }
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          margin-top: 0.2rem;
        }

        & .supplier-list-item {
          @include themify($themes) {
            color: themed('main');
          }
          padding: 0.5rem 1rem;
          cursor: pointer;

          &:hover {
            background-color: $white-2;
            border-radius: 0.7rem 0.7rem 0 0;
          }
        }
      }
    }
  }

  & .filter-supplier-title {
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 2rem;
    text-align: center;
    padding-bottom: 1rem;
    max-width: 65rem;
    width: 100%;
    margin-top: 1.4rem;

    @media screen and (max-width: 990px) and (min-width: 768px) {
      margin-top: 4.4rem;
    }

    @media screen and (max-width: 768px) {
      margin-top: 2.4rem;
    }
  }

  & .suppliers-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    width: 100%;

    & .suppliers-subtitle {
      @include themify($themes) {
        color: themed('main');
      }
      font-size: 1.1rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-weight: bold;
      opacity: 0.5;
      text-transform: uppercase;
    }

    & .filter-alphabet {
      display: flex;
      flex-direction: row;
      margin-bottom: 2rem;
      position: sticky;
      top: 12rem;

      .alphabet {
        @include themify($themes) {
          color: themed('main');
        }
        font-size: 1.4rem;
        border: 1px solid $gray-10;
        padding: 0.5rem 1rem;
        text-align: center;
        margin: 0.3rem;
        border-radius: 0.5rem;
        display: flex;
        justify-content: center;
        justify-items: center;
        align-items: center;
        cursor: pointer;
        background-color: $white;
      }

      .check {
        @include themify($themes) {
          background-color: themed('main');
        }
        @include themify($themes) {
          color: themed('secondary');
        }
      }
    }

    & .filter-suppliers-container {
      width: 100%;

      &.loading {
        height: 30rem;
      }

      @media screen and (max-width: 990px) {
        height: 30rem;
        overflow-y: auto;
      }

      & .no-data {
        font-size: 2.4rem;
        font-weight: bold;
        @include themify($themes) {
          color: themed('main');
        }
        opacity: 60%;
        margin-top: 5rem;
        white-space: nowrap;
        width: 100%;
        text-align: center;
      }

      &.catalogs {
        height: auto;

        .filter-suppliers {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          justify-content: flex-start;

          width: 100%;
          gap: 1rem 11.5rem;
          box-sizing: border-box;
          padding: 0 2rem;

          & .non-tecdoc {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
          }
        }
      }

      & > .small {
        top: -2rem;
      }

      & .filter-suppliers {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        gap: 1rem 11.5rem;
        box-sizing: border-box;
        padding: 0 2rem;

        & .supplier {
          @include themify($themes) {
            color: themed('main');
          }
          font-size: 1.4rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 0.5rem;
          width: 25rem;

          input[type='checkbox'] {
            @include themify($themes) {
              color: themed('main');
            }
          }

          input[type='checkbox']::before {
            border: 0.2rem solid $blue-1;
          }

          .supplier-actions {
            display: flex;
            gap: 1rem;
          }

          .supplier-action {
            cursor: pointer;
          }

          &.catalog {
            position: relative;
            min-height: 3rem;
            display: flex;
            align-items: center;

            & .spinner {
              width: 2rem;
            }
          }
        }
      }

      & .filter-suppliers-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & .filter-suppliers-alphabet {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;
          gap: 1rem 11.5rem;
          box-sizing: border-box;
          padding: 0 2rem;

          & .supplier {
            @include themify($themes) {
              color: themed('main');
            }
            font-size: 1.4rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 0.5rem;
            width: 25rem;

            input[type='checkbox'] {
              @include themify($themes) {
                color: themed('main');
              }
            }

            input[type='checkbox']::before {
              border: 0.2rem solid $blue-1;
            }

            .supplier-actions {
              display: flex;
              gap: 1rem;
            }

            .supplier-action {
              cursor: pointer;
            }

            &.catalog {
              position: relative;
              min-height: 3rem;
              display: flex;
              align-items: center;

              & .spinner {
                width: 2rem;
              }
            }
          }
        }

        & .filter-alphabet {
          display: flex;
          flex-direction: row;
          margin-bottom: 2rem;
          position: sticky;
          top: 12rem;

          .alphabet {
            @include themify($themes) {
              color: themed('main');
            }
            font-size: 1.4rem;
            border: 1px solid $gray-10;
            padding: 0.5rem 1rem;
            text-align: center;
            margin: 0.3rem;
            border-radius: 0.5rem;
            display: flex;
            justify-content: center;
            justify-items: center;
            align-items: center;
            cursor: pointer;
            background-color: $white;
          }

          .check {
            @include themify($themes) {
              background-color: themed('main');
            }
            @include themify($themes) {
              color: themed('secondary');
            }
          }
        }
      }
    }
  }

  & .filter-supplier-submit {
    width: 100%;
    max-width: 45rem;
    margin-top: 6rem;
    margin-bottom: 2rem;

    @media screen and (max-width: 860px) {
      max-width: 20rem;
    }
  }
}
