@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.equivalance-selector-tabs-container {
  width: 100%;

  & .equivalance-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.7rem;
    gap: 0.938rem;

    & .tab-equivalance {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2.7rem;
      border-radius: 0;
      font-size: 2rem;
      cursor: pointer;

      @include themify($themes) {
        color: themed('tabInactiveColor');
      }

      @media screen and (max-width: 768px) {
        .equivalence-title {
          font-size: 1rem;
          line-height: 1rem;
        }
      }

      &.disabled {
        pointer-events: none;
      }

      &.active {
        @include themify($themes) {
          color: themed('tabActiveColor');
          border-bottom: 3px solid themed('main');
        }
      }
    }
  }
}