@import '../../assets/sass/variables';
@import 'src/assets/sass/themes';

.video-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  gap: 14.8rem;
  margin: 1rem auto;
  @include themify($themes) {
    color: themed('main');
  }
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0;

  @media screen and (max-width: 990px) {
    flex-direction: column;
    gap: 1rem;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }

  .video-main {
    max-width: 61.9rem;
  }

  .video-list {
    width: auto;
    max-width: 500px;
  }

  .text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    max-width: 100%;
    word-break: break-word;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .player {
    height: 34.8rem;
    width: 61.9rem;
    border-radius: 3.375rem;
    overflow: hidden;
    position: relative;
    z-index: 0;
    margin-bottom: 0.5rem;
    cursor: pointer;

    @media screen and (max-width: 768px) and (max-width: 990px) {
      width: 100%;
      height: 23.7rem;
    }

    div.react-player__preview {
      position: relative;
      z-index: 1;
    }

    div.react-player__preview::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
    }

    div.react-player__preview::before {
      opacity: 0.8;
      background: $blue-10;
      z-index: 2;
    }

    .button-play {
      font-size: 5rem;
      color: #fff;
      z-index: 2;
      cursor: pointer;
    }
  }

  .informations {
    padding-top: 0.8rem;

    .date {
      @media screen and (max-width: 768px) and (max-width: 990px) {
        text-align: center;
      }
    }

    .title {
      font-size: 2.5rem;
      line-height: 3.4rem;

      @media screen and (max-width: 768px) and (max-width: 990px) {
        font-size: 1.8rem;
        line-height: 2.5rem;
        text-align: center;
      }
    }

    .description {
      padding-top: 0.7rem;

      @media screen and (max-width: 768px) and (max-width: 990px) {
        font-size: 1.5rem;
        text-align: center;
      }
    }
  }

  .video-list {
    .video-item {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      gap: 2rem;
      padding-bottom: 2rem;

      @media screen and (max-width: 768px) and (max-width: 990px) {
        flex-direction: column;
        gap: 0.5rem;
        align-items: center;
        text-align: center;
      }

      .player {
        height: 10.9rem;
        width: 19.4rem;
        border-radius: 1rem;
        background-color: $blue-4;
        position: relative;
        z-index: 0;
        display: flex;
        flex: 0 0 auto;

        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 1;
        }

        &::before {
          opacity: 0.8;
          background: $blue-10;
          z-index: 2;
        }

        .video-preview {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }

        .button-play {
          cursor: pointer;
          margin-top: -1.5rem;
          position: absolute;
          top: 50%;
          left: 50%;
          font-size: 2.5rem;
          color: $white;
          z-index: 2;
        }

        .informations {
          display: flex;
          flex: 1 1 auto;

          .title {
            line-height: 2.8rem;
          }
        }
      }
    }

    .video-link-all {
      .default-button {
        margin: 1rem 0;
        max-width: 19.4rem;
        font-size: 1.12rem;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 1.5rem;
        text-align: center;
        color: $white;

        @media screen and (max-width: 768px) and (max-width: 990px) {
          max-width: none;
        }
      }
    }
  }
}
