@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.public-footer-container {
  @include themify($themes) {
    background-color: themed('secondary');
  }
  padding: 0.5rem 1rem;
  display: flex;
  position: relative;
  margin-top: auto;
  gap: 1rem;
  flex-direction: column;

  & .public-footer-top {
    @include themify($themes) {
      background-color: themed('secondary');
    }
    padding: 0.5rem 1rem;
    display: flex;
    position: relative;
    margin-top: auto;
    align-items: center;
    gap: 1rem;

    & .logo-tecalliance {
      mix-blend-mode: multiply;
    }

    .logos {
      display: flex;
      flex: 0 0 auto;

      .logo {
        display: inline-block;
        height: 1.4rem;
        max-width: 12.2rem;

        img {
          height: 100%;
          width: auto;
        }
      }
    }

    .links {
      @include themify($themes) {
        color: themed('main');
      }
      font-family: 'Open Sans Regular', 'sans-serif';
      font-size: 1.1rem;
      letter-spacing: normal;
      line-height: inherit;

      .copyright {
        font-weight: bold;
      }

      .text {
        display: inline-block;
        margin: 0 0.5rem;
      }
    }

    @media only screen and (max-width: 480px) {
      flex-direction: column;
      height: auto;
      text-align: center;
      .logos,
      .links {
        padding: 1.5rem 0 0.5rem;
      }

      .links {
        .text {
          margin: 0 0.5rem;
        }

        .copyright {
          width: 100%;
          margin: 0;
        }
      }
    }

    & .language-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      @include themify($themes) {
        color: themed('main');
      }
    }
  }

  .footer-terms-conditions {
    display: flex;
    gap: 4rem;
    @media screen and (max-width: 480px) {
      flex-direction: column;
      gap: 1rem;
      text-align: center;
    }
    .terms-conditions {
      font-size: 1.1rem;
      @include themify($themes) {
        color: themed('main');
      }

      @media screen and (max-width: 850px) {
        max-width: 100%;
      }
    }
  }
}
