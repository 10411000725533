@mixin respond($breakpoint) {
  @if $breakpoint ==phone {
    @media (max-width: 37.5em) {
      //600px
      @content;
    }
  }

  @if $breakpoint ==tab-port {
    @media (max-width: 56.25em) {
      //900px
      @content;
    }
  }

  @if $breakpoint ==tab-land {
    @media (max-width: 75em) {
      //1200em
      @content;
    }
  }

  @if $breakpoint ==big-desktop {
    @media (min-width: 112.5em) {
      //1800em
      @content;
    }
  }
}

@mixin hover-container() {
  border: 1px solid $red;
  cursor: pointer;
}
