@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.wrapper-myclients {
  width: 90%;
  margin: 0 auto;
  min-height: 80vh;

  .top-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20%;
    height: 7.7rem;

    .title {
      @include themify($themes) {
        color: themed('main');
      }

      line-height: 2.563rem;
      font-size: 2.875rem;
    }

    .notification {
      @include themify($themes) {
        background-color: themed('secondary-3');
      }
      border: 10px $white-3 solid;
      border-radius: 10px;
      color: $red-2;
      width: 10.688rem;
      text-align: center;
      font-size: 1.2rem;
      cursor: pointer;
    }

    .new-client {
      @include themify($themes) {
        background-color: themed('main');
      }

      border: 10px $blue-icon-color solid;
      border-radius: 10px;
      color: white;
      text-align: center;
      font-weight: 600;
      font-size: 1.45rem;
      cursor: pointer;
    }
  }

  .search-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 8.063rem;
    border: 1px $blue-5 solid;
    border-radius: 10px;
    padding: 1.563rem;

    .wrapper-title {
      line-height: 1.188rem;

      @include themify($themes) {
        color: themed('main');
      }

      font-size: 0.7rem;
    }

    .inside-content {
      display: flex;
      align-items: center;
      gap: 1.188rem;

      .sort-wrapper {
        & .sort {
          height: 3.8rem;
          font-size: 1.4rem;
          font-weight: 400;
          @include themify($themes) {
            background-color: themed('secondary-3');
          }
          border-radius: 10px;

          @include themify($themes) {
            color: themed('main');
          }

          width: 13.625rem;

          &.disabled {
            opacity: 30%;
            pointer-events: none;
            user-select: none;
          }

          [class$='-control'] {
            @include themify($themes) {
              color: themed('main');
            }

            border: none;
            @include themify($themes) {
              background-color: themed('secondary-3');
            }
            height: 3.8rem;
            box-shadow: none;

            &:hover {
              border: none;
            }
          }

          [class$='-placeholder'] {
            @include themify($themes) {
              color: themed('main');
            }

            font-size: 1.4rem;
            font-weight: 400;
          }

          [class$='-indicatorContainer'] {
            @include themify($themes) {
              color: themed('main');
            }
          }

          [class$='-singleValue'] {
            @include themify($themes) {
              color: themed('main');
            }
          }

          [class$='-indicatorSeparator'] {
            background: none;

            @include themify($themes) {
              color: themed('main');
            }
          }

          [class$='-indicator'] {
            @include themify($themes) {
              background: themed('main');
            }
            color: $white;
          }
        }
      }

      .client-search {
        width: 30rem;

        .radio-container {
          padding-top: 14px;
          display: flex;
          justify-content: flex-end;

          .input-search-icon {
            @include themify($themes) {
              color: themed('main');
            }

            margin-left: 5rem;
            margin-right: 4px;
            font-size: 2rem;
            padding-bottom: 5px;
            right: 0;
            border: 0;
            background-color: transparent;
            cursor: pointer;
          }

          .radio {
            position: relative;
            margin: 0.3rem;

            input[type='radio'] {
              z-index: 2;
              cursor: pointer;
              position: absolute;
              width: 1.5em;
              height: 1.5em;
              opacity: 0;

              + label {
                &:before {
                  content: '';
                  background: $blue-10;
                  border-radius: 100%;
                  border: 2px solid $blue;
                  display: inline-block;
                  position: relative;
                  top: -0.2em;
                  margin-right: 0.5em;
                  vertical-align: top;
                  text-align: center;
                  width: 1.5em;
                  height: 1.5em;
                }
              }

              &:checked {
                + label {
                  &:before {
                    @include themify($themes) {
                      background-color: themed('main');
                    }
                  }
                }
              }
            }

            label {
              font-size: 1.4rem;
              line-height: 1.9rem;
              font-weight: 700;
              margin-bottom: 0.5rem;
              padding-left: 0.4rem;

              @include themify($themes) {
                color: themed('main');
              }
            }

            .check-icon {
              position: absolute;
              left: 10px;
              top: 3px;
              color: $blue-10;
              font-size: 14px;
            }
          }
        }

        .text-input {
          height: 3.8rem;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .top-content {
      gap: 0;

      .notification {
        width: 8rem;
        border: 8px $white-3 solid;
      }

      .title {
        font-size: 1.8rem;
      }

      .new-client {
        border: 6px $blue-icon-color solid;
      }
    }

    .search-wrapper .inside-content .client-search {
      width: 22.5rem;
    }
  }

  @media screen and (max-width: 420px) {
    .search-wrapper {
      height: 13.063rem;

      .inside-content {
        flex-direction: column;

        .client-search {
          width: 22.5rem;
        }

        .sort-wrapper .sort {
          width: 22.5rem;
        }
      }
    }
  }
}
