@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.checkbox-cell-wrapper {
  input[type="checkbox"] {
    @include themify($themes) {
      accent-color: themed('main');
    }
  }
}
