@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.allowed-list-details {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    flex-direction: column;
    max-width: 132rem;
    margin: auto;
    width: 100%;

    .allowed-list-container {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        width: 100%;

        @media screen and (max-width: 768px) {
            width: 100%;
        }

        .allowed-return-button {
            display: flex;
            align-items: center;
            gap: 1rem;
            font-size: 1.6rem;
            align-self: flex-start;
            width: max-content;
            cursor: pointer;
            margin: 1rem 0;

            @include themify($themes) {
                color: themed('main');
            }

            &:hover {
                opacity: 0.7;
            }

            .icon {
                font-size: 1.6rem;
                cursor: pointer;

                @include themify($themes) {
                    color: themed('main');
                }
            }
        }

        .allowed-list-container-top {
            background-color: $white-5;
            padding: 2.5rem;
            border-radius: 1rem 1rem 0 0;
            position: relative;

            &.isloading {
                min-height: 5rem;

                & .data-table-spinner {
                    background: none;
                }
            }

            .allowed-list-data {
                display: flex;
                flex-direction: column;
                gap: 2rem;

                .allowed-list-comment {
                    color: $blue-1;
                    display: flex;
                    flex-direction: column;

                    &-label {
                        font-weight: bold;
                        font-size: 1.2rem;
                    }

                    &-value {
                        font-size: 1.1rem;
                    }

                    & .submit-btn {
                        min-width: 10rem;
                        align-self: flex-end;
                    }
                }

                .allowed-list-items {
                    display: flex;
                    justify-content: space-between;

                    .allowed-list-item {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 1rem;
                        color: $blue-1;

                        &-label {
                            font-weight: bold;
                            font-size: 1.2rem;
                        }

                        &-value {
                            font-size: 1.1rem;

                            &-file {
                                font-size: 1.5rem;
                                cursor: pointer;

                                &:hover {
                                    opacity: 0.7;
                                }
                            }
                        }
                    }
                }

                & .allowed-form {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    & .submit-btn {
                        width: 10rem;
                        align-self: flex-end;
                    }

                    & .allowed-row {
                        display: flex;
                        align-items: flex-start;
                        gap: 1rem;

                        @media screen and (max-width: 768px) {
                            flex-direction: column;
                        }

                        & .allowed-container {
                            width: 100%;

                            & .input-item {
                                max-width: 63rem;

                            }

                            & label {
                                font-weight: bold;
                                font-size: 1.2rem;
                                color: $blue-1;
                            }

                            &.hide {
                                display: none;
                            }

                            .select-dropdown {
                                border-radius: 0.5rem;
                                font-size: 1.4rem;
                                font-weight: 400;

                                @include themify($themes) {
                                    color: themed('main');
                                }

                                border: none;

                                [class$='-control'] {
                                    @include themify($themes) {
                                        color: themed('main');
                                    }

                                    border: none;

                                    @include themify($themes) {
                                        background-color: themed('secondary-3');
                                    }

                                    height: auto;
                                    box-shadow: none;
                                    min-height: 4.2rem;

                                    &:hover {
                                        border: none;
                                    }
                                }

                                [class$='-placeholder'] {
                                    @include themify($themes) {
                                        color: themed('main');
                                    }

                                    opacity: 0.5;
                                    font-size: 1.4rem;
                                    font-weight: 400;
                                }

                                [class$='-indicatorContainer'] {
                                    @include themify($themes) {
                                        color: themed('main');
                                    }
                                }

                                [class$='-singleValue'] {
                                    @include themify($themes) {
                                        color: themed('main');
                                    }
                                }

                                [class$='-indicatorSeparator'] {
                                    background: none;

                                    @include themify($themes) {
                                        color: themed('main');
                                    }
                                }

                                [class$='-indicator'] {
                                    @include themify($themes) {
                                        background: themed('main');
                                    }

                                    color: $white;
                                }
                            }
                        }
                    }
                }
            }
        }

        .allowed-list-container-bottom {
            background-color: $white-5;
            border-radius: 0 0 1rem 1rem;
            position: relative;

            &.isloading {
                min-height: 20rem;

                & .reference-table-spinner {
                    background: none;
                    top: 3rem;
                }
            }

            & .details-filter {
                display: flex;
                gap: 1rem;
                padding: 2.5rem;
                align-items: center;

                & .allowed-input-item {
                    width: 100%;
                }

                & .allowed-button-item {
                    width: 100%;
                    max-width: 10rem;
                }
            }

            & .table-wrapper {
                width: 100%;
                height: 100%;


                table {
                    border-radius: 0 0 1rem 1rem;

                    .notFond {
                        text-align: center;
                        padding: 1rem;
                        font-weight: bold;
                        height: 10rem;
                    }
                }
            }
        }
    }
}