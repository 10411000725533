@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.order-view-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 2rem 2rem;

  @media screen and (max-width: 768px) {
    padding: 0rem;
    width: 100%;
  }

  @media only screen and (min-width: 768px) and (max-width: 990px) {
    padding: 0rem;
    width: 100%;
  }

  & .order-client-back {
    position: relative;

    left: 0px;
    display: flex;

    gap: 10px;
    font-size: 14px;
    cursor: pointer;
    padding: 1rem;
    @include themify($themes) {
      color: themed('main');
    }

    @media screen and (min-width: 990px) {
      width: 15rem;
    }

    @media screen and (max-width: 768px) {
      position: static;
      background-color: $white;
      justify-content: left;

      padding: 2rem 2rem 2rem 2rem;
    }

    @media screen and (max-width: 990px) and (min-width: 768px) {
      position: static;
      background-color: $white;
      justify-content: left;

      padding: 2rem 2rem 2rem 2rem;
    }
  }

  & .order-view-body {
    display: flex;
    gap: 2.2rem;

    @media screen and (max-width: 768px) {
      padding: 0rem;
      gap: 0rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @media only screen and (min-width: 768px) and (max-width: 990px) {
      padding: 0rem;
      gap: 0rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  & .order-table {
    background-color:  $white-5;
    border-radius: 1rem;
    padding: 3rem 2.5rem;
    max-width: 81.6rem;
    width: 100%;

    @media screen and (max-width: 768px) {
      padding: 3rem 0.4rem;
      max-width: 95%;
      margin-bottom: 5rem;
    }

    @media only screen and (min-width: 768px) and (max-width: 990px) {
      padding: 3rem 0.4rem;
      max-width: 95%;
      margin-bottom: 5rem;
    }
  }

  & .chat {
    @media screen and (max-width: 768px) {
      width: 95%;
    }

    @media only screen and (min-width: 768px) and (max-width: 990px) {
      width: 100%;
    }
  }
}
