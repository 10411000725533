@import "../../assets/sass/variables";
@import "src/assets/sass/themes";

.catalog-parts-items-wrapper {
  gap: 0.5rem;
  flex-direction: column;
  background: $blue-10;
  border-radius: 0.5rem;
  display: flex;
  position: relative;
  max-width: 40rem;
  min-height: 32.5rem;
  justify-content: space-between;
  padding: 2rem;
  box-sizing: border-box;
  max-height: 45rem;

  .favorite-button-wrapper {
    align-self: flex-end;
    width: 100%;
    z-index: 2;
    position: relative;

    .favorite-button {
      position: absolute;
      right: 0;

      svg {
        cursor: pointer;
      }

      .spinner-container {
        width: 2rem !important;
        height: 2rem !important;
        position: relative;

        .spinner {
          position: absolute;
          right: -1rem;
          top: -1rem;
          left: unset;
          background-color: #f0f4fc;
        }
      }

      button {
        svg {
          position: absolute;
          width: 2rem !important;
          height: 2rem !important;
          right: -1rem;
          top: -1rem;
          left: unset;
        }
      }
    }
  }

  .wrapper-quantity {
    height: 3.4rem;
    line-height: 3.4rem;
  }

  input[type="number"] {
    background-color: $blue-11;
  }

  .heart {
    width: 1.8rem;

    @include themify($themes) {
      fill: themed("main");
    }

    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  .topContent {
    display: flex;
    gap: 1rem;
    width: 100%;
    position: relative;

    .img-zoom-container {
      position: relative;
      display: flex;
      gap: 1rem;
      z-index: 10;

      & .img-zoom {
        position: absolute !important;
        left: 6.5rem;
        // img {
        //   width: 100% !important;
        // }
      }
    }

    .img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 6.4rem;
      height: 6.4rem;
      border: 0.1rem solid $gray;

      img {
        max-width: 6.4rem;
        max-height: 6.4rem;
        margin: auto;
      }
    }

    .info {
      display: flex;
      flex-direction: column;

      .amerigo-part-title {
        font-size: 1.6rem;

        @include themify($themes) {
          color: themed("main");
        }

        font-weight: 700;

        &:hover {
          opacity: 80%;
          cursor: pointer;
        }
      }

      .description {
        width: 80%;
        font-size: 1.2rem;

        @include themify($themes) {
          color: themed("main");
        }
      }
    }
  }

  .articles-quantity {
    @include themify($themes) {
      color: themed("main");
    }

    font-weight: bold;
    font-size: 1.2rem;
    margin-top: 0.8rem;
  }

  .tag {
    font-family: "Open Sans", sans-serif;

    @include themify($themes) {
      color: themed("main");
    }

    font-size: 1.2rem;
    font-weight: 100;
    margin-top: 0.8rem;
    max-height: 232px;
    overflow: auto;
    margin-right: -7px;
    padding-right: 7px;
  }

  .info {
    @include themify($themes) {
      color: themed("main");
    }

    flex: 1;
  }

  .separator {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 1rem 0;

    .logo {
      max-width: 7.7rem;
      max-height: 2.5rem;
    }

    .teccom-logo {
      max-width: 7.7rem;
      max-height: 2.5rem;
      cursor: pointer;
    }

    .hr {
      height: 0.1rem;
      background: $gray;
      width: 45%;
    }
  }

  .bottomContent {
    display: flex;
    justify-content: space-between;

    .itemValue {
      display: flex;
      gap: 1.5rem;
      justify-content: flex-end;
      justify-content: space-between;
      width: 100%;

      .iconButton {
        font-weight: bold;
        width: 7.7rem;
        height: 3.4rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #ee2939;
        border-radius: 0.8rem;
        border: none;
        cursor: pointer;
        color: $white;
        gap: 1rem;
        font-size: 1.2rem;
        line-height: 1.7rem;
        text-align: center;

        svg {
          font-size: 1.2rem;

          circle {
            stroke: $white;
          }
        }

        &:hover {
          background-color: $white;
          color: #ee2939;
          border: 0.1rem solid #ee2939;

          svg {
            font-size: 1.2rem;

            circle {
              stroke: #ee2939 !important;
            }
          }
        }
      }
    }

    .barcode {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      top: 0.5rem;
      right: 0.9rem;
      min-width: 10.1rem;

      .barcode-img {
        max-width: 9.6rem;
        height: 5.2rem;
      }
    }

    .disponible {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      position: relative;
      align-items: flex-start;

      & .teccom-spinner {
        margin: 0;
        width: 2rem;
        height: 2rem;
        background: none;
        position: relative;
        align-self: center;
      }
    }

    .itemInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;
      width: 100%;

      .dispo {
        display: flex;
        align-items: center;
        gap: 0.3rem;
        padding: 0.5rem;

        @include themify($themes) {
          color: themed("main");
        }
      }

      .green {
        width: 1rem;
        height: 1rem;
        border-radius: 5rem;
        background: $green-4;
      }

      .red {
        width: 1rem;
        height: 1rem;
        border-radius: 5rem;
        background: $red;
      }
    }

    .price {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      gap: 0.5rem;
      font-size: 1.8rem;

      @include themify($themes) {
        color: themed("main");
      }

      font-weight: 400;
      font-weight: 750;

      .moneyType {
        font-size: 1.3rem;
      }
    }

    .cart {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.8rem;
      fill: $white;
      padding: 0.8rem;
      height: 2rem;
      width: 6rem;
      border-radius: 0.7rem;
      background: $red-4;
    }
  }
}

.container {
  height: 100%;
  width: 100%;
}

.no-parts-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  left: 48rem;

  svg {
    @media screen and (max-width: 768px) {
      width: 50%;
    }
  }
}
