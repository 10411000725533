.code-cell-wrapper {
  display: flex;
  .barCode {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 75px;
    .img {
      width: 70px;
      height: 41px;
    }

    .code {
      font-size: 8px;
      line-height: 1;
    }
  }
}
