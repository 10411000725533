@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.technical-documentation-graphics {
  display: flex;
  gap: 1rem;
  width: 100%;

  & .diagnostic-sidebar {
    width: 100%;
    max-width: 31.5rem;
  }

  & .diagnostic-content {
    width: 100%;
    height: 100%;

    & .btn-link {
      border: transparent;
      background-color: transparent;
      @include themify($themes) {
        color: themed('main');
      }
      margin-bottom: 1rem;
      margin-right: 1rem;
      font-weight: 700;
      font-size: 1.7rem;
    }
    & :hover {
      cursor: pointer;
    }
    & .active {
      @include themify($themes) {
        border-bottom: 2px solid themed('main');
      }
    }
    .btn-link:disabled {
      cursor: default;
      pointer-events: none;
    }
  }

  .tooltip-class {
    @include themify($themes) {
      background: themed('main');
    }
    min-width: 5rem;
    width: auto;
    text-align: center;
    &.show {
      opacity: 100% !important;
    }
  }
}

.app-layout-mobile {
  .technical-documentation-graphics {
    padding: 0 2rem;
    width: calc(100% - 4rem);
  }
}
