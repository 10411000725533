@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.container-ebook-body {
  .container-ebook {
    @media only screen and (max-width: 768px) {
      overflow-y: auto;
      max-height: calc(100vh - 155px);
    }
  }

  .fetch-ebooks-spinner {
    background: none;
  }

  .heading {
    color: #28549c;
  }

  .no-data {
    text-align: center;
    font-size: 2.4rem;
    font-weight: bold;

    @include themify($themes) {
      color: themed('main');
    }

    opacity: 60%;
    height: 100%;
  }

  .catalog-select {
    max-width: 32rem;

    @media only screen and (max-width: 768px) {
      max-width: 95%;
    }

    margin-left: 1rem;
    margin-bottom: 2rem;

    .catalog-label {
      font-weight: 600;
      font-size: 1.2rem;

      @include themify($themes) {
        color: themed('main');
      }
    }

    .ebooks-select-dropdown {
      height: 4.2rem;
      border-radius: 0.5rem;
      font-size: 1.4rem;
      font-weight: 400;

      @include themify($themes) {
        color: themed('main');
      }

      border: none;

      [class$='-control'] {
        @include themify($themes) {
          color: themed('main');
        }

        border: none;
        background-color: $white;
        height: 4.2rem;
        box-shadow: none;

        &:hover {
          border: none;
        }
      }

      [class$='-placeholder'] {
        @include themify($themes) {
          color: themed('main');
        }

        font-size: 1.4rem;
        font-weight: 400;
      }

      [class$='-indicatorContainer'] {
        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-singleValue'] {
        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-indicatorSeparator'] {
        background: none;

        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-indicator'] {
        @include themify($themes) {
          background: themed('main');
        }
        color: $white;
      }
    }
  }

  .sub-heading {
    color: #28549c;
    font-size: 1rem;
    text-align: right;
    margin-top: 3rem;
  }

  .check:nth-child(even) {
    background-color: #e0e6ef;
  }
}