@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.espace-catalog-container {
  margin-bottom: 2rem;

  &,
  .espace-catalog-search {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 3.7rem;
  }

  & .general-search {
    display: flex;
    flex-direction: row;
    height: 36.2rem;
    gap: 1rem;
    padding: 0 1rem;
    z-index: 2;

    @media screen and (min-width: 991px) and (max-width: 1366px) {
      flex-direction: column;
      height: 100%;
    }

    .search-inputs-container {
      position: relative;

      &.search-tyre div[class$='-dropdown-content'] {
        top: 65%;
      }

      &.search-battery div[class$='-dropdown-content'] {
        top: 95%;
      }

      div[class$='-dropdown-content'] {
        min-width: 1rem;
        width: calc(100% - 3rem);

        & div[class$='-result-content'] {
          width: 100%;

          & .suggestion-list-item {
            &:last-child {
              border-radius: 0 0 1rem 1rem;
            }

            & .suggestion-item-title {
              display: flex;
              justify-content: space-between;
              width: 100%;
              gap: 0.5rem;
            }
          }
        }
      }
    }
  }

  & .arrow-icon {
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 2rem;
  }

  .news {
    display: flex;
    flex-direction: row;
    gap: 12rem;
    position: relative;
    align-items: flex-start;
    justify-content: center;
    margin: 4.3rem auto auto 9rem;

    &.loading {
      min-height: 30rem;
      width: 100%;
    }

    & .main-news {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    & .side-news {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1.9rem;

      & .side-news-button {
        width: 19.5rem;
      }
    }
  }

  .container-fluid-large {
    max-width: 100%;
  }

  .side-rss {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 3.4rem;
    margin-bottom: 2rem;
  }
}

/* Responsive CSS Styles */

.app-layout-desktop {
  .espace-catalog-container {
    .espace-catalog-search {
      & .general-search {
        & > div[class*='search-card'] {
          border-radius: 0;
          box-shadow: none;

          &:nth-child(2) {
            @include themify($themes) {
              background: themed('secondary-2');
            }
          }

          .reference-label {
            padding: 0 0.5rem;
          }
        }

        & > div[class*='search-card'],
        & div[class$='-dropdown-content'] {
          label {
            margin-bottom: 0.5rem;
            display: inline-block;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1440px) {
    .espace-catalog-container {
      .header-search-espace-catalog {
        padding: 0 6rem;
        text-align: center;
      }
    }
  }

  @media screen and (min-width: 1366px) {
    .espace-catalog-container {
      .general-search {
        gap: 1rem;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .espace-catalog-container {
      .general-search {
        padding: 0 2rem;

        .search-card-refs {
          .ref-search-inputs-container {
            .reference-container {
              gap: 2rem;
              flex-wrap: wrap;
            }
          }
        }
      }
    }

    .news {
      padding-top: 0.6rem;
      align-items: flex-start;

      .main-news {
        .main-news-item img {
          width: 41.5rem;
          height: 27.6rem;
        }
      }

      .side-news {
        gap: 1rem;

        .side-news-item {
          & img {
            width: 16.3rem;
            height: 10.9rem;
          }
        }
      }
    }
  }

  .container-fluid-large {
    max-width: $container-width-large;
    margin: 0 auto;
  }
}

.app-layout-mobile,
.app-layout-tablet {
  .espace-catalog-container {
    margin-top: 0;

    .espace-catalog-search {
      padding-top: 2rem;
      background: $background-grey;
      padding-bottom: 3.7rem;
    }

    .header-search-espace-catalog {
      padding: 0 2rem;
      gap: 1.8rem;
      text-align: center;
    }

    .title {
      font-size: 2.5rem;
      font-weight: 200;
      word-spacing: 3px;
      color: $black;
      width: 100%;
    }

    .subtitle {
      @include themify($themes) {
        background-color: themed('secondary-3');
      }
      border-color: $grey-7;
      padding: 0.3rem 2rem;
      margin: 0.3rem 0;
      font-weight: 700;
      display: inline-table;
      border-radius: 2.5rem;
      height: auto;
      width: auto;
    }

    .general-search {
      flex-flow: column wrap;
      height: auto;
      padding: 0 2rem;
      margin: 0;
      gap: 1.2rem;
      position: relative;
      z-index: 2;
    }

    .news {
      background-color: $white;
      padding-top: 2.5rem;
      flex-wrap: wrap;
      gap: 2rem;
      margin: 0;
    }

    .main-news,
    .side-news {
      padding: 0 2rem;
    }

    .side-news {
      gap: 1.5rem;
      margin-bottom: 0;

      .side-news-button {
        width: 100%;
        box-shadow: 0.2rem 0.2rem 1rem #0000000a;
        margin: 1.5rem auto;

        .default-button {
          font-weight: 700;
          font-size: 1.2rem;
          line-height: 1.6rem;
          border-radius: 0.5rem;
        }
      }
    }
  }
}

.app-layout-tablet {
  .espace-catalog-container {
    .general-search {
      max-width: 61.7rem;
      align-self: center;
      width: 100%;
    }

    .side-rss {
      .swiper-wrapper {
        .swiper-slide {
          max-width: 24.2rem;
        }
      }
    }
  }
}

.app-layout-mobile {
  .espace-catalog-container {
    .header-search-espace-catalog {
      .title {
        font-size: 2rem;
        line-height: 2.8rem;
        font-weight: 400;
      }

      .subtitle {
        font-size: 1.1rem;
        line-height: 1.5rem;
      }

      .header-search-input {
        width: 100%;
        margin: 0;
      }
    }

    .side-rss {
      .swiper-wrapper {
        &.with-navigation {
          .swiper-container {
            padding: 0 2rem;
            width: calc(100% - 4rem);

            .navigation {
              flex-grow: 1;
              flex-shrink: 1;
            }
          }
        }
      }
    }
  }
}
