@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.icon-item-container {
  .item {
    display: flex;
    align-items: center;
    cursor: pointer;

    @include themify($themes) {
      color: themed('main');
    }

    .item-icon {
      width: 2.7rem;
      height: 1.6rem;
      line-height: 1.6rem;
      font-size: 1.8rem;
      letter-spacing: 0;

      svg {
        @media screen and (max-width: 990px) and (min-width: 768px) {
          position: absolute;
          right: 2rem;
          margin-top: 2rem;
        }
      }
    }
  }
}