@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.messages-catalog-navbar-container {
  width: 100%;
  @include themify($themes) {
    color: themed('main');
  }

  .row {
    &::after {
      flex: 1;
      content: '';
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      padding-top: 1.5rem;
    }

    .breadcrumbs {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 1rem;
      flex: 1;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      .icon {
        @include themify($themes) {
          background-color: themed('secondary');
        }
        border-radius: 100%;
        width: 1.7rem;
        height: 1.7rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.1rem solid $blue-icon-color;

        svg {
          font-size: 1.1rem;
          font-weight: bold;
          @include themify($themes) {
            background-color: themed('secondary');
          }
        }
      }

      .back-description {
        margin: 0;
      }
    }

    .title {
      font-family: Open Sans, 'sans-serif';
      @include themify($themes) {
        color: themed('main');
      }
      font-weight: bold;

      h1 {
        font-size: 2.5rem;
        line-height: 3.4rem;
        text-align: center;
      }
    }
  }

  .supplier-name {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding-top: 1rem;

    .icon svg {
      font-size: 2rem;
    }

    .description {
      text-align: center;
      font-size: 1.8rem;
      margin: 0;
    }
  }
}
