@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.message-data {
  max-width: 82rem;
  padding-bottom: 2.5rem;

  &:first-child {
    padding-bottom: 0;
  }

  .info {
    font-size: 1.3rem;
    line-height: 1.4rem;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .date {
      &.unseen {
        font-weight: bold;
        padding-left: 1.2rem;
        color: red;
        position: relative;

        &:before {
          content: '';
          background-color: red;
          border-radius: 100%;
          position: absolute;
          width: 10px;
          height: 10px;
          top: -0.5rem;
          left: 0;
        }
      }

      span {
        @include themify($themes) {
          color: themed('main');
        }

        font-weight: bold;
      }
    }

    .delete-message {
      max-width: 1.2rem;
      max-height: 1.2rem;

      svg {
        max-width: 1rem;
        max-height: 1rem;

        path {
          @include themify($themes) {
            fill: themed('catalogBtn', 'icon');
          }
        }
      }
    }
  }

  .message {
    border-radius: 0.8rem;
    font-size: 1.2rem;
    line-height: 1.4rem;
    padding: 1.2rem 0.6rem 1.5rem 1.4rem;

    p.message-content {
      margin: 0;
      word-break: break-word;
    }

    .files-data {
      .title-message {
        font-size: 1.2rem;
        line-height: 1.4rem;
        margin-top: 1.2rem;
        margin-bottom: 0.8rem;
      }

      .files {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
      }
    }
  }

  &.received {
    margin: 0 0 0 auto;
    padding: 0 1rem;

    .message {
      @include themify($themes) {

        background-color: themed('mySupplier', 'bgMessage');
      }
    }
  }

  &.sent {
    margin: auto 0 0 0;

    .message {
      background-color: white;
    }
  }
}