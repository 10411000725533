@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.menu-automotor-felicitations {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 12.1rem;
  // margin-bottom: 16.5rem;
  margin-bottom: 7rem;
  width: 100%;
  margin-top: 5rem;

  & .return-btn {
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.8rem;

    @include themify($themes) {
      color: themed('main');
    }

    cursor: pointer;
    max-width: 67.7rem;
    width: 100%;
    margin-bottom: 1rem;
  }

  .menu-automotor-felicitations-message {
    display: flex;
    flex-direction: column;
    margin-left: 62.2rem;
    margin-right: 62.1rem;
    width: 100%;
    max-width: 86rem;
    min-height: 34.5rem;

    @include themify($themes) {
      background-color: themed('secondary-3');
    }

    border-radius: 1rem;
    align-items: center;
    padding-bottom: 2rem;

    .felicitations-title {
      display: flex;
      background-color: $blue-5;
      font-family: 'Open Sans';
      font-weight: 700;
      font-size: 2.4rem;

      @include themify($themes) {
        color: themed('main');
      }

      height: 7.83rem;
      justify-content: center;
      // border-radius: 1rem;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      text-align: center;
      align-items: center;
      width: 100%;
    }

    .felicitations-image {
      margin-top: 2.37rem;
    }

    .felicitations-text {
      font-family: 'Open Sans';
      font-weight: 500;
      font-size: 1.8rem;

      @include themify($themes) {
        color: themed('main');
      }

      line-height: 2.45rem;
      margin-top: 2.5rem;
      margin-left: 6.7rem;
      margin-right: 4.5rem;
      align-items: center;
      text-align: center;

      @media screen and (max-width: 990px) {
        font-size: 1.5rem;
      }
    }
  }

  .question-container {
    max-width: 100rem;
    margin-bottom: 0rem;
  }
}