@import "assets/sass/variables";
@import "src/assets/sass/themes";

.wrapper-client-invitation-page {
  width: 90%;
  margin: 0 auto;

  .top-content {
    display: flex;
    justify-content: center;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    .title-back {
      @include themify($themes) {
        color: themed("main");
      }
    }

    .title {
      @include themify($themes) {
        color: themed("main");
      }

      font-size: 30px;
      line-height: 41px;
      flex: 1.5;

      @media screen and (max-width: 768px) {
        font-size: 20px;
        text-align: center;
      }
    }

    .return-button-wrapper {
      display: flex;
      align-items: center;
      gap: 5px;
      flex: 1;

      .button {
        width: 16px;
        height: 16px;
        fill: $blue-icon-color;
        cursor: pointer;
      }

      .title {
        font-size: 11px;
        line-height: 15px;
      }
    }
  }

  .search-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 8.063rem;
    border: 1px $blue-5 solid;
    border-radius: 10px;
    padding: 1.563rem;

    @media screen and (max-width: 768px) {
      height: 13.063rem;
    }

    .wrapper-title {
      line-height: 1.188rem;

      @include themify($themes) {
        color: themed("main");
      }

      font-size: 0.7rem;
    }

    .inside-content {
      display: flex;
      align-items: center;
      gap: 1.188rem;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      .sort-wrapper {
        // width: 100%;
        width: 30rem;
        max-width: 100%;
        z-index: 10;

        .sort {
          border: 10px $white-3 solid;
          border-radius: 10px;
          width: 30rem;
          position: relative;
          height: 38px;

          @include themify($themes) {
            background-color: themed("secondary-3");
            color: themed("main");
          }
        }
      }

      .client-search {
        width: 30rem;
        max-width: 100%;

        .radio-container {
          padding-top: 8px;
          display: flex;
          justify-content: flex-end;

          .input-search-icon {
            @include themify($themes) {
              color: themed("main");
            }

            margin-left: 5rem;
            margin-right: 4px;
            font-size: 2rem;
            padding-bottom: 5px;
            right: 0;
            border: 0;
            background-color: transparent;
            cursor: pointer;
          }

          .radio {
            position: relative;
            margin: 0.3rem;

            input[type="radio"] {
              z-index: 2;
              cursor: pointer;
              position: absolute;
              width: 1.5em;
              height: 1.5em;
              opacity: 0;

              + label {
                &:before {
                  content: "";
                  background: $blue-10;
                  border-radius: 100%;
                  border: 2px solid $blue;
                  display: inline-block;
                  position: relative;
                  top: -0.2em;
                  margin-right: 0.5em;
                  vertical-align: top;
                  text-align: center;
                  width: 1.5em;
                  height: 1.5em;
                }
              }

              &:checked {
                + label {
                  &:before {
                    @include themify($themes) {
                      background-color: themed("main");
                    }
                  }
                }
              }
            }

            label {
              font-size: 1.4rem;
              line-height: 1.9rem;
              font-weight: 700;
              margin-bottom: 0.5rem;
              padding-left: 0.4rem;

              @include themify($themes) {
                color: themed("main");
              }
            }

            .check-icon {
              position: absolute;
              left: 10px;
              top: 3px;
              color: $blue-10;
              font-size: 14px;
            }
          }
        }

        .text-input {
          height: 3.8rem;
        }
      }
    }
  }
}
