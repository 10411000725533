@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.amerigo-supplier-mapping-options {
  position: relative;
  min-height: 3rem;

  .upload-file-no-suppliers {
    p {
      color: $red;
      text-align: center;
      font-size: 1.4rem;
    }
  }

  .spinner {
    background: transparent;

    svg {
      width: 4rem;
      height: 4rem;
    }
  }

  .select-supplier {
    border-radius: 0.5rem;
    font-size: 1.4rem;
    font-weight: 400;
    @include themify($themes) {
      color: themed('main');
    }

    border: none;

    &.required {
      border: 1px solid red;
    }

    [class$='-control'] {
      @include themify($themes) {
        color: themed('main');
      }
      border: none;
      @include themify($themes) {background-color: themed('mainHover');}
      height: 4.2rem;
      box-shadow: none;

      &:hover {
        border: none;
      }
    }

    [class$='-placeholder'] {
      @include themify($themes) {
        color: themed('main');
      }
      font-size: 1.4rem;
      font-weight: 400;
    }

    [class$='-indicatorContainer'] {
      @include themify($themes) {
        color: themed('main');
      }
    }

    [class$='-singleValue'] {
      @include themify($themes) {
        color: themed('main');
      }
    }

    [class$='-indicatorSeparator'] {
      background: none;
      @include themify($themes) {
        color: themed('main');
      }
    }

    [class$='-indicator'] {
      @include themify($themes) {
        background: themed('main');
      }
      color: $white;
    }
  }
}
