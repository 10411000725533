@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.industrial {
  padding: 5rem 0rem;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 132rem;
  width: 100%;
  margin: 0rem auto;

  &__tabs {
    display: flex;
    justify-content: center;
    width: 100%;

    .navbar-item {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.1rem solid #d7e5ff;

      @include themify($themes) {
        background-color: themed('secondary');
      }

      width: 100%;
      height: 3.4rem;

      .disabled {
        pointer-events: none;
        opacity: 50%;
        background-color: $blue-14;
      }

      a {
        line-height: 3.4rem;
        display: inline-block;
        font-size: 1.3rem;
        font-weight: 600;
        letter-spacing: 0;
        text-align: center;
        text-decoration: none;
        height: 100%;
        width: 100%;

        label {
          @media screen and (max-width: 768px) {
            display: none;
          }
        }
      }

      a.active {
        color: $white;

        @include themify($themes) {
          background-color: themed('main');
        }

        outline: 0.1rem solid $blue-1;
      }

      &:not(:last-of-type) {
        border-right: none;
      }
    }
  }
}