@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.button-link-container {
  display: flex;
  align-items: center;
  text-decoration: none;
  @include themify($themes) {
    background-color: themed('secondary-3');
  }
  gap: 1.5rem;
  cursor: pointer;
  &:hover {
    opacity: 100% !important;
  }
  &.active {
    pointer-events: none;
    & .image {
      @include themify($themes) {
        background: themed('main');
      }
      & svg {
        @include themify($themes) {
          background: themed('main');
          fill: themed('tabActiveColor');
        }
      }
    }
  }
  /////////////////////////////////////////////

  .tooltip-class {
    @include themify($themes) {
      background: themed('main');
    }
    min-width: 5rem;
    width: auto;
    text-align: center;
    &.show {
      opacity: 100% !important;
    }
  }

  ///////////////////////////////////////

  :nth-child(odd) {
    background-color: #e0e6ef;
  }

  &:hover {
    opacity: 80%;
  }

  &.disabled {
    opacity: 50%;
    pointer-events: none;
  }

  .image {
    width: 4rem;
    height: 4rem;
    outline: none;
    appearance: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      @include themify($themes) {
        fill: themed('main');
      }
      width: 2.5rem;
      height: 2.5rem;
      background-color: none;
    }
  }

  div.image {
    min-width: 40px;
    min-height: 40px;
  }

  .text {
    font-size: 1.7rem;
    @include themify($themes) {
      color: themed('main');
    }
    min-width: 20rem;
    font-weight: bold;
  }

  .pdf-icon {
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 1.8rem;
  }
  & :nth-child(2n + 1) {
    @include themify($themes) {
      background-color: themed('secondary-3');
    }
  }
}
