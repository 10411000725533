@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.notifications-dropdown {
  position: relative;

  .notification-button-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    border-radius: 4.2rem;
    background-color: $blue-5;
    gap: 0.7rem;

    @include themify($themes) {
      color: themed('main');
    }

    font-size: 1.3rem;
    cursor: pointer;

    &.open {
      @include themify($themes) {
        background-color: themed('main');
      }

      color: $white;
      pointer-events: none;
    }

    &:hover {
      @include themify($themes) {
        background-color: themed('main');
      }

      color: $white;
    }

    & .notifications-count {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -5px;
      right: -5px;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      color: $white;
      background-color: $red;
      font-size: 0.9rem;
      user-select: none;
    }
  }

  .notifications-dropdown-content {
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    top: 5rem;
    right: 0;
    padding: 2rem;
    box-sizing: border-box;
    border-radius: 1rem;
    background-color: $blue-5;
    box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.25);
    min-width: 30rem;

    @media screen and (max-width: 768px) {
      &.centered {
        right: -163px;
      }
    }

    & .notifications-spinner {
      width: 25rem;
    }

    & .notification-title {
      @include themify($themes) {
        color: themed('main');
      }

      font-size: 1.8rem;
      font-weight: bold;
    }

    & .notification-list {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      height: 20rem;
      overflow-y: auto;

      & .no-notifications {
        @include themify($themes) {
          color: themed('main');
        }

        font-size: 1.4rem;
        width: 25rem;
        text-align: center;
        margin-top: 9rem;
      }

      & .notification-item {
        display: flex;
        flex-direction: row;
        gap: 1rem;

        @include themify($themes) {
          background-color: themed('mainHover');
        }

        padding: 1rem;
        box-sizing: border-box;
        border-radius: 1rem;

        &.unread {
          border: 2px dashed $blue;

          & .notification-message {
            font-weight: bold;
          }
        }

        & .notification-icon-container {
          & .notification-dropdown-icon {
            display: flex;
            align-items: center;
            justify-content: center;

            @include themify($themes) {
              color: themed('main');
            }

            border: 2px solid $blue-1;
            border-radius: 50%;
            padding: 0.5rem;
            height: 2rem;

            & .notification-icon {
              width: 2rem;
              height: 2rem;
            }
          }
        }

        & .notification-info {
          display: flex;
          flex-direction: column;
          gap: 2rem;

          &-text {
            display: flex;
            justify-content: space-between;
            gap: 1rem;
          }

          & .notification-message {
            display: -webkit-box;
            max-width: 100%;
            width: 20rem;
            margin: 0 auto;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          & .notification-date {
            white-space: nowrap;
          }
        }

        & .notification-actions {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          & .notification-file {
            @include themify($themes) {
              color: themed('main');
            }

            display: flex;
            gap: 1rem;
            font-size: 1.2rem;
            border: 1px dashed $blue-1;
            border-radius: 2px;
            padding: 0.5rem;

            & .notification-download-icon {
              cursor: pointer;

              &:hover {
                opacity: 75%;
              }

              .download-notification-spinner {
                width: 1.5rem;
                height: 1.5rem;
              }
            }

            & .notification-filename {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              max-width: 8rem;
            }
          }

          & .notifications-btns {
            display: flex;
            gap: 1rem;
            justify-content: flex-end;
            width: 100%;
          }

          & .notification-action {
            @include themify($themes) {
              color: themed('main');
            }

            font-weight: bold;
            font-size: 1.2rem;
            cursor: pointer;

            &:hover {
              opacity: 75%;
            }
          }
        }
      }
    }

    & .notification-all {
      @include themify($themes) {
        color: themed('main');
      }

      font-size: 1.2rem;
      font-weight: bold;
      text-align: end;
      cursor: pointer;

      &:hover {
        opacity: 75%;
      }
    }
  }
}