@import './assets/sass/variables';
@import 'src/assets/sass/themes';
@import './assets/sass/_mixins.scss';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Squada+One&display=swap');
@import './assets/sass/_toastify_overide.scss';
@import './assets/sass/form';
@import '~react-image-gallery/styles/css/image-gallery.css';

body,
#root {
  display: flex;
  flex-direction: column;
  // font-family: 'Squada One', sans-serif;
  font-family: 'Open Sans', sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;

  @media screen and (min-width: 1400px) {
    //width: 100vw;
    //overflow-x: hidden;
  }

  //This defines what 1rem is
  font-size: 62.5%;

  @include respond(tablet-land) {
    font-size: 56.25%;
  }

  @include respond(tablet-port) {
    font-size: 50%;
  }

  @include respond(big-desktop) {
    font-size: 62.5%;
  }
}

#root {
  min-height: 100vh;
  overflow-y: auto;
}