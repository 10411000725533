@import '../../assets/sass/variables';
@import 'src/assets/sass/themes';


.wrapper-catalog-parts {
  background-color: $white;

  .wrapper-content {
    display: flex;
    width: 90%;
    min-height: 100vh;
    flex-direction: column;
    font-family: 'Open Sans', sans-serif;
    margin: 0 auto;

    .topContent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;

      .logo {
        display: flex;
        align-items: flex-end;
        gap: 20px;
        align-items: flex-start;

        img {
          max-height: 70px;
          align-self: center;
        }

        .buttonHome {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          border: none;
          width: 30px;
          height: 30px;

          @include themify($themes) {
            background: themed('mainHover');
          }

          align-self: center;
        }

        .img {
          @include themify($themes) {
            fill: themed('main');
          }

          width: 18px;
        }
      }

      .title {
        font-size: 24px;

        @include themify($themes) {
          color: themed('main');
        }

        margin-left: 200px;
      }

      .desc {
        font-size: 11px;

        @include themify($themes) {
          color: themed('main');
        }
      }

      .filter {
        display: flex;
        align-items: center;
        gap: 20px;

        .button {
          padding: 5px;

          @include themify($themes) {
            background-color: themed('main');
          }

          color: $white;
          border: none;
          font-weight: 500;
          width: 180px;
          height: 40px;
          border-radius: 10px;
          cursor: pointer;
        }
      }
    }

    .separator {
      display: flex;
      justify-content: center;
      height: 1px;
      background: $gray;
      width: 100%;
    }

    .filterOptions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;
      padding: 10px 0;

      .rezults {
        text-align: center;
        font-size: 14px;
        font-weight: 400;

        @include themify($themes) {
          color: themed('main');
        }

        @media screen and (max-width: 768px) and (max-width: 990px) {
          white-space: nowrap;
        }
      }

      .drop {
        height: 30px;

        @include themify($themes) {
          background: themed('mainHover');
        }

        @include themify($themes) {
          border: 1px solid themed('mainHover');
        }

        border-radius: 5px;

        @include themify($themes) {
          color: themed('main');
        }

        font-weight: 200;
        width: 175px;
      }

      .sortIcons {
        display: flex;
        padding: 5px;
        gap: 10px;

        .icon {
          width: 18px;
          height: auto;
          display: flex;
          padding: 5px;

          @include themify($themes) {
            background: themed('mainHover');
          }

          @include themify($themes) {
            fill: themed('main');
          }

          border-radius: 5px;
          cursor: pointer;
        }
      }

      .iconLine {
        fill: $blue-5;
      }
    }

    .wrapperItems {
      // display: grid;
      // min-height: 70%;
      // width: 100%;
      // grid-gap: 2rem;
      // grid-template-columns: repeat(auto-fit, 30rem);
      // position: relative;
      // justify-content: center;
      // margin: 0 auto;
      // flex: 0.9;

      .car-parts-spinner {
        background: none;
      }

      .arrowLeft,
      .arrowRight {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 50%;
        fill: $blue-icon-color;
        cursor: pointer;
      }

      .arrowRight {
        right: -50px;
      }

      .arrowLeft {
        left: -50px;
      }
    }

    .no-parts-data {
      margin-top: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      svg {
        @media screen and (max-width: 768px) {
          width: 50%;
        }
      }

      & h1 {
        @include themify($themes) {
          color: themed('main');
        }

        align-self: center;
        justify-self: center;
        position: relative;
        font-size: 3rem;
      }
    }

    .nodata-wrapperItems {
      min-height: 70%;
      width: 100%;
      position: relative;
      margin: 0 auto;
      flex: 0.9;

      .car-parts-spinner {
        background: none;
        position: relative;
        top: 20rem;
      }
    }
  }
}