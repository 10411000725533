@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.parts-modal {
  .no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 15rem;
    font-weight: 700;
    font-size: 1.7rem;
    margin: 0.2rem 0;

    @include themify($themes) {
      color: themed('main');
    }
  }
  .parts-modal-body {
    display: flex;
    flex-direction: row;

    .image {
      margin-right: 2rem;
      img {
        width: 10rem;
      }
    }
    .content {
      & p {
        font-weight: 700;
        font-size: 1.7rem;
        margin: 0.2rem 0;

        @include themify($themes) {
          color: themed('main');
        }
      }
      & a {
        text-decoration: none;
        font-size: 1rem;
        padding: 5px 5px;
        display: block;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 2.2rem;
        cursor: pointer;

        @include themify($themes) {
          color: themed('main');
        }

        &:hover {
          background: $blue-5;
          border-radius: 3px;
        }
      }

      .content-subcategory {
        & ul {
          list-style: none;
          padding: 1px 5px 10px 5px;
          font-size: 1.2rem;
          font-weight: bold;

          & p {
            font-weight: 700;
            font-size: 1.7rem;
            margin: 0.1rem 0;

            @include themify($themes) {
              color: themed('main');
            }
          }

          & a {
            text-decoration: none;
            font-size: 1rem;
            padding: 5px 5px;
            display: block;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 2.2rem;
            cursor: pointer;

            @include themify($themes) {
              color: themed('main');
            }

            &:hover {
              background: $blue-5;
              border-radius: 3px;
            }
          }
        }
      }
    }
  }
}
