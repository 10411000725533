@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.accordion-item {
  .accordion-item-title {
    width: 100%;
    margin: 0;
    .accordion-item-btn {
      border: 1px solid $border-color;

      @include themify($themes) {
        color: themed('main');
      }
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      width: 100%;
      background-color: #aabcdc;
      padding: 1rem 4.6rem 1rem 6rem;
      font-size: 1.2rem;
      line-height: 1.6rem;
      font-weight: bold;
      cursor: pointer;
    }

    .accordion-item-btn::after {
      content: '';
      flex-shrink: 0;
      width: 18px;
      height: 18px;
      margin-left: auto;
      background-image: url('/assets/images/plus-accordion.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      transition: transform 0.2s ease-in-out;
    }
  }
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item.active .accordion-item-btn::after {
  background-image: url('/assets/images/minus-accordion.svg');
  background-position: center center;
}

.accordion-item-container {
  transition: height 0.2s ease-in-out;
  overflow: hidden;
}
