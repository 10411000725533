@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.navbar-below-container {
  height: 3.6rem;

  @include themify($themes) {
    background-color: themed('bgNavbarBelow');
  }

  @media only screen and (max-width: 500px) {
    height: 6rem;
  }

  .navbar-below {
    height: 100%;
    margin: 0 auto;

    .items {
      margin: 0 auto;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      .swiper-navbar-below-item {
        max-width: 20rem;
        padding: 0.5rem 0;

        @media screen and (max-width: 768px) {
          height: auto;
          max-width: 15rem;
        }
      }

      .navbar-item-container {
        display: flex;
        align-items: center;
        justify-content: center;

        &.disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }

        .item {
          align-items: center;
          box-sizing: border-box;
          color: $blue-1;
          cursor: pointer;
          display: flex;
          height: 3.4rem;
          justify-content: center;
          padding: 0 1rem;
          white-space: normal;
          width: 16rem;
        }

        .active {
          @include themify($themes) {
            background-color: themed('bgActive');
          }
        }
      }

      font-size: 1.8rem;
    }
  }

  @media screen and (max-width: 990px) {
    .swiper {}
  }
}