@import '../../assets/sass/variables';
@import 'src/assets/sass/themes';

.video-player-wrapper {
  display: flex;
  flex: 1 1 auto;
  max-width: 65rem;
  max-height: 36rem;
  width: 100%;
  height: 100%;
}
