@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.order-info {
  display: flex;
  justify-content: center;
  background-color:  $white-5;
  border-radius: 1rem;
  gap: 5rem;
  padding: 1.8rem 4.3rem;

  @media screen and (max-width: 768px) {
    background-color: $white;
    padding: 1.8rem 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }

  @media only screen and (min-width: 768px) and (max-width: 990px) {
    background-color: $white;
    padding: 1.8rem 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }

  &-item {
    display: flex;
    flex-direction: column;

    & .order-label {
      @include themify($themes) {
        color: themed('main');
      }
      font-weight: bold;
      font-size: 1.2rem;
    }

    & .order-value {
      @include themify($themes) {
        color: themed('main');
      }
      font-size: 1.2rem;
    }
  }
}
