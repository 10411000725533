@import "assets/sass/variables";
@import "src/assets/sass/themes";

.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f5f9ff;
  height: 100%;
  padding: 3rem 0;
  box-sizing: border-box;

  svg {
    height: auto;
    max-width: 100%;
  }

  .error-page-text {
    font-size: 2.4rem;
    font-weight: 400;

    @include themify($themes) {
      color: themed("main");
    }

    margin-top: -8rem;
    margin-bottom: 2rem;
    padding: 4rem 1rem 0rem;
    text-align: center;
  }

  .go-home-button {
    width: 17.5rem;
  }
}