@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

/* Mobile CSS Styles */

.app-layout-tablet,
.app-layout-mobile {
  .general-search {
    flex-flow: column wrap;
    height: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    margin: 0;
    gap: 1.2rem;
    position: relative;
    z-index: 2;
  }

  .search-card {
    //height: 3.5rem;
    height: auto;
    background-color: $white;
    overflow: visible;
    flex-basis: 100%;
    padding: 0;

    .card-title {
      margin: 0;

      @include themify($themes) {
        color: themed('light-main');
      }

      font-weight: 700;
      font-size: 1.6rem;
      line-height: 2.3rem;
      letter-spacing: 0;
      text-decoration: none;
      padding: 1rem 2.6rem 1rem 0.6rem;
      width: calc(100% - 3.1rem);
      text-align: center;
      position: relative;

      svg {
        position: absolute;
        right: 0;
        padding: 0.3rem 0.6rem;
        top: 50%;
        margin-top: -12px;

        @media screen and (max-width: 990px) and (min-width: 768px) {
          right: 10px;
        }
      }
    }

    .card-content {
      display: flex;
      flex-flow: row wrap;
      flex: 1 1 100%;
      gap: 0.5rem;
      width: 100%;
      padding: 0.5rem 0 1.5rem 0;

      .search-inputs-container {
        width: 100%;
        padding: 0.5rem 1.5rem;

        & .reference-label {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          white-space: nowrap;

          & svg {
            path {
              @include themify($themes) {
                fill: themed('main');
              }
            }
          }
        }

        .text-input-container {
          box-shadow: none;
          border-radius: 0.5rem;

          .text-input {
            @include themify($themes) {
              background-color: themed('inputColor');
            }
          }
        }
      }
    }

    label {
      color: $black;
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 1.6rem;
      word-spacing: normal;
      display: flex;
      align-items: center;
      padding: 0;
      margin-bottom: 0.5rem;

      svg {
        margin-right: 0.5rem;

        @include themify($themes) {
          color: themed('main');
        }

        font-size: 2rem;
      }
    }
  }
}