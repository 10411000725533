@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.ReactModal__Content {
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.ReactModal__Content--after-open {
  @media only screen and (max-width: 768px) {
    border-radius: 0px;
  }

  @media screen and (max-width: 990px) and (min-width: 768px) {
    width: 90%;
    max-height: calc(100vh - 100px);
  }
}

.confirmation-modal {
  width: 56rem;
  @include themify($themes) {
    color: themed('main');
  }
  flex-direction: column;
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100vh;
    padding: 2rem;
    min-width: auto;
    margin-top: 20vh;
    box-sizing: border-box;
  }

  @media screen and (max-width: 990px) and (min-width: 768px) {
    width: 100%;
    height: 100vh;
    padding: 1rem;
    min-width: auto;
    margin-top: 0vh;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
  }

  .title {
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 3rem;
    @include themify($themes) {
      color: themed('main');
    }
    padding: 1.4rem 0;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;

    @media screen and (max-width: 768px) {
      font-size: 2rem;
    }
  }

  .message {
    font-size: 1.6rem;
    line-height: 2.1rem;
    margin-bottom: 3.2rem;
    display: flex;
    flex-direction: column;

    span {
      font-weight: bold;
    }
  }

  .actions {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding-bottom: 4rem;

    .no {
      width: 40%;
    }

    .yes {
      width: 40%;
    }

    .red {
      border-radius: 0.5rem;
      @include themify($themes) {
        background-color: themed('secondary');
      }
      border: 0.1rem solid $blue-text-color;
      @include themify($themes) {
        color: themed('main');
      }

      &:hover {
        background-color: $red;
        border: 0.1rem solid $red;
        @include themify($themes) {
    color: themed('secondary');
}
      }
    }

    .blue {
      border-radius: 0.5rem;
      @include themify($themes) {
        background-color: themed('secondary');
      }
      border: 0.1rem solid $blue-text-color;
      @include themify($themes) {
        color: themed('main');
      }

      &:hover {
        @include themify($themes) {
          color: themed('main');
        }
        @include themify($themes) {
    color: themed('secondary');
}
      }
    }
  }
}
