@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

// Overwrite styles
div.promotion-container.default-promotion {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  gap: 1rem;
  padding: 0 1.3rem;

  .promotion-image {
    flex: 0 0 auto;

    & img {
      max-width: 10rem;
      max-height: 10rem;
    }
  }

  .promotion-info {
    width: auto;
    flex: 1 1 auto;
    max-width: 32rem;

    .information {
      color: $black;
      font-size: 1.4rem;
      letter-spacing: 0;
      line-height: 1.8rem;
      min-width: 22rem;

      .description {
        font-weight: 800;
        margin-bottom: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        /* number of lines to show */
        -webkit-box-orient: vertical;
      }

      .availability {
        font-weight: 400;
        font-size: 1.4rem;
        margin-bottom: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        -webkit-box-orient: vertical;
      }
      .read-more {
        @include themify($themes) {
          color: themed('main');
        }
        cursor: pointer;
        &:hover {
          opacity: 75%;
        }
      }
    }
  }

  .promotion-submit {
    flex: 1 1 100%;
    min-width: 11rem;
    max-width: 16rem;
  }
}
