@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.message-supplier-info {
  min-height: 5rem;
  padding-bottom: 3rem;
  position: relative;

  .spinner {
    padding-top: 2rem;
  }

  .info-items {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 7rem;

    @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
      gap: 1rem;
      padding-left: 10%;
    }
  }
}
