@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.espace-tariffs-container {
  justify-content: center;
  align-items: center;
  padding: 0.9rem;

  .espace-tariffs-filter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .espace-filter-title {
      height: 3.4rem;
      @include themify($themes) {
        color: themed('main');
      }
      font-family: 'Open Sans';
      font-size: 2.5rem;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 3.4rem;
      text-align: center;
      margin-top: 3.2rem;
    }

    .espace-tariffs-search {
      margin-top: 2.4rem;
      width: 62rem;

      @media screen and (max-width: 768px) {
        width: 25rem;
      }
    }

    .espace-tariffs-checkbox-container {
      display: flex;
      justify-content: center;
      height: 1.9rem;
      width: 36.7rem;
      @include themify($themes) {
        color: themed('main');
      }
      font-family: 'Open Sans';
      font-size: 1.4rem;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 1.9rem;
      margin-left: 12.7rem;
      margin-right: 12.6rem;
      margin-top: 0.8rem;
      gap: 1rem;

      @media screen and (max-width: 768px) {
        width: 100%;
        white-space: nowrap;
      }
    }
  }

  .espace-tariffs-suppliers-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 6.4rem;
    width: 100%;
    justify-content: space-between;
    gap: 4rem;
    position: relative;

    .items {
      display: flex;
      flex-flow: wrap;
      flex-direction: row;
      text-align: center;
      align-items: center;
      justify-content: center;
      gap: 2.4rem;
      width: 100%;

      .no-data {
        font-size: 2.4rem;
        font-weight: bold;
        @include themify($themes) {
          color: themed('main');
        }
        opacity: 60%;
      }

      .tariff-item-container {
        width: 19rem;
        height: 12.8rem;
        margin-bottom: 2.6rem;

        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }

      .tariff {
        height: 13rem;

        .body {
          height: 100%;
          justify-content: space-between;

          .catalog-logo {
            padding-top: 1.3rem;

            @media screen and (max-width: 768px) {
              padding-top: initial;
            }
          }

          .item {
            height: 7rem;
            display: flex;
            align-items: center;
          }

          .file-name {
            width: 17rem;
            margin-bottom: 0rem;
            // padding-top: 1.9rem;
          }
        }
      }

      .item-container {
        width: 19rem;
        height: 12.8;

        .item {
          width: 14.4rem;
          max-height: 7rem;
          margin-top: 0rem;

          img {
            max-height: 8.3rem;
          }
        }
      }
    }
  }
}
