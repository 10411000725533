@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.automotor-tariff-card {
  display: flex;
  align-items: center;
  flex-direction: column;

  @include themify($themes) {
    background-color: themed('secondary-3');
  }

  border-radius: 1rem;
  min-width: 62rem;
  max-width: 62rem;
  position: relative;
  min-height: 20rem;

  @media screen and (max-width: 768px) {
    min-width: auto;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    min-width: auto;
    width: 100%;
  }

  & .no-data {
    font-size: 2.4rem;
    font-weight: bold;

    @include themify($themes) {
      color: themed('main');
    }

    opacity: 60%;
    margin-top: 5rem;
  }

  & .automotor-tariff-title {
    @include themify($themes) {
      color: themed('main');
    }

    font-size: 2.6rem;

    @media screen and (max-width: 768px) {
      font-size: 1.8rem;
    }
  }

  & .automotor-tariff-container {
    display: grid;
    gap: 1.8rem 7.2rem;
    padding: 1rem 1.5rem 3.4rem 4.1rem;
    width: 60rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    max-height: 30rem;
    overflow-y: auto;
    box-sizing: border-box;

    @media screen and (max-width: 768px) {
      width: auto;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: row;
    }



    & .no-data {
      white-space: nowrap;
      text-align: center;
      width: 100%;
    }

    & .automotor-tariff-logo-container {
      display: flex;
      align-items: flex-end;
      position: relative;
      width: 5rem;
      height: 9rem;

      & .tariff-logo-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }

      & .automotor-tariff-spinner {
        max-width: 8rem;
        max-height: 7rem;
        width: 100%;
        height: 100%;
      }

      & .automotor-tariff-logo {
        max-width: 8rem;
        max-height: 7rem;
        cursor: pointer;
      }

      & .automotor-tariff-name {
        @include themify($themes) {
          color: themed('main');
        }

        font-size: 1.4rem;
        cursor: pointer;
        text-align: center;
        white-space: nowrap;
      }
    }
  }
}