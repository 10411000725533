@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.delete-container {
  width: 55.3rem;
  margin: 4rem 2rem;

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 0rem;
    margin-top: 40%;
  }

  @media screen and (max-width: 990px) and (min-width: 768px) {
    width: 100%;
    margin: 0rem;
    margin-top: 40%;
  }

  &.small {
    width: 40rem;
    margin: 2rem 1rem;

    @media only screen and (max-width: 768px) {
      width: auto;
    }

    .title {
      font-size: 1.5rem;
      line-height: 2rem;

      @media screen and (max-width: 768px) {
        padding-top: 10rem;
        font-size: 1.5rem;
        line-height: 3rem;
      }
    }

    .confirm-buttons {
      @media screen and (max-width: 768px) {
        padding-top: 10rem;
      }

      .button-delete {
        width: 10rem;
        height: 3rem;
        font-size: 1.3rem;

        @media screen and (max-width: 768px) {
          width: calc(50% - 5rem);
        }

        .icon-edit {
          font-size: 1.3rem;
        }
      }

      .button-edit {
        width: 10rem;
        height: 3rem;
        font-size: 1.3rem;

        @media screen and (max-width: 768px) {
          width: calc(50% - 5rem);
        }

        .icon-delete {
          font-size: 1.3rem;
        }
      }
    }
  }

  .title {
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 2.5rem;
    letter-spacing: 0;
    line-height: 3.4rem;
    text-align: center;

    span {
      font-weight: bold;
    }

    @media screen and (max-width: 768px) {
      padding-top: 10rem;
      font-size: 2rem;
      line-height: 3rem;
    }
  }

  .spinner-info {
    position: relative;
    margin-top: 2.5rem;
  }

  .confirm-buttons {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 3rem;

    .button-edit {
      padding: 0.3rem;
      width: 10rem;
      height: 3.5rem;
      border: none;
      @include themify($themes) {
        background-color: themed('main');
      }
      border-radius: 0.8rem;
      cursor: pointer;
      color: $white;
      font-size: 1.8rem;

      &:hover {
        background-color: $blue-4;
      }

      .icon-edit {
        color: $white;
        font-size: 1.8rem;
      }
    }

    .button-delete {
      padding: 0.3rem;
      width: 10rem;
      height: 3.5rem;
      border: none;
      background-color: $red;
      border-radius: 0.8rem;
      font-size: 1.8rem;
      cursor: pointer;
      color: $white;

      &:hover {
        background-color: $red-2;
      }

      .icon-delete {
        color: $white;
        font-size: 1.8rem;
      }
    }
  }
}
