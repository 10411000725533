@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

@import 'assets/sass/variables';
@import 'src/assets/sass/themes';
@import 'assets/sass/tables';

.automotor-notifications-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 4rem 0rem;
  max-width: 132rem;
  width: 100%;
  margin: 0rem auto;

  & .notifications-container {
    width: 100%;

    & .notifications-all-read {
      @include themify($themes) {
        color: themed('main');
      }

      font-size: 1.5rem;
      text-align: end;
      margin-bottom: 1rem;

      & span {
        cursor: pointer;

        &:hover {
          opacity: 75%;
        }
      }
    }

    & .notifications-filter {
      height: 13rem;
      border-radius: 1rem;
      border: 1px solid #cfd9ee;
      margin-bottom: 2rem;
      margin-top: 2.5rem;

      @media screen and (max-width: 990px) {
        height: auto;
        padding-bottom: 1rem;
      }

      @media screen and (max-width: 768px) {
        height: auto;
        padding-bottom: 1rem;
      }

      & .notifications-filter-header {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2.1rem 2.4rem;

        & .filter-title {
          font-size: 1.4rem;

          @include themify($themes) {
            color: themed('main');
          }

          font-weight: bold;
        }

        & .filter-reliquats {
          display: flex;
          align-items: center;
          font-size: 1.2rem;

          @include themify($themes) {
            color: themed('main');
          }
        }
      }

      & .notifications-filter-body {
        display: flex;
        flex-direction: row;
        gap: 1.4rem;
        padding: 0 2.4rem;

        @media screen and (max-width: 990px) {
          flex-direction: column;
        }

        @media screen and (max-width: 768px) {
          flex-direction: column;
        }

        & .filter-btn {
          width: 100%;
        }

        & .notifications-filter-input-item {
          width: 100%;
          position: relative;

          & .notifications-react-picker-dropdown {
            cursor: pointer;

            &.disable {
              pointer-events: none;
            }
          }

          & .notifications-date-picker {
            position: absolute;
            z-index: 101;
            top: 5rem;

            @media screen and (max-width: 768px) {
              width: 100%;
            }

            & .react-datepicker {
              display: flex;
              position: relative;
              border: none;
              box-shadow: 0.5rem 0.8rem 2.1rem rgb(0 0 0 / 15%);
              font-size: 1.3rem;

              &__month-container {
                & .react-datepicker__header {
                  background: none;
                  border-bottom: none;
                  padding: 1.2rem 1rem;

                  & .react-datepicker__current-month {
                    font-size: 1.4rem;
                  }

                  & .react-datepicker__day-names {
                    .react-datepicker__day-name {
                      width: 3rem;
                    }
                  }
                }

                & .react-datepicker__week {
                  .react-datepicker__day {
                    width: 3rem;
                    line-height: 3rem;

                    &--in-range {
                      background-color: #d73c5a;
                    }

                    &--selected {
                      background-color: #d73c5a;
                    }

                    &--in-selecting-range {
                      background-color: #f5bec7;
                    }

                    &--keyboard-selected {
                      background-color: #d73c5a;
                    }
                  }
                }
              }

              .react-datepicker__month-container {
                @media screen and (max-width: 768px) {
                  width: 100%;
                }
              }
            }
          }

          & .select-dropdown {
            width: 100%;
            height: 4.2rem;
            border-radius: 0.5rem;
            font-size: 1.4rem;
            font-weight: 400;

            @include themify($themes) {
              color: themed('main');
            }

            border: none;

            &.disabled {
              opacity: 30%;
              pointer-events: none;
              user-select: none;
            }

            [class$='-control'] {
              @include themify($themes) {
                color: themed('main');
              }

              border: none;

              @include themify($themes) {
                background-color: themed('mainHover');
              }

              height: 4.2rem;
              box-shadow: none;

              &:hover {
                border: none;
              }
            }

            [class$='-placeholder'] {
              @include themify($themes) {
                color: themed('main');
              }

              font-size: 1.4rem;
              font-weight: 400;
            }

            [class$='-indicatorContainer'] {
              @include themify($themes) {
                color: themed('main');
              }
            }

            [class$='-singleValue'] {
              @include themify($themes) {
                color: themed('main');
              }
            }

            [class$='-indicatorSeparator'] {
              background: none;

              @include themify($themes) {
                color: themed('main');
              }
            }

            [class$='-indicator'] {
              @include themify($themes) {
                background: themed('main');
              }

              color: $white;
            }
          }
        }
      }
    }

    & .notifications-list {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      position: relative;
      min-height: 20rem;

      & .no-notifications {
        display: flex;
        align-items: center;
        justify-content: center;

        @include themify($themes) {
          color: themed('main');
        }

        font-size: 1.6rem;
        font-weight: bold;
        margin-top: 5rem;
      }
    }
  }
}