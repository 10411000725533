@import '../../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.container-client-amerigo {
  min-height: 100%;
  background-color: $white;

  .container-items {
    min-height: 100%;
    display: flex;
    margin: 0 auto;
    position: relative;
    flex-direction: column;
    max-width: 144rem;

    .spinner-big {
      svg {
        width: 6.75em;
      }
    }

    .extra-small {
      circle {
        @include themify($themes) {
          stroke: themed('main');
        }
        stroke-width: 7;
      }
    }

    .container-top-header {
      @include themify($themes) {
        background-color: themed('secondary-3');
      }
      display: flex;
      align-items: center;
      flex-direction: row;
      padding: 0 1rem;
      justify-content: space-between;
      margin-bottom: 3rem;

      .header-body {
        .header-icon {
          padding: 1rem;

          .icon {
            width: 2.5rem;
            height: 2.5rem;
            color: $yellow;
          }
        }

        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2.2rem;
        display: flex;
        align-items: center;
        text-align: center;
        @include themify($themes) {
          color: themed('main');
        }
        border-bottom: 1px solid $blue-5;
        padding: 1rem;
        @media screen and (max-width: 500px) {
          font-size: 1.2rem;
          line-height: 1.6rem;
        }
      }

      .header-btn {
        .header-btn-logout {
          width: 14.3rem;
          height: 3.8rem;
          @include themify($themes) {
            background: themed('main');
          }
          border-radius: 5px;
          color: $white;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          border: none;

          &:hover {
            opacity: 0.7;
            cursor: pointer;
          }
        }
      }
    }

    .container-top {
      margin: 0 1rem;

      .container-top-title {
        font-style: normal;
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 3.3rem;
        @include themify($themes) {
          color: themed('main');
        }
      }

      .container-top-filter {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 1rem;

        .filter-input {
          display: flex;
          flex-direction: row;

          .filter-item {
            width: 100%;
            display: flex;
            flex-direction: row;
            margin-right: 2rem;
            min-width: 21.5rem;

            .select-client-name {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 4.2rem;
              border-radius: 0.5rem;
              border: none;
              padding: 0 1rem;
              font-size: 1.6rem;
              font-weight: 400;
              @include themify($themes) {
                color: themed('main');
              }
              outline: none;
              transition: all 0.3s ease-in-out;
              background-color: $input-color;
              box-sizing: border-box;

              &::placeholder {
                color: rgba(39, 80, 159, 0.5);
                font-size: 1.4rem;
              }
            }

            .dropdown-label {
              font-weight: 800;
              font-size: 1.2rem;
            }
          }
        }

        .select-dropdown {
          height: 4.2rem;
          border-radius: 0.5rem;
          font-size: 1.4rem;
          font-weight: 400;
          @include themify($themes) {
            color: themed('main');
          }
          width: 100%;
          border: none;

          [class$='-control'] {
            @include themify($themes) {
              color: themed('main');
            }
            border: none;
            @include themify($themes) {
              background-color: themed('mainHover');
            }
            height: 4.2rem;
            box-shadow: none;

            &:hover {
              border: none;
            }
          }

          [class$='-placeholder'] {
            @include themify($themes) {
              color: themed('main');
            }
            font-size: 1.4rem;
            font-weight: 400;
          }

          [class$='-indicatorContainer'] {
            @include themify($themes) {
              color: themed('main');
            }
          }

          [class$='-singleValue'] {
            @include themify($themes) {
              color: themed('main');
            }
          }

          [class$='-indicatorSeparator'] {
            background: none;
            @include themify($themes) {
              color: themed('main');
            }
          }

          [class$='-indicator'] {
            @include themify($themes) {
              background: themed('main');
            }
            color: $white;
          }
        }

        .filter-button {
          width: 25rem;
          display: flex;
          justify-content: flex-end;

          .filter-item {
            width: 100%;
            display: flex;
            flex-direction: row;

            .select-dropdown {
              width: 400px;
              height: 4.2rem;
            }

            .select-dropdown__control.select-dropdown__control {
              width: 400px;
            }

            .dropdown-label {
              font-weight: 800;
              font-size: 1.2rem;
            }

            .filter-btn-search {
              width: 100%;
              height: 3.8rem;
              border: 1px solid $red;
              border-radius: 0.5rem;
              font-size: 1.6rem;
              line-height: 1.9rem;
              background: $red;
              color: $white;
              margin: 0 1rem;
            }

            .filter-btn-search:hover {
              cursor: pointer;
              opacity: 0.8;
            }

            .filter-btn-reset {
              width: 100%;
              height: 3.8rem;
              border: 1px solid $blue;
              border-radius: 0.5rem;
              font-size: 1.6rem;
              line-height: 1.9rem;
              @include themify($themes) {
                background: themed('main');
              }
              border-radius: 5px;
              color: $white;
              margin: 0 1rem;
            }

            .filter-btn-reset:hover {
              cursor: pointer;
              opacity: 0.8;
            }
          }
        }

        @media screen and (max-width: 768px) {
          flex-direction: column;
          .filter-input {
            margin-left: 1rem;
          }
          .filter-button {
            margin-top: 2rem;
          }
        }

        @media screen and (max-width: 500px) {
          div.filter-input {
            flex-direction: column;
            .filter-item {
              margin-top: 1rem;
            }
          }
        }
      }
    }

    .container-middle {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
      justify-content: center;
      margin: 2rem 0;
      position: relative;
      min-height: 40rem;
      width: 100%;
      align-items: center;

      .select-client-no-data {
        font-size: 2.4rem;
        font-weight: bold;
        @include themify($themes) {
          color: themed('main');
        }
        opacity: 60%;
      }

      & .spinner-client {
        position: absolute;
      }

      .client-item {
        position: relative;
        max-width: 32.5rem;
        height: 25rem;
        @include themify($themes) {
          background-color: themed('secondary-3');
        }
        border-radius: 1rem;
        margin-right: 1rem;
        margin-right: 0.7rem;

        .client-heder {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          width: 28.5rem;
          border-bottom: 1px solid $hovered-blue;
          margin: 0 auto;
          height: 8.4rem;

          .client-title {
            font-style: normal;
            font-weight: 700;
            font-size: 1.6rem;
            line-height: 1.9rem;
            @include themify($themes) {
              color: themed('main');
            }
            display: flex;
            width: 150px;

            .client-check {
              margin: 0rem 1rem;
            }
          }

          .client-icons {
            width: 5.2rem;
            height: 1.7rem;
            font-weight: 900;
            font-size: 1.95955rem;
            line-height: 2.2rem;

            display: flex;
            align-items: center;
            text-align: center;

            @include themify($themes) {
              color: themed('main');
            }
          }

          .client-disabled-icons {
            width: 5.2rem;
            height: 1.7rem;
            font-weight: 900;
            font-size: 1.95955rem;
            line-height: 2.2rem;

            display: flex;
            align-items: center;
            text-align: center;
            @include themify($themes) {
              color: themed('main');
            }

            g path {
              fill: #aabcdc;
              filter: invert(50%) sepia(150%) saturate(991%) hue-rotate(180deg) brightness(101%) contrast(83%);
            }
          }

          .client-icons:hover {
            cursor: pointer;
            opacity: 0.8;
          }

          .client-disabled-icons:hover {
            cursor: pointer;
            opacity: 0.8;
          }

          .teccom-container {
            position: absolute;
            top: 10px;
            right: 15px;
            .client-teccom {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              gap: 0.5rem;
            }

            .teccom-label {
              @include themify($themes) {
                color: themed('main');
              }
              width: 130px;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }

        .client-info {
          height: 9rem;
          width: 28.5rem;

          margin: 10px auto;

          .client-body {
            display: flex;
            align-items: center;
            text-align: center;
            flex-direction: row;
            justify-content: space-between;
            padding: 0.5rem 0;

            .client-lable {
              font-weight: 400;
              font-size: 1.4rem;
              line-height: 1.6rem;

              /* lightblue */
              color: #8494c0;
            }

            .client-content {
              font-weight: 500;
              font-size: 1.4rem;
              line-height: 1.6rem;
              text-align: right;

              /* blue */
              @include themify($themes) {
                color: themed('main');
              }
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              padding-left: 1rem;
            }
          }
        }

        .client-btn {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 28.5rem;
          height: 3.8rem;
          bottom: 2rem;
          margin: 0 2rem;
          border: 1px solid $blue-1;
          @include themify($themes) {
            background-color: themed('secondary-3');
          }
          border-radius: 1rem;
          font-size: 1.6rem;
          line-height: 1.9rem;
          @include themify($themes) {
            color: themed('main');
          }

          .container-spiner {
            margin: 0rem 0.3rem 0rem 0rem;
          }
        }

        .client-btn:hover {
          @include themify($themes) {
            background: themed('main');
          }
          color: $white;
          cursor: pointer;

          circle {
            stroke: $white;
            stroke-width: 7;
          }
        }
      }
    }

    .container-bootom {
      margin-top: auto;
    }
  }

  .spiner-client {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20rem;
  }
}
