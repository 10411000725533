@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.favorite-list-item {
  padding: 0 1rem;
  height: 6.8rem;
  display: flex;
  align-items: center;

  @include themify($themes) {
    border-bottom: 2px solid themed('favorite', 'bg');
  }

  .icon {
    svg {
      path {
        @include themify($themes) {
          fill: themed('favorite', 'bgFill');
        }
      }
    }

  }

  .title {
    font-size: 1.8rem;
    line-height: 3.8rem;
    flex-grow: 1;
    padding-left: 1rem;
    overflow: auto;
    margin-right: 5px;

    p {
      text-align: left;
      margin: 0;
    }
  }

  .status {
    .checkboxField {
      width: 0;
      height: 0;
      visibility: hidden;
    }

    svg {
      path {
        @include themify($themes) {
          fill: themed('favorite', 'bgFill');
        }
      }
    }

  }
}