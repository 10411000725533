@import "src/assets/sass/variables";
@import "src/assets/sass/themes";

.footer-padding .footer {
  padding-bottom: 11.8rem;
  z-index: -1;
}

.part-view {
  &.item-view-wrapper {
    padding: 2rem 0 0;
    background: $white;

    .small {
      position: absolute;
      top: 15rem;
    }

    .item-view-header,
    .item-view-content,
    .item-view-footer {
      padding: 0 2rem;
    }

    .item-view-header {
      margin-bottom: 1.5rem;
      margin-top: 3rem;

      .return-button {
        .returnButton {
          position: absolute;
          display: flex;
          align-items: center;
          gap: 1rem;
          font-size: 1.4rem;
          top: 4.3rem;

          @include themify($themes) {
            color: themed("main");
          }
        }
      }

      .mobile-part-item-references {
        align-items: center;

        .mobile-part-item-reference-wrapper {
          justify-content: center;
          align-items: center;
          display: flex;

          .mobile-part-item-reference {
            min-width: 12.5rem;
            height: 2.5rem;
            border-radius: 1.3rem;

            @include themify($themes) {
              background-color: themed("secondary-3");
            }

            @include themify($themes) {
              color: themed("main");
            }

            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 1.4rem;
            line-height: 1.9rem;
            padding-top: 0.5rem;
            padding-left: 0.8rem;
            padding-right: 0.8rem;

            & span {
              font-weight: 700;
            }
          }
        }

        .mobile-part-item-brand-reference {
          height: 2.8rem;
          font-weight: 700;
          font-size: 2rem;
          line-height: 2.8rem;
          align-items: center;
          text-align: center;
          color: #171818;
          margin-top: 0.5rem;
        }
      }

      .mobile-part-item-options {
        position: absolute;
        right: 3.4rem;
        top: 5rem;

        .spinner-container {
          position: absolute;
          top: -1rem;
          left: -0.5rem;
        }

        .iconButton {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: $white;
          border: none;
          cursor: pointer;
          color: #ca312a;
          gap: 0.625rem;
          font-size: 1.2rem;
          line-height: 1.4rem;
          text-align: center;

          svg {
            width: 4.225rem;
            height: 2.825rem;
            fill: #ca312a;
          }
        }
      }
    }

    .item-view-content {
      .image-gallery-content.bottom.fullscreen {
        .image-gallery-thumbnails-wrapper.bottom.thumbnails-swipe-horizontal {
          display: none;
        }
      }

      .image-gallery-content.bottom {
        .image-gallery-slide-wrapper.bottom {
          .image-gallery-bullets {
            display: none;
          }
        }

        .image-gallery-bullets {
          display: none;
        }

        .image-gallery-thumbnails-wrapper.bottom.thumbnails-swipe-horizontal {
          display: flex;
        }
      }

      .mobile-car-part-details {
        .car-detail__header__article {
          display: none;
        }

        .car-detail__header__title {
          display: none;
        }

        .car-detail__header__category {
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 1.9rem;
          color: $black;
        }

        .car-detail__header__set {
          font-weight: 700;
          font-size: 1.4rem;
          line-height: 1.9rem;

          @include themify($themes) {
            color: themed("main");
          }
        }

        .car-detail__content {
          padding-bottom: 2rem;
        }
      }

      .mobille-barcode-container {
        padding-bottom: 2rem;
      }

      .options {
        background: #f2f2f2;
        position: fixed;
        bottom: 0;
        z-index: 1;
        width: calc(100% - 4rem);
        left: 0;
        padding: 1.4rem 2rem;

        .car-part-options-wrapper {
          //padding-right: 3.5rem;

          .teccom {
            display: none;
          }

          .top {
            display: none;
          }

          .availability {
            justify-content: space-between;

            .quantity-disponibility {
              padding-left: 0rem;
              margin-left: 1rem;
            }

            .car-part-view-price {
              width: 110px;
              height: 38px;
              font-weight: 700;
              font-size: 28px;
              color: $black;

              .currency {
                font-weight: 700;
                font-size: 28px;
                padding-left: 0rem;
              }

              .price-ttc {
                position: absolute;
                top: 5rem;
                right: 2.5rem;
                width: 4.7rem;
                height: 1.1rem;
                font-weight: 700;
                font-size: 0.8rem;
                color: #8494c0;
              }
            }
          }

          .mobile-buttons {
            display: flex;
            margin-top: 1.5rem;
            width: 100%;
            // justify-content: space-between;
            justify-content: flex-end;

            .add-to-cart-button {
              display: flex;
              width: 15rem;
              height: 3.4rem;
              left: 18.8rem;
              top: 156.8rem;
              border-radius: 0.5rem;

              @include themify($themes) {
                background: themed("main");
              }

              justify-content: center;
              align-items: center;
              cursor: pointer;

              .spinner.extra-small.round-cart-spinner {
                width: 1.8rem;
                padding-left: 0rem;
                margin-left: 0rem;

                circle {
                  stroke: $white;
                }
              }

              .iconButton {
                height: 16px;
                font-weight: 900;
                font-size: 12px;

                @include themify($themes) {
                  background: themed("main");
                }

                color: $white;
                gap: 1rem;
              }
            }

            .add-to-quote-button {
              display: flex;
              width: 15rem;
              height: 3.4rem;
              left: 18.8rem;
              top: 156.8rem;
              border-radius: 0.5rem;

              @include themify($themes) {
                background: themed("btnHover");
              }

              justify-content: center;
              align-items: center;
              cursor: pointer;

              .iconButton {
                height: 1.6rem;
                font-weight: 900;
                font-size: 1.2rem;

                @include themify($themes) {
                  background: themed("btnHover");
                }

                color: $white;
                gap: 1rem;
              }
            }
          }
        }
      }
    }

    .item-view-footer {
      background: $background-grey;

      .equivalence-cars-part {
        padding: 3.5rem 0;

        .tabs-container {
          min-height: 28rem;

          .vehicle-selector-tabs {
            gap: 1rem;

            .tab-item {
              border-radius: 0;
              height: 3.9rem;
              background: #ffffff;
              padding: 0.4rem 0;

              &.active {
                border-bottom: 0.4rem solid #ca312a;
              }

              .icon {
                padding-top: 0.5rem;
              }
            }
          }
        }

        // @media screen and (max-width: 768px) {
        .equivalance-oem-container {
          .filters {
            .manufacturers {
              width: 100%;
              margin-left: 0rem;
              z-index: 0;

              .select-title {
                width: 18.6rem;
                height: 1.6rem;
                font-weight: 700;
                font-size: 1.2rem;
                color: $black;
              }

              [class$="-control"] {
                // div:first-child {
                height: 42px;
                background: #ffffff;
                /* box-shadow: 1px 1px 20px 0px; */
                border-radius: 5px;
                // }
              }

              .css-control {
                height: 42px;
                background: #ffffff;
                /* box-shadow: 1px 1px 20px 0px; */
                border-radius: 5px;
              }
            }
          }

          .list {
            padding: 0;

            .swiper-wrapper {
              z-index: auto;
              width: 100%;

              .swiper-container.items {
                z-index: -1;
                margin-top: 2.9rem;
                margin-bottom: 0;

                .swiper-slide {
                  //max-width: 30rem;
                  justify-content: initial;

                  .equivalence-oem-item {
                    background: $white;

                    .content {
                      display: flex;
                      padding: 0;
                      width: 17.5rem;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      gap: 0.5rem;
                      flex-direction: column;

                      .title {
                        color: $black;
                      }

                      .category {
                        color: $black;
                      }

                      .subtitle-client {
                        span {
                          font-weight: bold;
                        }

                        font-size: 1.2rem;
                        line-height: 1.7rem;
                        width: fit-content;
                        padding-left: 1rem;
                        padding-right: 1rem;
                        height: 2rem;

                        @include themify($themes) {
                          background-color: themed("secondary-3");
                        }

                        border-radius: 1.3rem;
                        margin-bottom: 0.3rem;
                      }

                      .subtitle {
                        width: fit-content;
                        padding-left: 1rem;
                        padding-right: 1rem;
                        height: 2rem;

                        @include themify($themes) {
                          background-color: themed("secondary-3");
                        }

                        border-radius: 1.3rem;
                      }
                    }
                  }
                }
              }
            }

            .swiper-container {
              .navigation.swiper-prev {
                display: none;
              }

              .navigation.swiper-next {
                display: none;
              }
            }
          }
        }

        // }

        // @media screen and (max-width: 990px) and (min-width: 768px) {
        .equivalance-oem-container {
          .filters {
            .manufacturers {
              width: 100%;
              margin-left: 0rem;

              .select-title {
                width: 18.6rem;
                height: 1.6rem;
                font-weight: 700;
                font-size: 1.2rem;
                color: $black;
              }

              [class$="-control"] {
                // div:first-child {
                height: 42px;
                background: #ffffff;
                /* box-shadow: 1px 1px 20px 0px; */
                border-radius: 5px;
                // }
              }

              .css-control {
                height: 42px;
                background: #ffffff;
                /* box-shadow: 1px 1px 20px 0px; */
                border-radius: 5px;
              }
            }
          }

          &.list {
            padding: 0rem;
            gap: 1rem;

            .swiper-wrapper {
              width: 102%;
              z-index: -2;

              .swiper-container.items {
                z-index: -1;
                margin-top: 2.9rem;
                margin-bottom: 0rem;

                .swiper-slide {
                  max-width: 30rem;

                  .equivalence-oem-item {
                    background: $white;

                    .content {
                      padding: 0rem;
                      width: 17.5rem;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;

                      .title {
                        color: $black;
                      }

                      .category {
                        color: $black;
                      }

                      .subtitle {
                        width: fit-content;
                        padding-left: 1rem;
                        padding-right: 1rem;
                        height: 2rem;

                        @include themify($themes) {
                          background-color: themed("secondary-3");
                        }

                        border-radius: 1.3rem;
                      }
                    }
                  }
                }
              }
            }

            .swiper-container {
              .navigation.swiper-prev {
                display: none;
              }

              .navigation.swiper-next {
                display: none;
              }
            }
          }
        }

        // }

        .vehicle-concern-wrapper {
          .tabs-container {
            position: unset;
            padding: 0rem;
            width: 100%;

            .vehicle-selector-tabs {
              position: unset;
              justify-content: flex-start;
              // position: absolute;
              // right: 5.5rem;
              // top: 0.5rem;

              .tab-item {
                background-color: #becbe3;
                border-bottom: 0.1rem solid #fff;
                border-radius: 1rem;

                &.active {
                  @include themify($themes) {
                    background-color: themed("main");
                  }
                }
              }
            }

            .content {
              .filters {
                display: flex;
                flex-direction: column;
                position: unset;
                height: 4rem;
                margin-top: 7rem;

                .manufacturers {
                  width: 100%;

                  .select-title {
                    font-weight: 700;
                    font-size: 1.2rem;
                    color: $black;
                  }

                  [class$="-control"] {
                    // div:first-child {
                    height: 42px;
                    background: #ffffff;
                    /* box-shadow: 1px 1px 20px 0px; */
                    border-radius: 5px;
                    // }
                  }

                  .css-control {
                    height: 42px;
                    background: #ffffff;
                    /* box-shadow: 1px 1px 20px 0px; */
                    border-radius: 5px;
                  }
                }
              }

              .list {
                margin-top: 5rem;

                .swiper-wrapper {
                  width: 102%;
                  z-index: 0;

                  .swiper-container.items {
                    z-index: -1;
                    margin-top: 1rem;
                    margin-bottom: 0rem;

                    .navigation.swiper-prev {
                      display: none;
                    }

                    .navigation.swiper-next {
                      display: none;
                    }

                    // .swiper-slide.swiper-slide-active{
                    //   margin-right: 0.5rem !important;

                    // @media screen and (max-width: 990px) {
                    //   .swiper-slide {
                    //     max-width: 34rem;
                    //   }
                    // }

                    .swiper-slide {
                      max-width: 30rem;

                      .swiper-slide-active {
                        margin-right: 0rem;
                      }

                      .vehicle-equivalence-item-list {
                        background: $white;
                        border-radius: 1rem;

                        .vehicle-equivalence-item {
                          height: 8.5rem;
                          border-radius: 1rem;

                          .content {
                            padding: 0rem;
                            width: 17.5rem;
                            white-space: nowrap;

                            .title {
                              color: $black;
                            }

                            // .category {
                            //   color: $black;
                            // }

                            .description {
                              width: fit-content;
                              // white-space:
                              height: 2rem;
                              white-space: normal;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
