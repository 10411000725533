@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.vehicle-header-wrapper {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  flex: 0 0 100%;
  flex-flow: column nowrap;
  text-align: center;
  padding: 2rem 0;
  gap: 1rem;

  @media screen and (max-width: 768px) {
    padding: 1rem 0;
  }

  .favourite-mobile {
    float: right;
    font-size: 1.8rem;
    line-height: 1.8rem;
    height: 4.7rem;
    margin: 0 1rem;
    display: flex;
    align-items: flex-end;

    @include themify($themes) {
      color: themed('main');
    }

    position: absolute;
    top: 0rem;
    right: 0rem;
  }

  a,
  a:focus,
  a:visited {
    @include themify($themes) {
      color: themed('main');
    }
  }

  .vehicle-header-container {
    position: relative;
    min-height: 5rem;

    @media screen and (max-width: 768px) {
      margin: 1rem;
    }

    .extra-small {
      min-height: 5.8rem;
    }

    .vehicle-details {
      position: absolute;
      left: 0;
      bottom: 0;

      @include themify($themes) {
        color: themed('main');
      }

      @media screen and (max-width: 768px) {
        width: 5rem;
        height: 5rem;
      }

      @media screen and (max-width: 1200px) and (min-width: 768px) {
        position: initial;
      }

      .info {
        height: 4.7rem;
        min-width: 13.2rem;
        max-width: 20rem;

        @include themify($themes) {
          border: 0.1rem solid themed('catalogVehicleTabs', 'border');
        }

        border-radius: 1rem;
        padding: 0.4rem;
        font-size: 1.4rem;
        line-height: 1.9rem;
        font-weight: bold;
        letter-spacing: 0;
        float: left;
        cursor: pointer;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 768px) {
          min-width: 5rem;
          min-height: 5rem;

          .label {
            display: none;
          }
        }

        svg {
          width: 100%;
        }

        .header {
          overflow: hidden;

          .title {
            float: left;
          }

          .close {
            float: right;
            height: 1.8rem;
            width: 3rem;
            font-size: 2rem;
            line-height: 2rem;
            text-align: center;
          }
        }
      }

      .favourite {
        float: right;
        font-size: 1.8rem;
        line-height: 1.8rem;
        height: 4.7rem;
        margin: 0 1rem;
        display: flex;
        align-items: flex-end;
      }
    }

    .vehicle-name {
      @include themify($themes) {
        color: themed('main');
      }

      font-size: 2.5rem;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 3.4rem;

      @media screen and (max-width: 768px) {
        font-size: 2rem;
        max-width: calc(100% - 15px);
      }
    }

    .vehicle-description {
      @include themify($themes) {
        color: themed('main');
      }

      font-size: 1.8rem;
      letter-spacing: 0;
      line-height: 2.4rem;
      padding: 0px 0px 0px 80px;

      @media screen and (min-width: 1200px) and (max-width: 1450px) {
        padding: 0px 0px 0px 130px;
      }

      @media screen and (max-width: 768px) {
        font-size: 1.1rem;
        padding: 0px 70px 0px 70px;
      }
    }
  }

  .vehicle-page-navbar {
    display: flex;
    justify-content: center;

    .navbar-item {

      @include themify($themes) {
        background-color: themed('secondary');
        border: 0.1rem solid themed('catalogVehicleTabs', 'border');
      }

      width: 25%;
      height: 3.4rem;

      .disabled {
        pointer-events: none;
        opacity: 50%;

        @include themify($themes) {
          background-color: themed('catalogVehicleTabs', 'disabled');
        }
      }

      a {
        line-height: 3.4rem;
        display: inline-block;
        font-size: 1.3rem;
        font-weight: 600;
        letter-spacing: 0;
        text-align: center;
        text-decoration: none;
        height: 100%;
        width: 100%;

        label {
          @media screen and (max-width: 768px) {
            display: none;
          }
        }
      }

      a.active {

        @include themify($themes) {
          background-color: themed('main');
          outline: 0.1rem solid themed('main');
          color: $white;
        }

      }

      &:not(:last-of-type) {
        border-right: none;
      }
    }
  }

  .vehicle-info-container {
    position: absolute;
    top: 100%;
    overflow: hidden;
    z-index: 14;
    left: 0;
  }
}