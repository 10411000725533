@import '../../assets/sass/variables';
@import 'src/assets/sass/themes';

.container_platform_admin_message {


  .wrapper_meessage {
    background-color: $white;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .row-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      width: 100%;
      margin-top: 20px;
      flex-direction: column;
      padding: 0 5rem;
      box-sizing: border-box;

      .search {
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        gap: 1rem;

        .select-supplier {
          width: 100%;
          height: 4.2rem;
          border-radius: 0.5rem;
          font-size: 1.4rem;
          font-weight: 400;

          @include themify($themes) {
            color: themed('main');
          }

          border: none;

          &:focus {
            outline: none;
          }

          [class$='-control'] {
            @include themify($themes) {
              color: themed('main');
            }

            border: none;

            @include themify($themes) {
              background-color: themed('mainHover');
            }

            height: 4.2rem;
            box-shadow: none;

            &:hover {
              border: none;
            }
          }

          [class$='-placeholder'] {
            @include themify($themes) {
              color: themed('main');
            }

            font-size: 1.4rem;
            font-weight: 400;
          }

          [class$='-indicatorContainer'] {
            @include themify($themes) {
              color: themed('main');
            }
          }

          [class$='-singleValue'] {
            @include themify($themes) {
              color: themed('main');
            }
          }

          [class$='-indicatorSeparator'] {
            background: none;

            @include themify($themes) {
              color: themed('main');
            }
          }

          [class$='-indicator'] {
            @include themify($themes) {
              background: themed('main');
            }

            color: $white;
          }
        }

        .icon {
          @include themify($themes) {
            color: themed('main');
          }

          width: 20px;
          height: 20px;
          position: absolute;
          top: 8px;
          right: 10px;
          cursor: pointer;
        }
      }

      h1 {
        @include themify($themes) {
          color: themed('main');
        }
      }

      button {
        color: $white;

        @include themify($themes) {
          background-color: themed('main');
        }

        width: 200px;
        border-radius: 10px;
        padding: 10px;
        border: none;
        cursor: pointer;
      }
    }

    .header-row {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 100px;
      width: 100%;
      margin-top: 20px;

      .search {
        width: 230px;
        display: flex;
        align-items: center;
        position: relative;

        .select-supplier {
          width: 100%;
          height: 4.2rem;
          border-radius: 0.5rem;
          font-size: 1.4rem;
          font-weight: 400;

          @include themify($themes) {
            color: themed('main');
          }

          border: none;

          &:focus {
            outline: none;
          }

          [class$='-control'] {
            @include themify($themes) {
              color: themed('main');
            }

            border: none;

            @include themify($themes) {
              background-color: themed('mainHover');
            }

            height: 4.2rem;
            box-shadow: none;

            &:hover {
              border: none;
            }
          }

          [class$='-placeholder'] {
            @include themify($themes) {
              color: themed('main');
            }

            font-size: 1.4rem;
            font-weight: 400;
          }

          [class$='-indicatorContainer'] {
            @include themify($themes) {
              color: themed('main');
            }
          }

          [class$='-singleValue'] {
            @include themify($themes) {
              color: themed('main');
            }
          }

          [class$='-indicatorSeparator'] {
            background: none;

            @include themify($themes) {
              color: themed('main');
            }
          }

          [class$='-indicator'] {
            @include themify($themes) {
              background: themed('main');
            }

            color: $white;
          }
        }

        .icon {
          @include themify($themes) {
            color: themed('main');
          }

          width: 20px;
          height: 20px;
          position: absolute;
          top: 8px;
          right: 10px;
          cursor: pointer;
        }
      }

      h1 {
        @include themify($themes) {
          color: themed('main');
        }
      }

      button {
        color: $white;

        @include themify($themes) {
          background-color: themed('main');
        }

        width: 200px;
        border-radius: 10px;
        padding: 10px;
        border: none;
        cursor: pointer;
      }
    }

    .messages {
      min-height: 50rem;
      position: relative;
      width: 100%;

      .content {
        .no-messages {
          margin: 0 auto;
          padding: 2rem;
          text-align: center;

          @include themify($themes) {
            color: themed('main');
          }

          p {
            font-size: 3rem;
            line-height: 4rem;
          }
        }
      }
    }

    ul.pagination-container {
      padding: 0 !important;
    }
  }
}