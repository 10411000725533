@import 'assets/sass/variables';
@import 'src/assets/sass/themes';
@import 'assets/sass/_mixins';

.public-navbar-container {
  .public-navbar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @include themify($themes) {
      background-color: themed('secondary');
    }
    height: 5rem;
    @media screen and (max-width: 991px) {
      justify-content: center;
    }
    & .logos {
      margin-left: 1rem;
      display: flex;
      align-items: center;
      height: 100%;
      gap: 1rem;
    }
  }
}
