@import './variables';

$themes: (
  default: (main: $blue-1,
    main-2: $blue-1,
    bgApp: $white,
    secondary: $white-1,
    secondary-2: $white-5,
    secondary-3: $white-3,
    secondary-darker: $blue-22,
    light-main: $light-blue,
    bgNavbarBelow: $white-4,
    bgActive: $blue-icon-active-fill,
    inputColor: $input-color,
    inputPlaceholderColor: rgba(39, 80, 159, 0.5),
    mainHover: $blue-3,
    disabled: $blue-icon-inactive-fill,
    border: $blue-1,
    tabInactive: $blue-5,
    tabActive: $blue-1,
    tabInactiveColor: $white,
    tabActiveColor: $white,
    iconTextItemActive: $blue-15,
    cellTableLast: $blue-5,
    bgTable: $white-5,
    bgCustomDropdown: $hovered-blue,
    btnHover: $blue-5,
    btnCloseBg: $blue-4,
    bgSpinner:$white,
    disabledIconAccount: $blue-icon-inactive-fill,
    iconClose: $blue-1,
    iconFill: $blue-1,
    separatorLine: $blue-14,
    pngColorFilter: unset,
    colorIconMyAccount:$blue-13,
    bgMiniDropdown:$blue-5,
    mainMobile:$white-1,
    navbarIconMobile:$blue,
    catalogBtn: (icon: $blue-1,
      inactive: $white-3,
      active: $blue-1,
      textInactive: $blue-1,
      textActive: $white,
    ),
    navbarMecadepot: (bg: $white-1,
      color: $blue-1,
      colorActive: $blue-17,
      active: $blue-1,
    ),
    slidingCart: (bg: $white-1,
      text: $blue-1,
      tableRow: $white-10,
      tableRow-2: $white-3,
      tableRow-3: $white-5,
      color:$blue-1 ),
    generalSearch: (bg: $hovered-blue,
      text: $blue-1,
      bgTable: $blue-5,
      bgTableHover: $blue-4,
      border: $blue-5,
    ),
    roundBtn: (bg: $white-3,
      bgIcon: $white-1,
      hover: $blue-1,
      text: $white-3,
      textHover: $blue-1,
    ),
    table: (bg: $white-5,
      cellColor:$white-3,
      hover:$blue-icon-active-fill,
      cellTableLast: $blue-5,
      footer: $blue-14,
      border: $blue-9 ),
    filter: (border: $blue-5 ),
    my-profile: (bg:$hovered-blue ),
    categoriesSidebar: (evenItem: #e0e6ef,
      oddItem: #e4ebf7,
      image: #d3ddf0,
      text: #778cb3,
      active: $blue-1,
    ),
    catalogVehicleTabs: (disabled: $blue-14,
      text: $blue-1,
      active: $blue-1,
      border: #d7e5ff,
    ),
    mySupplier: (bgMessage: $grey-2, ),
    myProfile : (disable: $white-5),
    favorite :(selectTab: $background-color-2,
      border: $blue-5,
      colorFolder: $blue,
      bgFill:$blue,
      bg:$background-color-2),
    searchSvg:(fill1:$blue,
      fill2:$blue-23 ,
      fill3:$blue-24,
      fill4:$red-5)),
  mecadepot: (main: $black,
    main-2: $red,
    bgApp: $white,
    secondary: $grey-11,
    secondary-2: $grey-11,
    secondary-3: $grey-11,
    light-main: $grey-12,
    secondary-darker: $grey-12,
    bgNavbarBelow: $white-4,
    bgActive: $blue-2,
    inputColor: $gray,
    inputPlaceholderColor: $gray-10,
    mainHover: $gray,
    disabled: $gray-o75,
    border: $black,
    tabInactive: $black,
    tabActive: $red,
    tabInactiveColor: $white,
    tabActiveColor: $white,
    iconTextItemActive: $blue-15,
    cellTableLast: $blue-5,
    bgTable: $white-5,
    bgCustomDropdown: $grey-11,
    btnHover: $red,
    btnCloseBg: $grey-11,
    bgSpinner:$gray,
    disabledIconAccount: $gray,
    iconFill:$black,
    iconClose:$black,
    separatorLine: $grey-12,
    pngColorFilter: grayscale(1),
    colorIconMyAccount:$grey-14,
    bgMiniDropdown:$gray,
    mainMobile:$black,
    navbarIconMobile:$white,
    catalogBtn: (icon: $black,
      inactive: $black,
      active: $red,
      textInactive: $white,
      textActive: $white,
    ),
    navbarMecadepot: (bg: $black,
      color: $white,
      colorActive: $white,
      active: $red,
    ),
    slidingCart: (bg: $grey-11,
      text: $black,
      tableRow:$black,
      tableRow-2: $grey-3-o75,
      tableRow-3: $grey-5,
      color:$white ),
    generalSearch: (bg: $grey-11,
      text: $black,
      bgTable: $grey-5,
      bgTableHover: $grey-3-o75,
      border: $gray-10,

    ),
    roundBtn: (bg: $grey-11,
      bgIcon: $grey-3-o75,
      hover: $red,
      text: $white,
      textHover: $black,
    ),
    table: (bg: $grey-11,
      cellColor:$gray,
      hover: $grey-3-o75,
      cellTableLast: $grey-12,
      footer: $grey-12,
      border: $grey-3-o75 ),
    filter: (border: $gray ),
    my-profile: (bg: $gray),
    categoriesSidebar: (evenItem: $grey-5,
      oddItem: $grey-3-o75,
      image: $grey-12,
      text: $black,
      active: $red,
    ),
    catalogVehicleTabs: (disabled: $gray-o75,
      border: $grey-12,
      text: $black,
      active: $red,
    ),
    mySupplier: (bgMessage: $gray ),
    myProfile : (disable: $gray),
    favorite :(selectTab: $gray,
      border: $grey-11,
      colorFolder: $black,
      bgFill:$black,
      bg:$gray), searchSvg: (fill1:$black,
      fill2:$gray,
      fill3:$grey-13,
      fill4:$red )),
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: (
        ) !global;

      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}'
        );
      $theme-map: map-merge($theme-map,
          ($key: $value,
          )) !global;
    }

    @content;
    $theme-map: null !global;
  }
}
}

@function themed($key, $subkey: null) {
  @if not map-has-key($theme-map, $key) {
    @error "Key '#{$key}' does not exist in theme map.";
    @return null; // or handle error accordingly
  }

  $value: map-get($theme-map, $key);

  @if $subkey {
    @if not map-has-key($value, $subkey) {
      @error "Subkey '#{$subkey}' does not exist for key '#{$key}' in theme map.";
      @return null; // or handle error accordingly
    }

    @return map-get($value, $subkey);
  }

  @else {
    @return $value;
  }
}