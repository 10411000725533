.car-part-barcode {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 15rem;

  & > img {
    width: 100%;
    height: 8rem;
  }
  span {
    font-size: 1.8rem;
  }
}
