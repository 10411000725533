@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.proforma-mapping-column-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: relative;

  & > .exchange-icons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 3rem;

    & > i {
      font-size: 4rem;
      cursor: pointer;

      &:hover {
        transform: scale(1.3);
        transition: transform 0.3s;
      }
    }
  }

  .proforma-mapping-column {
    flex: 1;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    margin: 0 3rem;
    max-width: 100rem;
    min-height: 49.6rem;
    top: 0;
    background-color: #eaf0fb;

    &-title {
      padding-bottom: 1.3rem;
      font-weight: 500;
      font-size: 2.4rem;
      @include themify($themes) {
        color: themed('main');
      }
    }

    &-item-list {
      padding: 0.8rem;
      background-color: #eaf0fb;
      flex-grow: 1;
      min-height: 10rem;
    }
  }
}

.proforma-custom-mapping {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 2.7rem;
}

.spinner-table-data {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  position: absolute;
  backdrop-filter: blur(0.25rem);
}
