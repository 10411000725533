@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.oils-filters-catalog {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 0;
  width: 100%;

  @include themify($themes) {
    background-color: themed('secondary-2');
  }

  gap: 1rem;
  padding-bottom: 5.2rem;
  z-index: 30;
  bottom: -200%;
  transition: bottom 0.3s;

  @media screen and (max-width: 768px) {
    height: calc(100% - 72px);
    padding-bottom: 0rem;
  }

  &.active {
    bottom: 0;
  }

  & .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 1.5rem 0 1.5rem;

    & .buttons-container {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 0.5rem;
    }

    & .icon {
      font-size: 2rem;
      font-weight: bold;

      @include themify($themes) {
        color: themed('main');
      }

      cursor: pointer;
    }
  }

  & .title {
    display: flex;
    justify-content: center;
    font-size: 2.3rem;
    font-weight: 300;
    gap: 0.5rem;
  }

  & .filters {
    display: block;
    gap: 1.5rem;
    padding: 0 5rem;
    margin-bottom: 4rem;
    overflow: auto;

    & .container-label {
      font-weight: bold;
      font-size: 1.4rem;

      @include themify($themes) {
        color: themed('main');
      }

      margin: 1rem 0;
      border-bottom: 0.1rem solid $blue-5;
      padding-bottom: 0.5rem;
      text-align: center;
    }

    & .dropdowns-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1.1rem;
      align-items: flex-end;
      width: 100%;

      @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: initial;
      }

      & .dropdown-label {
        font-weight: 800;
        font-size: 1.2rem;
        line-height: 20px;

        @include themify($themes) {
          color: themed('main');
        }
      }

      & .parts-filter-select-dropdown {
        @include themify($themes) {
          color: themed('main');
        }

        border-radius: 0.5rem;
        font-size: 1.4rem;
        font-weight: 400;

        &.disabled {
          opacity: 30%;
          pointer-events: none;
          user-select: none;
        }

        [class$='-control'] {
          @include themify($themes) {
            color: themed('main');
          }

          border: none;
          background-color: $white;
          box-shadow: none;

          &:hover {
            border: none;
          }
        }

        [class$='-placeholder'] {
          @include themify($themes) {
            color: themed('main');
          }

          font-size: 1.4rem;
          font-weight: 400;
        }

        [class$='-indicatorContainer'] {
          @include themify($themes) {
            color: themed('main');
          }
        }

        [class$='-singleValue'] {
          @include themify($themes) {
            color: themed('main');
          }
        }

        [class$='-indicatorSeparator'] {
          background: none;

          @include themify($themes) {
            color: themed('main');
          }
        }

        [class$='-indicator'] {
          @include themify($themes) {
            background: themed('main');
          }

          color: $white;
        }

        .checkbox-select-container {
          display: flex;
          min-width: 23rem;
          padding: 0;
          justify-content: flex-start;
          width: 100%;
          margin-left: -10px;

          .checkbox-item {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            font-size: 1.4rem;

            @include themify($themes) {
              color: themed('main');
            }

            align-items: center;
            gap: 0.5rem;
            margin-left: 1rem;
            margin-bottom: 1rem;

            & .checkbox {
              display: flex;
              align-items: center;
              gap: 0.5rem;
            }

            input[type='checkbox'] {
              appearance: none;
              background-color: $white;
              margin: 0;
              font: inherit;

              @include themify($themes) {
                color: themed('main');
              }

              width: 1.15em;
              height: 1.15em;
              border: 0.15em solid $blue-1;
              border-radius: 0.15em;
              transform: translateY(-0.075em);
              display: grid;
              place-content: center;
              outline: none;
            }

            input[type='checkbox']::before {
              content: '';
              width: 0.65em;
              height: 0.65em;
              transform: scale(0);
              transition: 120ms transform ease-in-out;
              box-shadow: inset 1em 1em $blue-1;
              transform-origin: bottom left;
              clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
            }

            input[type='checkbox']:checked::before {
              transform: scale(1);
            }
          }
        }
      }
    }

    .dropdowns-container-attributes-criteria {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 1.1rem;
      align-items: flex-end;
      width: 100%;
      max-height: 14rem;
      overflow-y: auto;

      [class$='-control'] {
        @include themify($themes) {
          color: themed('main');
        }

        border: none;
        background-color: $white;
        box-shadow: none;

        &:hover {
          border: none;
        }
      }

      [class$='-placeholder'] {
        @include themify($themes) {
          color: themed('main');
        }

        font-size: 1.4rem;
        font-weight: 400;
      }

      [class$='-indicatorContainer'] {
        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-singleValue'] {
        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-indicatorSeparator'] {
        background: none;

        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-indicator'] {
        @include themify($themes) {
          background: themed('main');
        }

        color: $white;
      }
    }

    .dropdown-second-label {
      font-weight: 800;
      font-size: 1.2rem;
      line-height: 20px;

      @include themify($themes) {
        color: themed('main');
      }
    }
  }

  & .apply-btn {
    align-self: center;
    width: 24.3rem;

    @media screen and (max-width: 768px) {
      padding-bottom: 2rem;
    }
  }
}