@import "assets/sass/variables";
@import "src/assets/sass/themes";

.client-view-content-wrapper {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;

  .input-disabled {
    opacity: 0.6;
    pointer-events: none;
    cursor: not-allowed;
  }

  .separator {
    border: 1px $grey-3-o75 solid;
    bottom: 15px;
    position: relative;
    width: 100%;
  }

  .client-title {
    color: $blue-text-color-o47;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 0;
    height: 7px;
  }

  .top-content {
    display: flex;
    justify-content: center;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    .title-back {
      @include themify($themes) {
        color: themed("main");
      }
    }

    .title {
      @include themify($themes) {
        color: themed("main");
      }
      font-size: 30px;
      line-height: 41px;
      flex: 1.5;

      @media screen and (max-width: 768px) {
        font-size: 20px;
        text-align: center;
      }

      @media screen and (min-width: 768px) and (max-width: 990px) {
        font-size: 25px;
      }
    }

    .return-button-wrapper {
      display: flex;
      align-items: center;
      gap: 5px;
      flex: 1;

      .button {
        width: 16px;
        height: 16px;
        fill: $blue-icon-color;
        cursor: pointer;
      }

      .title {
        font-size: 11px;
        line-height: 15px;
      }
    }
  }

  .middle-content {
    .client-wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;

      .client-box {
        position: relative;
        border-radius: 10px;
        @include themify($themes) {
          background: themed("secondary-2");
        }
        border: 5px $white-5 solid;
        width: 100%;
        height: 5.5rem;
        display: flex;
        justify-content: center;
        margin-top: 1.5rem;

        .edit-icon {
          position: absolute;
          top: 1px;
          right: 3px;
          @include themify($themes) {
            color: themed("main");
          }
          cursor: pointer;
        }

        .name-wrapper {
          flex: 1;
          display: grid;
          align-content: center;
          margin-left: 1.313rem;

          .client-name {
            font-size: 12px;
            @include themify($themes) {
              color: themed("main");
            }
            margin: 0;
          }

          .client-name-value {
            @extend .client-name;
            font-weight: 400;
          }

          &--m {
            @extend .name-wrapper;
            flex: 2.5;
          }
        }
      }
    }

    .contact-wrapper {
      @extend .client-wrapper;
      flex: 1.5;

      .contact-title {
        @extend .client-title;
      }
    }

    .button-container {
      display: flex;
      justify-content: flex-end;

      .new-contact {
        @include themify($themes) {
          background-color: themed("main");
        }
        border: 10px $blue-icon-color solid;
        border-radius: 10px;
        color: white;
        text-align: center;
        font-weight: 600;
        font-size: 1.45rem;
        cursor: pointer;
        width: 150px;
        margin-top: 2rem;
        margin-bottom: -2rem;
      }
    }
  }

  .parameters-title {
    @extend .client-title;
    height: 20px;
    align-self: center;
  }

  .parameters-wrapper {
    display: flex;
    flex-direction: row;
    gap: 100px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    .subtitle {
      @include themify($themes) {
        color: themed("main");
      }
      font-weight: 900;
      font-size: 14px;
    }

    .parameters {
      flex: 1;

      .list-wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-bottom: 1rem;

        .item-wrapper {
          & .item-date-picker {
            margin-left: 2rem;
            &.rmi-date-picker {
              margin-left: 1rem;
            }
          }

          &.registration {
            display: flex;
            flex-direction: column;
            position: relative;
          }

          &.rmi_modules {
            display: flex;
            align-items: center;
            position: relative;
          }

          input[type="checkbox"] {
            cursor: pointer;
            @include themify($themes) {
              color: themed("main");
            }
          }

          label {
            font-size: 14px;
            line-height: 30px;
            @include themify($themes) {
              color: themed("main");
            }
          }
        }
        .rmi-settings-select {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          align-items: center;
        }

        .settings-select {
          padding: 0;
          width: 100%;
          margin-top: -0.5rem;

          &.rmi-settings {
            display: flex;
          }

          &.disabled {
            .radio input {
              pointer-events: none;
            }
          }

          .radio {
            position: relative;
            margin-left: 2rem;
            margin-top: 0.8rem;

            input {
              z-index: 2;
              cursor: pointer;
              position: absolute;
              width: 1.5em;
              height: 1.5em;
              opacity: 0;

              + label {
                &:before {
                  content: "";
                  background: $blue-10;
                  border-radius: 100%;
                  border: 2px solid $blue;
                  display: inline-block;
                  position: relative;
                  margin-right: 0.5em;
                  vertical-align: top;
                  text-align: center;
                  width: 0.8em;
                  height: 0.8em;
                }
              }

              &:checked {
                + label {
                  &:before {
                    @include themify($themes) {
                      background-color: themed("main");
                    }
                  }
                }
              }
            }

            label {
              font-size: 1.4rem;
              line-height: 1.9rem;
              margin-bottom: 0.5rem;
              padding-left: 0.4rem;
              @include themify($themes) {
                color: themed("main");
              }
            }

            .check-icon {
              position: absolute;
              left: 8px;
              top: 4px;
              color: $blue-10;
              font-size: 8px;
            }
          }
        }
      }
    }
  }

  .actions-wrapper {
    display: flex;
    justify-content: space-between;
    max-height: 35px;
    margin: 3rem 0;

    .button-wrapper {
      display: flex;
      align-items: center;
      gap: 15px;
      @include themify($themes) {
        background: themed("secondary-2");
      }
      padding: 15px;
      border-radius: 10px;
      font-size: 14px;
      @include themify($themes) {
        color: themed("main");
      }
      font-weight: 400;
      cursor: pointer;

      &--apply {
        display: flex;
        align-items: center;
        @include themify($themes) {
          background-color: themed("main");
        }
        border-radius: 10px;
        padding: 10px;
        font-size: 14px;
        color: $white;
        cursor: pointer;
      }

      .discard-icon {
        font-size: 20px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .middle-content .contact-wrapper .client-box {
      background-color: $gray-6;
      height: 10.5rem;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

    .middle-content .client-wrapper .client-box {
      background-color: $gray-6;

      .edit-icon {
        margin: 5px;
      }
    }

    .middle-content .button-container .new-contact {
      margin-bottom: 1rem;
    }

    .parameters-wrapper {
      gap: 30px;
    }
  }

  @media screen and (max-width: 500px) {
    .middle-content .contact-wrapper .client-box {
      display: grid;
      grid-template-columns: 1fr 1fr;
      height: 19.5rem;
    }

    .parameters-wrapper {
      gap: 10px;

      .subtitle {
        font-size: 12px;
      }

      .parameters .list-wrapper {
        .settings-select {
          min-width: fit-content;
        }

        .item-wrapper label {
          font-size: 11px;
        }
      }
    }

    .actions-wrapper {
      gap: 10px;

      .button-wrapper {
        font-size: 11px;
        background-color: $gray-6;
      }

      .button-wrapper--apply {
        font-size: 11px;
        text-align: center;
      }
    }
  }
}

.modal-success-proforma {
  padding: 30px;
  text-align: center;

  h1 {
    @include themify($themes) {
      color: themed("main");
    }
  }

  h2 {
    @include themify($themes) {
      color: themed("main");
    }
  }
}

.modal-footer {
  padding: 1.5rem;
  text-align: right;
  border-top: 0.2rem solid $blue-2;

  @media screen and (max-width: 768px) {
    text-align: center;
  }

  .btn-close {
    color: $white;
    @include themify($themes) {
      background-color: themed("main");
    }
    border: none;
    height: 4.2rem;
    width: 17.6rem;
    border-radius: 0.5rem;
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0.5rem;
    cursor: pointer;
  }

  .btn-submit {
    @include themify($themes) {
      color: themed("main");
    }
    background-color: $white;
    border: 1px solid $blue;

    height: 4.2rem;
    width: 17.6rem;
    border-radius: 0.5rem;
    font-size: 1.4rem;
    font-weight: 700;
    margin: 0.5rem;

    font-style: normal;
  }

  .btn-submit:hover,
  .btn-close:hover {
    background-color: $light-blue;
    color: $white;
  }
}
