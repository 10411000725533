@import 'assets/sass/variables';
@import 'src/assets/sass/themes';
@import 'assets/sass/tables';

.stock-price {
  @media screen and (max-width: 768px) {
    width: 100%;
  }

  .stock-header {
    @include themify($themes) {
      color: themed('main');
    }
    display: flex;
    flex-direction: row;
    margin-top: 3.3rem;
    margin-bottom: 3.3rem;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }

    @media screen and (max-width: 990px) and (min-width: 768px) {
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }

    .title {
      @include themify($themes) {
        color: themed('main');
      }
      font-size: 2.5rem;
      width: 59rem;

      @media only screen and (max-width: 768px) {
        display: flex;
        width: 100%;
        justify-content: center;
      }

      @media screen and (max-width: 990px) and (min-width: 768px) {
        width: auto;
      }
    }

    .btn-new {
      button {
        height: 3.5rem;
        width: 24.1rem;
        border-radius: 8px;
        background-color: $blue-13;
        border: none;
        cursor: pointer;
        color: $white;
        font-size: 1.4rem;
        font-weight: bold;

        &:hover {
          @include themify($themes) {
            background-color: themed('main');
          }
        }
      }
    }

    .filter {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 60.5rem;

      @media only screen and (max-width: 768px) {
        justify-content: center;
        width: 100%;
        align-items: center;
      }

      @media screen and (max-width: 990px) and (min-width: 768px) {
        width: auto;
      }

      .title-filter {
        font-size: 1.1rem;
        padding: 1rem 2rem;

        @media only screen and (max-width: 768px) {
          display: none;
        }

        @media screen and (max-width: 990px) and (min-width: 768px) {
          display: none;
        }
      }

      .btn-filter {
        &.active {
          pointer-events: none;
        }

        button {
          height: 3.5rem;
          width: 24.1rem;
          border-radius: 8px;
          @include themify($themes) {
            background-color: themed('main');
          }
          border: none;
          cursor: pointer;
          color: $white;
          font-size: 1.4rem;
          font-weight: bold;

          &:hover {
            background-color: $blue-15;
            @include themify($themes) {
              color: themed('main');
            }
          }
        }
      }
    }
  }

  & .stock-table {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    min-height: 20rem;

    & .sellers-table-spinner {
      position: absolute;
      background: none;
    }
  }
}
