@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.collapse-list {
  & .item {
    font-size: 1.4rem;
    font-weight: bold;
    cursor: pointer;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.level-2 {
      margin-top: 1rem;
      padding-left: 2rem;
    }

    &.last-level {
      margin-top: 1rem;
      padding-left: 6rem;
    }

    &.active {
      color: $white;
      @include themify($themes) {
        background-color: themed('main');
      }
      padding: 0.5rem 1rem;
      border-radius: 10rem;
      box-sizing: border-box;
      width: fit-content;
      pointer-events: none;
    }

    &.no-pointer-events {
      pointer-events: all;
    }

    &:hover {
      opacity: 80%;
    }

    & .collapse-item {
      display: flex;
      align-items: center;
      gap: 2rem;
    }

    & .iframe-info-btn {
      @include themify($themes) {
        background-color: themed('main');
      }
      color: $white;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

/* Mobile CSS Styles */

.app-layout-mobile {
  .tecrmi-modules {
    .content {
      .items {
        & > div[class^='collapse-list'] {
          & > .item {
            padding-left: 0;
            padding-right: 0;

            .collapse-item {
              gap: 1rem;
            }
          }
        }
      }
    }
  }
}
