@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.universal-dropdown-content {
  position: absolute;
  border-radius: 1rem;
  margin-top: 0.2rem;
  z-index: 2;
  box-shadow: 0.2rem 0.2rem 1rem rgb(1 1 1 / 15%);
  min-width: 100%;
  @include themify($themes) {
    background-color: themed('generalSearch', 'bg');
  }

  & .universal-dropdown-header {
    padding: 1.3rem 0.6rem 0.4rem 0.6rem;
    @include themify($themes) {
      border-bottom: 1px solid themed('generalSearch', 'border');
    }

    & .universal-dropdown-title {
      @include themify($themes) {
        color: themed('generalSearch', 'text');
      }
      font-size: 1.4rem;
      font-weight: bold;
    }
  }

  & .universal-search-result-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & .skeleton-line {
      margin: 0.6rem;
    }

    & .no-data {
      padding: 1.1rem;
      @include themify($themes) {
        color: themed('generalSearch', 'text');
      }
    }

    & .suggestion-list-item {
      display: flex;
      list-style: none;
      padding: 1.1rem;
      cursor: pointer;

      &:hover {
        @include themify($themes) {
          background-color: themed('generalSearch', 'bgTable');
        }
      }

      & .suggestion-item-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5.5rem;
        @include themify($themes) {
          color: themed('generalSearch', 'text');
        }
      }
    }
  }
  & .refresh-vin-results {
    @include themify($themes) {
      color: themed('generalSearch', 'text');
    }
    padding: 1.1rem;
    box-sizing: border-box;
    & span {
      cursor: pointer;
      &:hover {
        opacity: 75%;
      }
    }
  }
}
