@import 'assets/sass/variables';

@import 'assets/sass/_variables.scss';
@import 'assets/sass/tables';

.automotor-offer-list {
  padding: 0;

  @media screen and (max-width: 990px) {
    padding: 0 2rem 2rem 2rem
  }

  @media screen and (max-width: 768px) {
    padding: 0 2rem 2rem 2rem
  }

  & .orders-filter {
    height: 13rem;
    border-radius: 1rem;
    border: 1px solid #CFD9EE;
    margin-bottom: 2rem;
    margin-top: 2.5rem;

    @media screen and (max-width: 990px) {
      height: auto;
      padding-bottom: 1rem;
    }

    @media screen and (max-width: 768px) {
      height: auto;
      padding-bottom: 1rem;
    }

    & .orders-filter-header {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2.1rem 2.4rem;

      & .filter-title {
        font-size: 1.4rem;
        color: $blue-1;
        font-weight: bold;
      }

      & .filter-reliquats {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        color: $blue-1;
      }
    }

    & .orders-filter-body {
      display: flex;
      flex-direction: row;
      gap: 1.4rem;
      padding: 0 2.4rem;

      @media screen and (max-width: 990px) {
        flex-direction: column;
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      & .orders-filter-input-item {
        width: 100%;
        position: relative;

        & .orders-react-picker-dropdown {
          cursor: pointer;

          &.disable {
            pointer-events: none;
          }
        }

        & .orders-date-picker {
          position: absolute;
          z-index: 101;
          top: 5rem;

          @media screen and (max-width: 768px) {
            width: 100%;
          }

          & .react-datepicker {
            display: flex;
            position: relative;
            border: none;
            box-shadow: 0.5rem 0.8rem 2.1rem rgb(0 0 0 / 15%);
            font-size: 1.3rem;

            &__month-container {
              & .react-datepicker__header {
                background: none;
                border-bottom: none;
                padding: 1.2rem 1rem;

                & .react-datepicker__current-month {
                  font-size: 1.4rem;
                }

                & .react-datepicker__day-names {
                  .react-datepicker__day-name {
                    width: 3rem;
                  }
                }
              }

              & .react-datepicker__week {
                .react-datepicker__day {
                  width: 3rem;
                  line-height: 3rem;

                  &--in-range {
                    background-color: #d73c5a;
                  }

                  &--selected {
                    background-color: #d73c5a
                  }

                  &--in-selecting-range {
                    background-color: #f5bec7;
                  }

                  &--keyboard-selected {
                    background-color: #d73c5a;
                  }
                }
              }
            }

            .react-datepicker__month-container {
              @media screen and (max-width: 768px) {
                width: 100%;
              }
            }
          }
        }

        & .select-dropdown {
          width: 100%;
          height: 4.2rem;
          border-radius: 0.5rem;
          font-size: 1.4rem;
          font-weight: 400;
          color: $blue;
          border: none;

          &.disabled {
            opacity: 30%;
            pointer-events: none;
            user-select: none;
          }

          [class$='-control'] {
            color: $blue;
            border: none;
            background-color: $blue-3;
            height: 4.2rem;
            box-shadow: none;

            &:hover {
              border: none;
            }
          }

          [class$='-placeholder'] {
            color: $blue;
            font-size: 1.4rem;
            font-weight: 400;
          }

          [class$='-indicatorContainer'] {
            color: $blue;
          }

          [class$='-singleValue'] {
            color: $blue;
          }

          [class$='-indicatorSeparator'] {
            background: none;
            color: $blue;
          }

          [class$='-indicator'] {
            background: $blue;
            color: $white;
          }
        }
      }
    }
  }


}