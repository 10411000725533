@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.tecrmi-modules {
  width: 100%;
  //padding: 0 2rem;
  box-sizing: border-box;

  @media screen and (max-width: 990px) and (min-width: 768px) {
    padding: 0rem;
  }

  .tecrmi-modules-modal-btn {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    padding: 0 2rem;
    transition: background 0.2s ease-in-out;
    gap: 5rem;

    @include themify($themes) {
      color: themed('main');
      background: themed('inputColor');
    }

    &:hover {
      opacity: 75%;
    }

    svg {
      font-size: 1.4rem;
    }

    & .modules-modal-btn {
      display: flex;
      align-items: center;

      & .modules-modal-btn-icon {
        margin-right: 1rem;

        & svg {
          @include themify($themes) {
            fill: themed('main');
          }

          width: 2.5rem;
          height: 2.5rem;
          background-color: none;
        }
      }

      & .modules-modal-btn-text {
        font-size: 1.5rem;

        @include themify($themes) {
          color: themed('main');
        }
      }
    }
  }

  &>div[class^='technical-documentation-'] {
    display: flex;
    gap: 2rem;
    width: 100%;

    .tdoc-sidebar {
      width: 100%;
      max-width: 31.5rem;
    }

    .tdoc-content {
      width: 100%;
      height: 100%;
    }

    .tdoc-module {
      width: 100%;
    }
  }
}

/* Mobile CSS Styles */

.app-layout-tablet,
.app-layout-mobile {
  .tecrmi-modules {
    padding: 1rem 0 0;
    background: $white;

    .tcrmi-icons-bar {
      margin-left: 2rem;
      margin-right: 2rem;
      width: calc(100% - 5rem);
    }

    .tecrmi-modules-modal-btn {
      width: calc(100% - 8rem);
      margin: 0 auto 2rem;
    }

    &>div[class^='technical-documentation-'] {
      width: calc(100% - 4rem);
      padding: 0 2rem;
    }
  }
}