@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.price-list-tariff-item {
  background-color: #eaf0fb;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 100%;

  & .tariff-item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 1.8rem;
    border-bottom: 0.1rem solid $blue-14;
    font-weight: bold;

    & .delete-btn {
      cursor: pointer;

      &:hover {
        opacity: 75%;
      }
    }
  }

  & .tariff-message {
    color: $blue-13;
    font-size: 1.5rem;
    margin: 1rem 0;
  }

  & .tariff-item-body {
    border-bottom: 0.1rem solid $blue-14;
    padding-bottom: 1rem;

    & .attached-file-title {
      @include themify($themes) {
        color: themed('main');
      }
      font-size: 1.5rem;
      margin-bottom: 2rem;
    }

    & .tariff-data {
      display: flex;

      & .tariff-file-icon {
        @include themify($themes) {
          color: themed('main');
        }
        padding: 0 2rem;
        box-sizing: border-box;
        position: relative;

        .tariff-file-spinner {
          position: absolute;
          top: -1rem;

          svg {
            circle {
              stroke: $white;
            }
          }
        }

        & .excel-icon {
          font-size: 5rem;
          cursor: pointer;

          &:hover {
            opacity: 75%;
          }

          &.blur {
            filter: blur(0.4rem);
          }
        }
      }

      & .tariff-file-details {
        & .tariff-file-detail {
          @include themify($themes) {
            color: themed('main');
          }
          font-size: 1.3rem;
        }
      }
    }
  }

  & .tariff-item-footer {
    & .mapping-status {
      font-size: 1.5rem;
      font-weight: bold;
      margin-top: 1rem;

      &.error {
        color: $red;
      }

      &.success {
        color: $green;
      }
    }
  }
}
