@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.favorite-part-tab {
  width: 100%;
  display: flex;
  gap: 1.6rem;
  justify-content: space-between;
  padding-top: 4rem;

  @media screen and (max-width: 768px) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .favorite-sidebar-wrapper {
    width: 100%;
    max-width: 24rem;

    @media screen and (max-width: 768px) {
      max-width: 90%;
    }

    @media screen and (max-width: 990px) and (min-width: 768px) {
      max-width: 40%;
    }
  }

  .items-wrapper {
    flex-grow: 1;
    position: relative;
    min-height: 30rem;

    @media screen and (max-width: 990px) and (min-width: 768px) {
      max-width: 60%;
    }

    @media screen and (max-width: 768px) {
      max-width: 90%;
      margin-top: 4rem;
      position: initial;
    }
  }
}
