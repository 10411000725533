@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.error-codes-header {
  display: flex;
  width: 100%;

  form {
    margin-left: 0;
  }
}

.error-codes-search {
  overflow: hidden;
  max-width: 65rem;
  margin: 1.5rem auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .text {
    font-size: 1.4rem;
    @include themify($themes) {
      color: themed('main');
    }
    line-height: 1.9rem;
    text-transform: uppercase;
    padding: 0 1.5rem;
    bottom: -15px;
    position: relative;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  & > div {
    display: inline-block;
    margin: 0.5rem;
  }

  label {
    margin: 0.5rem;
    display: inline-block;
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1.9rem;
  }

  input {
    opacity: 0.5;
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 1.5rem;
    letter-spacing: 0;
    line-height: 2rem;
  }
}

.error-codes-login-vts {
  display: flex;
  overflow: hidden;
  max-width: 22.7rem;
  min-width: 22.7rem;
  margin: 1.5rem auto;
  justify-content: center;
  align-items: flex-end;

  .default-button {
    &.disabled {
      opacity: 50%;
      pointer-events: none;
      cursor: not-allowed;
    }

    width: 22.7rem;
    padding: 0.5rem 1rem;
    height: 3.4rem;
    text-decoration: none;
    margin: 0.5rem auto;
    text-align: center;
    line-height: normal;
    border-radius: 0.5rem;
    color: $white;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* Responsive CSS Styles */

@media screen and (max-width: 1360px) {
  .error-codes-header {
    flex-flow: column wrap;
    padding: 0;
    gap: 0;

    form {
      margin: 0;

      .text-input-container {
        .text-input {
          background-color: $input-color;
        }
      }
    }

    .error-codes-login-vts {
      min-width: 22.7rem;
      max-width: 22.7rem;
    }
  }
}

.app-layout-tablet,
.app-layout-mobile {
  .error-codes-header {
    flex-flow: column wrap;
    padding: 0;
    gap: 0;

    form {
      margin: 0;

      .text-input-container {
        .text-input {
          background-color: $input-color;
        }
      }
    }

    .error-codes-login-vts {
      min-width: 22.7rem;
      max-width: 22.7rem;
    }
  }
}

.app-layout-mobile {
  .error-codes-header {
    gap: 0;
    padding: 0 2rem;
    width: calc(100% - 4rem);
  }

  @media screen and (max-width: 620px) {
    .error-codes-header {
      .error-codes-search {
        flex-wrap: wrap;

        & > div {
          width: 100%;
          margin: 0;
        }

        .text {
          margin: 0.5rem auto;
          bottom: 0;
          text-align: center;
        }
      }

      .error-codes-login-vts {
        max-width: 100%;
        width: 100%;

        .default-button {
          width: 100%;
        }
      }
    }
  }
}
