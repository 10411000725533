@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.price-cell-wrapper {
  font-weight: bold;
  font-size: 1.2rem;
  display: flex;
  align-items: flex-start;
  .price-cell {
    display: flex;
    align-items: center;
    flex-direction: column;
    .divider {
      border-bottom: 1px solid $blue-4;
      width: 1rem;
      margin: 0.5rem 0;
      display: flex;
      align-items: center;
    }
    .vat-price {
      font-size: 1.1rem;
      @include themify($themes) {
        color: themed('main');
      }
      text-align: center;
      & .vat-label {
        font-size: 0.7rem;
        text-align: center;
      }
    }
  }
}
