@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.upload-part-replacement-modal {
  padding: 1rem 3rem 1rem 3rem;
  width: 70rem;

  @media screen and (max-width: 990px) {
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
  }

  .download-template {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;

    & a {
      text-decoration: none;
      color: $blue;
      font-size: 1.4rem;
      font-weight: 700;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      @include themify($themes) {
        color: themed("main");
      }

      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }

    @include themify($themes) {
      color: themed("main");
    }
  }

  & .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.5rem;
    border-bottom: 0.1rem solid $blue-14;
    padding-bottom: 1rem;

    @include themify($themes) {
      color: themed('main');
    }

    & .close-modal {
      cursor: pointer;

      &:hover {
        opacity: 75%;
      }
    }
  }

  & .mobile-scroll {
    @media screen and (max-width: 768px) {
      height: 80vh;
      overflow: auto;
    }

    & .modal-body {
      padding-top: 3rem;
      font-size: 1.5rem;

      & .part-replacement-file-upload-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: $blue-14;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='cornflowerblue' stroke-width='5' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 1rem;
        padding: 2.5rem 5.3rem;

        &.invalid {
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='red' stroke-width='5' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        }

        &>.part-replacement-folder-upload {
          @include themify($themes) {
            color: themed('main');
          }

          font-size: 4rem;
        }

        &>.part-replacement-title-upload {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin: 1.6rem 0;

          &>.browse-files {
            cursor: pointer;
          }
        }
      }

      & .part-replacement-details {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 1rem;

        & .uploaded-file {
          & .file-icon {
            font-size: 3rem;
          }

          display: flex;
          align-items: center;

          @include themify($themes) {
            color: themed('main');
          }

          justify-content: space-between;
          width: 100%;

          & .file-data {
            width: 80%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            & .remove-file {
              cursor: pointer;

              &:hover {
                opacity: 75%;
              }
            }
          }
        }

        & .part-replacement-setting-row {
          display: flex;
          align-items: center;
          gap: 1rem;
          justify-content: space-between;
          width: 100%;

          @include themify($themes) {
            color: themed('main');
          }

          &.part-replacement-clients {
            margin: 2rem 0;
          }

          @media screen and (max-width: 768px) {
            flex-direction: column;
            gap: 1.5rem;
          }

          & .hide {
            display: none;
          }

          & .part-replacement-dropdown-item {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 80%;

            @media screen and (max-width: 768px) {
              width: 100%;
            }

            .part-replacement-client-container {
              display: flex;
              gap: 1rem;

              & .part-replacement-client-radio {
                display: flex;
                gap: .5rem;
                background-color: $hovered-blue;
                padding: .8rem;
                border-radius: .5rem;
                align-items: center;
                width: 100%;

                &-btn {
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  & input {
                    margin: auto;
                  }
                }
              }
            }

            .part-replacement-select-container {
              width: 100%;

              &.clients {
                display: flex;
                gap: 1rem;
                width: 100%;

                @media screen and (max-width: 768px) {
                  flex-direction: column;
                  justify-content: center;
                }

                & .select-all-container {
                  display: flex;
                  align-items: center;
                  gap: 1rem;

                  & span {
                    white-space: nowrap;
                  }

                  @media screen and (max-width: 768px) {
                    justify-content: space-between;
                    flex-direction: row-reverse;
                  }

                  input[type='checkbox'] {
                    appearance: none;
                    background-color: $white;
                    margin: 0;
                    font: inherit;

                    @include themify($themes) {
                      color: themed('main');
                    }

                    width: 1.15em;
                    height: 1.15em;
                    border: 0.15em solid $blue-1;
                    border-radius: 0.15em;
                    transform: translateY(-0.075em);
                    display: grid;
                    place-content: center;
                    outline: none;
                  }

                  input[type='checkbox']::before {
                    content: '';
                    width: 0.65em;
                    height: 0.65em;
                    transform: scale(0);
                    transition: 120ms transform ease-in-out;
                    box-shadow: inset 1em 1em $blue-1;
                    transform-origin: bottom left;
                    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
                  }

                  input[type='checkbox']:checked::before {
                    transform: scale(1);
                  }
                }

                & .select-dropdown {
                  width: 100%;

                  @media screen and (max-width: 768px) {
                    width: 100%;
                  }

                  &.error {
                    border: 0.1rem solid $red;
                  }

                  & [class$='-ValueContainer'] {
                    overflow-y: auto;
                    max-height: 10rem;
                  }
                }
              }
            }

            & .select-dropdown {
              width: 100%;
              outline: none;

              @media screen and (max-width: 768px) {
                width: 100%;
              }

              &.required {
                border: 0.1rem solid $red;
                border-radius: 0.5rem;
              }

              & [class$='-ValueContainer'] {
                overflow-y: auto;
                max-height: 10rem;
              }

              & [class$='-control'] {
                & div:first-child {
                  overflow-y: auto;
                  max-height: 10rem;
                }
              }
            }
          }
        }

        & .special-price {
          display: flex;
          align-items: center;

          @include themify($themes) {
            color: themed('main');
          }

          gap: 5.2rem;

          @media screen and (max-width: 768px) {
            justify-content: space-between;
          }

          input[type='checkbox'] {
            appearance: none;
            background-color: $white;
            margin: 0;
            font: inherit;

            @include themify($themes) {
              color: themed('main');
            }

            width: 1.15em;
            height: 1.15em;
            border: 0.15em solid $blue-1;
            border-radius: 0.15em;
            transform: translateY(-0.075em);
            display: grid;
            place-content: center;
            outline: none;
          }

          input[type='checkbox']::before {
            content: '';
            width: 0.65em;
            height: 0.65em;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em $blue-1;
            transform-origin: bottom left;
            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
          }

          input[type='checkbox']:checked::before {
            transform: scale(1);
          }
        }
      }
    }

    & .part-replacement-message {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;

      & .part-replacement-textarea {
        @include themify($themes) {
          background-color: themed('bgActive');
        }

        padding: 0.625rem;
        font-size: 1.125rem;
        border-radius: 0.625rem;
        border: none;
        resize: vertical;

        @include themify($themes) {
          color: themed('main');
        }

        outline: none;
        min-height: 5rem;
        max-height: 12rem;

        &.error {
          border: 0.1rem solid $red;
        }

        &::-webkit-input-placeholder {
          color: $blue-13;
        }
      }
    }
  }

  & .modal-footer {
    padding: 1rem 0 0 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;

    @media screen and (max-width: 768px) {
      justify-content: center;
    }

    & .upload-part-replacement-btn {
      min-width: 12rem;
    }
  }
}