@import '../../assets/sass/variables';
@import 'src/assets/sass/themes';

.part-list-item-oe-new {
  width: 35rem;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.5rem;
  max-height: 40rem;

  @include themify($themes) {
    background-color: themed('secondary-darker');
  }

  &>div[class^='part-'] {
    width: 90%;
  }

  .part-list-item-favorite {
    align-self: flex-end;
    width: 100%;
    z-index: 2;
    position: relative;

    .favorite-button {
      position: absolute;
      right: 0;

      .iconButton {
        background: none;
      }

      svg {
        cursor: pointer;
      }

      .spinner-container {
        width: 2rem !important;
        height: 2rem !important;
        position: relative;

        .spinner {
          position: absolute;
          right: 1rem;
          top: 1rem;
          left: unset;
          background-color: inherit;
        }
      }

      button {
        svg {
          position: absolute;
          width: 2rem !important;
          height: 2rem !important;
          right: 1rem;
          top: 1rem;
          left: unset;

          @include themify($themes) {
            fill: themed('main');
          }
        }
      }
    }
  }

  .part-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    align-items: center;
    box-sizing: border-box;
    margin-top: 3rem;

    .img {
      img {
        max-width: 6.4rem;
        max-height: 6.4rem;
      }
    }

    .title {
      text-align: center;
      margin-bottom: 0.2rem;
      font-size: 1.8rem;
      font-weight: 600;

      @include themify($themes) {
        color: themed('main');
      }
    }

    .reference {
      text-align: center;
      margin-bottom: 0.2rem;

      @include themify($themes) {
        color: themed('main');
      }

      .info {
        text-align: center;
        margin-bottom: 0.3rem;
        font-size: 1.4rem;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        -webkit-box-orient: vertical;
        padding-top: 1rem;
      }

      .show-more {
        text-align: center;

        .btn-more {
          border: none;
          background: transparent;
          font-size: 1.1rem;
          font-weight: 600;

          @include themify($themes) {
            color: themed('main');
          }

          cursor: pointer;
        }
      }
    }

    .product-name {
      text-align: center;
      margin-bottom: 0.2rem;
      font-size: 1.4rem;
      font-weight: 400;

      @include themify($themes) {
        color: themed('main');
      }
    }

    .description {
      .info {
        text-align: left;
        margin-bottom: 0.5rem;
        font-size: 1.1rem;

        @include themify($themes) {
          color: themed('main');
        }

        line-height: 1.3;
        padding: 0rem 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        /* number of lines to show */
        -webkit-box-orient: vertical;

        .characteristics {
          @include themify($themes) {
            color: themed('main');
          }

          padding: 0.2rem 0.5rem 0.2rem 0.5rem;
          box-sizing: border-box;

          .characteristics-key {
            font-weight: 600;
            font-size: 1.1rem;
            text-align: center;
          }

          .characteristics-info {
            text-align: center;
          }
        }
      }

      .show-more {
        text-align: center;

        .btn-more {
          border: none;
          background: transparent;
          font-size: 1.1rem;
          font-weight: 600;

          @include themify($themes) {
            color: themed('main');
          }

          cursor: pointer;
        }
      }
    }
  }

  .part-footer {
    display: flex;
    flex-direction: column;
    border-top: 0.1rem solid $gray;
    margin-top: auto;
    width: 30rem;
    height: 8.85rem;
  }
}