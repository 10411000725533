@import 'assets/sass/variables';
@import 'src/assets/sass/themes';
.public-home {
  display: flex;
  flex-direction: column;
  width: 100%;
  .register-client-bar {
    background-color: $blue-14;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    & .register-btn {
      max-width: 15rem;
      min-width: 10rem;
    }
  }

  .general-search {
    display: flex;
    flex-direction: row;
    height: 36.2rem;
    gap: 1rem;
    padding: 3rem 1rem;

    @media screen and (min-width: 991px) and (max-width: 1366px) {
      flex-direction: column;
      height: 100%;
    }
    @media screen and (max-width: 991px) {
      flex-flow: column wrap;
      height: auto;
      padding: 1rem 2rem;
      margin: 0;
      gap: 1.2rem;
      position: relative;
      z-index: 2;
      max-width: 61.7rem;
      align-self: center;
      width: 100%;
    }
  }
}
.public-heading {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.3rem;
  padding: 0 1rem;
  box-sizing: border-box;
  & .title {
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 2.5rem;
    font-weight: 300;
    letter-spacing: 0;
    text-align: center;
    line-height: 41px;
    & .title-bold {
      font-weight: 700;
      font-size: 3rem;
    }
  }
  & .public-reliable-logo {
    max-width: 45rem;
    width: 100%;
  }
  & .header-search-input {
    width: 100%;
    max-width: 61.7rem;
  }
}
