@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.solutions-container {
  text-align: center;
  @include themify($themes) {
    color: themed('main');
  }
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 1.8rem;
  position: relative;
  min-height: 10rem;

  .no-data {
    text-align: center;
  }

  .inner-container {
    max-width: 64.2rem;
    margin: 0 auto;
  }

  .technotes-list {
    display: flex;
    flex-direction: column;
    text-align: left;

    .technotes-item {
      cursor: pointer;
      display: flex;
      column-count: 2;
      flex-direction: row;
      gap: 1rem;
      padding: 1rem 0;
      border-bottom: 0.1rem solid #d7e5ff;

      &:hover {
        @include themify($themes) {
          background-color: themed('secondary');
        }
      }
      &:last-of-type {
        border-bottom: none;
      }
      .error-code {
        width: 100%;
        max-width: 12rem;
      }
      .error-description {
        width: 100%;
      }
    }
  }

  .technotes-title {
    display: inline-flex;
    position: relative;
    min-height: 3rem;
    width: auto;
    min-width: 7.2rem;
    max-width: 60%;
    padding: 0.3rem 1rem;
    margin: 0 auto;
    bottom: -1.5rem;
    border-radius: 2.1rem;
    @include themify($themes) {
      background-color: themed('main');
    }
    color: $white;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: 0;
    align-items: center;
    justify-content: center;
  }
  .technotes-container {
    width: 100%;
    min-height: 4.2rem;
    @include themify($themes) {
      background-color: themed('secondary');
    }
    margin: 0 auto 2rem;
    display: flex;
    flex-flow: row nowrap;
    padding: 3rem 0 2.5rem;
    align-items: stretch;
    @include themify($themes) {
      color: themed('main');
    }
    letter-spacing: 0;
    font-size: 1.3rem;
    line-height: 1.4rem;
    text-align: center;

    &.reparation-container {
      max-width: calc(100% - 4rem);
      flex-direction: column;
      font-size: 1.4rem;
      line-height: 1.8rem;
      padding: 3rem 2rem 4rem;
    }
    .simptoms,
    .cause {
      width: 50%;
      height: 100%;
      display: flex;
      flex-flow: column nowrap;
      padding: 0.5rem 2rem;
      align-self: center;
      white-space: pre-wrap;

      strong {
        padding-bottom: 1rem;
      }
    }
    .separator-line {
      min-height: 3rem;
      width: 0.1rem;
      @include themify($themes) {
        background-color: themed('main');
      }
      display: flex;
    }
    .reparation-title {
      padding-bottom: 2rem;
    }
    .reparation-description {
      text-align: left;
      white-space: pre-wrap;
    }
  }

  .returnButton {
    text-align: left;
    margin-bottom: 1rem;

    .returnLink {
      cursor: pointer;
      text-decoration: none;
      @include themify($themes) {
        color: themed('main');
      }

      svg {
        margin-right: 0.5rem;
      }
    }
  }
}

/* Mobile CSS Styles */

.app-layout-mobile {
  .solutions-container {
    .technotes-list {
      padding: 0;
      margin: 0;

      .technotes-item {
        padding-left: 2rem;
        padding-right: 2rem;

        .error-description {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .technotes-container {
      box-shadow: 0.2rem 0.2rem 1rem rgb(0 0 0 / 10%);
      border-radius: 0.5rem;
      flex-wrap: wrap;
      gap: 3rem;

      .simptoms,
      .cause {
        width: 100%;
      }
      .separator-line {
        display: none;
      }

      &.reparation-container {
        gap: 0;
      }
    }

    .returnButton {
      margin-top: -3rem;
    }
  }

  @media screen and (max-width: 480px) {
    .solutions-container {
      .technotes-list {
        .technotes-item {
          padding-left: 0;
          padding-right: 0;

          .error-description {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  @media screen and (max-width: 360px) {
    .solutions-container {
      .technotes-list {
        margin: 0 -2rem;

        .technotes-title {
          max-width: 26rem;
          padding: 0.2rem 1rem;
        }
      }
    }
  }
}
