@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.list-dlrn-hernr-codfou-by-specifying-reference {
  background-color: $white-5;
  width: 90rem;
  min-height: 25rem;

  @include themify($themes) {
    color: themed('main');
  }

  font-weight: normal;
  position: relative;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 990px) and (min-width: 768px) {
    width: 100%;
  }

  .modal-dialog {
    padding: 0 4.7rem 2rem;

    @media only screen and (max-width: 768px) {
      padding: 1rem;
      box-sizing: border-box;
    }

    .close {
      svg {
        position: absolute;
        top: 0;
        right: 1rem;

        @media only screen and (max-width: 768px) {
          top: 1rem;
        }
      }
    }

    .list-title {
      text-align: center;
      font-size: 2rem;
      font-weight: 600;
      padding: 1rem 0rem;
    }

    .list-content {
      margin-top: 2rem;

      & .price-file-upload-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: $blue-14;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='cornflowerblue' stroke-width='5' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 1rem;
        padding: 2.5rem 5.3rem;

        &.invalid {
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='red' stroke-width='5' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        }

        &>.price-folder-upload {
          @include themify($themes) {
            color: themed('main');
          }

          font-size: 4rem;
        }

        &>.price-title-upload {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin: 1.6rem 0;

          &>.browse-files {
            cursor: pointer;
          }
        }
      }

      & .uploaded-file {
        margin-top: 1.5rem;


        & .file-icon {
          font-size: 3rem;
        }

        display: flex;
        align-items: center;

        @include themify($themes) {
          color: themed('main');
        }

        justify-content: space-between;
        width: 100%;

        & .file-data {
          width: 80%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .file-name {
            font-size: 1.6rem;
            font-weight: 600;
          }

          & .remove-file {
            cursor: pointer;
            font-weight: 600;
            font-size: 1.6rem;

            &:hover {
              opacity: 75%;
            }
          }
        }
      }

      .separator {
        width: 100%;
        border-top: 0.2rem dashed $blue-18;
        margin: 1rem 0;
      }

      .codfou-reference {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        gap: 1rem;


      }

      .list-row {
        width: 100%;
        display: flex;
        margin-top: 1.5rem;

        .list-row-label {
          font-size: 1.4rem;
          font-weight: 400;
          width: 18rem;

        }

        .text-input {
          height: 4.2rem;
        }

        .provider-dropdown-item {
          width: 100%;

          .select-dropdown {
            width: 100%;
            outline: none;
            font-size: 1.4rem;
            min-height: 4rem !important;

            @media screen and (max-width: 768px) {
              width: 100%;
            }

            &.required {
              border: 0.1rem solid $red;
              border-radius: 0.5rem;
            }

            & [class$='-ValueContainer'] {
              overflow-y: auto;
              max-height: 10rem;

            }

            & [class$='-control'] {
              & div:first-child {
                overflow-y: auto;

              }
            }
          }
        }

      }




    }

    .action {
      text-align: center;
      margin-top: 3rem;

      .default-button {
        width: 20rem;
      }

      .default-button:hover {
        @include themify($themes) {
          background: $blue;

        }
      }
    }

    .list-response {
      height: 0;
      overflow: hidden;
      margin-top: 2rem;


      table {
        .row {
          &:first-child {
            .heading {
              .table-title {
                margin: 0.4rem 0;
              }

              &:last-child {
                .table-title {

                  height: 3.4rem;
                }
              }
            }
          }
        }


      }

      .tfoot {
        display: none;
      }
    }

    .no-data {
      text-align: center;

      @include themify($themes) {
        color: themed('main');
      }

      padding-top: 2rem;
      font-size: 1.6rem;
      font-weight: 600;
    }

    .list-response-show {
      height: calc(100vh - 55rem);
      overflow: auto;
      transition: height 1s cubic-bezier(0.25, 0.1, 0.25, 1);

    }
  }

}