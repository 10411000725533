@import "src/assets/sass/variables";
@import "src/assets/sass/themes";

.view-catalog {
  &.tyres-catalog-wrapper-mobile {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    min-height: 20rem;
    font-family: "Open Sans", sans-serif;
    margin: 0 auto;
    padding-bottom: 5rem;
    background: #fff;

    .tyres-catalog-heading {
      padding: 0 2rem;
      display: flex;
      align-items: center;
      width: 100%;
      margin: 1rem 0;

      & .tyres-category-return {
        justify-self: flex-start;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 1.2rem;

        @include themify($themes) {
          color: themed("main");
        }

        &:hover {
          opacity: 50%;
        }
      }

      & .tyres-category-title {
        flex: 1 1 auto;
        font-size: 1.6rem;
        font-weight: bold;

        @include themify($themes) {
          color: themed("main");
        }

        margin: 0;
        text-align: center;
      }
    }

    .retailer-catalog-tyres-container-mobile {
      display: flex;
      gap: 3rem;
      z-index: 1;

      .catalog-tyres-sidebar-mobile {
        width: 100%;
        padding: 0 2rem;
        box-sizing: border-box;
        border-radius: 2rem;

        &.loading {
          height: 30rem;
        }

        .parts-list-catalog-item {
          width: 100%;
          height: 55px;
          cursor: pointer;
          display: flex;
          align-items: center;

          &:nth-child(odd) {
            @include themify($themes) {
              background-color: themed("secondary-3");
            }
          }

          &:first-child {
            border-radius: 1rem 1rem 0 0;
          }

          &:last-child {
            border-radius: 0 0 1rem 1rem;
          }

          &.selected {
            @include themify($themes) {
              background-color: themed("categoriesSidebar", "active");
            }

            color: $white;

            &>p {
              color: $white !important;
            }
          }

          @media screen and (max-width: 768px) {
            width: 100%;
          }

          p {
            width: 234px;

            @include themify($themes) {
              color: themed("categoriesSidebar", "text");
            }

            font-family: "Open Sans",
            sans-serif;
            font-size: 17px;
            font-weight: bold;
            letter-spacing: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          img {
            margin: 0 1rem;
          }
        }
      }
    }

    .topContent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 1rem;

      .logo {
        display: flex;
        gap: 2rem;
        align-items: flex-start;

        img {
          max-height: 7rem;
          align-self: center;
        }

        .buttonHome {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          border: none;
          width: 3rem;
          height: 3rem;

          @include themify($themes) {
            background: themed("mainHover");
          }

          align-self: center;
        }

        .img {
          @include themify($themes) {
            fill: themed("main");
          }

          width: 1.8rem;
        }
      }

      .title {
        font-size: 2.4rem;

        @include themify($themes) {
          color: themed("main");
        }

        margin-left: 20rem;
      }

      .desc {
        font-size: 1.1rem;

        @include themify($themes) {
          color: themed("main");
        }
      }

      .filter {
        display: flex;
        align-items: center;
        gap: 2rem;

        .button {
          padding: 0.5rem;

          @include themify($themes) {
            background-color: themed("main");
          }

          color: $white;
          border: none;
          font-weight: 500;
          width: 18rem;
          height: 4rem;
          border-radius: 1rem;
          cursor: pointer;
        }
      }
    }

    .separator {
      display: flex;
      justify-content: center;
      height: 0.1rem;
      background: $gray;
      width: 100%;
    }

    .filterOptions {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      padding: 1rem 0;
      z-index: 2;
      position: relative;

      @media screen and (max-width: 768px) {
        justify-content: space-evenly;
      }

      .results {
        text-align: center;
        font-size: 1.4rem;
        font-weight: 400;

        @include themify($themes) {
          color: themed("main");
        }
      }

      .drop {
        height: 3rem;

        @include themify($themes) {
          background: themed("mainHover");
        }

        @include themify($themes) {
          border: 1px solid themed("mainHover");
        }

        border-radius: 0.5rem;

        @include themify($themes) {
          color: themed("main");
        }

        font-weight: 200;
        width: 17.5rem;
      }

      .sortIcons {
        display: flex;
        padding: 0.5rem;
        gap: 1rem;

        .icon {
          width: 1.8rem;
          height: auto;
          display: flex;
          padding: 0.5rem;

          @include themify($themes) {
            background: themed("mainHover");
          }

          @include themify($themes) {
            fill: themed("main");
          }

          border-radius: 0.5rem;
          cursor: pointer;
        }
      }

      .iconLine {
        fill: $blue-5;
      }
    }

    .wrapperItems {
      position: relative;
      min-height: 20rem;
      width: 100%;

      .catalog-view-mode {
        display: grid;
        min-height: 70%;
        width: 100%;
        grid-gap: 2rem;
        grid-template-columns: repeat(auto-fit, 100%);
        position: relative;
        justify-content: start;
        margin: 0 auto;
        flex: 0.9;
      }

      .catalog-view-mode.LIST {
        display: flex;
        flex-direction: column;
      }

      .car-parts-spinner {
        background: none;
      }

      .arrowLeft,
      .arrowRight {
        position: fixed;
        width: 2rem;
        height: 2rem;
        top: 50%;
        left: 95%;
        fill: $blue-icon-color;
        cursor: pointer;
      }

      .arrowRight {
        right: -5rem;
      }

      .arrowLeft {
        left: 3.5%;
      }
    }

    .no-parts-data {
      margin-top: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .notFoundSvg {
        text-align: center;
      }

      svg {
        @media screen and (max-width: 768px) {
          width: 50%;
        }
      }

      & h1 {
        @include themify($themes) {
          color: themed("main");
        }

        align-self: center;
        justify-self: center;
        position: relative;
        font-size: 2rem;
      }
    }

    .nodata-wrapperItems {
      min-height: 70%;
      width: 100%;
      position: relative;
      margin: 0 auto;
      flex: 0.9;

      .car-parts-spinner {
        background: none;
        position: relative;
        top: 20rem;
      }
    }
  }
}

/* Responsive CSS Styles */

.app-layout-tablet,
.app-layout-mobile {
  .view-catalog {
    &.tyres-catalog-wrapper-mobile {
      &>div {
        padding-left: 2rem;
        padding-right: 2rem;
        width: calc(100% - 4rem);
      }

      & div.catalog-navbar-container {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        padding-bottom: 1rem;
      }
    }
  }

  .tyres-wrapper {
    background: $white;
    width: 100%;

    &>div {
      padding-left: 2rem;
      padding-right: 2rem;
      width: calc(100% - 4rem);
    }

    .catalog-navbar-container {
      padding-left: 0;
      padding-right: 0;

      width: 100%;
    }

    .separator {
      display: none;
    }

    .filterOptions {
      margin-bottom: 1rem;
    }

    .wrapperItems {
      .catalog-view-mode {
        &.GRID {
          justify-content: center;
        }
      }
    }
  }
}

.app-layout-mobile {
  .view-catalog {
    &.tyres-catalog-wrapper-mobile {
      .filterOptions {
        flex-direction: row;
        gap: 2rem;
        justify-content: space-evenly;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .view-catalog {
      &.tyres-catalog-wrapper-mobile {
        .wrapperItems {
          .catalog-view-mode {
            &.GRID {
              grid-template-columns: repeat(auto-fit, 100%);
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 380px) {
    .view-catalog {
      &.tyres-catalog-wrapper-mobile {
        .sort-by-container .sort-select-container {
          flex-grow: 1;
        }
      }
    }
  }
}