@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.catalog-supplier-navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $grey-1;
  position: relative;

  .spiner-info {
    min-height: 6.5rem;
  }

  @media screen and (max-width: 990px) and (min-width: 768px) {
    flex-direction: column;
  }

  .vehicle-details {
    position: absolute;
    left: 18rem;
    bottom: 1.8rem;
    @include themify($themes) {
      color: themed('main');
    }

    @media screen and (max-width: 768px) {
      width: 5rem;
      height: 5rem;
      left: 0rem;
      top: 1rem;
    }

    @media screen and (max-width: 990px) and (min-width: 768px) {
      position: absolute;
      top: 1rem;
      left: 0;
    }

    .vehicle-info-container {
      position: absolute;
      top: 100%;
      overflow: hidden;
      z-index: 14;
      left: 0;

      .title {
        min-height: 2rem;
      }

      .vehicle-info-header {
        @media screen and (max-width: 768px) {
          width: 90%;
          display: flex;
          flex-direction: row;
          padding: 0;
          justify-content: space-between;
          z-index: 120;
          position: relative;
          align-items: center;
          margin-top: 2rem;
          z-index: 100;
        }
      }

      @media screen and (max-width: 768px) {
        position: fixed;
        overflow: hidden;
        z-index: 120;
        left: 0;
        top: 7rem;
        border-radius: 0;
        width: 100%;
        height: 100vh;
        margin: 0;
        padding: 2rem;
      }

      .close {
        float: right;
        height: 1.8rem;
        width: 2rem;
        font-size: 1.8rem;
        line-height: 2rem;
        text-align: center;
        cursor: pointer;
      }

      .content {
        @media screen and (max-width: 768px) {
          ul {
            li {
              width: 100%;
              margin-top: 0.5rem;
            }
          }
        }
      }
    }

    .info {
      height: 4.7rem;
      min-width: 13.2rem;
      max-width: 15rem;
      border: 0.1rem solid $blue-1;
      border-radius: 1rem;
      padding: 0 0.4rem;
      font-size: 1.4rem;
      line-height: 1.9rem;
      font-weight: bold;
      letter-spacing: 0;
      float: left;
      cursor: pointer;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 768px) {
        min-width: 5rem;
        min-height: 5rem;

        .label {
          display: none;
        }
      }

      svg {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 768px) and (max-width: 990px) {
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .breadcrumbs {
    display: flex;
    align-items: center;
    gap: 2.3rem;

    img {
      max-height: 4.7rem;
      max-width: 11.7rem;
    }

    .icon svg {
      font-size: 2.5rem;
      @include themify($themes) {
        background-color: themed('secondary-3');
      }
    }
  }

  .title {
    min-height: 8rem;
    max-width: 62rem;
    margin-left: 150px;

    @media screen and (min-width: 1190px) and (max-width: 1375px) {
      max-width: 40rem;
      margin-left: 150px;
    }

    @media screen and (min-width: 990px) and (max-width: 1190px) {
      max-width: 40rem;
      margin-left: 150px;
    }

    @media screen and (max-width: 768px) {
      margin-left: 0px;
    }

    .vehicle-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 1rem;

      .vehicle-name {
        @include themify($themes) {
          color: themed('main');
        }
        font-size: 2.5rem;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 3.4rem;
        text-align: center;

        @media screen and (min-width: 1190px) and (max-width: 1375px) {
          font-size: 2.3rem;
        }

        @media screen and (min-width: 768px) and (max-width: 1190px) {
          font-size: 2rem;
        }

        @media screen and (max-width: 768px) {
          font-size: 1.4rem;
          max-width: calc(100% - 15px);
        }
      }

      .vehicle-description {
        @include themify($themes) {
          color: themed('main');
        }
        font-size: 1.8rem;
        letter-spacing: 0;
        line-height: 2.4rem;
        padding: 0px 0px 0px 0px;
        text-align: center;

        @media screen and (min-width: 1190px) and (max-width: 1375px) {
          font-size: 1.8rem;
        }

        @media screen and (min-width: 768px) and (max-width: 1190px) {
          font-size: 1.2rem;
        }

        @media screen and (max-width: 768px) {
          font-size: 1.1rem;
          padding: 0px;
          font-size: 1.2rem;
        }
      }
    }

    h1 {
      font-family: Open Sans, 'sans-serif';
      @include themify($themes) {
        color: themed('main');
      }
      font-size: 2.5rem;
      line-height: 3.4rem;
      font-weight: normal;
      text-align: center;

      @media screen and (max-width: 768px) and (max-width: 990px) {
        line-height: 1.7rem;
        font-size: 1.7rem;
      }
    }
  }

  .options {
    .catalog-parts-filter {
      display: flex;
      align-items: center;
      gap: 20px;

      .catalog-parts-desc {
        font-size: 11px;
        @include themify($themes) {
          color: themed('main');
        }
      }

      .catalog-parts-button {
        padding: 5px;
        @include themify($themes) {
          background-color: themed('main');
        }
        color: $white;
        border: none;
        font-weight: 500;
        width: 180px;
        height: 40px;
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }
}
