@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.wrapper-select-client-erpid {

  width: 40rem;
  padding: 2rem;

  .modal-body {
    .close-modal {
      position: absolute;
      right: 1.6rem;
      top: 1.6rem;
      font-size: 1.8rem;

      @include themify($themes) {
        color: themed('main');
      }

      @media only screen and (max-width: 768px) {
        top: 2rem;
        right: 2.6rem;
      }

      @media screen and (max-width: 990px) and (min-width: 768px) {
        top: 3.6rem;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .title {
      font-size: 1.8rem;

      @include themify($themes) {
        color: themed('main');
      }

      text-align: center;

    }

    .input-container {
      .select-dropdown {
        width: 100%;
        height: 4.2rem;
        border-radius: 0.5rem;
        font-size: 1.4rem;
        font-weight: 400;
        color: $blue;
        border: none;



        &.disabled {
          opacity: 30%;
          pointer-events: none;
          user-select: none;
        }

        [class$='-control'] {
          color: $blue;
          border: none;
          background-color: $blue-3;
          height: 4.2rem;
          box-shadow: none;

          &:hover {
            border: none;
          }
        }

        [class$='-placeholder'] {
          color: $blue;
          font-size: 1.4rem;
          font-weight: 400;
        }

        [class$='-indicatorContainer'] {
          color: $blue;
        }

        [class$='-singleValue'] {
          color: $blue;
        }

        [class$='-indicatorSeparator'] {
          background: none;
          color: $blue;
        }

        [class$='-indicator'] {
          background: $blue;
          color: $white;
        }
      }
    }

    .btn-save {
      margin-top: 5rem;
      text-align: center;

      button {
        width: 20rem;

      }
    }
  }

}

.app-layout-mobile {
  .wrapper-select-client-erpid {
    width: auto;
  }
}