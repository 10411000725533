@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.popup-info-container {
  max-height: 30.8rem;
  width: 35rem;
  border-radius: 1rem;
  @include themify($themes) {
    background-color: themed('main');
  }
  color: $white;
  letter-spacing: 0;
  text-align: left;
  padding: 2rem;
  position: absolute;
  z-index: 150;
  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;
  left: 50%;
  margin-left: -17.5rem;

  @media screen and (max-width: 768px) {
    width: 30rem;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .title {
    font-size: 2rem;
    font-weight: bold;
    line-height: 2.7rem;

    svg {
      margin-right: 1rem;
    }
  }

  .content {
    font-size: 1.4rem;
    line-height: 1.9rem;

    span {
      font-weight: bold;
    }

    ul {
      display: flex;
      flex-flow: row wrap;
      list-style-type: none;
      padding: 0;

      li {
        width: 50%;
      }
    }
  }
}
