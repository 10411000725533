@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.maintenance-service-plan {
  @include themify($themes) {
    background: themed('secondary-2');
  }
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 3rem;

  & .form-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    & .inputs-first-row {
      display: flex;
      align-items: flex-end;
      gap: 1rem;
      width: 100%;
      justify-content: center;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }

      & .input-item {
        width: 100%;

        & .first-row-label {
          @include themify($themes) {
            color: themed('main');
          }
          font-size: 1.4rem;
        }
      }
    }

    & .inputs-second-row {
      display: flex;
      align-items: flex-end;
      gap: 1rem;
      width: 100%;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }

      & .input-item {
        width: 100%;

        & input {
          &:read-only {
            opacity: 60%;
          }
        }

        & .second-row-label {
          @include themify($themes) {
            color: themed('main');
          }
          font-size: 1.4rem;
        }
      }
    }
  }

  & .additional-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 1.4rem;
    margin-top: 1rem;
    border-bottom: 0.1rem solid $blue-14;
    padding-bottom: 2rem;
    margin-bottom: 2rem;

    & .checkbox {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      @include themify($themes) {
        color: themed('main');
      }
      font-size: 1.4rem;
    }

    input[type='checkbox'] {
      appearance: none;
      background-color: $white;
      margin: 0;
      font: inherit;
      @include themify($themes) {
        color: themed('main');
      }
      width: 1.15em;
      height: 1.15em;
      @include themify($themes) {
        border: 0.15em solid themed('main');
      }
      border-radius: 0.15em;
      transform: translateY(-0.075em);
      display: grid;
      place-content: center;
      outline: none;
    }

    input[type='checkbox']::before {
      content: '';
      width: 0.65em;
      height: 0.65em;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      @include themify($themes) {
        box-shadow: inset 1em 1em themed('main');
      }
      transform-origin: bottom left;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }

    input[type='checkbox']:checked::before {
      transform: scale(1);
    }

    & .info-wheels {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    & .print-btn {
      width: 10rem;
    }
  }
}
