@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.suggestion-list-container {
  max-height: 300px;
  overflow-y: auto;
  width: 100%;

  .suggestion-list-item {
    &.selected {
      font-weight: bold;

      span {
        color: $vin-hover-color;
      }
    }
  }

  .change-model-btn {
    @include themify($themes) {
      color: themed('main');
    }
    cursor: pointer;
    background: none;
    border: none;
    font-size: 1rem;
    padding: 0 0 1rem 1rem;
    //&:hover{
    //  color: $vin-hover-color;
    //}
  }
}
