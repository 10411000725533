@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.message-file {
  cursor: pointer;
  padding: 0 1rem;

  @include themify($themes) {
    background: themed('secondary-2');
  }

  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  gap: 0.681rem;
  height: 4.5rem;

  .name {
    font-size: 1rem;
    line-height: 1.4rem;

    p {
      margin: 0;
    }
  }

  .icon {
    display: flex;
    justify-content: center;

    svg {
      width: 1.696rem;
      height: 2.246rem;

      path {
        @include themify($themes) {
          fill: themed('iconFill');
        }
      }
    }
  }

  .spinner.extra-small {
    width: 1.696rem;
    height: 2.246rem;
  }
}