@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.cart-table-container-automotor {
  position: relative;
  min-height: 40rem;
  width: 100%;

  @media screen and (max-width: 990px) {
    padding: 1.5rem;
    box-sizing: border-box;
  }

  input[type='checkbox'],
  .link {
    //opacity: 0.41;
    cursor: pointer;
  }

  input:checked {
    opacity: 1;
  }

  input {
    width: 1.6rem;
    height: 1.6rem;
    margin: 0;
  }

  .cart-actions-wrapper {
    display: flex;
    justify-content: space-between;
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 1.1rem;
    letter-spacing: 0;
    line-height: 1.4rem;
    padding: 1rem 1.5rem;
    width: 100%;
    box-sizing: border-box;

    @media screen and (max-width: 768px) {
      padding: 0;
    }

    .checkbox-container {
      line-height: 1.6rem;

      .link {
        margin-left: 0.5rem;
        line-height: 1.6rem;
        float: right;
      }
    }
  }

  .cart-table-wrapper {
    background-color: $white-7;
    width: 100%;
    border-radius: 1rem;
    @include themify($themes) {
      color: themed('main');
    }
    text-align: left;
    line-height: 1.4rem;
    padding: 0 1.5rem;

    @media screen and (max-width: 768px) {
      padding: 0;
    }

    @media screen and (max-width: 990px) and (min-width: 768px) {
      padding: 0rem;
    }

    table {
      width: 100%;
      border-spacing: unset;

      tr.row th,
      tr.row td {
        height: 3.5rem;
        padding: 0.4rem 0;
      }

      .headings {
        .row {
          .heading {
            @include themify($themes) {
              color: themed('main');
            }
            text-align: left;
            font-weight: 300;
            font-size: 1.2rem;
          }
        }
      }

      .body {
        .row {
          font-size: 0.75rem;

          .cell {
            border-top: 2px solid $blue-9;
            font-size: 1.2rem;
            max-width: min-content;

            input {
              cursor: pointer;
              @include themify($themes) {
                background-color: themed('secondary-3');
              }
              font-size: 1.5rem;
              @include themify($themes) {
                color: themed('main');
              }
            }

            .quantity-availability {
              height: 3.4rem;

              .wrapper-quantity {
                height: 100%;

                .plusminus {
                  background-color: $gray-6;
                }
              }
            }
          }

          .cell:first-of-type {
            padding-left: 0;
          }

          .image-cell-wrapper {
            width: 4.2rem;
            height: 4.2rem;
          }

          .reference-cell-wrapper {
            font-weight: bold;
            text-transform: uppercase;
          }

          .category-cell-wrapper {
            text-transform: uppercase;
          }

          .delete-cell-wrapper {
            text-align: center;
          }
        }
      }

      .footer {
        font-size: 1.5rem;
        font-weight: bold;
        border-radius: 1rem;
        background-color: $white-7;
        height: 5.6rem;
        display: contents;

        .total-qty {
          width: 7.5rem;
          text-align: center;
          display: inline-block;
        }
      }
    }
  }

  .wrapper {
    table-layout: auto;
    width: 100%;
    background: $white-6;
    border-spacing: 0 0.8rem;
    padding: 1.25rem;
  }
}
