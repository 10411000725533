@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.session-expired-page {
  background-color: $blue-14;
  height: 100vh;
  width: 100vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;

  .session-expired {
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 8rem;
    font-weight: bold;
  }
  .home-btn {
    a {
      text-decoration: none;
      color: $white;
      @include themify($themes) {
        background-color: themed('main');
      }
      padding: 1rem;
      box-sizing: border-box;
      font-size: 1.6rem;
      border-radius: 1rem;
      transition: all 0.5s;
      font-weight: bold;

      &:hover {
        @include themify($themes) {
          color: themed('main');
        }
        background-color: $blue-12;
      }
    }
  }
}
