@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.battery-dropdown-content {
  position: absolute;

  @include themify($themes) {
    background-color: themed('generalSearch', 'bg');
  }

  border-radius: 1rem;
  margin-top: 0.2rem;
  z-index: 50;
  box-shadow: 0.2rem 0.2rem 1rem rgb(1 1 1 / 15%);
  min-width: 100%;
  font-size: 1.4rem;
  font-weight: bold;
  overflow-y: auto;
  max-height: 30rem;

  @media screen and (min-width: 768px) {
    width: 50rem;
  }

  & .battery-dropdown-header {
    padding: 1.3rem 0.6rem 0.4rem 0.6rem;

    @include themify($themes) {
      border-bottom: 1px solid themed('border');
    }

    & .battery-dropdown-title {
      @include themify($themes) {
        color: themed('main');
      }
    }
  }

  & .battery-search-result-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & .skeleton-line {
      margin: 0.6rem;
    }

    & .no-data {
      @include themify($themes) {
        color: themed('main');
      }

      padding: 1.1rem;
    }

    & .suggestion-list-item-battery {
      display: flex;
      list-style: none;
      padding: 1.1rem;
      width: 100%;
      box-sizing: border-box;

      &:last-child {
        border-radius: 0 0 1rem 1rem;
      }

      &:hover {
        background-color: $blue-5;
      }

      & .suggestion-item-title {
        color: #355099;
        white-space: nowrap;
        cursor: pointer;

        @media screen and (min-width: 768px) {
          display: grid;
          grid-template-columns: 70% 25%;
          width: 100%;
          gap: 2.5rem;
        }

        span {
          cursor: pointer;

          @media screen and (max-width: 768px) {
            padding-left: 2.8rem;
          }
        }

        & .suggestion-item-ref {
          display: flex;

          span {
            cursor: pointer;
            padding-left: 0.3rem;
          }

          img {
            max-width: 2.5rem;
            max-height: 2.5rem;
          }
        }
      }
    }
  }
}