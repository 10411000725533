@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.presentation-banner {
  display: flex;
  align-items: center;
  margin: 4rem 0;

  &-title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16rem;
    width: 100%;
    @include themify($themes) {
      background-color: themed('main');
    }
    box-sizing: border-box;
    border-radius: 1rem;
    box-shadow: 0 0.9rem 2rem rgba(19, 15, 15, 0.15);
    z-index: 1;

    & img {
      max-width: 50rem;
      max-height: 10rem;
      padding: 0 2rem;
    }
  }

  & .side-banner {
    height: 7.7rem;
    width: 100%;
    max-width: 40rem;
    @include themify($themes) {
      background-color: themed('main');
    }
    background-image: url('banner-texture.png');
    background-size: cover;
    background-repeat: no-repeat;
  }
}
