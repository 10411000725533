@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.account-dropdown-phone {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  @include themify($themes) {
    color: themed('secondary');
}
  font-size: 1.2rem;
  width: 4.2rem;
  height: 4.2rem;
  background-color: $hovered-blue;
  border-radius: 2.1rem;
  gap: 0.5rem;
  position: relative;

  & .phone-icon {
    font-size: 1.6rem;

    @include themify($themes) {
      color: themed('main');
    }

    padding-left: 0.5rem;
  }

  .show {
    .content-data {
      display: block;
    }
  }

  .hide {
    .content-data {
      display: none;
    }
  }

  .content-data {
    padding: 1rem 0;
    width: max-content;

    @include themify($themes) {
      background-color: themed('main');
    }

    color: white;
    position: absolute;
    border-radius: 0.4rem;
    top: 4.6rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
    text-align: center;
    padding: 1rem 1.4rem;
    border-radius: 20px;

    .phone-info {
      color: white;
      padding-right: 1rem;
    }

    .phone-text {
      text-decoration: underline;
      color: white;
    }

    @media only screen and (max-width: 768px) {
      position: fixed;
      top: 5.5rem;
      right: 0rem;
      left: auto;
      transform: none;
    }

    @media only screen and (max-width: 990px) and (min-width: 768px) {
      position: fixed;
      top: 5.5rem;
      right: 0rem;
      left: auto;
      transform: none;
    }

    .item {
      text-decoration: none;
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      padding: 0 1rem;
      gap: 1rem;
      cursor: pointer;

      &:hover {
        @include themify($themes) {background-color: themed('mainHover');}
      }

      .icon {
        padding: 0.1rem;
      }

      .text {
        min-width: 10rem;
        font-size: 1.2rem;
      }
    }
  }
}