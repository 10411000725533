@import '../../../../../../assets/sass/variables';
@import 'src/assets/sass/themes';
@import '../../../../../../assets/sass/_mixins';

.espace-navbar-container {
  padding: 1.5rem 0;

  @include themify($themes) {
    color: themed('main');
  }

  a {
    display: inline-block;
  }

  p {
    margin: 0;
  }

  .espace-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .espace-navbar-logo-select-client {
      display: flex;
      flex-direction: row;
      align-items: center;
      // width: 100%;

      .espace-navbar-logo {
        max-width: 15.5rem;
        max-height: 6.9rem;
        border-right: 1px solid rgba(39, 80, 159, 0.2);
        padding-right: 1.5rem;
      }

      img {
        max-width: 15.5rem;
        max-height: 8rem;
      }

      .select-client-button-container {
        margin-left: 1.5rem;

        .select-client-button {
          align-items: center;
          justify-content: center;
        }
      }
    }

    .espace-navbar-group {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-end;
      }

      .espace-navbar-user {
        padding-top: 1rem;
        text-align: right;
        line-height: 2.5rem;
        letter-spacing: 0;
        font-family: 'Open Sans', sans-serif;

        @media screen and (max-width: 768px) {
          line-height: 1.7rem;
        }

        .user-name {
          //font-size: 2.3rem;
          font-size: 1.8rem;

          span {
            font-weight: 700;
            padding-left: 0.5rem;
          }
        }

        .user-activity {
          text-transform: uppercase;
          font-size: 1.15rem;
        }
      }

      .espace-action-btns {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
      }
    }
  }
}