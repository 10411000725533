@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.header-search-espace-catalog-sidebar {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  margin: 3rem 0 0;

  @media screen and (max-width: 768px) {
    padding: 0 2rem;
  }

  .title-sidebar {
    @include themify($themes) {
      color: themed('main');
    }

    max-width: 76.6rem;
    width: 100%;
    font-size: 3rem;
    //font-weight: 300;
    letter-spacing: 0;
    text-align: center;
    line-height: 41px;

    @media screen and (max-width: 768px) {
      font-size: 2rem;
      line-height: 30px;
    }

    & .title-bold {
      font-weight: 700;
      font-size: 3rem;

      @media screen and (max-width: 768px) {
        font-size: 2rem;
      }
    }
  }

  & .subtitle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: 2.7rem;
    width: 40rem;
    background-color: $input-color;
    border: $light-blue 1px solid;
    border-radius: 1.55rem;
    color: $light-blue;
    font-size: 1.1rem;
  }

  & .header-search-input {
    max-width: 61.7rem;
    width: 100%;
  }
}