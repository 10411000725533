@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.cart-view-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  & .empty_cart {
    padding: 2rem 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 40%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & .empty_cart_image {
      & img {
        margin-left: -10%;
        width: 100%;
        height: 100%;
        max-width: 24rem;
        max-height: 21rem;
      }
    }

    & .empty_cart_title {
      font-size: 2rem;
      font-weight: 700;
      margin-bottom: 1rem;
      @include themify($themes) {
        color: themed('main');
      }

      @media screen and (max-width: 768px) {
        font-size: 1.5rem;
        white-space: nowrap;
      }
    }
  }

  & .tabs-spinner {
    top: 10rem;
  }

  .cart-title {
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 2.4rem;
  }

  .tabs {
    @media screen and (max-width: 768px) and (orientation: landscape) {
      width: 90%;
    }

    @media screen and (max-width: 990px) and (min-width: 768px) {
      width: 90%;
    }

    .tab-list {
      display: flex;
      flex-direction: row;
      gap: 5rem;
      list-style-type: none;
      padding: 1.6rem 0rem;
      height: 2.5rem;
      border-bottom: 0.2rem solid $blue-5;
      justify-content: center;

      .tab {
        padding-bottom: 1.6rem;
        font-weight: 700;
        font-size: 1.2rem;
        height: 2.5rem;
        line-height: 2.5rem;
        @include themify($themes) {
          color: themed('main');
        }
        outline: none;

        &:hover {
          border-bottom: 0.4rem solid $blue;
          cursor: pointer;
        }

        &.react-tabs__tab--selected {
          border-bottom: 0.4rem solid $blue;
          cursor: pointer;
        }
      }
    }

    .tab-body {
      padding-top: 0.5rem;

      .tab-row {
        display: flex;
        flex-direction: row;
        margin: 0.9rem auto;

        .tab-label {
          width: 26rem;
          font-weight: 600;
          font-size: 1.8rem;
          line-height: 2.5rem;
          font-style: normal;
          display: flex;
          align-items: center;

          @include themify($themes) {
            color: themed('main');
          }

          input {
            width: 25rem;
            margin-right: 0.5rem;
          }
        }

        .tab-span {
          width: 40rem;
          font-style: normal;
          font-weight: 400;
          font-size: 1.8rem;
          line-height: 2.5rem;
          display: flex;
          align-items: center;
          @include themify($themes) {
            color: themed('main');
          }

          input {
            width: 25rem;
            margin-right: 0.5rem;
          }

          .btn-test {
            margin: 0rem 1rem;
            width: 15.6rem;
            height: 4.2rem;
            color: $white;

            /* blue */
            @include themify($themes) {
              color: themed('main');
            }
            border-radius: 0.5rem;
            border: none;

            &:hover {
              opacity: 0.8;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
