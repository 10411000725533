@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.message-supplier-info-item {
  max-width: 26rem;

  .title {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.2rem;
    line-height: 2rem;

    @include themify($themes) {
      color: themed('main');
    }

    margin-bottom: 1rem;
  }

  .item {
    @include themify($themes) {
      color: themed('main');
    }

    font-size: 1.4rem;
    padding-bottom: 0.2rem;

    .label {
      font-weight: bold;
      padding-right: 0.5rem;
    }

    .value {}
  }
}