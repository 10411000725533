@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.product-category-item {
  display: flex;
  @include themify($themes) {
    background-color: themed('secondary-3');
  }
  min-height: 11rem;
  padding: 1.8rem 6.6rem 0 6.6rem;
  border-radius: 1.2rem;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  @include themify($themes) {
    color: themed('main');
  }

  @media screen and (max-width: 932px) {
    padding: 1.2rem 4rem 0 4rem;
    width: 100%;
  }

  @media screen and (max-width: 990px) and (min-width: 768px) {
    width: auto;
  }

  .item-icon {
    @include themify($themes) {
      background-color: themed('secondary');
    }
    line-height: 1.6rem;
    font-size: 4rem;
    letter-spacing: 0;
    margin-bottom: 1rem;
    width: 5.5rem;
    height: 5.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .item-text {
    max-width: 4rem;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5rem;
    letter-spacing: 0;
    line-height: 2rem;
    text-align: center;
    font-weight: bold;

    p {
      margin: 0;
      width: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
