@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.espace-automotor-navbar-buyer-container {
  .navbar {
    @include themify($themes) {
      background-color: themed('bgNavbarBelow');
    }

    height: 9rem;
    display: flex;
    align-items: center;

    & .container {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      margin: 0 auto;
      width: 100%;
      height: auto;
      max-width: 144rem;
      min-height: auto;

      @media only screen and (max-width: 768px) {
        margin: 1rem;
      }

      @media screen and (max-width: 990px) and (min-width: 768px) {
        padding: 0rem 2rem;
      }

      & .e-reliable-logo-navbar {
        display: flex;
        align-items: center;

        @media only screen and (max-width: 768px) {
          display: none;
        }
      }

      & .logos {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        gap: 1rem;

        @media only screen and (max-width: 768px) {
          justify-content: initial;
        }

        & .garage-michel {
          display: flex;
          flex-direction: column;
          margin-left: 0.6rem;
          height: 100%;
          align-items: center;
          padding-right: 0.5rem;
          border-right: 1px solid rgba(39, 80, 159, 0.2);

          @media only screen and (max-width: 768px) {
            width: auto;
            margin-left: 0rem;
          }

          & .client-logo {
            max-width: 10rem;
            max-height: 5rem;
          }

          & .user-name {
            background: $blue-5;
            font-style: normal;
            font-family: 'Open Sans';
            line-height: 1.7rem;
            align-items: center;
            font-size: 1rem;
            font-weight: 600;

            @include themify($themes) {
              color: themed('main');
            }

            height: 2rem;
            padding-left: 1rem;
            padding-right: 1rem;
            border-radius: 0.3rem;
            white-space: nowrap;
          }
        }
      }

      & .title-automotor {
        color: #27509f;
        font-size: 2.6rem;
        line-height: 3.2rem;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;

        @media screen and (max-width: 990px) {
          font-size: 1.8rem;
          line-height: 1rem;
        }

        @media screen and (max-width: 664px) {
          display: none;
        }

        .subtitle {
          text-align: center;
          display: block;
          width: 100%;
          font-family: "Open Sans", sans-serif;
          font-size: 1.3rem;

          @media screen and (max-width: 990px) {
            font-size: 1.2rem;
            line-height: 1.8rem;
          }

          @include themify($themes) {
            color: themed("main");
          }

          letter-spacing: 0;
          margin: 0 auto;
          padding: 0.5rem 0;
        }


      }

      & .tabs {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        gap: 1.2rem;

        & .tab {
          button {
            width: 22rem;
            height: 4.2rem;
            font-size: 1.2rem;
            font-family: 'Open Sans', sans-serif;
            line-height: 1.7rem;
            font-weight: 600;
          }
        }

        .active {
          button {
            @include themify($themes) {
              background-color: themed('main');
            }

            color: $white;
          }
        }

        .disable {
          button {
            cursor: not-allowed;
            opacity: 0.5;

            &:hover {
              @include themify($themes) {
                color: themed('main');
              }

              background-color: $icon-background-color;
            }
          }
        }
      }

      & .right-part-navbar {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;

        & .navbar-actions-container {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          padding: 0 1rem;
          box-sizing: border-box;

          & .logout-button {
            width: auto;
            height: auto;
          }

          & .user-settings {
            display: flex;
            align-items: center;
            gap: 1.7rem;



            & .mini-dropdowns {
              display: flex;
              align-items: flex-end;
              justify-content: center;
              flex-direction: column;
              gap: 0.6rem;
              margin-right: 1rem;

              & .reference-identification {
                display: flex;
                gap: 1rem;
                justify-content: flex-end;
                font-weight: 400;
                font-size: 2rem;

                @include themify($themes) {
                  color: themed('main');
                }

                & .icon-container {
                  background-color: $blue-5;
                  border-radius: 50%;
                  width: 30px;
                  height: 30px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  & .icon {
                    font-size: 14px;
                  }
                }

              }

              & .language-container {
                display: flex;
                align-items: center;
                gap: 1rem;
                justify-content: flex-end;

                & .label {
                  font-weight: 400;
                  font-size: 1.2rem;

                  @include themify($themes) {
                    color: themed('main');
                  }

                  @media only screen and (max-width: 768px) {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}