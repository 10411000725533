@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.notification-modal {
  padding: 0 1rem;
  &-header {
    display: flex;
    justify-content: space-between;
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 1.4rem;
    border-bottom: 1px solid $blue-4;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    &-close {
      cursor: pointer;
      &:hover {
        opacity: 75%;
      }
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 1.4rem;
    &-date {
      font-weight: bold;
    }
    &-message {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      &-label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
      }
    }
    &-file {
      @include themify($themes) {
        color: themed('main');
      }
      display: flex;
      gap: 1rem;
      font-size: 1.2rem;
      border: 1px dashed $blue-1;
      border-radius: 2px;
      padding: 0.5rem;
      max-width: fit-content;
      & .notification-download-icon {
        cursor: pointer;
        &:hover {
          opacity: 75%;
        }
      }
      & .notification-filename {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}
