@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.home {

  &,
  .retailer-catalog-search {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 3.7rem;
  }

  & .header-search {
    margin-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1.3rem;

    & .title {
      font-size: 2.5rem;
      font-weight: 200;
      word-spacing: 3px;

      @include themify($themes) {
        color: themed('main');
      }
    }

    & .subtitle {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      height: 2.5rem;
      padding: 0 2rem;
      border: $light-blue 1px solid;
      border-radius: 1.55rem;
      color: $light-blue;
      font-size: 1.2rem;
    }

    & .header-search-input {
      width: 61.7rem;
    }
  }

  & .general-search {
    display: flex;
    flex-direction: row;
    height: 36.2rem;
    gap: 1rem;
    padding: 0 1rem;
    z-index: 2;

    @media screen and (min-width: 991px) and (max-width: 1366px) {
      flex-direction: column;
      height: 100%;
    }

    .search-inputs-container {
      position: relative;

      &.search-tyre div[class$='-dropdown-content'] {
        top: 65%;
      }

      &.search-battery div[class$='-dropdown-content'] {
        top: 95%;
      }

      div[class$='-dropdown-content'] {
        min-width: 1rem;
        width: calc(100% - 3rem);

        & div[class$='-result-content'] {
          width: 100%;

          & .suggestion-list-item {
            &:last-child {
              border-radius: 0 0 1rem 1rem;
            }

            & .suggestion-item-title {
              display: grid;
              grid-template-columns: 30% 20% 50%;
              width: 100%;
              gap: 0.5rem;
            }
          }
        }
      }
    }
  }

  & .arrow-icon {
    @include themify($themes) {
      color: themed('main');
    }

    font-size: 2rem;
  }
}

.retailer-home-content {
  display: flex;
  flex-flow: column nowrap;
  gap: 3.7rem;
  margin-bottom: 2rem;
}

.promotions {
  padding: 1rem 0;
  position: relative;

  &:after {
    content: '';
    width: 100%;
    height: 50%;
    background: $white;
    display: block;
    position: absolute;
    bottom: 0;
    z-index: -1;
  }

  .promotions-slider {
    .swiper-container {
      div.swiper {
        border-radius: 0;
      }
    }

    .items {
      margin: 0 auto;
    }
  }
}

.news {
  display: flex;
  flex-direction: row;
  gap: 13rem;
  position: relative;
  padding-top: 0.6rem;
  padding-bottom: 0.8rem;
  //align-items: center;
  align-items: flex-start;
  justify-content: center;

  & .promotions-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.4rem;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    transform: translateY(-50%);

    & .promotions {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 1.6rem;
    }
  }

  & .main-news {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  & .side-news {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2.8rem;
    margin-bottom: 4rem;

    & .side-news-button {
      width: 19.5rem;
    }
  }
}

/* Responsive CSS Styles */

.app-layout-desktop {
  .home {
    padding-bottom: 8rem;

    .retailer-catalog-search {
      & .retailer-catalog-items {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 2rem;
      }

      & .general-search {
        &>div[class*='search-card'] {
          border-radius: 0;
          box-shadow: none;

          &:nth-child(2) {
            @include themify($themes) {
              background: themed('secondary');
            }
          }

          .reference-label {
            padding: 0 0.5rem;
          }
        }

        &>div[class*='search-card'],
        & div[class$='-dropdown-content'] {
          label {
            margin-bottom: 0.5rem;
            display: inline-block;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1440px) {
    .home {
      .header-search {
        padding: 0 6rem;
        text-align: center;
      }
    }

    .news {
      gap: 3rem;
    }
  }

  @media screen and (max-width: 1200px) {
    .home {
      .general-search {
        padding: 0 2rem;

        .search-card-refs {
          .ref-search-inputs-container {
            .reference-container {
              gap: 2rem;
              flex-wrap: wrap;
            }
          }
        }
      }
    }

    .news {
      .main-news {
        .main-news-item img {
          width: 41.5rem;
          height: 27.6rem;
        }
      }

      .side-news {
        gap: 1rem;

        .side-news-item {
          & img {
            width: 16.3rem;
            height: 10.9rem;
          }
        }
      }
    }
  }

  // Temporary styles (testing in progress)
  .container-fluid-large {
    max-width: $container-width-full;
    margin: 0 auto;

    @media screen and (max-width: 1440px) {
      //max-width: 125.4rem;
    }

    @media screen and (max-width: 1360px) {
      max-width: 107.5rem;
    }

    @media screen and (max-width: 1200px) {
      max-width: 96rem;
    }

    @media screen and (max-width: 1000px) {
      max-width: 92rem;
    }
  }
}

.app-layout-mobile,
.app-layout-tablet {
  .home {
    margin: 0 auto 2rem;

    .retailer-catalog-search {
      padding-top: 2rem;

      @include themify($themes) {
        background-color: themed('secondary');
      }

      padding-bottom: 3.7rem;

      & .retailer-catalog-items {
        display: flex;
        justify-content: center;
        gap: 3rem;

        & .swiper-wrapper {
          & .swiper-slide {
            @media screen and (max-width: 768px) {
              max-width: 20rem;
            }

            & div:first-child {
              margin: 0;
            }
          }
        }
      }
    }

    .header-search {
      padding: 0 2rem;
      gap: 1.8rem;
      margin: 0;
      text-align: center;
    }

    // .title {
    //   color: $black;
    // }

    .subtitle {
      @include themify($themes) {
        background-color: themed('secondary-3');
      }

      border-color: $grey-7;
      padding: 0.3rem 2rem;
      margin: 0.3rem 0;
      font-weight: 700;
      display: inline-table;
      border-radius: 2.5rem;
      height: auto;
    }

    .general-search {
      flex-flow: column wrap;
      height: auto;
      padding: 0 2rem;
      margin: 0;
      gap: 1.2rem;
      position: relative;
      z-index: 2;
    }

    .news {
      padding-top: 2.5rem;
      flex-wrap: wrap;
      gap: 2rem;
    }

    .main-news,
    .side-news {
      padding: 0 2rem;
    }

    .side-news {
      gap: 1.5rem;

      .side-news-button {
        width: 100%;
        box-shadow: 0.2rem 0.2rem 1rem #0000000a;
        margin: 1.5rem auto;

        .default-button {
          font-weight: 700;
          font-size: 1.2rem;
          line-height: 1.6rem;
          border-radius: 0.5rem;
        }
      }
    }
  }
}

.app-layout-tablet {
  .home {
    .general-search {
      max-width: 61.7rem;
      align-self: center;
      width: 100%;
    }

    .promotions-slider {
      .swiper-wrapper {
        .swiper-slide {
          max-width: 62rem;
        }
      }
    }
  }
}

.app-layout-mobile {
  .home {
    .header-search {
      .title {
        font-size: 2rem;
        line-height: 2.8rem;
        font-weight: 400;
      }

      .subtitle {
        font-size: 1.1rem;
        line-height: 1.5rem;
      }

      .header-search-input {
        width: 100%;
        margin: 0;
      }
    }
  }
}