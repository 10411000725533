@import 'src/assets/sass/_variables';
@import 'src/assets/sass/themes';

.catalog-parts {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  height: 100%;
  margin-bottom: 2rem;

  .catalog-parts-search {
    display: flex;
    justify-content: flex-end;



    & .catalog-search-input {
      @media screen and (max-width: 990px) {
        width: 100%;
        margin: 1rem 0;
      }
    }
  }

  .catalog-parts-page {
    display: flex;
    width: 100%;
    justify-content: center;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    .catalogs-spinner {
      position: absolute;
      height: 50vh;
      width: 100%;
      background: none;

      @media screen and (max-width: 768px) {
        width: 100vw;
        position: inherit;
      }

      @media screen and (max-width: 990px) and (min-width: 768px) {
        width: 100vw;
        position: inherit;
      }
    }

    .categories-list {
      position: relative;

      &.loading {
        height: 50vh;
        width: 100%;
        background: none;

        @media screen and (max-width: 768px) {
          width: 100vw;
          position: inherit;
        }

        @media screen and (max-width: 990px) and (min-width: 768px) {
          width: 100vw;
          position: inherit;
        }
      }

      h1 {
        display: none;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      & .categories-no-data {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        font-size: 2rem;
        font-weight: 600;

        @include themify($themes) {
          color: themed('main');
        }

        text-align: center;
      }

      .parts-list {
        width: 310px;
        height: 55px;
        cursor: pointer;
        display: flex;
        align-items: center;

        &.selected {
          @include themify($themes) {
            background-color: themed('categoriesSidebar', 'active');
          }

          color: $white;

          &>p {
            color: $white !important;
          }
        }

        @media screen and (max-width: 768px) {
          width: 100%;
        }

        p {
          width: 234px;
          font-family: 'Open Sans', sans-serif;
          font-size: 17px;
          font-weight: bold;
          letter-spacing: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @include themify($themes) {
            color: themed('categoriesSidebar', 'text');
          }
        }

        img {
          margin: 0 1rem;
        }
      }
    }

    .masonry-content {
      padding: 50px;

      @include themify($themes) {
        color: themed('main');
        background: themed('secondary');
      }

      width: 100%;
      overflow: auto;

      @media screen and (max-width: 768px) {
        overflow: visible;
        max-height: 100%;
      }

      .return {
        font-size: 16px;
        padding: 2rem 0 0rem 1.5rem;

        @media screen and (max-width: 768px) {
          border-bottom: 1px solid #b7bcc4;
          padding: 2rem 0 1.5rem 1.5rem;
        }
      }

      @media screen and (max-width: 768px) {
        position: static;
        padding: 0;

        // overflow: scroll;
        // height: calc(100vh + 160px);
      }

      .my-masonry-grid {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-left: -30px;
        width: auto;

        @media screen and (max-width: 768px) {
          flex-direction: column;
          width: 100%;
          height: 100%;
        }
      }

      .my-masonry-grid_column {
        padding-left: 30px;
        background-clip: padding-box;

        @media screen and (max-width: 768px) {
          flex-direction: column;
          width: 100% !important;
        }
      }

      .my-masonry-grid_column>div {
        margin-bottom: 30px;
      }

      .items {
        @media screen and (max-width: 768px) {
          height: 100%;
        }

        .item {
          display: flex;
          flex-direction: column;
          gap: 10px;

          @media screen and (max-width: 768px) {
            padding: 2rem 0rem 0rem 3rem;
          }

          .title {
            padding: 0;
            font-size: 16px;
            font-weight: 700;
            line-height: 22px;
          }

          .subcategory-item {
            position: relative;
            display: flex;
            text-align: left;
            gap: 1rem;

            .subcategory {
              font-size: 14px;
              cursor: pointer;
              line-height: 16px;
              position: relative;

              @include themify($themes) {
                color: themed('catalogVehicleTabs', 'text');
              }

              text-decoration: none;

              &.disabled {
                color: grey;
                cursor: text;
                z-index: 0;
              }


            }

            .manual-icon {
              position: absolute;
              left: -25px;
              cursor: pointer;

              font-size: 2rem;
              line-height: 1.3rem;
            }
          }

        }
      }
    }
  }

  /* Responsive CSS Styles */

  .app-layout-desktop {
    .catalog-parts-page {
      position: relative;

      @include themify($themes) {
        background-color: themed('secondary');
      }

      .categories-list {
        &.categories-sidebar {
          width: $vehicle-page-sidebar-width;

          .parts-list {
            width: 100%;
            margin: 0;
          }
        }
      }

      .content {
        &.categories-content {
          position: absolute;
          width: calc(100% - $vehicle-page-sidebar-width);
          right: 0;
          height: 100%;
          padding: 0;
          overflow: hidden;
          max-height: none;

          .items {
            padding: 5rem;
            overflow: auto;
            height: calc(100% - 10rem);
          }
        }
      }
    }
  }
}