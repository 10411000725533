@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.message-supplier-container {
  @include themify($themes) {
    color: themed('main');
  }
  font-size: 1.3rem;
  font-weight: 300;
  text-align: left;

  .message-supplier {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 2.5rem;
    @include themify($themes) {
     background-color: themed('secondary');
}
    margin: 1rem 0;

    .supplier {
      width: 45%;
      font-weight: bold;
    }

    .message {
      width: 40%;
    }

    .created-time {
      width: 15%;
    }

    p {
      margin: 0;
    }

    &.unseen {
      font-weight: bold;
      background-color: #ecf3ff;

      .created-time span {
        position: relative;

        &:after {
          content: '';
          background-color: red;
          border-radius: 100%;
          position: absolute;
          width: 10px;
          height: 10px;
          top: -0.5rem;
          left: 0;
        }
      }
    }
  }
}
