.sidebar-list-container {
  .list-heading {
    color: #28549c;
  }

  .check:nth-child(even) {
    background-color: #e0e6ef;
  }
}

/* Responsive CSS Styles */

.app-layout-tablet,
.app-layout-mobile {
  .sidebar-list-container {
    .button-link-container {
      padding: 0 1rem;
    }
  }
}
