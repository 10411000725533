@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.technical-documentation-adjust {
  display: flex;
  gap: 1rem;
  width: 100%;

  & .adjust-sidebar {
    width: 100%;

    @media screen and (max-width: 990px) and (min-width: 768px) {
      width: 60%;
    }
  }

  & .adjust-content {
    width: 100%;
    height: 100%;
  }

  & .adjust-module-mobile {
    width: 100%;
  }
}

/* Responsive CSS Styles */

.app-layout-mobile {
  & .adjust-content {
    padding: 0 2rem;
    width: calc(100% - 4rem);
  }
}
