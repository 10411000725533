@import "src/assets/sass/variables";
@import "src/assets/sass/themes";

.mecadepot-content {
  width: 100%;

  .slide-show-brand {
    margin: 1rem 0rem 0;
    position: relative;
    width: 100%;

    .brand-slider-home {
      height: 8rem;
      padding: 1rem 0rem;
      background-color: #f6f6f6;

      .swiper-slide-home {
        max-height: 100%;

        .image {
          max-height: 8rem;
          max-width: 15rem;
        }
      }
    }
  }

  .marquee {
    width: 100%;
    height: 30px;
    overflow: hidden;
    position: relative;
    line-height: 30px;
    padding-top: 1rem;
    padding-right: 1.7rem;

    @media screen and (max-width: 768px) {
      padding-right: 0;
    }
  }

  .marquee span {
    font-size: 2.5rem;
    color: red;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    text-align: center;
    font-weight: bold;
    word-spacing: 3px;
    white-space: nowrap;

    /* Starting position */
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    /* Apply animation to this element */
    -moz-animation: marquee 15s linear infinite;
    -webkit-animation: marquee 15s linear infinite;
    animation: marquee 15s linear infinite;
  }

  /* Move it (define the animation) */
  @-moz-keyframes marquee {
    0% {
      -moz-transform: translateX(100%);
    }

    100% {
      -moz-transform: translateX(-100%);
    }
  }

  @-webkit-keyframes marquee {
    0% {
      -webkit-transform: translateX(100%);
    }

    100% {
      -webkit-transform: translateX(-100%);
    }
  }

  @keyframes marquee {
    0% {
      -moz-transform: translateX(100%);
      /* Firefox bug fix */
      -webkit-transform: translateX(100%);
      /* Firefox bug fix */
      transform: translateX(100%);
    }

    100% {
      -moz-transform: translateX(-100%);
      /* Firefox bug fix */
      -webkit-transform: translateX(-100%);
      /* Firefox bug fix */
      transform: translateX(-100%);
    }
  }

  //}

  .mecadepot-home {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 990px) {
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }

    & .general-search {
      display: flex;
      flex-direction: column;
      width: 41.5rem;
      gap: 1rem;
      z-index: 2;

      &>div {
        border-radius: 1rem;
        box-shadow: none;
        overflow: hidden;
      }

      &>div.general-search-group .search-card {
        border-radius: 1rem;
        box-shadow: none;
      }

      @media screen and (min-width: 990px) and (max-width: 1440px) {
        width: 42rem;
      }

      div[class*="history-page__wrapper-tabs_content"] {
        height: 40rem;
        overflow-x: hidden;
        overflow-y: auto;

        .spinner {
          @include themify($themes) {
            background-color: themed("secondary");
          }
        }
      }

      div[class*="history-page__wrapper-tabs"] {
        .tabcard-container {
          width: 38rem;
        }
      }

      div[class="history-page__wrapper"] {
        @include themify($themes) {
          background-color: themed("secondary");
        }

        border-radius: 1rem;
        box-shadow: 0.2rem 0.2rem 1rem rgba(0, 0, 0, 0.0392156863);
      }

      .general-search-group {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      & .vehicle-selector-search-card {
        .vehicle-selector-tabs-container {
          .tab {
            .dropdowns-container {
              display: flex;
              flex-direction: column;

              .truck-selector-submit {
                width: 100%;
              }
            }

            .form label {
              display: none;
            }
          }
        }
      }

      .vehicle-selector-tabs-container {
        .tab {
          .form label {
            display: none;
          }
        }
      }

      .history-page__wrapper-pagination {
        padding-top: 0;

        .pagination {
          padding-top: 0;
          padding-bottom: 0;
        }
      }

      @media screen and (min-width: 991px) and (max-width: 1366px) {
        flex-direction: column;
        height: 100%;
      }

      .search-inputs-container {
        position: relative;

        &.search-tyre div[class$="-dropdown-content"] {
          top: 65%;
        }

        &.search-battery div[class$="-dropdown-content"] {
          top: 95%;
        }

        div[class$="-dropdown-content"] {
          min-width: 1rem;
          width: calc(100% - 3rem);

          & div[class$="-result-content"] {
            width: 100%;

            & .suggestion-list-item {
              &:last-child {
                border-radius: 0 0 1rem 1rem;
              }

              & .suggestion-item-title {
                display: grid;
                grid-template-columns: 30% 20% 50%;
                width: 100%;
                gap: 0.5rem;
              }
            }
          }
        }
      }
    }

    .home {
      width: 70%;

      @media screen and (min-width: 990px) and (max-width: 1050px) {
        width: 59%;
      }

      @media screen and (min-width: 1050px) and (max-width: 1150px) {
        width: 62%;
      }

      @media screen and (min-width: 1150px) and (max-width: 1250px) {
        width: 65%;
      }

      @media screen and (min-width: 1250px) and (max-width: 1340px) {
        width: 68%;
      }

      &,
      .retailer-catalog-search {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 3.7rem;

        @media screen and (max-width: 990px) {
          padding-top: 0px;
          width: 100%;
        }
      }

      .general-search {
        display: flex;

        @media screen and (max-width: 990px) {
          width: 100%;
          padding: 2rem;
          box-sizing: border-box;
        }

        .search-card {
          .card-title {
            width: calc(100% - 3.1rem);
          }
        }
      }

      & .header-search {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1.3rem;
        margin-top: 0rem;

        & .title {
          font-size: 2.5rem;
          font-weight: 200;
          word-spacing: 3px;

          @include themify($themes) {
            color: themed("main");
          }
        }

        & .subtitle {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          height: 2.5rem;
          padding: 0 2rem;
          border: $light-blue 1px solid;
          border-radius: 1.55rem;
          color: $light-blue;
          font-size: 1.2rem;
        }

        & .header-search-input {
          width: 61.7rem;

          @media screen and (max-width: 990px) {
            width: 100%;
          }

          @media screen and (min-width: 990px) and (max-width: 1150px) {
            width: 100%;
          }

        }
      }

      & .arrow-icon {
        @include themify($themes) {
          color: themed("main");
        }

        font-size: 2rem;
      }

      .promotions {
        padding: 1rem 0;
        position: relative;
        // min-height: 13rem;

        &:after {
          content: "";
          width: 100%;
          height: 50%;
          background: $white;
          display: block;
          position: absolute;
          bottom: 0;
          z-index: -1;
        }

        .promotions-slider {
          .swiper-container {
            div.swiper {
              border-radius: 0;
            }
          }

          .items {
            margin: 0 auto;
          }
        }
      }
    }

    .retailer-home-content {
      display: flex;
      margin-bottom: 2rem;

      .container-top-body {
        @media screen and (max-width: 990px) {
          margin: 0px;
        }
      }
    }
  }


}

.app-layout-mobile {
  .mecadepot-content {
    .brand-slider-home {
      max-width: 100%;
    }
  }
}