@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.list-container-supp {
  .heading {
    color: #28549c;
  }

  .list-container-item {
    display: flex;
    flex-direction: row;
    width: 100%;

    .checkbox-container-supp {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1rem;

      .checkbox-container {
        input[type='checkbox'] {
          @include themify($themes) {
            color: themed('main');
          }
          cursor: pointer;
          width: 1.2em;
          height: 1.2em;
        }

        input[type='checkbox']::before {
          border: 0.2rem solid $blue;
        }
      }
    }
  }

  .active {
    @include themify($themes) {
      background: themed('main');
    }

    div {
      p {
        color: $white !important ;
      }

      input[type='checkbox'] {
        accent-color: $blue-3 !important;
        cursor: pointer;
        width: 1.2em;
        height: 1.2em;
      }

      input[type='checkbox']::before {
        border: 0.2rem solid $blue-3 !important;
      }
    }
  }
}
