@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.favorite-button {
  .spinner-container {
    position: relative;
    width: 6rem;
    height: 5rem;

    .spinner {
      right: 1rem;
      left: unset;
    }
  }

  .favorite {
    padding-right: 1rem !important;
  }
}