@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.news-view {
  display: flex;
  flex-direction: column;
  padding: 5rem;
  position: relative;

  &-spinner {
    margin-top: 10rem;
  }

  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    &-contacts {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.3rem;
      font-size: 1.7rem;
      @include themify($themes) {
        color: themed('main');
      }

      & svg {
        font-size: 2.3rem;
      }
    }

    & .return-btn {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: 1.4rem;
      @include themify($themes) {
        color: themed('main');
      }
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        opacity: 75%;
      }
    }

    &-title {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;

      & .date {
        display: flex;
        align-items: center;
        justify-content: center;
        @include themify($themes) {
          color: themed('main');
        }
        font-weight: 400;
        width: 8rem;
        height: 2rem;
        font-size: 1.4rem;
        margin-bottom: 0.3rem;
      }

      & .title {
        font-size: 2.4rem;
        line-height: 3.2rem;
        font-weight: 700;
        margin-bottom: 1.6rem;
        @include themify($themes) {
          color: themed('main');
        }
        @media screen and (max-width: 520px) {
          font-size: 1.6rem;
          white-space: break-spaces;
          padding-left: 1rem;
          box-sizing: border-box;
        }
      }
    }
  }

  &-body {
    display: flex;
    flex-flow: row nowrap;
    gap: 3rem;

    &-media {
      img,
      .player {
        max-height: 36rem;
        max-width: 64.2rem;
        border-radius: 1rem;
      }

      .player {
        height: 36em;
        width: 64.2rem;
        overflow: hidden;
        position: relative;
        z-index: 0;
        margin-bottom: 0.5rem;
        cursor: pointer;

        div.react-player__preview {
          position: relative;
          z-index: 1;
        }

        div.react-player__preview::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 1;
        }

        div.react-player__preview::before {
          opacity: 0.6;
          background: $blue-10;
          z-index: 2;
        }

        .button-play {
          font-size: 5rem;
          color: #fff;
          z-index: 2;
          cursor: pointer;
        }
      }
    }

    &-content {
      width: 100%;
      gap: 1rem;
      font-size: 1.5rem;
      @include themify($themes) {
        color: themed('main');
      }
      text-align: justify;

      & p {
        margin: auto;
        text-indent: 4rem;
      }
    }
  }

  &-other {
    display: flex;
    flex-direction: column;
    position: relative;

    & .divder {
      align-self: center;
      width: 30rem;
      height: 1px;
      border-bottom: 1px solid $blue-1;
      margin: 3rem 0;
    }

    & .relevant-news {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      position: relative;

      &.loading {
        height: 20rem;
        width: 100%;
      }

      &-title {
        font-size: 2.4rem;
        line-height: 3.2rem;
        font-weight: 700;
        margin-bottom: 1.6rem;
        @include themify($themes) {
          color: themed('main');
        }
        text-align: center;
      }

      &-items {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        gap: 20rem;
      }

      &-item {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        align-items: center;
        @include themify($themes) {
          color: themed('main');
        }

        & img {
          max-width: 20rem;
          max-height: 15rem;
          border-radius: 1rem;
          margin-bottom: 1rem;
        }

        &-content {
          &-date {
            @include themify($themes) {
              color: themed('main');
            }
            font-weight: 400;
            width: 8rem;
            height: 2rem;
            font-size: 1.4rem;
            margin-bottom: 1rem;
          }

          &-title {
            font-size: 2.3rem;
            line-height: 2.4rem;
            font-weight: 700;
            margin-bottom: 1.6rem;
            @include themify($themes) {
              color: themed('main');
            }
            text-decoration: none;
            cursor: pointer;

            &:hover {
              opacity: 75%;
            }
          }
        }
      }

      .swiper-container {
        .swiper-slide {
          max-width: 45rem;
        }
      }
    }
  }
}

/* Responsive CSS Styles */

.app-layout-desktop {
  @media screen and (max-width: 1280px) {
    .news-view {
      &-body {
        gap: 2rem;

        &-media {
          & img,
          & .player {
            max-height: 30.6rem;
            max-width: 54rem;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .news-view {
      &-body {
        &-media {
          & img,
          & .player {
            max-height: 24.7rem;
            max-width: 44rem;
          }
        }
      }
    }
  }
}

.app-layout-tablet,
.app-layout-mobile {
  .news-view {
    padding: 2rem;

    &-body {
      flex-direction: column;

      &-media {
        & img,
        & .player {
          max-width: 100%;
          display: block;
          margin: 0 auto;
        }
      }
    }
  }
}

.app-layout-mobile {
  .news-view {
    &-body {
      &-media {
        & img,
        & .player {
          display: block;
          margin: 0 auto;
        }
      }
    }
  }
}
