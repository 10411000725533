@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.vehicle-equivalence-item-list {
  //padding: 3rem 0;
  //display: flex;
  //align-items: center;
  //justify-content: center;
  //flex-wrap: wrap;
  //gap: 1rem;
  @media screen and (max-width: 990px) {
    @include themify($themes) {
      background-color: themed('secondary');
    }
  }

  .vehicle-equivalence-item {
    width: 25rem;
    cursor: pointer;
    padding: 1rem 2rem;

    .content {
      .title {
        p {
          font-weight: bold;
          font-size: 1.5rem;
          line-height: 2rem;
          margin: 0.2rem 0;

          @media screen and (max-width: 990px) {
            width: 25rem;
            white-space: normal;
          }
        }
      }

      .description {
        p {
          font-size: 1.2rem;
          line-height: 1.7rem;
          margin: 0.2rem 0;

          @media screen and (max-width: 990px) {
            width: 25rem;
            white-space: normal;
          }
        }
      }
    }
  }
}
