@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.container-top-offer {
  width: 100%;
  height: 0;
  overflow: hidden;

  .top-offer-title {
    position: sticky;
    top: 0;

    h1 {
      @include themify($themes) {
        color: themed('main');
      }

      width: 100%;
      text-align: center;
    }
  }


  .no-parts-data-fex {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    flex-direction: column;

    .top-offer-spinner {
      top: 10rem;
    }

    h1 {
      @include themify($themes) {
        color: themed('main');
      }

      width: 100%;
    }
  }



}



.container-top-offer-show {
  height: 28rem;
  transition: height 1s ease;

  @media screen and (max-width: 1220px) {
    height: 54rem;
  }

}