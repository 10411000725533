@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.presentation-car-parts {
  padding: 0 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  & .car-parts-header {
    display: flex;
    align-items: center;
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 1.5rem;
    width: 100%;
    margin-bottom: 1rem;

    & .car-parts-return {
      cursor: pointer;
      white-space: nowrap;
      z-index: 1;

      &:hover {
        opacity: 75%;
      }
    }

    & .car-parts-breadcrumb {
      width: 100%;
      font-weight: 500;
      text-align: center;
      margin-left: -6.5rem;
      & .main-group {
        font-weight: 600;
      }

      & .sub-group {
        font-weight: 700;
      }

      & .sub-sub-group {
        font-weight: 800;
      }
    }
  }
}
