@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.modal-favorite-list {
  min-width: 59rem;

  @include themify($themes) {
    background-color: themed('secondary-2');
  }

  @include themify($themes) {
    color: themed('main');
  }

  font-weight: normal;
  position: relative;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    min-width: auto;
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;

    @media screen and (max-width: 768px) {
      top: 1rem;
    }

    svg {
      font-size: 2.4rem;
    }
  }

  .modal-dialog {
    .modal-container {
      padding: 0 2rem;

      .modal-header {
        font-weight: bold;
        font-size: 2.5rem;
        line-height: 3.4rem;
        padding-bottom: 2rem;

        @include themify($themes) {
          border-bottom: 2px solid themed('favorite', 'bg');
        }
      }

      .modal-body {
        .list {
          max-height: 50rem;
          overflow-y: auto;
        }

        .new-list-container {
          position: relative;
          padding: 2rem 0;

          .new-list-button {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            @include themify($themes) {
              background-color: themed('favorite', 'bg');
            }

            width: 17.2rem;
            height: 4.2rem;

            svg {
              padding-right: 1rem;

              path {
                @include themify($themes) {
                  fill: themed('favorite', 'bgFill');
                }
              }

            }

            font-size: 1.8rem;
            line-height: 2.4rem;
          }

          .new-list-input {
            width: 100%;

            input {
              width: 95%;

              height: 3.2rem;
              border: 1px solid $border-color;
              border-radius: 0.8rem;
              font-size: 1.6rem;
              line-height: 2.4rem;
              outline: none;
              padding-left: 1rem;
              padding-right: 1rem;

              @include themify($themes) {
                color: themed('main');
              }

              @include themify($themes) {
                background-color: themed('mainHover');
              }

              @include themify($themes) {
                border-color: themed('main');
              }

              &:focus {
                @include themify($themes) {
                  border-color: themed('main');
                }
              }

              &.error {
                border: 1px solid $red;

                @include themify($themes) {
                  color: themed('main');
                }
              }
            }
          }
        }

        .actions {
          .actions-content {
            height: 4.8rem;
            display: flex;
            justify-content: space-between;

            .cancel {
              width: 17.4rem;

              @media screen and (max-width: 768px) {
                width: 15rem;
              }

              button {
                height: 100%;
              }
            }

            .save {
              @media screen and (max-width: 768px) {
                width: 15rem;
                margin-left: 1rem;
              }

              width: 32rem;

              button {
                height: 100%;
              }
            }

            .save-favorites {
              width: 100%;
              height: 4.8rem;

              button {
                height: 100%;
              }

              &.disabled {
                button {
                  background-color: #92a3c8;
                }
              }
            }
          }
        }
      }
    }
  }
}