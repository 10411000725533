@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.equivalence-title {
  display: flex;
  @include themify($themes) {
    color: themed('main');
  }
  font-size: 1.3rem;
  line-height: 1.8rem;
  padding: 0 1rem;
  .icon {
    padding-right: 0.5rem;
    svg {
      width: 1.4rem;
      height: 1.4rem;
      @include themify($themes) {
        rect,
        ellipse {
          fill: themed('main');
        }
      }
    }
  }
}
