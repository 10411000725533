@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.automotor-tariff-cards {
  .divReturnButton {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    @media screen and (max-width: 990px) {
      width: 100%;
    }

    .returnButton {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;
      font-size: 1.6rem;

      @include themify($themes) {
        color: themed('main');
      }

      cursor: pointer;

      padding: 1rem;
      box-sizing: border-box;

      .icon {
        font-size: 1.6rem;
        cursor: pointer;

        @include themify($themes) {
          color: themed('main');
        }
      }
    }
  }

  .automotor-tariff-espace {
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
    justify-content: center;
    width: 100%;


    @media screen and (max-width: 990px) {
      align-items: center;
      width: 100%;
    }

    & .form-date-input-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      position: relative;

      & .form-react-picker-dropdown {
        cursor: pointer;

        &.disable {
          pointer-events: none;
        }
      }

      & .form-date-picker {
        position: absolute;
        z-index: 101;
        top: 8rem;

        & .react-datepicker {
          display: flex;
          position: relative;

          border: none;
          box-shadow: 0.5rem 0.8rem 2.1rem rgb(0 0 0 / 15%);
          font-size: 1.3rem;

          &__month-container {
            & .react-datepicker__header {
              background: none;
              border-bottom: none;
              padding: 1.2rem 1rem;

              & .react-datepicker__current-month {
                font-size: 1.4rem;
              }
            }

            & .react-datepicker__week {
              .react-datepicker__day {
                width: 3rem;
                line-height: 3rem;

                &--in-range {
                  @include themify($themes) {
                    background-color: themed('bgActive');
                  }
                }

                &--selected {
                  @include themify($themes) {
                    background-color: themed('main');
                  }
                }

                &--in-selecting-range {
                  @include themify($themes) {
                    background-color: themed('mainHover');
                  }
                }

                &--keyboard-selected {
                  @include themify($themes) {
                    background-color: themed('main');
                  }
                }
              }
            }
          }
        }
      }

      & .select-dropdown {
        width: 100%;
        height: 4.2rem;
        border-radius: 0.5rem;
        font-size: 1.4rem;
        font-weight: 400;

        @include themify($themes) {
          color: themed('main');
        }

        border: none;

        &.disabled {
          opacity: 30%;
          pointer-events: none;
          user-select: none;
        }

        [class$='-control'] {
          @include themify($themes) {
            color: themed('main');
          }

          border: none;

          @include themify($themes) {
            background-color: themed('mainHover');
          }

          height: 4.2rem;
          box-shadow: none;

          &:hover {
            border: none;
          }
        }

        [class$='-placeholder'] {
          @include themify($themes) {
            color: themed('main');
          }

          font-size: 1.4rem;
          font-weight: 400;
        }

        [class$='-indicatorContainer'] {
          @include themify($themes) {
            color: themed('main');
          }
        }

        [class$='-singleValue'] {
          @include themify($themes) {
            color: themed('main');
          }
        }

        [class$='-indicatorSeparator'] {
          background: none;

          @include themify($themes) {
            color: themed('main');
          }
        }

        [class$='-indicator'] {
          @include themify($themes) {
            background: themed('main');
          }

          color: $white;
        }
      }

      & .label {
        @include themify($themes) {
          color: themed('main');
        }

        font-weight: 700;
        font-size: 1.8rem;
        line-height: 2.5rem;
        text-align: center;
      }
    }

    .wrapper-quantity {
      justify-content: center;

      .num {
        @include themify($themes) {
          background: themed('bgActive');
        }

        @include themify($themes) {
          color: themed('main');
        }

        &:focus-visible {
          outline: none;
        }
      }
    }

    .tariff-header {
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      flex-direction: column;

      .tariff-title {
        height: 3.3rem;
        font-weight: 700;
        font-size: 2.4rem;

        @include themify($themes) {
          color: themed('main');
        }

        border-bottom: $blue-1 solid 0.3rem;
        width: 46rem;

        @media screen and (max-width: 768px) {
          font-size: 1.8rem;
          line-height: 2rem;
          margin: 1.9rem auto 1.5rem;
          width: 100%;
        }
      }

      .tariff-btn {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-top: 6rem;

        @media screen and (max-width: 768px) {
          flex-direction: column;
          gap: 1rem;
          align-items: center;
          margin-top: 2rem;
        }

        &-export {
          display: flex;
          gap: 1rem;

          @media screen and (max-width: 768px) {
            flex-direction: column;

          }

        }

        &-comand {
          &.disable {
            & .btn-comand {
              opacity: 50%;
              pointer-events: none;
            }
          }
        }

        .btn-export {
          @include themify($themes) {
            background-color: themed('main');
          }

          @media screen and (max-width: 768px) {

            width: 24rem;
          }

          color: $white;
          border: none;
          border-radius: 0.5rem;
          padding: 0.5rem 2.5rem;
          font-weight: 700;
          height: 4.7rem;
          font-size: 1.4rem;
          outline: none;
          transition: all 0.3s ease-in-out;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;

          @media screen and (max-width: 990px) {
            height: 3.7rem;
          }

          .extra-small {
            width: 1.5rem;
            margin-right: 0.4rem;

            circle {
              stroke: $white;
            }
          }

          &:hover {
            @include themify($themes) {
              background-color: themed('bgActive');
            }

            @include themify($themes) {
              color: themed('main');
            }

            .extra-small {
              circle {
                @include themify($themes) {
                  stroke: themed('main');
                }
              }
            }
          }

          .icon {
            margin-right: 0.4rem;
          }
        }

        .btn-comand {
          @include themify($themes) {
            background-color: themed('main');
          }

          @media screen and (max-width: 768px) {

            width: 24rem;
          }

          color: $white;
          border: none;
          border-radius: 0.5rem;
          padding: 0.5rem 2.5rem;
          font-weight: 700;
          height: 4.7rem;
          font-size: 1.4rem;
          outline: none;
          transition: all 0.3s ease-in-out;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;

          @media screen and (max-width: 990px) {
            height: 3.7rem;
          }

          .extra-small {
            width: 1.5rem;
            margin-right: 0.4rem;

            circle {
              stroke: $white;
            }
          }

          &:hover {
            @include themify($themes) {
              background-color: themed('bgActive');
            }

            @include themify($themes) {
              color: themed('main');
            }

            .extra-small {
              circle {
                @include themify($themes) {
                  stroke: themed('main');
                }
              }
            }
          }

          .icon {
            margin-right: 0.4rem;
          }
        }

        .automotor-online {
          display: flex;
          gap: 1rem;
          flex-direction: row;

          @media screen and (max-width: 768px) {
            flex-direction: column;

          }
        }
      }
    }

    .status-green {
      color: $green-4;
    }

    .status-red {
      color: $red;
    }

    .action {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .action_btn {
        margin: 0 0.5rem 0 2rem;

        .action_btn_remove {
          border: none;
          cursor: pointer;
          background-color: transparent;

          .action_btn_remove_icon {
            color: $red;
            font-size: 16px;

            &:hover {
              @include themify($themes) {
                color: themed('main');
              }
            }
          }
        }
      }
    }
  }
}