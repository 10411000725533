@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.maintenance-step {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  margin-top: 1rem;
  position: relative;
  height: 80%;

  .preview-maintenance-step {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .preview-item {
      @include themify($themes) {
        color: themed('main');
      }
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .preview-icon {
        @include themify($themes) {
          background-color: themed('secondary');
        }
        font-size: 1.5rem;
        width: 3rem;
        height: 3rem;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .preview-text {
        width: fit-content;
      }
    }
  }

  .milage-container {
    &.error {
      border-radius: 0.1rem solid $red;
    }

    & .milage-error-text {
      color: $red;
      font-weight: bold;
      font-size: 1.2rem;
    }

    .numeric-input {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 4.2rem;
      border-radius: 0.5rem;
      border: none;
      padding: 0 1rem;
      font-size: 1.6rem;
      font-weight: 400;
      @include themify($themes) {
        color: themed('main');
      }
      outline: none;
      transition: all 0.3s ease-in-out;
      background-color: $white;
      box-sizing: border-box;

      &.input-error {
        border: 0.1rem solid $red;
      }

      &.white {
        background-color: $white;
        height: 3.8rem;
      }

      &::placeholder {
        @include themify($themes) {
          color: themed('main');
        }
        font-size: 1.4rem;
      }
    }
  }

  .maintenance-dropdown-date {
    display: flex;
    gap: 1rem;
  }

  .configuration-dropdown {
    width: 100%;
    height: 4.2rem;
    border-radius: 0.5rem;
    font-size: 1.4rem;
    font-weight: 400;
    @include themify($themes) {
      color: themed('main');
    }
    border: none;

    &.disabled {
      opacity: 30%;
      pointer-events: none;
      user-select: none;
    }

    [class$='-control'] {
      @include themify($themes) {
        color: themed('main');
      }
      border: none;
      background-color: $white;
      height: 4.2rem;
      box-shadow: none;

      &:hover {
        border: none;
      }
    }

    [class$='-placeholder'] {
      @include themify($themes) {
        color: themed('main');
      }
      font-size: 1.4rem;
      font-weight: 400;
    }

    [class$='-indicatorContainer'] {
      @include themify($themes) {
        color: themed('main');
      }
      padding: 0;
    }

    [class$='-singleValue'] {
      @include themify($themes) {
        color: themed('main');
      }
    }

    [class$='-indicatorSeparator'] {
      background: none;
      @include themify($themes) {
        color: themed('main');
      }
    }

    [class$='-indicator'] {
      @include themify($themes) {
        background: themed('main');
      }
      color: $white;
    }

    [class$='-option'] {
      @include themify($themes) {
        background: themed('bgApp');
        color: themed('main');;
      }
    }

    [class$='-option']:hover {
      @include themify($themes) {
        background: themed('main');
        color: themed('bgApp');;
      }
    }
  }

  .checkbox-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 1.4rem;
    @include themify($themes) {
      color: themed('main');
    }

    & .checkbox {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    input[type='checkbox'] {
      appearance: none;
      background-color:  $white;
      margin: 0;
      font: inherit;
      @include themify($themes) {
        color: themed('main');
      }
      width: 1.15em;
      height: 1.15em;
      border: 0.15em solid $blue-1;
      border-radius: 0.15em;
      transform: translateY(-0.075em);
      display: grid;
      place-content: center;
      outline: none;
      @include themify($themes) {
        border-color: themed('main');
      }
    }

    input[type='checkbox']::before {
      content: '';
      width: 0.65em;
      height: 0.65em;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      transform-origin: bottom left;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      @include themify($themes) {
        box-shadow: inset 1em 1em themed('main');
      }
    }

    input[type='checkbox']:checked::before {
      transform: scale(1);
    }
  }

  & .maintenance-radio-labels {
    & .maintenance-radio-label {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      & input {
        margin: 0 0.5rem;
      }

      input[type='radio'] {
        appearance: none;
        background-color:  $white;
        margin: 0;
        font: inherit;
        @include themify($themes) {
          color: themed('main');
          border: 0.1rem solid themed('main');
        }
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        transform: translateY(-0.075rem);
        display: grid;
        place-content: center;
      }

      input[type='radio']::before {
        content: '';
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        @include themify($themes) {
          box-shadow: inset 1rem 1rem themed('main');
        }
      }

      input[type='radio']:checked::before {
        transform: scale(1);
      }

      & .label-title {
        @include themify($themes) {
          color: themed('main');
        }
        font-weight: 600;
        font-size: 1.2rem;
        margin-top: -0.2rem;
      }
    }

    ul {
      @include themify($themes) {
        color: themed('main');
      }
      font-size: 1.2rem;
      margin: 0.5rem 0;
      padding-left: 3rem;
    }
  }
}
