@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.create-parts-replacement-container {
  width: 70.3rem;
  margin: 4rem 2rem;

  @media only screen and (max-width: 768px) {
    width: auto;
    margin: 4rem 1rem;
    padding: 1rem;
    box-sizing: border-box;
  }

  @media screen and (max-width: 990px) and (min-width: 768px) {
    width: 100%;
    margin: 4rem 0rem;
  }



  .close-modal {
    position: absolute;
    right: 1.6rem;
    top: 1.6rem;
    font-size: 1.8rem;

    @include themify($themes) {
      color: themed('main');
    }

    @media only screen and (max-width: 768px) {
      top: 2rem;
      right: 2.6rem;
    }

    @media screen and (max-width: 990px) and (min-width: 768px) {
      top: 3.6rem;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .title {
    @include themify($themes) {
      color: themed('main');
    }

    font-size: 2.5rem;
    letter-spacing: 0;
    line-height: 3.4rem;
    text-align: center;

    @media only screen and (max-width: 768px) {
      font-size: 1.9rem;
    }

    span {
      font-weight: bold;
    }
  }

  .spinner-info {
    position: relative;
    margin-top: 2.5rem;
  }



  .modal-info {
    min-height: 10rem;

    @media only screen and (max-width: 768px) {
      max-height: 70vh;
    }

    label {
      @include themify($themes) {
        color: themed('main');
      }

      font-size: 1.4rem;
    }

    .download-template {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      margin-top: 1rem;

      & a {
        text-decoration: none;
        color: $blue;
        font-size: 1.4rem;
        font-weight: 700;
        display: flex;
        align-items: center;
        gap: 0.5rem;

        @include themify($themes) {
          color: themed('main');
        }

        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }

      @include themify($themes) {
        color: themed('main');
      }

    }
  }

  .groups {
    max-height: 40rem;
    overflow-y: scroll;
  }


  .row {
    display: flex;

    gap: 1.2rem;
    justify-content: center;
    margin: 1rem;

    .error_groups {
      border: #ca312a 1px solid;
      border-radius: 5px;
      display: flex;
      align-items: center;
      color: #ca312a;
      height: 4em;
      font-size: 1.8rem;
      justify-content: center;
      width: 100%;
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }

    @media screen and (max-width: 990px) and (min-width: 768px) {
      flex-direction: column;
      gap: 0rem;
      margin: 4rem;
    }

    .input-item {
      width: 100%;
      position: relative;
      // max-width: 33.5rem;

      .text {
        font-size: 1.6rem;
        color: $blue;
        padding-left: 0.5rem;
      }

      & .select-dropdown {
        width: 100%;
        height: auto;
        border-radius: 0.5rem;
        font-size: 1.4rem;
        font-weight: 400;
        color: $blue;
        border: none;
        min-height: 4.2rem;

        &.disabled {
          opacity: 50%;
          pointer-events: none;
          user-select: none;
        }

        [class$='-control'] {
          color: $blue;
          border: none;
          background-color: $blue-3;
          height: auto;
          box-shadow: none;
          min-height: 4.2rem;

          &:hover {
            border: none;
          }
        }

        [class$='-placeholder'] {
          color: $blue;
          font-size: 1.4rem;
          font-weight: 400;
        }

        [class$='-indicatorContainer'] {
          color: $blue;
        }

        [class$='-singleValue'] {
          color: $blue;
        }

        [class$='-indicatorSeparator'] {
          background: none;
          color: $blue;
        }

        [class$='-indicator'] {
          background: $blue;
          color: $white;
        }
      }
    }

    .empty_text {
      color: $blue-13 !important;
    }

    .active_text {
      color: $blue !important;
    }

    >.container {
      margin: 1rem 0;

      .promotions-active-btn {
        display: flex;
        min-width: 23rem;
        padding: 0;
        justify-content: flex-start;
        width: 100%;

        margin-top: 27px;

        @media screen and (max-width: 990px) and (min-width: 768px) {
          justify-content: center;
        }

        .radio {
          position: relative;
          margin: 0.3rem;

          input[type='radio'] {
            z-index: 2;
            cursor: pointer;
            position: absolute;
            width: 1.5em;
            height: 1.5em;
            opacity: 0;

            +label {
              &:before {
                content: '';
                background: $blue-10;
                border-radius: 100%;
                border: 2px solid $blue;
                display: inline-block;
                position: relative;
                top: -0.2em;
                margin-right: 0.5em;
                vertical-align: top;
                text-align: center;
                width: 1.5em;
                height: 1.5em;
              }
            }

            &:checked {
              +label {
                &:before {
                  @include themify($themes) {
                    background-color: themed('main');
                  }
                }
              }
            }
          }

          label {
            font-size: 1.4rem;
            line-height: 1.9rem;
            font-weight: 700;
            margin-bottom: 0.5rem;
            padding-left: 0.4rem;

            @include themify($themes) {
              color: themed('main');
            }
          }

          .check-icon {
            position: absolute;
            left: 10px;
            top: 3px;
            color: $blue-10;
            font-size: 14px;
          }
        }
      }

      & .date-input-item {
        width: 100%;
        position: relative;

        & .promotions-react-picker-dropdown {
          cursor: pointer;

          &.disable {
            pointer-events: none;
          }
        }

        & .date-picker {
          position: absolute;
          z-index: 101;
          top: 5rem;
          width: 30rem;

          @media screen and (max-width: 768px) {
            width: 100%;
          }

          & .react-datepicker {
            display: flex;
            position: relative;
            border: none;
            box-shadow: 0.5rem 0.8rem 2.1rem rgb(0 0 0 / 15%);
            font-size: 1.3rem;

            &__month-container {
              & .react-datepicker__header {
                background: none;
                border-bottom: none;
                padding: 1.2rem 1rem;

                & .react-datepicker__current-month {
                  font-size: 1.4rem;
                }

                & .react-datepicker__day-names {
                  padding-top: 1rem;

                  .react-datepicker__day-name {
                    width: 3rem;
                  }
                }
              }

              & .react-datepicker__week {
                .react-datepicker__day {
                  width: 3rem;
                  line-height: 3rem;

                  &--in-range {
                    background-color: #d73c5a;
                  }

                  &--selected {
                    background-color: #d73c5a;
                  }

                  &--in-selecting-range {
                    background-color: #f5bec7;
                  }

                  &--keyboard-selected {
                    background-color: #d73c5a;
                  }
                }
              }
            }

            .react-datepicker__month-container {
              width: 30rem;

              @media screen and (max-width: 768px) {
                width: 100%;
              }
            }
          }
        }

        & .select-dropdown {
          width: 100%;
          height: 4.2rem;
          border-radius: 0.5rem;
          font-size: 1.4rem;
          font-weight: 400;

          @include themify($themes) {
            color: themed('main');
          }

          border: none;

          &.disabled {
            opacity: 30%;
            pointer-events: none;
            user-select: none;
          }

          [class$='-control'] {
            @include themify($themes) {
              color: themed('main');
            }

            border: none;

            @include themify($themes) {
              background-color: themed('mainHover');
            }

            height: 4.2rem;
            box-shadow: none;

            &:hover {
              border: none;
            }
          }

          [class$='-placeholder'] {
            @include themify($themes) {
              color: themed('main');
            }

            font-size: 1.4rem;
            font-weight: 400;
          }

          [class$='-indicatorContainer'] {
            @include themify($themes) {
              color: themed('main');
            }
          }

          [class$='-singleValue'] {
            @include themify($themes) {
              color: themed('main');
            }
          }

          [class$='-indicatorSeparator'] {
            background: none;

            @include themify($themes) {
              color: themed('main');
            }
          }

          [class$='-indicator'] {
            @include themify($themes) {
              background: themed('main');
            }

            color: $white;
          }
        }
      }

      & ul.react-tabs__tab-list {
        list-style: none;
        display: flex;
        padding-left: 0;
        border-bottom: 2px solid $blue-5;

        & li {
          cursor: pointer;
          padding: 16px;
          text-align: center;
          font-weight: 700;
          font-size: 1.8rem;
          line-height: 2.5rem;

          @include themify($themes) {
            color: themed('main');
          }

          @media only screen and (max-width: 768px) {
            font-size: 1.5rem;
            line-height: 2rem;
            padding: 7px;
          }

          &.react-tabs__tab--selected {
            // color: white;

            border-bottom: 4px solid $blue-1;
            margin-bottom: -1px;
          }

          &.react-tabs__tab--disabled {
            color: $blue-4;
            pointer-events: none;
            cursor: default;
          }
        }
      }

      & .react-tabs__tab-panel {
        .__react_component_tooltip {
          @media only screen and (max-width: 768px) {
            left: 2rem !important;
            width: 80% !important;
          }
        }

        & ul {
          list-style: none;
          padding: 5px 5px 20px 5px;
          font-size: 1.2rem;
          font-weight: bold;

          @media screen and (max-width: 990px) and (min-width: 768px) {
            max-height: 500px;
            overflow-y: auto;
          }

          & p {
            font-weight: 700;
            font-size: 1.7rem;
            margin: 1rem 0;

            @include themify($themes) {
              color: themed('main');
            }

            @media only screen and (max-width: 768px) {
              font-size: 1.5rem;
            }
          }

          & a {
            text-decoration: none;
            font-size: 1rem;
            padding: 5px 5px;
            display: block;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 2.2rem;
            cursor: pointer;

            @include themify($themes) {
              color: themed('main');
            }

            &:hover {
              background: $blue-5;
              border-radius: 3px;
            }
          }
        }

        & .no-data {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 25rem;
          font-weight: 700;
          font-size: 1.7rem;
          margin: 0.2rem 0;

          @include themify($themes) {
            color: themed('main');
          }
        }
      }
    }

    .item_group {
      margin: 0rem;
    }

    label {
      @include themify($themes) {
        color: themed('main');
      }

      font-size: 1.4rem;
    }

    >.file {
      width: 40rem;

      @media screen and (max-width: 990px) and (min-width: 768px) {
        width: auto;
      }

      @media screen and (max-width: 768px) {
        width: auto;
      }
    }

    >.pdf {
      width: 40rem;

      @media screen and (max-width: 990px) and (min-width: 768px) {
        width: auto;
      }

      @media screen and (max-width: 768px) {
        width: auto;
      }
    }



    textarea {
      margin-top: 1rem;
      width: 100%;
      border: none;

      @include themify($themes) {
        background-color: themed('secondary-3');
      }

      resize: vertical;

      font-size: 1.6rem;

      @include themify($themes) {
        color: themed('main');
      }

      @media screen and (max-width: 768px) {
        padding: 0;
      }

      &:focus-visible {
        outline: none;
      }
    }

    .button {
      margin-top: 1rem;
    }

    .select-dropdown {
      height: 4.2rem;
      border-radius: 0.5rem;
      font-size: 1.4rem;
      font-weight: 400;

      @include themify($themes) {
        color: themed('main');
      }

      border: none;

      [class$='-control'] {
        @include themify($themes) {
          color: themed('main');
        }

        border: none;

        @include themify($themes) {
          background-color: themed('secondary-3');
        }

        height: auto;
        box-shadow: none;
        min-height: 4.2rem;

        &:hover {
          border: none;
        }
      }

      [class$='-placeholder'] {
        @include themify($themes) {
          color: themed('main');
        }

        font-size: 1.4rem;
        font-weight: 400;
      }

      [class$='-indicatorContainer'] {
        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-singleValue'] {
        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-indicatorSeparator'] {
        background: none;

        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-indicator'] {
        @include themify($themes) {
          background: themed('main');
        }

        color: $white;
      }
    }
  }

  .hide {
    display: none;
  }

  .btn-save {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3rem 0rem;

    @media only screen and (max-width: 768px) {
      margin: 3rem 3rem;
    }

    button {
      @include themify($themes) {
        background-color: themed('main');
      }

      color: $white;
      border: none;
      border-radius: 0.5rem;
      padding: 0.5rem 2.5rem;
      font-weight: bold;
      width: 45.2rem;
      height: 4.7rem;
      font-size: 1.4rem;
      outline: none;
      transition: all 0.3s ease-in-out;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
      }

      .title-btn-save {
        width: 100%;
      }

      .extra-small {
        width: 2rem;

        circle {
          stroke: $white;
        }
      }
    }
  }
}