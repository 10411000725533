@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.rounded-button {
  width: 100%;
  height: 4.2rem;
  border-radius: 2rem;
  border: none;
  padding: 0 0.5rem;
  font-size: 1.6rem;
  font-weight: 400;
  @include themify($themes) {
    color: themed('main');
    background-color: themed('secondary-3');
  }
  outline: none;
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 990px) and (min-width: 768px) {
    width: 70%;
  }

  cursor: pointer;

  &:hover {
    @include themify($themes) {
      background-color: themed('main');
      color: themed('secondary');
    }
  }

  &.active {
    @include themify($themes) {
      background-color: themed('main');
      color: themed('secondary-3');
    }
  }
}
