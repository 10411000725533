@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.supplier-settings-modal {
  padding: 0.5rem 1.5rem;
  box-sizing: border-box;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 1.4rem;
    border-bottom: 1px solid $blue-2;
    padding-bottom: 1rem;
    &-close {
      cursor: pointer;
      &:hover {
        opacity: 75%;
      }
    }
  }
  &__body {
    padding: 1rem 0;
  }
  &__footer {
    border-top: 1px solid $blue-2;
    padding-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    &-save {
      min-width: 15rem;
    }
  }
}
