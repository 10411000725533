@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.modules-mobile-card {
  @include themify($themes) {
    color: themed('main');
  }

  max-width: 31.5rem;

  @include themify($themes) {
    background-color: themed('secondary-3');
  }

  width: 100%;
  margin-bottom: 5rem;

  @media screen and (max-width: 768px) {
    max-width: none;
  }

  .header {
    min-height: 5rem;
    display: flex;
    align-items: center;

    @include themify($themes) {
      background: themed('categoriesSidebar', 'oddItem');
    }

    padding: 1rem;

    .icon {
      font-size: 2rem;
      cursor: pointer;

      &:hover {
        opacity: 80%;
      }
    }

    .return-button {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 14px;
      cursor: pointer;
    }

    .title {
      padding: 0;
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
      width: 100%;
    }
  }

  .content {
    padding: 1rem 2rem;
    box-sizing: border-box;

    .items {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
  }
}