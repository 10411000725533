@import '../../../../../../assets/sass/variables';
@import 'src/assets/sass/themes';
@import '../../../../../../assets/sass/_mixins';

.search-reference-content {
  @include themify($themes) {
    color: themed('main');
  }

  font-family: 'Open Sans',
  sans-serif;

  .title {
    text-align: center;
    padding-bottom: 1rem;

    p {
      font-size: 1.6rem;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 2.2rem;
      text-align: center;
      margin: 0;
    }
  }

  .search {
    @include themify($themes) {
      background-color: themed('secondary');
    }
    padding: 3rem 1rem;
    display: flex;
    flex-direction: row;
    gap: 3rem;
    justify-content: center;
    align-items: center;

    .reference-label {
      font-size: 1.4rem;
      line-height: 3rem;
      font-weight: bold;
      white-space: nowrap;
    }

    .car-parts-reference {
      .ref-dropdown-content {
        position: relative;
      }
    }

    .reference-icon {
      font-size: 2rem;
      padding-right: 1rem;
    }

    input {
      border-radius: 1rem;
      // max-width: 19rem;
    }

    .input-search-icon {
      font-size: 1.5rem;
    }
  }

  @media screen and (max-width: 1124px) {
    .search {
      gap: 1rem;

      .reference-label {
        font-size: 1.1rem;
      }
    }
  }

  @media screen and (max-width: 990px) and (min-width: 768px) {
    .search {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      padding: 2rem;

      .car-parts-reference,
      .tyre-reference,
      .oil-reference,
      .consumable-reference,
      .battery-reference {
        width: 100%;

        input {
          max-width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .search {
      display: flex;
      flex-direction: column;

      .car-parts-reference,
      .tyre-reference,
      .oil-reference,
      .consumable-reference,
      .battery-reference {
        width: 94%;
      }
    }
  }
}