@import '../../../../../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.navbar-catalog-naf-container {
  height: 3rem;
  background-color: $blue-14;

  @media only screen and (max-width: 768px) {
    height: 4.5rem;
  }

  .navbar-below {
    height: 100%;
    margin: 0 auto;

    .items {
      margin: 0 auto;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      .item {
        width: 23rem;
        display: flex;
        justify-content: center;

        @media only screen and (max-width: 768px) {
          width: auto;
          height: 4.5rem;
        }

        // @media only screen and (max-width: 1368px) {
        //   width: 18rem;
        // }
        // @media only screen and (max-width: 1068px) {
        //   width: 16rem;
        // }
      }

      .icon-item {
        width: 23rem;
        display: flex;
        justify-content: center;

        @media only screen and (max-width: 768px) {
          width: auto;
          height: 4.3rem;
        }

        // @media only screen and (max-width: 1368px) {
        //   width: 18rem;
        // }
        // @media only screen and (max-width: 1068px) {
        //   width: 16rem;
        // }
      }
    }

    .active {
      background-color: $blue-15;
      height: 3rem;

      @media only screen and (max-width: 768px) {
        height: 4.5rem;

      }
    }
  }
}