
.item-option-barcode{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 150px;

  & > img {
    width: 100%;
    height: 80px;
  }
  span{
    font-size:1.8rem;
  }
}