@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.proforma-mapping-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $blue-14;
  border-radius: 1rem;
  padding: 0.8rem;
  margin-bottom: 0.8rem;
  box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.25);

  &-name-input {
    border: 0.1rem solid $blue-1;
    font-size: 1.6rem;
    padding: 0.8rem;
    border-radius: 0.3rem;
    height: 2.8rem;
    width: 100%;
    outline-color: rgb(118, 166, 218);
  }

  &-title {
    display: flex;
    align-items: center;

    &-required {
      color: red;
    }
  }
}

.proforma-mapping-item-num-input {
  border-radius: 0.3rem;
  border: 1px solid #e4e5e9;
  width: 100%;
  height: 3rem;
  padding: 0 0 0 1.7rem;
  outline-color: rgb(118, 166, 218);
}

.input-disable-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &>.fas.fa-times {
    margin-right: 1rem;
    cursor: pointer;
  }
}

.proforma-mapping-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  position: relative;

  @media screen and (max-width: 768px) {
    width: auto;

  }
}

.proforma-mapping-table td {
  border-bottom: 1px solid $blue-14;
  font-family: Arial, sans-serif;
  font-size: 1.6rem;
  overflow: hidden;
  padding: 1rem 0.5rem;
  word-break: normal;

  @include themify($themes) {
    color: themed('main');
  }
}

.proforma-mapping-table th {
  border-bottom: 0.1rem solid $blue-14;
  font-family: Arial, sans-serif;
  font-size: 2rem;
  font-weight: normal;
  overflow: hidden;
  padding: 1rem 0.5rem;
  word-break: normal;

  @include themify($themes) {
    color: themed('main');
  }
}

.proforma-mapping-table .tg-0pky {
  vertical-align: top;

  &.title {
    white-space: pre-wrap;
  }

  &.disabled {
    color: gray;

    &>.handle-num-container {
      >.proforma-mapping-item-num-input {
        @include themify($themes) {
          color: themed('main');
        }
      }
    }

    &>.input-disable-container {
      &>input {
        @include themify($themes) {
          color: themed('main');
        }
      }
    }
  }

  &>.required {
    color: red;
  }
}

.droptarget {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5rem;
  border: 1px solid #aaaaaa;
  width: 100%;
}

.droptarget>tr {
  border: none;
  padding: 0.8rem;
  width: 100%;
}

.proforma-mapping-table th {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  text-align: left;

  &.custom {
    border-left: 1px solid $blue-14;
  }
}

.proforma-mapping-table tr,
.proforma-mapping-table td,
.proforma-mapping-table th {
  padding: 1.8rem 1rem;
}

.invalid-order {
  border: 1px solid red;
  outline: none;
}

.enable-mapping-title {
  @include themify($themes) {
    color: themed('main');
  }

  &.disabled {
    color: gray;
  }
}

.proforma-enable-custom {
  display: flex;
  align-items: center;
}