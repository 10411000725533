@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.login-mobile-retailer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;

  background: transparent url('../../../../assets/images/login-background.png') no-repeat fixed;
  background-size: cover;

  .login-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 32rem;
    height: 55.2rem;
    background-color: white;
    border-radius: 0.5rem;
    position: relative;

    &-header {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 4.7rem;
      gap: 2.3rem;

      &-description {
        font-size: 2rem;
        font-weight: 400;
        color: $black;
        margin-bottom: 2rem;
        text-align: center;
        line-height: 28px;
        letter-spacing: 0px;
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 28.6rem;
      height: 100%;

      &-title {
        font-size: 1.4rem;
        font-weight: 200;
        color: $light-blue;
        margin-bottom: 2rem;
        text-align: center;
      }

      &-input-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;

        & .input-item {
          font-weight: 700;

          &.error label {
            color: $red;
          }

          &.error .error-message {
            margin-top: 0.5rem;
            font-size: 1rem;
          }

          &.error input {
            border: 1px solid $red;
            color: $red;
          }

          display: flex;
          flex-direction: column;
          width: 100%;

          & label {
            font-size: 1.2rem;
            font-weight: bold;
            color: $black;
            margin-bottom: 0.5rem;
          }

          & .forgot-password {
            align-self: flex-end;
            font-size: 1.2rem;
            font-weight: 400;
            color: rgba(39, 80, 159, 0.5);
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }

  .error {
    color: $red;
  }

  .check {
    padding-right: 0.625px;
  }

  .forgot {
    display: flex;
    justify-content: flex-end;

    @include themify($themes) {
      color: themed('main');
    }

    cursor: pointer;

    & a {
      font-size: 1.2rem;
      font-weight: 400;
      color: rgba(39, 80, 159, 0.5);
    }
  }
}