@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.espace-automotor-container-wrapper {
  .container {
    // max-width: $container-width-xlarge;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: 144rem;

    .header {
      position: sticky;
      top: 0;
      z-index: 11;

      @media screen and (max-width: 768px) {
        padding: 0rem;
        box-sizing: border-box;
      }

      & .header-slide-menu {
        @include themify($themes) {
          background-color: themed('secondary');
        }

        & .slider-menu-container {
          max-width: 144rem;
          margin: 0 auto;
        }

        & .title {
          display: none;
        }

        & .description {
          display: none;
        }

        & .automotor-menu-slide-item {
          width: auto;
          height: auto;
        }
      }

      >div {
        position: relative;
      }
    }

    @media screen and (max-width: 1260px) {
      max-width: $container-width-xlarge;
    }

    @media screen and (max-width: 990px) {
      height: initial;
    }

    .body {
      position: relative;
      max-width: $container-width-xlarge;
      margin: 0 auto;
      width: 100%;

      @media screen and (max-width: 990px) {
        height: initial;
      }
    }

    & .footer {
      position: relative;
      max-width: 144rem;
      margin: 0 auto;
      width: 100%;
      display: flex;
      margin: auto auto 0 auto;
      justify-content: center;
      flex-direction: column;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }

      .reference-estimation {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;
      }
    }
  }

  .container-fluid-large {
    max-width: $container-width-large;
    margin: 0 auto;
  }

  .footer {
    display: block;
    margin-top: auto;
  }
}

@media screen and (min-width: 990px) {
  ::-webkit-scrollbar {
    width: 0.6rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    @include themify($themes) {
      background-color: themed('main');
    }

    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @include themify($themes) {
      background-color: themed('main');
    }
  }
}

.aol-layout {
  @media screen and (min-width: 1600px) {
    .container {
      &>.header {
        margin: 0 -9rem;
        padding: 0 9rem;
        background: $white;
      }
    }
  }

}