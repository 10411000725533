@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.delete-cell-icon {
  width: 20px;
  height: 20px;
  @include themify($themes) {
    color: themed('main');
  }
  cursor: pointer;
  &.disabled {
    pointer-events: none;
  }
}
