.image-cell-wrapper {
  display: flex;
  .image-container {
      max-width: 6rem;
      max-height: 6rem;
      overflow: hidden;
      display: flex;
      align-items: center;

    .img {
      max-width: 100%;
      max-height: 100%;
      max-width: -webkit-fill-available;
      max-height: -webkit-fill-available;
    }
  }
}
