@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.ref-dropdown-content {
  position: absolute;
  background-color: $hovered-blue;
  border-radius: 1rem;
  margin-top: 0.2rem;
  z-index: 1;
  box-shadow: 0.2rem 0.2rem 1rem rgb(1 1 1 / 15%);
  min-width: 100%;
  font-size: 1.4rem;
  font-weight: bold;
  overflow-y: auto;
  max-height: 30rem;

  & .ref-dropdown-header {
    padding: 1.3rem 0.6rem 0.4rem 0.6rem;
    border-bottom: 1px solid $blue-5;

    & .ref-dropdown-title {
      @include themify($themes) {
        color: themed('main');
      }
    }
  }

  & .ref-search-result-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & .skeleton-line {
      margin: 0.6rem;
    }

    & .no-data {
      @include themify($themes) {
        color: themed('main');
      }
      padding: 1.1rem;
    }

    & .suggestion-list-item {
      list-style: none;
      padding: 1.1rem;
      cursor: pointer;
      width: 100%;
      box-sizing: border-box;

      @media screen and (max-width: 500px) {
        font-size: 1.1rem;
      }

      &:last-child {
        border-radius: 0 0 1rem 1rem;
      }

      &:hover {
        background-color: $blue-5;
      }

      & .suggestion-item-replacement {
        @include themify($themes) {
          color: themed('main');
        }
        font-size: 1.2rem;

        span {
          color: $red;
        }
        & .commercial-ref {
          font-size: 0.8rem;
        }
      }

      & .suggestion-item-title {
        display: flex;
        justify-content: space-between;
        gap: 2rem;
        @include themify($themes) {
          color: themed('main');
        }
      }
    }
  }
}

.suggestion-item-replacement {
  @include themify($themes) {
    color: themed('main');
  }
  font-size: 1.2rem;

  span {
    color: $red;
  }
  & .commercial-ref {
    font-size: 1rem;
  }
}
/* Mobile CSS Styles */

.app-layout-mobile {
  //.search-inputs-container {
  //  div[class$='-dropdown-content'] {
  //    min-width: 1rem;
  //    width: calc(100% - 3rem);
  //
  //    & .ref-search-result-content {
  //      width: 100%;
  //
  //      & .suggestion-list-item {
  //
  //        &:last-child {
  //          border-radius: 0 0 1rem 1rem;
  //        }
  //
  //        & .suggestion-item-title {
  //          display: grid;
  //          grid-template-columns: 30% 20% 50%;
  //          width: 100%;
  //          gap: .5rem;
  //        }
  //      }
  //    }
  //  }
  //}

  @media screen and (max-width: 360px) {
  }
}
