@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.navbar-mecadepot {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 7.2rem;

  @include themify($themes) {
    background-color: themed('navbarMecadepot', 'bg');
  }

  .sidebar-mobile {
    height: 58px;
    width: 38px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 990px) and (min-width: 768px) {
      width: 48px;
    }

    .icon {
      font-size: 1.8rem;
      font-weight: 900;

      @include themify($themes) {
        color: themed('navbarMecadepot', 'active');
      }
    }
  }

  & .logos {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 1rem;

    @media only screen and (max-width: 768px) {
      align-items: initial;
      margin-left: 0.2rem;
      max-width: 220px;
    }

    @media only screen and (max-width: 990px) and (min-width: 768px) {
      align-items: initial;
      margin-left: 0.2rem;
      max-width: 220px;
    }

    img {
      @media only screen and (max-width: 1150px) and (min-width: 990px) {
        display: none;
      }
    }

    & :hover {
      cursor: pointer;
    }

    & .garage-michel {
      display: flex;
      margin-left: 0.6rem;
      height: 100%;
      align-items: center;
      padding-right: 0.5rem;
      border-right: 1px solid rgba(39, 80, 159, 0.2);
      flex-direction: column;
      justify-content: center;
      gap: 10px;

      img {
        max-width: 13rem;
        max-height: 4rem;

        @media only screen and (max-width: 1340px) and (min-width: 990px) {
          display: inline;
        }
      }

      @media only screen and (max-width: 768px) {
        display: flex;
        align-items: center;
        width: 100%;
        border-right: none;
        margin-left: 0rem;
      }

      @media only screen and (max-width: 990px) and (min-width: 768px) {
        width: 100%;
        align-items: center;
        border-right: none;
        margin-left: 0rem;
      }
    }
  }

  & .tabs {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;

    & .tab {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 0.5rem;
      height: 72px;
      max-width: 18rem;
      max-width: 16rem;

      @include themify($themes) {
        color: themed('navbarMecadepot', 'color');
      }

      @media only screen and (max-width: 1340px) and (min-width: 990px) {
        min-width: 166px;
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      & .tab-icon {
        @include themify($themes) {
          color: themed('navbarMecadepot', 'color');
        }

        width: 18px;
        height: 18px;
        font-size: 1.5rem;
      }

      & .tab-icon-white {
        @include themify($themes) {
          color: themed('navbarMecadepot', 'colorActive');
        }
      }

      & .text {
        white-space: initial;
        text-align: center;
        font-size: 1.3rem;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 1.8rem;
      }

      & :hover {
        cursor: pointer;
      }
    }



    & .active {
      @include themify($themes) {
        color: themed('navbarMecadepot', 'colorActive');
      }
    }
  }



  & .user-settings {
    display: flex;
    align-items: center;
    gap: 1.7rem;

    .phone-div {
      display: flex;
      flex-direction: row !important;
      align-items: center;
      justify-content: center;
      border-radius: 1rem;
      box-shadow: 0.2rem 0.2rem 1rem rgba(0, 0, 0, 0.0392156863);
      height: 5rem;
      padding: 0 2rem;
      width: auto;
      position: relative;

      .mecadepot-messages-count {
        color: $red;
        background-color: $white;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        position: absolute;
        top: -.5rem;
        right: -.5rem;
      }

      @include themify($themes) {
        background: themed('navbarMecadepot', 'active');
        color: themed('navbarMecadepot', 'colorActive');
      }

      @media only screen and (max-width: 1340px) and (min-width: 990px) {
        width: max-content;
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      & .tab-icon {
        @include themify($themes) {
          color: themed('navbarMecadepot', 'color');
        }

        width: 18px;
        height: 18px;
        font-size: 1.5rem;
      }

      & .tab-icon-white {
        @include themify($themes) {
          color: themed('navbarMecadepot', 'colorActive');
        }
      }

      & .text {
        white-space: initial;
        text-align: center;
        font-size: 1.3rem;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 1.8rem;
      }

      & :hover {
        cursor: pointer;
      }

      .phone a {
        font-size: 1.6rem;
        color: #ffff;
      }
    }

    & .mini-dropdowns {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 0.6rem;
      margin-right: 1rem;
    }
  }
}