@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

/* Mobile CSS Styles */

.app-layout-mobile,
.app-layout-tablet {
  .swiper-wrapper {
    .swiper-slide {
      max-width: 40rem;
    }

    &.with-pagination {
      padding-bottom: 4.5rem;

      .swiper-container {
        margin: 0;
      }

      .swiper-pagination-bullets {
        &.swiper-pagination-horizontal {
          bottom: 0rem;

          .swiper-pagination-bullet {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .swiper-wrapper {
      .swiper-slide {
        max-width: 30rem;
      }
    }
  }
}