@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.cart-retailer-body {
  position: relative;

  .CartButton.cart-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.2rem;
    width: 4.2rem;
    border-radius: 50%;
    cursor: pointer;
    position: relative;

    @include themify($themes) {
      color: themed('main');
      background-color: themed('roundBtn', 'bg');
    }


    @media only screen and (max-width: 768px) {
      width: 3.8rem;
      height: 3.8rem;
      margin-right: 0.8rem;
    }

    @media only screen and (max-width: 990px) and (min-width: 768px) {
      width: 3.8rem;
      height: 3.8rem;
      margin-right: 0.8rem;
    }

    & .cart-icon {
      font-size: 1.6rem;
      z-index: 1;
      position: relative;
    }

    .quantity {
      background: $red;
      border-radius: 100%;
      color: $white;
      font-size: 1rem;
      font-weight: 700;
      height: 1.8rem;
      position: absolute;
      right: 0;
      text-align: center;
      top: -0.2rem;
      width: 2rem;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}