@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.modal-select-car {
  width: 99.3rem;
  margin: 0;

  .close-modal {
    position: absolute;
    right: 1.6rem;
    top: 1.6rem;
    font-size: 1.8rem;

    @include themify($themes) {
      color: themed('main');
    }

    &:hover {
      cursor: pointer;
    }
  }

  .modal-select-title {
    padding: 4.3rem 0 2.3rem 0;

    @include themify($themes) {
      color: themed('main');
    }

    font-size: 2.5rem;
    letter-spacing: 0;
    line-height: 3.4rem;
    text-align: center;
  }

  .general-search-car {
    display: flex;
    flex-direction: row;

    padding: 0 1.7rem 1.6rem 1.7rem;

    .search-card {
      display: flex;
      align-items: center;
      flex-direction: column;
      flex: 1;
      background-color: $white;
      border-radius: 0;
    }

    .vehicle-selector-search-card {
      display: flex;
      align-items: center;
      flex-direction: column;
      flex: 1;

      @include themify($themes) {
        background-color: themed('secondary');
      }

      border-radius: 0;
    }
  }
}

/* Responsive CSS Styles */

.ReactModal__Content {
  @media screen and (max-width: 990px) {
    width: 100%;
  }
}

.ReactModal__Content--after-open {
  @media screen and (max-width: 990px) and (min-width: 768px) {
    border-radius: 0;
    max-height: 100%;
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    border-radius: 0;
  }
}

.app-layout-tablet {
  .general-search {
    flex-direction: column;
    padding-bottom: 2rem;
  }

  .modal-select-car {
    width: 100%;
    height: auto;

    .close-modal {
      right: 2.6rem;
      top: 2.6rem;
    }

    .general-search-car {
      height: 85%;
      overflow: auto;
      flex-flow: column;
      gap: 1rem;
    }
  }
}

.app-layout-mobile {
  .general-search {
    flex-direction: column;
    padding-bottom: 2rem;
  }

  .modal-select-car {
    width: 100%;
    height: 100vh;

    .close-modal {
      top: 2rem;
      right: 2.6rem;
    }

    .modal-select-content {
      overflow: auto;
      height: calc(100vh - 13.8rem);

      .general-search-car {
        height: auto;
        overflow: auto;
        flex-flow: column;
        gap: 1rem;
      }
    }
  }
}