@import "src/assets/sass/variables";
@import "src/assets/sass/themes";

.catalog-supplier-layout-container {
  position: relative;
  // max-width: 125.8rem;
  // margin: 0 auto;
  max-width: 132rem;
  margin: 2rem auto;
  padding: 0 3.4rem;
  min-height: 40vh;
  width: 100%;

  @media screen and (max-width: 768px) {
    padding: 0 1rem;
  }
}