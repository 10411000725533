@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.error-page-500 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f5f9ff;
  height: 100%;

  .error500 {
    position: relative;
  }

  .error-page-text {
    position: absolute;
    font-size: 4rem;
    font-weight: 600;
    @include themify($themes) {
      color: themed('main');
    }
    top: 12rem;
    z-index: 1;
  }

  .go-home-button {
    width: 17.5rem;
    position: absolute;
    bottom: 9rem;
  }
}
