@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.sidenav-espace {
  background-color: $background-grey;
  height: 100vh;
  width: 0;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;

  overflow-x: hidden;
  transition: 0.5s;

  .header-sidenav-espace {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 5.8rem;
    z-index: 10;

    .close-btn {
      @include themify($themes) {
        color: themed('main');
      }

      font-size: 1.6rem;
      font-weight: 900;
      width: 3.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }

  &__sidenav {
    display: flex;
    height: 90%;


    a:visited {
      text-decoration: none;
      color: $blue-1;
    }

    a:focus {
      text-decoration: none;
      color: $blue-1;
    }

    a:any-link {
      text-decoration: none;
      color: $blue-1;
    }

    a:hover,
    a:active {
      text-decoration: none;
      color: $white-3;
    }
  }

  &__tabs {
    flex: 0 0 70px;
    display: flex;
    flex-direction: column;
    background-color: $white-3;
    overflow-y: auto;
  }

  &__tab {
    padding: 10px;
    color: $blue-1;
    cursor: pointer;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      fill: $blue-1;
      width: 2rem;
      height: 2rem;
    }

    &.active {
      background-color: $blue-1;
      color: $white-3;

      svg {
        fill: $white-3;

        path {
          fill: $white-3;
        }
      }
    }
  }

  &__content {
    flex: 1;
    padding: 20px 20px 20px 0;
    overflow-y: auto;
    color: $blue-1;
    box-sizing: border-box;
  }

  &__separator {
    margin: 20px 0;
  }

  &__content-item {
    padding: 10px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid $blue-18;
    box-sizing: border-box;

    & a {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    svg {
      fill: $blue-1;
      width: 2rem;
      height: 2rem;
    }
  }

  .setting {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;

    .e-book {
      display: flex;
      justify-content: space-evenly;
      flex-direction: row;
      align-items: center;
      width: 12.3rem;
      height: 4.2rem;
      border-radius: 20.5px;

      @include themify($themes) {
        color: themed('secondary');
      }

      font-weight: 600;
      font-size: 11px;

      @include themify($themes) {
        background-color: themed('main');
      }

      border: none;

      .e-book-button {
        width: 6rem;
      }

      .e-book-icon {
        font-size: 16px;
      }
    }

    .mini-dropdowns {
      display: flex;
      flex-direction: row;
      width: 12rem;
      justify-content: space-between;

      img {
        width: 2.1rem;
        height: 1.3rem;
      }
    }
  }

  .footer {
    height: 5.5rem;
    bottom: 0px;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include themify($themes) {
      background-color: themed('secondary');
    }
  }
}

.show-sidenav {
  width: 100%;
}

.hidden-sidenav {
  width: 0%;
}