@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.container-button-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;

  :nth-child(odd) {
    @include themify($themes) {
      background-color: themed('categoriesSidebar', 'oddItem');
    }
  }

  .image {
    width: 4rem;
    height: 4rem;

    @include themify($themes) {
      background-color: themed('categoriesSidebar', 'image');
    }

    outline: none;
    appearance: none;
    border-radius: 5px;
    aspect-ratio: 3/2;
    object-fit: contain;
  }

  div.image {
    min-width: 40px;
    min-height: 40px;
    margin: 0 1rem;


  }

  .image-svg {
    width: 4rem;
    height: 4rem;
    min-width: 40px;
    min-height: 40px;
    margin: 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @include themify($themes) {
      background-color: themed('categoriesSidebar', 'image');
    }

    outline: none;
    appearance: none;
    border-radius: 5px;
    aspect-ratio: 3/2;
    object-fit: contain;

    svg {
      width: 2.5rem;
      height: 2.5rem;

      @include themify($themes) {
        background-color: themed('categoriesSidebar', 'image');
      }

      path {
        fill: black;
      }

    }
  }

  .text {
    font-size: 16px;
    min-width: 200px;

    @include themify($themes) {
      color: themed('main');
    }
  }

  .number {
    font-size: 1.4rem;
    width: 3.5rem;
    height: 3rem;

    @media only screen and (max-width: 768px) {
      height: 3rem;
    }

    background-color: $black !important;
    border-radius: 100%;
    text-align: center;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @include themify($themes) {
      color: themed('secondary');
    }
  }

  .pdf-icon {
    @include themify($themes) {
      color: themed('main');
    }

    font-size: 1.8rem;
  }
}