@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.link-button {

  @include themify($themes) {
    background-color: themed('inputColor');
  }

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  @include themify($themes) {
    border: 1px solid themed('border');
  }

  border-radius: 5rem;
  width: 100%;
  padding: 0.5rem 2rem;
  cursor: pointer;

  .title-link {
    @include themify($themes) {
      color: themed('main');
    }

    font-size: 1.1rem;
    white-space: nowrap;
  }

  .icon-link {
    @include themify($themes) {
      color: themed('main');
    }

    font-size: 1.1rem;
  }
}