@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.tabcard-container {
  display: flex;
  align-items: center;
  padding: 1rem;
  @include themify($themes) {
    background-color: themed('secondary');
  }
  height: 9.5rem;
  width: 40rem;
  border-radius: 10px;

  .image-wrapper {
    cursor: pointer;
    width: 8.5rem;
    height: 8.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;

    .image {
      width: auto;
      height: auto;
      max-width: 8.5rem;
      max-height: 8.5rem;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    padding-left: 2rem;
    width: 100%;

    .date {
      font-size: 1.2rem;
      @include themify($themes) {
        color: themed('main');
      }
      font-weight: 300;
    }

    .nameWrapper {
      text-decoration: none;

      .tab-card-title {
        cursor: pointer;
        @include themify($themes) {
          color: themed('main');
        }
        font-size: 16px;
        font-weight: bold;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 29rem;
        position: relative;

        //&:hover {
        //  text-overflow: clip;
        //  white-space: normal;
        //  word-break: break-all;
        //}
      }

      .tab-card-description {
        @include themify($themes) {
          color: themed('main');
        }
        font-size: 12px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 29rem;
        position: relative;

        //&:hover {
        //  text-overflow: clip;
        //  white-space: normal;
        //  word-break: break-all;
        //}
      }
    }

    .model {
      @include themify($themes) {
        color: themed('main');
      }
      font-size: 12px;
      display: flex;

      span {
        font-weight: bold;
      }

      .modelDescription {
        font-weight: 300;
        padding-left: 1rem;
      }
    }
  }
}

/* Mobile CSS Styles */

.app-layout-mobile,
.app-layout-tablet {
  .tabcard-container {
    align-items: flex-start;
    max-height: 15rem;
    height: auto;
    padding: 1rem;
    width: calc(100% - 2rem);

    .wrapper {
      padding-left: 2rem;
      margin: 0;

      .nameWrapper {
        .title,
        .description {
          white-space: initial;
          width: 100%;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .description {
          -webkit-line-clamp: 1;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .tabcard-container {
      .wrapper {
        padding-left: 1rem;
      }
    }
  }
}

/* Responsive CSS Styles */

.app-layout-mobile,
.app-layout-tablet {
  .tabcard-container {
    align-items: flex-start;
    max-height: 15rem;
    height: auto;
    padding: 1rem;
    width: calc(100% - 2rem);

    @media screen and (max-width: 990px) and (min-width: 768px) {
      width: 35rem;
    }

    @media screen and (max-width: 830px) and (min-width: 768px) {
      width: 100%;
    }

    .wrapper {
      padding-left: 2rem;
      margin: 0;

      .nameWrapper {
        .title,
        .description {
          white-space: initial;
          width: 100%;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .description {
          -webkit-line-clamp: 1;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .tabcard-container {
      .wrapper {
        padding-left: 1rem;
      }
    }
  }
}

.app-layout-desktop {
}
