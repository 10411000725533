@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.industrial-car-parts {
  width: 100%;
  &__content {
    width: 100%;
    &.loading {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 20rem;
    }
    &-no-data {
      display: flex;
      justify-content: center;
      align-items: center;
      @include themify($themes) {
        color: themed('main');
      }
      font-size: 2rem;
      font-weight: bold;
      min-height: 20rem;
    }
  }
  &__filter {
    height: 13rem;
    border-radius: 1rem;
    border: 1px solid #cfd9ee;
    margin-bottom: 2rem;

    @media screen and (max-width: 990px) {
      height: auto;
      padding-bottom: 1rem;
    }

    @media screen and (max-width: 768px) {
      height: auto;
      padding-bottom: 1rem;
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2.1rem 2.4rem;

      &-title {
        font-size: 1.4rem;
        @include themify($themes) {
          color: themed('main');
        }
        font-weight: bold;
      }
    }

    &-body {
      display: flex;
      flex-direction: row;
      gap: 1.4rem;
      padding: 0 2.4rem;

      @media screen and (max-width: 990px) {
        flex-direction: column;
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      &-item {
        width: 100%;
        position: relative;
      }
    }
  }
}
