@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.favorite-items-container {
  position: relative;

  .items-options {
    z-index: 5;

    @include themify($themes) {
      color: themed('main');
    }

    position: absolute;
    top: -4rem;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 3rem;
    width: 100%;
    gap: 1rem;

    .select-all {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.6rem;
      font-size: 1.4rem;
      line-height: 1.4rem;
      min-width: 5rem;
      max-width: min-content;

      @media screen and (max-width: 768px) {
        min-width: auto;
      }

      .status {
        svg {
          width: 1.6rem;
          height: 1.6rem;

          path {
            @include themify($themes) {
              fill: themed('favorite', 'bgFill');
            }
          }
        }
      }

      p {
        margin: 0;
        white-space: nowrap;
      }
    }

    .delete {
      width: 100%;
      max-width: fit-content;

      button {
        height: 3rem;
      }
    }

    .favourite-add-to-cart {
      width: 100%;
      max-width: fit-content;

      button {
        height: 3rem;
      }
    }
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;

    .no-items {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 30rem;
      font-size: 1.6rem;
      line-height: 1.6rem;

      @include themify($themes) {
        color: themed('main');
      }
    }
  }
}