@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.message-supplier-header-container {
  @include themify($themes) {
    color: themed('main');
  }
  font-size: 1.3rem;
  font-weight: 300;
  text-align: left;

  .message-supplier {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
    padding: 0 2.5rem;

    .supplier {
      width: 35%;
    }

    .message {
      width: 45%;
    }

    .created-time {
      width: 20%;
    }

    p {
      margin: 0;
    }
  }
}
