@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.app-layout-mobile,
.app-layout-tablet {
  .card-content {
    display: flex;
    flex-direction: column;
    flex: 0 0 100%;
    padding: 1.8rem;

    & .dropdowns-container {
      width: 100%;
      display: block;

      & .dropdown-item {
        margin-bottom: 1.1rem;

        & .dropdown-label {
          font-weight: 800;
          font-size: 1.5rem;
          @include themify($themes) {
            color: themed('main');
          }
        }

        & .select-dropdown {
          width: 100%;
          height: 4.2rem;
          border-radius: 0.5rem;
          font-size: 1.4rem;
          font-weight: 400;
          @include themify($themes) {
            color: themed('main');
          }
          border: none;

          &.disabled {
            opacity: 30%;
            pointer-events: none;
            user-select: none;
          }

          [class$='-control'] {
            @include themify($themes) {
              color: themed('main');
            }
            border: none;
            @include themify($themes) {background-color: themed('mainHover');}
            height: 4.2rem;
            box-shadow: none;

            &:hover {
              border: none;
            }
          }

          [class$='-placeholder'] {
            @include themify($themes) {
              color: themed('main');
            }
            font-size: 1.4rem;
            font-weight: 400;
          }

          [class$='-indicatorContainer'] {
            @include themify($themes) {
              color: themed('main');
            }
          }

          [class$='-singleValue'] {
            @include themify($themes) {
              color: themed('main');
            }
            width: 19rem;
          }

          [class$='-indicatorSeparator'] {
            background: none;
            @include themify($themes) {
              color: themed('main');
            }
          }

          [class$='-indicator'] {
            @include themify($themes) {
              background: themed('main');
            }
            color: $white;
          }
        }
      }

      & .submit-button {
        &.disabled {
          opacity: 30%;
          pointer-events: none;
          user-select: none;
        }
      }

      & .truck-selector-submit {
        grid-column: span 2;
      }
    }
  }

  .modal-select-car {
    .general-search {
      & .card-title {
        margin: 1.5rem auto;
      }
    }
  }
}
