@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.favorite-button {
  .spinner-container {
    position: relative;
    width: 5rem;
    height: 5rem;
    .spinner {
      right: 4.5rem;
      left: unset;
    }
  }
}
