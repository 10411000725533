@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.reference-table-wrapper {
  table {
    background: none !important;
    @media screen and (max-width: 760px) {
      font-size: 0.9rem !important;
      padding: 0.4rem 1rem !important;
      box-sizing: border-box !important;
    }
    .body {
      .row {
        background: $blue-5 !important;
        cursor: default !important;
        &:hover {
          background: $blue-5 !important;
        }
      }
    }
  }
}
