@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.minimum-order-value {
    max-width: 132rem;
    width: 100%;
    margin: 5rem auto;

    .order-value__container-top {
        padding: 0 2rem;

        .container-top-title {
            font-style: normal;
            font-weight: 700;
            font-size: 2.4rem;
            line-height: 3.3rem;

            @include themify($themes) {
                color: themed('main');
            }
        }

        .container-top-filter {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 1rem;

            .filter-input {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap: 2rem;

                .filter-item {
                    display: flex;
                    flex-direction: row;
                    min-width: 21.5rem;

                    .dropdown-label {
                        font-weight: 800;
                        font-size: 1.2rem;
                    }

                    .select-client-name {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 4.2rem;
                        border-radius: 0.5rem;
                        border: none;
                        padding: 0 1rem;
                        font-size: 1.6rem;
                        font-weight: 400;

                        @include themify($themes) {
                            color: themed('main');
                        }

                        outline: none;
                        transition: all 0.3s ease-in-out;
                        background-color: $input-color;
                        box-sizing: border-box;

                        &::placeholder {
                            color: rgba(39, 80, 159, 0.5);
                            font-size: 1.4rem;
                        }
                    }
                }
            }

            .select-dropdown {
                height: 4.2rem;
                border-radius: 0.5rem;
                font-size: 1.4rem;
                font-weight: 400;

                @include themify($themes) {
                    color: themed('main');
                }

                width: 100%;

                border: none;

                [class$='-control'] {
                    @include themify($themes) {
                        color: themed('main');
                    }

                    border: none;

                    @include themify($themes) {
                        background-color: themed('mainHover');
                    }

                    height: 4.2rem;
                    box-shadow: none;

                    &:hover {
                        border: none;
                    }
                }

                [class$='-placeholder'] {
                    @include themify($themes) {
                        color: themed('main');
                    }

                    font-size: 1.4rem;
                    font-weight: 400;
                }

                [class$='-indicatorContainer'] {
                    @include themify($themes) {
                        color: themed('main');
                    }
                }

                [class$='-singleValue'] {
                    @include themify($themes) {
                        color: themed('main');
                    }
                }

                [class$='-indicatorSeparator'] {
                    background: none;

                    @include themify($themes) {
                        color: themed('main');
                    }
                }

                [class$='-indicator'] {
                    @include themify($themes) {
                        background: themed('main');
                    }

                    color: $white;
                }
            }

            .filter-button {
                .filter-item {
                    width: 25rem;
                    display: flex;
                    flex-direction: row;
                    gap: 2rem;

                    @media screen and (max-width: 768px) {
                        width: 100%;
                    }

                    .select-dropdown {
                        width: 400px;
                        height: 4.2rem;
                    }

                    .select-dropdown__control.select-dropdown__control {
                        width: 400px;
                    }

                    .dropdown-label {
                        font-weight: 800;
                        font-size: 1.2rem;
                    }

                    .filter-btn-search {
                        width: 11.8rem;
                        height: 3.8rem;
                        border: 1px solid $red;
                        border-radius: 0.5rem;
                        font-size: 1.6rem;
                        line-height: 1.9rem;
                        background: $red;
                        color: $white;

                        @media screen and (max-width: 768px) {
                            width: 100%;
                        }
                    }

                    .filter-btn-search:hover {
                        cursor: pointer;
                        opacity: 0.8;
                    }

                    .filter-btn-reset {
                        width: 11.8rem;
                        height: 3.8rem;
                        border: 1px solid $blue;
                        border-radius: 0.5rem;
                        font-size: 1.6rem;
                        line-height: 1.9rem;

                        @media screen and (max-width: 768px) {
                            width: 100%;
                        }

                        @include themify($themes) {
                            background: themed('main');
                        }

                        color: $white;
                    }

                    .filter-btn-reset:hover {
                        cursor: pointer;
                        opacity: 0.8;
                    }
                }
            }

            @media screen and (max-width: 942px) {
                flex-direction: column !important;

                .filter-button {
                    margin-top: 2rem;
                }
            }

            @media screen and (max-width: 768px) {
                div.filter-input {
                    flex-direction: column;

                    .filter-item {
                        margin-top: 1rem;
                    }
                }
            }
        }
    }

    .order-value__container-middle {
        padding: 0rem;

        .clients-list {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            justify-content: space-evenly;
            margin: 2rem 0;
            position: relative;
            min-height: 40rem;
            width: 100%;
            align-items: center;

            .no-data {
                font-size: 2.4rem;
                font-weight: bold;

                @include themify($themes) {
                    color: themed('main');
                }

                opacity: 60%;
            }

            & .spinner-client {
                position: absolute;
            }

            .client-item {
                width: 30rem;
                height: 25rem;

                @media screen and (min-width: 405px) and (max-width: 640px) {
                    width: 38rem;
                }

                @include themify($themes) {
                    background-color: themed('secondary-3');
                }

                border-radius: 1rem;

                .client-heder {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: row;

                    width: 26.5rem;
                    border-bottom: 1px solid $hovered-blue;
                    margin: 0 auto;
                    height: 5.4rem;

                    .client-title {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 1.6rem;
                        line-height: 1.9rem;

                        @include themify($themes) {
                            color: themed('main');
                        }

                        .client-check {
                            margin: 0rem 1rem;
                        }
                    }

                    .client-icons {
                        width: 2.2rem;
                        height: 1.7rem;
                        font-weight: 900;
                        font-size: 1.95955rem;
                        line-height: 2.2rem;

                        display: flex;
                        align-items: center;
                        text-align: center;

                        @include themify($themes) {
                            color: themed('main');
                        }
                    }

                    .client-icons:hover {
                        cursor: pointer;
                        opacity: 0.8;
                    }
                }

                .client-info {
                    height: 12rem;
                    width: 26.5rem;

                    @media screen and (min-width: 405px) and (max-width: 640px) {
                        width: 34rem;
                    }

                    margin: 10px auto;

                    .client-body {
                        display: flex;
                        align-items: center;
                        text-align: center;
                        flex-direction: row;
                        justify-content: space-between;
                        padding: 0.5rem 0;

                        .client-lable {
                            font-weight: 400;
                            font-size: 1.4rem;
                            line-height: 1.6rem;

                            /* lightblue */
                            color: #8494c0;
                        }

                        .client-content {
                            font-weight: 500;
                            font-size: 1.4rem;
                            line-height: 1.6rem;
                            text-align: right;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            padding-left: 1rem;

                            /* blue */
                            @include themify($themes) {
                                color: themed('main');
                            }
                        }
                    }
                }

                .client-btn {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    width: 26.5rem;
                    height: 3.8rem;
                    bottom: 2rem;
                    margin: 0 2rem;
                    border: 1px solid $blue-1;

                    @include themify($themes) {
                        background-color: themed('secondary-3');
                    }

                    border-radius: 1rem;
                    font-size: 1.2rem;
                    line-height: 1.9rem;

                    @include themify($themes) {
                        color: themed('main');
                    }

                    .container-spiner {
                        margin: 0rem 0.3rem 0rem 0rem;
                    }
                }

                .client-btn:hover {
                    @include themify($themes) {
                        background: themed('main');
                    }

                    color: $white;
                    cursor: pointer;

                    circle {
                        stroke: $white;
                        stroke-width: 7;
                    }
                }
            }
        }
    }
}