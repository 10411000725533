@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.ReactModal__Content {
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
}

.print-iframe-modal {
  padding: 0 1rem;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding: 0 2rem;
    box-sizing: border-box;
    min-width: auto;
    margin-top: 5rem;
  }

  & .print-iframe-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 0.1rem solid $blue-14;

    & .title {
      @include themify($themes) {
        color: themed('main');
      }
      font-size: 1.6rem;
    }

    & .header-actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      @include themify($themes) {
        color: themed('main');
      }
      font-size: 1.4rem;
      position: relative;

      & .print-spinner {
        top: 0;
        left: -4rem;
        width: 2rem;
      }

      & .print-btn {
        display: flex;
        align-items: center;
        @include themify($themes) {
          color: themed('main');
        }
        font-size: 1.4rem;
        gap: 0.5rem;
        cursor: pointer;

        &:hover {
          opacity: 80%;
        }
      }

      & .close-modal {
        cursor: pointer;

        &:hover {
          opacity: 80%;
        }
      }
    }
  }

  .print-iframe {
    @media screen and (max-width: 768px) {
      min-width: auto;
    }

    min-width: 70rem;
    height: 55rem;
  }
}
