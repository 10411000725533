@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.admin-client-navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $grey-1;

  .breadcrumbs {
    flex: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 1rem;
    @include themify($themes) {
      color: themed('main');
    }

    .icon {
      @include themify($themes) {
        background-color: themed('secondary');
      }
      border-radius: 100%;
      width: 1.7rem;
      height: 1.7rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0.1rem solid $blue-icon-color;

      svg {
        font-size: 1.1rem;
        font-weight: bold;
        @include themify($themes) {
          background-color: themed('secondary');
        }
      }
    }

    .back-description {
      margin: 0;
    }
  }

  .title h1 {
    flex: 1;
    font-family: Open Sans, 'sans-serif';
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 2.5rem;
    line-height: 3.4rem;
    font-weight: normal;
    text-align: center;

    @media only screen and (max-width: 768px) {
      font-size: 1.9rem;
    }
  }

  .options {
    flex: 1;
  }
}
