@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

table {
  @include themify($themes) {
    color: themed('main');
  }
  .table-expand {
    width: 2rem;
    padding-right: 1rem;
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
