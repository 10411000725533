@import 'assets/sass/variables';
@import 'src/assets/sass/themes';


.modal_file {

  max-width: fit-content;
  padding: 2rem;
  min-height: 13rem;
  display: flex;
  flex-direction: column;

  /* The important part: vertically center the items */
  align-items: center;

  .text {
    font-size: 2.2rem;
    color: $blue;
    margin-bottom: 10rem;
  }
}