@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.top-sales-navbar-container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $grey-1;
  padding: 1rem 0;
  width: 100%;
  background: #f2f5f8;
  height: 10rem;

  .breadcrumbs {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 2.3rem;


    @include themify($themes) {
      color: themed('main');
    }

    font-size: 2.5rem;
  }


}