@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.catalog-vehicle-search-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  @include themify($themes) {
    background-color: themed('secondary');
  }
  border-radius: 1rem;
  box-shadow: 0.2rem 0.2rem 1rem #0000000a;
  padding: 1.8rem;

  & .catalog-vehicle-selector-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.1rem;
    gap: 0.6rem;

    & .tab {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 5.3rem;
      height: 2.7rem;
      background-color: $blue-5;
      color: $white;
      font-size: 2rem;
      border-radius: 0.9rem;

      &.active {
        @include themify($themes) {
          background-color: themed('main');
        }
      }
    }
  }

  & .dropdowns-container {
    display: flex;
    gap: 1.1rem;
    align-items: flex-end;
    width: 100%;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    & .dropdown-item {
      width: 100%;

      & .dropdown-label {
        font-weight: 800;
        font-size: 1.5rem;
        @include themify($themes) {
          color: themed('main');
        }
      }

      & .select-dropdown {
        width: 100%;
        border-radius: 0.5rem;
        font-size: 1.4rem;
        font-weight: 400;
        @include themify($themes) {
          color: themed('main');
        }
        border: none;

        &.disabled {
          pointer-events: none;
          user-select: none;
          &:before {
            opacity: 0;
          }
        }

        [class$='-control'] {
          @include themify($themes) {
            color: themed('main');
          }
          border: none;
          @include themify($themes) {background-color: themed('mainHover');}
          box-shadow: none;

          &:hover {
            border: none;
          }
        }

        [class$='-placeholder'] {
          @include themify($themes) {
            color: themed('main');
          }
          font-size: 1.4rem;
          font-weight: 400;
        }

        [class$='-indicatorContainer'] {
          @include themify($themes) {
            color: themed('main');
          }
        }

        [class$='-singleValue'] {
          @include themify($themes) {
            color: themed('main');
          }
        }

        [class$='-indicatorSeparator'] {
          background: none;
          @include themify($themes) {
            color: themed('main');
          }
        }

        [class$='-indicator'] {
          @include themify($themes) {
            background: themed('main');
          }
          color: $white;
        }
      }
    }

    & .truck-selector-submit {
      min-width: 10rem;
      width: 100%;
    }

    & .catalog-car-selector-btn {
      min-width: 10rem;
      width: 100%;
    }
  }
}
