@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.tabs-container {
  & .tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include themify($themes) {
      color: themed('main');
    }

    & .tab-item {
      width: 100%;
      height: 5.4rem;
      list-style: none;
      font-size: 1.6rem;
      font-weight: 300;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 1rem 1rem 0 0;
      cursor: pointer;
      text-align: center;

      @media screen and (max-width: 768px) {
        padding: 0 0.5rem;
        font-size: 1.15rem;
      }

      &.active {
        @include themify($themes) {
          background-color: themed('secondary');
        }
        font-weight: bold;
      }
    }
  }
}

/* Responsive CSS Styles */

.app-layout-desktop {
}

.app-layout-tablet {
}

.app-layout-tablet,
.app-layout-mobile {
  .tabs-container {
  }
}

.app-layout-mobile {
  .tabs-container {
    .tabs {
      .swiper-wrapper {
        .swiper-slide {
          max-width: 28rem;
          width: auto;

          .tab-item {
            padding: 0 2rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .tabs-container {
    }
  }
}
