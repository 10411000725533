@import '../../assets/sass/variables';
@import 'src/assets/sass/themes';

.quantity-disponibility {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  .quantity-availability-stok-title {
    font-size: 1.1rem;
    line-height: 1.1rem;
    @include themify($themes) {
      color: themed('main');
    }

    .disponibility {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      span {
        white-space: nowrap;
      }

      .spinner {
        padding-left: 1rem;
        font-size: 0.8rem;
      }
    }

    .disponible-title {
      &.unavailable {
        width: 100%;
      }
      .stock-availability {
        color: $green;
      }

      .contact_number {
        display: flex;
        flex-direction: column;

        .stock-unavailability {
          color: $red-2;
          &.title {
            white-space: nowrap;
          }
          a {
            color: $red-2;
            text-decoration: none;
            white-space: nowrap;
          }

          p {
            margin-top: 1px;
          }
        }
      }
    }
  }

  .quantity-availability-stok {
    display: flex;

    .quantity-stock-availability,
    .quantity-stock-unavailability {
      height: 1.2rem;
      width: 1.2rem;
      border-radius: 50%;
      display: inline-block;
    }

    .quantity-stock-availability {
      &.green {
        background-color: $green-2;
      }
      &.blue {
        @include themify($themes) {
          background-color: themed('main');
        }
      }
      background-color: $red-2;
    }

    .quantity-stock-unavailability {
      background-color: $red-2;
    }
  }
}
