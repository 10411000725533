@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.diagnostic-container-modal {
  height: 29.5rem;
  width: 59.9rem;
  display: flex;
  margin-top: 4.3rem;
  align-items: center;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 100vh;
    overflow-y: visible;
    margin-top: 0rem;
    padding-top: 4.3rem;
  }

  .title {
    @include themify($themes) {
      color: themed('main');
    }

    font-size: 25px;
    line-height: 34px;
    text-align: center;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .select-dropdown {
      width: 26.8rem;
      height: 4.2rem;
      border-radius: 0.5rem;
      font-size: 1.4rem;
      font-weight: 400;

      @include themify($themes) {
        color: themed('main');
      }

      margin-top: 1rem;
      border: none;

      [class$='-control'] {
        @include themify($themes) {
          color: themed('main');
        }

        @include themify($themes) {
          background-color: themed('inputColor');
        }

        height: 4.2rem;
        box-shadow: none;

        &:hover {
          border: none;
        }
      }

      [class$='-placeholder'] {
        @include themify($themes) {
          color: themed('main');
        }

        font-size: 1.4rem;
        font-weight: 400;
      }

      [class$='-indicatorContainer'] {
        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-singleValue'] {
        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-indicatorSeparator'] {
        background: none;

        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-indicator'] {
        @include themify($themes) {
          background: themed('main');
        }

        color: $white;
      }
    }
  }

  .btn-save {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      @include themify($themes) {
        background-color: themed('main');
      }

      color: $white;
      border: none;
      border-radius: 0.5rem;
      padding: 0.5rem 1.5rem;
      font-weight: bold;
      width: 26.7rem;
      height: 4.7rem;
      font-size: 1.4rem;
      outline: none;
      transition: all 0.3s ease-in-out;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
      }

      .title-btn-save {
        padding-left: 1rem;
      }

      .extra-small {
        width: 2rem;

        circle {
          stroke: $white;
        }
      }
    }
  }
}