@import "src/assets/sass/variables";
@import "src/assets/sass/themes";

.catalog-view-mode {
  min-height: 70%;
  width: 100%;
  position: relative;
  margin: 0 auto;
  padding-bottom: 6rem;

  &.GRID {
    display: grid;
    grid-template-columns: repeat(auto-fit, 35rem);
    grid-gap: 1rem;
    justify-content: center;
  }

  &.LIST {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: flex-start;
  }

  @media screen and (max-width: 990px) and (min-width: 768px) {
    grid-template-columns: repeat(auto-fit, 35rem);
  }

  .car-parts-spinner {
    background: none;
  }

  .car-parts-spinner-info {
    background: rgba(255, 255, 255, 0.5);
    min-height: 20rem;
  }

  .arrowRight,
  .arrowLeft {
    &.disable {
      opacity: 50%;
    }

    position: fixed;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 95%;
    fill: $blue-icon-color;
    cursor: pointer;
  }

  .arrowRight {
    right: -50px;
  }

  .arrowLeft {
    left: 3.5%;
  }
}

/* Responsive CSS Styles */

.app-layout-mobile {
  @media screen and (max-width: 480px) {
    .catalog-view-mode {
      &.GRID {
        grid-template-columns: repeat(auto-fit, 100%);

        div[class^="part-list-item-"] {
          width: 100%;
        }
      }
    }
  }
}