@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.automotor-home-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
    gap: 1rem;

    @media screen and (max-width: 768px) {
        padding: 0rem 1rem;
        box-sizing: border-box;
    }



    .promotion {
        box-sizing: border-box;
        width: 100%;
        height: 0;
        overflow: hidden;
        margin-top: 0rem;

        .swiper-prev {
            margin-left: -9rem;
        }

        .swiper-next {
            margin-right: -9rem;
        }

        .swiper-container .navigation .arrow {
            font-size: 3.2rem;
        }
    }

    .promotion-show {
        height: 13rem;
        overflow: initial;
        transition: height 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    }

    .orders-table {
        width: 100%;
        display: flex;
        flex-direction: column;
        min-height: 40rem;
        box-sizing: border-box;
        align-items: center;
        gap: 3rem;

        .title-table {
            @include themify($themes) {
                color: themed('main');
            }

            font-weight: 600;
            text-align: center;
            width: 100%;
            font-size: 3rem;
            line-height: 4.1rem;
        }


        .button-table {
            width: 19.4rem;
            height: 3.4rem;

            button {
                height: 100%;
            }
        }
    }

    .tariffs {
        width: 144rem;

        .small {
            position: initial;
            margin-top: 5rem;
        }

        .items {
            background-color: #e4ebf7;
            width: 100%;
            min-height: 7rem;
            border-radius: 1rem;
            margin: 2rem 0rem 1rem 0rem;

            .no-data {
                @include themify($themes) {
                    color: themed('main');
                }

                font-size: 1.8rem;
                padding: 1.1rem;
                min-height: 10rem;
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
                flex-direction: column;
            }

            .item-title {
                color: $blue;
                font-size: 1.6rem;
                text-align: center;
                padding-top: 1rem;
                font-weight: 600;
            }

            .swiper-slide {
                display: flex;
                justify-content: center;
                align-items: center;
                width: auto;
            }

            .navigation {
                margin-top: -3.2rem;
            }

            .navigation.swiper-prev {
                margin-left: -9rem;
            }

            .navigation.swiper-next {
                margin-right: -9rem;
            }

            .swiper-container .navigation .arrow {
                font-size: 3.2rem;
            }

            .item-body {
                position: relative;
                display: flex;
                height: 100%;
                width: max-content;
                min-height: 5rem;
                justify-content: flex-end;

                :hover {
                    cursor: pointer;
                }

                & .automotor-tariff-spinner {
                    max-width: 8rem;
                    max-height: 7rem;
                    width: 100%;
                    height: 100%;
                    margin-top: 0rem;
                }

                .item-container {
                    padding: 0.5rem;

                    .item {
                        width: auto;

                        img {
                            max-width: 12rem;
                            max-height: 4rem;
                            // flex-grow: 1;
                        }

                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        flex-direction: column;
                        min-height: 6rem;

                        gap: 0.5rem;

                        .item-name {
                            font-weight: bold;
                            font-size: 1.1rem;
                            text-align: center;
                            margin: 0;

                            @include themify($themes) {
                                color: themed('main');
                            }
                        }
                    }
                }
            }
        }
    }
}

.automotor-home-page {

    .tariffs,
    .promotion-show {
        @media screen and (max-width: 1600px) {
            max-width: $container-width-large;
            margin: 0 auto;
        }

        @media screen and (max-width: 1440px) {
            max-width: calc(100% - 18rem);
        }

        @media screen and (max-width: 992px) {
            width: 100%;
            max-width: 100%;
            margin: 0;
            padding: 0;
        }
    }

    .promotion-show {
        @media screen and (max-width: 992px) {
            height: 20rem;
        }
    }
}