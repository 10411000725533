@import "../../../../assets/sass/variables";
@import "src/assets/sass/themes";

.edit-contact-container {
  .popup-wrapper {
    width: 88%;
    padding: 0 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .update-button {
      @include themify($themes) {
        background: themed("main");
      }
      border: none;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      gap: 15px;
      padding: 15px;
      cursor: pointer;
      font-size: 16px;
      width: 290px;
      margin: 10px auto;
      margin-bottom: 50px;

      .icon {
        color: $white !important;
        font-size: 16px;
      }
    }

    .icon {
      font-size: 2.5rem;
      @include themify($themes) {
        color: themed("main");
      }
      cursor: pointer;
    }

    .close-btn {
      position: absolute;
      @include themify($themes) {
        color: themed("main");
      }
      right: 1.888rem;
      top: 1.638rem;
      font-size: 2.238rem;
      cursor: pointer;

      @media screen and (max-width: 990px) {
        right: 2rem;
        top: 4rem;
      }
    }

    .select {
      p {
        @include themify($themes) {
          color: themed("main");
        }
        font-size: 1.4rem;
        line-height: 1.9rem;
        font-weight: 700;
        width: 100%;
        padding-left: 0.4rem;
        margin: 0 0 0.5rem 0;
      }

      select {
        border: none;
        @include themify($themes) {
          background-color: themed("secondary-3");
        }
        width: 22rem;
        border-radius: 10px;
        outline: none;
        height: 4.2rem;
        font-size: 1.5rem;
        @include themify($themes) {
          color: themed("main");
        }
      }
    }

    .popup-title {
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 2.55rem;
      line-height: 3.4rem;
      @include themify($themes) {
        color: themed("main");
      }
      padding: 2rem 13rem;
      padding-bottom: 0rem;
      position: relative;

      .button {
        display: flex;
        cursor: pointer;
        margin: 1rem auto;
        border-radius: 0.8rem;
        font-weight: 700;
        border: none;
        line-height: 1.634rem;
        font-size: 1.2rem;
        height: 3.5rem;
        width: 40rem;
        @include themify($themes) {
          background-color: themed("main");
        }
        color: $white;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
      }

      span {
        font-weight: 700;
      }
    }

    .client-select {
      display: flex;
      min-width: 23rem;
      padding: 0;
      justify-content: flex-start;
      width: 100%;

      @media screen and (max-width: 990px) {
        margin-left: 0;
      }

      @media screen and (min-width: 768px) and (max-width: 990px) {
        justify-content: center;
      }

      .radio {
        position: relative;
        margin: 0.3rem;

        input[type="radio"] {
          z-index: 2;
          cursor: pointer;
          position: absolute;
          width: 1.5em;
          height: 1.5em;
          opacity: 0;

          + label {
            &:before {
              content: "";
              background: $blue-10;
              border-radius: 100%;
              border: 2px solid $blue;
              display: inline-block;
              position: relative;
              top: -0.2em;
              margin-right: 0.5em;
              vertical-align: top;
              text-align: center;
              width: 1.5em;
              height: 1.5em;
            }
          }

          &:checked {
            + label {
              &:before {
                @include themify($themes) {
                  background-color: themed("main");
                }
              }
            }
          }
        }

        label {
          font-size: 1.4rem;
          line-height: 1.9rem;
          font-weight: 700;
          margin-bottom: 0.5rem;
          padding-left: 0.4rem;
          @include themify($themes) {
            color: themed("main");
          }
        }

        .check-icon {
          position: absolute;
          left: 10px;
          top: 3px;
          color: $blue-10;
          font-size: 14px;
        }
      }
    }

    .form {
      width: 100%;
      padding-top: 0.75rem;
      padding-bottom: 2.4rem;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .form-title {
        font-size: 11px;
        line-height: 2.7rem;
        text-transform: uppercase;
        margin: 0;
        color: $blue-text-color-o47;
      }

      .row {
        display: flex;
        gap: 3.2rem;
        width: 100%;

        .container {
          margin: 1rem 0;
          .select-dropdown {
            height: 4.2rem;
            border-radius: 0.5rem;
            font-size: 1.4rem;
            font-weight: 400;
            @include themify($themes) {
              color: themed("main");
            }
            width: 100%;

            border: none;

            [class$="-control"] {
              @include themify($themes) {
                color: themed("main");
              }
              border: none;
              @include themify($themes) {
                background-color: themed("secondary-3");
              }
              height: 4.2rem;
              box-shadow: none;

              &:hover {
                border: none;
              }
            }

            [class$="-placeholder"] {
              @include themify($themes) {
                color: themed("main");
              }
              font-size: 1.4rem;
              font-weight: 400;
            }

            [class$="-indicatorContainer"] {
              @include themify($themes) {
                color: themed("main");
              }
            }

            [class$="-singleValue"] {
              @include themify($themes) {
                color: themed("main");
              }
            }

            [class$="-indicatorSeparator"] {
              background: none;
              @include themify($themes) {
                color: themed("main");
              }
            }

            [class$="-indicator"] {
              @include themify($themes) {
                background: themed("main");
              }
              color: $white;
            }
          }
          label {
            @include themify($themes) {
              color: themed("main");
            }
            font-size: 1.4rem;
            line-height: 1.9rem;
            font-weight: 700;
            width: 100%;
            margin-bottom: 0.5rem;
            display: inline-block;
            padding-left: 0.4rem;
          }

          .text-input {
            border: none;
            padding: 0 1.1rem;
            @include themify($themes) {
              background-color: themed("secondary-3");
            }

            border-radius: 10px;
            outline: none;
            height: 4.2rem;
            font-size: 1.5rem;
            @include themify($themes) {
              color: themed("main");
            }
          }

          .error-message {
            color: #ca312a !important;
            display: block;
            margin-top: 0.5rem;
            font-size: 1rem;
          }
        }
      }
    }

    textarea {
      max-height: 100px;
      font-family: inherit;
      font-size: inherit;
      line-height: 2.5;
      resize: none;
    }

    @media screen and (min-width: 652px) and (max-width: 990px) {
      .form .row {
        justify-content: center;

        .container {
          label {
            font-size: 12px;
          }

          .text-input {
            width: 150px;
          }

          select {
            width: 172px;
          }

          .logo-upload-container .logo-upload {
            font-size: 10px;
            width: 90px;

            .select-logo-title {
              line-height: 12px;
            }
          }
        }
      }
    }

    @media screen and (max-width: 652px) {
      padding: 0 2rem;
      box-sizing: border-box;

      width: 100%;

      .popup-title {
        padding: 0rem;
        margin: 3rem 0;
      }

      .form {
        max-height: 62vh;
        overflow-y: auto;
        overflow-x: hidden;
        display: initial;

        .row {
          justify-content: center;
          flex-direction: column;
          gap: 1rem;

          .container {
            label {
              font-size: 14px;
            }

            // .text-input {
            //   width: 95%;
            // }

            select {
              width: 100%;
            }

            .logo-upload-container .logo-upload {
              font-size: 12px;
              width: 100%;

              .select-logo-title {
                line-height: 14px;
              }
            }

            textarea {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}
