@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.submit-quotation-modal {
  background-color: $white-5;
  width: 90rem;
  min-height: 25rem;

  @include themify($themes) {
    color: themed('main');
  }

  font-weight: normal;
  position: relative;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 990px) and (min-width: 768px) {
    width: 100%;
  }

  .close {
    text-align: right;
    font-size: 2.5rem;
    margin-right: 1rem;

    svg {
      cursor: pointer;
    }
  }

  .modal-dialog {
    padding: 0 4.7rem 2rem;
    min-height: 20rem;
    text-align: center;
    border-radius: 1rem;
    margin: 0 auto;

    @media only screen and (max-width: 768px) {
      padding: 1rem;
      box-sizing: border-box;
    }

    .espace-automotor-felicitations .espace-automotor-felicitations-message {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-radius: 1rem;
      align-items: center;
      padding-bottom: 2rem;
      margin-top: 3rem;
      margin-left: 0rem;
      margin-right: 0rem;
      max-width: 80.7rem;
      min-height: 28rem;
    }

    .send-mail-container .send-email-to-seller {
      background-color: #eaf0fb;
      padding: 1.2rem 7rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 4.5rem 0rem 1rem 0rem;
      position: relative;
      border-radius: 5px;
      cursor: pointer;
    }

    svg {
      font-size: 2.4rem;
    }

    & .upload-btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      padding: 1rem;

      & .icon {
        width: 6.7rem;
        height: 8.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;

        & img {
          width: 100%;
          height: 100%;
        }
      }

      & .text {
        font-weight: 500;
        font-size: 2.5rem;
        line-height: 3.4rem;

        @include themify($themes) {
          color: themed('main');
        }

        p {
          margin: 0;
        }

        .error {
          font-size: 1.5rem;
        }

        .title {
          font-size: 2.2rem;
          line-height: 3rem;
        }

        .subtitle {
          font-size: 1.6rem;
          line-height: 2.2rem;
          color: $anchor-text-color-proforma-modal;

          a {
            font-weight: bold;
            color: $anchor-text-color-proforma-modal;
            text-decoration: none;
            cursor: pointer;
          }
        }

        .quotation-export_file_example {
          font-size: 1.3rem;
          line-height: 2.2rem;
          color: $red;
          cursor: pointer;

          svg {
            circle {
              stroke: $red;
            }
          }

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }

    .actions {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      padding-top: 2rem;

      .no {
        width: 30%;
      }

      .yes {
        width: 30%;
      }

      .red {
        border-radius: 0.5rem;

        @include themify($themes) {
          background-color: themed('secondary');
        }

        border: 0.1rem solid $blue-text-color;

        @include themify($themes) {
          color: themed('main');
        }

        &:hover {
          background-color: $red;
          border: 0.1rem solid $red;

          @include themify($themes) {
            color: themed('secondary');
          }
        }
      }

      .blue {
        border-radius: 0.5rem;

        @include themify($themes) {
          background-color: themed('secondary');
        }

        border: 0.1rem solid $blue-text-color;

        @include themify($themes) {
          color: themed('main');
        }

        &:hover {
          @include themify($themes) {
            background-color: themed('main');
          }

          @include themify($themes) {
            color: themed('secondary');
          }
        }
      }
    }
  }
}