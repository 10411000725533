@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.favorite-sidebar {
  position: relative;

  @include themify($themes) {
    background-color: themed('secondary');
  }

  border-radius: 1rem;

  @include themify($themes) {
    color: themed('main');
  }

  min-height: 30rem;

  .new-list-container {
    .new-list-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.5rem;
      font-size: 1.6rem;
      line-height: 1.8rem;
      font-weight: bold;

      .img-wrapper {
        cursor: pointer;

        @include themify($themes) {
          background-color: themed('secondary-2');
        }

        svg {
          path {
            @include themify($themes) {
              fill: themed('favorite', 'bgFill');
            }
          }

        }

        padding: 0.5rem;
      }
    }

    .new-list-input {
      .actions-content {
        padding: 0 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        height: 2.6rem;

        button {
          height: 100%;

          @include themify($themes) {
            color: themed('main');
          }

          font-weight: bolder;
        }

        .cancel,
        .save {
          cursor: pointer;
          height: 100%;
          width: 2.6rem;

          @include themify($themes) {
            background-color: themed('favorite', 'selectTab');
          }

          svg {
            path {
              @include themify($themes) {
                fill: themed('favorite', 'bgFill');
              }
            }
          }

          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .input-content {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.5rem 1rem;

        input {
          width: 100%;
          height: 2.2rem;

          @include themify($themes) {
            border-color: themed('main');
          }

          font-size: 1.3rem;

          @include themify($themes) {
            color: themed('main');
          }
        }
      }
    }
  }

  // .list-container{
  //height: 100%;
  //max-height: 50rem;
  //overflow-y: scroll;
  // }
}