@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.item-container-offert {
    width: 22rem;
    min-height: 5rem;
    border-radius: 0.5rem;
    padding: 1rem 0rem;
    position: relative;

    @include themify($themes) {
        background: $white;
    }

    @media screen and (max-width: 1220px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 23rem;
        height: auto;
    }

    padding-left: 1rem;


    .item-shop {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;

        @media screen and (max-width: 1220px) {
            display: flex;

            justify-content: center;

            height: auto;
        }


        .item-column-1 {
            display: flex;
            flex-direction: column;

            margin: 0rem;
            gap: 1rem;
            width: 12rem;

            @media screen and (max-width: 1220px) {

                justify-content: center;


            }


            .img {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 5rem;
                height: 5rem;
                border: 0.1rem solid $gray;

                img {
                    max-width: 5rem;
                    max-height: 5rem;
                    margin: auto;
                }
            }


        }

        .item-column-2 {

            display: flex;
            flex-direction: column;
            width: 100%;
            margin: 0rem;
            height: 100%;



            .reference {
                text-align: left;
                margin-bottom: 0rem;
                font-size: 1.1rem;
                font-weight: 700;
                line-height: 1.5;
                padding-right: 1rem;

                @media screen and (max-width: 1220px) {

                    text-align: center;


                }

                @include themify($themes) {
                    color: themed('main');
                }
            }



            .description {
                display: flex;
                flex-direction: column;
                height: 100%;

                box-sizing: border-box;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;


                span {

                    color: #171818;
                    font-size: 1.1rem;
                    width: 11.8rem;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    position: relative;
                }

                span::after {
                    content: '...';
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    background-color: white;

                    padding-left: 4px;

                }
            }

            .btn {
                display: flex;
                justify-content: flex-end;
                padding-right: 2rem;
                box-sizing: border-box;

                button {
                    width: 8rem;
                    height: 2.5rem;
                }
            }
        }

        .item-column-3 {
            height: 100%;

            .show-item {
                padding: 0rem 1rem;
                box-sizing: border-box;
                font-size: 1.7rem;
            }
        }
    }

    .no-data {
        font-size: 2.4rem;
        font-weight: bold;

        @include themify($themes) {
            color: themed('main');
        }

        opacity: 60%;
        margin-top: 5rem;
        white-space: nowrap;
    }
}