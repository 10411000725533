@import "src/assets/sass/variables";
@import "src/assets/sass/themes";

.vehicle-concern-wrapper-content {
  .vehicle-concern-container-content {
    .tabs-container {
      width: 100%;
      position: relative;
      padding: 7rem 0;
      min-height: 10rem;

      .vehicle-selector-content-tabs {
        gap: 2rem;
        margin: 0;
        position: absolute;
        top: 3rem;
        left: calc(50% - 30rem);

        @media screen and (max-width: 768px) {
          position: relative;
          top: auto;
          left: auto;
        }
      }
    }
  }
}
