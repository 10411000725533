@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.catalog-vehicle-tyre-page {
  @media only screen and (max-width: 990px) {
    // background-color: #f2f5f8;
  }

  .body {
    @media only screen and (max-width: 990px) {
      // background-color: #f2f5f8;
    }

    .tyre-header {
      @include themify($themes) {
        color: themed('main');
      }

      display: flex;
      flex-direction: row;
      margin-top: 3.3rem;

      @media only screen and (max-width: 990px) {
        margin-top: 0rem;
        padding-top: 2rem;
      }

      .title {
        @include themify($themes) {
          color: themed('main');
        }

        font-size: 2.5rem;
        width: 49.5rem;
      }

      .btn-new {
        button {
          height: 3.5rem;
          width: 24.1rem;
          border-radius: 8px;
          background-color: $blue-13;
          border: none;
          cursor: pointer;
          color: $white;
          font-size: 1.4rem;
          font-weight: bold;

          &:hover {
            @include themify($themes) {
              background-color: themed('main');
            }
          }
        }
      }

      .filter {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 56rem;

        .title-filter {
          font-size: 1.1rem;
          padding: 1rem 2rem;
        }

        .btn-filter {
          &.active {
            pointer-events: none;
          }

          button {
            height: 3.5rem;
            width: 24.1rem;
            border-radius: 8px;

            @include themify($themes) {
              background-color: themed('main');
            }

            border: none;
            cursor: pointer;
            color: $white;
            font-size: 1.4rem;
            font-weight: bold;

            &:hover {
              background-color: $blue-15;

              @include themify($themes) {
                color: themed('main');
              }
            }
          }
        }
      }
    }

    .filterOptions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 2rem;
      padding: 1rem 0;
      z-index: 20;
      position: relative;

      @media screen and (max-width: 768px) {
        justify-content: space-evenly;
      }

      .results {
        text-align: center;
        font-size: 1.4rem;
        font-weight: 400;

        @include themify($themes) {
          color: themed('main');
        }
      }

      .drop {
        height: 3rem;

        @include themify($themes) {
          background: themed('mainHover');
        }

        @include themify($themes) {
          border: 1px solid themed('mainHover');
        }

        border-radius: 0.5rem;

        @include themify($themes) {
          color: themed('main');
        }

        font-weight: 200;
        width: 17.5rem;
      }

      .sortIcons {
        display: flex;
        padding: 0.5rem;
        gap: 1rem;

        .icon {
          width: 1.8rem;
          height: auto;
          display: flex;
          padding: 0.5rem;

          @include themify($themes) {
            background: themed('mainHover');
          }

          @include themify($themes) {
            fill: themed('main');
          }

          border-radius: 0.5rem;
          cursor: pointer;
        }
      }

      .iconLine {
        fill: $blue-5;
      }
    }

    .hr {
      margin-top: 1.3rem;
      box-sizing: border-box;
      height: 0.1rem;
      border: 0.1rem solid #e7e7e7;
    }
  }

  .tyre-tcrmi-container .tyres-input-search {
    margin-top: 0;
  }

  .tyre-sizes-container {
    padding-bottom: 5rem;

    .tyres-input-search {
      display: flex;
      justify-content: flex-end;

    }
  }

  .return-button {
    @include themify($themes) {
      color: themed('main');
    }

    font-size: 2.5rem;
    cursor: pointer;
  }

  .spinner.small {
    position: inherit;
    margin: 100px 0;
  }

  .wrapper-tyres {
    .catalog-view-mode {
      display: grid;
      width: 100%;
      grid-gap: 2rem;
      grid-template-columns: repeat(auto-fit, 35rem);
      flex: 0.9 1;
      margin-bottom: 7rem;
      // @media screen and (max-width: 480px) {
      //   grid-template-columns: repeat(auto-fit, 100%);
      // }
    }

    .catalog-view-mode.LIST {
      display: flex;
      flex-direction: column;
    }
  }

  .no-parts-data {
    margin-top: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & h1 {
      @include themify($themes) {
        color: themed('main');
      }

      align-self: center;
      justify-self: center;
      position: relative;
      font-size: 3rem;
    }
  }
}

.catalog-vehicle-tyre-page .tyre-catalog {
  width: 100%;

  .tyre-header {
    justify-content: space-between;
  }
}

/* Responsive CSS Styles */

.app-layout-desktop {}

.app-layout-tablet {
  .catalog-vehicle-tyre-page {
    .body {
      .tyre-header {
        .title {
          width: 40%;
        }

        .filter {
          width: 40%;
        }
      }
    }

    .wrapper-tyres {
      justify-content: center;
    }
  }
}

.app-layout-tablet,
.app-layout-mobile {
  .catalog-vehicle-tyre-page .tyre-catalog {
    .tyre-header {
      justify-content: space-around;
    }
  }

  .catalog-vehicle-tyre-page {
    .tyre-sizes-container {
      .tyre-tcrmi-container {
        .search-tyres-container {
          margin-top: 0;
        }
      }

      .tyres-input-search {
        padding: 2rem 0;

        &>div {
          width: 100%;
        }
      }
    }
  }
}

.app-layout-mobile {
  .catalog-vehicle-tyre-page {
    .body {
      .tyre-header {
        flex-direction: row;
        align-items: center;
        gap: 1rem;

        @media only screen and (max-width: 768px) {
          flex-direction: column;
          background-color: #f2f5f8;
        }

        .title {
          display: flex;
          width: 20%;
          justify-content: center;

          @media only screen and (max-width: 990px) {
            width: 100%;
          }
        }

        .filter {
          justify-content: center;
          width: 80%;
          align-items: center;

          .title-filter {
            display: none;
          }
        }
      }
    }

    .wrapper-tyres {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;

      .item-container-shop-tyre {
        margin-top: 0;
      }
    }

    .no-parts-data {
      svg {
        width: 50%;
      }
    }
  }
}