@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.sidenav {
  @include themify($themes) {
    background-color: themed('secondary');
  }

  height: 100vh;
  width: 0;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;

  overflow-x: hidden;
  transition: 0.5s;

  .header-sidenav {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 5.8rem;
    z-index: 10;

    .close-btn {
      @include themify($themes) {
        color: themed('main');
      }

      font-size: 1.6rem;
      font-weight: 900;
      width: 3.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      img {
        max-width: 10rem;
        max-height: 4rem;

        @media only screen and (max-width: 1340px) and (min-width: 990px) {
          display: inline;
        }
      }
    }
  }

  .tabs-nav {
    display: flex;

    flex-direction: column;
    justify-content: center;
    margin: 2rem 2rem 2rem 9.2rem;
    height: 50vh;

    @media only screen and (max-width: 990px) and (min-width: 768px) {
      margin: 2rem 2rem 2rem 19.2rem;
    }

    .disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    .tab {
      display: flex;

      flex-direction: row;
      gap: 1.7rem;

      @include themify($themes) {
        color: themed('main');
      }

      height: 52px;
      margin-bottom: 2rem;

      .tab-icon {
        @include themify($themes) {
          color: themed('main');
        }

        width: 20px;
        height: 20px;
        font-size: 2rem;

      }

      .text {
        white-space: nowrap;
        font-size: 1.8rem;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 1.8rem;
        color: $black;
      }

      :hover {
        cursor: pointer;
      }

      .phone {
        @include themify($themes) {
          color: themed('main');

          a {
            color: themed('main');
          }
        }

      }
    }

    .tehnical {
      height: 135px;
    }
  }

  .setting {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;

    .e-book {
      display: flex;
      justify-content: space-evenly;
      flex-direction: row;
      align-items: center;
      width: 12.3rem;
      height: 4.2rem;
      border-radius: 20.5px;

      @include themify($themes) {
        color: themed('secondary');
      }

      font-weight: 600;
      font-size: 11px;

      @include themify($themes) {
        background-color: themed('main');
      }

      border: none;

      .e-book-button {
        width: 6rem;
      }

      .e-book-icon {
        font-size: 16px;
      }
    }

    .mini-dropdowns {
      display: flex;
      flex-direction: row;
      width: 12rem;
      justify-content: space-between;

      .mini-dropdown {
        @include themify($themes) {
          background-color: themed('bgMiniDropdown');
        }
      }

      img {
        width: 2.1rem;
        height: 1.3rem;
      }
    }
  }

  .footer {
    height: 5.5rem;
    bottom: 0px;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include themify($themes) {
      background-color: themed('secondary');
    }
  }
}

.show-sidenav {
  width: 100%;
}

.hidden-sidenav {
  width: 0%;
}