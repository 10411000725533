@import 'src/assets/sass/variables';

.cart-view-container-espace {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 132rem;
  margin: 0rem auto;

  .cart-view-body-espace {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    min-height: 40rem;

    .cart-view {
      width: 100%;

      &.loading {
        min-height: 75vh;
      }

      @media screen and (max-width: 768px) {
        width: 90%;
      }



      .cart {
        display: flex;
        justify-content: space-between;
        color: #355099;
        font-size: 2.4rem;
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #e7e7e7;
        margin-bottom: 2rem;


        .cart-title {

          padding: 2rem;
        }


        .checkout-button {

          padding: 2rem;

          button {
            border-radius: 0.8rem;
          }
        }
      }

    }

    & .empty_cart {
      padding: 0rem 0;
      position: absolute;
      top: 10%;
      left: 50%;
      transform: translate(-50%, 40%);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      & .empty_cart_image {
        & img {
          margin-left: -10%;
          width: 100%;
          height: 100%;
          max-width: 24rem;
          max-height: 21rem;
        }
      }

      & .empty_cart_title {
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 1rem;
        color: $blue-1;

        @media screen and (max-width: 768px) {
          font-size: 1.5rem;
          white-space: nowrap;
        }
      }
    }
  }



}