@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

.container-sidebar-retailer {
  animation: 0.1s ease-out 0s 1 slideInFromLeft;
  position: absolute;
  z-index: 11;
  left: 0;
  top: 0;
  display: flex;
  height: 100vh;
  background-color: #e3eaf6;

  @media screen and (max-width: 768px) {
    width: 100%;
    position: fixed;
  }

  .close-btn {
    position: absolute;

    @include themify($themes) {
      color: themed('main');
    }

    right: 1.888rem;
    top: 1.638rem;
    font-size: 2.238rem;
    cursor: pointer;
  }

  .tabs {
    display: flex;
    width: 50px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    justify-content: center;
    background-color: white;

    .tab {
      flex: 1;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: #28549c;
      width: 50px;

      cursor: pointer;

      .tab-name {
        font-size: 14px;
        transform: translate(-16px, -25px) rotate(90deg);
      }

      .icon {
        margin-left: 15px;
        width: 24px;
        height: 24px;
        color: #28549c;
        justify-content: center;
        cursor: pointer;
      }
    }

    .tab {

      &:hover,
      &.active {
        background-color: #28549c;
        color: white;
        cursor: pointer;

        .icon {
          width: 26px;
          height: 26px;
          color: white;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
  }

  .content-list {
    height: 100vh;
    overflow: auto;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .content {
    width: 260px;
    padding: 15px;
    overflow: auto;

    @media screen and (max-width: 768px) {
      width: auto;
    }

    .heading {
      font-size: 14px;
      margin: 0;
      margin-top: 15px;
      padding-left: 45px;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    h1 {
      color: #28549c;
    }

    .odd {
      background-color: #e0e6ef;
      width: 250px;
    }

    .even {
      width: 250px;
    }

    h2 {
      color: #28549c;
      padding-left: 30px;
      margin: 0;
      margin-top: 25px;
    }

    .search {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .btn-catalog {
        color: #ffffff;

        @include themify($themes) {
          background-color: themed('main');
        }

        height: 3.4rem;
        border: none;
        cursor: pointer;
        padding: 0.8rem;
        border-radius: 0.8rem;
        font-size: 1.5rem;
        width: 100%;
      }

      .input {
        position: relative;
        position: block;
        padding: 10px;
        outline: none;
        border: none;
        border-radius: 10px;
      }

      .icon {
        position: absolute;
        right: 65px;
        width: 20px;
        height: 20px;
        color: #28549c;
      }

      .question {
        width: 20px;
        height: 20px;
        color: #28549c;
        padding-left: 10px;
      }
    }

    .subtitle {
      font-size: 14px;
    }

    .listItem {
      gap: 15px;
      font-weight: 700;
      padding: 0 10px;
      line-height: 23px;
      font-size: 17px;
      cursor: pointer;

      img {
        padding: 6px;
        min-width: 34px;
        height: auto;
      }
    }

    .listItem:focus {
      border-radius: 0.5rem;
      background-color:  $white-5;
    }
  }
}