@import 'assets/sass/variables';
@import 'src/assets/sass/themes';
@import 'assets/sass/_mixins.scss';

.spinner {

  @include themify($themes) {
    background: rgba(themed('bgSpinner'), 0.75);
  }

  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;

  &.small {
    background: none;

    z-index: 9;
  }

  &.extra-small {
    background: none;
    position: relative;
    z-index: 9;

    &.right {
      float: right;
      position: absolute;
      left: 90%;
      width: 5%;
    }

    svg {
      width: 1.8em;
    }

    &.rem-1 {
      svg {
        width: 1.1rem;
      }
    }
  }

  &.clear {
    clear: both;
  }

  &.content-spinner {
    position: absolute;
    height: 100% !important;
  }

  svg {
    width: 3.75em;
    transform-origin: center;
    animation: rotate 2s linear infinite;
  }

  circle {
    fill: none;

    @include themify($themes) {
      stroke: themed('main');
    }

    stroke-width: 2;
    stroke-dasharray: 1,
    200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 90, 200;
      stroke-dashoffset: -35px;
    }

    100% {
      stroke-dashoffset: -125px;
    }
  }
}