@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.tyres-modal-overlay {
  @include themify($themes) {
    background-color: themed('main');
  }
}

.tyres-modal {
  &-overlay {
    position: fixed;
    inset: 0px;
    background-color: rgba($color: black, $alpha: 0.5);
    z-index: 999;
  }
  .tyres-modal-header {
    margin: 45px 0;
    & .tyres-modal-title {
      display: flex;
      justify-content: center;
      @include themify($themes) {
        color: themed('main');
      }
      font-weight: 400;
      font-size: 25px;
      line-height: 34px;
    }
    .tyres-modal-close {
      width: 45px;
      height: 45px;
      padding: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      border: none;
      background: none;

      position: absolute;
      top: 8px;
      right: 8px;

      font-size: 2rem;
      font-weight: bold;
      @include themify($themes) {
        color: themed('main');
      }
      cursor: pointer;
    }
  }

  & .tyres-right-container {
    @include themify($themes) {
      background-color: themed('main');
    }
    position: relative;
    padding: 0;
    height: 279px;
    width: 651px;
    margin: 47px;
    & .tyres-help-image {
      height: 280px;
    }

    .arrow-right:after {
      content: '';
      display: inline-block !important;
      width: 0;
      height: 0;
      border-left: 7px solid white;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      vertical-align: middle;
    }

    .arrow-right:before {
      width: 400px;
      height: 2px;
      background: white;
      content: '';
      display: inline-block;
      vertical-align: middle;
    }

    & .tyres-curved-stats {
      top: 71px;
      left: 433px;
      right: -92px;
      position: absolute;
      transform: rotate(-47deg);

      path {
        fill: transparent;
      }

      text {
        fill: #b0b0b0;
        font-size: 40px;
      }
    }

    .tyre-stat-description {
      position: absolute;
      padding: 0 10px;
      @include themify($themes) {
        background-color: themed('main');
      }
      color: white;
      font-size: 14px;
    }

    & .tyres-stats-arrow-1 {
      position: absolute;
      top: 37px;
      right: 62px;
      left: 0;
      .arrow-right:before {
        width: 580px;
      }
    }

    & .tyres-stats-arrow-2 {
      position: absolute;
      top: 69px;
      left: 0;
      .arrow-right:before {
        width: 530px;
      }
    }

    & .tyres-stats-arrow-3 {
      position: absolute;
      top: 101px;
      left: 0;
      .arrow-right:before {
        width: 500px;
      }
    }

    & .tyres-stats-arrow-4 {
      position: absolute;
      top: 150px;
      left: 0;
      .arrow-right:before {
        width: 470px;
      }
    }

    & .tyres-stats-arrow-5 {
      position: absolute;
      top: 240px;
      left: 0;
      .arrow-right:before {
        width: 460px;
      }
    }
  }
  h5,
  h3 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  &.mobile {
    & .tyres-right-container {
      height: 279px;
      width: 340px;
      margin: 20px auto;
      & .tyres-help-image {
        height: 279px;
        position: absolute;
        right: 0;
      }
      .tyres-curved-stats {
        left: 130px;
      }
    }
    & .tyres-stats-arrow-1 {
      position: absolute;
      top: 37px;
      right: 62px;
      left: 0;
      .arrow-right:before {
        width: 270px;
      }
    }

    & .tyres-stats-arrow-2 {
      position: absolute;
      top: 69px;
      left: 0;
      .arrow-right:before {
        width: 230px;
      }
    }

    & .tyres-stats-arrow-3 {
      position: absolute;
      top: 101px;
      left: 0;
      .arrow-right:before {
        width: 190px;
      }
    }

    & .tyres-stats-arrow-4 {
      position: absolute;
      top: 150px;
      left: 0;
      .arrow-right:before {
        width: 170px;
      }
    }

    & .tyres-stats-arrow-5 {
      position: absolute;
      top: 240px;
      left: 0;
      .arrow-right:before {
        width: 150px;
      }
    }
  }
}
