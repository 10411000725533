@import '../../assets/sass/variables';
@import 'src/assets/sass/themes';

.promotion-description-info {
  width: 70rem;
  margin: 1rem 2rem;

  @media only screen and (max-width: 768px) {
    width: auto;
    height: calc(100% - 6rem);
    margin: 1rem 2rem;
  }

  & .promotion-description-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    box-sizing: border-box;
    border-bottom: 1px solid $blue-4;
    & .promotion-description-title {
      @include themify($themes) {
        color: themed('main');
      }
      font-size: 1.8rem;
      font-weight: bold;
    }

    .close-modal {
      top: 1.6rem;
      font-size: 1.8rem;
      @include themify($themes) {
        color: themed('main');
      }

      @media only screen and (max-width: 768px) {
        top: 2rem;
        right: 2.6rem;
      }

      @media screen and (max-width: 990px) and (min-width: 768px) {
        top: 3.6rem;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .description {
    font-size: 1.4rem;
    max-height: 80vh;
    overflow-y: auto;

    @media only screen and (max-width: 768px) {
      padding: 1rem;
      box-sizing: border-box;

      overflow-x: hidden;
    }

    .description-lable {
      padding-bottom: 0.5rem;

      span {
        font-weight: 600;
        font-size: 1.5rem;
      }
    }
  }
}
