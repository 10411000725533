@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

/* Mobile CSS Styles */

.app-layout-mobile {
  .error-codes-layout {
    .content.container-fluid-medium {
      max-width: 100%;

      .error-codes-header {
        margin: 0;

        form {
          margin: 0;
        }
      }
    }

    @media screen and (max-width: 768px) {
      .error-codes-header {
        gap: 0;
      }
    }

    @media screen and (max-width: 620px) {
      .error-codes-header {
        .error-codes-search {
          flex-wrap: wrap;

          & > div {
            width: 100%;
            margin: 0;
          }

          .text {
            margin: 0.5rem auto;
            bottom: 0;
            text-align: center;
          }
        }

        .error-codes-login-vts {
          max-width: 100%;
          width: 100%;

          .default-button {
            width: 100%;
          }
        }
      }
    }
  }
}
