@import "../../assets/sass/variables";
@import "src/assets/sass/themes";

.part-item-oem-list {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;

  @include themify($themes) {
    background: themed("secondary");
  }

  border-radius: 0.5rem;
  box-sizing: border-box;
  max-height: 40rem;
  gap: 2rem;
  padding: 1rem 1rem;
  position: relative;
  justify-content: space-between;

  &.best-price {
    border: 2px solid orange;
  }

  & .best-price-label {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-left: 0px solid transparent;
    border-right: 50px solid transparent;
    border-top: 50px solid orange;
    text-align: center;
    z-index: 10;

    & .best-price-text {
      position: absolute;
      top: -44px;
      left: 3px;
      rotate: -45deg;
      font-weight: bold;
      color: white;
      line-height: 10px;
      text-transform: uppercase;
    }
  }

  .part-list-item-favorite {
    position: absolute;
    right: 0;
    top: 1rem;

    .favorite-button {
      position: absolute;
      right: 0;

      .iconButton {
        background: none;
      }

      svg {
        cursor: pointer;
      }

      .spinner-container {
        width: 2rem !important;
        height: 2rem !important;
        position: relative;

        .spinner {
          position: absolute;
          right: 1rem;
          top: 0rem;
          left: unset;
          background-color: inherit;
        }
      }

      button {
        svg {
          position: absolute;
          width: 2rem !important;
          height: 2rem !important;
          right: 1rem;
          top: 0rem;
          left: unset;

          @include themify($themes) {
            fill: themed("main");
          }
        }
      }
    }
  }

  .oem-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    min-width: 25rem;

    .img-zoom-container {
      position: relative;
      display: flex;
      gap: 1rem;
      z-index: 2;

      & .img-zoom {
        position: absolute !important;
        right: -20rem;
        background-color: #ffffff;
        border: 1px solid #dad8d8;
        // img {
        //   width: 100% !important;
        // }
      }
    }

    .img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 10rem;
      height: 10rem;
      border: 0.1rem solid $gray;

      img {
        max-width: 10rem;
        max-height: 10rem;
        margin: auto;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      margin-left: 1rem;

      @include themify($themes) {
        color: themed("main");
      }

      .title {
        text-align: left;
        margin-bottom: 0.3rem;
        font-size: 1.4rem;
        font-weight: 600;

        @include themify($themes) {
          color: themed("main");
        }

        text-decoration: none;
        width: fit-content;

        &:hover {
          opacity: 80%;
          cursor: pointer;
        }
      }

      .reference {
        text-align: left;
        margin-bottom: 0.3rem;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.5;
      }

      .description-short {
        text-align: left;
        margin-bottom: 0.5rem;
        font-size: 1.1rem;
        font-weight: 600;

        @include themify($themes) {
          color: themed("main");
        }

        .oe-replacements-btn {
          cursor: pointer;

          &:hover {
            opacity: 80%;
          }
        }
      }

      .description_attributes {
        text-align: left;
        margin-bottom: 0.5rem;
        font-size: 1.1rem;

        @include themify($themes) {
          color: themed("main");
        }

        line-height: 1.3;
        display: -webkit-box;
        -webkit-line-clamp: 15;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        border-bottom: 1px solid $gray;
        padding-bottom: 0.4rem;
      }

      .description {
        text-align: left;
        margin-bottom: 0.5rem;
        font-size: 1.1rem;

        @include themify($themes) {
          color: themed("main");
        }

        line-height: 1.3;

        & .commercial-ref-value {
          color: $red;
          font-weight: bold;
        }
      }
    }
  }

  .oem-actions-side {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;

    .oem-actions {
      display: flex;
      flex-direction: column;
      border-left: 0.1rem solid $gray;
      border-right: 0.1rem solid $gray;
      padding: 0 1rem;
      box-sizing: border-box;
      align-items: center;

      .cirrus-prices {
        display: flex;
        gap: 0.8rem;
        align-items: center;
        justify-content: center;
        margin-top: 0.8rem;

        .price-separator {
          border-left: 1px solid $gray;
          height: 2.5rem;
        }

        .cirrus-price {
          display: flex;
          gap: 0.2rem;
          color: $blue-1;
          align-items: center;

          .cirrus-price-label {
            font-size: 1rem;
            font-weight: 800;
          }

          .cirrus-price-value {
            font-size: 0.9rem;
            font-weight: 600;
          }
        }
      }

      .cart-price {
        @include themify($themes) {
          color: themed("main");
        }
      }

      .quantity {
        flex-direction: column;
        margin: 0.3rem;
        display: flex;
        gap: 1rem;
      }

      .cart-button {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
      }

      .cart {
        text-align: center;
        margin-bottom: 0.2rem;
        font-size: 1.8rem;
        font-weight: 600;
        display: flex;
        align-items: flex-end;
        gap: 1rem;

        .cirrus-contact-us {
          white-space: nowrap;

          @include themify($themes) {
            color: themed("main");
          }

          font-size: 1.2rem;
          text-decoration: underline;
          cursor: pointer;
          min-width: 6.2rem;
          height: 1.6rem;
          margin-top: 0.2rem;

          & .extra-small {
            margin: auto;
          }

          &:hover {
            opacity: 80%;
          }
        }

        .part-cart-button {
          display: flex;

          .button {
            padding: 0.3rem;
          }
        }
      }

      .oem-price_per_unit {
        @include themify($themes) {
          color: themed("main");
        }

        font-size: 1.2rem;
      }
    }

    .barcode {
      width: 10.1rem;
      object-fit: contain;
      display: block;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 3rem;

      & img {
        max-width: 10.1rem;
        height: 5.2rem;
      }

      & .barcode-number {
        text-align: center;
      }
    }
  }
}
