@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.search-card-refs {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;

  @include themify($themes) {
    background-color: themed('secondary');
  }

  border-radius: 1rem;

  @media screen and (min-width: 1366px) {
    border-radius: 0;
  }

  @media screen and (min-width: 991px) and (max-width: 1366px) {
    box-shadow: 0.2rem 0.2rem 1rem #0000000a;
  }

  padding: 1.8rem;

  & .card-title {
    margin-top: 2.1rem;
    font-size: 1.7rem;

    @include themify($themes) {
      color: themed('main');
    }

    font-weight: 600;
    margin-bottom: 3.5rem;
    letter-spacing: 0.3px;

    @include themify($themes) {
      text-decoration: underline themed('main');
    }
  }

  & .ref-search-inputs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;

    & .car-parts-reference {
      margin-bottom: 1rem;
      font-weight: 800;
      font-size: 1.4rem;
      position: relative;
      width: 100%;

      & .reference-label {
        @include themify($themes) {
          color: themed('main');
        }

        word-spacing: 3px;
        padding: 0rem 1rem;

        & .reference-icon {
          @include themify($themes) {
            color: themed('main');
          }
        }
      }
    }

    & .reference-container {
      display: flex;
      align-items: flex-start;
      gap: 3rem;
      margin-bottom: 1rem;
      width: 100%;

      & .oil-ref-search,
      & .consumable-ref-search,
      & .battery-ref-search,
      & .tyre-ref-search {
        position: relative;
        width: 100%;
      }

      & .reference-label {
        @include themify($themes) {
          color: themed('main');
        }

        word-spacing: 3px;
        padding: 0rem 1rem;
        font-weight: 800;
        font-size: 1.3rem;
        white-space: nowrap;

        & .reference-icon {
          @include themify($themes) {
            color: themed('main');
          }
        }
      }

      p {
        @include themify($themes) {
          color: themed('main');
        }

        font-size: 1.1rem;
        margin: 0.5rem;

        & .tyre-help-icon {
          @include themify($themes) {
            color: themed('main');
          }

          font-size: 1.5rem;
          opacity: 0.4;
        }
      }

      & .battery-ref-search {
        position: relative;

        & .reference-label {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          white-space: nowrap;

          & svg {
            path {
              @include themify($themes) {
                fill: themed('main');
              }
            }
          }
        }
      }
    }
  }
}