@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.error-message {
  display: none;
}

.error-input {
  color: $red !important;
  font-weight: 700;

  p {
    margin: 0;
  }

  .error-message {
    display: block;
    margin-top: 0.5rem;
    font-size: 1rem;
  }

  & select,
  input {
    border: 1px solid $red !important;
  }
}

.form-container-account {
  width: 100%;
  max-width: 58.6rem;
  margin: 0 auto;
  @include themify($themes) {
    color: themed('main');
  }

  .returnButton {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.6rem;
    @include themify($themes) {
      color: themed('main');
    }
    cursor: pointer;

    .icon {
      font-size: 1.6rem;
      cursor: pointer;
      @include themify($themes) {
        color: themed('main');
      }
    }
  }

  .title {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 2.55rem;
    line-height: 3.4rem;
    margin: 1.9rem auto 2.95rem;

    @media screen and (max-width: 768px) {
      font-size: 1.8rem;
      line-height: 2rem;
      margin: 1.9rem auto 1.5rem;
    }
  }

  .form {
    width: 100%;
    padding-top: 0.75rem;
    padding-bottom: 2.4rem;
    border-top: 1px solid $blue-5;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 768px) {
      padding: 0.75rem 2rem;
      box-sizing: border-box;
    }

    h4 {
      font-size: 11px;
      line-height: 2.7rem;
      text-transform: uppercase;
      margin: 0;
      opacity: 0.47;
    }

    input,
    select {
      font-size: 1.5rem;
      font-family: 'Open Sans', 'sans-serif';
      line-height: 2rem;
      @include themify($themes) {
        color: themed('main');
      }
    }

    label {
      @include themify($themes) {
        color: themed('main');
      }
      font-size: 1.4rem;
      line-height: 1.9rem;
      font-weight: 700;
      width: 100%;
      margin-bottom: 0.5rem;
      display: inline-block;
      padding-left: 0.4rem;
    }

    .line {
      display: flex;
      min-width: 23rem;
      padding: 0;
      justify-content: flex-start;
      width: 100%;

      label {
        display: inline;
      }

      .radio-group-languages {
        display: grid;
        grid-template-columns: repeat(auto-fit, 10rem);
        width: 100%;
        justify-content: center;

        @media screen and (max-width: 768px) {
          grid-template-columns: repeat(auto-fit, 16rem);
        }
      }

      .radio {
        padding: 0.5rem;
        display: inline-block;
      }

      input[type='radio'] {
        cursor: pointer;
        appearance: none;
        background-color: $white;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 2px solid $blue-1;
        display: inline-grid;
        place-content: center;
      }

      input[type='radio']::before {
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 50%;
        transform: scale(0);
        transform-origin: bottom left;
        background-color: $white;
        clip-path: polygon(13% 50%, 34% 66%, 81% 2%, 100% 18%, 39% 100%, 0 71%);
      }

      input[type='radio']:checked::before {
        transform: scale(1);
      }

      input[type='radio']:checked {
        @include themify($themes) {
          background-color: themed('main');
        }
        @include themify($themes) {
          border-color: themed('main');
        }
      }
    }

    .row {
      display: flex;
      width: 100%;
      gap: 3.2rem;
      justify-content: center;

      @media screen and (max-width: 768px) {
        gap: 0;
        flex-direction: column;
      }

      > .container-automotor-account {
        margin: 1rem 0;
        width: 100%;

        &.email {
          opacity: 30%;
        }

        .error-message {
          color: $red !important;
          display: block;
          margin-top: 0.5rem;
          font-size: 1rem;
          text-align: right;
        }
      }

      .form-group {
        display: flex;
        flex: 1 1;
        gap: 1rem;
      }

      .text-input-container {
        height: 4.2rem;
        padding: 0;
      }

      .text-input,
      .field {
        border: none;
        @include themify($themes) {
          background-color: themed('secondary-3');
        }
        padding: 0 1.1rem;
        outline: none;
        border-radius: 10px;
        height: 4.2rem;
      }

      .text-input-container {
        width: 100%;
        border: none;
        @include themify($themes) {
          background-color: themed('secondary-3');
        }
        outline: none;
        border-radius: 10px;
      }

      .input-long {
        width: 33.6rem;

        input {
          width: 100%;
        }
      }

      .input-short {
        width: 7.2rem;

        input {
          width: 100%;
        }
      }

      .select {
        display: flex;
        flex-direction: column;
        width: 19.6rem;
        padding: 0;

        select {
          width: 19.6rem;
          height: 4.2rem;
        }
      }
    }

    .icon {
      font-size: 16px;
      top: -2px;
      position: relative;
    }

    &:last-of-type {
      border: none;
    }
  }

  button {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    @include themify($themes) {
      background-color: themed('main');
    }
    width: 29.6rem;
    height: 3.4rem;
    margin: 1rem auto;
    color: $white;
    border-radius: 0.8rem;
    font-size: 1.2rem;
    line-height: 1.634rem;
    font-weight: 700;
    border: none;
    cursor: pointer;
    justify-content: center;
  }
}
