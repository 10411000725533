@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.parts-filter-tyre {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $background-grey;
  gap: 1rem;
  padding-bottom: 2.2rem;
  z-index: 30;
  bottom: -100%;
  transition: bottom 0.3s;

  @media screen and (max-width: 768px) {
    height: calc(100% - 70px);
    padding-bottom: 0rem;
  }

  &.active {
    bottom: 0;
  }

  & .header-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 1.5rem 0 1.5rem;

    & .buttons-container {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 0.5rem;
    }

    & .icon {
      font-size: 2rem;
      font-weight: bold;

      @include themify($themes) {
        color: themed('main');
      }

      cursor: pointer;
    }
  }

  & .title {
    display: flex;
    justify-content: center;
    font-size: 2.3rem;
    font-weight: 300;
    gap: 0.5rem;
  }

  & .filters {
    display: block;
    gap: 1.5rem;
    padding: 0 5rem;
    margin-bottom: 4rem;

    @media screen and (max-width: 768px) {
      overflow: auto;
    }

    svg {
      cursor: pointer;
    }

    & .dropdowns-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1.1rem;
      align-items: flex-end;
      width: 100%;

      @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: initial;
      }

      &.single {
        grid-template-columns: 1fr;
      }

      &.disabled {
        opacity: 30%;
        pointer-events: none;
        user-select: none;
      }

      [class$='-control'] {
        @include themify($themes) {
          color: themed('main');
        }

        border: none;
        background-color: $white;
        box-shadow: none;

        &:hover {
          border: none;
        }
      }

      & .parts-filter-select-dropdown {
        @include themify($themes) {
          color: themed('main');
        }

        border-radius: 0.5rem;
        font-size: 1.4rem;
        font-weight: 400;

        &.disabled {
          opacity: 30%;
          pointer-events: none;
          user-select: none;
        }

        [class$='-control'] {
          @include themify($themes) {
            color: themed('main');
          }

          border: none;
          background-color: $white;
          box-shadow: none;

          &:hover {
            border: none;
          }
        }

        [class$='-placeholder'] {
          @include themify($themes) {
            color: themed('main');
          }

          font-size: 1.4rem;
          font-weight: 400;
        }

        [class$='-indicatorContainer'] {
          @include themify($themes) {
            color: themed('main');
          }
        }

        [class$='-singleValue'] {
          @include themify($themes) {
            color: themed('main');
          }
        }

        [class$='-indicatorSeparator'] {
          background: none;

          @include themify($themes) {
            color: themed('main');
          }
        }

        [class$='-indicator'] {
          @include themify($themes) {
            background: themed('main');
          }

          color: $white;
        }

        & .dropdown-label {
          font-weight: 800;
          font-size: 1.2rem;
          line-height: 20px;

          @include themify($themes) {
            color: themed('main');
          }
        }

        & .checkbox-select-container {
          display: flex;
          min-width: 23rem;
          padding: 0;
          justify-content: flex-start;
          width: 100%;
          margin-left: -10px;

          & .dropdown-label {
            font-size: 1.2rem;
            font-weight: 800;
            line-height: 30px;
            margin-top: 20px;

            @include themify($themes) {
              color: themed('main');
            }
          }

          .checkbox-item {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            font-size: 1.4rem;

            @include themify($themes) {
              color: themed('main');
            }

            align-items: center;
            gap: 0.5rem;
            margin-left: 1rem;
            margin-bottom: 1rem;

            & .checkbox {
              display: flex;
              align-items: center;
              gap: 0.5rem;
            }

            input[type='checkbox'] {
              appearance: none;
              background-color: $white;
              margin: 0;
              font: inherit;

              @include themify($themes) {
                color: themed('main');
              }

              width: 1.15em;
              height: 1.15em;
              border: 0.15em solid $blue-1;
              border-radius: 0.15em;
              transform: translateY(-0.075em);
              display: grid;
              place-content: center;
              outline: none;
            }

            input[type='checkbox']::before {
              content: '';
              width: 0.65em;
              height: 0.65em;
              transform: scale(0);
              transition: 120ms transform ease-in-out;
              box-shadow: inset 1em 1em $blue-1;
              transform-origin: bottom left;
              clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
            }

            input[type='checkbox']:checked::before {
              transform: scale(1);
            }
          }
        }
      }

      & .dropdown-label {
        font-weight: 800;
        font-size: 1.2rem;
        line-height: 20px;

        @include themify($themes) {
          color: themed('main');
        }
      }

      .checkbox-select {
        display: flex;
        min-width: 23rem;
        padding: 0;
        justify-content: flex-start;
        width: 100%;
        margin-left: -10px;

        .checkbox-item {
          position: relative;
          margin: 0.3rem;

          input[type='checkbox'] {
            z-index: 2;
            cursor: pointer;
            position: absolute;
            width: 1.5em;
            height: 1.5em;
            opacity: 0;

            +label {
              &:before {
                content: '';
                background: $blue-10;
                border-radius: 100%;
                border: 2px solid $blue;
                display: inline-block;
                position: relative;
                top: -0.2em;
                margin-right: 0.5em;
                vertical-align: top;
                text-align: center;
                width: 1.5em;
                height: 1.5em;
              }
            }

            &:checked {
              +label {
                &:before {
                  @include themify($themes) {
                    background-color: themed('main');
                  }
                }
              }
            }
          }

          label {
            font-size: 1.4rem;
            line-height: 1.9rem;
            font-weight: 700;
            margin-bottom: 0.5rem;
            padding-left: 0.4rem;

            @include themify($themes) {
              color: themed('main');
            }
          }

          .check-icon {
            position: absolute;
            left: 10px;
            top: 3px;
            color: $blue-10;
            font-size: 14px;
          }
        }
      }
    }

    .container-criteria {
      .dropdown-label {
        font-weight: bold;
        font-size: 1.4rem;

        @include themify($themes) {
          color: themed('main');
        }

        margin: 1rem 0;
        border-bottom: 0.1rem solid $blue-5;
        padding-bottom: 0.5rem;
        text-align: center;
      }

      .dropdowns-container-criteria {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1.1rem;
        align-items: flex-end;
        width: 100%;

        @media screen and (max-width: 768px) {
          display: flex;
          flex-direction: column;
          align-items: initial;
        }

        &.criteria-length-1,
        &.criteria-length-2 {
          grid-template-columns: 1fr 1fr;
        }

        & .dropdown-label {
          font-weight: 800;
          font-size: 1.2rem;
          line-height: 30px;
          margin-top: 20px;
        }

        & .parts-filter-select-dropdown {
          @include themify($themes) {
            color: themed('main');
          }

          border-radius: 0.5rem;
          font-size: 1.4rem;
          font-weight: 400;

          &.disabled {
            opacity: 30%;
            pointer-events: none;
            user-select: none;
          }

          [class$='-control'] {
            @include themify($themes) {
              color: themed('main');
            }

            border: none;
            background-color: $white;
            box-shadow: none;

            &:hover {
              border: none;
            }
          }

          [class$='-placeholder'] {
            @include themify($themes) {
              color: themed('main');
            }

            font-size: 1.4rem;
            font-weight: 400;
          }

          [class$='-indicatorContainer'] {
            @include themify($themes) {
              color: themed('main');
            }
          }

          [class$='-singleValue'] {
            @include themify($themes) {
              color: themed('main');
            }
          }

          [class$='-indicatorSeparator'] {
            background: none;

            @include themify($themes) {
              color: themed('main');
            }
          }

          [class$='-indicator'] {
            @include themify($themes) {
              background: themed('main');
            }

            color: $white;
          }

          & .dropdown-label {
            font-weight: 800;
            font-size: 1.2rem;
            line-height: 20px;

            @include themify($themes) {
              color: themed('main');
            }
          }

          & .checkbox-select-container {
            display: flex;
            min-width: 23rem;
            padding: 0;
            justify-content: flex-start;
            width: 100%;
            margin-left: -10px;

            & .dropdown-label {
              font-size: 1.2rem;
              font-weight: 800;
              line-height: 30px;
              margin-top: 20px;

              @include themify($themes) {
                color: themed('main');
              }
            }

            .checkbox-item {
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              font-size: 1.4rem;

              @include themify($themes) {
                color: themed('main');
              }

              align-items: center;
              gap: 0.5rem;
              margin-left: 1rem;
              margin-bottom: 1rem;

              & .checkbox {
                display: flex;
                align-items: center;
                gap: 0.5rem;
              }

              input[type='checkbox'] {
                appearance: none;
                background-color: $white;
                margin: 0;
                font: inherit;

                @include themify($themes) {
                  color: themed('main');
                }

                width: 1.15em;
                height: 1.15em;
                border: 0.15em solid $blue-1;
                border-radius: 0.15em;
                transform: translateY(-0.075em);
                display: grid;
                place-content: center;
                outline: none;
              }

              input[type='checkbox']::before {
                content: '';
                width: 0.65em;
                height: 0.65em;
                transform: scale(0);
                transition: 120ms transform ease-in-out;
                box-shadow: inset 1em 1em $blue-1;
                transform-origin: bottom left;
                clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
              }

              input[type='checkbox']:checked::before {
                transform: scale(1);
              }
            }
          }
        }
      }
    }

    .dropdown-second-label {
      line-height: 20px;
      font-weight: 800;
      font-size: 1.2rem;
      line-height: 20px;

      @include themify($themes) {
        color: themed('main');
      }
    }
  }

  & .apply-btn {
    align-self: center;
    width: 24.3rem;

    @media screen and (max-width: 768px) {
      padding-bottom: 2rem;
    }
  }
}