@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.technical-documentation-repair-items {
  .vehicle-repair-container {
    display: flex;
    flex-direction: row;
    width: 100%;

    .repair-group {
      flex: 1 0 25%;
      margin-right: 40px;

      &:nth-child(3) {
        flex: 1 0 35%;
        margin-right: 0;

        .sidebar-details {
          max-width: none;
        }
      }

      .work-info-sidebar-details {
        @include themify($themes) {
          color: themed('main');
        }

        @include themify($themes) {
          background-color: themed('secondary-3');
        }

        width: 100%;
        margin-bottom: 5rem;

        .header {
          min-height: 5rem;
          display: flex;
          align-items: center;

          @include themify($themes) {
            background: themed('categoriesSidebar', 'oddItem');
          }

          padding: 1rem;

          .icon {
            font-size: 2rem;
            cursor: pointer;

            &:hover {
              opacity: 80%;
            }
          }

          .return-button {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 14px;
            cursor: pointer;
          }

          .title {
            padding: 0;
            font-size: 1.4rem;
            font-weight: 700;
            text-align: center;
            width: 100%;

            span {
              font-size: 2rem;
              display: block;
            }
          }
        }

        .content {
          padding: 1rem 2rem;
          box-sizing: border-box;

          .items {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            .item {
              display: flex;
              justify-content: space-between;
              line-height: 2.8rem;
              font-size: 1.68rem;
              font-weight: bold;
              padding: 0 1rem;

              .icons-container {
                display: flex;

                .info-icon {
                  margin-left: 2rem;
                }

                .cart-icon {
                  margin-left: 2rem;
                }

                svg {
                  cursor: pointer;

                  @include themify($themes) {
                    background-color: themed('bgMiniDropdown');
                  }

                  border-radius: 6rem;
                  padding: 0.8rem;
                  font-size: 1rem;
                  width: 1rem;
                  height: 1rem;
                  font-weight: bold;
                }
              }

              &.active {
                color: $white;

                @include themify($themes) {
                  background-color: themed('main');
                }

                padding: 0.5rem 1rem;
                border-radius: 10rem;
                box-sizing: border-box;
                width: fit-content;
                pointer-events: none;
              }

              &.no-pointer-events {
                pointer-events: all;
              }

              &:hover {
                opacity: 80%;
              }
            }
          }
        }
      }
    }
  }
}

.work-info-modal-content {
  margin: 4rem;

  @include themify($themes) {
    color: themed('main');
  }

  .title {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.5;

    @include themify($themes) {
      border-bottom: 1px solid themed('main');
    }

    margin-bottom: 4rem;
  }

  li {
    line-height: 2.5rem;
    font-size: 1.4rem;
    font-weight: bold;
  }

  .default-button {
    margin-top: 4rem;
  }
}

/* Responsive CSS Styles */

.app-layout-mobile {
  .technical-documentation-repair-items {
    .vehicle-repair-container {
      flex-direction: column;

      .repair-group {
        flex: 1 1 100%;
        margin: auto;
        width: 100%;

        .sidebar-details {
          max-width: inherit;
        }
      }
    }
  }
}