@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.ReactModal__Content {
  padding: 1rem 0.5rem;
  overflow: visible;
  @include themify($themes) {
    background: themed('secondary-2');
  }
}

.ReactModal__Content--after-open {
  @media only screen and (max-width: 768px) {
    border-radius: 0px;
  }

  @media screen and (max-width: 990px) and (min-width: 768px) {
    max-height: calc(100vh - 100px);
    width: 90%;
  }
}

.modal-dialog {
  padding: 2rem 1.5rem;
}

.modal-new-tariff {
  @include themify($themes) {
    color: themed('main');
  }

  .close {
    float: right;
    text-align: center;
  }

  .title {
    font-size: 2.5rem;
    line-height: 3.4rem;
    letter-spacing: normal;
    text-align: center;
    margin: 0.5rem auto;

    @media screen and (max-width: 990px) and (min-width: 768px) {
      margin: 10.5rem auto;
    }
  }

  .tariff-content {
    max-width: 26.8rem;
    width: 100%;
    margin: 0 auto;
    padding: 1rem 0;
  }

  .tariff-modal-container {
    .tariff-upload {
      height: 4rem;
      margin: 7rem auto 1rem;

      .file-error {
        color: $red;
      }

      .content {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $blue-12;
        background: $white;
        border-radius: 1rem;
        font-size: 1.4rem;
        line-height: 1rem;
        width: 100%;
        height: 100%;
        cursor: pointer;

        &.errors {
          border: 1px solid $red;
        }
      }
    }

    .tariff-files {
      padding: 0;

      .uploaded-message-file {
        padding: 1rem;
        margin: 0;
        background: none;
        height: auto;
        justify-content: center;

        .file-info {
          width: auto;
        }

        .remove-file {
          width: auto;
          padding: 0 1rem;
        }
      }
    }

    .tariff-clients {
      .error {
        @include themify($themes) {
          color: themed('main');
        }
        border-color: $red;
      }
    }

    .tariff-message {
      textarea {
        width: 100%;
        max-width: calc(100% - 1rem);
        min-height: 4rem;
        max-height: 8rem;
        overflow: auto;
        padding: 0.4rem;
        border-color: $blue-12;
        @include themify($themes) {
          color: themed('main');
        }
      }
    }
  }
}
