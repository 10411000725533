@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.cirrus-cart-availability-wrapper {
  .value {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    width: 100%;

    .item {
      width: 13px;
      height: 13px;
      border-radius: 50px;
      &.green {
        background-color: $green-2;
      }
      &.blue {
        @include themify($themes) {
          background-color: themed('main');
        }
      }
      &.orange {
        background-color: $orange;
      }
      background-color: $red-2;
    }
  }
}
