@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.mini-dropdown {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.4rem;
  height: 1.7rem;
  border-radius: 0.4rem;
  gap: 0.5rem;
  position: relative;

  @include themify($themes) {
    background-color: themed('bgMiniDropdown');
    color: themed('main');
  }

  @media screen and (max-width: 768px) {
    width: 5rem;
    height: 2.6rem;
  }

  .show {
    display: contents;
  }

  .hide {
    display: none;
  }

  .content-data {
    overflow-y: scroll;
    max-height: 25rem;
    position: absolute;
    border-radius: 0.4rem;
    top: 2rem;
    left: 50%;
    transform: translateX(-85%);
    z-index: 10;

    @include themify($themes) {
      background-color: themed('bgMiniDropdown');
    }

    @media screen and (max-width: 768px) {
      top: 3rem;
    }

    .item {
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      padding: 0 1rem;
      gap: 1rem;
      cursor: pointer;

      &:hover {
        @include themify($themes) {
          background-color: themed('table', 'hover');
        }
      }

      .icon {
        padding: 0.1rem;
      }

      .image {
        padding: 0.1rem;
      }

      .text {
        min-width: 10rem;
        font-size: 1.2rem;
      }
    }
  }

  .content-data-top {
    overflow-y: scroll;
    max-height: 25rem;

    @include themify($themes) {
      background-color: themed('bgCustomDropdown');
    }

    position: absolute;
    border-radius: 0.4rem;
    top: -25.1rem;
    left: -115%;
    transform: translateX(-50%);
    z-index: 10;

    .item {
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      padding: 0 1rem;
      gap: 1rem;
      cursor: pointer;

      &:hover {
        @include themify($themes) {
          background-color: themed('mainHover');
        }
      }

      .icon {
        padding: 0.1rem;
      }

      .image {
        padding: 0.1rem;
      }

      .text {
        min-width: 10rem;
        font-size: 1.2rem;
      }
    }
  }
}