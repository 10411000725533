@import "src/assets/sass/variables";
@import "src/assets/sass/themes";

.catalog-messages-wrapper {
  padding: 2rem;
  font-family: "Open sans", sans-serif;

  .messages-container {
    @include themify($themes) {
      background-color: themed("secondary");
    }

    max-width: 99.2rem;
    margin: 0 auto;
    padding: 1rem 0.5rem 1rem 5rem;
    position: relative;
    min-height: auto;

    @media screen and (max-width: 990px) {
      min-height: 100vh;
    }

    @media screen and (max-width: 768px) {
      padding: 1rem 1rem 2rem 1rem;
      min-height: initial;
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      height: 16.9rem;
      width: 100%;
      content: "";
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.0001) 0%, $white-gradient 100%);
      pointer-events: none;

      @media screen and (max-width: 768px) {
        height: auto;
        min-height: 4rem;
      }
    }

    @include themify($themes) {
      color: themed("main");
    }

    @media screen and (min-width: 990px) {
      ::-webkit-scrollbar {
        position: relative;
        margin-right: -50.5px;
      }
    }

    .content {
      .messages {
        z-index: 1;
        height: 30rem;
        position: relative;
        width: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 768px) {
          max-height: 30rem;
          min-height: 10rem;
          height: auto;
        }

        .messages-list {
          margin-top: auto;

          .loading-old-messages {
            min-height: 10rem;
            position: relative;
          }
        }

        #scrollableDiv {
          padding-right: 2rem;
          height: 50rem;
          position: relative;

          .extra-small {
            height: 3rem;
          }
        }

        .no-messages {
          font-size: 1.5rem;
          text-align: center;
        }
      }
    }
  }
}