@import '../../../../../../assets/sass/variables';
@import 'src/assets/sass/themes';
@import '../../../../../../assets/sass/_mixins';

.orders {
  padding: 5rem 0 0 0;
  margin: 0 auto;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  @include themify($themes) {
    color: themed('main');
  }
  display: grid;

  .title {
    font-family: 'Open Sans Bold', sans-serif;
    max-width: 76rem;
    margin: 0 auto;
    padding-bottom: 1.5rem;
    font-weight: bold;

    p {
      margin: 0;
      font-size: 2.5rem;
      letter-spacing: 0;
      line-height: 3.4rem;
    }
  }

  .table {
    margin: 0 auto;
  }

  .all-orders-button {
    max-width: 19rem;
    max-height: 3.4rem;
    margin: 0 auto;
    padding: 2.2rem 0 11rem 0;
  }
}
