@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.presentation-suppliers {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .suppliers-title {
    @include themify($themes) {
      color: themed('main');
    }
    font-weight: bold;
    font-size: 1.5rem;
  }

  & .suppliers-container {
    margin-top: 2rem;
    display: flex;
    gap: 8rem;
  }
}
