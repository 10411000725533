@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.round-cart-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  background-color: $red;
  color: $white;
  cursor: pointer;

  &:hover {
    opacity: 80%;
  }

  & .round-cart-spinner {
    svg {
      width: 1.8rem;

      circle {
        stroke: $white;
      }
    }
  }

  & .cart-icon {
    font-size: 1.6rem;
  }
}

.disable_add_cart {
  pointer-events: none;
  background-color: #f9b4b1;
}
