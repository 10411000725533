@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.modal-select-checkout {
  .close {
    font-size: 2.4rem;
    line-height: 1;
    @include themify($themes) {
      color: themed('main');
    }
    text-shadow: 0 1px 0 $blue;
    background: none;
    border: none;
    position: absolute;
    right: 0.3rem;
    top: 0.5rem;
    cursor: pointer;

    @media screen and (max-width: 990px) {
      right: 1.3rem;
      top: 2.5rem;
    }
  }

  .close:hover,
  .close:focus {
    @include themify($themes) {
      color: themed('main');
    }
    text-decoration: none;
    cursor: pointer;
    filter: alpha(opacity=50);
    opacity: 0.5;
  }

  .modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media screen and (max-width: 768px) {
      margin-top: 10%;
    }

    @media screen and (max-width: 990px) and (min-width: 768px) {
      padding: 20%;
    }

    .checkout-modal-title {
      @include themify($themes) {
        color: themed('main');
      }
      font-size: 2rem;
      margin-bottom: 2rem;
    }

    .modal-container {
      background-color: $white;
      width: 100%;
      border-radius: 1rem;

      .modal-body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        padding: 0rem 24.5rem;
        margin: 0rem;
        gap: 2rem;

        @media screen and (max-width: 990px) {
          padding: 0rem;
        }

        & .modal-success-checkout {
          text-align: center;

          &-icon {
            padding-top: 3rem;
            color: $green;
            font-size: 6rem;
          }

          &-text {
            @include themify($themes) {
              color: themed('main');
            }
            font-weight: bold;
            font-size: 1.4rem;
          }
        }

        & .modal-success-checkout-description {
          text-align: center;
          @include themify($themes) {
            color: themed('main');
          }
          font-size: 1.4rem;
        }

        & .modal-success-checkout-buttons {
          display: flex;
          gap: 1rem;
          width: 100%;
          padding-bottom: 5rem;
        }
      }
    }
  }
}
