@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.catalog-oils-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  min-height: 20rem;
  font-family: 'Open Sans', sans-serif;
  margin: 0 auto;
  padding-bottom: 5rem;

  .search-parts-title {
    font-size: 14px;

    @include themify($themes) {
      color: themed('main');
    }

    cursor: pointer;
    font-weight: bold;
  }

  .retailer-catalog-oil-container {
    display: flex;
    gap: 3rem;

    .catalog-oil-sidebar {
      width: fit-content;

      .parts-list {
        width: 310px;
        height: 55px;
        cursor: pointer;
        display: flex;
        align-items: center;

        &:nth-child(odd) {
          @include themify($themes) {
            background-color: themed('categoriesSidebar', 'oddItem');
          }
        }

        &.selected {
          @include themify($themes) {
            background-color: themed('categoriesSidebar', 'active');
          }

          color: $white;

          &>p {
            color: $white !important;
          }
        }

        @media screen and (max-width: 768px) {
          width: 100%;
        }

        p {
          width: 234px;

          @include themify($themes) {
            color: themed('categoriesSidebar', 'text');
          }

          font-family: 'Open Sans',
          sans-serif;
          font-size: 17px;
          font-weight: bold;
          letter-spacing: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        img {
          margin: 0 1rem;
        }
      }
    }
  }

  .topContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;

    .logo {
      display: flex;
      gap: 2rem;
      align-items: flex-start;

      img {
        max-height: 7rem;
        align-self: center;
      }

      .buttonHome {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: none;
        width: 3rem;
        height: 3rem;

        @include themify($themes) {
          background: themed('mainHover');
        }

        align-self: center;
      }

      .img {
        @include themify($themes) {
          fill: themed('main');
        }

        width: 1.8rem;
      }
    }

    .title {
      font-size: 2.4rem;

      @include themify($themes) {
        color: themed('main');
      }

      margin-left: 20rem;
    }

    .desc {
      font-size: 1.1rem;

      @include themify($themes) {
        color: themed('main');
      }
    }

    .filter {
      display: flex;
      align-items: center;
      gap: 2rem;

      .button {
        padding: 0.5rem;

        @include themify($themes) {
          background-color: themed('main');
        }

        color: $white;
        border: none;
        font-weight: 500;
        width: 18rem;
        height: 4rem;
        border-radius: 1rem;
        cursor: pointer;
      }
    }
  }

  .separator {
    display: flex;
    justify-content: center;
    height: 0.1rem;
    background: $gray;
    width: 100%;
  }

  .filterOptions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;
    padding: 1rem 0;

    @media screen and (max-width: 768px) {
      justify-content: space-evenly;
    }

    .results {
      text-align: center;
      font-size: 1.4rem;
      font-weight: 400;

      @include themify($themes) {
        color: themed('main');
      }
    }

    .drop {
      height: 3rem;

      @include themify($themes) {
        background: themed('mainHover');
      }

      @include themify($themes) {
        border: 1px solid themed('mainHover');
      }

      border-radius: 0.5rem;

      @include themify($themes) {
        color: themed('main');
      }

      font-weight: 200;
      width: 17.5rem;
    }

    .sortIcons {
      display: flex;
      padding: 0.5rem;
      gap: 1rem;

      .icon {
        width: 1.8rem;
        height: auto;
        display: flex;
        padding: 0.5rem;

        @include themify($themes) {
          background: themed('mainHover');
        }

        @include themify($themes) {
          fill: themed('main');
        }

        border-radius: 0.5rem;
        cursor: pointer;
      }
    }

    .iconLine {
      fill: $blue-5;
    }
  }

  .wrapperItems {
    position: relative;
    min-height: 20rem;
    width: 100%;

    .catalog-view-mode {
      display: grid;
      min-height: auto;
      width: 100%;
      grid-gap: 2rem;
      grid-template-columns: repeat(auto-fit, 35rem);
      position: relative;
      justify-content: start;
      margin: 0 auto;
      flex: 0.9;
    }

    .catalog-view-mode.LIST {
      display: flex;
      flex-direction: column;
    }

    .car-parts-spinner {
      background: none;
    }

    .arrowLeft,
    .arrowRight {
      position: fixed;
      width: 2rem;
      height: 2rem;
      top: 50%;
      left: 95%;
      fill: $blue-icon-color;
      cursor: pointer;
    }

    .arrowRight {
      right: -5rem;
    }

    .arrowLeft {
      left: 3.5%;
    }
  }

  .no-parts-data {
    margin-top: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    svg {
      @media screen and (max-width: 768px) {
        width: 50%;
      }
    }

    & h1 {
      @include themify($themes) {
        color: themed('main');
      }

      align-self: center;
      justify-self: center;
      position: relative;
      font-size: 3rem;
    }
  }

  .nodata-wrapperItems {
    min-height: 70%;
    width: 100%;
    position: relative;
    margin: 0 auto;
    flex: 0.9;

    .car-parts-spinner {
      background: none;
      position: relative;
      top: 20rem;
    }
  }
}

/* Responsive CSS Styles */

.app-layout-desktop {}

.app-layout-tablet {}

.app-layout-tablet,
.app-layout-mobile {
  .oils-wrapper {
    background: $white;
    width: 100%;
    margin-bottom: 0;

    &>div {
      padding-left: 2rem;
      padding-right: 2rem;
      width: calc(100% - 4rem);
    }

    .oil-navbar-container {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }

    .separator {
      display: none;
    }

    .wrapperItems {
      .catalog-view-mode {
        &.GRID {
          justify-content: center;
        }
      }
    }
  }
}

.app-layout-mobile {
  .oils-wrapper {
    .filterOptions {
      justify-content: space-between;
      margin-bottom: 1rem;
    }
  }

  @media screen and (max-width: 480px) {
    .oils-wrapper {
      .wrapperItems {
        .catalog-view-mode {
          &.GRID {
            grid-template-columns: repeat(auto-fit, 100%);
          }
        }
      }
    }
  }
}