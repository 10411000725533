@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.account-dropdown-automotor {
  cursor: pointer;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  @include themify($themes) {
    color: themed('main');
  }

  width: 20rem;
  gap: 0.5rem;

  @media only screen and (max-width: 500px) {
    width: auto;
    flex-direction: column;
    gap: 0rem;
  }

  .text {
    font-size: 1.3rem;
    letter-spacing: 0;
    line-height: 1rem;
    text-align: center;

    @media only screen and (max-width: 500px) {
      line-height: 2rem;
    }
  }

  .myAccount {
    @include themify($themes) {
      color: themed('main');
    }
  }

  & .caret-icon {
    @include themify($themes) {
      color: themed('main');
    }
  }

  & .user-icon {
    font-size: 1.6rem;

    @include themify($themes) {
      color: themed('main');
    }
  }

  .show {
    .content-data {
      display: block;
    }
  }

  .hide {
    .content-data {
      display: none;
    }
  }

  .content-data {
    padding: 1rem 0;
    min-width: 25rem;

    @include themify($themes) {
      background-color: themed('bgActive');
    }

    position: absolute;
    border-radius: 0.4rem;
    top: 3.6rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

    @media only screen and (max-width: 768px) {
      top: 5rem;
    }

    .item-help {
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      text-decoration: none;
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      padding: 0 1rem;
      gap: 1rem;
      cursor: pointer;

      &:hover {
        @include themify($themes) {
          background-color: themed('mainHover');
        }

        width: auto;
      }

      .icon {
        padding: 0.1rem;
      }

      .text {
        min-width: 10rem;
        font-size: 1.2rem;
      }
    }

    .active {
      width: auto;
      height: auto;
    }
  }
}