.general-search-group {
  display:flex;
  gap: 1rem;
  @media screen and (min-width: 1366px) {
    width: 66.6%;
  }
  @media screen and (min-width: 991px) and (max-width: 1366px) {
    padding-bottom: 2rem;
    gap:2rem;
  }
}