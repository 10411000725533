@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.swiper-container {
  position: relative;
  max-width: 100%;
  margin: 0;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 100%;
  justify-content: center;

  .navigation {
    display: flex;
    flex: 0 0 9rem;
    justify-content: center;
    align-items: center;

    .arrow {
      @include themify($themes) {
        color: themed('main');
      }

      font-size: 2rem;
    }
  }

  .swiper-slide {
    max-width: 65rem;
  }

  .swiper-slide,
  .navigation {
    cursor: pointer;
  }

}

.swiper-pagination-bullet {
  @include themify($themes) {
    background-color: themed('main-2');
  }
}