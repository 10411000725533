@import '../../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.battery-edit-container-category {
  width: 99.3rem;
  margin: 4rem 2rem;

  @media only screen and (max-width: 768px) {
    width: auto;
    margin: 4rem 1rem;
  }

  @media screen and (max-width: 990px) and (min-width: 768px) {
    width: 100%;
    margin: 4rem 0rem;
  }

  .close-modal {
    position: absolute;
    right: 1.6rem;
    top: 1.6rem;
    font-size: 1.8rem;
    @include themify($themes) {
      color: themed('main');
    }

    @media only screen and (max-width: 768px) {
      top: 2.6rem;
    }

    @media screen and (max-width: 990px) and (min-width: 768px) {
      top: 3.6rem;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .title {
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 2.5rem;
    letter-spacing: 0;
    line-height: 3.4rem;
    text-align: center;

    @media only screen and (max-width: 768px) {
      font-size: 1.9rem;
    }

    span {
      font-weight: bold;
    }
  }

  .spinner-info {
    position: relative;
    margin-top: 2.5rem;
  }

  .info-item {
    margin-top: 1.4rem;
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 1.3rem;
    letter-spacing: 0;
    line-height: 1.8rem;
    text-align: center;

    span {
      font-weight: bold;
    }
  }

  .row {
    display: flex;

    gap: 1.2rem;
    justify-content: center;
    margin: 1rem;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }

    @media screen and (max-width: 990px) and (min-width: 768px) {
      flex-direction: column;
      gap: 0rem;
      margin: 4rem;
    }

    > .container {
      margin: 1rem 0;
    }

    label {
      @include themify($themes) {
        color: themed('main');
      }
      font-size: 1.4rem;
    }

    input {
      margin-top: 1rem;
    }

    textarea {
      margin-top: 1rem;
      width: 100%;
      border: none;
      resize: vertical;
      padding: 1rem;
      font-size: 1.6rem;
      @include themify($themes) {
        color: themed('main');
        background-color: themed('secondary-3');
      }

      &:focus-visible {
        outline: none;
      }
    }

    .button {
      margin-top: 1rem;
    }

    .select-dropdown {
      height: 4.2rem;
      border-radius: 0.5rem;
      font-size: 1.4rem;
      font-weight: 400;
      @include themify($themes) {
        color: themed('main');
      }
      margin-top: 1rem;

      border: none;

      [class$='-control'] {
        @include themify($themes) {
          color: themed('main');
        }
        border: none;
        @include themify($themes) {
          background-color: themed('secondary-3');
        }
        height: 4.2rem;
        box-shadow: none;

        &:hover {
          border: none;
        }
      }

      [class$='-placeholder'] {
        @include themify($themes) {
          color: themed('main');
        }
        font-size: 1.4rem;
        font-weight: 400;
      }

      [class$='-indicatorContainer'] {
        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-singleValue'] {
        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-indicatorSeparator'] {
        background: none;
        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-indicator'] {
        @include themify($themes) {
          background: themed('main');
        }
        color: $white;
      }
    }
  }

  .btn-save {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3rem 0rem;

    @media only screen and (max-width: 768px) {
      margin: 3rem 3rem;
    }

    button {
      @include themify($themes) {
        background-color: themed('main');
      }
      color: $white;
      border: none;
      border-radius: 0.5rem;
      padding: 0.5rem 2.5rem;
      font-weight: bold;
      width: 45.2rem;
      height: 4.7rem;
      font-size: 1.4rem;
      outline: none;
      transition: all 0.3s ease-in-out;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
      }

      .title-btn-save {
        width: 100%;
      }

      .extra-small {
        width: 2rem;

        circle {
          stroke: $white;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: auto;
    margin: 4rem 1rem;
    padding: 1rem;
    box-sizing: border-box;
  }

  @media screen and (max-width: 990px) and (min-width: 768px) {
    width: 100%;
    margin: 4rem 0rem;
  }

  .upload-file-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 15rem;

    img {
      width: 200px;
    }

    .icon {
      > img {
        width: 30px;
      }
    }

    .file-preview {
      width: 200px;
    }
  }

  .close-modal {
    position: absolute;
    right: 1.6rem;
    top: 1.6rem;
    font-size: 1.8rem;
    @include themify($themes) {
      color: themed('main');
    }

    @media only screen and (max-width: 768px) {
      top: 2rem;
      right: 2.6rem;
    }

    @media screen and (max-width: 990px) and (min-width: 768px) {
      top: 3.6rem;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .title {
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 2.5rem;
    letter-spacing: 0;
    line-height: 3.4rem;
    text-align: center;

    @media only screen and (max-width: 768px) {
      font-size: 1.9rem;
    }

    span {
      font-weight: bold;
    }
  }

  .spinner-info {
    position: relative;
    margin-top: 2.5rem;
  }

  .info-item {
    margin-top: 1.4rem;
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 1.3rem;
    letter-spacing: 0;
    line-height: 1.8rem;
    text-align: center;

    span {
      font-weight: bold;
    }
  }

  .modal-info {
    overflow: auto;
    max-height: 74vh;

    @media only screen and (max-width: 768px) {
      max-height: 70vh;
    }
  }

  .row {
    display: flex;

    gap: 1.2rem;
    justify-content: center;
    margin: 1rem;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }

    @media screen and (max-width: 990px) and (min-width: 768px) {
      flex-direction: column;
      gap: 0rem;
      margin: 4rem;
    }

    .empty_text {
      color: $blue-13 !important;
    }

    .active_text {
      color: $blue !important;
    }

    > .container {
      margin: 1rem 0;

      & .date-input-item {
        width: 100%;
        position: relative;

        & .select-dropdown {
          width: 100%;
          height: 4.2rem;
          border-radius: 0.5rem;
          font-size: 1.4rem;
          font-weight: 400;
          @include themify($themes) {
            color: themed('main');
          }
          border: none;

          &.disabled {
            opacity: 30%;
            pointer-events: none;
            user-select: none;
          }

          [class$='-control'] {
            @include themify($themes) {
              color: themed('main');
            }
            border: none;
            @include themify($themes) {
              background-color: themed('mainHover');
            }
            height: 4.2rem;
            box-shadow: none;

            &:hover {
              border: none;
            }
          }

          [class$='-placeholder'] {
            @include themify($themes) {
              color: themed('main');
            }
            font-size: 1.4rem;
            font-weight: 400;
          }

          [class$='-indicatorContainer'] {
            @include themify($themes) {
              color: themed('main');
            }
          }

          [class$='-singleValue'] {
            @include themify($themes) {
              color: themed('main');
            }
          }

          [class$='-indicatorSeparator'] {
            background: none;
            @include themify($themes) {
              color: themed('main');
            }
          }

          [class$='-indicator'] {
            @include themify($themes) {
              background: themed('main');
            }
            color: $white;
          }
        }
      }

      & ul.react-tabs__tab-list {
        list-style: none;
        display: flex;
        padding-left: 0;
        border-bottom: 2px solid $blue-5;

        & li {
          cursor: pointer;
          padding: 16px;
          text-align: center;
          font-weight: 700;
          font-size: 1.8rem;
          line-height: 2.5rem;

          @include themify($themes) {
            color: themed('main');
          }

          @media only screen and (max-width: 768px) {
            font-size: 1.5rem;
            line-height: 2rem;
            padding: 7px;
          }

          &.react-tabs__tab--selected {
            // color: white;

            border-bottom: 4px solid $blue-1;
            margin-bottom: -1px;
          }

          &.react-tabs__tab--disabled {
            color: $blue-4;
            pointer-events: none;
            cursor: default;
          }
        }
      }

      & .react-tabs__tab-panel {
        .__react_component_tooltip {
          @media only screen and (max-width: 768px) {
            left: 2rem !important;
            width: 80% !important;
          }
        }

        & ul {
          list-style: none;
          padding: 5px 5px 20px 5px;
          font-size: 1.2rem;
          font-weight: bold;

          @media screen and (max-width: 990px) and (min-width: 768px) {
            max-height: 500px;
            overflow-y: auto;
          }

          & p {
            font-weight: 700;
            font-size: 1.7rem;
            margin: 1rem 0;
            @include themify($themes) {
              color: themed('main');
            }

            @media only screen and (max-width: 768px) {
              font-size: 1.5rem;
            }
          }

          & a {
            text-decoration: none;
            font-size: 1rem;
            padding: 5px 5px;
            display: block;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 2.2rem;
            cursor: pointer;

            @include themify($themes) {
              color: themed('main');
            }

            &:hover {
              background: $blue-5;
              border-radius: 3px;
            }
          }
        }

        & .no-data {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 25rem;
          font-weight: 700;
          font-size: 1.7rem;
          margin: 0.2rem 0;

          @include themify($themes) {
            color: themed('main');
          }
        }
      }
    }

    > .file {
      width: 40rem;

      @media screen and (max-width: 990px) and (min-width: 768px) {
        width: auto;
      }

      @media screen and (max-width: 768px) {
        width: auto;
      }
    }

    > .pdf {
      width: 40rem;

      @media screen and (max-width: 990px) and (min-width: 768px) {
        width: auto;
      }

      @media screen and (max-width: 768px) {
        width: auto;
      }
    }

    label {
      @include themify($themes) {
        color: themed('main');
      }
      font-size: 1.4rem;
    }

    textarea {
      margin-top: 1rem;
      width: 100%;
      border: none;
      @include themify($themes) {
        background-color: themed('secondary-3');
      }
      resize: vertical;

      font-size: 1.6rem;
      @include themify($themes) {
        color: themed('main');
      }

      @media screen and (max-width: 768px) {
        padding: 0;
      }

      &:focus-visible {
        outline: none;
      }
    }

    .button {
      margin-top: 1rem;
    }

    .select-dropdown {
      height: 4.2rem;
      border-radius: 0.5rem;
      font-size: 1.4rem;
      font-weight: 400;
      @include themify($themes) {
        color: themed('main');
      }
      margin-top: 1rem;

      border: none;

      [class$='-control'] {
        @include themify($themes) {
          color: themed('main');
        }
        border: none;
        @include themify($themes) {
          background-color: themed('secondary-3');
        }
        height: 4.2rem;
        box-shadow: none;

        &:hover {
          border: none;
        }
      }

      [class$='-placeholder'] {
        @include themify($themes) {
          color: themed('main');
        }
        font-size: 1.4rem;
        font-weight: 400;
      }

      [class$='-indicatorContainer'] {
        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-singleValue'] {
        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-indicatorSeparator'] {
        background: none;
        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-indicator'] {
        @include themify($themes) {
          background: themed('main');
        }
        color: $white;
      }
    }
  }

  .btn-save {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3rem 0rem;

    @media only screen and (max-width: 768px) {
      margin: 3rem 3rem;
    }

    button {
      @include themify($themes) {
        background-color: themed('main');
      }
      color: $white;
      border: none;
      border-radius: 0.5rem;
      padding: 0.5rem 2.5rem;
      font-weight: bold;
      width: 45.2rem;
      height: 4.7rem;
      font-size: 1.4rem;
      outline: none;
      transition: all 0.3s ease-in-out;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
      }

      .title-btn-save {
        width: 100%;
      }

      .extra-small {
        width: 2rem;

        circle {
          stroke: $white;
        }
      }
    }
  }

  .disabled {
    pointer-events: none;
    background-color: $blue-2 !important;
  }
}
