@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.my-eshop-container {
  max-width: 100%;
  margin: 0 auto;

  .order-clients {
    display: flex;
    flex-direction: column;
    margin-top: 5rem;

    .order-clients-title {
      @include themify($themes) {
        color: themed('main');
      }

      text-align: center;
      font-size: 2.5rem;
      font-weight: bold;
    }

    .order-clients-table {
      margin-top: 1.5rem;
    }

    .order-clients-btn-order-client {
      text-align: center;
      margin: 1.5rem 0rem 5.5rem 0rem;

      .btn {
        padding: 0 2.5rem 0 2.5rem;
        height: 3.8rem;
        @include themify($themes) {
          background: themed('main');
        }
        border-radius: 0.5rem;
        color: $white;
        font-weight: 600;
        font-size: 1.12rem;
        line-height: 1.9rem;
        border: none;

        &:hover {
          opacity: 0.7;
          cursor: pointer;
        }
      }
    }
  }
}