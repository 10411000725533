@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.table-wrapper.statistics-table {
  position: relative;

  & .centered {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .no-data {
    font-size: 2.4rem;
    font-weight: bold;

    @include themify($themes) {
      color: themed('main');
    }

    opacity: 60%;
    text-align: center;
  }

  .statistics-table-spinner {
    position: absolute;
    top: 23rem;
  }

  table {
    width: 100%;

    .headings {
      .row {
        .heading {
          @include themify($themes) {
            color: themed('main');
          }

          font-weight: bold;
          text-align: left;
          font-size: 1.2rem;
        }
      }
    }
  }

  .body {
    .cell-row {
      &:hover {
        opacity: 50%;
        cursor: pointer;
      }

      font-size: 1.2rem;

      .cell {
        padding: .85rem .4rem .85rem 1.4rem;
        max-width: max-content;

        @include themify($themes) {
          background-color: themed('secondary-3');
        }



        @include themify($themes) {
          color: themed('main');
        }
      }


    }
  }
}