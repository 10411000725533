@import "src/assets/sass/variables";
@import "src/assets/sass/themes";
@import "src/assets/sass/tables";

.DefaultCss_body__m19pM {
  position: static !important;
}

.catalog-page-container {
  .catalog-page {
    margin-top: 2.6rem;

    & .catalog-header-container {
      padding: 0 1.4rem;

      & .catalog-header {
        position: relative;

        @include themify($themes) {
          background-color: themed("secondary");
        }

        padding: 1.8rem 27.6rem 3.04rem 27.6rem;
        border-radius: 1rem;
        min-height: 10rem;

        @media screen and (max-width: 768px) {
          padding: 1.8rem 1.4rem 3.04rem 1.4rem;
        }

        .catalog-header-edit {
          text-align: right;
          font-size: 16px;

          @include themify($themes) {
            color: themed("main");
          }

          @media screen and (min-width: 768px) and (max-width: 1080px) {
            margin-right: 10rem;
            margin-top: 2rem;
          }

          svg {
            cursor: pointer;
          }
        }

        & .catalog-header-info {
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 1.7rem;

          & .supplier-img {
            max-height: 30rem;
            max-width: 30rem;
          }

          & .website-btn {
            width: 14.2rem;
            height: 3.8rem;
          }

          & .description {
            text-align: center;

            @include themify($themes) {
              color: themed("main");
            }

            font-weight: 300;
            font-size: 1.5rem;
          }
        }
      }
    }

    & .catalog-name {
      padding: 2.6rem 0;
      text-align: center;
      font-size: 3rem;

      @include themify($themes) {
        color: themed("main");
      }

      font-weight: 300;
    }

    & .promotions-slider {
      margin-bottom: 3.8rem;
      position: relative;

      & .promotions {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 1.6rem;
        width: 100%;
      }

      & .arrow-icon {
        @include themify($themes) {
          color: themed("main");
        }

        font-size: 2rem;
      }
    }

    .container-fluid-large {
      @media screen and (min-width: 1440px) {
        .swiper-container {
          .swiper-prev {
            margin-left: -9rem;
          }

          .swiper-next {
            margin-right: -9rem;
          }
        }
      }
    }
  }

  .container-fluid-large {
    padding: 1rem 0;

    .promotions-slider,
    .catalog-video {
      padding: 1rem 0;
      position: relative;
    }

    .news {
      display: flex;
      flex-direction: row;
      gap: 12rem;
      position: relative;
      align-items: flex-start;
      justify-content: center;
      margin: 4.3rem auto auto 9rem;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        margin: 4.3rem 3rem auto 3rem;

        img {
          width: auto;
        }
      }

      &.loading {
        min-height: 30rem;
        width: 100%;
      }

      & .main-news {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      & .side-news {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1.9rem;

        & .side-news-button {
          width: 19.5rem;
        }
      }
    }
  }

  .catalog-orders-table {
    padding: 2rem 9rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-height: 20rem;
    justify-content: flex-end;

    & .sellers-table-spinner {
      position: absolute;
      background: none;
    }

    & .button {
      width: 19.4rem;
      margin: 1rem 0;
    }
  }

  .category-slider {
    padding: 2rem 0;
  }

  .category-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2.5rem;
    padding: 2rem 0;
    width: 100%;
    min-height: 20rem;
    position: relative;

    .category-item {
      max-width: 18.9rem;
    }
  }

  .tariff-container {
    position: absolute;
    left: 1.2rem;
    bottom: 1.2rem;

    @media screen and (max-width: 768px) {
      position: initial;
      margin-top: 2rem;
    }
  }

  .catalog-video {
    .video-container {
      padding: 5rem 0;

      @media screen and (max-width: 768px) and (max-width: 990px) {
        padding: 5rem 1rem;
      }
    }
  }
}

/* Responsive CSS Styles */

.app-layout-desktop {}

.app-layout-tablet {
  .catalog-page-container {
    .tariff-container {
      max-width: fit-content;
    }
  }
}

.app-layout-tablet,
.app-layout-mobile {
  .catalog-page-container {
    padding-bottom: 4rem;
    background-color: $white;

    .catalog-page {
      margin-top: 0;
      background-color: $background-grey;

      .catalog-header-container {
        padding: 0 2rem;

        .catalog-header {
          padding: 0;
          background-color: transparent;
        }
      }
    }

    .tab-filter {
      padding-bottom: 4rem;
      background-color: $background-grey;
      z-index: 2;
      position: relative;
    }

    .tabs-container {
      .tabs {
        .tab-item {
          &.active {
            background-color: $white;
          }
        }
      }

      .tabs-content {
        &>div {
          background-color: $white;
          box-shadow: none;
          border-radius: 0;
        }
      }
    }

    .catalog-orders-table {
      padding: 2rem;
    }

    .category-slider {
      @media screen and (max-width: 990px) {
        .swiper-wrapper {
          .swiper-slide {
            min-width: 18rem;
          }
        }
      }
    }

    .category-items {}

    .tariff-container {
      position: initial;
      margin: 3rem auto 1rem;
    }

    .catalog-video {}
  }
}

.app-layout-mobile {}