@import "assets/sass/variables";
@import "src/assets/sass/themes";

.price-group-container {
  display: block;
  max-width: $container-width-xlarge;
  margin: 0 auto;
  width: 100%;

  & .actions {
    display: flex;
    justify-content: center;
    gap: 3rem;
    flex-wrap: wrap;
    // margin: 15rem auto 1rem auto;
    margin: 7rem auto 1rem auto;

    @media screen and (max-width: 768px) {
      padding: 0 2rem;
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    gap: 3rem;
    flex-wrap: wrap;
    margin: 7rem auto 1rem auto;

    >* {
      background-color: #e4ebf7;
    }
  }

  .item-price {
    a {
      text-decoration: none;
      display: block;
      width: 100%;
      height: 100%;

    }

    max-width: 40rem;
    width: 100%;
    text-align: center;

    @include themify($themes) {
      background-color: themed("secondary-3");
    }

    border-radius: 1rem;
    cursor: pointer;

    & .title {
      padding: 0 4px;
      background: $blue-5;
      font-weight: 700;
      font-size: 2.2rem;
      line-height: 3rem;

      @include themify($themes) {
        color: themed("main");
      }

      padding: 1.4rem 0;
      border-top-right-radius: 1rem;
      border-top-left-radius: 1rem;

      @media screen and (max-width: 768px) {
        font-size: 1.8rem;
        line-height: 2rem;
      }
    }

    & .icon-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 3rem;
      margin: 3.7rem 0;

      & .icon {
        width: 11rem;
        height: 11rem;
        border-radius: 50%;

        @include themify($themes) {
          background-color: themed("secondary");
        }

        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 768px) {
          width: 8rem;
          height: 8rem;
        }

        & svg {
          width: 6rem;
          height: 6rem;

          @include themify($themes) {
            fill: themed("searchSvg", fill1);
          }

          @media screen and (max-width: 768px) {
            width: 4rem;
            height: 4rem;
          }
        }
      }

      & .text {
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 1.7rem;

        @include themify($themes) {
          color: themed("main");
        }

        @media screen and (max-width: 768px) {
          font-size: 1.5rem;
          line-height: 1.3rem;
        }
      }
    }
  }
}