@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.maintenance-tabs-container {
  & .maintenance-tabs {
    @include themify($themes) {
      background: themed('secondary-2');
    }
    width: 100%;
    height: 10.5rem;
    border-radius: 0.6rem;
    margin-top: 5rem;
    margin-bottom: 3rem;

    & .tabs {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include themify($themes) {
        color: themed('main');
      }

      & .tab-item {
        width: 100%;
        height: 5.4rem;
        list-style: none;
        font-size: 1.6rem;
        font-weight: 300;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1rem 1rem 0 0;
        display: flex;
        flex-direction: column;
        gap: 1.3rem;

        & .tab-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          @include themify($themes) {
            background-color: themed('secondary-3');
          }
          padding: 4rem;
          border-radius: 50%;
          box-sizing: border-box;
          width: 3rem;
          height: 3rem;
          font-size: 3rem;
          margin-top: -2rem;
          transition: all 0.2s ease-in-out;
          cursor: pointer;

          &:hover {
            @include themify($themes) {
              background-color: themed('main');
            }
            @include themify($themes) {
              color: themed('secondary');
            }
          }
        }

        &.active {
          font-weight: bold;

          .tab-icon {
            @include themify($themes) {
              background-color: themed('main');
            }
            @include themify($themes) {
              color: themed('secondary');
            }
          }
        }

        &.disabled-tab {
          cursor: default;
          pointer-events: none;

          .tab-icon {
            background-color: #e3e6ed;
            @include themify($themes) {
              color: themed('main');
            }

            & > svg {
              @include themify($themes) {
                fill: themed('main');
              }
              opacity: 25%;
            }
          }

          .tab-title {
            @include themify($themes) {
              color: themed('main');
            }
            opacity: 25%;
          }
        }
      }
    }
  }
}
