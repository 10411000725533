@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.ReactModal__Content {
  min-width: 35rem;

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
}

.client-reject-modal {
  padding: 0 1rem;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding: 0 2rem;
    box-sizing: border-box;
    min-width: auto;
    margin-top: 5rem;
  }

  & .modal-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;

    & .title {
      text-align: center;
      font-size: 1.6rem;
      line-height: 1.6rem;
      font-weight: 600;
      @include themify($themes) {
        color: themed('main');
      }
      margin: 0;
      width: 100%;
    }

    & .close {
      & .close-icon {
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        @include themify($themes) {
          color: themed('main');
        }
      }
    }
  }

  & .modal-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & .client-message {
      width: 100%;

      .client-reject-textarea {
        width: 100%;
        @include themify($themes) {background-color: themed('bgActive');}
        padding: 0.625rem;
        font-size: 1.125rem;
        border-radius: 0.625rem;
        border: none;
        resize: vertical;
        @include themify($themes) {
          color: themed('main');
        }
        outline: none;
        min-height: 5rem;
        box-sizing: border-box;
        max-height: 20rem;

        &::-webkit-input-placeholder {
          color: $blue-13;
        }
      }
    }
  }
}
