@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.tabs-history {
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.history-page {
  display: flex;
  width: 100%;
  flex-direction: column;

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 35px;



    @media screen and (max-width: 990px) and (min-width: 768px) {
      padding-top: 2rem;
    }

    &-search {
      display: none;

      .text-input-container {

        .text-input {
          @include themify($themes) {
            background-color: themed('inputColor');
            color: themed('main');
          }
        }
      }
    }

    & .show-cars,
    & .show-parts {
      display: block;
    }

    &-title {
      font-size: 26px;
      font-weight: 800;
      margin-top: 2rem;

      @include themify($themes) {
        color: themed('main');
      }
    }

    &-tabs {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      gap: 15px;

      @media screen and (max-width: 768px) {
        height: 100%;
      }

      &-item {
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 700;
        height: 28px;
        border-radius: 10px;
        width: 160px;

        @include themify($themes) {
          background-color: themed('tabInactive');
          color: themed('tabInactiveColor');
        }
      }

      .activeTabs {
        @include themify($themes) {
          background-color: themed('tabActive');
          color: themed('tabActiveColor');
        }

        border-bottom: 1px solid transparent;
      }

      .activeTabs::before {
        display: block;

        @include themify($themes) {
          background-color: themed('tabActive');
        }
      }
    }

    &-no_data {
      @include themify($themes) {
        color: themed('main');
      }

      font-size: 2rem;
    }

    &-tabs_content {
      display: flex;
      position: relative;
      flex-wrap: wrap;
      width: 90%;
      height: auto;
      gap: 20px;
      justify-content: center;

      @media screen and (max-width: 768px) {
        flex-wrap: nowrap;
        height: 100%;
      }

      @media screen and (max-width: 990px) and (min-width: 768px) {
        width: 100%;
      }
    }

    &-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      padding: 3rem 0 2rem 0;

      a {
        @include themify($themes) {
          color: themed('main');
        }
      }
    }
  }
}