@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.my-account-wrapper {
  display: flex;
  height: 100%;

  .main-container {
    flex: 1;
    padding: 0 3.5rem;
  }
}

/* Responsive CSS Styles */

.app-layout-tablet,
.app-layout-mobile {
  .my-account-wrapper {
    background: $white;
  }
}

.app-layout-mobile {
  .my-account-wrapper {
    .main-container {
      width: 100%;
      padding: 0;
    }
  }
}
