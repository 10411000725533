@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.vin-search-card {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  @include themify($themes) {
    background-color: themed('secondary');
  }
  border-radius: 1rem;
  box-shadow: 0.2rem 0.2rem 1rem #0000000a;
  padding: 3rem;
  gap: 2.4rem;

  @media screen and (max-width: 768px) {
    gap: 0rem;
    flex-direction: column;
  }

  & .reference-label {
    font-weight: 800;
    font-size: 1.4rem;
    @include themify($themes) {
      color: themed('main');
    }
  }

  & .or-separator {
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 1.4rem;
    margin-top: 1rem;
  }

  & .catalog-plate-number-container {
    position: relative;
    width: 43rem;

    @media screen and (max-width: 768px) {
      width: 30rem;
    }

    @media screen and (min-width: 768px) and (max-width: 990px) {
      width: 30rem;
    }
  }

  & .catalog-vin-container {
    position: relative;
    width: 43rem;

    @media screen and (max-width: 768px) {
      width: 30rem;
    }

    @media screen and (min-width: 768px) and (max-width: 990px) {
      width: 30rem;
    }
  }
}
