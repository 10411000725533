@import '../../../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.icon-del {
  width: 20px;
  height: 20px;
  @include themify($themes) {
    color: themed('main');
  }
  cursor: pointer;
}