@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.configuration-card {
  @include themify($themes) {
    background: themed('secondary-2');
  }
  width: 33%;
  min-height: 25vh;
  padding: 2rem;
  box-sizing: border-box;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
  border-radius: 0.5rem;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  & .card-header {
    @include themify($themes) {
      color: themed('main');
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include themify($themes) {
      border-bottom: 0.1rem solid themed('separatorLine');
    }
    padding-bottom: 1.3rem;

    & .card-title {
      font-size: 1.6rem;
    }

    & .card-edit {
      font-size: 1.6rem;
      cursor: pointer;

      &:hover {
        opacity: 80%;
      }
    }
  }
}
