@import "src/assets/sass/variables";
@import "src/assets/sass/themes";

.search-card,
.vehicle-selector-search-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;

  @include themify($themes) {
    background: themed("secondary");
  }

  border-radius: 1rem;
  box-shadow: 0.2rem 0.2rem 1rem #0000000a;

  @media screen and (min-width: 991px) and (max-width: 1366px) {
    box-shadow: 0.2rem 0.2rem 1rem rgb(0 0 0 / 4%) !important;
    border-radius: 1rem !important;
  }


  padding: 1.8rem;

  & .card-title {
    @include themify($themes) {
      text-decoration: underline themed("main");
    }

    font-size: 1.7rem;

    @include themify($themes) {
      color: themed("main");
    }

    font-weight: 600;
    margin-bottom: 1.4rem;
    margin-top: 2.1rem;
    letter-spacing: 0.3px;
  }

  & .dropdowns-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.7rem 3.2rem;
    align-items: flex-end;
    width: 100%;

    & .dropdown-item {
      width: 100%;
      min-width: 21rem;

      & .dropdown-label {
        font-weight: 800;
        font-size: 1.5rem;

        @include themify($themes) {
          color: themed("main");
        }
      }

      & .select-dropdown {
        width: 100%;
        height: 4.2rem;
        border-radius: 0.5rem;
        font-size: 1.4rem;
        font-weight: 400;
        border: none;

        @include themify($themes) {
          color: themed("main");
        }

        &.disabled {
          opacity: 30%;
          pointer-events: none;
          user-select: none;

          &:before {
            opacity: 0;
          }
        }

        [class$="-control"] {
          border: none;
          height: 4.2rem;
          box-shadow: none;
          border-radius: 1rem;

          @include themify($themes) {
            color: themed("main");
            background-color: themed("mainHover");
          }

          &:hover {
            border: none;
          }
        }

        [class$="-placeholder"] {
          @include themify($themes) {
            color: themed("main");
          }

          font-size: 1.4rem;
          font-weight: 400;
        }

        [class$="-indicatorContainer"] {
          @include themify($themes) {
            color: themed("main");
          }
        }

        [class$="-singleValue"] {
          @include themify($themes) {
            color: themed("main");
          }

          width: 19rem;
        }

        [class$="-indicatorSeparator"] {
          background: none;

          @include themify($themes) {
            color: themed("main");
          }
        }

        [class$="-indicator"] {
          @include themify($themes) {
            background: themed("main");
          }

          color: $white;
        }
      }
    }
  }

  & .submit-button {
    width: 100%;
    grid-column: span 2;

    &.disabled {
      //opacity: 30%;
      pointer-events: none;
      user-select: none;
    }
  }

  & .truck-selector-submit {
    grid-column: span 2;
    margin: 0.7rem 0;
  }
}