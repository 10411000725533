@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.tariff {
  @include themify($themes) {background: themed('mainHover');}
  border-radius: 1rem;
  padding: 1.4rem 5rem;
  position: relative;

  @media screen and (max-width: 768px) {
    padding: 0 1.4rem;
  }

  & .actualisation {
    color: $red-3;
    font-weight: 800;
    font-size: 1rem;
    line-height: 1.4rem;
    position: absolute;
    top: 3px;
    left: -12px;
    transform: rotate(-29deg);
    text-transform: uppercase;
  }

  & .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.1rem;
    background: none;

    @media screen and (max-width: 768px) {
      flex-direction: row;
      justify-content: space-around;
    }

    & .logo {
      max-height: 4.7rem;
      max-width: 11.7rem;
      padding-top: 1rem;
    }

    & .file-name {
      @include themify($themes) {
        color: themed('main');
      }
      text-decoration: underline;
      font-size: 1.2rem;
      line-height: 1.4rem;
      max-width: 15rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
      padding-bottom: 0;

      @media screen and (max-width: 768px) {
        max-width: 19rem;
      }
    }

    & .action {
      display: flex;
      gap: 1rem;
      @include themify($themes) {
        color: themed('main');
      }
      font-size: 1.5rem;

      & .tariff {
        @include themify($themes) {
          color: themed('main');
        }
        @include themify($themes) {
          fill: themed('main');
        }
      }

      & .download svg :hover {
        font-size: 1.7rem;
        transition: 0.5s;
        position: relative;
        cursor: pointer;

        & svg {
          position: absolute;
        }
      }
    }
  }
}
