@import '../../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.tyre-edit-container-brand {
  width: 99.3rem;
  margin: 4rem 2rem;

  @media only screen and (max-width: 768px) {
    width: 100%;

    margin: 4rem 0rem;
  }

  @media screen and (max-width: 990px) and (min-width: 768px) {
    width: 100%;

    margin: 4rem 0rem;
  }

  .close-modal {
    position: absolute;
    right: 1.6rem;
    top: 1.6rem;
    font-size: 1.8rem;
    @include themify($themes) {
      color: themed('main');
    }

    @media only screen and (max-width: 768px) {
      top: 2.6rem;
    }

    @media screen and (max-width: 990px) and (min-width: 768px) {
      top: 3.6rem;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .title {
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 2.5rem;
    letter-spacing: 0;
    line-height: 3.4rem;
    text-align: center;

    @media only screen and (max-width: 768px) {
      font-size: 1.9rem;
      padding: 0rem;
    }

    span {
      font-weight: bold;
    }
  }

  .spinner-info {
    position: relative;
    margin-top: 2.5rem;
  }

  .info-item {
    margin-top: 1.4rem;
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 1.3rem;
    letter-spacing: 0;
    line-height: 1.8rem;
    text-align: center;

    span {
      font-weight: bold;
    }
  }

  .row {
    display: flex;

    gap: 1.2rem;
    justify-content: center;
    margin: 1rem;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      gap: 0rem;
    }

    @media screen and (max-width: 990px) and (min-width: 768px) {
      flex-direction: column;
      gap: 0rem;
      margin: 4rem;
    }

    > .container {
      margin: 1rem 0;
    }

    label {
      @include themify($themes) {
        color: themed('main');
      }
      font-size: 1.4rem;
    }

    input {
      margin-top: 1rem;
    }

    textarea {
      margin-top: 1rem;
      width: 100%;
      border: none;
      @include themify($themes) {
        background-color: themed('secondary-3');
      }
      resize: vertical;
      padding: 1rem;
      font-size: 1.6rem;
      @include themify($themes) {
        color: themed('main');
      }

      &:focus-visible {
        outline: none;
      }
    }

    .button {
      margin-top: 1rem;
    }

    .select-dropdown {
      height: 4.2rem;
      border-radius: 0.5rem;
      font-size: 1.4rem;
      font-weight: 400;
      @include themify($themes) {
        color: themed('main');
      }
      margin-top: 1rem;

      border: none;

      [class$='-control'] {
        @include themify($themes) {
          color: themed('main');
        }
        border: none;
        @include themify($themes) {
          background-color: themed('secondary-3');
        }
        height: 4.2rem;
        box-shadow: none;

        &:hover {
          border: none;
        }
      }

      [class$='-placeholder'] {
        @include themify($themes) {
          color: themed('main');
        }
        font-size: 1.4rem;
        font-weight: 400;
      }

      [class$='-indicatorContainer'] {
        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-singleValue'] {
        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-indicatorSeparator'] {
        background: none;
        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-indicator'] {
        @include themify($themes) {
          background: themed('main');
        }
        color: $white;
      }
    }
  }

  .btn-save {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3rem 0rem;

    @media only screen and (max-width: 768px) {
      margin: 3rem 3rem;
    }

    button {
      @include themify($themes) {
        background-color: themed('main');
      }
      color: $white;
      border: none;
      border-radius: 0.5rem;
      padding: 0.5rem 2.5rem;
      font-weight: bold;
      width: 45.2rem;
      height: 4.7rem;
      font-size: 1.4rem;
      outline: none;
      transition: all 0.3s ease-in-out;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
      }

      .title-btn-save {
        width: 100%;
      }

      .extra-small {
        width: 2rem;

        circle {
          stroke: $white;
        }
      }
    }
  }

  .disabled {
    pointer-events: none;
    background-color: $blue-2 !important;
  }
}
