@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.order-in-progress a {
  text-decoration: none;
}

// .proforma-upload-file {
//     max-width: 48rem;
//     width: 100%;
//     text-align: center;
//     border-radius: 1rem;
//     cursor: pointer;

//     & .title {
//         background: $blue-5;
//         font-weight: 700;
//         font-size: 2.2rem;
//         line-height: 3rem;
//         padding: 1.4rem 0;
//         border-top-right-radius: 1rem;
//         border-top-left-radius: 1rem;
//     }

//     & .upload-btn {
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         align-items: center;
//         gap: 1.5rem;
//         margin: 3.7rem 0;

//         & .icon {
//             width: 11rem;
//             height: 11rem;
//             border-radius: 50%;

//             display: flex;
//             justify-content: center;
//             align-items: center;
//             position: relative;

//             & img {
//                 width: 4rem;
//             }
//         }

//         & .text {
//             font-weight: 500;
//             font-size: 1.8rem;
//             line-height: 6px;
//         }
//     }

// }
