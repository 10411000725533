@import "../../assets/sass/variables";
@import "src/assets/sass/themes";

.LIST-item-container-shop-tyre {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  justify-content: center;
  margin: 1rem 0;
  position: relative;
  width: 100%;
  align-items: center;

  .item-shop {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    @include themify($themes) {
      background: themed("secondary");
    }

    padding: 1rem;

    .tyre-header {
      display: flex;
      flex-direction: row;
      width: 90%;
      margin-left: 0.8rem;
      gap: 1rem;
      align-items: center;

      .img-zoom-container {
        position: relative;
        display: flex;
        gap: 1rem;
        z-index: 10;

        & .img-zoom {
          position: absolute !important;
          left: 6.5rem;
          background-color: #ffffff;
          border: 1px solid #dad8d8;
          // img {
          //   width: 100% !important;
          // }
        }
      }

      .img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 6.4rem;
        height: 6.4rem;
        border: 0.1rem solid $gray;

        img {
          max-width: 6.4rem;
          max-height: 6.4rem;
          margin: auto;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        margin-left: 2rem;

        .title {
          text-align: left;
          margin-bottom: 0.5rem;
          font-size: 1.4rem;
          font-weight: bold;

          @include themify($themes) {
            color: themed("main");
          }

          cursor: pointer;

          &:hover {
            opacity: 75%;
          }
        }

        .reference {
          text-align: left;
          margin-bottom: 0.5rem;
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 1.5;

          @include themify($themes) {
            color: themed("main");
          }
        }
      }

      .description-short {
        text-align: left;
        margin-bottom: 0.5rem;
        font-size: 1rem;
        font-weight: bold;

        @include themify($themes) {
          color: themed("main");
        }
      }

      .description {
        text-align: left;
        margin-bottom: 0.5rem;
        font-size: 1.1rem;

        @include themify($themes) {
          color: themed("main");
        }

        line-height: 1.3;
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 90%;
      margin: auto;
      flex: 1;
      border-left: 0.1rem solid $gray;
      padding-right: 2rem;
      padding-left: 1rem;

      .classes-container {
        position: relative;

        .class-img {
          height: 32px;

          @include themify($themes) {
            filter: themed("pngColorFilter");
          }
        }

        .class-index-fuel {
          @include themify($themes) {
            color: themed("main");
          }

          position: absolute;
          font: 800 18px Arial;
          -webkit-text-fill-color: transparent;
          -webkit-text-stroke: 1px;
          left: 41px;
          top: 7px;
        }

        .class-index-wet {
          color: $blue-10;
          position: absolute;
          font: 700 18px Arial;
          left: 43px;
          top: 10px;
        }

        .class-index-decibel {
          @include themify($themes) {
            color: themed("main");
          }

          position: absolute;
          font: 400 8px Arial;
          left: 15px;
          top: 7px;
          width: 22px;
        }
      }
    }

    .footer {
      display: flex;
      flex-direction: column;
      border-left: 0.1rem solid $gray;
      align-items: flex-start;
      margin: 0;
      width: 27.8rem;
      padding-left: 1rem;

      .quantity {
        flex-direction: column;

        .quantity-disponibility {
          align-items: none;
        }

        .disponibility-porduct {
          margin-top: 0.5rem;

          @include themify($themes) {
            color: themed("main");
          }

          .disponibility-count {
            @include themify($themes) {
              color: themed("main");
            }

            font-weight: bold;
          }
        }
      }

      .group {
        display: flex;
        flex-direction: row;
        padding-top: 1rem;
        gap: 1rem;
        align-items: center;

        .cart-button {
          display: flex;
          gap: 1rem;
          justify-content: flex-end;

          .cirrus-contact-us {
            white-space: nowrap;

            @include themify($themes) {
              color: themed("main");
            }

            font-size: 1.2rem;
            text-decoration: underline;
            cursor: pointer;
            min-width: 6.2rem;
            height: 1.6rem;
            margin-top: 0.2rem;

            & .extra-small {
              margin: auto;
            }

            &:hover {
              opacity: 80%;
            }
          }

          .button-edit {
            padding: 0.3rem;
            width: 7rem;
            height: 3.5rem;
            border: none;

            @include themify($themes) {
              background-color: themed("main");
            }

            border-radius: 0.8rem;
            cursor: pointer;

            &:hover {
              background-color: $blue-4;
            }

            .icon-edit {
              color: $white;
              font-size: 1.7rem;
            }
          }

          .button-delete {
            padding: 0.3rem;
            width: 7rem;
            height: 3.5rem;
            border: none;
            background-color: $red;
            border-radius: 0.8rem;
            cursor: pointer;

            &:hover {
              background-color: $red-2;
            }

            .icon-delete {
              color: $white;
              font-size: 1.7rem;
            }
          }
        }
      }

      .cart {
        text-align: center;
        margin-bottom: 0.2rem;
        font-size: 1.8rem;
        font-weight: 600;

        .cart-price {
          font-size: 1.8rem;

          @include themify($themes) {
            color: themed("main");
          }

          font-weight: bold;
        }
      }

      .oem-price_per_unit {
        @include themify($themes) {
          color: themed("main");
        }

        font-size: 1.2rem;
        white-space: nowrap;
      }
    }
  }

  .no-data {
    font-size: 2.4rem;
    font-weight: bold;

    @include themify($themes) {
      color: themed("main");
    }

    opacity: 60%;
    margin-top: 5rem;
    white-space: nowrap;
  }
}
