@import '../../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.account-dropdown-mobile {
  cursor: pointer;

  display: flex;
  justify-content: center;
  @include themify($themes) {
    color: themed('main');
  }
  flex-direction: column;
  height: 0px;

  & .header-drop {
    display: flex;
    height: 0px;
    flex-direction: row;
    gap: 1.7rem;
  }

  & .text {
    white-space: nowrap;
    font-size: 1.3rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.8rem;
    color: black;
  }

  & .myAccount {
    @include themify($themes) {
      color: themed('main');
    }
  }

  & .caret-icon {
    @include themify($themes) {
      color: themed('main');
    }
  }

  & .user-icon {
    font-size: 1.6rem;
    @include themify($themes) {
      color: themed('main');
    }
  }

  & .show {
    .content-data-mobile {
      display: block;

      & .account-dropdown-mobile {
        justify-content: right;
      }
    }
  }

  & .hide {
    .content-data-mobile {
      display: none;
    }
  }

  .content-data-mobile {
    padding: 1rem 0;
    min-width: 25rem;
    background-color: #f2f5f8;
    margin-top: 2rem;
    position: fixed;
    color: black;

    .item {
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      text-decoration: none;
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      padding: 0 1rem;
      gap: 1rem;
      cursor: pointer;
      color: black;

      &:hover {
        @include themify($themes) {background-color: themed('mainHover');}
      }

      .icon {
        padding: 0.1rem;
      }

      .text {
        min-width: 10rem;
        font-size: 1.2rem;
      }
    }

    .active {
      width: auto;
      height: auto;
    }
  }
}
