@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.send-mail-container {
  @media screen and (max-width: 990px) {
    width: 100%;
  }

  .send-email-to-seller {
    background-color: #eaf0fb;
    padding: 1.2rem 7rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 4.5rem 7rem;
    position: relative;
    border-radius: 5px;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      padding: 1rem 3rem;
      margin: 2.5rem 6rem;
    }

    & .header {
      font-weight: 700;
      font-size: 2.2rem;
      line-height: 3rem;
      @include themify($themes) {
        color: themed('main');
      }

      @media screen and (max-width: 768px) {
        font-size: 1.7rem;
      }
    }

    & .subject {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.2rem;
      @include themify($themes) {
        color: themed('main');
      }
      text-align: initial;

      @media screen and (max-width: 768px) {
        font-size: 1.3rem;
      }
    }

    & .icon {
      width: 6rem;
      height: 6rem;
      border-radius: 50%;
      background-color: $blue-5;
      position: absolute;
      top: 15px;
      left: -30px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 768px) {
        width: 4rem;
        height: 4rem;
        left: -20px;
      }

      & svg {
        font-size: 30px;
        @include themify($themes) {
          color: themed('main');
        }

        @media screen and (max-width: 768px) {
          font-size: 2rem;
        }
      }
    }
  }
}
