@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.header-btn-search-retailer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
  top: 8rem;
  position: absolute !important;
  right: 1rem;
}
