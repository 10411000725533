@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.proforma-data-item {
  .content {
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include themify($themes) {
      color: themed('main');
    }
    @include themify($themes) {
      background-color: themed('secondary-3');
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  &:not(:last-child) {
    border-bottom: 2px solid $blue-5;
  }

  span {
    padding: 0 0.5rem;
    flex: 1;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.63rem;
  }

  span.delete {
    width: 1rem;
    text-align: right;
    padding-right: 5rem;

    @media only screen and (max-width: 768px) {
      left: 50%;
      position: relative;
    }
  }
}
