@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.table-wrapper-orders-clients {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 0;
  overflow: auto;

  &.sellers-table-wrapper {
    min-height: 0;
    overflow: auto;
  }

  table {
    @include themify($themes) {
      background-color: themed('bgTable');
    }

    @include themify($themes) {
      color: themed('main');
    }

    border-radius: 1rem;
    border-spacing: 0 .7rem;
    font-size: 1.2rem;
    letter-spacing: normal;
    line-height: 1.7rem;
    padding: .4rem 2.5rem;
    text-align: left;
    width: 100%;

    .headings {
      .row {
        .heading {
          @include themify($themes) {
            color: themed('main');
          }

          font-weight: bold;
          text-align: left;
          font-size: 1.2rem;
          padding: .4rem .4rem .4rem 1.4rem;
        }
      }
    }

    .body {

      .cell-row {
        &:hover {
          opacity: 50%;
          cursor: pointer;
        }

        font-size: 1.2rem;

        .cell {
          padding: .85rem .4rem .85rem 1.4rem;
          max-width: max-content;

          @include themify($themes) {
            background-color: themed('secondary-3');
          }



          @include themify($themes) {
            color: themed('main');
          }
        }

        .cell:last-of-type {
          @include themify($themes) {
            background-color: themed('cellTableLast');
          }

          border-radius: 0 .5rem .5rem 0;
        }
      }

      .no-data {
        font-size: 1.6rem;
        font-weight: bold;

        @include themify($themes) {
          color: themed('main');
        }

        opacity: 60%;
        text-align: center;
      }
    }
  }
}