@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.app-layout-mobile {
  .vehicle-header-wrapper {
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    flex: 0 0 100%;
    flex-flow: column nowrap;
    text-align: center;
    padding: 1rem 0 0;
    gap: 1rem;

    a,
    a:focus,
    a:visited {
      @include themify($themes) {
        color: themed('main');
      }
    }

    .vehicle-header-container {
      position: relative;
      margin-bottom: 2rem;

      .extra-small {
        min-height: 5.8rem;
      }

      .vehicle-details {
        position: absolute;
        left: 0;
        bottom: 0;

        @include themify($themes) {
          color: themed('main');
        }

        width: 5rem;
        height: 5rem;

        .info {
          height: 4.7rem;
          min-width: 13.2rem;
          max-width: 20rem;

          @include themify($themes) {
            border: 0.1rem solid themed('main');
          }

          border-radius: 1rem;
          padding: 0 0.4rem;
          font-size: 1.4rem;
          line-height: 1.9rem;
          font-weight: bold;
          letter-spacing: 0;
          float: left;
          cursor: pointer;
          display: flex;
          flex-flow: row wrap;
          justify-content: center;
          align-items: center;

          @media screen and (max-width: 768px) {
            min-width: 5rem;
            min-height: 5rem;

            .label {
              display: none;
            }
          }

          svg {
            width: 100%;
          }

          .vehicle-info {
            overflow: hidden;

            .title {
              float: left;
            }

            .close {
              float: right;
              height: 1.8rem;
              width: 3rem;
              font-size: 2rem;
              line-height: 2rem;
              text-align: center;
            }
          }
        }

        .favourite {
          float: right;
          font-size: 1.8rem;
          line-height: 1.8rem;
          height: 4.7rem;
          margin: 0 1rem;
          display: flex;
          align-items: flex-end;

          @media screen and (max-width: 768px) {
            position: fixed;
            top: 7.5rem;
            right: 2rem;
          }
        }
      }

      .vehicle-name {
        @include themify($themes) {
          color: themed('main');
        }

        font-size: 2.5rem;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 3.4rem;

        @media screen and (max-width: 768px) {
          font-size: 2rem;
        }
      }

      .vehicle-description {
        @include themify($themes) {
          color: themed('main');
        }

        font-size: 1.8rem;
        letter-spacing: 0;
        line-height: 2.4rem;
        padding: 0px 0px 0px 80px;

        @media screen and (min-width: 1200px) and (max-width: 1450px) {
          padding: 0px 0px 0px 130px;
        }

        @media screen and (max-width: 768px) {
          font-size: 1.1rem;
          padding: 0px 70px 0px 70px;
        }
      }
    }

    .vehicle-page-navbar {
      display: flex;
      justify-content: center;

      .swiper-slide {
        max-width: 22.5rem;

        .navbar-item {
          width: 100%;
        }
      }
    }

    .vehicle-info-container {
      position: fixed;
      overflow: hidden;
      z-index: 120;
      left: 0;
      top: 13rem;
      border-radius: 0;
      width: 100%;
      height: 100vh;
      margin: 0;
      padding: 2rem;

      .vehicle-info-header {
        width: 90%;
        display: flex;
        flex-direction: row;
        padding: 0;
        justify-content: space-between;
        z-index: 120;
        position: relative;
        align-items: center;
        margin-top: 2rem;
      }

      .close {
        float: right;
        height: 1.8rem;
        width: 2rem;
        font-size: 1.8rem;
        line-height: 2rem;
        text-align: center;
        cursor: pointer;
      }

      .content {
        ul {
          li {
            width: 100%;
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
}