@import '../../../../../../../assets/sass/variables';
@import 'src/assets/sass/themes';

//destop
.header-btn-search {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
  top: 17rem;
  position: absolute;
  right: 1rem;

  @media only screen and (max-width: 768px) {
    top: 14rem;
  }
}