@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.app-layout-mobile,
.app-layout-tablet {
  .general-search,
  .home .general-search {
    .search-inputs-container.search-vin {
      div[class$='-dropdown-content'] {
        width: 100%;

        div[class$='-result-content'] {
          .suggestion-list-item {
            .suggestion-item-title {
              grid-template-columns: auto;
              white-space: initial;
            }
          }
        }
      }
    }
    .search-inputs-container.search-plate {
      div[class$='-dropdown-content'] {
        width: 100%;

        div[class$='-result-content'] {
          .suggestion-list-item {
            .suggestion-item-title {
              grid-template-columns: auto;
              white-space: initial;
            }
          }
        }
      }
    }
    .search-inputs-container .or-separator {
      @include themify($themes) {
        color: themed('main');
      }
      font-size: 1.4rem;
    }
  }
}
.plate-number-label {
  color: $white;
}
