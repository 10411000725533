@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.consumable-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 990px) {
    background-color:  $white;
  }

  .body {
    width: 90%;

    .consumable-header {
      @include themify($themes) {
        color: themed('main');
      }
      display: flex;
      flex-direction: row;
      margin-top: 3.3rem;

      @media only screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
      }

      @media screen and (max-width: 990px) and (min-width: 768px) {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
      }

      .title {
        @include themify($themes) {
          color: themed('main');
        }
        font-size: 2.5rem;
        width: 49.5rem;

        @media only screen and (max-width: 768px) {
          display: flex;
          width: 100%;
          justify-content: center;
        }

        @media screen and (max-width: 990px) and (min-width: 768px) {
          width: auto;
        }
      }

      .btn-new {
        button {
          height: 3.5rem;
          width: 24.1rem;
          border-radius: 8px;
          background-color: $blue-13;
          border: none;
          cursor: pointer;
          color: $white;
          font-size: 1.4rem;
          font-weight: bold;

          &:hover {
            @include themify($themes) {
              background-color: themed('main');
            }
          }
        }
      }

      .filter {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 56rem;

        @media only screen and (max-width: 768px) {
          justify-content: center;
          width: 100%;
          align-items: center;
        }

        @media screen and (max-width: 990px) and (min-width: 768px) {
          width: auto;
        }

        .title-filter {
          font-size: 1.1rem;
          padding: 1rem 2rem;

          @media only screen and (max-width: 768px) {
            display: none;
          }

          @media screen and (max-width: 990px) and (min-width: 768px) {
            display: none;
          }
        }

        .btn-filter {
          &.active {
            pointer-events: none;
          }

          button {
            height: 3.5rem;
            width: 24.1rem;
            border-radius: 8px;
            @include themify($themes) {
              background-color: themed('main');
            }
            border: none;
            cursor: pointer;
            color: $white;
            font-size: 1.4rem;
            font-weight: bold;

            &:hover {
              background-color: $blue-15;
              @include themify($themes) {
                color: themed('main');
              }
            }
          }
        }
      }
    }

    .hr {
      margin-top: 1.3rem;
      box-sizing: border-box;
      height: 0.1rem;
      border: 0.1rem solid #e7e7e7;
    }

    .container-header-btn {
      display: flex;
      flex-direction: row;
      padding-top: 1rem;
      justify-content: space-between;

      .checkbox-container {
        input[type='checkbox'] {
          @include themify($themes) {
            color: themed('main');
          }
          width: 1.1em;
          height: 1.1em;
          cursor: pointer;
        }

        input[type='checkbox']::before {
          border: 0.2rem solid $blue;
        }

        span {
          height: 3.5rem;
          width: 24.1rem;
          @include themify($themes) {
            color: themed('main');
          }
          font-size: 1.4rem;
          font-weight: bold;
          cursor: pointer;
        }
      }

      .div-delete {
        display: flex;
        flex-direction: row;
        gap: 1rem;

        .btn-delete {
          button {
            height: 3.5rem;
            width: 24.1rem;
            border-radius: 8px;
            background-color: $red;
            border: none;
            cursor: pointer;
            color: $white;
            font-size: 1.4rem;
            font-weight: bold;

            &:hover {
              background-color: $red-2;
            }
          }
        }
      }
    }
  }
}
