@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.sidebar-details {
  @include themify($themes) {
    color: themed('main');
    background-color: themed('secondary-3');
  }

  max-width: 100%;
  width: 100%;
  margin-bottom: 5rem;

  @media screen and (max-width: 768px) {
    max-width: none;
  }

  .header {
    min-height: 5rem;
    display: flex;
    align-items: center;

    @include themify($themes) {
      background: themed('categoriesSidebar', 'oddItem');
    }

    padding: 1rem;

    .icon {
      font-size: 2rem;
      cursor: pointer;

      &:hover {
        opacity: 80%;
      }
    }

    .return-button {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 14px;
      cursor: pointer;
    }

    .title {
      font-size: 1.2rem;
      padding: 0 0.5rem;
      font-weight: 700;
      text-align: center;
      width: 100%;

      span {
        display: block;
        font-size: 2rem;
        padding: 0.5rem 0;
      }
    }
  }

  .content {
    padding: 1rem 2rem;
    box-sizing: border-box;

    .items {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .item {
        font-size: 1.4rem;
        font-weight: bold;
        cursor: pointer;
        padding: 0 1rem;

        @include themify($themes) {
          color: themed('main');
        }

        &.active {

          @include themify($themes) {
            background-color: themed('main-2');
            color: $white;
          }

          padding: 0.5rem 1rem;
          border-radius: 1rem;
          box-sizing: border-box;
          width: fit-content;
          pointer-events: none;
        }

        &.no-pointer-events {
          pointer-events: all;
        }

        &:hover {
          opacity: 80%;
        }
      }
    }
  }
}