@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.part-catalog-wrapper.parts-wrapper {
  width: 100%;
  margin-bottom: 20px;

  @media screen and (min-width: 1300px) {
    margin-top: -40px;
  }
}

.parts-wrapper {
  display: flex;
  width: 90%;
  min-height: 100vh;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
  margin: 0 auto;

  .topContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;

    .logo {
      display: flex;
      //align-items: flex-end;
      gap: 20px;
      align-items: flex-start;

      img {
        max-height: 70px;
        align-self: center;
      }

      .buttonHome {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: none;
        width: 30px;
        height: 30px;
        @include themify($themes) {background: themed('mainHover');}
        align-self: center;
      }

      .img {
        @include themify($themes) {
          fill: themed('main');
        }
        width: 18px;
      }
    }

    .title {
      font-size: 24px;
      @include themify($themes) {
        color: themed('main');
      }
      margin-left: 200px;
    }

    .desc {
      font-size: 11px;
      @include themify($themes) {
        color: themed('main');
      }
    }

    .filter {
      display: flex;
      align-items: center;
      gap: 20px;

      .button {
        padding: 5px;
        @include themify($themes) {
          background-color: themed('main');
        }
        color: $white;
        border: none;
        font-weight: 500;
        width: 180px;
        height: 40px;
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }

  .separator {
    display: flex;
    justify-content: center;
    height: 1px;
    background: $gray;
    width: 100%;
  }

  .work-time {
    font-size: 14px;
    font-weight: 400;
    @include themify($themes) {
      color: themed('main');
    }
    cursor: pointer;
    position: relative;
  }

  .filterOptions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;
    padding: 1rem 0;
    z-index: 3;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 0.5rem;
      justify-content: space-evenly;
    }

    .filter-body-part {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 16rem;
    }

    .results {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      @include themify($themes) {
        color: themed('main');
      }
      margin: 1rem auto;
    }

    .clock-icon {
      margin-left: 1rem;
    }

    .drop {
      height: 30px;
      @include themify($themes) {background: themed('mainHover');}
      @include themify($themes) {border: 1px solid themed('mainHover');}
      border-radius: 5px;
      @include themify($themes) {
        color: themed('main');
      }
      font-weight: 200;
      width: 175px;
    }

    .sortIcons {
      display: flex;
      padding: 5px;
      gap: 10px;

      .icon {
        width: 18px;
        height: auto;
        display: flex;
        padding: 5px;
        @include themify($themes) {background: themed('mainHover');}
        @include themify($themes) {
          fill: themed('main');
        }
        border-radius: 5px;
        cursor: pointer;
      }
    }

    .iconLine {
      fill: $blue-5;
    }
  }

  .wrapperItems {
    display: grid;
    min-height: 70%;
    width: 100%;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fit, 30rem);
    position: relative;
    justify-content: center;
    margin: 0 auto;
    flex: 0.9;

    @media screen and (max-width: 990px) and (min-width: 768px) {
      grid-template-columns: repeat(auto-fit, 29rem);
    }

    .car-parts-spinner {
      background: none;
    }

    .arrowRight,
    .arrowLeft {
      &.disable {
        opacity: 50%;
      }

      position: fixed;
      width: 20px;
      height: 20px;
      top: 50%;
      left: 95%;
      fill: $blue-icon-color;
      cursor: pointer;
    }

    .arrowRight {
      right: -50px;
    }

    .arrowLeft {
      left: 3.5%;
    }
  }

  .no-parts-data {
    margin-top: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    svg {
      @media screen and (max-width: 768px) {
        width: 50%;
      }
    }

    & h1 {
      @include themify($themes) {
        color: themed('main');
      }
      align-self: center;
      justify-self: center;
      position: relative;
      font-size: 3rem;
    }
  }

  .nodata-wrapperItems {
    min-height: 70%;
    width: 100%;
    position: relative;
    margin: 0 auto;
    flex: 0.9;
  }

  .car-parts-spinner {
    background: none;
    position: relative;
  }
}

/* Responsive CSS Styles */

.app-layout-desktop {
}

.app-layout-tablet {
}

.app-layout-tablet,
.app-layout-mobile {
  .parts-wrapper {
    background: $white;
    width: 100%;
    min-height: initial;

    & > div {
      padding-left: 2rem;
      padding-right: 2rem;
      width: calc(100% - 4rem);
    }

    .universal-parts-navbar-container {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }

    .spinner {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    .separator {
      display: none;
    }

    .no-parts-data {
      margin-top: 0;

      svg {
        width: 50%;
      }
    }
  }
}

.app-layout-mobile {
  .parts-wrapper {
    .filterOptions {
      .filter-body-part {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }
}
