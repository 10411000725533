@import '../../assets/sass/variables';
@import 'src/assets/sass/themes';

.grid {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 5rem;
  padding-bottom: 3.75rem;

  .tabContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
  }

  .tabs {
    width: 60%;
    display: flex;
    justify-content: space-evenly;
    border-bottom: 0.125rem solid $gray;

    .equivalence {
      height: 1.563rem;

      @include themify($themes) {
        color: themed('main');
      }

      font-size: 1rem;
      cursor: pointer;
      display: flex;
      gap: 1rem;
    }

    .selected {
      border-bottom: 0.2rem solid;
      font-weight: 700;
    }
  }

  p {
    font-size: 1rem;
    font-weight: 600;
  }
}

.warapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}