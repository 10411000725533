@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.vehicle-info-container {
  height: auto;
  width: 65rem;
  border-radius: 1rem;
  @include themify($themes) {
    background-color: themed('main');
  }
  color: $white;
  letter-spacing: 0;
  text-align: left;
  padding: 1.5rem;
  z-index: 14;
  cursor: text;

  @media screen and (max-width: 768px) {
    width: 32rem;
    margin-top: 0.5rem;
  }

  .title {
    font-size: 2rem;
    font-weight: bold;
    line-height: 2.7rem;
  }

  .content {
    font-size: 1.4rem;
    line-height: 1.9rem;

    span {
      font-weight: bold;
    }

    ul {
      display: flex;
      flex-flow: row wrap;
      list-style-type: none;
      padding: 0;

      li {
        width: 50%;
      }
    }
  }
}
