@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.wrapper-quantity {
  display: flex;

  input {
    text-align: center;
    border: 0.1rem solid #6c757d;
  }

  input[type='number'] {
    -webkit-appearance: textfield !important;
    -moz-appearance: textfield !important;
    appearance: textfield !important;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .plusminus {
    width: 1.8rem;
    background: $white;
    font-size: 1.5rem;
    height: auto;
    cursor: pointer;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;

    @include themify($themes) {
      color: themed('main');
      background-color: themed('inputColor');
      border: 0.2rem solid themed('inputColor');
    }

    &.disable-add-btn {
      opacity: 50%;
      cursor: not-allowed;
    }
  }

  .num {
    height: auto;
    width: 3.5rem;
    border: none;
    font-size: 14px;
  }

  .wrapper-quantity-red {
    border: 1px solid #ca312a !important;
  }
}