@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.order-details-container {
  width: 100%;
  text-align: center;
  margin: 2rem auto;

  .order-details-header {
    display: flex;
    flex-direction: row;

    justify-content: space-between;

    & .back-btn {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 14px;
      cursor: pointer;

      @include themify($themes) {
        color: themed('main');
      }

      margin-top: 1.2rem;

      &:hover {
        opacity: 75%;
      }
    }

    & .export-btn {
      display: flex;
      align-items: center;
      margin-top: 2rem;

      .btn-export {
        @include themify($themes) {
          background-color: themed('main');
        }

        color: $white;
        border: none;
        border-radius: 0.5rem;
        padding: 0.5rem 2.5rem;
        font-weight: 700;
        height: 3.7rem;
        font-size: 1.3rem;
        outline: none;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-decoration: none;

        @media screen and (max-width: 990px) {
          height: 3.7rem;
        }

        .extra-small {
          width: 1.5rem;
          margin-right: 0.4rem;

          circle {
            stroke: $white;
          }
        }

        &:hover {
          @include themify($themes) {
            background-color: themed('bgActive');
          }

          @include themify($themes) {
            color: themed('main');
          }

          .extra-small {
            circle {
              @include themify($themes) {
                stroke: themed('main');
              }
            }
          }
        }

        .icon {
          margin-right: 0.4rem;
        }
      }

    }
  }


  .automotor-order-items {
    position: relative;
    min-height: 15rem;
  }

  & .title {
    margin-top: 4.7rem;
    font-weight: 700;
    font-size: 2rem;
    line-height: 3.3rem;

    @include themify($themes) {
      color: themed('main');
    }

    text-decoration: underline;

    @media screen and (max-width: 768px) {
      font-size: 2rem;
      line-height: 2.8rem;
    }
  }

  & .order-status {
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 3.3rem;
    margin-bottom: 4rem;

    @include themify($themes) {
      color: themed('main');
    }

    @media screen and (max-width: 768px) {
      font-size: 1.7rem;
      line-height: 2.8rem;
      padding: 0 1.5rem;
    }

    & span {
      font-weight: 700;
    }

    & .id {
      margin-right: auto;
    }

    & .status {
      margin-left: auto;
    }
  }

  .order-summary {
    width: 100%;
  }

  .order-summary table {
    & .cell {
      width: 50%;
    }

    & tr td:first-child.cell {
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }

  .automotor-order-items {
    margin-top: 2.8rem;
    width: 100%;
  }

  & .table-wrapper-tariff table .row:first-child .heading .table-title {
    background-color: transparent;
  }

  & .table-wrapper-tariff table .row:first-child .heading:last-child .table-title {
    height: 3.4rem;
  }

  .order-tables {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
}