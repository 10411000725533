@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.service-card-container {
  @include themify($themes) {
    background: themed('secondary-2');
  }
  width: 100%;
  min-height: 28rem;
  padding: 2rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  margin-bottom: 3rem;
  position: relative;

  & .service-spinner {
    top: 4rem;
  }

  & .header {
    & .title {
      font-size: 1.8rem;
      @include themify($themes) {
        color: themed('main');
        border-bottom: 0.1rem solid themed('separatorLine');
      }
      margin-bottom: 1.5rem;
      padding-bottom: 1.1rem;
    }
  }

  & .toggle-list {
    & .toggle-list-header {
      margin-top: 1.7rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      padding-bottom: 1rem;
      border-bottom: 0.1rem solid $blue-14;

      &.expanded {
        border: none;
      }

      &:hover {
        opacity: 80%;
        cursor: pointer;
      }

      & .toggle-text {
        @include themify($themes) {
          color: themed('main');
        }
        font-size: 1.6rem;
      }

      & .toggle-icon {
        font-size: 1.4rem;
        @include themify($themes) {
          color: themed('main');
        }
      }
    }

    & .content-container {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;

      & .toggle-list-content {
        & .toggle-list-item {
          display: flex;
          justify-content: space-between;

          & .toggle-list-actions {
            display: flex;
            gap: 1rem;
            align-items: center;
          }

          & .checkbox {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            @include themify($themes) {
              color: themed('main');
            }
            font-size: 1.4rem;
          }

          input[type='checkbox'] {
            appearance: none;
            background-color: $white;
            margin: 0;
            font: inherit;
            @include themify($themes) {
              color: themed('main');
            }
            width: 1.15em;
            height: 1.15em;
            @include themify($themes) {
              border: 0.15em solid themed('main');
            }
            border-radius: 0.15em;
            transform: translateY(-0.075em);
            display: grid;
            place-content: center;
            outline: none;
          }

          input[type='checkbox']::before {
            content: '';
            width: 0.65em;
            height: 0.65em;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em $blue-1;
            @include themify($themes) {
              box-shadow: inset 1em 1em themed('main');
            }
            transform-origin: bottom left;
            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
          }

          input[type='checkbox']:checked::before {
            transform: scale(1);
          }
        }
      }
    }
  }
}
