@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.account-dropdown {
  cursor: pointer;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 72px;
  width: 136px;
  margin-top: 0.5rem;
  gap: 0.5rem;
  @include themify($themes) {
    color: themed('main');
  }

  .text {
    white-space: nowrap;
    font-size: 1.3rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.8rem;
    @include themify($themes) {
      color: themed('navbarMecadepot', 'color');
    }
  }

  .myAccount {
    @include themify($themes) {
      color: themed('main');
    }
  }

  & .caret-icon {
    @include themify($themes) {
      color: themed('main');
    }
  }

  & .user-icon {
    font-size: 1.6rem;
    @include themify($themes) {
      color: themed('main');
    }
  }

  .show {
    .content-data {
      display: block;
    }
  }

  .hide {
    .content-data {
      display: none;
    }
  }

  .content-data {
    padding: 1rem 0;
    min-width: 25rem;
    position: absolute;
    border-radius: 0.4rem;
    top: 6.8rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    @include themify($themes) {
      background-color: themed('bgCustomDropdown');
    }

    .item {
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      text-decoration: none;
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      padding: 0 1rem;
      gap: 1rem;
      cursor: pointer;

      &:hover {
        @include themify($themes) {
          background-color: themed('mainHover');
        }
      }

      .icon {
        padding: 0.1rem;
      }

      .text {
        min-width: 10rem;
        font-size: 1.2rem;
      }
    }

    .active {
      width: auto;
      height: auto;
    }
  }
}
