@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.cart-availability-wrapper {
  .value {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    width: 100%;

    .item {
      width: 13px;
      height: 13px;
      border-radius: 50px;
    }
    .green {
      background: $green-4;
    }

    .orange {
      background: $orange;
    }
    .red {
      background: $red;
    }
  }
}
