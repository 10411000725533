@import '../../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.espace-automotor-order-container {
  display: block;
  max-width: $container-width-xlarge;
  margin: 0 auto;
  width: 100%;

  & .actions {
    display: flex;
    justify-content: center;
    gap: 3rem;
    flex-wrap: wrap;
    // margin: 15rem auto 1rem auto;
    margin: 7rem auto 1rem auto;

    @media screen and (max-width: 768px) {
      padding: 0 2rem;
    }
  }
}
