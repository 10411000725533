@import '../../assets/sass/variables';
@import 'src/assets/sass/themes';

.part-item-oe-list-info {
  width: 70rem;
  margin: 4rem 2rem;

  @media only screen and (max-width: 768px) {
    width: auto;
    height: calc(100% - 6rem);
    margin: 4rem 0 2rem;
  }

  .close-modal {
    position: absolute;
    right: 1.6rem;
    top: 1.6rem;
    font-size: 1.8rem;
    @include themify($themes) {
      color: themed('main');
    }

    @media only screen and (max-width: 768px) {
      top: 2rem;
      right: 2.6rem;
    }

    @media screen and (max-width: 990px) and (min-width: 768px) {
      top: 3.6rem;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .description {
    font-size: 1.4rem;
    @include themify($themes) {
      color: themed('main');
    }
    max-height: 80vh;
    overflow-y: auto;

    @media only screen and (max-width: 768px) {
      padding: 1rem;
      box-sizing: border-box;

      overflow-x: hidden;
    }

    .description-lable {
      padding-bottom: 0.5rem;

      span {
        font-weight: 600;
        font-size: 1.5rem;
      }
    }
  }

  .description-line {
    font-size: 1.6rem;
    font-weight: 600;
    @include themify($themes) {
      color: themed('main');
    }

    @media only screen and (max-width: 768px) {
      padding: 0 2rem;
      box-sizing: border-box;
      height: 100%;
      overflow: auto;
    }
  }
}

/* Responsive CSS Styles */

.app-layout-desktop {
}

.app-layout-tablet {
}

.app-layout-tablet,
.app-layout-mobile {
  .parts-wrapper {
    background: $white;
    width: 100%;
    min-height: initial;

    & > div {
      padding-left: 2rem;
      padding-right: 2rem;
      width: calc(100% - 4rem);
    }

    .universal-parts-navbar-container {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }

    .spinner {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    .separator {
      display: none;
    }

    .no-parts-data {
      margin-top: 0;

      svg {
        width: 50%;
      }
    }
  }
}

.app-layout-mobile {
}
