@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.add-client-container {
  max-height: 84vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100rem;

  @media screen and (max-width: 990px) {
    width: auto;
  }

  @media screen and (max-width: 768px) {
    max-height: 100vh;
  }

  .popup-wrapper {
    width: 100%;
    padding: 0 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .btns {
      width: 30rem;
      align-self: center;
    }

    .update-button {
      @include themify($themes) {
        background: themed('main');
      }
      border: none;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      gap: 15px;
      padding: 15px;
      cursor: pointer;
      font-size: 16px;
      width: 290px;
      margin: 10px auto;
      margin-bottom: 50px;

      .icon {
        color: $white;
        font-size: 16px;
      }
    }

    .separator {
      border: 1px $grey-3-o75 solid;
      position: relative;
      width: 100%;
    }

    .icon {
      font-size: 2.5rem;
      @include themify($themes) {
        color: themed('main');
      }
      cursor: pointer;
    }

    .close-btn {
      position: absolute;
      @include themify($themes) {
        color: themed('main');
      }
      right: 1.888rem;
      top: 1.638rem;
      font-size: 2.238rem;
      cursor: pointer;
      z-index: 2;

      @media screen and (max-width: 990px) and (min-width: 768px) {
        top: 3.638rem;
      }

      @media screen and (max-width: 768px) {
        top: 3rem;
        right: 4rem;
      }
    }

    textarea {
      max-width: 24rem;
      max-height: 100px;
      font-family: inherit;
      font-size: inherit;
      line-height: 2.5;
      resize: none;
      @media screen and (max-width: 990px) {
        max-width: 100%;
      }
    }

    .select-dropdown {
      @include themify($themes) {
        background-color: themed('secondary-3');
      }
      width: 22rem;
      border-radius: 10px;
      outline: none;
      height: 4.2rem;
      font-size: 1.5rem;
      @include themify($themes) {
        color: themed('main');
      }

      [class$='-control'] {
        @include themify($themes) {
          color: themed('main');
        }

        @include themify($themes) {
          background-color: themed('mainHover');
        }
        height: 4.2rem;
        box-shadow: none;
      }

      [class$='-placeholder'] {
        @include themify($themes) {
          color: themed('main');
        }
        font-size: 1.4rem;
        font-weight: 400;
      }

      [class$='-indicatorContainer'] {
        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-singleValue'] {
        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-indicatorSeparator'] {
        background: none;
        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-indicator'] {
        @include themify($themes) {
          background: themed('main');
        }
        color: $white;
      }
    }

    .select {
      p {
        @include themify($themes) {
          color: themed('main');
        }
        font-size: 1.4rem;
        line-height: 1.9rem;
        font-weight: 700;
        width: 100%;
        padding-left: 0.4rem;
        margin: 0 0 0.5rem 0;
      }

      select {
        border: none;
        @include themify($themes) {
          background-color: themed('secondary-3');
        }
        width: 22rem;
        border-radius: 10px;
        outline: none;
        height: 4.2rem;
        font-size: 1.5rem;
        @include themify($themes) {
          color: themed('main');
        }
      }
    }

    .popup-title {
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 2.55rem;
      line-height: 3.4rem;
      @include themify($themes) {
        color: themed('main');
      }
      padding: 2rem 13rem;
      padding-bottom: 0rem;
      position: relative;
      z-index: 1;

      .button {
        display: flex;
        cursor: pointer;
        margin: 1rem auto;
        border-radius: 0.8rem;
        font-weight: 700;
        border: none;
        line-height: 1.634rem;
        font-size: 1.2rem;
        height: 3.5rem;
        width: 40rem;
        @include themify($themes) {
          background-color: themed('main');
        }
        color: $white;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
      }

      span {
        font-weight: 700;
      }
    }

    .client-select {
      display: flex;
      min-width: 23rem;
      padding: 0;
      justify-content: flex-start;
      width: 100%;
      margin-left: -10px;

      @media screen and (max-width: 990px) and (min-width: 768px) {
        justify-content: center;
      }

      .radio {
        position: relative;
        margin: 0.3rem;

        input[type='radio'] {
          z-index: 2;
          cursor: pointer;
          position: absolute;
          width: 1.5em;
          height: 1.5em;
          opacity: 0;

          + label {
            &:before {
              content: '';
              background: $blue-10;
              border-radius: 100%;
              border: 2px solid $blue;
              display: inline-block;
              position: relative;
              top: -0.2em;
              margin-right: 0.5em;
              vertical-align: top;
              text-align: center;
              width: 1.5em;
              height: 1.5em;
            }
          }

          &:checked {
            + label {
              &:before {
                @include themify($themes) {
                  background-color: themed('main');
                }
              }
            }
          }
        }

        label {
          font-size: 1.4rem;
          line-height: 1.9rem;
          font-weight: 700;
          margin-bottom: 0.5rem;
          padding-left: 0.4rem;
          @include themify($themes) {
            color: themed('main');
          }
        }

        .check-icon {
          position: absolute;
          left: 10px;
          top: 3px;
          color: $blue-10;
          font-size: 14px;
        }
      }
    }

    .form {
      width: 100%;
      padding-top: 0.75rem;
      padding-bottom: 2.4rem;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .form-title {
        font-size: 11px;
        line-height: 2.7rem;
        text-transform: uppercase;
        margin: 0;
        color: $blue-text-color-o47;
      }

      .row {
        display: flex;
        width: 100%;
        gap: 3.2rem;
        &.subscription {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        & .subscription-date-picker {
          width: 100%;
          & > label {
            @include themify($themes) {
              color: themed('main');
            }
            font-size: 1.4rem;
            line-height: 1.9rem;
            font-weight: 700;
            width: 100%;
            margin-bottom: 0.5rem;
            display: inline-block;
            padding-left: 0.4rem;
          }
          & .custom-picker-dropdown {
            @media screen and (max-width: 990px) {
              width: 100%;
            }
          }
        }
        @media screen and (max-width: 768px) {
          gap: 1rem;
        }

        .create-container {
          margin: 1rem 0;
          @media screen and (max-width: 990px) {
            width: 100%;
          }
          label {
            @include themify($themes) {
              color: themed('main');
            }
            font-size: 1.4rem;
            line-height: 1.9rem;
            font-weight: 700;
            width: 100%;
            margin-bottom: 0.5rem;
            display: inline-block;
            padding-left: 0.4rem;
          }

          .text-input {
            border: none;
            padding: 0 1.1rem;
            @include themify($themes) {
              background-color: themed('secondary-3');
            }
            width: 24rem;
            border-radius: 10px;
            outline: none;
            height: 4.2rem;
            font-size: 1.5rem;
            @include themify($themes) {
              color: themed('main');
            }
            @media screen and (max-width: 990px) {
              width: 100%;
            }
          }

          .select-dropdown {
            width: 24rem;
            @media screen and (max-width: 990px) {
              width: 100%;
            }
          }

          .error-message {
            color: #ca312a !important;
            display: block;
            margin-top: 0.5rem;
            font-size: 1rem;
          }

          .logo-upload-button {
            display: flex;

            & .upload-image-btn {
              border: 1px solid $blue-1;
              padding: 1rem 1.5rem;
              border-radius: 0.3rem;
              @include themify($themes) {
                background-color: themed('secondary-3');
              }
              cursor: pointer;

              &:hover {
                opacity: 60%;
              }
            }
          }

          .logo-upload-container {
            display: flex;
            align-items: center;
            gap: 1rem;

            .logo-upload {
              width: 14rem;
              padding: 0 1.1rem;
              height: 4rem;
              margin: 0;
              display: flex;
              align-items: center;
              border: 1px solid $blue-12;
              @include themify($themes) {
                background-color: themed('secondary-3');
              }
              border-radius: 1rem;
              font-size: 1.4rem;
              line-height: 1rem;
              @media screen and (max-width: 990px) {
                width: 100%;
              }
              & .select-logo-title {
                @include themify($themes) {
                  color: themed('main');
                }
                line-height: 1.7rem;
              }

              & .uploaded-message-file {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                & .text-input {
                  text-overflow: ellipsis;
                  width: 9rem;
                }

                & .icon {
                  & img {
                    max-width: 3.8rem;
                    max-height: 3.8rem;
                  }
                }

                & .remove-client-img {
                  @include themify($themes) {
                    color: themed('main');
                  }
                  cursor: pointer;
                }
              }

              & div {
                padding: 0;
                margin: 0;
              }

              & svg {
                padding-left: 0.5rem;
              }

              div > input[type='text'] {
                height: 3rem !important;
                width: 12.6rem;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 990px) {
      padding: 3rem 4rem;
      box-sizing: border-box;

      .popup-title {
        padding: 0rem;
        margin: 3rem 0;
      }

      .update-button {
        transform: translate(-20px, 10px);
      }

      .form .row {
        justify-content: center;
        flex-direction: column;

        .container {
          label {
            font-size: 14px;
          }

          .text-input {
            width: 100%;
          }

          select {
            width: 100%;
          }

          .select-dropdown {
            width: 100%;
          }

          .logo-upload-container .logo-upload {
            font-size: 12px;
            width: calc(95% - 60px);

            .select-logo-title {
              line-height: 14px;
            }
          }

          textarea {
            max-width: 100%;
          }
        }
      }
    }
  }
}
