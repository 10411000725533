@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.industrial-tabs-container {
  width: 100%;

  .tab {
    display: flex;
    position: relative;
  }

  & .tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.7rem;

    & .tab-item {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.1rem solid #d7e5ff;
      @include themify($themes) {
        background-color: themed('secondary');
        color: themed('main');
      }
      width: 100%;
      height: 3.4rem;
      font-size: 1.3rem;
      font-weight: 600;
      cursor: pointer;
      .disabled {
        pointer-events: none;
        opacity: 50%;
        background-color: $blue-14;
      }

      .title {
        padding: 0 10px;
        height: 100%;
        display: flex;
        justify-content: center;
        color: $white;
      }

      &.active {
        @include themify($themes) {
          background-color: themed('tabActive');
          color: themed('tabActiveColor');
        }
      }
    }
  }
}
