@import "src/assets/sass/variables";
@import "src/assets/sass/themes";

.cart-sliding-v2 {
  position: fixed;
  top: 5.7rem;
  right: 0rem;
  overflow: hidden;
  z-index: 100;
  height: 0rem;
  border: 1px solid #ccc;
  border-radius: 5px;

  @media screen and (min-width: 1440px) {
    position: absolute;
    right: auto;
    transform: translateX(-65%);

  }

  @media screen and (max-width: 1440px) {

    top: 7.2rem;
  }

  @include themify($themes) {
    background-color: themed("slidingCart", "bg");
  }

  .cart-sliding-body {
    display: flex;
    justify-content: center;
    //position: fixed;
    width: 32rem;
    opacity: 0;
    visibility: hidden;
    /* Inițial invizibil */
    transition: opacity 1s ease;
    /* Tranziție pentru opacitate */
    text-align: center;

    @include themify($themes) {
      background-color: themed("slidingCart", "bg");
    }

    .cart-title {
      text-align: center;
      font-size: 1.6rem;
      margin-top: 0px;

      @include themify($themes) {
        border-bottom: 1px solid themed("separatorLine");
      }

      display: flex;
      justify-content: flex-end;
      width: 100%;

      @include themify($themes) {
        color: themed("slidingCart", "text");
      }

      p {
        width: 100%;
        margin: 1rem;
      }
    }

    .cart-sliding-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;

      .small {
        position: absolute;
        top: 8rem;
        height: auto;
      }

      .empty_cart {
        padding: 4rem 0rem;
        position: absolute;

        left: 50%;
        transform: translate(-50%, 40%);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        & .empty_cart_image {
          & img {
            margin-left: -10%;
            width: 100%;
            height: 100%;
            max-width: 24rem;
            max-height: 21rem;
          }
        }

        & .empty_cart_title {
          font-size: 2rem;
          font-weight: 700;
          margin-bottom: 1rem;

          @include themify($themes) {
            color: themed("slidingCart", "text");
          }

          @media screen and (max-width: 768px) {
            font-size: 1.5rem;
            white-space: nowrap;
          }
        }
      }

      .cart-content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        padding: 1rem 1rem;
        width: 100%;

        box-sizing: border-box;

        .table {
          width: 100%;
          display: flex;
          justify-content: center;
          overflow-y: auto;
          max-height: 25rem;

          table {
            width: 98%;
            text-align: center;
            border-radius: 30px;
            border-spacing: 0;

            thead {
              @include themify($themes) {
                color: themed("slidingCart", "color");
              }

              font-weight: 300;
              font-size: 1.3rem;

              tr th {
                position: sticky;
                /* make the table heads sticky */
                top: 0px;
                height: 3.5rem;
                padding: 0.4rem 0;

                @include themify($themes) {
                  background-color: themed("slidingCart", "tableRow");
                }
              }
            }

            tbody {
              @include themify($themes) {
                color: themed("slidingCart", "text");
              }

              font-weight: 300;
              font-size: 1.2rem;

              tr:nth-child(odd) {
                @include themify($themes) {
                  background-color: themed("slidingCart", "tableRow-2");
                }
              }

              tr:nth-child(even) {
                @include themify($themes) {
                  background-color: themed("slidingCart", "tableRow-3");
                }
              }

              tr td {
                height: 3rem;
                padding: 0.4rem 0;

                .img {
                  max-width: 4rem;
                  max-height: 4rem;
                }
              }
            }
          }
        }

        .table-spinner {
          margin-top: 1rem;
        }

        .tfooter {
          display: flex;
          justify-content: space-between;
          margin-top: 2rem;

          @include themify($themes) {
            color: themed("slidingCart", "text");
          }

          font-size: 1.4rem;
          font-weight: 800;
        }

        .btn {
          padding-top: 2rem;

          text-align: right;
        }
      }
    }
  }

  .show-sliding-body {
    display: block;
    opacity: 1;
    visibility: visible;
    position: sticky;

    &.fixed-position {
      position: sticky;
    }
  }

  .hidden-sliding-body {
    opacity: 0;
    visibility: hidden;
    height: 0rem;
    transition:
      opacity 0.3s,
      height 0.3s;
  }
}

// .cart-sliding-v2::before {
//   content: '';
//   position: absolute;
//   /* Schimbă de la absolute la fixed */
//   top: -2rem;
//   /* Poziționează săgeata la același nivel cu elementul părinte */
//   border-width: 10px;
//   border-style: solid;
//   border-color: transparent transparent #5e5a5a transparent;
//   z-index: 1000;
//   left: 50%;
//   visibility: visible;

// }

.show-sliding {
  height: auto;
  min-height: 25rem;
  z-index: 100;
  overflow: hidden;
  /* Ensure content doesn't overflow during transition */
  transition:
    opacity 0.3s,
    height 0.3s;
}

.hidden-sliding {
  height: 0;
  overflow: hidden;
  transition:
    opacity 0.3s,
    height 0.3s;
  visibility: hidden;
}