@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.proforma-file {
  display: flex;
  background-color: #eaf0fb;
  width: 35rem;
  height: 100%;

  @media screen and (max-width: 768px) {
    width: 100%;

  }

  &-mapping {
    padding: 5rem 3rem 0 3rem;
    overflow: hidden;

    @media screen and (max-width: 768px) {
      padding: 1rem 0rem 0 5rem;

    }

    .input-container {
      margin-bottom: 2rem;

      .custom-input {
        min-height: 1.3rem;
        display: flex;
        padding: 0;

        & .header-line-label {
          white-space: nowrap;
          margin: auto 1rem auto 0;
          font-size: 1.4rem;

          @include themify($themes) {
            color: themed('main');
          }

          &.disabled {
            color: gray;
          }
        }

        & .header-line-input {
          @include themify($themes) {
            color: themed('main');
          }

          background-color: $blue-14;
          border: none;
          border-radius: 0.5rem;
          height: 3rem;
          width: 5rem;

          &.disabled {
            color: gray;
            background-color: lightgray;
          }
        }
      }

      .waring-message {
        margin-top: 1rem;
        color: red;
      }
    }

    .proforma-file-columns {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 2rem;
      width: 100%;

      &-container {
        height: 45rem;
        overflow-y: auto;

        @media screen and (max-width: 768px) {
          height: auto;
          padding-bottom: 2rem;
        }
      }
    }

    &>.proforma-file-title {
      font-size: 2.4rem;
      font-weight: 500;
      margin-bottom: 2rem;

      @include themify($themes) {
        color: themed('main');
      }
    }

    &>.proforma-file-subtitle {
      font-size: 1.8rem;
      font-weight: 500;

      @include themify($themes) {
        color: themed('main');
      }

      margin-bottom: 1.6rem;
    }

    .proforma-file-columns-title {
      font-size: 1.8rem;
      font-weight: 500;

      @include themify($themes) {
        color: themed('main');
      }

      margin-top: 5rem;
      margin-bottom: 1.6rem;
    }

    &>.proforma-file-upload-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: $blue-14;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='cornflowerblue' stroke-width='5' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      border-radius: 1rem;
      width: 18rem;
      padding: 1.5rem 5.3rem;

      &>.proforma-folder-upload {
        @include themify($themes) {
          color: themed('main');
        }

        font-size: 4rem;
      }

      &>.proforma-title-upload {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 1.6rem 0;

        &>.browse-files {
          cursor: pointer;
        }
      }

      &>.proforma-subtitle-upload {
        font-size: 0.9rem;
        color: #8f95a5;
      }

      &.proforma-disabled-file {
        background-color: #ebecf0;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='lightgrey' stroke-width='5' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      }
    }
  }
}

.proforma-file-upload-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  position: relative;
  backdrop-filter: blur(0.25rem);
}

.proforma-disabled-text {
  color: #828282 !important;
  pointer-events: none;
}

.proforma-disabled-icon {
  color: #828282 !important;
}