@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.table-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  & .sellers-table-spinner {
    background: none;
    position: absolute;
    top: -6rem;
  }

  &.sellers-table-wrapper {
    min-height: 0;
  }

  table {
    .body {
      .cell-row {
        &:hover {
          opacity: 50%;
          cursor: pointer;
        }

        .cell {
          padding: .85rem .4rem .85rem 1.4rem;
          max-width: max-content;

          @include themify($themes) {
            background-color: themed('secondary-3');
          }



          @include themify($themes) {
            color: themed('main');
          }
        }
      }

      .no-data {
        font-size: 2.4rem;
        font-weight: bold;

        @include themify($themes) {
          color: themed('main');
        }

        opacity: 60%;
      }
    }
  }
}