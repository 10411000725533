@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.standard-dropdown {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 4.2rem;

  @include themify($themes) {
    background-color: themed('secondary-3');
  }

  @include themify($themes) {
    color: themed('main');
  }

  border-radius: 0.5rem;
  gap: 1rem;
  position: relative;

  .selected-data {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;

    .info {
      display: flex;
      gap: 1rem;
      align-items: center;

      .selected-image {
        min-height: 1.3rem;
      }

      .selected-value {
        font-size: 1.5rem;
        line-height: 2rem;
        text-align: left;
      }
    }
  }

  .show {
    display: block;
  }

  .hide {
    display: none;
  }

  .content-data {
    overflow-y: scroll;
    max-height: 25rem;
    min-width: 20rem;

    @include themify($themes) {
      background-color: themed('bgCustomDropdown');
    }

    position: absolute;
    border-radius: 0.4rem;
    top: 4rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    .item {
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      padding: 0 1rem;
      gap: 1rem;
      cursor: pointer;

      &:hover {
        @include themify($themes) {
          background-color: themed('mainHover');
        }
      }

      .icon {
        padding: 0.1rem;
      }

      .image {
        padding: 0.1rem;
        min-height: 1.3rem;
      }

      .text {
        font-size: 1.5rem;
        line-height: 2rem;
        text-align: left;
        min-width: 10rem;
      }
    }
  }
}