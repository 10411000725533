@import "assets/sass/variables";
@import "src/assets/sass/themes";

.container-wrapper-v2 {
  .container {
    max-width: $container-width-xlarge;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;

    .header {
      position: sticky;
      top: 0;
      z-index: 10;

      // @media screen and (max-width: 768px) {
      //   z-index: 0;
      // }

      >div {
        position: relative;
      }
    }

    .sidebar {
      position: absolute;
      z-index: 11;
      left: 0;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    @media screen and (max-width: 1260px) {
      max-width: $container-width-xlarge;
    }

    @media screen and (max-width: 990px) {
      height: initial;
    }
  }

  .container-fluid-large {
    max-width: $container-width-large;
    margin: 0 auto;
  }

  .disabled {
    position: relative;
    pointer-events: none;
    cursor: not-allowed;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 0;
    opacity: 30%;
  }

  .disabled::before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
  }

  .body {
    position: relative;
    z-index: 9;
    height: 100%;
    //max-width: 126rem;
    //margin: 0 auto;
    //padding-left: 3rem;
    //padding-right: 3rem;

    @media screen and (max-width: 990px) {
      height: initial;
    }
  }

  .footer {
    display: block;
    margin-top: auto;
    z-index: 14;
  }
}

.container-wrapper-v2 {
  &>.container {
    &>.body {
      // min-height: calc(100vh - 10.6rem);
      min-height: 100vh;
      margin-bottom: 4rem;
    }
  }
}

@media screen and (min-width: 990px) {
  ::-webkit-scrollbar {
    width: 0.3rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    @include themify($themes) {
      background-color: themed("main");
    }

    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @include themify($themes) {
      background-color: themed("main");
    }
  }

  .theme-mecadepot {
    .cart-sliding-v2 {

      @media screen and (min-width: 1440px) {

        transform: translateX(-42%) !important;
      }
    }

    .car-part-view-modal {
      .car-part-view-modal-body {
        max-height: calc(100vh - 10rem);
        overflow-y: auto;

        width: 140rem;

        @media screen and (min-width: 990px) and (max-width: 1400px) {
          width: auto;
        }

        background: $grey-11;

        .car-part-options-wrapper .top .iconButton.favorite {
          padding-right: 0rem;
          background-color: $grey-11;
        }

        .equivalence-oem-item {
          background-color: $white;
        }

        .vehicle-equivalence-item-list {
          background-color: $white;
        }

        .spinner {
          background: transparent;
        }

        .equivalance-oem-container .content .filters .manufacturers .select-element [class$="-control"] {
          color: #171818;
          background-color: $white;
        }

        .equivalence-vehicle .content .filters .manufacturers .select-element [class$="-control"] {
          color: #171818;
          background-color: $white;
        }
      }

      .header-close {
        display: flex;
        justify-content: flex-end;
        padding: 1rem;

        @include themify($themes) {
          color: themed("main");
        }

        font-size: 1.6rem;
        cursor: pointer;

        &:hover {
          opacity: 75%;
        }
      }
    }



    .tyres-wrapper {
      .separator {
        position: sticky;
        top: 13.5rem;
        z-index: 30;
      }

      .filterOptions {
        position: sticky;
        top: 13.6rem;
        z-index: 20;
        background-color: $white;

        .text-input-container {
          width: 52%;
        }

        .parts-general-input {
          width: 100%;

          .parts-input {
            width: 100% !important;
            margin-left: 31%;
          }
        }
      }

      .catalog-tyres-sidebar {
        .list-container {
          position: sticky;
          top: 19.8rem;
        }
      }

      .catalog-navbar-container {
        position: sticky;
        top: 7.2rem;
        z-index: 30;
        background-color: $white;

        .breadcrumbs {
          width: 100%;
        }
      }
    }

    .top_sales-wrapper {
      .separator {
        position: sticky;
        top: 13.5rem;
        z-index: 30;
      }

      .filterOptions {
        position: sticky;
        top: 13.6rem;
        z-index: 20;
        background-color: $white;

        .text-input-container {
          width: 52%;
        }

        .parts-general-input {
          width: 100%;

          .parts-input {
            width: 100% !important;
            margin-left: 31%;
          }
        }
      }

      .catalog-tyres-sidebar {
        .list-container {
          position: sticky;
          top: 19.8rem;
        }
      }

      .catalog-navbar-container {
        position: sticky;
        top: 7.2rem;
        z-index: 30;
        background-color: $white;

        .breadcrumbs {
          width: 100%;
        }
      }
    }

    .catalog-oils-wrapper {
      .oil-navbar-container {
        position: sticky;
        top: 7.2rem;
        z-index: 30;
        background-color: $white;

        .breadcrumbs {
          width: 100%;
        }
      }

      .separator {
        position: sticky;
        top: 13.5rem;
        z-index: 30;
      }

      .filterOptions {
        position: sticky;
        top: 13.6rem;
        z-index: 20;
        background-color: $white;

        .text-input-container {
          width: 52%;
        }

        .parts-general-input {
          width: 100%;

          .parts-input {
            width: 100% !important;
            margin-left: 31%;
          }
        }
      }

      .catalog-oil-sidebar {
        .list-container {
          position: sticky;
          top: 19.8rem;
        }
      }
    }

    .consumables-wrapper {
      .consumable-navbar-container {
        position: sticky;
        top: 7.2rem;
        z-index: 30;
        background-color: $white;

        .breadcrumbs {
          width: 100%;
        }
      }

      .separator {
        position: sticky;
        top: 13.5rem;
        z-index: 30;
      }

      .filterOptions {
        position: sticky;
        top: 13.6rem;
        z-index: 20;
        background-color: $white;

        .text-input-container {
          width: 52%;
        }

        .parts-general-input {
          width: 100%;

          .parts-input {
            width: 100% !important;
            margin-left: 31%;
          }
        }
      }

      .catalog-consumables-sidebar {
        .list-container {
          position: sticky;
          top: 19.8rem;
        }
      }
    }

    .batteries-wrapper {
      .catalog-navbar-container {
        position: sticky;
        top: 7.2rem;
        z-index: 30;
        background-color: $white;

        .breadcrumbs {
          width: 100%;
        }
      }

      .separator {
        position: sticky;
        top: 13.5rem;
        z-index: 30;
      }

      .filterOptions {
        position: sticky;
        top: 13.6rem;
        z-index: 20;
        background-color: $white;

        .text-input-container {
          width: 52%;
        }

        .parts-general-input {
          width: 100%;

          .parts-input {
            width: 100% !important;
            margin-left: 31%;
          }
        }
      }

      .catalog-batteries-sidebar {
        .list-container {
          position: sticky;
          top: 19.8rem;
        }
      }
    }

    .catalog-vehicle-layout {
      .catalog-vehicle-header-wrapper {
        position: sticky;
        top: 7.2rem;
        z-index: 30;


        background-color: $white;
      }

      .catalog-vehicle-page {
        .catalog-vehicle-header-wrapper {
          position: sticky;
          top: 21.6rem;
          z-index: 21;
          width: 85%;
          background-color: $white;

          @media screen and (min-width: 1370px) {
            width: 86%;
          }

          @media screen and (max-width: 1370px) and (min-width: 990px) {
            width: 80%;
          }
        }

        .universal-parts-navbar-container {
          position: sticky;
          top: 21rem;
          z-index: 20;
          background-color: $white;
        }

        .filterOptions {
          position: sticky;
          top: 27.1rem;
          z-index: 20;
          background-color: $white;
        }
      }
    }

    .parts-wrapper {
      .universal-parts-navbar-container {
        position: sticky;
        top: 7.2rem;
        z-index: 30;
        background-color: $white;

        .breadcrumbs {
          width: 100%;
        }
      }

      .separator {
        position: sticky;
        top: 13.5rem;
        z-index: 30;
      }

      .filterOptions {
        position: sticky;
        top: 13.6rem;
        z-index: 20;
        background-color: $white;

        .text-input-container {
          width: 52%;
        }

        .parts-general-input {
          width: 100%;

          .parts-input {
            width: 100% !important;
            margin-left: 31%;
          }
        }
      }
    }

    .catalog-parts-search {
      @media screen and (min-width: 990px) {
        position: sticky;
        top: 20.4rem;
        right: 0;
        z-index: 40;
      }
    }
  }
}