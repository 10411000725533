@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.order-messages-wrapper {
  font-family: 'Open sans', sans-serif;
  min-width: 40rem;

  .messages-container {
    min-height: auto;
  }

  .messages-container {
    border-radius: 1rem;
    margin: 0 auto;
    position: relative;
    min-height: auto;

    @include themify($themes) {
      background-color: themed('secondary');
    }

    @include themify($themes) {
      color: themed('main');
    }

    .reply-container {
      padding: 1rem 1.5rem 2.5rem 1.5rem;
    }

    @media screen and (min-width: 990px) {
      ::-webkit-scrollbar {
        position: relative;
        margin-right: -50.5px;
      }
    }

    @media screen and (min-width: 768px) and (max-width: 990px) {
      min-height: 100vh;
    }

    .content {
      .supplier {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 1.5rem;

        .info {
          position: relative;
          display: inline-block;

          svg.fa-comment {
            padding-right: 1rem;
          }

          span {
            word-break: break-all;
          }

          font-size: 1.8rem;
          line-height: 2.4rem;
          text-align: center;

          .spinner {
            display: inline-block;

            svg {
              padding: 0;
              height: 2rem;
            }
          }
        }
      }

      .messages {
        z-index: 1;
        height: 10rem;
        position: relative;
        width: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;

        .messages-list {
          margin-top: auto;
          margin-right: 1.5rem;
          margin-left: 1.5rem;

          .loading-old-messages {
            min-height: 10rem;
            position: relative;
          }
        }

        #scrollableDiv {
          padding-right: 2rem;
          height: 50rem;
          position: relative;

          .extra-small {
            height: 3rem;
          }
        }

        .no-messages {
          font-size: 1.5rem;
          text-align: center;
        }
      }
    }
  }
}