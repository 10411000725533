@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.form-container-section-account-financial {
  display: flex;
  justify-content: center;
  padding-bottom: 5rem;
  max-width: 132rem;
  align-items: center;
  margin: 1rem auto;
  width: 100%;

  .container-section-account-financial {
    width: 100%;

    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 2rem;
      box-sizing: border-box;
    }

    @media screen and (min-width: 768px) and (max-width: 990px) {
      width: 100%;
      padding: 4rem;
      box-sizing: border-box;
    }

    .returnButton {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: 1.6rem;

      @include themify($themes) {
        color: themed('main');
      }

      cursor: pointer;

      .icon {
        font-size: 1.6rem;
        cursor: pointer;

        @include themify($themes) {
          color: themed('main');
        }
      }
    }

    .title {
      text-align: center;
      font-style: normal;
      font-weight: 700;
      font-size: 2rem;
      line-height: 3.4rem;
      margin: 1.9rem auto 2.95rem;
      min-width: 100%;

      @include themify($themes) {
        color: themed('main');
      }

      @media screen and (max-width: 768px) {
        font-size: 1.75rem;
        margin: 1rem;
      }
    }

    .filter-btns {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 3rem;
      margin-bottom: 1rem;

      .button-filter-financial {
        @include themify($themes) {
          background-color: themed('secondary-3');
        }

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        border: 1px solid #7285b8;
        border-radius: 5rem;
        width: 100%;
        padding: 0.9rem 2rem;

        @include themify($themes) {
          color: themed('main');
        }

        font-size: 1.4rem;
        cursor: pointer;

        .icon {
          @include themify($themes) {
            color: themed('main');
          }

          font-size: 1.6rem;
        }
      }

      .export-btn {
        @include themify($themes) {
          background-color: themed('secondary-3');
        }

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        border: 1px solid #7285b8;
        border-radius: 5rem;
        width: 100%;
        padding: 0.9rem 2rem;

        @include themify($themes) {
          color: themed('main');
        }

        font-size: 1.4rem;
        cursor: pointer;

        .icon {
          @include themify($themes) {
            color: themed('main');
          }

          font-size: 1.6rem;
        }
      }
    }

    .financial-filter {
      width: 100%;

      @media screen and (max-width: 768px) {
        background-color: $background-grey;
        height: calc(100% - 9rem);
        width: 0;
        position: absolute;
        z-index: 10;
        top: 9rem;
        left: 0;
        overflow-x: hidden;
        transition: 0.5s;
        border: none;
        border-radius: 0rem;
        margin-bottom: 0rem;
        margin-top: 0rem;
      }
    }

    .hidden-filter-financial {
      width: 0%;
    }

    .show-filter-financial {
      width: 100%;
    }

    .close-btn {
      @include themify($themes) {
        color: themed('main');
      }

      font-size: 1.6rem;
      font-weight: 900;
      width: 95%;
      padding-top: 1rem;
      display: flex;
      justify-content: right;
      align-items: center;
    }

    .client-info {
      border-radius: 1rem;
      border: 1px solid #cfd9ee;
      min-height: 10rem;
      margin-bottom: 2rem;

      .not-data-client {
        font-size: 1.6rem;

        @include themify($themes) {
          color: themed('main');
        }

        text-align: center;
        margin: 1rem;
      }

      & .client-title {
        font-size: 2rem;

        @include themify($themes) {
          color: themed('main');
        }

        font-weight: bold;
        text-align: center;
        margin-top: 3rem;
      }

      .financial-info-client {
        display: flex;
        flex-direction: row;
        justify-content: center;
        justify-content: space-around;

        @media screen and (max-width: 768px) {
          flex-direction: column;
        }

        .row-client {
          display: flex;
          flex-direction: column;
          padding: 1rem;
          text-align: center;

          .label-client {
            font-size: 1.5rem;

            @include themify($themes) {
              color: themed('main');
            }

            font-weight: bold;
            text-align: center;
          }

          .label-number {
            font-size: 1.4rem;

            @include themify($themes) {
              color: themed('main');
            }

            padding: 1rem;

            .label-number-year {
              display: flex;
              flex-direction: row;
              justify-content: space-around;

              .label-year {
                display: flex;
                flex-direction: column;
                padding: 0rem 1rem 0rem 1rem;
              }
            }
          }
        }
      }
    }

    .filter {
      height: 13rem;
      border-radius: 1rem;
      border: 1px solid #cfd9ee;
      margin-bottom: 2rem;
      margin-top: 2.5rem;

      @media screen and (max-width: 768px) {
        height: auto;
        border: none;
        margin-top: 5.5rem;
      }

      .spinner.extra-small svg {
        width: 1.3rem;
      }

      & .filter-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2.1rem 2.4rem;

        @media screen and (max-width: 768px) {
          flex-direction: column;
        }

        & .filter-title {
          font-size: 1.4rem;

          @include themify($themes) {
            color: themed('main');
          }

          font-weight: bold;
        }

        & .filter-reliquats {
          display: flex;
          align-items: center;
          font-size: 1.2rem;

          @include themify($themes) {
            color: themed('main');
          }
        }

        & .filter-link-btn {
          @media screen and (max-width: 768px) {
            display: none;
          }

          .export-btn {
            @include themify($themes) {
              background-color: themed('secondary-3');
            }

            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            border: 1px solid #7285b8;
            border-radius: 5rem;
            width: 100%;
            padding: 0.9rem 2rem;

            @include themify($themes) {
              color: themed('main');
            }

            font-size: 1.4rem;
            cursor: pointer;

            .icon {
              @include themify($themes) {
                color: themed('main');
              }

              font-size: 1.6rem;
            }
          }
        }
      }

      & .filter-body {
        display: flex;
        flex-direction: row;
        gap: 1.4rem;
        padding: 0 2.4rem;

        @media screen and (max-width: 768px) {
          flex-direction: column;
        }

        & .filter-input-item {
          width: 100%;
          position: relative;

          & .react-picker-dropdown {
            cursor: pointer;

            &.disable {
              pointer-events: none;
            }
          }

          & .date-picker {
            position: absolute;
            z-index: 101;
            top: 5rem;

            @media screen and (max-width: 768px) {
              width: 100%;
            }

            & .react-datepicker {
              display: flex;
              position: relative;
              border: none;
              box-shadow: 0.5rem 0.8rem 2.1rem rgb(0 0 0 / 15%);
              font-size: 1.3rem;

              &__month-container {
                & .react-datepicker__header {
                  background: none;
                  border-bottom: none;
                  padding: 1.2rem 1rem;

                  & .react-datepicker__current-month {
                    font-size: 1.4rem;
                  }

                  & .react-datepicker__day-names {
                    & .react-datepicker__day-name {
                      width: 3rem;
                      line-height: 4rem;
                    }
                  }
                }

                & .react-datepicker__week {
                  .react-datepicker__day {
                    width: 3rem;
                    line-height: 3rem;

                    &--in-range {
                      background-color: #d73c5a;
                    }

                    &--selected {
                      background-color: #d73c5a;
                    }

                    &--in-selecting-range {
                      background-color: #f5bec7;
                    }

                    &--keyboard-selected {
                      background-color: #d73c5a;
                    }
                  }
                }
              }

              .react-datepicker__month-container {
                @media screen and (max-width: 768px) {
                  width: 100%;
                }
              }
            }
          }

          & .select-dropdown {
            width: 100%;
            height: 4.2rem;
            border-radius: 0.5rem;
            font-size: 1.4rem;
            font-weight: 400;

            @include themify($themes) {
              color: themed('main');
            }

            border: none;

            &.disabled {
              opacity: 30%;
              pointer-events: none;
              user-select: none;
            }

            [class$='-control'] {
              @include themify($themes) {
                color: themed('main');
              }

              border: none;

              @include themify($themes) {
                background-color: themed('mainHover');
              }

              height: 4.2rem;
              box-shadow: none;

              &:hover {
                border: none;
              }
            }

            [class$='-placeholder'] {
              @include themify($themes) {
                color: themed('main');
              }

              font-size: 1.4rem;
              font-weight: 400;
            }

            [class$='-indicatorContainer'] {
              @include themify($themes) {
                color: themed('main');
              }
            }

            [class$='-singleValue'] {
              @include themify($themes) {
                color: themed('main');
              }
            }

            [class$='-indicatorSeparator'] {
              background: none;

              @include themify($themes) {
                color: themed('main');
              }
            }

            [class$='-indicator'] {
              @include themify($themes) {
                background: themed('main');
              }

              color: $white;
            }
          }
        }
      }
    }

    .table-wrapper {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      min-height: 9rem;

      & .sellers-table-spinner {
        background: none;
        position: absolute;
      }

      table {
        padding: 1rem 2.5rem;

        @media screen and (max-width: 768px) {
          padding: 1rem;
        }

        .thead {
          tr {
            th {
              padding: 5px;
            }
          }
        }

        .body {
          .body-row-espace-financial {
            @include themify($themes) {
              background-color: themed('secondary-3');
            }

            &:hover {
              opacity: 50%;
              cursor: pointer;
            }

            .cell {
              padding: 0.85rem 0.4rem 0.85rem 1.4rem;

              &:first-child {
                border-radius: 0.5rem 0 0 0.5rem;
              }

              &:last-of-type {
                border-radius: 0 0.5rem 0.5rem 0;
                background-color: $blue-5;
              }
            }
          }

          .notFond {
            text-align: center;
            font-size: 1.8rem;
            font-weight: bold;

            @include themify($themes) {
              color: themed('main');
            }

            opacity: 60%;
          }
        }
      }
    }
  }
}