@import "assets/sass/_variables.scss";

.select-client-tabs-container {
  width: 100%;

  .tab {
    display: flex;
    position: relative;
    flex-direction: column;
  }

  & .tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.7rem;

    & .tab-item {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.1rem solid #d7e5ff;
      background-color: $white-1;
      width: 100%;
      height: 3.4rem;
      color: $blue-1;
      font-size: 1.3rem;
      font-weight: 600;
      cursor: pointer;

      @media screen and (max-width: 768px) {
        text-align: center;
        padding: 5px;
      }

      .disabled {
        pointer-events: none;
        opacity: 50%;
        background-color: $blue-14;
      }

      .title {
        padding: 0 10px;
        height: 100%;
        display: flex;
        justify-content: center;
        color: $white;
      }

      &.active {
        background-color: $blue;
        color: #d7e5ff;
      }
    }
  }
}
