@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.provider-modal {
  padding: 0.5rem 1rem;

  .provider-modal-header {
    display: flex;
    align-items: center;
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 1.3rem;
    justify-content: space-between;
    padding-bottom: 1rem;
    box-sizing: border-box;
    border-bottom: 1px solid $blue-14;

    & .provider-modal-close {
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        opacity: 75%;
      }
    }
  }
  .provider-modal-body {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 1rem;
    padding: 1rem 0;
    box-sizing: border-box;
    width: 100%;

    & .franco-input {
      width: 100%;
    }
    & .provider-modal-save {
      min-width: 8rem;
      max-width: fit-content;
      width: 100%;
    }
  }
}
