@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.proforma-mapping-container {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.proforma-left {
  display: flex;
  flex-direction: column;
  width: 100%;

  img {
    width: 100%;
    height: 25rem;
  }
}

.proforma-mapping-file-container {
  display: flex;
  justify-content: flex-end;
}

.proforma-mapping-content {
  margin-top: -15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .proforma-mapping {
    width: 100%;
    display: flex;
    flex-direction: column;

    &-body {
      display: flex;
      width: 100%;
    }
  }

  .proforma-mapping-save {
    display: flex;
    margin: 1.3rem 4.3rem;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    &>.proforma-header-title-container {
      &>h1 {
        color: white;
        font-size: 2.5rem;
      }

      &>h6 {
        color: white;
        font-weight: 400;
      }
    }

    &label {
      margin-bottom: 0;
    }

    &-btn {
      border: none;
      color: #fff;
      width: 14rem;
      height: 4.5rem;
      border-radius: 0.4rem;
      background-color: #cd544d;
    }
  }

  .proforma-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & .proforma-header-save-btn {
      width: 100%;
      max-width: 15rem;
    }
  }

  .enable-mapping {
    position: relative;
    display: inline-block;
    width: 3.6rem;
    height: 2rem;
    margin: 0 1rem;
  }

  .enable-mapping input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(128, 128, 128, 0.475);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 1.6rem;
    width: 1.6rem;
    left: 0.2rem;
    bottom: 0.2rem;
    background-color: $white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked+.slider {
    @include themify($themes) {
      background-color: themed('main');
    }
  }

  input:focus+.slider {
    box-shadow: 0 0 1px #222d4a;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 3.4rem;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.disabledBtn:disabled {
  background-color: #8f95a5;
  cursor: not-allowed;
}

.spinner-sending-data {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  position: relative;
}