@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.table-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &.stock-table-wrapper {
    min-height: 0;
    overflow: auto;

    table {
      border-spacing: 0 .7rem;
      color: #27509f;
      font-family: Open Sans, sans-serif;
      font-size: 1.2rem;
      letter-spacing: normal;
      line-height: 1.7rem;
      text-align: left;


      .headings {
        .row {
          .heading {
            color: $blue;

            font-weight: bold;
            text-align: left;
            font-size: 1.2rem;
            padding: .4rem .4rem .4rem 1.4rem;
          }
        }
      }

      .body {
        background-color: $white-5;

        .row-stock {
          background-color: #e4ebf7;

          &:hover {
            opacity: 50%;
            cursor: pointer;
          }

          .cell {
            padding: .85rem .4rem .85rem 1.4rem;
          }

          .cell:last-of-type {
            background-color: #cfd9ee;
            border-radius: 0 .5rem .5rem 0;
          }

          .action {
            display: flex;
            justify-content: right;

            span {
              padding-right: 1rem;
              font-size: 1.7rem;
            }

            span:last-child {
              color: #ca312a;
            }
          }
        }



        .no-data {
          font-size: 1.6rem;
          font-weight: bold;

          @include themify($themes) {
            color: themed('main');
          }

          opacity: 60%;
          text-align: center;
        }
      }
    }
  }


}