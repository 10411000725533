@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.error-codes-navbar-links {
  padding: 2rem 0 0;

  .navbar-links {
    display: flex;
    justify-content: center;
    padding: 1rem 0 0;
    border-bottom: 0.1rem solid $blue-16;
    margin-bottom: 2rem;
    gap: 2rem;

    .navbar-link {
      display: inline-block;
      min-width: 13.6rem;
      height: 2.8rem;
      line-height: 2rem;

      @include themify($themes) {
        color: themed('main');
      }

      cursor: pointer;
      font-size: 1.3rem;
      letter-spacing: 0;
      text-align: center;
      outline: none;

      a {
        @include themify($themes) {
          color: themed('main');
        }

        text-decoration: none;
        padding: 0.5rem 1.2rem;

        svg {
          margin-right: 0.3rem;
        }

        &.active {
          @include themify($themes) {
            border-bottom: 0.4rem solid themed('main');
          }
        }

        &.disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      }
    }
  }
}