@import './../../../../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.wrapper-ebooks {
  z-index: 2;
  max-width: 35rem;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  @include themify($themes) {
    background-color: themed('secondary');
  }

  @media only screen and (max-width: 768px) {
    height: 100vh;
    position: fixed;
    max-width: 100%;
  }

  & .icon {
    font-size: 2rem;
    float: right;
    padding: 1.5rem;
    cursor: pointer;
    @include themify($themes) {
      color: themed('main');
    }
  }

  .title {
    font-size: 2.8rem;
    font-weight: 700;
    padding: 0 1rem;
    @include themify($themes) {
      color: themed('main');
    }
  }

  .listItem {
    gap: 1rem;
    font-weight: 700;
    padding: 0 10px;
  }
}

.wrapper--x2 {
  min-width: 50vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  @include themify($themes) {
    color: themed('main');
    background-color: themed('secondary');
  }

  .header {
    min-height: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding: 1rem;

    .icon {
      font-size: 2.4rem;
      cursor: pointer;
    }

    .returnButton {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: 1.4rem;
    }
  }

  .content {
    padding: 5rem;

    .items {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .item {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .title {
          padding: 0;
          font-size: 2rem;
          font-weight: 700;
        }

        .item {
          font-size: 1.4rem;
        }
      }
    }
  }
}
