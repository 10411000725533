@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.plate-dropdown-content {
  position: absolute;
  background-color: $hovered-blue;
  border-radius: 1rem;
  margin-top: 0.2rem;
  z-index: 1;
  box-shadow: 0.2rem 0.2rem 1rem rgb(1 1 1 / 15%);
  min-width: 100%;
  font-size: 1.4rem;
  font-weight: bold;
  width: 36rem;
  overflow-y: auto;
  max-height: 30rem;

  & .plate-dropdown-header {
    padding: 1.3rem 0.6rem 0.4rem 0.6rem;
    border-bottom: 1px solid $blue-5;

    & .plate-dropdown-title {
      @include themify($themes) {
        color: themed('main');
      }
    }
  }

  & .plate-search-result-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & .skeleton-line {
      margin: 0.6rem;
    }

    & .no-data {
      @include themify($themes) {
        color: themed('main');
      }
      padding: 1.1rem;
    }

    & .suggestion-list-item {
      display: flex;
      list-style: none;
      padding: 1.1rem;
      cursor: pointer;

      &:last-child {
        border-radius: 0 0 1rem 1rem;
      }

      &:hover {
        background-color: $blue-5;
      }

      & .suggestion-item-title {
        display: flex;
        align-items: center;
        gap: 5.5rem;
        @include themify($themes) {
          color: themed('main');
        }
      }
    }
  }
}
