@import "src/assets/sass/variables";
@import "src/assets/sass/themes";

.reply-container {
  padding: 1rem 2rem 2.5rem 0;

  @media screen and (max-width: 768px) {
    padding: 1rem 0rem 0rem;
  }

  .reply-content {
    display: flex;
    gap: 1.1rem;

    .left-wrapper {
      text-align: right;
      width: 100%;

      textarea {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 4.2rem;
        border-radius: 0.5rem;
        border: none;
        padding: 0 1rem;
        font-size: 1.6rem;
        font-weight: 400;

        @include themify($themes) {
          color: themed("main");
        }

        outline: none;
        transition: all 0.3s ease-in-out;
        background-color: $input-color;
        box-sizing: border-box;
      }

      &.error {
        textarea {
          border: 1px solid red;

          &:focus {
            border: 1px solid red;
          }
        }
      }

      .title {
        padding: 0;
      }

      textarea {
        resize: none;
        width: 100%;
        min-height: 9.6rem;
        background-color: $white;
        padding: 10px;

        &:focus-visible {
          border: none;
        }
      }

      .submit-wrapper {
        padding-top: 1.4rem;
        width: 14.1rem;
        height: 3.3rem;
        margin: 0 0 0 auto;

        .spinner.extra-small svg circle {
          stroke: $grey-2;
        }
      }
    }

    .right-wrapper {
      min-width: 8.1rem;

      svg {
        path {
          @include themify($themes) {
            fill: themed("iconFill");
          }
        }
      }

      .content {
        cursor: pointer;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @include themify($themes) {
          background-color: themed("mySupplier", "bgMessage") !important;
        }

        border-radius: 0.5rem;

        p {
          margin: 0;
        }
      }
    }
  }
}
