@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.proforma-rows {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include themify($themes) {
      color: themed('main');
    }
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 6px;
    .header-item {
      flex: 1;
      text-align: center;
      font-size: 1.2rem;
      line-height: 1.4rem;
      font-weight: bold;
      &.actions {
        padding-right: 5rem;
      }
    }
  }
}
