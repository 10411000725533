@import "src/assets/sass/variables";
@import "src/assets/sass/themes";

.error-message {
  display: none;
}

.error {
  color: $red !important;
  font-weight: 700;

  p {
    margin: 0;
  }

  .error-message {
    display: block;
    margin-top: 0.5rem;
    font-size: 1rem;
  }

  select,
  input {
    border: 1px solid $red !important;
  }
}

.my-info.form-container {
  max-width: 132rem;
  margin: 0 auto;
  width: 100%;

  @include themify($themes) {
    color: themed("main");
  }

  .title {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 2.55rem;
    line-height: 3.4rem;
    margin: 1.9rem auto 2.95rem;
  }

  .m3-form {
    display: flex;
    padding: 1rem 0;
    box-sizing: border-box;
    font-size: 1.4rem;

    & .m3 {
      display: grid;
      align-items: flex-start;
      justify-content: flex-start;
      grid-template-columns: 1fr 1fr;
      flex-direction: column;

      .m3-item {
        padding-right: 3rem;
        box-sizing: border-box;

        .m3-key {
          font-weight: bold;
          white-space: wrap;
        }
      }
    }
  }

  .form {
    width: 100%;
    padding-top: 0.75rem;
    padding-bottom: 2.4rem;

    @include themify($themes) {
      border-top: 1px solid themed("border");
    }

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 768px) {
      background-color: $white;
    }

    h4 {
      font-size: 11px;
      line-height: 2.7rem;
      text-transform: uppercase;
      margin: 0;
      opacity: 0.47;
    }

    input,
    select {
      font-size: 1.5rem;
      font-family: "Open Sans", "sans-serif";
      line-height: 2rem;

      @include themify($themes) {
        color: themed("main");
      }
    }

    label {
      @include themify($themes) {
        color: themed("main");
      }

      font-size: 1.4rem;
      line-height: 1.9rem;
      font-weight: 700;
      width: 100%;
      margin-bottom: 0.5rem;
      display: inline-block;
      padding-left: 0.4rem;
    }

    .line {
      display: flex;
      min-width: 23rem;
      padding: 0;
      justify-content: flex-start;
      width: 100%;

      @media screen and (max-width: 768px) {
        justify-content: center;
      }

      label {
        display: inline;
      }

      .radio:first-child {
        padding-left: 0;
        margin-left: 0;

        input[type="radio"] {
          padding-left: 0;
          margin-left: 0;
        }
      }

      .radio {
        padding: 0.5rem;
        display: inline-block;
      }

      input[type="radio"] {
        cursor: pointer;
        appearance: none;
        background-color: $white;
        width: 16px;
        height: 16px;
        border-radius: 50%;

        @include themify($themes) {
          border: 2px solid themed("border");
        }

        display: inline-grid;
        place-content: center;
      }

      input[type="radio"]::before {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        transform: scale(0);
        transform-origin: bottom left;
        background-color: $white;
        clip-path: polygon(13% 50%, 34% 66%, 81% 2%, 100% 18%, 39% 100%, 0 71%);
      }

      input[type="radio"]:checked::before {
        transform: scale(1);
      }

      input[type="radio"]:checked {
        @include themify($themes) {
          background-color: themed("main");
        }

        @include themify($themes) {
          border-color: themed("main");
        }
      }
    }

    .row {
      display: flex;
      width: 100%;
      gap: 3.2rem;
      justify-content: center;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        width: 90%;
        gap: 1rem;
      }

      >.container {
        margin: 1rem 0;
        min-height: auto;
        flex: 1;

        .select-dropdown {
          height: 4.2rem;
          border-radius: 0.5rem;
          font-size: 1.4rem;
          font-weight: 400;

          @include themify($themes) {
            color: themed("main");
          }

          width: 100%;
          border: none;
        }

        .container {
          min-height: auto;
        }
      }

      .form-group {
        display: flex;
        flex: 1 1;
        gap: 1rem;
        flex-direction: row;

        @media screen and (max-width: 768px) {
          flex-direction: column;
        }
      }

      .text-input-container {
        width: 100%;
        height: 4.2rem;
        padding: 0;
      }

      .text-input,
      .field {
        border: none;

        @include themify($themes) {
          background-color: themed("inputColor");
        }

        padding: 0 1.1rem;
        outline: none;
        border-radius: 10px;
        width: 100%;
        height: 4.2rem;
      }

      .text-input {
        width: 100%;

        &::placeholder {
          @include themify($themes) {
            color: themed("inputPlaceholderColor");
          }
        }
      }

      .text-input-container {
        width: 100%;
        border: none;

        @include themify($themes) {
          background-color: themed("inputColor");
        }

        outline: none;
        border-radius: 10px;
        align-items: flex-start;
      }

      .input-long {
        width: 30.6rem;

        @media screen and (max-width: 768px) {
          width: 100%;
        }

        input {
          width: 100%;
        }
      }

      .input-short {
        width: 10.2rem;

        @media screen and (max-width: 768px) {
          width: 100%;
        }

        input {
          width: 100%;
        }
      }

      .select {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;

        @media screen and (max-width: 768px) {
          width: 100%;
        }

        select {
          width: 100%;
          height: 4.2rem;

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }

    .icon {
      font-size: 16px;
      top: -2px;
      position: relative;
    }

    &:last-of-type {
      border: none;
    }
  }

  button {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: 29.6rem;
    height: 3.4rem;
    margin: 1rem auto;
    border-radius: 0.8rem;
    font-size: 1.2rem;
    line-height: 1.634rem;
    font-weight: 700;
    border: none;
    cursor: pointer;
    justify-content: center;
  }
}

/* Responsive CSS Styles */

.app-layout-desktop {}

.app-layout-tablet {}

.app-layout-mobile {
  .my-info.form-container {
    .form {
      .row {
        .text-input {
          width: 100%;
        }
      }
    }
  }
}