@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.app-amerigo {
  &.no-header {
    background-color: $background-grey;

    .body {
      background: initial;
    }
  }

  .amerigo-espace {
    &.espace-container {
      margin: 0 auto;
      width: 100%;
      max-width: 100rem;
      height: 100%;
      padding: 4rem 0;
      padding-bottom: 0;

      .tabs {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
}

/* Responsive CSS Styles */

@media screen and (max-width: 1100px) {
  .app-amerigo.no-header {
    .amerigo-espace {
      &.espace-container {
        & > div {
          max-width: $container-width-medium;
          padding-left: 2rem;
          padding-right: 2rem;
          width: calc(100% - 4rem);
          background: none;
          margin: 0 auto 4rem;
        }

        .tabs {
          gap: 2rem;

          .tab {
            .espace-tab-container {
              min-height: 35rem;
              height: auto;
              max-width: 46.2rem;
              width: auto;
            }
          }
        }
      }
    }
  }
}

.app-amerigo.app-layout-mobile,
.app-amerigo.app-layout-tablet {
  &.container-wrapper {
    max-width: 100%;
    //background-color: $background-grey;
  }

  .amerigo-espace {
    &.espace-container {
      padding: 0;

      .tabs {
        flex-direction: column;

        .tab {
          .espace-tab-container {
            max-width: 100%;
          }
        }
      }
    }
  }
}

.app-amerigo.app-layout-mobile {
  .amerigo-espace {
    &.espace-container {
      .tabs {
        .tab {
          .espace-tab-container {
            min-height: initial;

            .text-ellipsis {
              -webkit-line-clamp: initial;
            }
          }
        }
      }
    }
  }
}
