@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.swiper-container {
  .promotion-container.default-promotion.mobile {
    flex-flow: row wrap;
    gap: 0.8rem;
    height: 15rem;
    width: calc(100% - 2.6rem);
    padding: 0.8rem 1.3rem;

    @media screen and (max-width: 768px) and (max-width: 990px) {
      height: auto;
    }

    .promotion-header {
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;

      .title {
        color: $red;
        font-style: normal;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1.9rem;
        padding: 0 0.5rem;

        @media screen and (max-width: 768px) and (max-width: 990px) {
          margin: auto;
        }
      }

      .discount {
        @include themify($themes) {
          color: themed('main');
        }
        font-style: normal;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 2.5rem;
        white-space: nowrap;
      }
    }

    .promotion-content {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      flex: 1 1 auto;
      align-items: center;

      @media screen and (max-width: 768px) {
        height: auto;
        display: flex;
        flex-direction: column;
        flex: auto;
      }

      .promotion-image {
        flex: 0 0 12rem;

        @media screen and (max-width: 768px) {
          flex: 0 0 8rem;
        }

        & img {
          max-width: 12rem;
          max-height: 9rem;
        }
      }

      .promotion-info {
        padding-left: 1rem;

        & .information {
          min-width: 1rem;

          .description {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 1.2rem;
          }

          .availability {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .swiper-container {
    .promotion-container {
      .swiper-slide {
        max-width: 31rem;
      }
    }
  }
}
