@import '../../assets/sass/variables';
@import 'src/assets/sass/themes';

.part-item-oe-list-new {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;

  @include themify($themes) {
    background-color: themed('secondary-darker');
  }

  border-radius: 0.5rem;
  box-sizing: border-box;
  max-height: 40rem;
  gap: 2rem;
  padding: 1rem 1rem;
  position: relative;
  justify-content: space-between;

  .oe-header {
    display: flex;
    flex-direction: row;
    width: max-content;
    align-items: center;

    .img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 10rem;
      height: 10rem;
      border: 0.1rem solid $gray;

      img {
        max-width: 10rem;
        max-height: 10rem;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      margin-left: 1rem;

      @include themify($themes) {
        color: themed('main');
      }

      .title {
        .info {
          text-align: left;
          margin-bottom: 0.3rem;
          font-size: 1.4rem;
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          /* number of lines to show */
          -webkit-box-orient: vertical;
        }

        .show-more {
          float: right;

          .btn-more {
            border: none;
            background: transparent;
            font-size: 1.1rem;
            font-weight: 600;

            @include themify($themes) {
              color: themed('main');
            }

            cursor: pointer;
          }
        }
      }

      .description {
        text-align: left;
        margin-bottom: 0.3rem;
        font-size: 1.1rem;

        @include themify($themes) {
          color: themed('main');
        }

        line-height: 1.3;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        -webkit-box-orient: vertical;

        .info {
          text-align: left;
          margin-bottom: 0.3rem;
          font-size: 1.1rem;

          @include themify($themes) {
            color: themed('main');
          }

          line-height: 1.3;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          /* number of lines to show */
          -webkit-box-orient: vertical;
        }

        .show-more {
          float: right;

          .btn-more {
            border: none;
            background: transparent;
            font-size: 1.1rem;
            font-weight: 600;

            @include themify($themes) {
              color: themed('main');
            }

            cursor: pointer;
          }
        }
      }
    }
  }
}