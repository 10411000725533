@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.equivalence-oem-item {
  cursor: pointer;
  padding: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  width: auto;
  @include themify($themes) {
    background-color: themed('secondary');
  }
  border-radius: 1rem;
  .image {
    width: 8.1rem;
    height: 8.1rem;
    @include themify($themes) {
      background-color: themed('secondary-3');
    }
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .content {
    padding: 0 1.4rem 0 0;
    width: 15rem;
    .title {
      text-decoration: none;
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 2rem;
      @include themify($themes) {
        color: themed('main');
      }
    }
    .category {
      font-size: 1.2rem;
      line-height: 1.7rem;
      padding-bottom: 0.5rem;
    }
    .subtitle {
      span {
        font-weight: bold;
      }
      font-size: 1.2rem;
      line-height: 1.7rem;
    }
  }
}
