@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.car-parts-static {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 10rem;
  margin-bottom: 5rem;
  gap: 1rem;
  position: relative;
  min-height: 11.2rem;

  & .no-data {
    font-size: 2.4rem;
    font-weight: bold;
    @include themify($themes) {
      color: themed('main');
    }
    opacity: 60%;
    margin-left: 2rem;
    white-space: nowrap;
  }
}
