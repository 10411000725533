@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';
@import 'src/assets/sass/_mixins';

.presentation-navbar-container {
  width: 100%;

  .presentation-navbar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @include themify($themes) {
      background-color: themed('secondary');
    }
    height: 7.2rem;
    padding: 0 15rem;
    box-sizing: border-box;

    .mini-dropdowns {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.6rem;
      margin-right: 1rem;

      & .language-dropdown-title {
        @include themify($themes) {
          color: themed('main');
        }
      }
    }

    .presentation-nav-logo {
      display: flex;
      align-items: center;

      img {
        max-width: 7.6rem;
        max-height: 6.4rem;
      }
    }
  }
}
