@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

/* Responsive CSS Styles */

.app-layout-mobile,
.app-layout-tablet {
  .quick-slider-container {
  }
}

.app-layout-desktop {
  .quick-slider-container {
    margin-bottom: 1rem;

    .tabcard-container {
      width: calc(100% - 2rem);
      max-width: 41rem;
      overflow: hidden;

      .image-wrapper {
        cursor: pointer;
        width: 8.1rem;
        height: 8.1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;

        .image {
          max-width: 8.1rem;
          max-height: 8.1rem;
        }
      }

      .wrapper {
        gap: 0.6rem;
        padding-left: 1.5rem;

        .nameWrapper {
          & > .title,
          & > .description {
            width: 29rem;
          }
        }
      }
    }

    .swiper-container {
      .swiper-slide {
        max-width: 41rem;
      }
    }
  }
}
