@import '../../assets/sass/variables';
@import 'src/assets/sass/themes';

.parameters-container {
  .wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 132rem;
    margin: auto;

    @media screen and (max-width: 990px) and (min-width: 768px) {
      min-height: 80vh;
      justify-content: flex-start;
    }

    h1 {
      @include themify($themes) {
        color: themed('main');
      }

      font-size: 2.5rem;
    }

    .form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      & .settings-row-header {
        display: flex;
        align-items: center;
        gap: 1rem;
      }

      .settings-separator {
        width: 100%;

        @include themify($themes) {
          border-bottom: 1px solid themed('border');
        }

        padding: 2rem 0;

        &:first-child {
          padding: 0 0 2rem 0;
        }
      }

      @media screen and (max-width: 768px) {
        margin-bottom: 3rem;
      }

      .row {
        display: flex;
        justify-content: space-between;
        gap: 3rem;
        width: 100%;

        &.language-country {
          align-items: center;
          justify-content: center;
        }

        &.add_logo {
          display: block;
          align-items: center;
          justify-content: center;
          padding-top: 20px;
          width: 100%;
          text-align: center;

          .icon {
            width: 100%;

            .logo {
              display: block;
              margin-left: auto;
              margin-right: auto;
              max-width: 150px;
              max-height: 150px;
            }
          }

          .logo-upload-container {
            text-align: center;
          }

          .uploaded-message-file {
            height: auto;
            padding-left: 2.3rem;
            width: 50%;
            margin: 0 auto;
          }
        }

        @media screen and (max-width: 768px) {
          display: flex;
          flex-direction: column;
        }

        .t1 {
          display: flex;

          @include themify($themes) {
            color: themed('main');
          }

          align-items: center;
          gap: 0.4rem;
          font-size: 1.2rem;

          & .t-label {
            font-weight: bold;

            & span {
              font-size: 1.4rem;
            }
          }

          & .t-input {
            width: 8rem;

            .text-input-container {

              .text-input {
                @include themify($themes) {
                  background-color: themed('inputColor');
                  color: themed('main');
                }
              }
            }
          }

          & .t-currency {
            font-size: 1.4rem;
          }
        }

        .standard-dropdown {
          width: 19.6rem;

          @media screen and (max-width: 768px) {
            width: 100%;
          }

          @include themify($themes) {
            background-color: themed('inputColor');
          }
        }

        .label {
          font-size: 1.4rem;

          @include themify($themes) {
            color: themed('main');
          }

          line-height: 1.9rem;
          font-weight: bold;
          margin-bottom: 0.5rem;
        }
      }

      @include themify($themes) {
        color: themed('main');
      }

      .submit-button {
        width: 100%;
        max-width: 20rem;
        margin-top: 5rem;
      }
    }
  }
}