@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.diagnostic-error-code-item {
  &.big {
    display: block;
    width: calc(100% - 4rem);
  }
  &.medium {
    display: inline-block;
    width: calc(50% - 3.5rem);
  }
  &.small {
    display: inline-block;
    width: 16.6rem;
    @media screen and (max-width: 768px) {
      width: calc(50% - 3.5rem);
    }
  }
  @include themify($themes) {
    color: themed('main');
  }
  background-color: #f5f9fe;
  border-radius: 1rem;
  padding: 1rem;
  margin: 0.5rem;
  .name {
    height: 3rem;
    p {
      margin: 0;
    }
    font-size: 1rem;
    line-height: 1.2rem;
    text-align: center;
    font-weight: bold;
  }
  .error-codes {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
    span {
      display: inline-block;
      margin-top: 1rem;
      cursor: pointer;
      text-align: center;
      @include themify($themes) {
        color: themed('main');
      }
      @include themify($themes) {
        background-color: themed('secondary-3');
      }
      border-radius: 2rem;
      width: 8.5rem;
      height: 3rem;
      font-size: 1.5rem;
      line-height: 3rem;
      &.selected {
        @include themify($themes) {
          color: themed('main');
        }
        color: #e4ebf7;
      }
    }
  }
}
