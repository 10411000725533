@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.theme-mecadepot {
    & .LIST-mecadepot-catalog-item {
        & .mecadepot-catalog-header {
            & .product-image-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 1rem;
            }

            & .content {
                justify-content: flex-start;

                & .title {
                    display: flex;
                    gap: .5rem;
                    align-items: center;



                    &:hover {
                        cursor: pointer;
                        opacity: 80%;
                    }



                    & svg {
                        cursor: pointer;

                        &:hover {
                            opacity: 80%;
                        }
                    }
                }

                & .description-short {
                    font-size: 1.5rem;
                    font-weight: bold;
                    text-decoration: underline;


                    &:hover {
                        cursor: pointer;
                        opacity: 80%;
                    }
                }
            }
        }
    }
}

.LIST-mecadepot-catalog-item {
    width: 100%;
    align-items: center;
    height: auto;
    display: flex !important;
    flex-direction: row;

    @include themify($themes) {
        background: themed('secondary');
    }

    border-radius: 0.5rem;
    box-sizing: border-box;
    max-height: 40rem;
    gap: 2rem;
    padding: 1rem 1rem;
    position: relative;
    justify-content: space-between;

    .mecadepot-catalog-item-favorite {
        position: absolute;
        right: 0;
        top: 1rem;

        .favorite-button {
            position: absolute;
            right: 0;

            .iconButton {
                background: none;
            }

            svg {
                cursor: pointer;
            }

            .spinner-container {
                width: 2rem !important;
                height: 2rem !important;
                position: relative;

                .spinner {
                    position: absolute;
                    right: 1rem;
                    top: 0;
                    left: unset;
                    background-color: inherit;
                }
            }

            button {
                svg {
                    position: absolute;
                    width: 2rem !important;
                    height: 2rem !important;
                    right: 1rem;
                    top: 0;
                    left: unset;

                    @include themify($themes) {
                        fill: themed('main');
                    }
                }
            }
        }
    }

    .mecadepot-catalog-header {
        display: flex;
        flex-direction: row;
        width: 100%;
        min-width: 25rem;
        align-items: center;

        .img-zoom-container {
            position: relative;
            display: flex;
            gap: 1rem;
            z-index: 10;

            & .img-zoom {
                position: absolute !important;
                left: 8rem;
                // img {
                //   width: 100% !important;
                // }
            }
        }

        .img {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 8rem;
            height: 8rem;
            border: 0.1rem solid $gray;

            img {
                max-width: 8rem;
                max-height: 8rem;
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            margin-left: 1rem;

            @include themify($themes) {
                color: themed('main');
            }

            .title {
                text-align: left;
                margin-bottom: 0.3rem;
                font-size: 1.4rem;
                font-weight: 600;

                &:hover {
                    opacity: 80%;
                    cursor: pointer;
                }
            }

            .description-short {
                text-align: left;
                margin-bottom: 0.5rem;
                font-size: 1.1rem;
                font-weight: 600;

                @include themify($themes) {
                    color: themed('main');
                }
            }

            .description {
                text-align: left;
                margin-bottom: 0.5rem;
                font-size: 1.1rem;

                @include themify($themes) {
                    color: themed('main');
                }

                line-height: 1.3;
            }
        }
    }

    .mecadepot-catalog-body {
        margin-top: 0.8rem;
        display: flex;
        flex-direction: column;
        width: 90%;
    }

    .mecadepot-catalog-footer {
        display: flex;
        gap: 1rem;
        border-left: 0.1rem solid #e5e5e5;
        border-top: none;
        justify-content: flex-end;

        .mecadepot-catalog-actions {
            padding-left: 1rem;

            .oem-price_per_unit {
                @include themify($themes) {
                    color: themed('main');
                }

                font-size: 1.2rem;
                white-space: nowrap;
            }
        }

        .barcode {
            width: 10.1rem;
            object-fit: contain;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-right: 3rem;

            & img {
                max-width: 10.1rem;
                height: 5.2rem;
            }

            & .barcode-number {
                text-align: center;
            }
        }

        .quantity {
            flex-direction: column;
            margin: 0.3rem;
            display: flex;
            gap: 1rem;
        }

        .cart-price {
            @include themify($themes) {
                color: themed('main');
            }
        }

        .cart {
            text-align: center;
            margin-bottom: 0.2rem;
            font-size: 1.8rem;
            font-weight: 600;
            display: flex;
            align-items: flex-end;
            gap: 1rem;

            .cart-button {
                display: flex;
                justify-content: flex-end;

                .cirrus-contact-us {
                    white-space: nowrap;

                    @include themify($themes) {
                        color: themed('main');
                    }

                    font-size: 1.2rem;
                    text-decoration: underline;
                    cursor: pointer;
                    min-width: 6.2rem;
                    height: 1.6rem;
                    margin-top: 0.2rem;

                    & .extra-small {
                        margin: auto;
                    }

                    &:hover {
                        opacity: 80%;
                    }
                }

                .button {
                    padding: 0.3rem;
                }
            }
        }
    }
}