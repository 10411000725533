@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.technical-documentation-container {
  padding: 2rem 0;

  .vehicle-iframe {
    max-width: 80rem;
    margin: 0 auto;
  }
}

/* Mobile CSS Styles */

.app-layout-mobile {
  .technical-documentation-container {
    padding-left: 2rem;
    padding-right: 2rem;

    .vehicle-iframe {
      box-shadow: 0.2rem 0.2rem 1rem rgb(0 0 0 / 10%);
      border-radius: 0.5rem;
    }
  }
}
