@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.sellers-content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;
  padding: 0;
  position: relative;

  .table {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media screen and (max-width: 990px) and (min-width: 768px) {
      width: 100%;
    }

    .row {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;
      gap: 2rem;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 1rem;
        padding-bottom: 2rem;
      }

      .title {
        @include themify($themes) {
          color: themed('main');
        }

        font-size: 14px;
      }

      .input {
        @include themify($themes) {
          color: themed('main');
        }

        width: 8.5rem;
        height: 2rem;
        border: none;
        background-color: $blue-9;
        border-radius: 1rem;
        padding: 0.8rem;

        @media screen and (max-width: 768px) {
          min-width: 23rem;
          padding: 0.8rem 0;
        }
      }

      .checkout-button {
        min-width: 23rem;
      }
    }
  }
}