@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.my-suppliers-container {
  justify-content: center;
  align-items: center;
  padding: 2rem 24rem;
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    padding: 0 1rem;
    margin-bottom: 5rem;
  }

  .my-suppliers-filter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .title-search {
      height: 3.4rem;
      @include themify($themes) {
        color: themed('main');
      }
      font-family: 'Open Sans';
      font-size: 2.5rem;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 3.4rem;
      text-align: center;
      margin-top: 3.2rem;
    }

    .my-suppliers-main-search {
      margin-top: 2.4rem;
      width: 62rem;

      @media screen and (max-width: 768px) {
        width: 25rem;
      }
    }

    .my-suppliers-checkbox-container {
      display: flex;
      justify-content: center;
      height: 1.9rem;
      width: 36.7rem;
      @include themify($themes) {
        color: themed('main');
      }
      font-family: 'Open Sans';
      font-size: 1.4rem;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 1.9rem;
      margin-top: 0.8rem;
      gap: 1rem;

      @media screen and (max-width: 768px) {
        width: 100%;
        white-space: nowrap;
      }
    }
  }

  .my-suppliers-all-brands-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 6.4rem;
    width: 100%;
    justify-content: space-between;
    gap: 4rem;
    position: relative;

    .items {
      height: 100%;
      display: flex;
      flex-flow: wrap;
      flex-direction: row;
      text-align: center;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 3rem;

      .no-data {
        font-size: 2.4rem;
        font-weight: bold;
        @include themify($themes) {
          color: themed('main');
        }
        opacity: 60%;
      }

      .item-container {
        flex: 1 1 16%;
        max-height: 9rem;

        .item {
          height: 100%;
          width: 100%;
          max-height: fit-content;

          .item-name {
            height: 1.9rem;
            font-weight: 600;
            font-size: 14px;
            @include themify($themes) {
              color: themed('main');
            }
          }
        }
      }
    }
  }
}
