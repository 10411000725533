@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.espace-automotor-price-history {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 4rem;
  max-width: 132rem;
  margin: auto;
  width: 100%;

  & .price-list-container {
    width: 100%;


    @media screen and (max-width: 990px) {
      width: 100%;
    }

    & .price-list-header {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 4rem;

      @media screen and (max-width: 990px) {
        flex-direction: column;
      }

      & .price-list-title {

        font-size: 2rem;
        font-weight: bold;

        @include themify($themes) {
          color: themed('main');
        }

        flex: 1;
        text-align: center;


        @media screen and (max-width: 990px) {
          width: 100%;
          flex: none;
          text-align: center;
          margin-left: 0;
        }

      }

      & .add-new-price-btn {
        width: 18rem;
      }

      .return-button {
        display: flex;
        align-items: center;
        gap: 1rem;
        font-size: 1.6rem;
        align-self: flex-start;
        width: 10rem;
        cursor: pointer;
        margin: 1rem 0;

        @include themify($themes) {
          color: themed('main');
        }

        &:hover {
          opacity: 0.7;
        }

        .icon {
          font-size: 1.6rem;
          cursor: pointer;

          @include themify($themes) {
            color: themed('main');
          }
        }
      }
    }



    & .price-items {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      position: relative;

      & .table-wrapper-tariff-default table .row:first-child .heading:last-child .table-title {
        height: 3.4rem !important;
      }

      .faFileDownload {
        font-size: 18px;
        cursor: pointer;


      }




      .disabled {
        pointer-events: none;
        color: $grey-13;
        visibility: hidden;
      }

      .status {

        color: $white;
        padding: 0.2rem 0.4rem;
        border-radius: 0.5rem;
        width: 100%;
        text-align: center;

      }

      .action {
        display: flex;
        justify-content: space-around;
        position: relative;

        .tooltip_lable {
          display: none;
          position: absolute;
          padding: 1rem 2rem;
          background-color: $black;
          color: $white;
          top: -4rem;
          left: -2.5rem;
          border-radius: 5px;
          z-index: 99999999999;
        }
      }

      .failed {
        background-color: #dc3545;
      }

      .pending {
        background-color: #ffc107;
      }

      .inprogress {
        background-color: #17a2b8;
      }

      .finished {
        background-color: #28a745;
      }

      & .prices-spinner {
        top: 10rem;
      }

      & .no-data {
        font-size: 2.4rem;
        font-weight: bold;

        @include themify($themes) {
          color: themed('main');
        }

        opacity: 60%;
        margin-top: 5rem;
        white-space: nowrap;
      }
    }
  }
}