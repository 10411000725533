@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.stats-filter {
  display: grid;
  align-items: flex-end;
  width: 100%;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(20rem, auto));

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
  .stats-filter-item {
    width: 100%;
    & .stats-react-picker-dropdown {
      cursor: pointer;
      min-width: 18rem;
      &.disable {
        pointer-events: none;
      }
    }

    & .stats-date-picker {
      position: absolute;
      z-index: 101;
      top: 13rem;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      & .react-datepicker {
        display: flex;
        position: relative;
        border: none;
        box-shadow: 0.5rem 0.8rem 2.1rem rgb(0 0 0 / 15%);
        font-size: 1.3rem;

        &__month-container {
          & .react-datepicker__header {
            background: none;
            border-bottom: none;
            padding: 1.2rem 1rem;

            & .react-datepicker__current-month {
              font-size: 1.4rem;
            }

            & .react-datepicker__day-names {
              .react-datepicker__day-name {
                width: 3rem;
              }
            }
          }

          & .react-datepicker__week {
            .react-datepicker__day {
              width: 3rem;
              line-height: 3rem;

              &--in-range {
                background-color: #d73c5a;
              }

              &--selected {
                background-color: #d73c5a;
              }

              &--in-selecting-range {
                background-color: #f5bec7;
              }

              &--keyboard-selected {
                background-color: #d73c5a;
              }
            }
          }
        }

        .react-datepicker__month-container {
          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }

    .stats-filter-label {
      @include themify($themes) {
        color: themed('main');
      }
      font-weight: bold;
    }
    .stats-select-dropdown {
      border-radius: 0.5rem;
      font-size: 1.2rem;
      font-weight: 400;
      @include themify($themes) {
        color: themed('main');
      }
      width: 100%;
      border: none;
      min-width: 18rem;

      [class$='-control'] {
        @include themify($themes) {
          color: themed('main');
        }
        border: none;
        @include themify($themes) {background-color: themed('mainHover');}
        box-shadow: none;

        &:hover {
          border: none;
        }
      }

      [class$='-placeholder'] {
        @include themify($themes) {
          color: themed('main');
        }
        font-size: 1.2rem;
        font-weight: 400;
      }

      [class$='-indicatorContainer'] {
        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-singleValue'] {
        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-indicatorSeparator'] {
        background: none;
        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-indicator'] {
        @include themify($themes) {
          background: themed('main');
        }
        color: $white;
      }
    }
  }
  .stats-btns {
    display: flex;
    gap: 1rem;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    .stats-btn {
      width: fit-content;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      .button-text {
        padding: 0 0.6rem;
      }
    }
  }
  .stats-checkbox-option {
    padding: 0.5rem 0;
  }
}
