@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.automotor-check-reference-disponibility {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 70rem;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    margin-top: 0.5rem;
    padding: 0rem 1rem;
    box-sizing: border-box;
  }

  @media screen and (max-width: 578px) {
    max-width: 100%;
  }


  & .input {
    padding: 0rem;
    width: 55rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    border-radius: 0rem;

    @media screen and (max-width: 768px) {
      padding: 0 2rem;
      max-width: 100%;
    }

    @media screen and (max-width: 578px) {
      padding: 0rem;
      box-sizing: border-box;
    }

    .search {
      width: 15rem;
    }
  }


}