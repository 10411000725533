@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.new-ref-dropdown {
  position: absolute;
  background-color: $hovered-blue;
  border-radius: 1rem;
  z-index: 30;
  box-shadow: 0.2rem 0.2rem 1rem rgb(1 1 1 / 15%);

  font-size: 1.4rem;
  font-weight: bold;
  margin: 0rem 5rem 2rem 5rem;
  bottom: auto;
  top: 70px;
  max-height: 30rem;
  overflow-y: auto;
  &.has-ind {
    width: 100%;
  }
  & .ref-dropdown-header {
    display: flex;
    justify-content: space-between;
    min-width: 32rem;
    box-sizing: border-box;
    padding: 1.3rem 0.6rem 0.4rem 0.6rem;
    border-bottom: 1px solid $blue-5;
    &.has-ind {
      width: 100%;
    }
    & .ref-dropdown-title {
      @include themify($themes) {
        color: themed('main');
      }
      width: 100%;
      white-space: nowrap;
      @media screen and (max-width: 768px) {
        white-space: normal;
      }
      &.ind {
        text-align: right;
      }
    }
  }

  & .ref-search-result-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // width: 100%;

    & .skeleton-line {
      margin: 0.6rem;
    }

    & .no-data {
      @include themify($themes) {
        color: themed('main');
      }
      padding: 1.1rem;
    }

    & .suggestion-list-item {
      display: flex;
      list-style: none;
      padding: 0 1.1rem;
      cursor: pointer;
      width: 100%;
      box-sizing: border-box;
      gap: 4rem;
      min-width: 28rem;
      @media screen and (max-width: 768px) {
        gap: 1rem;
      }

      &:last-child {
        border-radius: 0 0 1rem 1rem;
      }

      &:hover {
        background-color: $blue-5;
      }

      & .red {
        display: flex;
        width: 100%;
        color: $red;
        align-items: center;
        &:hover {
          opacity: 55%;
        }
      }

      & .suggestion-item-title {
        display: grid;
        grid-template-columns: 10rem 7rem;
        align-items: center;
        gap: 4rem;
        @include themify($themes) {
          color: themed('main');
        }
        width: 100%;
        height: 4.1rem;

        &.has-ind {
          display: grid;
          grid-template-columns: 6rem 7rem;
        }
        @media screen and (max-width: 768px) {
          gap: 1rem;
          grid-template-columns: 8rem 8rem;
        }
        &:hover {
          opacity: 55%;
        }
      }
    }
  }
}
