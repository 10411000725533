@import '../../assets/sass/variables';
@import 'src/assets/sass/themes';

.part-list-item-oe {
  width: 35rem;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include themify($themes) {
    background-color: themed('secondary-darker');
  }

  border-radius: 0.5rem;
  max-height: 46rem;

  & .tags {
    padding: 1rem;

    @include themify($themes) {
      color: themed('main');
    }
  }

  .part-list-item-favorite {
    align-self: flex-end;
    width: 100%;
    z-index: 2;
    position: relative;

    .favorite-button {
      position: absolute;
      right: 0;

      .iconButton {
        background: none;
      }

      svg {
        cursor: pointer;
      }

      .spinner-container {
        width: 2rem !important;
        height: 2rem !important;
        position: relative;

        .spinner {
          position: absolute;
          right: 1rem;
          top: 1rem;
          left: unset;
          background-color: inherit;
        }
      }

      button {
        svg {
          position: absolute;
          width: 2rem !important;
          height: 2rem !important;
          right: 1rem;
          top: 1rem;
          left: unset;

          @include themify($themes) {
            fill: themed('main');
          }
        }
      }
    }
  }

  .part-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    align-items: center;
    box-sizing: border-box;
    margin-top: 3rem;
    padding: 0 1rem;

    .img {
      img {
        max-width: 6.4rem;
        max-height: 6.4rem;
      }
    }

    .title {
      text-align: center;
      margin-bottom: 0.2rem;
      font-size: 1.8rem;
      font-weight: 600;

      @include themify($themes) {
        color: themed('main');
      }
    }

    .reference {
      text-align: center;
      margin-bottom: 0.2rem;
      font-size: 1.8rem;
      font-weight: 600;

      @include themify($themes) {
        color: themed('main');
      }

      max-height: 16rem;
      overflow: auto;
    }

    .product-name {
      text-align: center;
      margin-bottom: 0.2rem;
      font-size: 1.4rem;
      font-weight: 400;

      @include themify($themes) {
        color: themed('main');
      }
    }

    .product-man-group {
      max-height: 17.5rem;
      overflow: auto;
      text-align: center;
      padding: 1rem 0;

      &-info {
        margin-bottom: 0.2rem;

        & .oe-item-link {
          text-decoration: none;

          @include themify($themes) {
            color: themed('main');
          }

          &:hover {
            opacity: 75%;
            text-decoration: underline;
          }
        }

        .title {
          font-size: 1.6rem;
        }

        .reference {
          font-size: 1.6rem;
          font-weight: 400;
        }
      }
    }
  }

  .part-footer {
    display: flex;
    flex-direction: row;
    border-top: 0.1rem solid $gray;
    align-items: center;
    margin-top: auto;
    width: 27.8rem;
    height: 8.85rem;

    .barcode {
      width: 10.1rem;
      height: 6.8rem;

      object-fit: contain;
      display: block;
      margin: 1rem auto 0 0;
      float: left;
    }

    .quantity {
      width: 11rem;
      flex-direction: column;
      margin: 1rem;
      display: flex;
      gap: 2rem;
    }

    .cart {
      text-align: center;
      margin-bottom: 0.2rem;
      font-size: 1.8rem;
      font-weight: 600;

      .cart-price {
        font-size: 1.8rem;

        @include themify($themes) {
          color: themed('main');
        }
      }

      .cart-button {
        display: flex;

        .button {
          padding: 0.3rem;
        }
      }
    }
  }
}