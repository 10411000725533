@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.battery-view {
  &.item-view-wrapper {
    padding: 2rem 0;
    background: $white;

    .small {
      position: absolute;
      top: 15rem;
    }

    .item-view-header,
    .item-view-content,
    .item-view-footer {
      padding: 0 2rem;
    }

    .item-view-header {
      margin-top: 3rem;
      margin-bottom: 1.5rem;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;

      .return-button {
        .returnButton {
          position: absolute;
          display: flex;
          align-items: center;
          gap: 1rem;
          font-size: 1.4rem;
          top: 4.3rem;
          @include themify($themes) {
            color: themed('main');
          }
          left: 3rem;
        }
      }

      .mobile-battery-item-reference {
        display: flex;
        height: 2.5rem;
        border-radius: 1.3rem;
        background: #e4ebf7;
        @include themify($themes) {
          color: themed('main');
        }
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 1.4rem;
        line-height: 1.9rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;

        & span {
          font-weight: 700;
        }
      }

      .mobile-battery-brand {
        height: 2.8rem;
        font-weight: 700;
        font-size: 2rem;
        line-height: 2.8rem;
        align-items: center;
        text-align: center;
        color: #171818;
        margin-top: 0.5rem;
      }
    }

    .item-view-content {
      .gallery {
        .image-gallery-content.bottom.fullscreen {
          .image-gallery-thumbnails-wrapper.bottom.thumbnails-swipe-horizontal {
            display: none;
          }
        }

        .image-gallery-content.bottom {
          .image-gallery-bullets {
            display: none;
          }

          .image-gallery-thumbnails-wrapper.bottom.thumbnails-swipe-horizontal {
            display: block;
          }
        }
      }

      .details {
        .battery__header__article {
          display: none;
        }

        .battery__header__title {
          display: none;
        }

        .battery__header__category {
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 1.9rem;
          color: #171818;
        }

        // .battery__content {
        //   font-weight: 700;
        //   font-size: 1.4rem;
        //   line-height: 1.9rem;
        // }
      }

      .options {
        background: #f2f2f2;
        position: fixed;
        bottom: 0;
        z-index: 1;
        padding-top: 1.4rem;
        padding-bottom: 2rem;
        width: 100%;
        left: 0rem;
        padding-left: 2rem;

        .battery-options-wrapper {
          padding-right: 3.5rem;

          .top {
            display: none;
          }

          .availability-add-to-cart-wrapper {
            width: 100%;

            .availability {
              justify-content: space-between;

              .quantity-disponibility {
                padding-left: 0rem;
                margin-left: 1rem;
              }

              .car-part-view-price {
                margin-right: 0.5rem;
                width: 11rem;
                height: 3.8rem;
                font-weight: 700;
                font-size: 2.8rem;
                color: $black;

                .currency {
                  font-weight: 700;
                  font-size: 2.8rem;
                  padding-left: 0rem;
                }

                .price-ttc {
                  position: absolute;
                  top: 5rem;
                  right: 2.2rem;
                  width: 4.7rem;
                  height: 1.1rem;
                  font-weight: 700;
                  font-size: 0.8rem;
                  color: #8494c0;
                }
              }
            }

            .mobile-buttons {
              display: flex;
              margin-top: 1.5rem;
              width: 100%;
              justify-content: space-between;

              .add-to-cart-button {
                display: flex;
                width: 15rem;
                height: 3.4rem;
                left: 18.8rem;
                top: 156.8rem;
                border-radius: 0.5rem;
                @include themify($themes) {
                  background: themed('main');
                }
                justify-content: center;
                align-items: center;
                cursor: pointer;

                .spinner {
                  width: 1.3rem;
                }

                .spinner.extra-small svg circle {
                  stroke: $grey-2;
                }

                .iconButton {
                  height: 16px;
                  font-weight: 900;
                  font-size: 12px;
                  @include themify($themes) {
                    background: themed('main');
                  }
                  color: $white;
                  gap: 1rem;
                }
              }

              .add-to-quote-button {
                display: flex;
                width: 15rem;
                height: 3.4rem;
                left: 18.8rem;
                top: 156.8rem;
                border-radius: 0.5rem;
                background: $light-blue;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                .iconButton {
                  height: 1.6rem;
                  font-weight: 900;
                  font-size: 1.2rem;
                  background: $light-blue;
                  color: $white;
                  gap: 1rem;
                }
              }
            }
          }
        }
      }
    }

    .item-view-footer {
    }
  }
}
