@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.technical-documentation-service-newsletter {
  display: flex;
  gap: 1rem;
  width: 100%;

  .tdoc-sidebar {
    width: 100%;
    max-width: 31.5rem;
  }

  .tdoc-content {
    width: 100%;
    height: 100%;
  }

  .tdoc-module {
    width: 100%;
  }
}

/* Mobile CSS Styles */

.app-layout-mobile {
  .technical-documentation-service-newsletter {
    width: calc(100% - 4rem);
    padding: 0 2rem;
  }
}
