@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.order-table-wrapper {
  position: relative;

  &.centered {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .no-data {
    font-size: 2.4rem;
    font-weight: bold;

    @include themify($themes) {
      color: themed('main');
    }

    opacity: 60%;
  }

  .order-table-spinner {
    position: absolute;
    top: 23rem;
  }

  table {
    width: 100%;
  }

  .headings {
    .row {
      .heading {
        @include themify($themes) {
          color: themed('main');
        }

        text-align: center;
        font-weight: 300;
        font-size: 1.2rem;
      }
    }
  }

  .body {
    .row {
      font-size: 0.75rem;

      .cell {
        @include themify($themes) {
          border-top: 1px solid themed('table', 'footer');
          color: themed('main');
        }

        padding: 8px 0px;
        font-size: 1.2rem;
        line-height: 1.7rem;
        text-align: center;
        height: 30px;
        max-width: min-content;

        & .order-table-ref-cell {
          @include themify($themes) {
            color: themed('main');
          }

          font-weight: bold;
        }

        & .order-view-relicats {
          display: inline-flex;
          align-items: center;
          gap: 0.3rem;

          @include themify($themes) {
            color: themed('main');
          }

          & .relicats-red {
            background-color: $green;
            padding: 0.2rem;
            border-radius: 50%;
            width: 1.2rem;
            color: white;
          }
        }
      }
    }
  }
}