@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.modal-proforma-validate {
  width: 60rem;
  padding: 30px;
  text-align: center;

  h1 {
    @include themify($themes) {
      color: themed('main');
    }
  }

  h2 {
    @include themify($themes) {
      color: themed('main');
    }
  }

  .btn-validate {
    padding-top: 1.4rem;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;

    button {
      width: 20rem;
    }
  }

  .color-red-h1 {
    color: red;
  }
}