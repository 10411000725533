@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.favorite-item-container {
  display: flex;
  align-items: center;
  padding: 1rem;

  @include themify($themes) {
    background-color: themed('secondary');
  }

  max-width: 35rem;
  width: 100%;
  border-radius: 10px;
  flex-direction: column;

  @media screen and (max-width: 990px) and (min-width: 768px) {
    max-width: 100%;
  }

  .favorite-item {
    display: flex;
    align-items: center;
    padding: 1rem;

    @include themify($themes) {
      background-color: themed('secondary');
    }

    max-width: 35rem;
    width: 100%;
    border-radius: 10px;

    .favorite-image-wrapper {
      cursor: pointer;

      .image {
        max-width: 8.5rem;
        max-height: 8.5rem;
      }
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      align-items: flex-start;
      padding-left: 20px;

      .date {
        font-size: 12px;

        @include themify($themes) {
          color: themed('main');
        }

        font-weight: 300;
      }

      .nameWrapper {
        .title-item-favorite {
          cursor: pointer;
          text-decoration: none;

          @include themify($themes) {
            color: themed('main');
          }

          font-size: 16px;
          font-weight: bold;
        }

        .description {
          @include themify($themes) {
            color: themed('main');
          }

          font-size: 12px;
        }
      }

      .model {
        @include themify($themes) {
          color: themed('main');
        }

        font-size: 12px;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .modelDescription {
          font-weight: 300;
        }
      }
    }

    .options {
      @include themify($themes) {
        background-color: themed('secondary');
      }

      width: 100%;
      max-width: 10rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: row;
      gap: 0.2rem;

      .checkboxField {
        width: 0;
        height: 0;
      }

      .fav-status {
        display: flex;
        align-items: center;
        justify-content: center;

        & label {
          cursor: pointer;
        }
      }

      .favorite-button {
        position: relative;

        button {
          cursor: pointer;

          @include themify($themes) {
            background-color: themed('secondary');
          }
        }

        .spinner {
          @include themify($themes) {
            background-color: themed('secondary');
          }

          right: 0;
        }

        svg {
          path {
            @include themify($themes) {
              fill: themed('favorite', 'bgFill');
            }
          }

          width: 100%;
          max-width: 2.2rem;
          height: 100%;
          max-height: 2.3rem;
        }
      }
    }

    svg {
      path {
        @include themify($themes) {
          fill: themed('favorite', 'bgFill');
        }
      }



    }
  }

  .unavailable {
    color: $red;
  }

  & .contact_number {
    white-space: nowrap;

    & .stock-unavailability {
      a {
        text-decoration: none;
        color: $red;
      }
    }
  }

  span {
    font-weight: bold;
    padding-right: 0.5rem;
  }
}