@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.modal-new-message {
  min-width: 100rem;
  background-color: $white-5;
  @include themify($themes) {
    color: themed('main');
  }
  font-weight: normal;
  position: relative;

  @media only screen and (max-width: 768px) {
    min-width: 100%;
  }

  @media screen and (max-width: 990px) and (min-width: 768px) {
    min-width: 100%;
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;

    @media only screen and (max-width: 768px) {
      top: 1.5rem;
      right: 1rem;
    }

    svg {
      font-size: 2.4rem;
    }
  }

  .modal-dialog {
    .modal-container {
      .modal-body {
        .title {
          h3 {
            margin: 0;
          }

          padding: 2rem;
          font-size: 2rem;
          text-align: center;
        }

        .supplier-list {
          width: 30rem;
          margin: 0 auto;
          padding: 3rem;

          @media only screen and (max-width: 768px) {
            width: 100%;
            padding: 0rem;
            margin-top: 5rem;
          }

          .select-supplier {
            width: 100%;
            border-radius: 0.5rem;
            font-size: 1.4rem;
            font-weight: 400;
            @include themify($themes) {
              color: themed('main');
            }
            border: none;

            &:focus {
              outline: none;
            }

            &.error {
              [class$='-control'] {
                border: 1px solid red;
              }

              input {
                border: none !important;
              }
            }

            [class$='-control'] {
              @include themify($themes) {
                color: themed('main');
              }
              background-color: $white;
              height: 4.2rem;
              box-shadow: none;
              border-radius: 1rem;
              border: 1px solid $grey-1;

              &:hover {
                border: none;
              }
            }

            [class$='-placeholder'] {
              @include themify($themes) {
                color: themed('main');
              }
              font-size: 1.4rem;
              font-weight: 400;
            }

            [class$='-indicatorContainer'] {
              @include themify($themes) {
                color: themed('main');
              }
            }

            [class$='-singleValue'] {
              @include themify($themes) {
                color: themed('main');
              }
            }

            [class$='-indicatorSeparator'] {
              background: none;
              @include themify($themes) {
                color: themed('main');
              }
            }

            [class$='-indicator'] {
              @include themify($themes) {
                background: themed('main');
              }
              color: $white;
              border: none;
            }
          }
        }

        .reply-container {
          width: 80%;
          margin: 0 auto;
          padding: 1rem 2rem 5rem 0;

          @media only screen and (max-width: 768px) {
            width: 100%;
          }

          .files-data {
            max-height: 20rem;
            overflow-y: auto;
          }

          .reply-content {
            display: flex;
            gap: 1.1rem;

            @media only screen and (max-width: 768px) {
              flex-direction: column;
            }

            .left-wrapper {
              text-align: right;
              width: 100%;

              textarea {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 4.2rem;
                border-radius: 0.5rem;
                border: none;
                padding: 0 1rem;
                font-size: 1.6rem;
                font-weight: 400;
                @include themify($themes) {
                  color: themed('main');
                }
                outline: none;
                transition: all 0.3s ease-in-out;
                background-color: $input-color;
                box-sizing: border-box;
              }

              &.error {
                textarea {
                  border: 1px solid red;

                  &:focus {
                    border: 1px solid red;
                  }
                }
              }

              .title {
                padding: 0;

                @media only screen and (max-width: 768px) {
                  width: 100%;
                }
              }

              textarea {
                resize: none;
                width: 100%;
                min-height: 9.6rem;
                background-color: $white;
                padding: 10px;

                &:focus-visible {
                  border: none;
                }
              }

              .submit-wrapper {
                padding-top: 1.4rem;
                width: 14.1rem;
                height: 3.3rem;
                margin: 0 0 0 auto;

                .spinner.extra-small svg circle {
                  stroke: $grey-2;
                }
              }
            }

            .right-wrapper {
              min-width: 8.1rem;

              .content {
                @media only screen and (max-width: 768px) {
                  padding: 2rem 0rem;
                }

                cursor: pointer;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                @include themify($themes) {
                  background-color: themed('secondary-3');
                }
                border-radius: 0.5rem;

                p {
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
