@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.pagination {
  width: 100%;
  padding: 2rem 0;

  & .pagination-container {
    display: flex;
    padding: 0;
    list-style-type: none;
    justify-content: center;

    .page-item {
      padding: 0 0.5rem;



      a.page-link {
        font-size: 1.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;

        font-weight: bold;
        min-width: 2.4rem;
        height: 2.4rem;

        @include themify($themes) {
          color: themed('main');
        }

        @media screen and (min-width: 768px) {
          width: 2.4rem;
        }
      }

      .button {
        width: 8rem;
        height: 2.4rem;

        @include themify($themes) {
          color: themed('main');
        }

        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.04);
        border: none;
        border-radius: 5px;
        color: $white;
      }
    }

    li.active a {
      @include themify($themes) {
        background-color: themed('main');
      }

      border-color: transparent;

      @include themify($themes) {
        color: themed('tabActiveColor') !important;
      }

      border-radius: 50%;
    }

    li.previous a,
    li.next a,
    li.break a {
      border-color: transparent;
    }

    li.disabled a {
      color: $gray-10;
    }

    li.disable,
    li.disabled a {
      cursor: default;
    }
  }
}