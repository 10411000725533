@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.DefaultIcon.file-button {
  display: inline-flex;
  height: 4.2rem;
  max-width: 4.2rem;
  border-radius: 6rem;
  align-items: center;
  overflow: hidden;
  -webkit-transition: max-width 0.5s;
  transition: max-width 0.5s;
  @include themify($themes) {
    background-color: themed('secondary-3');
    color: themed('main');
  }
  position: relative;
  @media screen and (max-width: 768px) {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .icon {
    position: relative;

    & .file-icon {
      font-size: 1.6rem;
      padding: 0 12px;
      display: flex;
      align-items: center;
      letter-spacing: 0;
      line-height: 18px;
      text-align: center;
      margin-right: 1.5px;
    }
    & .notification {
      font-size: 1.1rem;
      line-height: 1.5rem;
      letter-spacing: normal;
      font-family: 'Open Sans', sans-serif;
      font-weight: 600;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $red;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 55%;
      transform: translateY(-40%);
      text-align: center;
    }
  }

  & .button-text {
    white-space: nowrap;
    padding: 0 1rem 0 1.5rem;
  }
}
.file-button:hover {
  max-width: 20rem;
  cursor: pointer;
}

.file-button {
  direction: rtl;
}
