@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.modal-reference-equivalences {

    min-width: 50rem;
    min-height: 20rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 1rem;
    padding: 2rem;
    align-items: center;

    .ref-header {
        color: $blue-1;
        font-size: 2rem;
        font-weight: 700;
        display: flex;
        flex-direction: column;

        .ref-info {
            font-size: 1.6rem;
            font-weight: 700;
        }

        span {
            font-size: 1.4rem;
            font-weight: 400;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            white-space: normal;
            max-width: 100%;
            word-break: break-word;
        }
    }

    .ref-body {
        min-height: 10rem;

        gap: 1rem;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        align-items: center;

        .ref-content {
            display: flex;
            flex-direction: column;
            color: $blue-1;
            font-size: 1.6rem;
            font-weight: 600;
            text-align: left;

            min-width: 40rem;
            gap: 0.5rem;

            .link-ref {
                color: $blue-1;
                text-decoration: none;
            }


        }

        .close {
            width: 18rem;

            margin-top: 1.5rem;
            height: 3.5rem;
        }


        .ref-content-no-data {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $blue-1;
            font-size: 1.6rem;
            font-weight: 500;
        }
    }


}