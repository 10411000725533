@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

#send-file-modal-content {
  width: 108.5rem;
  position: relative;
  min-height: 4rem;

  @media screen and (max-width: 990px) {
    width: 100%;
  }

  .upload-file {
    .proforma-upload-file-amerigo {
      border-bottom: 1px solid $blue-5;
      width: 100%;
      max-width: unset;
      border-radius: 0;

      .upload-btn {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;

        img {
          width: 4rem;
          height: 5.5rem;
        }

        .text {
          text-align: left;
        }
      }
    }

    .file-name {
      // padding-top: 3rem;
      font-size: 2.3rem;
      line-height: 3.5rem;
      text-align: center;
      font-weight: bold;
    }

    .items-length {
      font-size: 1.6rem;
      line-height: 2.5rem;
      text-align: center;
    }

    p {
      margin: 0;
    }
  }

  .data {
    max-height: calc(100vh - 40rem);
    overflow-y: auto;
  }

  .footer {
    padding-top: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .supplier-list {
      width: 26rem;
    }

    .add-to-cart {
      width: 15rem;

      .spinner {
        svg {
          circle {
            fill: $blue-icon-color;
          }
        }
      }

      button {
        height: 4.2rem;
        border-radius: 0.5rem;
        color: $white;
        @include themify($themes) {
          background-color: themed('main');
        }
      }

      button.disabled {
        background-color: $background-disabled-button;
      }
    }
  }
}
