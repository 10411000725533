.button-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;

  .sidebar {
    width: 300px;
    height: 100vh;
  }

  &:disabled {
    cursor: not-allowed !important;
    opacity: 50%;
    pointer-events: none;
  }

  .wrapper {
    margin-bottom: 0;
  }


}