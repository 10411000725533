@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';
@import 'src/assets/sass/masonry';

.presentation-categories {
  padding: 0 15rem;
  box-sizing: border-box;

  & .categories-header {
    display: flex;
    align-items: center;
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 1.5rem;
    width: 100%;

    & .categories-return {
      cursor: pointer;
      white-space: nowrap;
      z-index: 1;

      &:hover {
        opacity: 75%;
      }
    }

    & .categories-title {
      width: 100%;
      font-weight: bold;
      text-align: center;
      margin-left: -6.5rem;
    }
  }

  & .category-cards {
    // display: flex;
    // grid-template-columns: repeat(auto-fill, minmax(25rem, auto));
    // width: 100%;
    // gap: 0 1rem;
    // position: relative;
    max-width: 1370px;
    margin: 0 auto;
    padding: 0 0 50px 0;

    & .categories-spinner {
      top: 15rem;
    }
  }
}
