@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.tcrmi-icons-bar {
  padding: 0 0.5rem;
  display: flex;
  height: 5rem;
  width: min-content;

  @include themify($themes) {
    background-color: themed('secondary-3');
  }

  margin-bottom: 2.5rem;
  border-radius: 0.5rem;

  .tecrmi-icon-list {
    display: flex;
    width: min-content;
    gap: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;

    & .image {
      min-width: 3.9rem;
      min-height: 3.9rem;

      & svg {
        width: 1.9rem;
        height: 1.8rem;
      }
    }

    .text {
      display: none;
    }
  }
}