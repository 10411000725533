@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.CustomValidationMessages.custom-validation-messages {
  .error-message {
    display: block;
    font-weight: normal;
    font-size: 1rem;
    color: $red;
  }
}
