@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.order-clients-view-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 2.7rem 2rem;
  position: relative;

  @media screen and (max-width: 768px) {
    box-sizing: border-box;
    padding: 0.7rem 0rem;
  }

  & .order-clients-back {
    position: absolute;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 14px;
    cursor: pointer;
    padding: 1rem;

    @media screen and (max-width: 768px) {
      position: relative;
      width: 45%;
      justify-content: left;
    }
  }

  .order-clients-title {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .order-clients-title-view {
    display: flex;
    justify-content: center;
    font-size: 3rem;
    @include themify($themes) {
      color: themed('main');
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  hr {
    border: 1px solid $grey-5;
    width: 100%;

    @media screen and (max-width: 990px) {
      width: 95%;
    }
  }

  & .order-view-body {
    display: flex;
    gap: 2.2rem;

    @media screen and (max-width: 990px) {
      flex-direction: column;
    }
  }

  & .order-table {
    @include themify($themes) {
      background: themed('secondary-2');
    }
    border-radius: 1rem;
    padding: 3rem 2.5rem;
    max-width: 81.6rem;
    width: 100%;

    @media screen and (max-width: 990px) {
      padding: 1rem;
      box-sizing: border-box;
    }

    & .order-table-wrapper {
      tbody {
        @include themify($themes) {
          background: themed('secondary-2');
        }
      }
    }
  }

  & .chat {
    flex: 1;

    .order-messages-wrapper {
      .container {
        @media screen and (max-width: 990px) {
          padding: 1rem;
          box-sizing: border-box;
        }
      }
    }
  }
}
