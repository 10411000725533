@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.plate-number {
  width: 100%;
  .form {
    position: relative;
    & .reference-label {
      font-weight: 800;
      font-size: 1.4rem;
      @include themify($themes) {
        color: themed('main');
      }
      padding: 0 1rem;
    }

    .error-message {
      display: block !important;
      color: $red;
      margin: 0;
    }
  }
}
