@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.icon-notification {
  cursor: pointer;
  padding: 0.4rem 1rem;
  position: relative;
  font-size: 2.2rem;

  .icon {
    width: 2rem;
    height: 2rem;
    @include themify($themes) {
      color: themed('main');
    }
  }

  span {
    font-size: 1.1rem;
    line-height: 1.5rem;
    letter-spacing: normal;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $red;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    position: absolute;
    top: 20%;
    left: 18%;
    transform: translateY(-40%);
    text-align: center;
  }
}
