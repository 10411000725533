@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

tr.more-information {
  td {
    width: 100%;
    border: 1px solid $border-color;
    border-radius: 0.5rem;
    padding: 0 1rem;
    span {
      font-weight: bold;
    }
  }
}
