@import 'assets/sass/variables';

.table-wrapper-parts-espace {
  width: 100%;
  max-height: 40rem;
  padding-bottom: 5rem;
  overflow: auto;

  table {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2rem;
    line-height: 1.4rem;
    text-align: left;
    letter-spacing: normal;
    color: $blue-1;
    width: 100%;
    background: $white-5;
    border-spacing: 0rem;
    padding: 0.8rem 2.5rem;
    border-radius: 0.5rem 0.5rem 0 0;

    @media screen and (max-width: 768px) {
      padding: 0.4rem 0.2rem;
    }

    .container-pinner {
      height: 3.5rem;
      position: relative;
    }

    .headings {
      position: sticky;
      top: 0px;
      background-color: #f1f5fc;
      z-index: 1;

      .row {
        text-align: center;

        &.is-clickable {
          cursor: pointer;
        }

        .heading {
          height: 3.4rem;
          font-weight: 700;
        }

        &:first-child {
          .heading {
            .table-title {
              height: 3.4rem;
              padding: 0.2rem;
              background-color: $white-3;
              border-radius: 0.5rem;
              text-align: center;
              margin: 0rem 0.4rem 0.4rem 0.4rem;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 700;
            }

            &:last-child {
              .table-title {
                height: 8rem;
              }
            }
          }
        }
      }
    }

    .body {
      max-height: 40rem;
      overflow: auto;

      .row {
        text-align: center;

        .cell {
          padding: 0.85rem 0.4rem 0.85rem 1.4rem;
          background-color: $white-3;
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 1.6rem;

          border: 0.7rem solid #f1f5fc;
          border-right: 0;
          border-left: 0;

          &:first-child {
            border-radius: 0.5rem 0 0 0.5rem;
          }

          &:last-of-type {
            border-radius: 0 0.5rem 0.5rem 0;
          }

          .name_red {
            color: #ca312a;
          }

          .referance_replaced {
            display: flex;
            flex-direction: column;

            .reference_old {
              padding-left: 1rem;
              font-size: 10px;
              cursor: pointer;

              span {
                color: #8494c0;
              }
            }

            .item_replaced {
              color: #ca312a;
              text-align: left;
              padding-left: 1rem;
              cursor: pointer;
            }
          }

          .referance_replaced_false {
            display: flex;
            flex-direction: column;

            .reference_old {
              padding-left: 1rem;
              font-size: 10px;
              color: #ca312a;
              cursor: pointer;

              span {
                color: #8494c0;
              }
            }

            .item_replaced {
              text-align: left;
              padding-left: 1rem;
              cursor: pointer;
            }
          }

          .iconRepeat {
            margin: 0.8rem 0rem 0rem 1rem;
            font-size: 1.4rem;
            cursor: pointer;
          }

          .reference {
            padding-left: 1rem;
            cursor: pointer;
          }

          .background-red {
            background-color: #ca312a;

            ul {
              padding: 0.2rem;
              margin: 0.5rem;
            }
          }

          .price_head {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;

            .price {
              .color-red {
                color: #ca312a;
              }
            }

            .ind_equivalence {
              .price_ind_equivalence_red {
                color: #ca312a;
              }

              .price_ind_equivalence_blue {
                color: $blue-18;
              }
            }
          }

          .ind_reference_selected {
            color: $blue-18 !important;
          }

          .color-yellow {
            color: #4dc00d;
          }

          .font-icon {
            color: #ca312a;
            font-size: 1.3rem;
          }

          .reference-head {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            .reference-name {
              display: flex;
              justify-content: flex-start;
              flex-direction: row;
              padding-left: 1rem;
              position: relative;
              margin-right: 4rem;

              .min_quantity {
                display: none;
                position: absolute;

                padding: 1rem 2rem;
                background-color: #ca312a;
                color: $white;
                top: -4rem;
                left: -3rem;
                border-radius: 5px;
                z-index: 99999999999;
              }
            }

            .ind_equivalence {
              .reference_ind_equivalence_red {
                color: #ca312a;
                cursor: pointer;
              }

              .reference_ind_equivalence_blue {
                color: $blue-18;
                cursor: pointer;
              }
            }
          }
        }

        .cell-title {
          padding: 0.85rem 0.4rem 0.85rem 0.4rem;

          background-color: $blue-21;
          font-size: 1.2rem;
          color: $blue-1;
          font-weight: 700;
          font-family: 'Open Sans';
          border-radius: 0.5rem;

          .cell-title-franco {
            display: flex;
            justify-content: space-between;
            position: relative;
            align-items: center;

            padding: 0rem 2rem;

            .franco_ind_equivalence {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: self-start;
              gap: 1rem;
            }

            .total_ind_equivalence {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              align-items: flex-end;
              gap: 1rem;
            }

            .franco {
              font-family: 'Times New Roman', Times, serif;
              font-style: italic;
              font-weight: 300;
              font-size: 1.3rem;
              line-height: 16px;
            }

            .red {
              color: #ca312a;
              cursor: pointer;
            }

            .not-red {
              padding-left: 1.5rem;
            }

            .background-red {
              background-color: #ca312a;
            }

            .min_franco {
              display: none;
              position: absolute;
              padding: 1rem 2rem;
              background-color: #ca312a;
              color: $white;
              top: -4rem;
              left: -2.5rem;
              border-radius: 5px;
              z-index: 99999999999;
            }
          }
        }
      }
    }

    .notFond1 {
      text-align: center;
      font-size: 2rem;
      font-weight: bold;
      color: $blue;
      opacity: 60%;
      margin-top: 5rem;
    }
  }

  .table-scroll {
    height: 40rem;
    overflow: auto;
  }

  .tfoot {
    display: flex;
    background: #d5e2fc;
    height: 2.8rem;
    color: $blue-1;
    border-radius: 0 0 0.5rem 0.5rem;
    align-items: center;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.9rem;
    position: absolute;
    width: 100%;

    @media screen and (max-width: 768px) {
      width: 90%;
    }

    .tfoot-td-1 {
      border-right: $blue-1 0.1rem solid;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .tfoot-td-2 {
      border-left: $blue-1 0.1rem solid;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .action {
    display: flex;
    flex-direction: row;
    float: right;

    .action_btn {
      margin: 0 0.5rem 0 2rem;

      .action_btn_remove {
        border: none;
        cursor: pointer;
        background-color: transparent;

        .action_btn_remove_icon {
          color: $red;
          font-size: 16px;

          &:hover {
            color: $blue;
          }
        }
      }
    }
  }

  .wrapper-quantity {
    justify-content: center;

    .num {
      background: $blue-2;
      color: $blue;

      &:focus-visible {
        outline: none;
      }
    }
  }

  .status-green {
    color: $green-4;
  }

  .status-red {
    color: $red;
  }
}

/* Responsive CSS Styles */

.app-layout-desktop {}

.app-layout-tablet {}

.app-layout-tablet,
.app-layout-mobile {
  .table-wrapper-tariff-default {
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;

    table {
      .row {
        &:first-child {
          .heading {
            .table-title {
              margin: 0.4rem 0;
            }

            &:last-child {
              .table-title {}
            }
          }
        }
      }
    }
  }
}

.app-layout-mobile {
  .table-wrapper-tariff-default {
    table {
      padding: 0;
    }
  }
}