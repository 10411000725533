@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.body {
  .row {
    font-size: 0.75rem;

    .cell {
      @include themify($themes) {
        border-top: 2px solid themed('table', 'border');
      }

      padding: 8px 0px;
      font-size: 1.2rem;
      line-height: 1.7rem;
      background: $white-6;
      text-align: left;

      @include themify($themes) {
        color: themed('main');
      }

      height: 30px;
      max-width: min-content;
    }
  }
}

.cart-table-container {
  position: relative;
  min-height: 40rem;
  min-width: 100rem;

  @media screen and (max-width: 768px) {
    min-width: unset;
    min-height: 30rem;
  }

  @media screen and (max-width: 990px) {
    min-width: unset;
  }

  .wrapper {
    table-layout: auto;
    width: 100%;
    background: $white-6;
    border-spacing: 0 0.8rem;
    padding: 1.25rem;

    .headings {
      .row {
        .heading {
          @include themify($themes) {
            color: themed('main');
          }

          text-align: left;
          font-weight: 300;
          font-size: 1.2rem;
        }
      }
    }
  }
}