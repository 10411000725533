@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.technical-documentations-page {
  display: flex;
  gap: 10.3rem;

  @media screen and (max-width: 768px) {
    margin-bottom: auto;
  }

  @media screen and (max-width: 990px) and (min-width: 768px) {
    gap: 2.3rem;
  }

  & .technical-documentations-sidebar {
    width: 100%;
    max-width: 31.5rem;

    @media screen and (max-width: 768px) {
      max-width: none;
    }

    .__react_component_tooltip {
      visibility: hidden;
    }

    .sidebar-title {
      font-size: 28px;
      font-weight: 700;
      max-width: 300px;
      padding: 0 10px;
    }

    .sidebar-list-item {
      gap: 10px;
      font-weight: 700;
      padding: 0 10px;
      cursor: pointer;
    }
  }

  & .technical-documentations-dashboard {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    margin-right: 7.8rem;

    @media screen and (max-width: 990px) and (min-width: 768px) {
      margin-right: 0rem;
      width: 100%;
    }

    & .dashboard-title {
      font-size: 1.4rem;
      @include themify($themes) {
        color: themed('main');
      }
      margin-top: 4.5rem;
      font-weight: bold;
    }

    & .dashboard-first-row {
      display: flex;
      gap: 1rem;

      &-item {
        width: 24.7rem;
        height: 21.7rem;
        @include themify($themes) {
     background-color: themed('secondary');
}

        @media screen and (max-width: 990px) and (min-width: 768px) {
          width: 13.7rem;
          height: 11.7rem;
        }

        @media screen and (max-width: 1200px) {
          width: 13.7rem;
          height: 14.7rem;
        }
      }
    }

    & .dashboard-second-row {
      display: flex;
      gap: 1rem;

      &-item {
        width: 37.5rem;
        height: 21.7rem;
        @include themify($themes) {
     background-color: themed('secondary');
}

        @media screen and (max-width: 990px) and (min-width: 768px) {
          width: 13.7rem;
          height: 11.7rem;
        }

        @media screen and (max-width: 1200px) {
          width: 17.7rem;
          height: 14.7rem;
        }
      }
    }
  }
}
