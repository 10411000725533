@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.catalog-navbar-container-tyre {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $grey-1;
  padding: 1rem 0;
  width: 100%;

  .breadcrumbs {
    display: flex;
    align-items: center;
    gap: 2.3rem;

    @include themify($themes) {
      color: themed('main');
    }

    font-size: 2.5rem;
  }

  .input {
    .catalog-parts-filter {
      display: flex;
      align-items: center;
      gap: 2rem;

      & .catalog-search-input-container {
        position: relative;

        .text-input-container {
          width: 55rem;


          @media screen and (max-width: 1310px) {
            width: 55rem;

          }

          @media screen and (max-width: 1242px) {
            width: 40rem;

          }

          @media screen and (max-width: 1044px) {
            width: 35rem;

          }

          @media screen and (max-width: 980px) {
            width: 30rem;

          }
        }


      }

    }
  }

  .options {
    .catalog-parts-filter {
      display: flex;
      align-items: center;
      gap: 2rem;

      & .catalog-search-input-container {
        position: relative;
      }

      .catalog-parts-desc {
        font-size: 11px;

        @include themify($themes) {
          color: themed('main');
        }
      }

      .catalog-parts-button {
        &.active {
          pointer-events: none;
        }

        padding: 5px;

        @include themify($themes) {
          background-color: themed('main');
        }

        color: $white;
        border: none;
        font-weight: 500;
        width: 180px;
        height: 40px;
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }
}

/* Responsive CSS Styles */

.app-layout-desktop {}

.app-layout-tablet {}

.app-layout-tablet,
.app-layout-mobile {
  .catalog-navbar-container {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-bottom: 1rem;
    width: 100%;
    background: $background-grey;
    border-bottom: none;
    flex-direction: column;
    gap: 1.5rem;
    border-bottom: none;

    .breadcrumbs {
      font-size: 2rem;
      text-align: center;
      padding: 2rem 2rem 0;
    }

    .options {
      position: relative;
      padding: 0 2rem;
      width: calc(100% - 4rem);
      display: flex;
      justify-content: center;

      &>div {
        position: relative;
        z-index: 1;
      }

      &::after {
        content: '';
        height: 2rem;
        width: 100%;
        position: absolute;
        display: block;
        bottom: 0;
        z-index: 0;
      }
    }

    .input {
      .catalog-search-input-container {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }
}

.app-layout-mobile {
  @media screen and (max-width: 480px) {
    .input {
      width: calc(100% - 4rem);
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}