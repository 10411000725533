@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.orderTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 20rem;

  & .orderTable-container {
    min-height: 10rem;

    @include themify($themes) {
      background: themed('secondary-2');
    }

    border-radius: 1rem;
    position: relative;

    & .order-table-small-spinner {
      position: absolute;

      &.has-data {
        position: relative;
        margin-bottom: 0.5rem;
      }
    }

    table {
      font-family: 'Open Sans', sans-serif;
      font-size: 1.2rem;
      line-height: 1.7rem;
      text-align: left;
      letter-spacing: normal;

      @include themify($themes) {
        color: themed('main');
      }

      width: 100%;

      @include themify($themes) {
        background: themed('secondary-2');
      }

      border-spacing: 0 0.7rem;
      padding: 0.4rem 2.5rem;
      border-radius: 1rem;

      @media screen and (max-width: 768px) {
        padding: 1rem;
        box-sizing: border-box;
      }

      .comment {
        width: 1.25rem;
        height: 1.25rem;

        @include themify($themes) {
          fill: themed('main');
        }
      }

      .headings {
        .row {
          .heading {
            padding: 0.4rem 0.4rem 0.4rem 1.4rem;

            .icons {
              .fa-caret-down {
                margin-top: -0.5rem;
              }
            }
          }
        }
      }

      .body {
        .body-cell-row {
          @include themify($themes) {
            background-color: themed('secondary-3');
          }

          &:hover {
            opacity: 50%;
            cursor: pointer;
          }

          .cell {
            padding: 0.85rem 0.4rem 0.85rem 1.4rem;

            &:first-child {
              border-radius: 0.5rem 0 0 0.5rem;
            }

            &:last-of-type {
              border-radius: 0 0.5rem 0.5rem 0;
              background-color: $blue-5;
            }
          }
        }
      }
    }
  }

  & .order-no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.1rem 2.4rem;
    font-size: 1.4rem;

    @include themify($themes) {
      color: themed('main');
    }

    font-weight: bold;
  }

  & .sellers-table-spinner {
    background: none;
    position: absolute;
  }
}