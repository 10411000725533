@import 'assets/sass/variables';
@import 'src/assets/sass/themes';
@import 'assets/sass/_mixins';

.clients-catalog.mobile {
  @media screen and (max-width: 568px) {
    .buttons {
      flex-direction: column;
      margin-bottom: 1.5rem;
    }
  }

  .catalog-items {
    display: flex;
    justify-content: center;

    .swiper-slide {
      max-width: 20.5rem;
      margin: 0px 1rem;

      .navbar-item {
        width: 100%;
      }
    }
  }
}
