@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';
@import 'src/assets/sass/_mixins';

.espace-reliable-catalog-container {
  margin: 0.7rem 0 3rem;

  button {
    width: 21.7rem;
    height: 3.4rem;
    text-align: center;
    padding: 0.7rem;
    border-radius: 0.8rem;
    font-size: 1.12rem;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 1.5rem;
    font-family: 'Open Sans', sans-serif;
    color: $white;
    transition: none;

    @media screen and (max-width: 993px) {
      width: 100%;
      padding: 0 3rem;
      white-space: nowrap;
    }
  }

  p {
    margin: 0;
    @include themify($themes) {
      color: themed('main');
    }
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5rem;
    letter-spacing: 0;
    line-height: 2rem;
  }

  .espace-reliable-catalog {
    background-color: $white;
    border-radius: 0.5rem;
    height: 9rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 3rem;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      padding: 3rem 0;
      height: auto;
    }

    @media screen and (max-width: 990px) and (min-width: 768px) {
      flex-direction: column;
      padding: 3rem 0;
      height: auto;
    }

    .reliable-catalog-type {
      svg {
        max-width: 12.4rem;
        max-height: 1.7rem;
        margin: 0 auto;
        display: block;
        g > rect {
          @include themify($themes) {
            fill: themed('main');
          }
        }
      }

      p {
        margin: 0;
        @include themify($themes) {
          color: themed('main');
        }
        font-family: 'Open Sans', sans-serif;
        font-size: 2.3rem;
        letter-spacing: 0;
        line-height: 3.2rem;
        text-align: center;
      }
    }

    .reliable-catalog-info {
      p {
        margin: 0;
        width: 32.9rem;

        @media screen and (max-width: 990px) {
          width: auto;
          margin: 0 auto;
          padding: 0 1rem;
          text-align: center;
        }

        span {
          font-weight: 700;
        }
      }

      @media screen and (max-width: 768px) {
        margin: 0 auto;
      }
    }

    .reliable-catalog-button {
      .espace-catalog-button {
        text-align: center;
        padding: 1rem 6rem;
        border-radius: 0.8rem;
        font-size: 1.12rem;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 1.5rem;
        font-family: 'Open Sans', sans-serif;
        color: #ffffff;
        transition: none;
        @include themify($themes) {
          background-color: themed('main');
        }
        cursor: pointer;
        outline: none;
        border: none;
        transition: all 0.3s ease-in-out;
        text-decoration: none;
        white-space: nowrap;

        & a {
          color: white;
          text-decoration: none;
        }

        &:hover {
          background-color: $blue-5;
          @include themify($themes) {
            color: themed('main');
          }
        }
      }

      @media screen and (max-width: 768px) {
        margin: 0 auto;
      }
    }
  }
}
