@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.collapse-list-diagnostic {
  & .item {
    font-size: 1.4rem;
    font-weight: bold;
    cursor: pointer;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.level-2 {
      margin-top: 1rem;
      padding-left: 2rem;
    }

    &.level-3 {
      margin-top: 1rem;
      padding-left: 4rem;
    }

    &.last-level {
      margin-top: 1rem;
      padding-left: 9rem;
    }

    &.active {
      color: $white;
      @include themify($themes) {
        background-color: themed('main');
      }
      padding: 0.5rem 1rem;
      border-radius: 10rem;
      box-sizing: border-box;
      width: fit-content;
      pointer-events: none;
    }

    &.no-pointer-events {
      pointer-events: all;
    }

    &:hover {
      opacity: 80%;
    }

    & .collapse-item {
      display: flex;
      align-items: center;
      gap: 2rem;
    }

    & .iframe-info-btn {
      @include themify($themes) {
        background-color: themed('main');
      }
      color: $white;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
