@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.forgot-password-wrapper {
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: $hovered-blue;
  height: 100vh;
  position: relative;

  .content {
    padding: 0 2.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $white;

    .logo {
      width: 36.6rem;
      margin: 5rem 0 2rem 0;
    }

    .title {
      font-size: 1.8rem;
      font-weight: bold;
      text-align: center;
    }

    .description {
      font-size: 1.3rem;
      @include themify($themes) {
        color: themed('main');
      }
      text-align: center;
      margin: 1rem 0rem;

      span {
        font-weight: 700;
      }
    }

    .formWrapper {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .back-to-signin {
        cursor: pointer;
        margin: 0;
        @include themify($themes) {
          color: themed('main');
        }
        opacity: 0.5;
        font-size: 1.2rem;
        text-transform: none;
        text-align: center;
        font-weight: normal;
        width: 10rem;
        margin-bottom: 1rem;
      }

      .inputs {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;

        .error {
          font-weight: 700;

          p {
            margin: 0;
          }

          .error-message {
            color: #ca312a !important;
            display: block;
            margin-top: 0.5rem;
            font-size: 1rem;
          }

          select,
          input {
            border: 1px solid $red !important;
          }
        }

        label {
          @include themify($themes) {
            color: themed('main');
          }
          font-weight: 700;
          font-size: 1.4rem;

          & .back-to-signin {
            cursor: pointer;
            margin: 0;
            @include themify($themes) {
              color: themed('main');
            }
            opacity: 0.5;
            font-size: 1.2rem;
            text-transform: none;
            text-align: left;
            font-weight: normal;
            width: 10rem;
          }
        }

        input {
          width: 26.8rem;
          height: 4.2rem;
          font-size: 1.4rem;
          margin: 0.5rem 0rem;

          &::placeholder {
            font-size: 1.4rem;
          }
        }
      }

      p {
        text-transform: uppercase;
        @include themify($themes) {
          color: themed('main');
        }
        font-size: 2rem;
        text-align: center;
      }

      i {
        padding: 0rem 1rem;
      }

      button {
        width: 26.8rem;
        height: 4.1rem;
        border-radius: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        margin-bottom: 1.2rem;

        span {
          font-size: 1.2rem;
        }
      }
    }
  }
}
