@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.table-retailer-order {

    .headings {
        .row {
            .heading {
                @include themify($themes) {
                    color: themed('main');
                }

                font-weight: bold;
                text-align: left;
                font-size: 1.2rem;
                padding: .4rem .4rem .4rem 1.4rem;
            }
        }
    }

    table {
        @include themify($themes) {
            background: themed('table', 'bg');
        }

        .body {
            @include themify($themes) {
                background: themed('table', 'bg');
            }

            .cell-row {
                &:hover {
                    opacity: 60%;
                    cursor: pointer;

                    @include themify($themes) {
                        background-color: themed('table', 'hover');
                    }
                }

                @include themify($themes) {
                    background-color: themed('table', 'cellColor');
                }

                @include themify($themes) {
                    color: themed('main');
                }

                font-size: 1.2rem;

                .cell {
                    padding: .85rem .4rem .85rem 1.4rem;
                    max-width: max-content;



                }

                .cell:last-of-type {
                    @include themify($themes) {
                        background-color: themed('table', 'cellTableLast');
                    }

                    border-radius: 0 .5rem .5rem 0;
                }
            }
        }
    }
}