@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.new-pagination {
  width: 100%;
  padding: 2rem 0;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 0.5rem;
    @include themify($themes) {
      background-color: themed('secondary-3');
      color: themed('main');
    }
    font-size: 1.6rem;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      @include themify($themes) {
        background-color: themed('main');
        color: themed('secondary-3');
      }
    }

    &.active {
      @include themify($themes) {
        background-color: themed('main');
        color: themed('secondary-3');
      }
    }

    &.disabled-page-btn {
      cursor: not-allowed;
      @include themify($themes) {
        background-color: themed('secondary-3');
        color: themed('main');
      }
      opacity: 50%;
    }
  }
}
