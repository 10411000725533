@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.password-strength-wrapper {
  .card {
    overflow: hidden;
  }

  .input-container {
    border-radius: 0.5rem;
    overflow: hidden;

    .text-input-container {
      width: 100%;
      position: relative;

      .text-input {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 4.2rem;
        border-radius: 0.5rem;
        border: none;
        padding: 0 1rem;
        font-size: 1.6rem;
        font-weight: 400;

        @include themify($themes) {
          color: themed('main');
        }

        outline: none;
        transition: all 0.3s ease-in-out;
        background-color: $input-color;
        box-sizing: border-box;

        &::placeholder {
          color: rgba(39, 80, 159, 0.5);
          font-size: 1.4rem;
        }
      }

      &.has-eye-icon {
        input {
          padding-right: 45px !important;
        }
      }

      & .input-search-icon {
        @include themify($themes) {
          color: themed('main');
        }

        position: absolute;
        padding: 1rem;
        font-size: 2rem;
        top: 0;
        right: 0;
        width: 25px;
      }
    }
  }

  .input {
    width: 20rem;
    font-size: 1rem;
    letter-spacing: 0.08rem;
    outline: none;
    border: none;
    padding: 0.8rem 0.3rem;
    background-color: transparent;
  }

  .input::placeholder {
    color: #b8bac5;
    font-size: 0.9rem;
  }

  .input-box {
    display: flex;
    align-items: center;
    position: relative;
  }

  .progress-bg {
    width: 100%;
    height: 0.5rem;
    background-color: transparent;
    border-radius: 0 0 10rem 10rem;
    position: relative;
    overflow: hidden;
    margin-top: -0.5rem;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    max-width: calc(100% - 0.5rem);
  }

  .progress {
    width: 0%;
    height: 0.5rem;
    position: absolute;
    transition: all 0.5s ease-out;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0.25rem;
    overflow: hidden;
  }

  .message {
    margin: 0;
    height: 25px;
    line-height: 25px;

    .message-text {
      font-size: 1rem;
      font-weight: 700;
      color: $red;
    }
  }
}

.error-input {
  .password-strength-wrapper {
    .input-container {
      .text-input-container {
        input {
          border: none !important;
        }
      }
    }
  }
}