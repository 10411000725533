@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.table-wrapper-industrial {
  width: 100%;

  table {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2rem;
    line-height: 1.4rem;
    text-align: left;
    letter-spacing: normal;

    @include themify($themes) {
      color: themed('main');
    }

    width: 100%;

    @include themify($themes) {
      background: themed('secondary-2');
    }

    border-spacing: 0 0.7rem;
    padding: 0.4rem 2.5rem;
    border-radius: 0.5rem 0.5rem 0 0;

    .container-pinner {
      height: 3.5rem;
      position: relative;
    }

    .row {
      text-align: center;

      .heading {
        height: 3.4rem;
        font-weight: 700;
      }

      .heading {
        .table-title {
          height: 3.4rem;
          padding: 0.2rem;

          @include themify($themes) {
            background-color: themed('secondary-3');
          }

          border-radius: 0.5rem;
          text-align: center;
          margin: 0rem 0.4rem 0.4rem 0.4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 700;
        }
      }
    }

    .body {
      .row {
        .cell {
          padding: 0.85rem 0.4rem 0.85rem 1.4rem;

          @include themify($themes) {
            background-color: themed('secondary-3');
          }

          font-weight: 400;
          font-size: 1.2rem;
          line-height: 1.6rem;

          &:first-child {
            border-radius: 0.5rem 0 0 0.5rem;
          }

          &:last-of-type {
            border-radius: 0 0.5rem 0.5rem 0;
          }
        }
      }

      .notFond {
        text-align: center;
      }
    }
  }

  .tfoot {
    display: flex;

    @include themify($themes) {
      background-color: themed('table', 'footer');
    }

    height: 2.8rem;

    @include themify($themes) {
      color: themed('main');
    }

    border-radius: 0 0 0.5rem 0.5rem;
    align-items: center;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.9rem;

    .tfoot-td-1 {
      border-right: $blue-1 0.1rem solid;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .tfoot-td-2 {
      border-left: $blue-1 0.1rem solid;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}