@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.supplier-card {
  display: flex;
  flex-direction: column;
  background-color: #eaf0fb;
  width: 25rem;
  height: 23rem;
  border-radius: 1rem;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 75%;
  }

  & .image-holder {
    background-color: $blue-14;
    width: 21rem;
    height: 14rem;
    margin-top: 1.6rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
      max-width: 14rem;
      max-height: 10rem;
    }
  }

  & .supplier-name {
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 1.6rem;
    margin-top: 2.5rem;
  }
}
