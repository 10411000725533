@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.new-parts-filter {
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 0;
  width: 100%;

  @include themify($themes) {
    background-color: themed('secondary-2');
  }

  gap: 1rem;
  padding-bottom: 2rem;
  z-index: 30;
  bottom: -100%;
  transition: bottom 0.3s;
  max-height: calc(100% - 10rem);
  overflow-y: auto;

  &.active {
    bottom: 0;
  }

  & .header-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 1.5rem 0 1.5rem;

    & .buttons-container {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 0.5rem;
    }

    & .icon {
      font-size: 2rem;
      font-weight: bold;

      @include themify($themes) {
        color: themed('main');
      }

      cursor: pointer;
    }
  }

  & .title {
    display: flex;
    justify-content: center;
    font-size: 2.3rem;
    font-weight: 300;
    gap: 0.5rem;
  }

  & .filters {
    display: block;
    gap: 1.5rem;
    padding: 0 5rem;
    margin-bottom: 4rem;

    svg {
      cursor: pointer;
    }

    & .dropdowns-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1.1rem;
      align-items: flex-end;
      width: 100%;

      @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: initial;
      }

      &.disabled {
        opacity: 30%;
        pointer-events: none;
        user-select: none;
      }

      [class$='-control'] {
        @include themify($themes) {
          color: themed('main');
        }

        border: none;
        background-color: $white;
        box-shadow: none;

        &:hover {
          border: none;
        }
      }

      [class$='-placeholder'] {
        @include themify($themes) {
          color: themed('main');
        }

        font-size: 1.4rem;
        font-weight: 400;
      }

      [class$='-indicatorContainer'] {
        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-singleValue'] {
        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-indicatorSeparator'] {
        background: none;

        @include themify($themes) {
          color: themed('main');
        }
      }

      [class$='-indicator'] {
        @include themify($themes) {
          background: themed('main');
        }

        color: $white;
      }

      & .dropdown-label {
        font-weight: 800;
        font-size: 1.2rem;
        line-height: 20px;

        @include themify($themes) {
          color: themed('main');
        }
      }

      .checkbox-select {
        display: flex;
        min-width: 23rem;
        padding: 0;
        justify-content: flex-start;
        width: 100%;
        margin-left: -10px;

        .checkbox-item {
          position: relative;
          margin: 0.3rem;

          input[type='checkbox'] {
            z-index: 2;
            cursor: pointer;
            position: absolute;
            width: 1.5em;
            height: 1.5em;
            opacity: 0;

            +label {
              &:before {
                content: '';
                background: $blue-10;
                border-radius: 100%;
                border: 2px solid $blue;
                display: inline-block;
                position: relative;
                top: -0.2em;
                margin-right: 0.5em;
                vertical-align: top;
                text-align: center;
                width: 1.5em;
                height: 1.5em;
              }
            }

            &:checked {
              +label {
                &:before {
                  @include themify($themes) {
                    background-color: themed('main');
                  }
                }
              }
            }
          }

          label {
            font-size: 1.4rem;
            line-height: 1.9rem;
            font-weight: 700;
            margin-bottom: 0.5rem;
            padding-left: 0.4rem;

            @include themify($themes) {
              color: themed('main');
            }
          }

          .check-icon {
            position: absolute;
            left: 10px;
            top: 3px;
            color: $blue-10;
            font-size: 14px;
          }
        }
      }
    }

    .container-criteria {
      position: relative;

      .dropdown-label {
        font-size: 1.2rem;
        font-weight: 800;
        line-height: 30px;
        margin-top: 20px;

        @include themify($themes) {
          color: themed('main');
        }
      }

      .panel {
        position: relative;
        margin: 2em auto;
        height: fit-content;
        transition: height 0.5s ease;
      }

      .btn {
        border-radius: 1.5em;
        left: 0;
        padding: 1em;
        text-decoration: none;
        font-size: 16px;

        @include themify($themes) {
          color: themed('main');
        }

        cursor: pointer;
        padding: 0 0.3rem;
        font-weight: bold;

        &:hover {
          opacity: 0.8;
        }
      }

      .show,
      .hide {
        position: absolute;
        bottom: -3em;
        z-index: 30;
        text-align: center;
      }

      .dropdowns-container-criteria {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 1.1rem;
        align-items: flex-end;
        width: 100%;

        @media screen and (max-width: 768px) {
          display: flex;
          flex-direction: column;
          align-items: initial;
        }

        // &.criteria-length-1,
        // &.criteria-length-2 {
        //   grid-template-columns: repeat(auto-fit, 25rem);
        // }

        & .dropdown-label {
          font-weight: 800;
          font-size: 1.2rem;
          line-height: 30px;
          margin-top: 20px;
        }

        [class$='-control'] {
          @include themify($themes) {
            color: themed('main');
          }

          border: none;
          background-color: $white;
          box-shadow: none;

          &:hover {
            border: none;
          }
        }

        [class$='-placeholder'] {
          @include themify($themes) {
            color: themed('main');
          }

          font-size: 1.4rem;
          font-weight: 400;
        }

        [class$='-indicatorContainer'] {
          @include themify($themes) {
            color: themed('main');
          }
        }

        [class$='-singleValue'] {
          @include themify($themes) {
            color: themed('main');
          }
        }

        [class$='-indicatorSeparator'] {
          background: none;

          @include themify($themes) {
            color: themed('main');
          }
        }

        [class$='-indicator'] {
          @include themify($themes) {
            background: themed('main');
          }

          color: $white;
        }
      }
    }

    .dropdown-second-label {
      line-height: 20px;

      @include themify($themes) {
        color: themed('main');
      }
    }
  }

  & .apply-btn {
    align-self: center;
    width: 24.3rem;
    padding-bottom: 1rem;
    box-sizing: border-box;

    @media screen and (max-width: 768px) {
      padding-bottom: 2rem;
    }
  }
}