@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.message-recipient-info {
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @include themify($themes) {
    color: themed('main');
  }

  @media screen and (max-width: 768px) {
    font-size: 1.4rem;
    width: 100%;
  }

  svg {
    padding-right: 1rem;
  }

  font-size: 1.8rem;
  line-height: 2.4rem;
  text-align: center;

  span {
    white-space: nowrap;
    padding-right: 1rem;
  }

  .spinner {
    svg {
      height: 2rem;
      padding-right: 0;
    }
  }
}
