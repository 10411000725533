@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.summary-order-container {
  max-width: 95%;
  width: 100%;
  text-align: center;
  padding: 0rem;
  box-sizing: border-box;

  & .title {
    margin-top: 4.7rem;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 3.3rem;

    @include themify($themes) {
      color: themed('main');
    }

    text-decoration: underline;

    .errorsValidate {
      font-size: 1.4rem;
      color: #ca312a;
    }
  }

  & .order-status {
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 3.3rem;
    margin-bottom: 4rem;

    @include themify($themes) {
      color: themed('main');
    }

    & span {
      font-weight: 700;
    }

    & .id {
      margin-right: auto;
    }

    & .status {
      margin-left: auto;
    }
  }

  .summary-sections {
    display: flex;
    position: relative;
    gap: 3rem;
    justify-content: center;

    & .info-bloc {
      position: relative;
      background: #eaf0fb;
      border-radius: 5px;
      margin-left: 3rem;
      max-width: 120rem;
      width: 100%;

      & .icon-confirmed {
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        background-color: #4dc00c;
        position: absolute;
        top: 15px;
        left: -30px;
        display: flex;
        justify-content: center;
        align-items: center;

        & svg {
          font-size: 40px;
          color: white;
        }

        &.not-confirmed {
          background-color: $red1;
        }

        &.not-found {
          @include themify($themes) {
            background-color: themed('main');
          }
        }

        &.not-valid {
          background-color: $red !important;
        }
      }

      & .title {
        margin: 0 4rem;
        font-weight: 700;
        font-size: 2rem;
        line-height: 2.7rem;

        @include themify($themes) {
          color: themed('main');
        }

        text-decoration: none;
        text-align: start;
        margin-top: 7px;
      }

      & .description {
        margin: 0 4rem;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.9rem;

        @include themify($themes) {
          color: themed('main');
        }

        padding-top: 2rem;

        & span {
          font-weight: 700;
        }
      }

      .btn-group {
        display: flex;
        justify-content: center;
        gap: 4rem;

        & .summary_btn {
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.6rem;

          @include themify($themes) {
            color: themed('main');
          }

          text-decoration-line: underline;
          margin: 1rem 0;
          cursor: pointer;
        }

        & .summary_btn_export {
          display: flex;
          justify-content: center;
          text-align: center;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.6rem;
          gap: 0.5rem;
          color: #ca312a;

          text-decoration-line: underline;
          margin: 1rem 0;
          cursor: pointer;

          svg {
            margin-top: 0.2rem;
          }

          .extra-small {
            margin-top: -0.3rem;
            width: 2rem;

            circle {
              stroke: #ca312a;
            }
          }

        }
      }


      & .resume {
        display: flex;
        justify-content: space-around;

        @include themify($themes) {
          background-color: themed('table', 'footer');
        }

        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1.9rem;

        @include themify($themes) {
          color: themed('main');
        }

        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 0.7rem 0;
        min-height: 1.9rem;

        // & .label {

        // font-weight: 700;
        // font-size: 1.4rem;
        // line-height: 1.9rem;

        // }

        // & .value {
        // border-left: 3px solid $blue-1;
        // }
      }
    }

    & .actions {
      display: flex;
      flex-direction: column;
      justify-content: center;

      & .form-items {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      & .errors {
        color: $red1;
      }
    }
  }
}