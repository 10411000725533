@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.search-by-ref.tyre-dropdown {
  .tyre-dropdown-content {
    position: absolute;

    @include themify($themes) {
      background-color: themed('generalSearch', 'bg');
    }

    border-radius: 1rem;
    margin-top: 0.2rem;
    z-index: 50;
    box-shadow: 0.2rem 0.2rem 1rem rgb(1 1 1 / 15%);
    min-width: 100%;
    font-size: 1.4rem;
    font-weight: bold;
    overflow-y: auto;
    max-height: 30rem;
    top: 6rem;

    & .tyre-dropdown-header {
      padding: 1.3rem 0.6rem 0.4rem 0.6rem;

      @include themify($themes) {
        border-bottom: 1px solid themed('border');
      }

      & .tyre-dropdown-title {
        @include themify($themes) {
          color: themed('main');
        }
      }
    }

    & .tyre-search-result-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      & .skeleton-line {
        margin: 0.6rem;
      }

      & .no-data {
        @include themify($themes) {
          color: themed('main');
        }

        padding: 1.1rem;
      }

      & .suggestion-list-item-tyre {
        list-style: none;
        padding: 1.1rem;
        cursor: pointer;
        width: 100%;
        box-sizing: border-box;

        &:last-child {
          border-radius: 0 0 1rem 1rem;
        }

        &:hover {
          background-color: $blue-5;
        }

        & .suggestion-item-title {
          display: flex;
          justify-content: space-between;

          @include themify($themes) {
            color: themed('main');
          }

          list-style: none;
          cursor: pointer;
        }
      }
    }
  }

  .tyre-help-container {
    display: flex;

    .help-container {
      cursor: pointer;

      @include themify($themes) {
        color: themed('main');
      }

      .tyre-help-icon {
        height: 14px;
        line-height: 16px;
      }
    }
  }
}