@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.catalog-vehicle-page.container-fluid-large {
  position: relative;
  .catalog-vehicle-content-wrapper {
    .universal-parts-navbar-container {
      justify-content: flex-end;

      .catalog-parts-desc {
        max-width: 400px;
      }

      .breadcrumbs {
        display: none;
      }
    }
  }
}

/* Responsive CSS Styles */

.app-layout-desktop {
}

.app-layout-tablet {
}

.app-layout-tablet,
.app-layout-mobile {
  .catalog-vehicle-page.container-fluid-large {
    .catalog-vehicle-content-wrapper {
      background: $white;

      .universal-parts-navbar-container {
        justify-content: center;

        .breadcrumbs {
          display: block;
        }
      }
    }
  }
}
