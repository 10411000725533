body {
  .Toastify {
    z-index: 9999999999;
    // position: relative;
  }

  /** Used to define container behavior: width, position: fixed etc... **/
  .Toastify__toast-container {
    max-width: 195px;
    height: auto;
    position: absolute;
    top: 11rem;
    z-index: 9999999999;

    @media screen and (max-width: 768px) {
      align-items: center;
      justify-content: center;
      max-width: 100%;
      display: flex;
      top: 8rem;
    }
  }

  /** Used to define the position of the ToastContainer **/
  // .Toastify__toast-container--top-left {
  // }

  // .Toastify__toast-container--top-center {
  // }

  // .Toastify__toast-container--top-right {
  // }

  // .Toastify__toast-container--bottom-left {
  // }

  // .Toastify__toast-container--bottom-center {
  // }

  // .Toastify__toast-container--bottom-right {
  // }

  /** Classes for the displayed toast **/
  .Toastify__toast {
    padding: 4px;
    width: 19.4rem;
    min-height: 69px;

    @media screen and (max-width: 768px) {
      min-height: 52px;
    }
  }

  // .Toastify__toast--rtl {
  // }

  .Toastify__toast-body {
    padding: 0.8rem 0.4rem;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1.44rem;
  }

  /** Used to position the icon **/
  // .Toastify__toast-icon {
  // }

  /** handle the notificaiton color and the text color based on the theme **/
  // .Toastify__toast-theme--dark {
  // }

  // .Toastify__toast-theme--light {
  // }

  .Toastify__toast-theme--colored.Toastify__toast--default {
    color: $white;
    background-color: $red-2;
  }

  // .Toastify__toast-theme--colored.Toastify__toast--info {
  // }

  // .Toastify__toast-theme--colored.Toastify__toast--success {
  // }

  // .Toastify__toast-theme--colored.Toastify__toast--warning {
  // }

  // .Toastify__toast-theme--colored.Toastify__toast--error {
  // }

  // .Toastify__progress-bar {
  // }

  // .Toastify__progress-bar--rtl {
  // }

  // .Toastify__progress-bar-theme--light {
  // }

  // .Toastify__progress-bar-theme--dark {
  // }

  // .Toastify__progress-bar--info {
  // }

  // .Toastify__progress-bar--success {
  // }

  // .Toastify__progress-bar--warning {
  // }

  // .Toastify__progress-bar--error {
  // }

  /** colored notifications share the same progress bar color **/
  // .Toastify__progress-bar-theme--colored.Toastify__progress-bar--info,
  // .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success,
  // .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning,
  // .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  // }

  /** Classes for the close button. Better use your own closeButton **/
  // .Toastify__close-button {
  // }

  // .Toastify__close-button--default {
  // }

  // .Toastify__close-button > svg {
  // }

  // .Toastify__close-button:hover,
  // .Toastify__close-button:focus {
  // }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }

    30% {
      opacity: 0.3;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    30% {
      opacity: 0.3;
    }

    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
    }

    70% {
      opacity: 0.7;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    70% {
      opacity: 0.7;
    }

    100% {
      opacity: 0;
    }
  }

  .toastifyFadeIn {
    display: flex;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
    animation-fill-mode: forwards;
  }

  .toastifyFadeOut {
    display: flex;
    -webkit-animation: fadeOut 1s;
    animation: fadeOut 1s;
    animation-fill-mode: forwards;
  }
}