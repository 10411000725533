@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.proforma-upload-file-amerigo {
  max-width: 48rem;
  width: 100%;
  text-align: center;
  border-radius: 1rem;
  cursor: pointer;
  margin: 0 auto;

  & .upload-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem;

    & .icon {
      width: 6.7rem;
      height: 8.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      & img {
        width: 100%;
        height: 100%;
      }
    }

    & .text {
      font-weight: 500;
      font-size: 2.5rem;
      line-height: 3.4rem;
      @include themify($themes) {
        color: themed('main');
      }
      p {
        margin: 0;
      }
      .error {
        font-size: 1.5rem;
      }
      .title {
        font-size: 2.2rem;
        line-height: 3rem;
      }
      .subtitle {
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: $anchor-text-color-proforma-modal;
        a {
          font-weight: bold;
          color: $anchor-text-color-proforma-modal;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
}
