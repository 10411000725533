@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.cart-view-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .cart-view-body {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    &.loading {
      min-height: 75vh;
    }

    @media screen and (max-width: 768px) {
      width: 90%;
    }

    p {
      width: 100%;
      text-align: center;
      border-bottom: 1px solid #e7e7e7;
      padding-bottom: 0.7rem;
    }

    & .empty_cart {
      padding: 2rem 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 40%);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      & .empty_cart_image {
        & img {
          margin-left: -10%;
          width: 100%;
          height: 100%;
          max-width: 24rem;
          max-height: 21rem;
        }
      }

      & .empty_cart_title {
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 1rem;
        @include themify($themes) {
          color: themed('main');
        }

        @media screen and (max-width: 768px) {
          font-size: 1.5rem;
          white-space: nowrap;
        }
      }
    }
  }

  .cart-checkout {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    @include themify($themes) {
      color: themed('main');
    }
    font-weight: bold;
    font-size: 1.5rem;
    padding: 1.5rem;
    margin-top: 8rem;
    width: 100%;
    box-sizing: border-box;

    & .checkout-button {
      width: 23rem;
      button {
        border-radius: 0.8rem;
      }
    }

    @media screen and (max-width: 768px) {
      margin-top: 1rem;
      flex-direction: column;
    }

    .text-input-container {
      width: 18rem;

      @media screen and (max-width: 768px) {
        width: 23rem;
      }

      input {
        border-radius: 0.924rem;
      }
    }
  }
}
