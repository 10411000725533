@import "src/assets/sass/variables";
@import "src/assets/sass/themes";

.equivalence-vehicle {
  min-height: 3rem;
  @include themify($themes) {
    color: themed("main");
  }

  .no-data {
    p {
      text-align: center;
      font-size: 2rem;
    }
  }

  .content {
    .filters {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      top: 0;
      right: calc(50% - 33rem);

      @media screen and (max-width: 768px) {
        position: relative;
        flex-direction: column;
        margin-top: 15px;
        right: auto;
      }

      .manufacturers {
        width: 20rem;
        margin: 0 auto;

        .select-title {
          font-weight: bold;
          text-align: left;
          font-size: 1.5rem;
          margin: 0;
          padding-bottom: 0.5rem;
        }

        .select-element {
          border-radius: 0.5rem;
          font-size: 1.4rem;
          font-weight: 400;
          border: none;

          &:focus {
            outline: none;
          }

          &.error {
            [class$="-control"] {
              border: 1px solid red;
            }

            input {
              border: none !important;
            }
          }

          [class$="-control"] {
            @include themify($themes) {
              color: themed("main");
              background-color: themed("secondary-3");
            }
            height: 4.2rem;
            box-shadow: none;
            border-radius: 1rem;
            border: none;

            &:hover {
              border: none;
            }
          }

          [class$="-placeholder"] {
            @include themify($themes) {
              color: themed("main");
            }
            font-size: 1.4rem;
            font-weight: 400;
          }

          [class$="-indicatorContainer"] {
            @include themify($themes) {
              color: themed("main");
            }
          }

          [class$="-singleValue"] {
            @include themify($themes) {
              color: themed("main");
            }
          }

          [class$="-indicatorSeparator"] {
            background: none;
            @include themify($themes) {
              color: themed("main");
            }
          }

          [class$="-indicator"] {
            @include themify($themes) {
              background: themed("main");
            }
            color: $white;
            border: none;
          }
        }
      }
    }

    .list {
      position: relative;
      padding: 3rem 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 1rem;
      width: 100%;

      @media screen and (max-width: 768px) {
        z-index: 0;
      }

      &.loading {
        min-height: 20rem;
      }
      & .items-no-data {
        width: 100%;

        & p {
          text-align: center;
          font-size: 2rem;
        }
      }
    }

    .no-data {
      position: absolute;
      right: calc(50% - 7rem);
      top: 1rem;
    }
  }
}
