@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.uploaded-message-file {
  font-size: 1rem;
  line-height: 1.4rem;
  margin-bottom: 1rem;
  height: 4rem;
  @include themify($themes) {
    background-color: themed('mySupplier', 'bgMessage');
  }
  padding: 1.4rem 1rem;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .file-info {
    padding: 0 0 0 1rem;

    width: 85%;
    display: flex;
    align-items: center;
    gap: 0.681rem;

    .name {
      p {
        margin: 0;
      }
    }

    .icon {
      display: flex;
      justify-content: center;

      svg,
      img {
        width: 1.696rem;
        height: 2.246rem;

      }
      svg {
        path {
          @include themify($themes) {
            fill: themed('iconFill');
          }
        }
      }
    }
  }

  .remove-file {
    cursor: pointer;
    font-size: 1.6rem;
    padding: 0 1rem 0 0;
  }
}
