@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.GlobalSearchInput.text-input-container {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 1rem;
  flex-direction: column;

  .error-message {
    display: block;
    padding-top: 0.5rem;
    color: $red;
    text-align: left;
    width: 100%;
  }

  .query-input {
    box-shadow: 0.2rem 0.2rem 1rem #0000000a;
    width: 100%;
    position: relative;

    .text-input {
      width: 100%;

      &--s {
        height: 1.4rem;
      }

      &--m {
        height: 3.8rem;
      }

      &--l {
        height: 6.4rem;
        border-radius: 1rem;
        padding: 0 1rem 0 1.6rem;
      }

      &::placeholder {
        @include themify($themes) {
          color: themed('inputPlaceholderColor');
        }

        font-size: 1.4rem;
      }
    }

    & .input-search-icon {
      @include themify($themes) {
        color: themed('main');
      }

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      padding: 1rem 1.5rem;
      font-size: 2rem;
      border: 0;
      background-color: transparent;
    }

    & .cursor-pointer {
      cursor: pointer;
    }
  }
}

/* Mobile CSS Styles */

.app-layout-mobile,
.app-layout-tablet {
  .text-input-container {
    & .text-input {
      color: $blue-19;
      background-color: $white;

      @media screen and (max-width: 768px) {
        max-width: 100%;
      }
    }
  }

  @media screen and (max-width: 360px) {
    .text-input-container {
      & .text-input {
        font-size: 1.4rem;
        line-height: 1.9rem;
      }
    }
  }
}