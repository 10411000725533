@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.custom-date-container {
  & .custom-date-item {
    position: relative;

    & .date-input-container {
      display: flex;
      align-items: center;
      height: 100%;

      & .date-separator {
        display: flex;
        align-items: center;
        @include themify($themes) {
          background-color: themed('secondary-3');
        }
        height: 4.2rem;
        color: $blue-13;
        font-weight: bold;
        font-size: 1.2rem;
      }

      & .custom-picker-dropdown {
        cursor: pointer;
        width: 100%;
        width: 11rem;

        &:first-child {
          & > .text-input-container {
            & > .text-input {
              border-radius: 0.5rem 0 0 0.5rem;
            }
          }
        }

        &:last-child {
          & > .text-input-container {
            & > .text-input {
              border-radius: 0rem 0.5rem 0.5rem 0;
            }
          }
        }

        &.disable-input {
          pointer-events: none;
        }
      }
    }
    & .start-date-picker,
    .end-date-picker {
      position: absolute;
      z-index: 101;
      bottom: 4rem;
      &.date-picker-rmi {
        right: 0;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
      }

      & .react-datepicker {
        display: flex;
        position: relative;
        border: none;
        box-shadow: 0.5rem 0.8rem 2.1rem rgb(0 0 0 / 15%);
        font-size: 1.3rem;

        &__month-container {
          & .react-datepicker__header {
            background: none;
            border-bottom: none;
            padding: 1.2rem 1rem;

            & .react-datepicker__current-month {
              font-size: 1.4rem;
            }

            & .react-datepicker__day-names {
              .react-datepicker__day-name {
                width: 3rem;
              }
            }
          }

          & .react-datepicker__week {
            .react-datepicker__day {
              width: 3rem;
              line-height: 3rem;

              &--in-range {
                background-color: #d73c5a;
              }

              &--selected {
                background-color: #d73c5a;
              }

              &--in-selecting-range {
                background-color: #f5bec7;
              }

              &--keyboard-selected {
                background-color: #d73c5a;
              }
            }
          }
        }

        .react-datepicker__month-container {
          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }
  }
}
