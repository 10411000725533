@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.provider-item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  background-color: $input-color;
  border-radius: 0.5rem;
  padding: 5rem;
  min-width: 20rem;
  transition: all 0.2s ease-in-out;
  box-shadow: 0.3rem 0.4rem 1.5rem rgb(0 0 0 / 15%);

  &:hover {
    box-shadow: 0.3rem 0.4rem 4rem rgb(0 0 0 / 15%);
    transform: scale(1.08);
  }
  .provider-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10rem;
    img {
      max-width: 12.5rem;
      max-height: 10rem;
    }
  }
  .provider-name {
    font-size: 1.6rem;
    @include themify($themes) {
      color: themed('main');
    }
  }
  .provider-franco {
    @include themify($themes) {
      color: themed('main');
    }
    display: flex;
    gap: 1rem;
    align-items: center;
    font-size: 1.3rem;
  }
  .edit-franco {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      opacity: 75%;
    }
  }
}
