@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.diagnostic-item-container {
  max-width: 24rem;
  @include themify($themes) {
     background-color: themed('secondary');
}
  border-radius: 1rem;

  &.active {
    border: 1px solid $blue-text-color;
    background-color: #edf3fd;

    .button {
      button {
        background-color: #c5d2ea;
      }
    }
  }

  &.inactive {
    background-color: #fdfeff;

    .content {
      .image {
        background-color: #fafbfe;
      }

      .name {
        p {
          color: #d4dcec;
        }
      }

      .button {
        button {
          background-color: #f5f7fc;
        }
      }
    }
  }

  .content {
    padding: 1.7rem 2rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .image {
      width: 5rem;
      height: 5rem;
      background-color: #e3ebf6;

      .img {
        max-width: 5rem;
        max-height: 5rem;
      }
    }

    .name {
      padding: 1rem 0;

      p {
        font-weight: bold;
        @include themify($themes) {
          color: themed('main');
        }
        font-size: 2.5rem;
        line-height: 3.4rem;
        margin: 0;
      }
    }

    .button {
      padding: 0 4.6rem;

      button {
        width: 15.7rem;
        height: 3.1rem;
        font-size: 1.1rem;
        line-height: 1.5rem;
      }
    }
  }
}
