@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.global-search-content {
  min-width: 1rem;
  width: 100%;
  position: absolute;
  border-radius: 1rem;
  margin-top: 0.2rem;
  box-shadow: 0.2rem 0.2rem 1rem rgb(1 1 1 / 15%);
  font-size: 1.4rem;
  font-weight: bold;
  overflow-y: auto;
  max-height: 50rem;
  text-align: center;
  top: 7rem;
  z-index: 11;

  &.content-m {
    top: 4rem;
  }

  @include themify($themes) {
    background-color: themed('generalSearch', 'bg');
  }

  .global-search-header {
    .global-search-header-title {
      font-size: 1.5rem !important;
      padding: 1rem;
      font-weight: bold;

      @include themify($themes) {
        color: themed('generalSearch', 'text');
      }
    }
  }

  .global-results {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    & .skeleton-line {
      margin: 0.6rem;
    }

    & .no-data {
      @include themify($themes) {
        color: themed('generalSearch', 'text');
      }

      padding: 1.1rem;
    }

    & .dropdown-header {
      padding: 0.6rem 0.6rem 0.4rem 0.6rem;
      text-align: center;

      @include themify($themes) {
        border-bottom: 1px solid themed('generalSearch', 'border');
      }

      & .dropdown-title {
        @include themify($themes) {
          color: themed('generalSearch', 'text');
        }

        font-weight: bold;
      }
    }

    .content-table-title {
      @include themify($themes) {
        color: themed('generalSearch', 'text');
      }
    }

    .suggestion-list-item {
      @include themify($themes) {
        border-top: 1px solid themed('generalSearch', 'border');
      }
    }

    .suggestion-list-item:last-child {
      border-radius: 0 0 1rem 1rem;
    }

    .parts-results {
      width: 100%;

      & .suggestion-list-item {
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 1.1rem;
        cursor: pointer;
        width: 100%;
        box-sizing: border-box;

        &:hover {
          @include themify($themes) {
            background-color: themed('generalSearch', 'bgTable');
          }
        }

        & .suggestion-item-title {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-between;
          gap: 1rem;

          @include themify($themes) {
            color: themed('generalSearch', 'text');
          }

          span:nth-child(1) {
            width: 30%;
            text-align: left;
          }

          span:nth-child(2) {
            width: 20%;
            text-align: center;
          }

          span:nth-child(3) {
            width: 50%;
            text-align: right;
          }

          @media screen and (max-width: 768px) {
            gap: 0.5rem;
          }

          @media screen and (max-width: 500px) {
            font-size: 1.1rem;
          }
        }

        & .suggestion-item-replacement {
          @include themify($themes) {
            color: themed('generalSearch', 'text');
          }

          font-size: 1.2rem;
          text-align: start;

          span {
            color: $red;
          }

          & .commercial-ref {
            font-size: 1rem;
          }
        }
      }
    }

    .oils-results {
      width: 100%;

      .suggestion-list-item {
        display: flex;
        list-style: none;
        padding: 1.1rem;
        width: 100%;
        box-sizing: border-box;

        &:hover {
          @include themify($themes) {
            background-color: themed('generalSearch', 'bgTable');
          }
        }

        & .suggestion-item-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 1rem;
          white-space: nowrap;
          width: 100%;

          @include themify($themes) {
            color: themed('generalSearch', 'text');
          }

          @media screen and (max-width: 768px) {
            gap: 0.5rem;
          }

          & span {
            cursor: pointer;
          }

          & .suggestion-item-ref {
            display: flex;
            gap: 0.5rem;
            align-items: center;

            img {
              max-width: 2.5rem;
              max-height: 2.5rem;
            }
          }
        }
      }
    }

    .consumables-results {
      width: 100%;

      .suggestion-list-item {
        display: flex;
        list-style: none;
        padding: 1.1rem;
        width: 100%;
        box-sizing: border-box;

        &:hover {
          @include themify($themes) {
            background-color: themed('generalSearch', 'bgTable');
          }
        }

        & .suggestion-item-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 1rem;
          white-space: nowrap;
          width: 100%;

          @include themify($themes) {
            color: themed('generalSearch', 'text');
          }

          @media screen and (max-width: 768px) {
            gap: 0.5rem;
          }

          & span {
            cursor: pointer;
          }

          & .suggestion-item-ref {
            display: flex;
            gap: 0.5rem;
            align-items: center;

            img {
              max-width: 2.5rem;
              max-height: 2.5rem;
            }
          }
        }
      }
    }

    .tyres-results {
      width: 100%;

      .suggestion-list-item {
        display: flex;
        list-style: none;
        padding: 1.1rem;
        width: 100%;
        box-sizing: border-box;

        &:hover {
          @include themify($themes) {
            background-color: themed('generalSearch', 'bgTable');
          }
        }

        & .suggestion-item-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 1rem;
          white-space: nowrap;
          cursor: pointer;
          width: 100%;

          @include themify($themes) {
            color: themed('generalSearch', 'text');
          }

          @media screen and (max-width: 768px) {
            gap: 1.6rem;
          }

          span:nth-child(1) {
            width: 30%;
            text-align: left;
          }

          span:nth-child(2) {
            width: 20%;
            text-align: center;
          }

          span:nth-child(3) {
            width: 50%;
            text-align: right;
          }
        }
      }
    }

    .batteries-results {
      width: 100%;

      & .suggestion-list-item {
        display: flex;
        list-style: none;
        padding: 1.1rem;
        width: 100%;
        box-sizing: border-box;

        &:hover {
          @include themify($themes) {
            background-color: themed('generalSearch', 'bgTable');
          }
        }

        & .suggestion-item-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 1rem;
          white-space: nowrap;
          width: 100%;

          @include themify($themes) {
            color: themed('generalSearch', 'text');
          }

          @media screen and (max-width: 768px) {
            gap: 0.5rem;
          }

          & span {
            cursor: pointer;
          }

          & .suggestion-item-ref {
            display: flex;
            gap: 0.5rem;
            align-items: center;

            & .item-ref {
              flex-wrap: wrap;
              display: flex;
              gap: 0.5rem;
              cursor: pointer;
            }

            img {
              max-width: 2.5rem;
              max-height: 2.5rem;
            }
          }
        }
      }
    }
  }
}