@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

// .tabs {
//display: flex;
//justify-content: center;
//flex-direction: column;

// }

.favorite-page-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 2rem auto;
  max-width: 132rem;


  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 35px;

    .title {
      font-size: 26px;
      font-weight: 800;

      @include themify($themes) {
        color: themed('main');
      }
    }



    .tabs {
      width: 100%;
      display: flex;
      gap: 15px;
      flex-direction: row;
      flex-wrap: wrap;

      .vehicle-selector-tabs-container {
        .vehicle-tabs {


          .tab-item {
            width: 16.4rem;
            height: 2.7rem;
            font-size: 1.3rem;
            border-radius: 1rem;
            font-weight: bold;
            color: $white;

            @include themify($themes) {
              background-color: themed('myProfile', 'disable');
            }
          }

          .active {
            @include themify($themes) {
              background-color: themed('main');
            }
          }

        }


      }
    }

    //
    //.tabsContent {
    //  display: flex;
    //  flex-wrap: wrap;
    //  width: 90%;
    //  gap: 20px;
    //  justify-content: center;
    //}

    .pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      padding-top: 40px;

      .item {
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        width: 20px;
        height: 20px;
        padding: 5px;
        border-radius: 50px;

        @include themify($themes) {
          background-color: themed('main');
        }

        color: white;
      }
    }
  }
}