@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.check-equivalences-list {

    width: 100%;

    .check-equivalences-list-title {
        display: flex;
        justify-content: center;
        font-size: 2rem;
        margin: 2.7rem 0rem;

        @include themify($themes) {
            color: themed('main');
        }

        font-weight: bold;

    }

    .table-wrapper-tariff-default table .row:last-child .heading:last-child .table-title {
        height: 3.4rem;
    }



    .action {
        display: flex;
        justify-content: space-around;
        position: relative;
        gap: 0.4rem;

        svg {
            font-size: 1.6rem;
        }


        span:hover {
            cursor: pointer;
        }

        .tooltip_lable {
            display: none;
            position: absolute;
            padding: 1rem 2rem;
            background-color: $black;
            color: $white;
            top: -4rem;
            left: -2.5rem;
            border-radius: 5px;
            z-index: 99999999999;
            width: max-content;

        }
    }

    .buyer-table-button {
        width: 100%;
        display: flex;
        flex-direction: column;
        min-height: 40rem;
        box-sizing: border-box;
        align-items: center;
        margin-top: 3rem;

        .button- {
            width: 19.4rem;
            height: 3.4rem;
            text-align: center;

            button {
                height: 100%;
            }
        }


    }


}