@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.automotor-tariff-cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 132rem;
  width: 100%;
  margin: 0rem auto;

  & .automotor-offer-cards {
    display: flex;
    gap: 6.5rem;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 3rem;

    @media screen and (max-width: 768px) {
      padding: 0 1rem;
      box-sizing: border-box;
      gap: 2rem;
    }
  }

  & .automotor-tariff-link {
    @include themify($themes) {
      color: themed('main');
    }

    cursor: pointer;
    font-size: 1.5rem;

    &:hover {
      opacity: 75%;
    }
  }
}