@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.container-wrapper {
  .container {
    max-width: $container-width-xlarge;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .sidebar {
      position: absolute;
      z-index: 11;
      left: 0;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    .header {
      position: sticky;
      top: 0;
      z-index: 10;

      >div {
        position: relative;
      }
    }

    @media screen and (max-width: 1260px) {
      max-width: $container-width-xlarge;
    }

    @media screen and (max-width: 990px) {
      height: initial;

      &>.body {
        background-color: $background-grey;
      }
    }
  }

  .container-fluid-large {
    max-width: $container-width-large;
    margin: 0 auto;

    @media screen and (max-width: 1440px) {
      //max-width: 125.4rem;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 0;
      padding: 0;
    }
  }

  .container-fluid-medium {
    max-width: $container-width-medium;
    margin: 0 auto;
  }

  .disabled {
    position: relative;
    pointer-events: none;
    cursor: not-allowed;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 0;
  }

  .disabled::before {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    opacity: 75%;
  }

  .text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    max-width: 100%;
    word-break: break-word;

    &.one-line {
      -webkit-line-clamp: 1;
    }
  }

  .body {
    position: relative;
    z-index: 9;
    height: 100%;

    @media screen and (max-width: 990px) {
      height: initial;
      width: 100%;
    }
  }

  .footer {
    display: block;
    margin-top: auto;
    z-index: 10;
  }
}

.ReactModal__Overlay {
  height: 100vh;

  .ReactModal__Content {
    outline: none;

    &.ReactModal__Tablet,
    &.ReactModal__Mobile {
      overflow: visible;

      @include themify($themes) {
        background: themed('secondary-2');
      }

      border-radius: 0rem !important;

      padding: 2rem 0;
      width: 100%;
      height: 100vh;
      max-height: 100%;
    }
  }
}

.ReactModal__Body--open {
  #root {
    z-index: 1;
  }
}


/** TEMPORARY STYLES **/
.ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-items: center;


  .ReactModal__Content {
    position: relative !important;
    inset: unset !important;
    margin: auto !important;
    transform: unset !important;

    @include themify($themes) {
      background-color: themed('secondary-2') !important;
    }
  }
}

.app-layout-tablet {
  display: flex;
  align-items: center;
  justify-content: center;
}

/** //TEMPORARY STYLES **/

@media screen and (min-width: 990px) {
  .ReactModal__Overlay {
    overflow: auto;
  }

  ::-webkit-scrollbar {
    width: 0.7rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $background-color-1;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    @include themify($themes) {
      background: themed('main');
    }

    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @include themify($themes) {
      background-color: themed('main');
    }
  }
}

[class$='-control'] {
  @include themify($themes) {
    color: themed('main');
  }

  @include themify($themes) {
    background-color: themed('bgCustomDropdown');
  }

  @include themify($themes) {
    border-color: themed('bgCustomDropdown');
  }

  @include themify($themes) {
    box-shadow: inset 0 0 1px themed('bgCustomDropdown');
  }

  min-height: 4.2rem !important;

  &:hover {

    @include themify($themes) {
      border-color: themed('bgCustomDropdown') !important;

    }
  }
}

[class$='-placeholder'] {
  @include themify($themes) {
    color: themed('main');
  }

  font-size: 1.4rem;
  font-weight: 400;
}

[class$='-indicatorContainer'] {
  @include themify($themes) {
    color: themed('main');

  }
}

[class$='-singleValue'] {
  @include themify($themes) {
    color: themed('main');

  }
}

[class$='-indicatorSeparator'] {
  background: none;

  @include themify($themes) {
    color: themed('main');
  }
}

[class$='-indicator'] {
  @include themify($themes) {
    background: themed('inputColor');
  }

  color: $white;
}



[class$='-option'] {
  cursor: pointer;

  @include themify($themes) {
    background: themed('bgCustomDropdown');
  }

  &:hover {
    @include themify($themes) {
      background: themed('mainHover');
      color: themed('main');
    }
  }
}

[class$='-9gakcf-option'] {
  @include themify($themes) {
    color: themed('mainHover');
    background: themed('main');
  }

  &:hover {
    @include themify($themes) {
      color: themed('mainHover');
      background: themed('main');
    }
  }
}

[class$='-MenuList'] {
  @include themify($themes) {
    background-color: themed('bgCustomDropdown');
  }
}

[class$='-menu'] {
  @include themify($themes) {
    background-color: themed('bgCustomDropdown');
  }
}


.notFoundSvg {
  svg {
    path[fill="#27509F"] {
      @include themify($themes) {
        fill: themed('searchSvg', fill1);

      }
    }

    path[fill="#A9B9D9"] {
      @include themify($themes) {
        fill: themed('searchSvg', fill2);

      }
    }

    path[fill="#9FC6FF"] {
      @include themify($themes) {
        fill: themed('searchSvg', fill1);

      }
    }

    path[fill="#D4E8FF"] {
      @include themify($themes) {
        fill: themed('searchSvg', fill2);

      }
    }

    path[fill="#318FFF"] {
      @include themify($themes) {
        fill: themed('searchSvg', fill3);

      }
    }

    path[fill="#E4EBF7"] {
      @include themify($themes) {
        fill: themed('searchSvg', fill3);

      }
    }

    path[fill="#FF8B7B"] {
      @include themify($themes) {
        fill: themed('searchSvg', fill4);

      }
    }

  }
}