@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.rapid-access {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 10rem;
  margin-bottom: 5rem;
  gap: 1rem;
  position: relative;
  min-height: 11.2rem;

  & .rapid-access-spinner {
    margin-top: 2rem;
    padding-left: 2rem;
    box-sizing: border-box;
  }

  & .no-data {
    font-size: 2.4rem;
    font-weight: bold;
    @include themify($themes) {
      color: themed('main');
    }
    opacity: 60%;
    margin-left: 2rem;
    white-space: nowrap;
  }
}

/* Mobile CSS Styles */

.app-layout-mobile,
.app-layout-tablet {
  .rapid-access {
    padding: 0 2rem;
    margin-bottom: 2rem;

    .rapid-access-item {
      width: 100%;

      .tabcard-container {
        margin: 0 auto;
      }
    }
  }
}
