@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.cart-file-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  @include themify($themes) {
    background-color: themed('main');
  }
  color: $white;
  cursor: pointer;

  &:hover {
    opacity: 80%;
  }
  &.disable_add_cart {
    pointer-events: none;
    opacity: 75%;
  }

  & .round-cart-spinner {
    svg {
      width: 1.8rem;

      circle {
        stroke: $white;
      }
    }
  }
  & .file-icon {
    font-size: 1.6rem;
  }
}
