@import "../../../../assets/sass/variables";
@import "src/assets/sass/themes";

.consumable-create-container {
  width: 99.3rem;
  margin: 4rem 2rem;

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 4rem 1rem;
    gap: 0rem;
    margin: 0rem;
  }

  @media screen and (max-width: 990px) and (min-width: 768px) {
    width: 100%;
    margin: 0rem;
    gap: 0rem;
    height: 100%;
  }

  .close-modal {
    position: absolute;
    right: 1.6rem;
    top: 1.6rem;
    font-size: 1.8rem;

    @include themify($themes) {
      color: themed("main");
    }

    @media only screen and (max-width: 768px) {
      top: 2.6rem;
    }

    @media screen and (max-width: 990px) and (min-width: 768px) {
      top: 3.6rem;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .title {
    @include themify($themes) {
      color: themed("main");
    }

    font-size: 2.5rem;
    letter-spacing: 0;
    line-height: 3.4rem;
    text-align: center;

    @media only screen and (max-width: 768px) {
      font-size: 1.9rem;
      padding: 2rem;
    }

    @media screen and (max-width: 990px) and (min-width: 768px) {
      padding: 2rem;
    }

    span {
      font-weight: bold;
    }
  }

  .spinner-info {
    position: relative;
    margin-top: 2.5rem;
  }

  .info-item {
    margin-top: 1.4rem;

    @include themify($themes) {
      color: themed("main");
    }

    font-size: 1.3rem;
    letter-spacing: 0;
    line-height: 1.8rem;
    text-align: center;

    span {
      font-weight: bold;
    }
  }

  .consumable-create-body {
    padding: 2rem;

    max-height: calc(100vh - 40rem);
    overflow-y: auto;
    overflow-x: hidden;

    @media only screen and (max-width: 768px) {
      width: auto;
      max-width: 100%;
      max-height: calc(100vh - 24rem);
      overflow-y: auto;
    }

    @media screen and (max-width: 990px) and (min-width: 768px) {
      width: 100%;
      overflow-y: auto;
    }

    .row {
      display: flex;

      gap: 1.2rem;
      justify-content: center;
      margin: 1rem;

      @media only screen and (max-width: 768px) {
        flex-direction: column;
        overflow: visible;
        margin: 0rem;
        padding: 0rem 2rem;
        gap: 0;
        height: 100%;
      }

      @media screen and (max-width: 990px) and (min-width: 768px) {
        margin: 2rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        height: 100%;
      }

      > .container {
        margin: 1rem 0;

        .placeholderRed {
          color: $red;
        }

        .upload_image_info {
          display: flex;
          justify-content: center;
          color: $blue;
          font-size: 1.2rem;
          text-align: center;
        }

        .description_info {
          display: flex;
          justify-content: space-between;

          button {
            width: 20rem;
          }
        }
      }

      label {
        @include themify($themes) {
          color: themed("main");
        }

        font-size: 1.4rem;
      }

      input {
        margin-top: 1rem;
      }

      textarea {
        margin-top: 1rem;
        width: 100%;
        border: none;

        @include themify($themes) {
          background-color: themed("secondary-3");
        }

        resize: vertical;
        padding: 1rem;
        font-size: 1.6rem;

        @include themify($themes) {
          color: themed("main");
        }

        @media only screen and (max-width: 768px) {
          padding: 0;
        }

        &:focus-visible {
          outline: none;
        }
      }

      .button {
        margin-top: 1rem;
      }

      .select-dropdown {
        height: 4.2rem;
        border-radius: 0.5rem;
        font-size: 1.4rem;
        font-weight: 400;

        @include themify($themes) {
          color: themed("main");
        }

        margin-top: 1rem;

        border: none;

        [class$="-control"] {
          @include themify($themes) {
            color: themed("main");
            background-color: themed("secondary-3");
          }

          height: 4.2rem;
          box-shadow: none;

          &:hover {
            border: none;
          }
        }

        [class$="-placeholder"] {
          @include themify($themes) {
            color: themed("main");
          }

          font-size: 1.4rem;
          font-weight: 400;
        }

        [class$="-indicatorContainer"] {
          @include themify($themes) {
            color: themed("main");
          }
        }

        [class$="-singleValue"] {
          @include themify($themes) {
            color: themed("main");
          }
        }

        [class$="-indicatorSeparator"] {
          background: none;

          @include themify($themes) {
            color: themed("main");
          }
        }

        [class$="-indicator"] {
          @include themify($themes) {
            background: themed("main");
          }

          color: $white;
        }
      }

      @media only screen and (min-width: 990px) {
        &.file-container {
          align-items: flex-end;
        }
      }
    }

    .top-offer-checkbox-container {
      > * {
        cursor: pointer;
      }

      label {
        display: inline-block;
        float: left;
        text-align: left;

        @include themify($themes) {
          color: themed("main");
        }
      }

      > input {
        margin-top: 0.3rem;
      }
    }
  }

  .btn-save {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3rem 0rem;

    @media only screen and (max-width: 768px) {
      margin: 3rem 3rem;
    }

    button {
      @include themify($themes) {
        background-color: themed("main");
      }

      color: $white;
      border: none;
      border-radius: 0.5rem;
      padding: 0.5rem 2.5rem;
      font-weight: bold;
      width: 45.2rem;
      height: 4.7rem;
      font-size: 1.4rem;
      outline: none;
      transition: all 0.3s ease-in-out;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
      }

      .title-btn-save {
        width: 100%;
      }

      .extra-small {
        width: 2rem;

        circle {
          stroke: $white;
        }
      }
    }
  }

  .disabled {
    pointer-events: none;
    background-color: $blue-2 !important;
  }
}
