@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.diagnostic-error-codes {
  @media screen and (max-width: 768px) {
    padding: 0 0.5rem;
  }
  .content {
    @include themify($themes) {
      color: themed('main');
    }
    max-width: 81rem;
    margin: 0 auto;
    .header {
      padding-top: 2rem;
      text-align: center;
      .file {
        a {
          .spinner {
            display: inline-block;
            width: auto;
            svg {
              padding-right: 0;
              width: 1.7rem;
              line-height: 2rem;
              circle {
              }
            }
          }
          span {
            padding-left: 0.5rem;
          }
          cursor: pointer;
          @include themify($themes) {
            color: themed('main');
          }
          text-decoration: none;
          margin-bottom: 0;
          svg {
            font-size: 1.6rem;
          }
          font-size: 1.2rem;
          line-height: 1.7rem;
        }
      }
      .title {
        p {
          font-size: 1.4rem;
          line-height: 1.9rem;
          font-weight: bold;
        }
      }
    }
    .data {
      position: relative;
      margin: 0 auto;
      display: block;
      min-height: 10rem;
      max-height: 30rem;
      overflow-y: auto;
      //display: flex;
      //flex-direction: column;
      //align-items: center;
      //gap: 2rem;
    }
    .no-data {
      text-align: center;
      min-height: 10rem;
      max-height: 30rem;
      line-height: 30rem;
      font-size: 1.5rem;
    }
  }
}
