@import 'assets/sass/variables';
@import 'src/assets/sass/themes';
@import 'assets/sass/tables';

.orders-page-client-espace {
  & .orders-table {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    min-height: 20rem;

    & .sellers-table-spinner {
      position: absolute;
      background: none;
    }
  }
}
