@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.round-account-dropdown {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  @include themify($themes) {
    color: themed('secondary');
  }

  font-size: 1.2rem;
  width: 13.6rem;
  height: 4.2rem;
  border-radius: 2.1rem;
  gap: 0.5rem;
  position: relative;

  @include themify($themes) {
    background-color: themed('bgCustomDropdown');
  }

  @media only screen and (max-width: 1340px) and (min-width: 990px) {
    height: 4.2rem;
    width: 4.2rem;
    gap: 0rem;
  }

  @media only screen and (max-width: 768px) {
    width: 3.8rem;
    height: 3.8rem;
    gap: 0rem;
  }

  @media only screen and (max-width: 990px) and (min-width: 768px) {
    width: 3.8rem;
    height: 3.8rem;
    gap: 0rem;
  }

  .myAccount {
    @include themify($themes) {
      color: themed('main');
    }

    @media only screen and (max-width: 768px) {
      display: none;
    }

    @media only screen and (max-width: 990px) {
      display: none;
    }

    @media only screen and (max-width: 1340px) and (min-width: 990px) {
      display: none;
    }
  }

  & .caret-icon {
    @include themify($themes) {
      color: themed('main');
    }

    @media only screen and (max-width: 1340px) and (min-width: 990px) {
      display: none;
    }

    @media only screen and (max-width: 990px) {
      display: none;
    }

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  & .user-icon {
    font-size: 1.6rem;

    @include themify($themes) {
      color: themed('main');
    }
  }

  .show {
    .content-data {
      display: block;
    }
  }

  .hide {
    .content-data {
      display: none;
    }
  }

  .content-data {
    padding: 1rem 0;
    min-width: 25rem;
    position: absolute;
    border-radius: 0.4rem;
    top: 4.5rem;
    left: 50%;
    transform: translateX(-66%);
    z-index: 20;

    @include themify($themes) {
      background-color: themed('bgCustomDropdown');
    }

    @media only screen and (max-width: 768px) {
      position: fixed;
      top: 5.5rem;
      right: 0rem;
      left: auto;
      transform: none;
    }

    @media only screen and (max-width: 990px) and (min-width: 768px) {
      position: fixed;
      top: 5.5rem;
      right: 0rem;
      left: auto;
      transform: none;
    }

    .item {
      text-decoration: none;
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      padding: 0 1rem;
      gap: 1rem;
      cursor: pointer;

      .switch {
        position: relative;
        display: inline-block;
        width: 3.6rem;
        height: 2rem;
        margin-left: 3rem;
      }

      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .switch_slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(128, 128, 128, 0.475);
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      .switch_slider:before {
        position: absolute;
        content: '';
        height: 1.6rem;
        width: 1.6rem;
        left: 0.2rem;
        bottom: 0.2rem;
        background-color: $white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      input:checked+.switch_slider {
        @include themify($themes) {
          background-color: themed('main');
        }
      }

      input:focus+.switch_slider {
        box-shadow: 0 0 1px #222d4a;
      }

      input:checked+.switch_slider:before {
        -webkit-transform: translateX(16px);
        -ms-transform: translateX(16px);
        transform: translateX(16px);
      }

      /* Rounded sliders */
      .switch_slider.round {
        border-radius: 3.4rem;
      }

      .switch_slider.round:before {
        border-radius: 50%;
      }

      &:hover {
        @include themify($themes) {
          background-color: themed('mainHover');
        }
      }

      .icon {
        padding: 0.1rem;
      }

      .text {
        min-width: 10rem;
        font-size: 1.2rem;
      }
    }
  }
}

.mecadepot-width {
  width: 6.6rem;
}