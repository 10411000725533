@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.table-wrapper-tariff-default {
  width: 100%;

  & .order-default-table-container {
    min-height: 10rem;

    @include themify($themes) {
      background: themed('secondary-2');
    }

    border-radius: 1rem;
    position: relative;

    & .default-table-small-spinner {
      position: absolute;

      &.has-data {
        position: relative;
        margin-bottom: 0.5rem;
      }
    }

    & .no-data {
      display: flex;
      align-items: center;
      justify-content: center;

      @include themify($themes) {
        color: themed('main');
      }

      font-size: 2rem;
      height: 10rem;
      text-align: center;
    }
  }

  table {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2rem;
    line-height: 1.4rem;
    text-align: left;
    letter-spacing: normal;
    width: 100%;
    border-spacing: 0 0.7rem;
    padding: 0.4rem 1.5rem;
    border-radius: 0.5rem 0.5rem 0 0;

    @include themify($themes) {
      color: themed('main');
      background: themed('secondary-2');
    }

    .container-pinner {
      height: 3.5rem;
      position: relative;
    }

    .row {
      text-align: center;

      &.is-clickable {
        cursor: pointer;
      }

      .heading {
        height: 3.4rem;
        font-weight: 700;
      }

      &:first-child {
        .heading {
          .table-title {
            height: 3.4rem;
            padding: 0.2rem;

            @include themify($themes) {
              background-color: themed('secondary-3');
            }

            border-radius: 0.5rem;
            text-align: center;
            margin: 0rem 0.4rem 0.4rem 0.4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
          }

          &:last-child {
            .table-title {
              height: 8rem;
            }
          }
        }
      }
    }

    .body {
      .row {
        .cell {
          padding: 0.85rem 0.4rem 0.85rem 1.4rem;

          @include themify($themes) {
            background-color: themed('secondary-3');
          }

          font-weight: 400;
          font-size: 1.2rem;
          line-height: 1.6rem;

          &:first-child {
            border-radius: 0.5rem 0 0 0.5rem;
          }

          &:last-of-type {
            border-radius: 0 0.5rem 0.5rem 0;
          }

          .country_restriction {
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            position: relative;
            gap: 1rem;

            svg {
              color: #ca312a;
              ;
              ;
            }

            .restriction {
              display: none;
              position: absolute;
              width: 13rem;
              padding: 1rem 2rem;
              background-color: $red;
              color: $white;
              top: -4rem;
              left: -3rem;
              border-radius: 5px;
              z-index: 99999999999;
            }
          }
        }
      }

      .notFond {
        text-align: center;
      }
    }
  }

  .tfoot {
    display: flex;

    @include themify($themes) {
      background-color: themed('table', 'footer');
      color: themed('main');
    }

    height: 2.8rem;


    border-radius: 0 0 0.5rem 0.5rem;
    align-items: center;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.9rem;

    .tfoot-td-1 {
      border-right: $blue-1 0.1rem solid;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .tfoot-td-2 {
      border-left: $blue-1 0.1rem solid;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}

/* Responsive CSS Styles */

.app-layout-desktop {}

.app-layout-tablet {}

.app-layout-tablet,
.app-layout-mobile {
  .table-wrapper-tariff-default {
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;

    table {
      .row {
        &:first-child {
          .heading {
            .table-title {
              margin: 0.4rem 0;
            }

            &:last-child {
              .table-title {}
            }
          }
        }
      }
    }
  }
}

.app-layout-mobile {
  .table-wrapper-tariff-default {
    table {
      padding: 0;
    }
  }
}