@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.favorite-list-sidebar-item-wrapper {
  &.selected {
    @include themify($themes) {
      background-color: themed('favorite', 'selectTab');
    }
  }

  &:not(.selected) {
    &:not(:last-child) {
      @include themify($themes) {
        border-bottom: 1px solid themed('favorite', 'border');
      }

    }
  }

  .favorite-list-sidebar-item {
    padding: 1.4rem 1.3rem 1.3rem 1.3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon {
      width: 2.5rem;
      padding-right: 0.7rem;

      svg {
        width: 2.1rem;
        height: 1.6rem;

        path {
          @include themify($themes) {
            fill: themed('favorite', 'colorFolder') !important;
          }
        }

      }
    }

    .title-favorite {
      flex: 1;
      font-weight: 800;

      p {
        font-size: 1.4rem;
        line-height: 1.9rem;
        margin: 0;
        word-break: break-all;
      }
    }

    .options-wrapper {
      cursor: pointer;
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .options {
        svg {
          height: 2rem;
        }

        text-align: center;
        align-items: flex-end;
        position: relative;

        .options-content {
          z-index: 3;

          @include themify($themes) {
            background-color: themed('favorite', 'bg');
          }

          padding: 1rem;
          border-radius: 0.3rem;
          width: 8rem;
          position: absolute;
          top: 3rem;
          left: 0;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          gap: 1rem;

          @media screen and (max-width: 768px) {
            right: 0;
            top: 3.5rem;
            left: auto;
            width: 10rem;
          }

          .option {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            @include themify($themes) {
              background-color: themed('favorite', 'bg');
            }

            width: 100%;

            &:hover {
              background-color: $grey-1;
            }

            svg {
              height: 1.25rem;
              width: 1.25rem;
              padding-right: 0.5rem;

              path {
                @include themify($themes) {
                  fill: themed('favorite', 'bgFill');
                }
              }
            }

            span {
              font-size: 1.2rem;
              line-height: 1.6rem;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}