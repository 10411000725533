@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.session-modal {
  width: 68rem;

  @include themify($themes) {
    color: themed('main');
  }

  position: relative;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100vh;
  }

  @media screen and (max-width: 990px) and (min-width: 768px) {
    width: 100%;
  }

  .close {
    float: right;

    @media screen and (max-width: 768px) {
      padding-top: 1rem;
    }
  }

  .modal-header {
    p {
      margin-top: 0;
      text-align: center;
      font-size: 2.5rem;
      line-height: 3.4rem;
    }
  }

  .modal-body {
    .row {
      display: flex;
      flex-direction: row;
      gap: 3.4rem;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      &:first-child {
        padding-bottom: 1.6rem;
      }

      .form-field {
        width: 100%;

        label {}

        .text-input-container {
          padding-top: 0.5rem;

          input {
            width: 100%;
            background-color: $white;
            border: 1px solid #c4d1eb;
            padding: 0 1rem;
          }

          .error-message {
            display: block;
            margin: 0.2rem;
            color: $red;
          }
        }
      }
    }
  }

  .modal-footer {
    .separator {
      @include themify($themes) {
        border: 2px solid themed('table', 'footer');
      }

      margin: 2.6rem 0;
    }

    .save {
      width: 100%;

      button {
        border-radius: 1rem;
        border: none;
        padding: 0 0.5rem;
        font-size: 1.6rem;
        font-weight: 400;
        color: white;
        outline: none;
        transition: all 0.3s ease-in-out;

        @include themify($themes) {
          background-color: themed('main');
        }

        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 33rem;
        height: 4.2rem;
        margin: 0 auto;

        @media screen and (max-width: 768px) {
          width: 100%;
        }

        &:hover {
          background-color: $blue-5;

          @include themify($themes) {
            color: themed('main');
          }

          .default-btn-spinner {
            svg {
              circle {
                @include themify($themes) {
                  fill: themed('main');
                }
              }
            }
          }
        }

        span {
          padding-right: 0.6rem;

          svg circle {
            stroke: $grey-1;
          }
        }
      }
    }
  }
}