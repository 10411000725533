@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.text-input-container {
  width: 100%;
  position: relative;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .text-input {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 4.6rem;
    border-radius: 0.5rem;
    border: none;
    padding: 0 1rem;
    font-size: 1.6rem;
    font-weight: 400;
    outline: none;
    transition: all 0.3s ease-in-out;

    @include themify($themes) {
      color: themed('main');
      background-color: themed('inputColor');
    }

    box-sizing: border-box;

    &.input-error {
      border: 0.1rem solid $red;
    }

    &.white {
      background-color: $white;
    }

    &::placeholder {
      @include themify($themes) {
        color: themed('inputPlaceholderColor');
      }

      font-size: 1.4rem;
    }
  }

  & .input-search-icon-fa {
    @include themify($themes) {
      color: themed('main');
    }

    position: absolute;
    padding: 1rem;
    font-size: 2rem;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  .close-icon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    top: 1.1rem;
    right: 1rem;

    @include themify($themes) {
      background: themed('btnCloseBg');
    }

    border-radius: 50%;
    width: 2rem;
    height: 2rem;

    .input-close-icon {
      @include themify($themes) {
        color: themed('main');
      }
    }
  }
}

/* Responsive CSS Styles */

.app-layout-desktop {}

.app-layout-tablet {}

.app-layout-tablet,
.app-layout-mobile {
  .text-input-container {
    @include themify($themes) {
      color: themed('main');
    }

    .close-icon {
      right: 1rem;
    }
  }
}

.app-layout-mobile {}