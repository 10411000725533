@import '../../assets/sass/variables';
@import 'src/assets/sass/themes';

.upload-file-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    min-height: auto;
  }

  & .icon {
    @include themify($themes) {
      color: themed('main');
    }
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.5rem;
    width: 7.5rem;
    height: 7.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $blue-5;
    position: relative;

    img {
      width: 30px;
    }
  }

  & .button {
    width: 65%;

    button {
      margin-top: 1rem;
    }
  }

  .file-preview {
    width: 10rem;
    position: relative;

    .remove-file {
      width: 1.5rem;
      height: 1.5rem;
      position: absolute;
      top: 6px;
      right: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0rem 0.6rem;
      border: none;
      background-color: $blue-4;
      @include themify($themes) {
        color: themed('main');
      }
      border-radius: 100%;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
