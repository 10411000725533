@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';
@import 'src/assets/sass/tables';

.table-head-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.2rem;
  @include themify($themes) {
    color: themed('main');
  }
  font-family: 'Open Sans Bold', sans-serif;
  line-height: 1.4rem;
  letter-spacing: normal;
  text-align: left;

  &.centered {
    justify-content: center;
  }

  .icons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .icon {
      width: 1rem;
      height: 1rem;
      @include themify($themes) {
        color: themed('main');
      }
    }
  }
}

/* Responsive CSS Styles */

.app-layout-desktop {
}

.app-layout-tablet {
}

.app-layout-tablet,
.app-layout-mobile {
  .table-head-wrapper {
    gap: 0.2rem;
    font-size: 1.2rem;
    text-align: center;
    padding: 0 0.4rem;
  }
}
