@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.component-link-modal {
  @include themify($themes) {
    background-color: themed('secondary-2');
    color: themed('main');
  }
  font-weight: normal;
  position: relative;

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 100vh;
  }

  .modal-dialog {
    padding: 0 4.7rem 2rem;

    @media only screen and (max-width: 768px) {
      padding: 6rem 1.5rem;
    }

    .close {
      svg {
        position: absolute;
        top: 2rem;
        right: 2rem;
      }
    }

    svg {
      font-size: 2.4rem;
    }
  }

  .rmi-module-link-tabs {
    min-width: 70rem;
    min-height: 25rem;
    &.no_data {
      display: flex;
      align-items: center;
      justify-content: center;
      & .no-data {
        font-size: 16px;
      }
    }
    @media only screen and (max-width: 768px) {
      min-width: 100%;
    }

    .react-tabs {
      @include themify($themes) {
        border-bottom: 2px solid themed('main');
      }
      min-height: 25rem;
    }

    @media screen and (max-width: 990px) and (min-width: 768px) {
      min-width: 100%;
    }

    & ul.react-tabs__tab-list {
      list-style: none;
      display: flex;
      // align-items: flex-start;
      padding-left: 0;

      @include themify($themes) {
        border-bottom: 2px solid themed('main');
      }

      & li {
        cursor: pointer;
        padding: 16px;
        text-align: center;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 2.5rem;
        @include themify($themes) {
          color: themed('main');
        }

        @media only screen and (max-width: 768px) {
          font-size: 1.5rem;
          line-height: 2rem;
          padding: 10px;
        }

        &.react-tabs__tab--selected {
          // color: white;

          @include themify($themes) {
            border-bottom: 4px solid  themed('main');
          }
          margin-bottom: -1px;
        }

        &.react-tabs__tab--disabled {
          @include themify($themes) {
            color: themed('secondary-darker');
          }
          pointer-events: none;
          cursor: default;
        }
      }
    }

    & .react-tabs__tab-panel {
      & > ul {
        min-height: 15rem;
        //border-bottom: 2px solid $blue-5;
      }
      .__react_component_tooltip {
        @media only screen and (max-width: 768px) {
          left: 2rem !important;
          width: 80% !important;
        }
      }

      // &>ul {
      //   min-height: 15rem;
      //   border-bottom: 2px solid $blue-5;
      // }

      & ul {
        list-style: none;
        padding: 5px 5px 20px 5px;
        font-size: 1.2rem;
        font-weight: bold;

        @media screen and (max-width: 990px) and (min-width: 768px) {
          max-height: 500px;
          overflow-y: auto;
        }

        @media only screen and (max-width: 768px) {
          margin: 0;
        }

        & p {
          font-weight: 700;
          font-size: 1.7rem;
          margin: 1rem 0;
          @include themify($themes) {
            color: themed('main');
          }

          @media only screen and (max-width: 768px) {
            font-size: 1.5rem;
            margin: 0;
          }
        }

        & a {
          text-decoration: none;
          padding: 5px 5px;
          display: block;
          font-weight: 600;
          font-size: 1.6rem;
          line-height: 2.2rem;
          cursor: pointer;

          @media only screen and (max-width: 768px) {
            font-size: 1.5rem;
          }

          @include themify($themes) {
            color: themed('main');
          }

          &:hover {
            @include themify($themes) {
              background-color: themed('inputColor');
            }
            border-radius: 3px;
          }
        }
      }

      & .no-data {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 25rem;
        font-weight: 700;
        font-size: 1.7rem;
        margin: 0.2rem 0;

        @include themify($themes) {
          color: themed('main');
        }
      }
    }

    & .none {
      display: none;
    }
  }

  .tooltip-class {
    @include themify($themes) {
      background: themed('main');
    }
    min-width: 5rem;
    width: auto;
    text-align: center;

    &.show {
      opacity: 100% !important;
    }
  }
}
