@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';

body {
  display: flex;
}

.wrapper-clients-table {
  width: 100%;
  @include themify($themes) {
    background: themed('secondary-2');
  }
  border-spacing: 0 0.8rem;
  padding: 1.25rem;
  border: 20px $white-5;
  border-radius: 10px;
  margin-top: 19px;

  .spinner {
    height: 10rem;
    position: relative;
    background: none;
    width: 500%;
    height: 60vh;
  }

  .headings {
    .row {
      .heading {
        @include themify($themes) {
          color: themed('main');
        }

        text-align: left;
        font-weight: 300;
        font-size: 1.2rem;
        width: max-content;
      }
    }
  }

  .body {
    .row {
      font-size: 0.75rem;
      cursor: pointer;

      .cell {
        border: 1px solid $white-3;

        padding: 8px;
        font-size: 1.2rem;
        line-height: 1.7rem;
        @include themify($themes) {
          background-color: themed('secondary-3');
        }
        text-align: left;

        @include themify($themes) {
          color: themed('main');
        }

        height: 20px;
        max-width: min-content;

        &:last-of-type {
          border-radius: 0 5px 5px 0;
        }

        &:first-of-type {
          border-radius: 5px 0 0 5px;
        }

        .relicats-green {
          background-color: $green;
          padding: 0.2rem;
          border-radius: 50%;
          width: 1.2rem;
          color: white;
        }

        .relicats-red {
          background-color: $red;
          padding: 0.2rem;
          border-radius: 50%;
          width: 1.2rem;
          color: white;
        }
      }
    }

    .notFond {
      text-align: center;
      font-size: 2.4rem;
      font-weight: bold;

      @include themify($themes) {
        color: themed('main');
      }

      opacity: 60%;
      margin-top: 5rem;
    }
  }
}
