.masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -24px;
  width: auto;

  .masonry-grid-column {
    padding-left: 24px;
    background-clip: padding-box;
    width: 100%;

    .category-card {
      width: 100%;
      min-height: 7rem;
      background-color: #e5eeff;
      border-radius: 0.5rem;
      margin-top: 2rem;

      &-header {
        display: flex;
        align-items: center;
        flex-direction: column;

        .category-card-title {
          @include themify($themes) {
            color: themed('main');
          }
          font-weight: 600;
          font-size: 1.6rem;
          padding-top: 1.3rem;
          border-bottom: 0.1rem solid #cfd9ee;
          width: 90%;
          text-align: center;
          padding-bottom: 0.4rem;
          margin-bottom: 0.5rem;
        }
      }

      &-body {
        @include themify($themes) {
          color: themed('main');
        }

        & .subcategory-title {
          padding-bottom: 1.7rem;
          margin-left: 0.5rem;

          ul {
            margin-top: 0.3rem;

            li {
              text-decoration: underline;
              cursor: pointer;

              &:hover {
                opacity: 75%;
              }
            }
          }

          & .title {
            margin-left: 2rem;
            font-weight: 600;
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}

//   .vehicle-subcategories-card {
//     display: flex;
//     justify-content: space-between;

//     p {
//       line-height: 1.2;
//     }
//   }

//   .flex-center {
//     display: flex;
//     justify-content: flex-start;
//   }

//   .button-card {
//     height: 25px;
//     background-color: $dark-blue;
//     padding: 0 12px;
//     max-width: 181px;

//     &:hover {
//       background-color: $red;
//     }
//   }
//     }
//   }
// }}
