@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.summary-order-container {
  max-width: 95%;
  width: 100%;
  text-align: center;
  margin: 0 auto;

  & .file-invalid-error-message {
    color: red;
    font-size: 14px;
  }

  & .title {
    margin-top: 4.7rem;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 3.3rem;

    @include themify($themes) {
      color: themed('main');
    }

    text-decoration: underline;
  }

  & .order-status {
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 3.3rem;
    margin-bottom: 4rem;

    @include themify($themes) {
      color: themed('main');
    }

    & span {
      font-weight: 700;
    }

    & .id {
      margin-right: auto;
      display: flex;

      .file_name {
        margin-left: 1rem;
        width: 35rem;
        /* Set a fixed width */
        overflow: hidden;
        /* Hide overflow content */
        white-space: nowrap;
        /* Prevent text wrapping */
        text-overflow: ellipsis;
        font-weight: 700;

      }

      .tooltip-class {
        @include themify($themes) {
          background: themed('main');
        }

        min-width: 5rem;
        width: auto;
        text-align: center;

        &.show {
          opacity: 100% !important;
        }
      }
    }

    & .status {
      margin-left: auto;
    }
  }

  .submit-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    justify-content: center;
    max-width: 144rem;
    gap: 10rem;

    & .submit-button {
      width: 100%;
      max-width: 21rem;

      display: flex;
      justify-content: center;

      & button {
        width: 100%;
      }
    }

    & .form-date-input-item {
      width: auto;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      max-width: 32rem;
      position: relative;

      & .form-react-picker-dropdown {
        cursor: pointer;

        &.disable {
          pointer-events: none;
        }
      }

      & .form-date-picker {
        position: absolute;
        z-index: 101;
        top: 5rem;

        & .react-datepicker {
          display: flex;
          position: relative;
          border: none;
          box-shadow: 0.5rem 0.8rem 2.1rem rgb(0 0 0 / 15%);
          font-size: 1.3rem;

          &__month-container {
            & .react-datepicker__header {
              background: none;
              border-bottom: none;
              padding: 1.2rem 1rem;

              & .react-datepicker__current-month {
                font-size: 1.4rem;
              }
            }

            & .react-datepicker__week {
              .react-datepicker__day {
                width: 3rem;
                line-height: 3rem;

                &--in-range {
                  @include themify($themes) {
                    background-color: themed('bgActive');
                  }
                }

                &--selected {
                  @include themify($themes) {
                    background-color: themed('main');
                  }
                }

                &--in-selecting-range {
                  @include themify($themes) {
                    background-color: themed('mainHover');
                  }
                }

                &--keyboard-selected {
                  @include themify($themes) {
                    background-color: themed('main');
                  }
                }
              }
            }
          }
        }
      }

      & .select-dropdown {
        width: 100%;
        height: 4.2rem;
        border-radius: 0.5rem;
        font-size: 1.4rem;
        font-weight: 400;

        @include themify($themes) {
          color: themed('main');
        }

        border: none;

        &.disabled {
          opacity: 30%;
          pointer-events: none;
          user-select: none;
        }

        [class$='-control'] {
          @include themify($themes) {
            color: themed('main');
          }

          border: none;

          @include themify($themes) {
            background-color: themed('mainHover');
          }

          height: 4.2rem;
          box-shadow: none;

          &:hover {
            border: none;
          }
        }

        [class$='-placeholder'] {
          @include themify($themes) {
            color: themed('main');
          }

          font-size: 1.4rem;
          font-weight: 400;
        }

        [class$='-indicatorContainer'] {
          @include themify($themes) {
            color: themed('main');
          }
        }

        [class$='-singleValue'] {
          @include themify($themes) {
            color: themed('main');
          }
        }

        [class$='-indicatorSeparator'] {
          background: none;

          @include themify($themes) {
            color: themed('main');
          }
        }

        [class$='-indicator'] {
          @include themify($themes) {
            background: themed('main');
          }

          color: $white;
        }
      }

      & .label {
        @include themify($themes) {
          color: themed('main');
        }

        font-weight: 700;
        font-size: 1.8rem;
        line-height: 2.5rem;
        text-align: center;
      }
    }
  }

  .sections-container {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    margin-bottom: 4rem;
  }
}

.modal-proforma-restriction {
  width: 70rem;
  padding: 30px;



  h1 {
    @include themify($themes) {
      color: themed('main');
    }
  }

  h2 {
    @include themify($themes) {
      color: themed('main');
    }
  }

  .btn-validate-restriction {
    padding-top: 2.4rem;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;

    button {
      width: 30rem;
    }


  }

  .restriction-reference {
    width: 100%;

    @include themify($themes) {
      color: themed('main');
    }

    font-size: 1.8rem;

  }

  .color-red-h1 {
    color: red;
  }
}


.modal-success-proforma {
  padding: 50px;
  text-align: center;

  @media screen and (max-width: 768px) {
    margin-top: 100px;
  }

  h1 {
    @include themify($themes) {
      color: themed('main');
    }
  }

  h2 {
    @include themify($themes) {
      color: themed('main');
    }
  }
}

.table-wrapper-tariff table .row:first-child .heading:last-child .table-title {
  height: inherit;
}