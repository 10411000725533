@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.menu-item-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  // cursor: pointer;
  gap: 5px;
  margin-bottom: 10px;

  .iconWrapper {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    flex: 0 0 auto;
    gap: 5px;
    justify-content: center;
    align-items: center;
    background-color: $white-6;

    @include themify($themes) {
      color: themed('disabled');
    }



    .icon {
      width: 18px;
      height: 20px;

      svg {
        @include themify($themes) {
          fill: themed('colorIconMyAccount');
        }
      }

    }
  }

  .text {
    padding-left: 10px;

    @include themify($themes) {
      color: themed('main');
    }

    font-size: 16px;
  }
}