@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.mecadepot-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 2rem;
  position: relative;
  height: calc(100% - 6rem);
  width: 95%;

  @media only screen and (max-width: 768px) {
    width: auto;
    height: calc(100% - 6rem);
    margin: 1rem 2rem;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;

    &-title {
      @include themify($themes) {
        color: themed('main');
      }

      font-size: 1.8rem;
      font-weight: bold;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;

    &__description {
      font-size: 1.4rem;
      max-height: 80vh;
      overflow-y: auto;

      @media only screen and (max-width: 768px) {
        padding: 1rem;
        box-sizing: border-box;

        overflow-x: hidden;
      }
    }

    &-footer {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      &-submit {
        width: 20rem;
      }
    }

    &.is-loading {
      min-height: 20rem;
    }
  }
}