@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.news-page-main-container {
  max-width: 132rem;
  width: 100%;
  margin: 0rem auto;

  .floatable-buttons-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1rem;
    top: 9rem;
    position: absolute;
    right: 5rem;
  }

  .news-page-container {
    padding-top: 2rem;
    padding-bottom: 2rem;

    .news-title-container {
      width: 100%;
      height: 4.4rem;
      top: 9.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0.5rem;

      .news-title {
        @include themify($themes) {
          color: themed('main');
        }

        font-weight: bold;
        font-size: 2.5rem;
        line-height: 3.4rem;
        display: flex;
        align-items: center;
        text-align: center;
      }
    }

    .news-promotion-video-container {
      .no-news-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        padding: 5rem 0;

        & svg {
          max-width: 35rem;
          max-height: 35rem;
        }
      }

      & .promotions-container {
        position: relative;

        &.loadingPromo {
          height: 20rem;
        }
      }

      .news-promo {
        padding: 5rem 0;

        &.container-fluid-large {
          width: 100%;
          max-width: none;
        }
      }

      .news-video {
        display: flex;
        flex-direction: row;
        gap: 13rem;
        position: relative;
        padding: 2rem 13rem 6.4rem 9.7rem;
        justify-content: center;

        @media screen and (max-width: 768px) {
          padding: 0rem;
          gap: 0rem
        }

        .side-news-videos {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-bottom: 1.9rem;
          gap: 3.5rem;
        }
      }
    }

    .news-flux-rss {
      display: flex;
      flex-direction: row;
      gap: 3.4rem;
      justify-content: center;
      margin-bottom: 5rem;
    }
  }
}

/* Responsive CSS Styles */

.app-layout-desktop {
  @media screen and (max-width: 1200px) {
    .news-page-main-container {
      .news-promotion-video-container {
        position: relative;

        &.loading {
          height: 70vh;
        }

        .news-video {
          gap: 3rem;
          align-items: flex-start;
          padding-left: 2rem;
          padding-right: 2rem;
        }
      }
    }
  }
}

.app-layout-tablet {
  .news-page-main-container {
    .news-promotion-video-container {
      .news-video {
        gap: 3rem;
        align-items: flex-start;
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }

    .news-page-container {
      .news-flux-rss {
        .swiper-wrapper {
          .swiper-slide {
            max-width: 24.2rem;
          }
        }
      }
    }
  }
}

.app-layout-mobile {
  .news-page-main-container {
    .news-page-container {
      .news-title-container {
        margin-top: 0;
        margin-bottom: 1rem;

        .news-title {
          font-weight: 700;
          font-size: 2rem;
          line-height: 2.8rem;
        }
      }

      .news-promotion-video-container {
        padding: 2rem 0;

        .news-promo {
          padding: 0;
        }

        .news-video {
          flex-wrap: wrap;
          padding: 1rem 2rem;
          gap: 2rem;

          .side-news-videos {
            gap: 1.5rem;
          }
        }
      }

      .news-flux-rss {
        .swiper-wrapper {
          &.with-navigation {
            .swiper-container {
              padding: 0 2rem;
              width: calc(100% - 4rem);

              .navigation {
                flex-grow: 1;
                flex-shrink: 1;
              }
            }
          }
        }
      }
    }
  }
}