.catalog-vehicle-layout {
  padding: 0rem;
}

/* Responsive CSS Styles */

.app-layout-tablet,
.app-layout-mobile {
  .catalog-vehicle-layout {
    padding: 0;

    .catalog-vehicle-content-wrapper {
      padding: 0;
    }
  }
}