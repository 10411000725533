@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.vehicle-selector-tabs-container {
  width: 100%;

  & .vehicle-selector-content-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.7rem;
    gap: 0.938rem;

    & .tab-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 5rem;
      height: 2.7rem;
      @include themify($themes) {
        background-color: themed('tabInactive');
        color: themed('tabInactiveColor');
      }
      font-size: 2rem;
      border-radius: 0.938rem;
      cursor: pointer;
      &.disabled {
        pointer-events: none;
      }
      &.active {
        @include themify($themes) {
          background-color: themed('tabActive');
          color: themed('tabActiveColor');
        }
      }
    }
  }
}
