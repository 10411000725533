@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.drop-background {
  width: 100%;
  background-color: $blue-14;
  color: #3b4d7c;
  outline: 0.3rem dashed cornflowerblue;
  outline-offset: -0.1rem;
}

.proforma-drop {
  border-left: 1px solid $blue-14;
  width: 100%;
}

.proforma-drop-text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.disabled-drop-text {
  color: grey;
}

.fa-times.dropback {
  height: 1.4rem;
  width: 1.4rem;
  cursor: pointer;
}

.proforma-file-items {
  & > .proforma-file-item {
    display: flex;
    align-items: center;
    gap: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    margin: 0.1rem 0.2rem 0 0.2rem;
    @include themify($themes) {
      color: themed('main');
    }

    & > .proforma-item-name {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    & > i {
      cursor: grab;
    }
  }
}

.fas.fa-bars.drag-disabled {
  cursor: default;
  color: #828282;
}

.proforma-disabled-text {
  color: #828282 !important;
  pointer-events: none;
}
