@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.tabcard-fav-category {
  &.tabcard-container {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px;
    @include themify($themes) {
      background-color: themed('secondary');
    }
    width: 40rem;
    border-radius: 10px;

    .image-wrapper {
      width: 8.5rem;
      height: 8.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding-left: 20px;
      @include themify($themes) {
        color: themed('main');
      }
      font-size: 1.6rem;
      font-weight: bold;
    }
  }
}

/* Mobile CSS Styles */

.app-layout-mobile {
  .tabcard-fav-category {
    &.tabcard-container {
      padding-left: 1rem;
      padding-right: 1rem;
      max-width: calc(100% - 2rem);
      margin: 0 auto;
      width: 100%;
    }
  }
}
