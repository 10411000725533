@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.notification-item-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  @include themify($themes) {background-color: themed('mainHover');}
  padding: 2rem;
  box-sizing: border-box;
  border-radius: 1rem;
  &.unread {
    border: 2px dashed $blue;
    & .notification-message {
      font-weight: bold;
    }
  }
  & .notification-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;

    & .notification-info-icon {
      @include themify($themes) {
        color: themed('main');
      }
      border: 2px solid $blue-1;
      border-radius: 50%;
      padding: 2rem;
      & .notification-icon {
        width: 2rem;
        height: 2rem;
      }
    }
  }
  & .notification-info {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    &-text {
      display: flex;
      justify-content: space-between;
      gap: 2rem;
      width: 100%;
      & .notification-message {
        display: -webkit-box;
        max-width: 100%;
        width: 100%;
        margin: 0 auto;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1.2rem;
      }
      & .notification-date {
        font-size: 1.2rem;
        white-space: nowrap;
      }
    }
  }
  & .notification-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    & .notification-file {
      @include themify($themes) {
        color: themed('main');
      }
      display: flex;
      gap: 1rem;
      font-size: 1.2rem;
      border: 1px dashed $blue-1;
      border-radius: 2px;
      padding: 0.5rem;
      & .notification-download-icon {
        cursor: pointer;
        &:hover {
          opacity: 75%;
        }
        & .download-notification-spinner {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
      & .notification-filename {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 12rem;
      }
    }
    & .notifications-btns {
      display: flex;
      gap: 1rem;
      justify-content: flex-end;
      width: 100%;
    }
    & .notification-action {
      @include themify($themes) {
        color: themed('main');
      }
      font-weight: bold;
      font-size: 1.4rem;
      cursor: pointer;
      &:hover {
        opacity: 75%;
      }
    }
  }
}
