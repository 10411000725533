@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.vehicle-iframe {
  display: flex;
  width: 100%;
  min-height: 370px;
  height: 100vh;
  background-color: $white;
  position: relative;
  box-shadow: 0.2rem 0.2rem 1rem rgb(1 1 1 / 15%);
  padding: 1rem;
  box-sizing: border-box;
  margin-bottom: 5rem;

  &.content-empty {
    align-items: center;
  }

  &__no-data {
    text-align: center;
    width: 100%;
    font-size: 20px;
  }
}
