@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.retailer-rapid-search {
  &,
  .retailer-catalog-search {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 3.7rem;
    & .rapid-search-catalog-items {
      display: flex;
      justify-content: center;
      gap: 3rem;
    }
  }

  & .header-search {
    margin-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1.3rem;

    & .title {
      font-size: 2.5rem;
      font-weight: 200;
      word-spacing: 3px;
      @include themify($themes) {
        color: themed('main');
      }
    }

    & .subtitle {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      height: 2.5rem;
      padding: 0 2rem;
      border: $light-blue 1px solid;
      border-radius: 1.55rem;
      color: $light-blue;
      font-size: 1.2rem;
    }

    & .header-search-input {
      width: 61.7rem;
    }
  }

  & .general-search {
    display: flex;
    flex-direction: row;
    height: 36.2rem;
    gap: 1rem;
    padding: 0 1rem;
  }

  & .arrow-icon {
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 2rem;
  }

  .vehicle-quick-sliders {
    background: $white;
    padding: 4rem 0;

    & .quick-sliders {
      .slider-title {
        text-align: center;
        font-size: 1.6rem;
        @include themify($themes) {
          color: themed('main');
        }
        font-weight: bold;
        margin-left: 2rem;
      }

      .tabs {
        .tab-list {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          .tab-container {
            display: flex;
            justify-content: center;
            gap: 1rem;

            .tab {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 1.3rem;
              color: $white;
              list-style: none;
              background-color: $blue-5;
              width: 16.4rem;
              height: 2.7rem;
              border-radius: 0.9rem;
              cursor: pointer;
              line-height: 2.7rem;

              &.react-tabs__tab--selected {
                @include themify($themes) {
                  background-color: themed('main');
                }
                cursor: default;
              }
            }
          }

          & .view-all {
            @include themify($themes) {
              color: themed('main');
            }
            font-weight: bold;
            right: 10rem;
            position: absolute;
            font-size: 1.3rem;
            cursor: pointer;

            &:hover {
              opacity: 60%;
            }
          }
        }
      }
    }
  }
}

/* Responsive CSS Styles */

.app-layout-desktop {
  @media screen and (max-width: 1200px) {
    .retailer-rapid-search {
      & .rapid-search-catalog-items {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 2rem;
      }

      .general-search {
        height: 100%;
        flex-direction: column;
        .search-card-refs {
          .ref-search-inputs-container {
            .reference-container {
              gap: 2rem;
              flex-wrap: wrap;
            }
          }
        }
      }
    }
  }
}

.app-layout-mobile,
.app-layout-tablet {
  .retailer-rapid-search {
    margin: 0 auto 2rem;

    & .rapid-search-catalog-items {
      display: flex;
      justify-content: center;
      gap: 3rem;

      & .swiper-wrapper {
        & .swiper-slide {
          & div:first-child {
            margin: 0rem;
          }
        }
      }
    }

    .retailer-catalog-search {
      padding-top: 2rem;
      background: $background-grey;
      padding-bottom: 3.7rem;
    }

    .header-search {
      padding: 0 2rem;
      gap: 1.8rem;
      margin: 0;
      text-align: center;
    }

    .title {
      color: $black;
    }

    .subtitle {
      @include themify($themes) {
        background-color: themed('secondary-3');
      }
      border-color: $grey-7;
      padding: 0.3rem 2rem;
      margin: 0.3rem 0;
      font-weight: 700;
      display: inline-table;
      border-radius: 2.5rem;
      height: auto;
    }

    .general-search {
      flex-flow: column wrap;
      height: auto;
      padding: 0 2rem;
      margin: 0;
      gap: 1.2rem;
      position: relative;
      z-index: 2;
    }

    .retailer-rapid-search {
      & .general-search {
        flex-flow: column wrap;
        height: auto;
        padding: 0 2rem;
        margin: 0;
        gap: 1.2rem;
        position: relative;
        z-index: 2;
      }
    }

    & .quick-sliders {
      padding: 0;

      .slider-title {
        margin: 0;
        padding-bottom: 1rem;
      }

      .tabs {
        .tab-list {
          flex-flow: column wrap;
          padding: 0 2rem;
          width: calc(100% - 4rem);

          .view-all {
            right: initial;
            position: relative;
            margin: 1rem auto;
          }

          .tab-container {
            .tab {
              max-width: 15rem;
            }
          }
        }
      }
    }
  }
}

.app-layout-tablet {
  .retailer-rapid-search {
    .general-search {
      max-width: 61.7rem;
      align-self: center;
      width: 100%;
    }
  }
}
