@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.logout-button-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  // width: 100%;
  height: 4rem;
  border-radius: 4.2rem;
  @include themify($themes) {
    background-color: themed('main');
  }
  gap: 0.7rem;
  color: $white;
  font-size: 1.3rem;
  cursor: pointer;

  &:hover {
    background-color: $blue-5;
    @include themify($themes) {
      color: themed('main');
    }
  }
}
