@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.ref-search-card {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.3rem;
  flex: 1;
  @include themify($themes) {
    background-color: themed('secondary');
  }
  border-radius: 1rem;
  box-shadow: 0.2rem 0.2rem 1rem #0000000a;
  padding: 3rem;

  > .ref-search-card-content {
    position: relative;
    width: 43rem;
  }

  & .reference-label {
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 1.4rem;

    & .reference-icon-parts {
      @include themify($themes) {
        color: themed('main');
      }
      width: 1.4rem;
      height: 1.4rem;
    }

    & .reference-icon-tyre {
      @include themify($themes) {
        color: themed('main');
      }
      width: 2rem;
      height: 2rem;
    }

    & .reference-icon-oil {
      @include themify($themes) {
        color: themed('main');
      }
      width: 2.3rem;
      height: 1.7rem;
    }
  }
}
