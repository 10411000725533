@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.modal-select-sendemail {
  .close {
    font-size: 2.4rem;
    line-height: 1;
    @include themify($themes) {
      color: themed('main');
    }
    text-shadow: 0 1px 0 $blue;
    background: none;
    border: none;
    position: absolute;
    right: 0.3rem;
    top: 0.5rem;

    @media screen and (max-width: 990px) {
      top: 2.5rem;
    }

    @media screen and (max-width: 768px) {
      top: 2.5rem;
    }
  }

  .close:hover,
  .close:focus {
    @include themify($themes) {
      color: themed('main');
    }
    text-decoration: none;
    cursor: pointer;
    filter: alpha(opacity=50);
    opacity: 0.5;
  }

  .sendemail-modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .checkout-modal-title {
      @include themify($themes) {
        color: themed('main');
      }
      font-size: 2rem;
      margin-top: 5rem;
      margin-bottom: 2rem;
    }

    .modal-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border-radius: 1rem;

      .modal-body {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        flex-direction: column;
        margin: 0rem;
        gap: 2rem;
        padding: 0 4.4rem;
        width: 100%;

        .sendemail-input-container {
          width: 100%;
          display: flex;
          flex-direction: column;

          & .sendemail-error {
            border: 1px solid $red !important;
          }

          & .sendemail-modal-err {
            color: $red;
          }

          & .sendemail-input {
            border: 1px solid $blue-12;
            height: 5rem;
            border-radius: 0.5rem;
            width: 100%;
            padding-left: 2rem;
            @include themify($themes) {
              color: themed('main');
            }
            box-sizing: border-box;
            outline: none;

            &::placeholder {
              color: $blue-12;
            }
          }

          & .sendemail-textarea {
            border: 1px solid $blue-12;
            height: 16.5rem;
            border-radius: 0.5rem;
            width: 100%;
            padding-left: 2rem;
            padding-top: 2rem;
            @include themify($themes) {
              color: themed('main');
            }
            resize: none;
            box-sizing: border-box;
            outline: none;

            &::placeholder {
              color: $blue-12;
            }
          }
        }

        & .sendemail-button {
          max-width: 10rem;
          width: 100%;
          margin-bottom: 5rem;
        }
      }
    }
  }
}
