@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.espace-automotor-tariffs {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 4rem;
  max-width: 132rem;

  @media screen and (max-width: 990px) {
    width: auto;
    margin: 0rem;
  }

  & .price-list-container {
    width: 100%;

    @media screen and (max-width: 990px) {
      width: 100%;
    }

    & .price-list-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 4rem;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 1rem;
      }

      & .price-list-title {
        @include themify($themes) {
          color: themed('main');
        }

        font-size: 2rem;
        font-weight: bold;
      }

      & .add-new-tariff-btn {
        width: 18rem;
      }
    }

    & .tariff-items {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      position: relative;

      & .tariffs-spinner {
        top: 10rem;
      }

      & .no-data {
        font-size: 2.4rem;
        font-weight: bold;

        @include themify($themes) {
          color: themed('main');
        }

        opacity: 60%;
        margin-top: 5rem;
        white-space: nowrap;
      }
    }
  }
}