@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.ref-dropdown-content-parts-admin {
  position: absolute;
  background-color: $hovered-blue;
  border-radius: 1rem;
  margin-top: 0.2rem;
  z-index: 10;
  box-shadow: 0.2rem 0.2rem 1rem rgb(1 1 1 / 15%);
  min-width: 30%;
  font-size: 1.4rem;
  font-weight: bold;
  overflow-y: auto;
  max-height: 30rem;

  @media screen and (min-width: 200px) and (max-width: 460px) {
    left: 10%;
    width: 85%;
  }

  @media screen and (min-width: 461px) and (max-width: 768px) {
    left: 15%;
    width: 70%;
  }

  & .ref-dropdown-header {
    padding: 1.3rem 0.6rem 0.4rem 0.6rem;
    border-bottom: 1px solid $blue-5;

    & .ref-dropdown-title {
      @include themify($themes) {
        color: themed('main');
      }
    }
  }

  & .ref-search-result-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & .skeleton-line {
      margin: 0.6rem;
    }

    & .no-data {
      @include themify($themes) {
        color: themed('main');
      }
      padding: 1.1rem;
    }

    & .suggestion-list-item {
      display: flex;
      list-style: none;
      padding: 1.1rem;
      cursor: pointer;
      width: 100%;
      box-sizing: border-box;

      &:last-child {
        border-radius: 0 0 1rem 1rem;
      }

      &:hover {
        background-color: $blue-5;
      }

      & .suggestion-item-title {
        display: grid;
        grid-template-columns: 10rem 8rem 20rem;
        align-items: center;
        gap: 1rem;
        @include themify($themes) {
          color: themed('main');
        }

        &.has-commercial-reference {
          grid-template-columns: 20rem 8rem 20rem;
        }

        @media screen and (max-width: 990px) {
          display: flex;
        }
      }
    }
  }
}

/* Mobile CSS Styles */

.app-layout-mobile {
  //.search-inputs-container {
  //  div[class$='-dropdown-content'] {
  //    min-width: 1rem;
  //    width: calc(100% - 3rem);
  //
  //    & .ref-search-result-content {
  //      width: 100%;
  //
  //      & .suggestion-list-item {
  //
  //        &:last-child {
  //          border-radius: 0 0 1rem 1rem;
  //        }
  //
  //        & .suggestion-item-title {
  //          display: grid;
  //          grid-template-columns: 30% 20% 50%;
  //          width: 100%;
  //          gap: .5rem;
  //        }
  //      }
  //    }
  //  }
  //}

  @media screen and (max-width: 360px) {
  }
}
