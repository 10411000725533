@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.catalogs-tab-container {
  width: 60%;
  display: flex;
  position: relative;
  justify-content: flex-start;
  flex: 0 0 100%;
  flex-flow: column nowrap;
  text-align: center;
  gap: 1rem;

  a,
  a:focus,
  a:visited {
    color: $light-blue;
  }

  .vehicle-page-navbar {
    display: flex;
    justify-content: flex-start;
    padding-top: 1rem;

    .navbar-item {
      width: 130px;
      height: 24px;

      @include themify($themes) {
        color: themed('tabInactive');
      }

      label {
        @include themify($themes) {
          color: themed('main');
          opacity: 0.7;
        }
      }

      svg {
        width: 1.5rem;
        height: 1.5rem;

        @include themify($themes) {
          path {
            fill: themed('tabInactive');
          }

          rect {
            fill: themed('tabInactive');
          }

          ellipse {
            fill: themed('tabInactive');
          }
        }
      }

      .icon {
        padding-right: 5px;
        margin-bottom: -3px;
      }

      a {
        line-height: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        font-size: 13px;
        letter-spacing: 0;
        text-align: center;
        text-decoration: none;
        height: 100%;
        width: 100%;
        padding-bottom: 1.3rem;
        box-sizing: border-box;
      }

      img {
        opacity: 0.7;
      }

      a.active {
        @include themify($themes) {
          color: themed('tabActive');
          border-bottom: 4px solid themed('tabActive');
        }

        label {
          @include themify($themes) {
            color: themed('tabActive');
          }
        }

        svg {
          @include themify($themes) {
            path {
              fill: themed('tabActive');
            }

            rect {
              fill: themed('tabActive');
            }

            ellipse {
              fill: themed('tabActive');
            }
          }
        }

        img {
          opacity: 1;
        }
      }
    }
  }
}

/* Responsive CSS Styles */

.app-layout-desktop {}

.app-layout-tablet {}

.app-layout-tablet,
.app-layout-mobile {
  .catalogs-tab-container {
    width: 100%;

    .vehicle-page-navbar {
      padding: 0;

      .navbar-item {
        width: 100%;
      }
    }
  }
}

.app-layout-mobile {
  .catalogs-tab-container {
    .vehicle-page-navbar {
      .navbar-item {
        padding: 1rem 1rem 0 1rem;

        a {
          label {
            display: none;
          }
        }
      }
    }
  }
}