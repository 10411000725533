@import 'src/assets/sass/_variables';
@import 'src/assets/sass/themes';

.list-container {
  .heading-list {
    color: #28549c;
  }

  .check {
    &:nth-child(even) {
      @include themify($themes) {
        background-color: themed('categoriesSidebar', 'evenItem');
      }
    }

    &:nth-child(odd) {
      @include themify($themes) {
        background-color: themed('categoriesSidebar', 'oddItem');
      }
    }
  }
}