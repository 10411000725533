@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 3px;
}
.buttons.hidden {
  display: none;
}
.buttons.show {
  max-width: 20rem;
  cursor: pointer;
}
.file-button {
  max-width: 20rem;
  cursor: pointer;
}

.app-layout-tablet,
.app-layout-mobile {
  .floatable-buttons-wrapper {
    display: flex;
    flex-direction: column-reverse;
    top: unset;
    bottom: 1rem;
    position: fixed !important;
    gap: 0.3rem;
  }
}
