@import './assets/sass/variables';
@import 'src/assets/sass/themes';

[class^='theme-'] {
  height: 100%;
}

.app-style {
  height: 100%;
  @include themify($themes) {
    background-color: themed('bgApp');
  }
}
