@import '../../../../../../assets/sass/variables';
@import 'src/assets/sass/themes';
@import '../../../../../../assets/sass/_mixins';

.espace-reliable-navbar-container {
  .espace-reliable-navbar {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include themify($themes) {
      background-color: themed('secondary');
    }

    height: 8rem;

    @media only screen and (max-width: 768px) {
      height: 7rem;
    }

    & .sidebar-mobile {
      height: 7rem;
      width: 7.8rem;
      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: 990px) and (min-width: 768px) {
        width: 48px;
      }

      & .icon {
        font-size: 1.8rem;

        @include themify($themes) {
          color: themed('main');
        }

        font-weight: 900;
      }
    }

    & .logos {
      display: flex;
      align-items: center;
      height: 100%;
      gap: 1rem;

      @media only screen and (max-width: 768px) {
        width: 100%;
        justify-content: flex-start;
      }

      @media only screen and (max-width: 990px) and (min-width: 768px) {
        width: 100%;
        justify-content: flex-start;
      }

      & .garage-michel {
        display: flex;
        flex-direction: column;
        margin-top: 0.5rem;
        margin-left: 0.6rem;
        height: 100%;
        align-items: center;
        justify-content: center;
        padding-right: 0.5rem;
        border-right: 1px solid rgba(39, 80, 159, 0.2);

        & .client-logo {
          max-width: 10rem;
          max-height: 5rem;
        }

        & .user-name {
          background: $blue-5;
          font-style: normal;
          line-height: 1.7rem;
          align-items: center;
          font-size: 1rem;
          font-weight: 600;

          @include themify($themes) {
            color: themed('main');
          }

          height: 2rem;
          padding-left: 1rem;
          padding-right: 1rem;
          border-radius: 0.3rem;
          white-space: nowrap;
        }

        @media only screen and (max-width: 768px) {
          margin-top: 0.6rem;

          & .client-logo {
            max-height: 4rem;
          }

          & .user-name {
            margin-top: 0.5em;
          }
        }
      }
    }

    & .espace-reliable-tabs {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      gap: 1.2rem;

      & .espace-reliable-tab {
        button {
          width: 22rem;
          height: 4.2rem;
          font-size: 1.2rem;
          font-family: 'Open Sans', sans-serif;
          line-height: 1.7rem;
          font-weight: 600;

          @media only screen and (max-width: 1050px) and (min-width: 990px) {
            width: 18rem;
          }
        }

        .espace-catalog-button-rounded {
          text-align: center;
          padding: 1.33rem 6.49rem;
          font-size: 1.2rem;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 1.5rem;
          font-family: 'Open Sans', sans-serif;

          @include themify($themes) {
            color: themed('main');
          }

          transition: none;

          @include themify($themes) {
            background-color: themed('secondary-3');
          }

          cursor: pointer;
          outline: none;
          border: none;
          transition: all 0.3s ease-in-out;
          text-decoration: none;
          border-radius: 2rem;
          white-space: nowrap;

          & a {
            color: white;
            text-decoration: none;
          }

          &:hover {
            background-color: #355099;
            color: #ffffff;
          }
        }
      }

      .active {
        button {
          @include themify($themes) {
            background-color: themed('main');
          }

          color: $white;
        }
      }

      .disable {
        button {
          cursor: not-allowed;
          opacity: 0.5;

          &:hover {
            @include themify($themes) {
              color: themed('main');
            }

            background-color: $icon-background-color;
          }
        }
      }
    }

    & .user-settings {
      display: flex;
      align-items: center;
      gap: 1.7rem;
      padding-right: 1rem;

      & .mini-dropdowns {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 0.6rem;
        margin-right: 1rem;
      }
    }
  }
}