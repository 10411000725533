@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.sidebarWrapperSidebar {
  @include themify($themes) {
    color: themed('main');
  }
  min-width: 50vw;
  position: fixed;
  background: $blue-9;

  animation-duration: 0.4s;
  animation-name: FloatingLeft;
  animation-timing-function: ease;

  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  .header {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding: 10px;

    .icon {
      font-size: 24px;
      cursor: pointer;
    }

    .returnButton {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .content {
    padding: 50px;
    overflow: auto;
    max-height: calc(100vh - 180px);

    @media only screen and (max-width: 768px) {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: calc(100vh - 120px);
      padding: 20px;
    }

    .items {
      display: grid;
      grid-template-columns: 1fr 1fr;

      @media only screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
      }

      .item {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .title {
          padding: 0;
          font-size: 20px;
          font-weight: 700;
        }

        .item {
          font-size: 14px;
          cursor: pointer;
        }
      }

      .disabled {
        color: grey;

        cursor: text;
        pointer-events: none;
      }

      .disabled::before {
        background-color: #e5eefd;
      }
    }
  }

  @keyframes FloatingLeft {
    0% {
      left: -256px;
    }

    100% {
      left: 0px;
    }
  }
}
