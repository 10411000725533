@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.car-part-details-wrapper {
  @include themify($themes) {
    color: themed('main');
  }
  text-align: left;
  max-width: 35.8rem;
  .car-detail__header {
    padding: 2.1rem 0;
    .car-detail__header__article {
      font-size: 1.4rem;
      line-height: 1.4rem;
      span {
        font-weight: bold;
      }
    }
    .car-detail__header__title {
      margin: 1.3rem 0;
      font-size: 4rem;
      line-height: 5.5rem;
      font-weight: bold;
      @media screen and (max-width: 1160px) {
        font-size: 3rem;
        line-height: 3.5rem;
      }
    }
    .car-detail__header__category {
      font-size: 1.4rem;
      line-height: 1.9rem;
      @media screen and (max-width: 1160px) {
        font-size: 1rem;
        line-height: 1.4rem;
      }
    }
    .car-detail__header__set {
      padding: 1.9rem 0 0 0;
      font-weight: bold;
      font-size: 1.2rem;
      line-height: 1.7rem;
      @media screen and (max-width: 1160px) {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }
  .car-detail__content {
    max-height: 40rem;
    overflow-y: auto;
    margin: 0 1rem;
    .content-item {
      margin: 0;
      .label,
      .value {
        display: inline-block;
        margin: 0;
        font-size: 1.4rem;
        line-height: 1.9rem;
      }
      .value {
        font-weight: bold;
        padding-left: 1rem;
        & .commercial-ref-value {
          color: $red;
        }
      }
    }
  }
}
