@import "src/assets/sass/variables";
@import "src/assets/sass/themes";
@import "src/assets/sass/_mixins";

.espace-tab-container {
  background-color: white;
  border-radius: 0.5rem;
  height: 35rem;
  width: 46.2rem;
  overflow: hidden;
  padding: 1rem;

  @media screen and (max-width: 990px) {
    width: auto;
  }

  &.tab-disabled {
    opacity: 0.5;
    position: relative;
    pointer-events: none;
    cursor: not-allowed;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 0;
  }

  .title,
  .subtitle {
    text-align: center;
    display: block;
    width: 100%;
    font-family: "Open Sans", sans-serif;

    @include themify($themes) {
      color: themed("main");
    }

    letter-spacing: 0;
    margin: 0 auto;
    padding: 0.5rem 0;
  }

  .text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    max-width: 100%;
    word-break: break-word;
  }

  @media screen and (max-width: 768px) {
    height: auto;
  }

  .notifications {
    .notification-item {
      display: inline-block;
      padding: 0;
    }
  }

  .title {
    font-size: 2.3rem;
    line-height: 3.2rem;
    text-transform: capitalize;

    span {
      font-weight: bold;
    }
  }

  .subtitle {
    max-width: 32.9rem;
    font-size: 1.5rem;
    line-height: 2rem;

    @media screen and (max-width: 768px) {
      max-width: 100%;
    }
  }

  .button {
    text-align: center;
    margin: 1.9rem auto 1rem;

    button {
      max-width: 28.5rem;
      height: 3.4rem;
      font-size: 1.12rem;
      font-weight: bold;
      border-radius: 0.8rem;
      line-height: 1.5rem;
    }
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 12rem;
  }

  .items {
    @media screen and (max-width: 768px) {
      padding: 2rem 0;
    }

    max-height: 14.5em;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 100%;

    .navigation {
      background: transparent;
      border: none;

      @include themify($themes) {
        color: themed("main");
      }

      font-size: 32px;
      cursor: pointer;
    }

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      @media screen and (max-width: 992px) {
        min-width: 18rem;
      }
    }

    .item-container {
      padding: 0.5rem;
      flex: 1;

      .item {
        width: 120px;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.extra-small {
  circle {
    @include themify($themes) {
      stroke: themed("main");
    }

    stroke-width: 7;
  }
}