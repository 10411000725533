@import "assets/sass/variables";

.client-order-table-history {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 20rem;

  & .back-btn {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    cursor: pointer;
    color: $blue-1;
    padding-left: 10px;

    &:hover {
      opacity: 75%;
    }
  }

  & .orders-filter {
    height: 15rem;
    border-radius: 1rem;
    border: 1px solid #cfd9ee;
    margin-bottom: 2rem;
    margin-top: 2.5rem;

    @media screen and (max-width: 990px) {
      height: auto;
      padding-bottom: 1rem;
    }

    @media screen and (max-width: 768px) {
      height: auto;
      padding-bottom: 1rem;
    }

    .orders-filter-header {
      display: flex;
      flex: 2;
      align-items: center;
      justify-content: center;
      padding: 2.1rem 2.4rem;
      gap: 35%;

      & .filter-title {
        font-size: 1.4rem;
        color: $blue-1;
        font-weight: bold;
      }

      & .filter-reliquats {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        color: $blue-1;
      }
    }

    & .orders-filter-body {
      display: flex;
      flex-direction: row;
      gap: 1.4rem;
      padding: 0 2.4rem;

      @media screen and (max-width: 990px) {
        flex-direction: column;
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      & .orders-filter-input-item {
        width: 100%;
        position: relative;

        & .orders-react-picker-dropdown {
          cursor: pointer;

          &.disable {
            pointer-events: none;
          }
        }

        & .orders-date-picker {
          position: absolute;
          z-index: 101;
          top: 5rem;

          @media screen and (max-width: 768px) {
            width: 100%;
          }

          & .react-datepicker {
            display: flex;
            position: relative;
            border: none;
            box-shadow: 0.5rem 0.8rem 2.1rem rgb(0 0 0 / 15%);
            font-size: 1.3rem;

            &__month-container {
              & .react-datepicker__header {
                background: none;
                border-bottom: none;
                padding: 1.2rem 1rem;

                & .react-datepicker__current-month {
                  font-size: 1.4rem;
                }

                & .react-datepicker__day-names {
                  .react-datepicker__day-name {
                    width: 3rem;
                  }
                }
              }

              & .react-datepicker__week {
                .react-datepicker__day {
                  width: 3rem;
                  line-height: 3rem;

                  &--in-range {
                    background-color: #d73c5a;
                  }

                  &--selected {
                    background-color: #d73c5a;
                  }

                  &--in-selecting-range {
                    background-color: #f5bec7;
                  }

                  &--keyboard-selected {
                    background-color: #d73c5a;
                  }
                }
              }
            }

            .react-datepicker__month-container {
              @media screen and (max-width: 768px) {
                width: 100%;
              }
            }
          }
        }

        & .select-dropdown {
          width: 100%;
          height: 4.2rem;
          border-radius: 0.5rem;
          font-size: 1.4rem;
          font-weight: 400;
          color: $blue;
          border: none;

          &.disabled {
            opacity: 30%;
            pointer-events: none;
            user-select: none;
          }

          [class$="-control"] {
            color: $blue;
            border: none;
            background-color: $blue-3;
            height: 4.2rem;
            box-shadow: none;

            &:hover {
              border: none;
            }
          }

          [class$="-placeholder"] {
            color: $blue;
            font-size: 1.4rem;
            font-weight: 400;
          }

          [class$="-indicatorContainer"] {
            color: $blue;
          }

          [class$="-singleValue"] {
            color: $blue;
          }

          [class$="-indicatorSeparator"] {
            background: none;
            color: $blue;
          }

          [class$="-indicator"] {
            background: $blue;
            color: $white;
          }
        }
      }
    }
  }

  & .client-order-table-container-history {
    min-height: 10rem;
    background-color: $white-5;
    border-radius: 1rem;
    position: relative;

    & .order-table-small-spinner {
      position: absolute;

      &.has-data {
        position: relative;
        margin-bottom: 0.5rem;
      }
    }

    table {
      font-family: "Open Sans", sans-serif;
      font-size: 1.2rem;
      line-height: 1.7rem;
      text-align: left;
      letter-spacing: normal;
      color: $blue-1;
      width: 100%;
      background: $white-5;
      border-spacing: 0 0.7rem;
      padding: 0.4rem 2.5rem;
      border-radius: 1rem;

      @media screen and (max-width: 768px) {
        padding: 1rem;
        box-sizing: border-box;
      }

      .comment {
        width: 1.25rem;
        height: 1.25rem;
        fill: $blue-1;
      }

      .headings {
        .row {
          .heading {
            padding: 0.4rem 0.4rem 0.4rem 1.4rem;

            .icons {
              .fa-caret-down {
                margin-top: -0.5rem;
              }
            }
          }
        }
      }

      .body {
        .row {
          background-color: $white-3;

          &:hover {
            opacity: 80%;
            cursor: pointer;
          }

          .cell {
            padding: 0.85rem 0.4rem 0.85rem 0.4rem;

            &:first-child {
              border-radius: 0.5rem 0 0 0.5rem;
            }

            &:last-of-type {
              border-radius: 0 0.5rem 0.5rem 0;
              background-color: $blue-5;

              & .status {
                display: flex;
                justify-content: space-evenly;
                text-align: center;
                justify-items: center;
                font-size: 1.3rem;

                @media screen and (max-width: 768px) {
                  flex-direction: column;
                }

                .status_old {
                  width: 20rem;

                  @media screen and (max-width: 768px) {
                    width: auto;
                    margin-bottom: 3px;
                  }
                }

                .status_new {
                  width: 20rem;

                  @media screen and (max-width: 768px) {
                    width: auto;
                    margin-top: 3px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  & .order-no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.1rem 2.4rem;
    font-size: 1.4rem;
    color: #27509f;
    font-weight: bold;
  }

  & .sellers-table-spinner {
    background: none;
    position: absolute;
  }
}
