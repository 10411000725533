@import "src/assets/sass/variables";
@import "src/assets/sass/themes";

.tyre-tcrmi-container {
  position: relative;
  padding: 0;

  .tyres-tcrmi-table-spinner {
    top: 12rem;
  }

  .tyres-input-search {
    margin-top: 3rem;

    .text-input-container {
      .close-icon {
        right: 4.5rem;
      }
    }
  }

  .search-tyres-container {
    margin-top: 2rem;
    padding-bottom: 2rem;

    & .tyres-container {
      display: flex;
      margin-top: 2rem;
      border-radius: 0.5rem;

      @include themify($themes) {
        background-color: themed("secondary-2");
      }

      & .tyres-table-container {
        justify-content: space-between;
        margin: 1.5rem;
        width: 100%;

        & .table-wrapper-tariff {
          align-items: center;
          width: 100%;

          & div.tfoot {
            display: none;
          }

          & div.table-title:last-child {
            height: 3.4rem !important;

            @include themify($themes) {
              background-color: themed("secondary-2") !important;
            }
          }
        }

        .table-wrapper-tariff-default {
          width: 100%;

          table {
            padding: 0;

            .row {
              .heading {
                .table-title {
                  height: 100%;
                }
              }
            }

            .body {
              .row {
                .cell {
                  max-width: max-content;
                }
              }
            }
          }
        }
      }

      .notFoundSvg {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        flex-direction: column;

        svg {
          height: auto;
          max-width: 100%;
        }

        & h1 {
          @include themify($themes) {
            color: themed("main");
          }

          align-self: center;
          justify-self: center;
          position: relative;
          font-size: 3rem;
        }
      }
    }
  }
}

/* Responsive CSS Styles */

.app-layout-mobile,
.app-layout-tablet {
  .tyre-tcrmi-container {
    padding: 0 2rem;

    .search-tyres-container {
      & .tyres-container {
        margin-top: 0;

        & .tyres-table-container {
          margin: 0;

          .table-wrapper-tariff {
            table {
              padding: 0;
            }
          }
        }
      }
    }
  }
}

.app-layout-mobile {
  .search-tyres-container {
    & .tyres-container {
      .no-parts-data {
        svg {
          width: 50%;
        }
      }
    }
  }
}
