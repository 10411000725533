@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.error-codes-layout {
  display: flex;
  gap: 2rem;
  width: 100%;

  .tdoc-content.container-fluid-medium {
    .error-codes-header {
      overflow: hidden;
      margin: 1.5rem auto;
      display: flex;
      justify-content: center;
      align-items: center;

      form {
        // margin-left: 14rem;

        @media screen and (max-width: 990px) and (min-width: 768px) {
          margin-left: 0rem;
        }
      }

      .error-codes-login-vts {
        max-width: 14rem;
        min-width: 14rem;
        // height: 8.1rem;
        //display: none;

        .error-codes-login-vts .default-button {
          width: calc(100% - 2rem);
        }
      }
    }
  }

  .error-codes-container {
    overflow: hidden;
    max-width: 93rem;
    margin: 0 auto;
  }
}
