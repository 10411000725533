@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.dropdown-content-table-wrapper {
  table {
    background: none !important;

    @media screen and (max-width: 760px) {
      font-size: 0.9rem !important;
      padding: 0.4rem 1rem !important;
      box-sizing: border-box !important;
    }

    .body {
      .rows-dropdown {
        @include themify($themes) {
          background-color: themed('generalSearch', 'bgTable') !important;
        }

        &:hover {
          opacity: 100% !important;
          @include themify($themes) {
            background-color: themed('generalSearch', 'bgTableHover') !important;
          }
        }

        .cell {
          padding: 0.85rem 0.4rem 0.85rem 1.4rem;
          cursor: pointer;
        }

        .cell:last-of-type {
          background: none !important;
          text-align: center;
        }
      }
    }
  }
}
