@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.country_restriction_header {
  width: 100%;
  margin: 0 auto;
  padding: 0rem;
  max-width: 132rem;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    margin: 0;
    min-height: 71vh;
  }

  @media screen and (min-width: 768px) and (max-width: 990px) {
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    margin: 0;
    min-height: 71vh;
  }



  .country_restriction_title {
    display: flex;
    justify-content: center;
    font-size: 2rem;
    margin-top: 2.7rem;

    @include themify($themes) {
      color: themed('main');
    }

    font-weight: bold;
  }

  .filter {
    height: 13rem;
    border-radius: 1rem;
    border: 1px solid #cfd9ee;
    margin-bottom: 2rem;
    margin-top: 2.5rem;

    @media screen and (max-width: 768px) {
      height: auto;
      border: none;
      margin-top: 5.5rem;
    }

    .spinner.extra-small svg {
      width: 1.3rem;
    }

    & .filter-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2.1rem 2.4rem;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      & .filter-title {
        font-size: 1.4rem;

        @include themify($themes) {
          color: themed('main');
        }

        font-weight: bold;
      }

      & .filter-reliquats {
        display: flex;
        align-items: center;
        font-size: 1.2rem;

        @include themify($themes) {
          color: themed('main');
        }
      }

      & .filter-link-btn {
        @media screen and (max-width: 768px) {
          display: none;
        }

        .add-btn {
          @include themify($themes) {
            background-color: themed('secondary-3');
          }

          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          border: 1px solid #7285b8;
          border-radius: 5rem;
          width: 100%;
          padding: 0.9rem 2rem;

          @include themify($themes) {
            color: themed('main');
          }

          font-size: 1.4rem;
          cursor: pointer;

          .icon {
            @include themify($themes) {
              color: themed('main');
            }

            font-size: 1.6rem;
          }
        }
      }
    }

    & .filter-body {
      display: flex;
      flex-direction: row;
      gap: 1.4rem;
      padding: 0 2.4rem;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      & .filter-input-item {
        width: 400rem;
        position: relative;

        & .react-picker-dropdown {
          cursor: pointer;

          &.disable {
            pointer-events: none;
          }
        }

        & .date-picker {
          position: absolute;
          z-index: 101;
          top: 5rem;

          @media screen and (max-width: 768px) {
            width: 100%;
          }

          & .react-datepicker {
            display: flex;
            position: relative;
            border: none;
            box-shadow: 0.5rem 0.8rem 2.1rem rgb(0 0 0 / 15%);
            font-size: 1.3rem;

            &__month-container {
              & .react-datepicker__header {
                background: none;
                border-bottom: none;
                padding: 1.2rem 1rem;

                & .react-datepicker__current-month {
                  font-size: 1.4rem;
                }

                & .react-datepicker__day-names {
                  & .react-datepicker__day-name {
                    width: 3rem;
                    line-height: 4rem;
                  }
                }
              }

              & .react-datepicker__week {
                .react-datepicker__day {
                  width: 3rem;
                  line-height: 3rem;

                  &--in-range {
                    background-color: #d73c5a;
                  }

                  &--selected {
                    background-color: #d73c5a;
                  }

                  &--in-selecting-range {
                    background-color: #f5bec7;
                  }

                  &--keyboard-selected {
                    background-color: #d73c5a;
                  }
                }
              }
            }

            .react-datepicker__month-container {
              @media screen and (max-width: 768px) {
                width: 100%;
              }
            }
          }
        }

        & .select-dropdown {
          width: 100%;
          height: 4.2rem;
          border-radius: 0.5rem;
          font-size: 1.4rem;
          font-weight: 400;

          @include themify($themes) {
            color: themed('main');
          }

          border: none;

          &.disabled {
            opacity: 30%;
            pointer-events: none;
            user-select: none;
          }

          [class$='-control'] {
            @include themify($themes) {
              color: themed('main');
            }

            border: none;

            @include themify($themes) {
              background-color: themed('mainHover');
            }

            height: 4.2rem;
            box-shadow: none;

            &:hover {
              border: none;
            }
          }

          [class$='-placeholder'] {
            @include themify($themes) {
              color: themed('main');
            }

            font-size: 1.4rem;
            font-weight: 400;
          }

          [class$='-indicatorContainer'] {
            @include themify($themes) {
              color: themed('main');
            }
          }

          [class$='-singleValue'] {
            @include themify($themes) {
              color: themed('main');
            }
          }

          [class$='-indicatorSeparator'] {
            background: none;

            @include themify($themes) {
              color: themed('main');
            }
          }

          [class$='-indicator'] {
            @include themify($themes) {
              background: themed('main');
            }

            color: $white;
          }
        }
      }
    }
  }

  .filter-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: 1rem;
    box-sizing: border-box;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 1rem;
    }

    .button-filter-promo {
      @include themify($themes) {
        background-color: themed('secondary-3');
      }

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      border: 1px solid #7285b8;
      border-radius: 5rem;
      width: 18rem;
      padding: 0.9rem 2rem;

      @include themify($themes) {
        color: themed('main');
      }

      font-size: 1.4rem;
      cursor: pointer;

      .icon {
        @include themify($themes) {
          color: themed('main');
        }

        font-size: 1.6rem;
      }
    }

    .add-btn {
      @include themify($themes) {
        background-color: themed('secondary-3');
      }

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      border: 1px solid #7285b8;
      border-radius: 5rem;
      width: 17rem;
      padding: 0.9rem 2rem;

      @include themify($themes) {
        color: themed('main');
      }

      font-size: 1.4rem;
      cursor: pointer;

      .icon {
        @include themify($themes) {
          color: themed('main');
        }

        font-size: 1.6rem;
      }
    }
  }

  .promo-filter {
    @media screen and (max-width: 768px) {
      background-color: #f2f5f8;
      height: 100vh;
      width: 0;
      position: absolute;
      z-index: 15;
      top: 0rem;
      left: 0;
      overflow: hidden;
      transition: 0.5s;
      border: none;
      border-radius: 0rem;
      margin-bottom: 0rem;
      margin-top: 0rem;
    }
  }

  .hidden-filter-promo {
    width: 0%;
  }

  .show-filter-promo {
    width: 100%;
  }

  .close-btn {
    @include themify($themes) {
      color: themed('main');
    }

    font-size: 1.6rem;
    font-weight: 900;
    width: 95%;
    padding-top: 1rem;
    display: flex;
    justify-content: right;
    align-items: center;
  }

  .row-aling-right {
    text-align: right;
    font-size: 1.6rem;

    svg {
      padding-right: 1rem;
      cursor: pointer;
    }
  }

  .notFond {
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;

    @include themify($themes) {
      color: themed('main');
    }

    opacity: 60%;
  }

  .table-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 20rem;

    table {
      @media screen and (max-width: 768px) {
        padding: 1rem;
        box-sizing: border-box;
      }

      .body {
        .cell-row {
          &:hover {
            opacity: 50%;
            cursor: pointer;
          }

          font-size: 1.2rem;

          .cell {
            padding: .85rem .4rem .85rem 1.4rem;
            max-width: max-content;

            @include themify($themes) {
              background-color: themed('secondary-3');
            }



            @include themify($themes) {
              color: themed('main');
            }
          }

          .cell:last-of-type {
            @include themify($themes) {
              background-color: themed('cellTableLast');
            }

            border-radius: 0 .5rem .5rem 0;
          }
        }


      }
    }

    & .order-no-data {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2.1rem 2.4rem;
      font-size: 1.4rem;

      @include themify($themes) {
        color: themed('main');
      }

      font-weight: bold;
    }

    & .sellers-table-spinner {
      background: none;
      position: absolute;
    }
  }
}