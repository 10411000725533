$green: #7de1b2;
$green-2: #00b517;
$green-3: #cfeedc;
$green-4: #4dc00d;

$red: #ca312a;
$red1: #ee2939;
$red-2: #bd0039;
$red-3: #ed2a39;
$red-4: #ed2939;
$red-5: #ff8b7b;

$blue: #355099;
$blue-1: #27509f;
$blue-2: #d8e1f1;
$blue-3: #ecf0f7;
$blue-4: #b7c6e3;
$blue-5: #cfd9ee;
$blue-6: #e1ecff;
$blue-7: #e0e7f3;
$blue-8: #d5dceb;
$blue-9: #e5eefd;
$blue-10: #f0f4fc;
$blue-11: #e4eaf6;
$blue-12: #c4d1eb;
$blue-13: #7491c9;
$blue-14: #d5e2fc;
$blue-15: #abc6f3;
$blue-16: #27509f20;
$blue-17: #e2e9f7;
$blue-18: #9db0d4;
$blue-19: #35509980; // 50% opacity
$blue-20: #27509f99; // 60% opacity
$blue-21: #cfdefb;
$blue-22: #ccd8eb;
$blue-23: #A9B9D9;
$light-blue: #7285b8;
$blue-24: #72b3ff;
$red: red;
$black: #171818;
$black-2: #8c8c8c;

$white: #ffffff;
$white-1: #f5f9ff;
$white-2: #ecf0f6;
$white-3: #e4ebf7;
$white-4: #ebf0fb;
$white-5: #f1f5fc;
$white-6: #ffffff80; // white + Opacity 0.5
$white-7: #f1f5fc4d; // white + Opacity 0.3
$white-8: #f6f9fd;
$white-9: #0000000a; // white + Opacity 0.04
$white-10: #f9fcff;

$gray: #e5e5e5;
$gray-o75: #e5e5e5bf;
//TODO: add colors for other parts
$grey-1: #ebebeb;
$grey-2: #e9eef8;
$grey-3-o75: #e8e8e8bf; // opacity 0.75
$grey-4: #becbe3;
$grey-5: #e7e7e7;
$gray-6: #dbe3ef;
$grey-7: #c0cde6;
$gray-10: #6c757d;
$grey-11: #f6f6f6;
$grey-12: #9fa2a4;
$grey-13: #878787;
$grey-14: #a4a4a4;

$icon-background-color: #eef2fa;
$border-color: #e3e8f5;
$favorite-border-color: #cfd9ee;
$background-grey: #f2f5f8;
$input-color: #e4ebf7;
$blue-icon-inactive-fill: #829bca;
$blue-icon-active-fill: #dae3f3;
$background-color-1: #dde6f5;
$background-color-2: #dde6f6;
$blue-text-color: #27509f;
$blue-text-color-o47: #27509f78; // opacity 0.47
$blue-icon-color: #27509f;
$blue-pale: #3e63aa;
$white-gradient: #f8fbff;
$red: #ca312a;
$hovered-blue: #dde6f5;
$yellow: #ffa92a;
$orange: #ffa92b;
$anchor-text-color-proforma-modal: #778cb3;
$background-disabled-button: #92a3c8;

$container-width-full: 100%;
$container-width-xlarge: 144rem;
$container-width-large: 126rem;
$container-width-medium: 99.2rem;
$container-width-small: 76.8rem;
$container-width-xsmall: 48rem;

$vin-hover-color: #cd544d;

$vehicle-page-sidebar-width: 31.5rem;