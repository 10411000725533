@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.vehicle-selector-tabs-container {
  width: 100%;

  .tab {
    display: flex;
  }

  & .tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.7rem;
    gap: 0.938rem;

    & .tab-item {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 5rem;
      height: 2.7rem;
      @include themify($themes) {
        background-color: themed('tabInactive');
        color: themed('tabInactiveColor');
      }
      font-size: 2rem;
      border-radius: 0.938rem;
      cursor: pointer;
      .title {
        padding: 0 10px;
        height: 100%;
        display: flex;
        justify-content: center;
        svg {
          margin: auto;
          @include themify($themes) {
            path {
              fill: themed('tabInactiveColor');
            }
            rect {
              stroke: themed('tabInactiveColor');
            }
          }
        }
        span {
          padding-left: 5px;
          font-size: 11px;
          font-weight: 700;
          line-height: 27px;
        }
      }

      &.active {
        @include themify($themes) {
          background-color: themed('tabActive');
          color: themed('tabActiveColor');
          svg {
            path {
              fill: themed('tabActiveColor');
            }
            rect {
              stroke: themed('tabActiveColor');
            }
          }
        }
      }
    }
  }
}
