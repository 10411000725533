@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.category-card {
  width: 100%;
  min-height: 7rem;
  background-color: #e5eeff;
  border-radius: 0.5rem;
  margin-top: 2rem;

  &-header {
    display: flex;
    align-items: center;
    flex-direction: column;

    & .category-card-title {
      @include themify($themes) {
        color: themed('main');
      }
      font-weight: 600;
      font-size: 1.6rem;
      padding-top: 1.3rem;
      border-bottom: 0.1rem solid #cfd9ee;
      width: 90%;
      text-align: center;
      padding-bottom: 0.4rem;
      margin-bottom: 0.5rem;
    }
  }

  &-body {
    @include themify($themes) {
      color: themed('main');
    }

    & .subcategory-title {
      margin-left: 0.5rem;

      ul {
        margin-top: 0.3rem;
        li {
          text-decoration: underline;
          cursor: pointer;
          font-size: 1.4rem;

          &:hover {
            opacity: 75%;
          }
        }
      }

      & .title {
        margin-left: 2rem;
        font-weight: 600;
        font-size: 1.6rem;
      }
    }
  }
}
