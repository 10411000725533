@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.sort-by-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;

  @include themify($themes) {
    color: themed('main');
  }

  cursor: pointer;
  padding: 0 0.3rem;
  font-weight: bold;
  width: 100%;
  flex: 0 1 auto;
  z-index: 11;

  .sort-select-container {
    font-weight: normal;
    font-size: 13px;
    flex-grow: 1;
    max-width: 20rem;
    width: 100%;
  }

  svg {
    width: 2rem;
    height: 2rem;
  }

  >* {
    margin: 0 0.5rem;
    white-space: nowrap;
  }
}

/* Responsive CSS Styles */

.app-layout-tablet,
.app-layout-mobile {
  .sort-by-container {}
}

.app-layout-mobile {
  @media screen and (max-width: 380px) {
    .sort-by-container {
      .sort-select-container {
        flex-grow: 0;
      }
    }
  }
}