@import 'assets/sass/variables';

.client-order-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 20rem;

  &-container {
    min-height: 10rem;
    background-color: $white-5;
    border-radius: 1rem;
    position: relative;

    & .order-table-small-spinner {
      position: absolute;

      &.has-data {
        position: relative;
        margin-bottom: 0.5rem;
      }
    }

    table {
      font-family: 'Open Sans', sans-serif;
      font-size: 1.2rem;
      line-height: 1.7rem;
      text-align: left;
      letter-spacing: normal;
      color: $blue-1;
      width: 100%;
      background: $white-5;
      border-spacing: 0 0.7rem;
      padding: 0.4rem 2.5rem;
      border-radius: 1rem;

      @media screen and (max-width: 768px) {
        padding: 1rem;
        box-sizing: border-box;
      }

      .comment {
        width: 1.25rem;
        height: 1.25rem;
        fill: $blue-1;
      }

      .headings {
        .row {
          .heading {
            padding: 0.4rem 0.4rem 0.4rem 1.4rem;

            .icons {
              .fa-caret-down {
                margin-top: -0.5rem;
              }
            }
          }
        }
      }

      .body {
        .row {
          background-color: $white-3;

          &:hover {
            opacity: 80%;
            cursor: pointer;
          }

          .cell {
            padding: 0.85rem 0.4rem 0.85rem 0.4rem;

            &:first-child {
              border-radius: 0.5rem 0 0 0.5rem;
            }

            &:last-of-type {
              border-radius: 0 0.5rem 0.5rem 0;
              background-color: $blue-5;

              & span {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1rem;

                & .reject-order {
                  color: $red1;

                  &:hover {
                    color: $red-2;
                  }
                }

                & .confirm-order {
                  color: $green-2;

                  &:hover {
                    color: $green;
                  }
                }

                & .confirm-order-btn {
                  background-color: $green-2;
                  color: $white;
                  border-radius: 0.5rem;
                  padding: 0.5rem 1rem;
                  font-size: 1.2rem;
                  font-weight: 700;
                  cursor: pointer;
                  transition: all 0.3s ease-in-out;
                  outline: none;
                  border: none;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 0.5rem;

                  &:hover {
                    background-color: $green;
                  }
                }

                & .reject-order-btn {
                  background-color: $red1;
                  color: $white;
                  border-radius: 0.5rem;
                  padding: 0.5rem 1rem;
                  font-size: 1.2rem;
                  font-weight: 700;
                  cursor: pointer;
                  transition: all 0.3s ease-in-out;
                  outline: none;
                  border: none;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 0.5rem;

                  &:hover {
                    background-color: $red-2;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  & .order-no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.1rem 2.4rem;
    font-size: 1.4rem;
    color: #27509f;
    font-weight: bold;
  }

  & .sellers-table-spinner {
    background: none;
    position: absolute;
  }
}