@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.table-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &.stock-price-table-wrapper {
    min-height: 0;
    overflow: auto;
  }

  table {
    .body {
      background-color:  $white-5;

      .row {
        &:hover {
          opacity: 50%;
          cursor: pointer;
        }
      }

      .action {
        display: flex;
        justify-content: right;

        span {
          padding-right: 1rem;
          font-size: 1.7rem;
        }

        span:last-child {
          color: #ca312a;
        }
      }

      .no-data {
        font-size: 1.6rem;
        font-weight: bold;
        @include themify($themes) {
          color: themed('main');
        }
        opacity: 60%;
        text-align: center;
      }
    }
  }
}
