@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.rounded-file-button {
  width: auto;
  display: inline-flex;
  align-items: center;

  @include themify($themes) {
    color: themed('main');
  }

  // @media screen and (max-width: 768px) {
  //   color: $black;
  // }

  & .rounded-file-icon {

    border-radius: 6rem;
    padding: 0.8rem;
    font-size: 1rem;
    width: 1rem;
    height: 1rem;
    font-weight: bold;

    @include themify($themes) {
      background-color: themed('roundBtn', 'bgIcon');
    }

    @media screen and (max-width: 768px) {
      @include themify($themes) {
        color: themed('main');
        background-color: themed('secondary');
      }
    }
  }

  & .rounded-file-text {
    font-size: 1.3rem;
    padding: 0 1rem;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 1rem;
  }
}