@import 'assets/sass/variables';

.client-offer-details-container {
  width: 98%;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 5rem;

  & .back-btn {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    cursor: pointer;
    color: $blue-1;
    margin-top: 4.7rem;

    &:hover {
      opacity: 75%;
    }
  }

  .automotor-order-items {
    position: relative;
    min-height: 15rem;
  }

  & .title {
    margin-top: 4.7rem;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 3.3rem;
    color: $blue-1;
    text-decoration: underline;

    @media screen and (max-width: 768px) {
      font-size: 2rem;
      line-height: 2.8rem;
    }
  }

  & .order-status {
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 3.3rem;
    margin-bottom: 4rem;

    color: $blue-1;

    @media screen and (max-width: 768px) {
      font-size: 1.7rem;
      line-height: 2.8rem;
      padding: 0 1.5rem;
    }

    & span {
      font-weight: 700;
    }

    & .id {
      margin-right: auto;
    }

    & .status {
      margin-left: auto;
    }
  }

  .offer-summary {
    width: 100%;
  }

  .offer-summary table {
    & .cell {
      width: 50%;
    }

    & tr td:first-child.cell {
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }

  .automotor-order-items {
    margin-top: 2.8rem;
    width: 100%;
  }

  & .table-wrapper-tariff table .row:first-child .heading .table-title {
    background-color: transparent;
  }

  & .table-wrapper-tariff table .row:first-child .heading:last-child .table-title {
    height: 3.4rem;
  }

  .offer-tables {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: flex-end;
    gap: 1rem;

    &-export_btn {
      width: 10rem;
    }
  }
}