@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.ReactModal__Content {
  .print-iframe-modal {
    padding: 0 1rem;

    & .print-iframe-modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 2rem;
      margin-bottom: 2rem;
      @include themify($themes) {
        border-bottom: 0.1rem solid themed('separatorLine');
      }

      & .title {
        @include themify($themes) {
          color: themed('main');
        }
        font-size: 1.6rem;
      }

      & .header-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        @include themify($themes) {
          color: themed('main');
        }
        font-size: 1.4rem;
        position: relative;

        & .print-spinner {
          top: 0;
          left: -4rem;
          width: 2rem;
        }

        & .print-btn {
          display: flex;
          align-items: center;
          @include themify($themes) {
            color: themed('main');
          }
          font-size: 1.4rem;
          gap: 0.5rem;
          cursor: pointer;

          &:hover {
            opacity: 80%;
          }
        }

        & .close-modal {
          cursor: pointer;

          &:hover {
            opacity: 80%;
          }
        }
      }
    }

    .print-iframe {
      min-width: 70rem;
      height: 55rem;
    }
  }

  /* Mobile CSS Styles */

  .app-layout-mobile {
    width: 100%;
    height: 100%;

    .print-iframe-modal {
      width: 100%;
      height: 100%;
      padding: 0 2rem;
      box-sizing: border-box;
      min-width: auto;
      margin-top: 2rem;

      .print-iframe {
        min-width: auto;
        height: calc(100% - 10.3rem);

        .vehicle-iframe {
          min-height: 100%;
          margin: 0 auto;
        }
      }
    }
  }
}
