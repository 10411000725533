@import "src/assets/sass/variables";
@import "src/assets/sass/themes";

.ReactModal__Content {
  padding: 1rem 0.5rem;
  overflow: visible;
  @include themify($themes) {
    background: themed("secondary-2");
  }
}

.ReactModal__Content--after-open {
  @media only screen and (max-width: 768px) {
    border-radius: 0px;
  }

  @media screen and (max-width: 990px) and (min-width: 768px) {
    width: 90%;
    max-height: calc(100vh - 100px);
  }
}

.modal-dialog {
  padding: 2rem 1.5rem;
  & .modal-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & .import-modal-container {
      width: 100%;
    }
  }
}

.modal-new-tariff {
  @include themify($themes) {
    color: themed("main");
  }

  .error {
    & > div {
      border-color: #ca312a;
    }
    input {
      border: none !important;
    }
  }
  .text-center {
    text-align: center;
  }
  .close {
    float: right;
    text-align: center;
  }

  .title {
    font-size: 2.5rem;
    line-height: 3.4rem;
    letter-spacing: normal;
    text-align: center;
    margin: 0.5rem auto;
  }

  .import-content {
    max-width: 26.8rem;
    width: 100%;
    margin: 0 auto;
    padding: 1rem 0;

    label {
      display: inline-block;
      margin: 0.5rem 0;
    }

    & .download-catalog-template {
      padding-top: 0.5rem;

      & a {
        cursor: pointer;
        display: inline-block;
        @include themify($themes) {
          color: themed("main");
        }
        text-decoration: none;
        text-transform: lowercase;

        &::first-letter {
          text-transform: uppercase;
        }

        &:hover {
          opacity: 75%;
        }
      }
    }
  }

  .import-modal-container {
    @media screen and (max-width: 990px) and (min-width: 768px) {
      margin-top: 10rem;
    }

    .import-progressBar {
      .import-progressBar-title {
        margin-bottom: 1rem;
      }
    }

    .import-upload {
      height: auto;
      margin: 0 auto;

      .file-error {
        margin: 0.5rem 0;
        text-align: center;
        color: $red;
      }

      .content {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $blue-12;
        background: $white;
        border-radius: 1rem;
        font-size: 1.4rem;
        line-height: 1rem;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      & .error {
        @include themify($themes) {
          color: themed("main");
        }
        [class$="-control"] {
          border: 1px solid $red;
        }
      }
    }

    .import-files {
      padding: 0;

      .uploaded-message-file {
        padding: 1rem;
        margin: 0;
        background: none;
        height: auto;
        justify-content: center;

        .file-info {
          width: auto;
        }

        .remove-file {
          width: auto;
          padding: 0 1rem;
        }
      }
    }

    .import-clients {
      .error {
        @include themify($themes) {
          color: themed("main");
        }
        border-color: $red;
      }
    }

    .import-message {
      textarea {
        width: 100%;
        max-width: calc(100% - 1rem);
        min-height: 4rem;
        max-height: 8rem;
        overflow: auto;
        padding: 0.4rem;
        border-color: $blue-12;
        @include themify($themes) {
          color: themed("main");
        }
      }
    }

    .import-preview-wrapper {
      max-width: 100%;
      margin: 25px auto;
      max-height: 400px;
      overflow: auto;
      &.no-scroll {
        max-height: none;
        overflow: initial;
      }
      &.force-scroll {
        overflow: auto;
      }
      table {
        margin: 0 auto;

        td {
          padding: 4px 8px;
        }
      }
    }
  }

  &.mobile {
    .import-modal-container {
      // position: absolute;
      width: 89%;
      // transform: translate(0, 55%);
    }

    .close {
      margin: 8px 8px 0 0;
    }
  }
}
