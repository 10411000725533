@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.products-view-mode {
  position: relative;
  display: flex;
  padding: 5px;
  gap: 10px;

  .spinner {
    width: 10px;
    height: auto;
    display: flex;
    padding: 5px;
  }

  .icon {
    width: 18px;
    height: auto;
    display: flex;
    padding: 5px;
    @include themify($themes) {background: themed('mainHover');}
    @include themify($themes) {
      fill: themed('main');
    }
    border-radius: 5px;
    cursor: pointer;
    &.selected {
      @include themify($themes) {
        background-color: themed('main');
      }
      @include themify($themes) {fill: themed('mainHover');}
      pointer-events: none;
    }
  }
  .iconLine {
    fill: $blue-5;
  }
}
