@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.diagnostic-layout {
  .diagnostic-header {
    .title {
      padding: 2rem;

      h2 {
        @include themify($themes) {
          color: themed('main');
        }
        margin: 0;
        font-size: 1.4rem;
        line-height: 1.9rem;
        text-align: center;
      }
    }
  }

  .diagnostic-content {
    .solutions-container {
      margin: 4rem auto;
    }
  }
}

/* Responsive CSS Styles */

.app-layout-tablet,
.app-layout-mobile {
  .diagnostic-layout {
    padding-top: 1rem;
    background: $white;

    .diagnostic-header {
      .title {
        padding: 1rem 2rem 2rem;
      }
    }
    .diagnostic-content {
      padding: 0 2rem;

      .solutions-container {
        margin: 2rem auto;
      }
    }
  }
}

.app-layout-mobile {
  .diagnostic-layout {
    .diagnostic-items {
      padding: 0 2rem;
      width: calc(100% - 4rem);

      .diagnostic-item-container {
        width: 100%;
        max-width: none;

        .button {
          .default-button {
            width: auto;
            padding: 0 2rem;
          }
        }
      }
    }
  }
}
