@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

#load-file-modal-content {
  width: 51rem;
  position: relative;
  min-height: 4rem;

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-top: 8rem;
  }

  @media screen and (max-width: 990px) and (min-width: 768px) {
    width: 100%;
    margin-top: 10rem;
  }
}
