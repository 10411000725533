@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.actia-table-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .option {
    width: 3rem;
    cursor: pointer;
    &.download {
      .spinner {
        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
    svg {
      font-size: 1.5rem;
      margin: 0 auto;
      display: block;
    }
  }
}
.delete-confirmation-modal-session {
  .delete-container {
    .title {
      span {
        font-size: 1.8rem !important;
      }
    }
  }
}
