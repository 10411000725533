@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.ReactModal__Content {
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.ReactModal__Content--after-open {
  @media only screen and (max-width: 768px) {
    border-radius: 0px;
  }

  @media screen and (max-width: 990px) and (min-width: 768px) {
    width: 90%;
    max-height: calc(100vh - 100px);
  }
}

.offer-confirmation-modal {
  width: 36rem;

  @include themify($themes) {
    color: themed('main');
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100vh;
    padding: 2rem;
    min-width: auto;
    margin-top: 20vh;
    box-sizing: border-box;
  }

  @media screen and (max-width: 990px) and (min-width: 768px) {
    width: 80%;
    height: 100vh;
    padding: 2rem;
    min-width: auto;
    margin-top: 15vh;
    box-sizing: border-box;
    display: flex;
    margin-left: 15%;
  }

  .content {
    padding: 1rem;
    box-sizing: border-box;

    .message {
      font-size: 1.6rem;
      line-height: 2.1rem;
      margin-bottom: 3.2rem;
      text-align: center;
      font-weight: bold;

      span {
        font-weight: bold;
      }
    }

    .actions {
      width: 100%;
      display: flex;
      gap: 1.5rem;

      .no {
        width: 35%;
      }

      .yes {
        width: 65%;
      }

    }
  }
}