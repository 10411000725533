@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.catalog-supplier-navbar-container-messages {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $grey-1;

  .breadcrumbs {
    display: flex;
    align-items: center;
    gap: 2.3rem;

    img {
      max-height: 4.7rem;
      max-width: 11.7rem;
    }

    .icon svg {
      font-size: 2.5rem;
      @include themify($themes) {
        background-color: themed('secondary-3');
      }
    }
  }

  .title h1 {
    font-family: Open Sans, 'sans-serif';
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 2.5rem;
    line-height: 3.4rem;
    font-weight: normal;
    text-align: center;
  }

  .options {
    .catalog-parts-filter {
      display: flex;
      align-items: center;
      gap: 20px;

      .catalog-parts-desc {
        font-size: 11px;
        @include themify($themes) {
          color: themed('main');
        }
      }

      .catalog-parts-button {
        padding: 5px;
        @include themify($themes) {
          background-color: themed('main');
        }
        color: $white;
        border: none;
        font-weight: 500;
        width: 180px;
        height: 40px;
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }
}
