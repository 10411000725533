@import '../../../../../../assets/sass/variables';
@import 'src/assets/sass/themes';
@import '../../../../../../assets/sass/_mixins';

.clients-catalog {
  padding: 2.7rem 0 0 0;
  margin: 0 auto;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  @include themify($themes) {
    color: themed('main');
  }

  .row {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 3.4rem;
    display: flex;
    justify-content: center;

    .title {
      margin: 0;
      font-size: 3rem;
      letter-spacing: 0;
      line-height: 4.1rem;

      span {
        font-weight: bold;
      }
    }

    .online {
      height: 2.5rem;
      width: 5.625rem;
      white-space: normal;
      color: $red;
      font-size: 0.625rem;
      display: flex;
      align-items: center;
      margin: 0;
      padding-right: 43.188rem;
    }
  }

  .subtitle {
    max-width: 59rem;
    margin: 0 auto;
    font-style: italic;

    p {
      margin: 0;
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  .catalog-items {
    padding-top: 2rem;
    padding-bottom: 3.7rem;
    display: flex;
    flex-direction: row;
    gap: 2.5rem;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 990px) and (min-width: 768px) {
      gap: 1.5rem;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    padding-left: 1.25rem;
    flex-direction: row;

    .header-btn {
      color: $white;
      @include themify($themes) {
        background-color: themed('main');
      }
      height: 3.4rem;
      border: none;
      cursor: pointer;
      padding: 0.8rem;
      border-radius: 0.8rem;
      transition: 0.3s all;

      &:hover {
        background-color: $blue-12;
      }
    }

    & .blue {
      @include themify($themes) {
        background-color: themed('main');
      }
    }

    .icon {
      padding-right: 0.625rem;
    }
  }
}
