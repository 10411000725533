@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.genart-link-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  @include themify($themes) {
    background-color: themed('main');
  }
  color: $white;
  cursor: pointer;

  &.disabled {
    opacity: 30%;
    pointer-events: none;
  }

  &:hover {
    opacity: 80%;
  }

  & svg {
    font-size: 1.3rem;
    width: 2rem;
    height: 2rem;
    fill: $white;
  }
}
