@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.client-manager-select-client-button {
  display: flex;
  width: 15.7rem;
  height: 3rem;
  top: 1.7rem;
  background-color: $blue-5;
  border-radius: 0.3rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @include themify($themes) {
    color: themed('main');
  }

  &:hover {
    @include themify($themes) {
      background-color: themed('main');
    }
    color: $blue-5;
  }

  .select-client-title {
    font-style: normal;
    font-weight: 500;
    font-size: 1.3rem;
  }
}
