@import 'assets/sass/variables';
@import 'src/assets/sass/themes';
@import 'assets/sass/tables';

.container-fluid-large {
  max-width: $container-width-large;
}

.espace-supplier-container {
  max-width: 100%;
  margin: 0 auto;

  .container-width {
    max-width: $container-width-large;
  }

  .title {
    text-align: center;
    @include themify($themes) {
      color: themed('main');
    }
    display: inline-block;
    width: 100%;
    margin: 1.5rem 0;
  }

  .button {
    width: 19.4rem;
    height: 3.4rem;
  }

  .espace-supplier-top-container {
    max-width: 101.7rem;
    margin: 1.5rem auto 2.5rem;
    padding: 0 4.5rem;

    .title {
      font-family: 'Open Sans Regular', 'sans-serif';
      font-size: 3rem;
      line-height: 4.1rem;

      span {
        font-family: 'Open Sans Bold', 'sans-serif';
        font-weight: bold;
      }
    }

    .subtitle {
      text-align: center;
      @include themify($themes) {
        color: themed('main');
      }
      font-family: 'Open Sans Italic', 'sans-serif';
      font-style: italic;
      font-size: 1.5rem;
      line-height: 2rem;
      margin: 1.5rem 0 0.5rem;
      display: inline-block;
      width: 100%;
    }

    .items-container {
      width: 100%;

      .items {
        max-height: 100%;
        display: flex;
        flex-flow: wrap;
        flex-direction: row;
        text-align: center;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        gap: 3rem;

        .item-container {
          flex: 1 1 16%;
          max-height: 9rem;

          .item {
            height: 100%;
            width: 100%;
            max-height: fit-content;
          }
        }
      }
    }
  }

  .espace-supplier-main-container {
    display: block;

    & .promotions-slider {
      position: relative;
    }

    .order-container {
      padding: 1.5rem 0 5rem;

      .title {
        font-family: 'Open Sans', 'sans-serif';
        font-size: 2.5rem;
        font-weight: bold;
        line-height: 3.4rem;
      }

      .order-list-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        min-height: 20rem;

        & .sellers-table-spinner {
          position: absolute;
          background: none;
          top: -4rem;
        }

        .button {
          margin: 2.2rem auto;
        }
      }
    }
  }
}
