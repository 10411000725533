@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.diagnostic-items {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.7rem;
  padding-bottom: 2rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
