@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.ReactModal__Content {
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
}

.modules-modal {
  padding: 0 1rem;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding: 0 2rem;
    box-sizing: border-box;
    min-width: auto;
    margin-top: 2rem;
  }

  & .modules-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    margin-bottom: 1rem;

    @include themify($themes) {
      border-bottom: 0.1rem solid themed('border');
    }

    .modal-title {
      font-size: 1.5rem;

      @include themify($themes) {
        color: themed('main');
      }

      text-align: center;
      width: 100%;
    }

    & .close-modal {
      cursor: pointer;

      @include themify($themes) {
        color: themed('main');
      }

      font-size: 1.5rem;

      &:hover {
        opacity: 80%;
      }
    }
  }

  & .modules-modal-content {
    & .modules-modal-item {
      display: flex;
      align-items: center;
      text-decoration: none;

      &.active {
        pointer-events: none;
        border-radius: 0.3rem;

        @include themify($themes) {
          background-color: themed('main-2');
        }

        & .modules-modal-item-text {
          @include themify($themes) {
            color: themed('tabActiveColor');
          }
        }

        & .modules-modal-item-icon {
          @include themify($themes) {
            svg {
              fill: themed('tabActiveColor');
            }
          }
        }
      }

      &.disabled {
        opacity: 50%;
        pointer-events: none;
      }

      & .modules-modal-item-icon {
        width: 4rem;
        height: 4rem;
        outline: none;
        appearance: none;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        & svg {
          @include themify($themes) {
            fill: themed('main');
          }

          width: 2.5rem;
          height: 2.5rem;
          background-color: none;
        }
      }

      & .modules-modal-item-text {
        font-size: 1.5rem;

        @include themify($themes) {
          color: themed('main');
        }
      }
    }
  }
}