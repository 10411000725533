@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.error-codes-layout {
  padding-bottom: 1rem;
  flex-flow: row wrap;
  background: $white;

  @media screen and (min-width: 990px) {
    ::-webkit-scrollbar {
      width: 0.5rem;
      padding: 0 0.2rem;
    }

    ::-webkit-scrollbar-thumb {
      @include themify($themes) {
        color: themed('main');
      }

      border-radius: 4.5px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #dae2f1;
      border-radius: 10px;
    }
  }

  .error-codes-container {
    max-width: none;
    width: 100%;

    &.container-fluid-medium {
      max-width: 93rem;
    }

    overflow: hidden;
    margin: 0 auto;
  }

  .sidebar-details {
    .content {
      padding: 2rem 1rem;

      .items {
        max-height: 650px;
        overflow: auto;

        .item {
          &.active {
            color: $white;
          }
        }
      }
    }
  }
}