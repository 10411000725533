@import '../../assets/sass/variables';
@import 'src/assets/sass/themes';

.part-item-oe-list {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;

  @include themify($themes) {
    background-color: themed('secondary-darker');
  }

  border-radius: 0.5rem;
  box-sizing: border-box;
  max-height: 40rem;
  gap: 2rem;
  padding: 1rem 1rem;
  position: relative;
  justify-content: space-between;

  .part-list-item-favorite {
    position: absolute;
    right: 0;
    top: 1rem;

    .favorite-button {
      position: absolute;
      right: 0;

      .iconButton {
        background: none;
      }

      svg {
        cursor: pointer;
      }

      .spinner-container {
        width: 2rem !important;
        height: 2rem !important;
        position: relative;

        .spinner {
          position: absolute;
          right: 1rem;
          top: 0rem;
          left: unset;
          background-color: inherit;
        }
      }

      button {
        svg {
          position: absolute;
          width: 2rem !important;
          height: 2rem !important;
          right: 1rem;
          top: 0rem;
          left: unset;

          @include themify($themes) {
            fill: themed('main');
          }
        }
      }
    }
  }

  .oe-header {
    display: flex;
    flex-direction: row;
    flex: 1 1 100%;
    width: max-content;
    align-items: center;

    .img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 10rem;
      height: 10rem;
      border: 0.1rem solid $gray;

      img {
        max-width: 10rem;
        max-height: 10rem;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      flex: 1 1 100%;
      margin-left: 1rem;

      @include themify($themes) {
        color: themed('main');
      }

      .title {
        text-align: left;
        margin-bottom: 0.3rem;
        font-size: 1.4rem;
        font-weight: 600;
      }

      .reference {
        text-align: left;
        margin-bottom: 0.3rem;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.5;
      }

      .description-short {
        text-align: left;
        margin-bottom: 0.5rem;
        font-size: 1.1rem;
        font-weight: 600;

        @include themify($themes) {
          color: themed('main');
        }
      }

      .description {
        text-align: left;
        margin-bottom: 0.5rem;
        font-size: 1.1rem;

        @include themify($themes) {
          color: themed('main');
        }

        line-height: 1.3;
      }

      .product-man-group {
        max-height: 76px;
        overflow: auto;
        padding-right: 5px;

        &-info {
          & .oe-item-link {
            text-decoration: none;

            @include themify($themes) {
              color: themed('main');
            }

            &:hover {
              opacity: 75%;
            }
          }
        }

        .reference {
          line-height: 1.2;
        }
      }
    }
  }

  .oe-actions-side {
    display: flex;
    gap: 1rem;

    .oe-actions {
      display: flex;
      flex-direction: column;
      border-left: 0.1rem solid #a8b3c4;
      padding: 0 1rem;
      box-sizing: border-box;
      align-items: center;

      .cart-price {
        @include themify($themes) {
          color: themed('main');
        }
      }

      .quantity {
        flex-direction: column;
        margin: 0.3rem;
        display: flex;
        gap: 1rem;
      }

      .cart {
        text-align: center;
        margin-bottom: 0.2rem;
        font-size: 1.8rem;
        font-weight: 600;
        display: flex;
        align-items: flex-end;
        gap: 1rem;

        .part-cart-button {
          display: flex;

          .button {
            padding: 0.3rem;
          }
        }
      }
    }

    .barcode {
      width: 10.1rem;
      object-fit: contain;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 3rem;

      & img {
        max-width: 10.1rem;
        height: 5.2rem;
      }

      & .barcode-number {
        text-align: center;
      }
    }
  }
}