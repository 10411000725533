@import 'assets/sass/variables';
@import 'src/assets/sass/themes';


.modal_file_not_fond {

  padding: 2rem 3rem;
  min-height: 7rem;
  display: flex;
  flex-direction: column;
  width: 65rem;
  justify-content: center;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 10rem;
  }

  .text {
    font-size: 2.2rem;
    color: $blue;
    margin-bottom: 2rem;
    text-align: center;
  }

  .file_not_fond {
    color: #ca312a;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .button {
    width: 20rem;
  }
}