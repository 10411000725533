@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.retailer-messages-page {
  .catalog-supplier-navbar-container {
    max-width: 65rem;
    margin: 0 auto;
    border-bottom: 2px solid $grey-1;

    .title {
      font-weight: bold;
      padding-bottom: 2rem;
    }
  }

  .automotor-messages-wrapper {
    padding-top: 0;

    @media screen and (max-width: 768px) {
      width: 100%;
      min-width: auto;
    }

    .container {
      border-radius: 0;

      @media screen and (max-width: 768px) {
        width: 90%;
        min-height: auto;
        margin-bottom: 1rem;
      }

      @media screen and (max-width: 990px) and (min-width: 768px) {
        min-height: auto;
      }

      .content {
        .supplier {
          display: flex;
          align-items: center;
          justify-content: center;

          .info {
            svg {
              padding-right: 1rem;
            }

            font-size: 1.8rem;
            line-height: 2.4rem;
            text-align: center;
          }
        }

        .messages {
          z-index: 1;
          height: 30rem;
          position: relative;
          width: 100%;
          overflow-y: auto;
          display: flex;
          flex-direction: column;

          .messages-list {
            margin-top: auto;
            margin-right: 1.5rem;
            margin-left: 1.5rem;

            .loading-old-messages {
              min-height: 10rem;
              position: relative;
            }
          }

          #scrollableDiv {
            padding-right: 2rem;
            height: 50rem;
            position: relative;

            .extra-small {
              height: 3rem;
            }
          }

          .no-messages {
            font-size: 1.5rem;
            text-align: center;
          }
        }

        .reply-container {
          padding: 1rem 2rem 1rem 1.5rem;

          .reply-content {
            display: flex;
            gap: 1.1rem;

            .left-wrapper {
              text-align: right;
              width: 100%;

              textarea {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 4.2rem;
                border-radius: 0.5rem;
                border: none;
                padding: 0 1rem;
                font-size: 1.6rem;
                font-weight: 400;
                @include themify($themes) {
                  color: themed('main');
                }
                outline: none;
                transition: all 0.3s ease-in-out;
                background-color: $input-color;
                box-sizing: border-box;
              }

              &.error {
                textarea {
                  border: 1px solid red;

                  &:focus {
                    border: 1px solid red;
                  }
                }
              }

              .title {
                padding: 0;
              }

              textarea {
                resize: none;
                width: 100%;
                min-height: 9.6rem;
                background-color: $white;
                padding: 10px;

                &:focus-visible {
                  border: none;
                }
              }

              .submit-wrapper {
                padding-top: 1.4rem;
                width: 14.1rem;
                height: 3.3rem;
                margin: 0 0 0 auto;

                .spinner.extra-small svg circle {
                  stroke: $grey-2;
                }
              }
            }

            .right-wrapper {
              min-width: 8.1rem;

              .content {
                cursor: pointer;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                @include themify($themes) {
                  background-color: themed('secondary-3');
                }
                border-radius: 0.5rem;

                p {
                  margin: 0;
                }
              }
            }
          }
        }
      }

      //.message-data.sent{
      //  .message{
      //    background-color: #F9FCFF;
      //  }
      //}
    }
  }
}
