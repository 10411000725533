@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.car-part-options-wrapper {
  @include themify($themes) {
    color: themed('main');
  }

  .top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media screen and (max-width: 990px) {
      justify-content: center;
    }

    gap: 4.5rem;

    .item-option-barcode {
      color: black;
      width: 16rem;
    }

    .iconButton {
      &.favorite {
        padding-right: 6rem;
      }

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $white;
      border: none;
      cursor: pointer;

      @include themify($themes) {
        color: themed('main');
      }

      gap: 0.625rem;
      font-size: 1.2rem;
      line-height: 1.4rem;
      text-align: center;

      svg {
        width: 4.225rem;
        height: 2.825rem;

        path {
          @include themify($themes) {
            fill: themed('favorite', 'bgFill');
          }
        }
      }
    }
  }

  .teccom {
    cursor: pointer;
    border-bottom: 1px solid $grey-1;
    margin-bottom: 2rem;
    padding: 15rem 0 1.1rem 0;
    width: 100%;

    .teccom-icon {
      height: 3.8rem;
      width: 100%;
      margin: 0 auto;
    }
  }

  .bottom {
    .availability {
      @media screen and (max-width: 990px) {
        margin: 0 auto;
      }

      @include themify($themes) {
        color: themed('main');
      }

      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 990px) {
        justify-content: center;
      }

      align-items: center;

      .wrapper-quantity {
        .plusminus {
          background: #dbe3ef;
          width: 2rem;
          font-size: 2.4rem;
          line-height: 3.3rem;
          border: 0;
        }

        input[type='number'] {
          width: 5rem;

          @include themify($themes) {
            background-color: themed('secondary-3');
          }

          font-size: 1.8em;
          line-height: 2.4rem;
          border: 0;

          @include themify($themes) {
            color: themed('main');
          }

          &:focus-visible {
            outline-width: 0;
          }
        }
      }

      .quantity-disponibility {
        @media screen and (max-width: 990px) {
          width: auto;
        }

        padding-left: 2rem;
      }
    }

    .button {
      padding-top: 3.2rem;
      width: 100%;

      .iconButton {
        @media screen and (max-width: 1160px) {
          width: 25.6rem;
          height: 3.4rem;
        }

        @media screen and (max-width: 990px) {
          margin: 0 auto;
        }

        width: 100%;
        font-weight: bold;
        height: 3.4rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #ee2939;
        border-radius: 0.8rem;
        border: none;
        cursor: pointer;
        color: $white;
        gap: 1rem;
        font-size: 1.2rem;
        line-height: 1.7rem;
        text-align: center;

        svg {
          font-size: 1.6rem;
          fill: $blue-icon-color;
        }

        &:hover {
          background-color: $white;
          color: #ee2939;
          border: 0.1rem solid #ee2939;

          svg {
            font-size: 1.2rem;

            circle {
              stroke: #ee2939 !important;
            }
          }
        }

        .spinner {
          width: 3rem;
          flex-grow: initial;

          svg {
            font-size: 1.2rem;

            circle {
              stroke: $white;
            }
          }
        }
      }
    }
  }
}