@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.filter-supplier-category-mobile {
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  width: 100%;

  .categories-no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 2rem;
    font-weight: 600;
    @include themify($themes) {
      color: themed('main');
    }
    text-align: center;
  }

  .close-modal {
    position: absolute;
    right: 2.6rem;
    top: 1.6rem;
    font-size: 1.8rem;
    @include themify($themes) {
      color: themed('main');
    }

    &:hover {
      cursor: pointer;
    }
  }

  .modal-title {
    @include themify($themes) {
      color: themed('main');
    }
    letter-spacing: 0;
    line-height: 3.4rem;
    text-align: center;
    margin-bottom: 0rem;
    margin-top: 2rem;
    font-size: 1.9rem;

    span {
      font-weight: bold;
    }
  }

  .supplier-categories {
    display: flex;
    flex-direction: row;
    max-height: 78vh;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 3rem 2rem 2rem 2rem;

    .categories-list {
      width: 100%;
      background: #d3def2;
      border-radius: 5px;
      height: 100%;

      .supplier-list {
        padding: 0rem 1rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        width: 85%;

        img {
          width: 4rem;
          height: 4rem;
          background: #d3ddf0;
          outline: none;
          appearance: none;
          border-radius: 5px;
        }

        p {
          @include themify($themes) {
            color: themed('main');
          }
          font-family: 'Open Sans', sans-serif;
          font-size: 17px;
          font-weight: bold;
          letter-spacing: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-left: 1rem;
        }
      }
    }

    .supplier-subcategories-list {
      height: 100%;
      padding: 10px 0px 0px 20px;

      @include themify($themes) {
        color: themed('main');
      }
      width: 100%;
      overflow: auto;

      .return {
        font-size: 16px;
        padding: 0rem 0 3rem 0rem;
        width: 100%;

        span {
          cursor: pointer;
        }
      }

      .my-masonry-grid {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-left: -30px;
        width: auto;

        @media screen and (max-width: 768px) {
          flex-direction: column;
          width: 100%;
          height: 100%;
        }
      }

      .my-masonry-grid_column {
        padding-left: 30px;
        background-clip: padding-box;

        @media screen and (max-width: 768px) {
          flex-direction: column;
          width: 100% !important;
        }

        .item {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .title {
            padding: 0;
            font-size: 16px;
            font-weight: 700;
            line-height: 22px;
            display: flex;
            flex-direction: row;
            gap: 0.5rem;

            input[type='checkbox'] {
              @include themify($themes) {
                color: themed('main');
              }
              cursor: pointer;
              width: 1.1em;
              height: 1.1em;
            }

            input[type='checkbox']::before {
              border: 0.2rem solid $blue;
            }
          }

          .subcategory {
            font-size: 14px;
            cursor: pointer;
            line-height: 16px;
            position: relative;
            display: flex;
            flex-direction: row;
            gap: 0.5rem;

            &.disabled {
              color: grey;
              cursor: text;
              z-index: 0;
            }
          }
        }
      }

      .my-masonry-grid_column > div {
        margin-bottom: 30px;
      }
    }

    .show {
      display: block;
    }

    .hide {
      display: none;
    }
  }

  .btn-save {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0rem;

    @media only screen and (max-width: 768px) {
      margin: 3rem 3rem;
    }

    button {
      @include themify($themes) {
        background-color: themed('main');
      }
      color: $white;
      border: none;
      border-radius: 0.5rem;
      padding: 0.5rem 1.5rem;
      font-weight: bold;
      width: 25.2rem;
      height: 4.7rem;
      font-size: 1.4rem;
      outline: none;
      transition: all 0.3s ease-in-out;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
      }

      .title-btn-save {
        width: 100%;
      }

      .extra-small {
        width: 2rem;

        circle {
          stroke: $white;
        }
      }
    }
  }
}
