@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.battery-wrapper {
  max-width: 125.8rem;
  margin: 0 auto;
  padding: 2rem 3.4rem;
  position: relative;
  min-height: 30vh;

  // @media screen and (max-width: 768px){

  // }

  .battery {
    padding: 2rem 0;
    display: flex;

    @media screen and (max-width: 1160px) {
      justify-content: space-between;
    }

    @media screen and (max-width: 990px) {
      flex-direction: column;
    }

    border-bottom: 1px solid $grey-1;

    .gallery {
      padding-right: 3rem;

      @media screen and (max-width: 1160px) {
        padding-right: 2.5rem;
      }
    }

    .details {
      @media screen and (max-width: 990px) {
        margin: 0 auto;
        padding: 2rem;
      }

      flex-grow: 1;

      @media screen and (max-width: 1160px) {
        padding-right: 2rem;
      }
    }

    .options {
      margin-bottom: 8rem;

      @media screen and (max-width: 990px) {
        margin: 0 auto;
        padding: 2rem;
      }

      flex-grow: 1;

      @media screen and (max-width: 1160px) {
        padding-right: 2rem;
      }
    }
  }
}