@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.checkbox-cell-wrapper-tab {
  input[type='checkbox'] {
    @include themify($themes) {
      color: themed('main');
    }
    cursor: pointer;
    width: 1.1em;
    height: 1.1em;
  }

  input[type='checkbox']::before {
    border: 0.2rem solid $blue;
  }
}
