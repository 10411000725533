@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.delete-confirmation-modal-item {
  width: 36rem;
  @include themify($themes) {
    color: themed('main');
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100vh;
    padding: 2rem;
    min-width: auto;
    margin-top: 20vh;
    box-sizing: border-box;
  }

  @media screen and (max-width: 990px) and (min-width: 768px) {
    width: 80%;
    height: 100vh;
    padding: 2rem;
    min-width: auto;
    margin-top: 15vh;
    box-sizing: border-box;
    display: flex;
    margin-left: 15%;
  }

  .content {
    .message {
      font-size: 1.6rem;
      line-height: 2.1rem;
      margin-bottom: 3.2rem;

      span {
        font-weight: bold;
      }
    }

    .actions {
      width: 100%;
      display: flex;
      gap: 1.5rem;

      .no {
        width: 35%;
      }

      .yes {
        width: 65%;
      }

      button {
        border-radius: 0.5rem;
        @include themify($themes) {
          background-color: themed('secondary');
        }
        border: 0.1rem solid $blue-text-color;
        @include themify($themes) {
          color: themed('main');
        }

        &:hover {
          @include themify($themes) {
            color: themed('main');
          }
          @include themify($themes) {
    color: themed('secondary');
}
          .spinner svg circle {
            @include themify($themes) {
    stroke: themed('secondary');
}
          }
        }

        .spinner svg circle {
          stroke: $blue-text-color;
          &:hover {
            @include themify($themes) {
    stroke: themed('secondary');
}
          }
        }
      }
    }
  }
}
