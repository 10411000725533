@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.sidebarWrapper {
  max-width: 300px;
  height: 100vh;
  background: $blue-9;
  position: absolute;
  top: 0;
  left: 0;
  animation-duration: 0.4s;
  animation-name: FloatingLeft;
  animation-timing-function: ease;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
    width: 100%;
    z-index: 40;
  }

  .closeSidebarWrapper {
    width: 2rem;
    text-align: center;
    right: 1rem;
    position: absolute;
    top: 0.5rem;
    font-size: 16px;

    @include themify($themes) {
      color: themed('main');
    }

    cursor: pointer;
  }
}

// .title- {
//   font-size: 28px;
//   font-weight: 700;
//   max-width: 300px;
//   padding: 0 10px;
// }

.listItem {
  gap: 10px;
  font-weight: 700;
  padding: 0 10px;
  cursor: pointer;
}

.detailsWrapper {
  @include themify($themes) {
    color: themed('main');
  }

  min-width: 50vw;
  height: 100vh;
  background: $blue-9;
  position: absolute;
  animation-duration: 0.4s;
  animation-name: FloatingLeft;
  animation-timing-function: ease;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  .header {
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding: 10px;

    .icon {
      font-size: 24px;
      cursor: pointer;
    }

    .returnButton {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .content {
    padding: 50px;

    .items {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .item {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .title {
          padding: 0;
          font-size: 20px;
          font-weight: 700;
        }

        .item {
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
  }
}

@keyframes FloatingLeft {
  0% {
    left: -256px;
  }

  100% {
    left: 0px;
  }
}