@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';



.supplier-table-container {
  position: relative;
  min-height: 40rem;
  width: 100%;
  min-width: 60rem;

  @media screen and (max-width: 768px) {
    min-width: unset;
    min-height: 10rem;
  }

  @media screen and (max-width: 990px) and (min-width: 768px) {
    min-height: 30rem;
  }

  .wrapper {
    table-layout: auto;
    width: 100%;
    background: $white-6;
    border-spacing: 0 0.8rem;
    padding: 1.25rem;

    .headings {
      .row {
        .heading {
          @include themify($themes) {
            color: themed('main');
          }

          text-align: left;
          font-weight: 300;
          font-size: 1.2rem;
        }
      }
    }

    .body {
      .supplier-row {
        font-size: 0.75rem;
        width: 100%;

        .cell {
          border-top: 1px solid $blue-9;
          padding: 8px 0px;
          font-size: 1.2rem;
          line-height: 1.7rem;
          background: $white-6;
          text-align: left;


          @include themify($themes) {
            color: themed('main');
          }

          height: 30px;
          max-width: min-content;
        }
      }
    }
  }
}