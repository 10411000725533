@import 'src/assets/sass/themes';

.table-wrapper {
  table {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2rem;
    line-height: 1.7rem;
    text-align: left;
    letter-spacing: normal;

    @include themify($themes) {
      color: themed('main');
      background: themed('secondary-2');
    }

    width: 100%;
    border-spacing: 0 0.7rem;
    padding: 0.4rem 2.5rem;
    border-radius: 1rem;

    .comment {
      width: 1.25rem;
      height: 1.25rem;

      @include themify($themes) {
        fill: themed('main');
      }
    }

    .headings {
      .row {
        .heading {
          padding: 0.4rem 0.4rem 0.4rem 1.4rem;

          .icons {
            .fa-caret-down {
              margin-top: -0.5rem;
            }
          }
        }
      }
    }

    .body {
      .row {
        @include themify($themes) {
          background-color: themed('secondary-3');
        }

        .cell {
          padding: 0.85rem 0.4rem 0.85rem 1.4rem;

          &:first-child {
            border-radius: 0.5rem 0 0 0.5rem;
          }

          &:last-of-type {
            border-radius: 0 0.5rem 0.5rem 0;
            background-color: $blue-5;
          }
        }
      }
    }
  }
}