@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.car-parts-detail-wrapper {
  padding: 0 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .catalog-spinner {
    position: relative;
    top: 20rem;
  }

  .car-part-description-wrapper {
    width: 100%;

    .car-pats-header {
      display: flex;
      align-items: center;

      @include themify($themes) {
        color: themed('main');
      }

      font-size: 1.5rem;
      width: 100%;
      margin-bottom: 1rem;

      .return-button {
        cursor: pointer;
        white-space: nowrap;
        z-index: 1;

        svg {
          margin-right: 0.5rem;
        }

        &:hover {
          opacity: 75%;
        }
      }

      .header-breadcrumb {
        @include themify($themes) {
          color: themed('main');
        }

        width: 100%;
        font-weight: 500;
        text-align: center;
        margin-left: -6.5rem;
        font-size: 1.5rem;

        .first-group {
          font-weight: 600;
        }

        .sub-group {
          font-weight: 700;
        }

        .sub-sub-group {
          font-weight: 800;
        }
      }
    }

    .car-part-item-container {
      max-width: 125.8rem;
      min-width: 36rem;
      margin: 0 auto;
      padding: 2rem 3.4rem;
      padding-left: 0rem;
      padding-right: 0rem;
      position: relative;
      min-height: 30vh;
      margin-top: 1.9rem;
      // padding: 2rem 0;
      display: flex;

      @media screen and (max-width: 1160px) {
        justify-content: space-between;
      }

      @media screen and (max-width: 990px) {
        flex-direction: column;
      }

      .car-part-image-container {
        padding-top: 16rem;
        padding-right: 3rem;

        @media screen and (max-width: 1160px) {
          padding-right: 2.5rem;
        }
      }

      .car-part-details-container {
        @media screen and (max-width: 990px) {
          margin: 0 auto;
          padding: 2rem;
        }

        flex-grow: 1;

        @media screen and (max-width: 1160px) {
          padding-right: 5rem;
        }
      }
    }
  }
}