@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.product-category-item-new {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  .item-main-image {
    width: 8rem;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
  }

  .item-button {
    display: flex;
    @include themify($themes) {
      background-color: themed('catalogBtn', 'inactive');
      color: themed('catalogBtn', 'textInactive');
    }
    height: 4.6rem;
    min-width: 16rem;
    padding: 0.3rem 1rem 0.3rem 0.6rem;
    border-radius: 1.2rem;
    align-items: center;
    flex-direction: row;
    border-radius: 20rem;
    cursor: pointer;

    .item-icon {
      line-height: 1.6rem;
      font-size: 2rem;
      letter-spacing: 0;

      width: 4rem;
      height: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20rem;

      img {
        width: 2rem;
        height: 2rem;
      }

      @include themify($themes) {
        background-color: themed('secondary');
        svg {
          fill: themed('catalogBtn', 'icon');
        }
        span {
          svg {
            width: 2rem;
            height: 2rem;

            path {
              fill: themed('catalogBtn', 'icon');
            }
          }
        }
      }
    }

    .item-text {
      font-size: 1.3rem;
      letter-spacing: 0;
      line-height: 2rem;
      text-align: center;
      font-weight: bold;
      margin-left: 1.1rem;
    }
  }
}

.product-category-item-new .item-button:hover {
  @include themify($themes) {
    background-color: themed('catalogBtn', 'active');
    color: themed('catalogBtn', 'textActive');
    .item-icon {
      svg {
        fill: themed('catalogBtn', 'active');
      }
      span {
        svg {
          path {
            fill: themed('catalogBtn', 'active');
          }
        }
      }
    }
  }
}
