@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.actia-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  table {
    width: 100%;
    @include themify($themes) {
      background: themed('secondary');
    }
    border-spacing: 0 0.8rem;
    padding: 1.25rem;
    border-radius: 0.8rem;
    .headings {
      .row {
        .heading {
          @include themify($themes) {
            color: themed('main');
          }
          font-weight: 900;
          text-align: left;
          padding-left: 1.25rem;
          font-size: 1.2rem;
          line-height: 1.4rem;
        }
      }
    }

    .body {
      tr.row {
        font-size: 0.75rem;
        &:hover {
          opacity: initial;
          cursor: initial;
        }
        .cell {
          border: 1px solid $white-3;

          padding: 8px;
          font-size: 1.2rem;
          line-height: 1.7rem;
          @include themify($themes) {
            background-color: themed('secondary-3');
          }
          text-align: left;
          @include themify($themes) {
            color: themed('main');
          }
          height: 20px;
          max-width: min-content;

          &:last-of-type {
            border-radius: 0 5px 5px 0;
          }

          &:first-of-type {
            border-radius: 5px 0 0 5px;
          }

          .relicats-green {
            background-color: $green;
            padding: 0.2rem;
            border-radius: 50%;
            width: 1.2rem;
            color: white;
          }

          .relicats-red {
            background-color: $red;
            padding: 0.2rem;
            border-radius: 50%;
            width: 1.2rem;
            color: white;
          }
        }
      }
    }
  }
}
