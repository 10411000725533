@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.automotor-menu-navbar {
  background-color: $white;
  display: flex;
  box-sizing: border-box;
  font-size: 1.3rem;
  min-height: 3.6rem;
  flex-direction: column;

  &__list {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    list-style: none;
    background-color: $white-4;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
    box-sizing: border-box;

    &--parent {
      position: relative;
      color: $blue-1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.8rem;
      border-radius: 0.2rem;
      transition: all 0.3s;

      &:hover {
        background-color: $blue-1;

        .automotor-menu-navbar__item--parent__title {
          color: $white-3;

          svg {
            fill: $white-3;

            path {
              fill: $white-3;
            }
          }
        }

        .automotor-menu-navbar__item--parent__children {
          display: block;
          opacity: 1;
          transform: translateY(0);
        }
      }

      &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        width: max-content;
        text-decoration: none;

        & span {
          display: flex;
          align-items: center;
          gap: .4rem;
          font-size: 1.5rem;

          & svg {
            width: 1rem;
            height: 1rem;
          }
        }
      }

      &__children {
        position: absolute;
        top: 3.6rem;
        left: 0rem;
        // translate: -50%;
        width: 100%;
        background-color: $white-3;
        display: none;
        opacity: 0;
        z-index: 100;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
        padding: 1rem;
        box-sizing: border-box;
        border-radius: .2rem;
        list-style: none;
        min-width: max-content;
        cursor: default;
        transition: opacity 0.3s ease, transform 0.3s ease;

        &__item {
          padding: 0.7rem 0.5rem;
          cursor: pointer;
          transition: all 0.3s;
          box-sizing: border-box;
          border-radius: .2rem;
          font-size: 1.4rem;

          &.disabled {
            cursor: not-allowed;
            pointer-events: none;
            opacity: 0.3;
          }

          a {
            text-decoration: none;
            color: $blue-1;
          }

          &:hover {
            background-color: $blue-1;
            color: $white-3;

            a {
              text-decoration: none;
              color: $white-3 !important;
            }


            svg {
              fill: $white-3;

              path {
                fill: $white-3;
              }
            }

            .switch_slider.round {
              border: #fff 1px solid;
            }
          }

          &--title {
            display: grid;
            align-items: center;
            gap: 0.5rem;
            grid-template-columns: 1fr 10fr;
            text-decoration: none;

            &.disabled {
              cursor: not-allowed;
              pointer-events: none;
              opacity: 0.3;
            }
          }
        }

        &__separator {
          width: 100%;
          height: 1px;
          background-color: $blue-18;
          margin: .3rem 0;
        }
      }
    }

    &--child {
      position: relative;
      color: $blue-1;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      width: max-content;
      height: 100%;
      padding: .5rem;
      border-radius: .5rem;
      box-sizing: border-box;
      transition: all 0.3s;
      text-decoration: none;

      &:hover {
        background-color: $blue-1;
        color: $white-3;

        & svg {
          fill: $white-3;

          & path {
            fill: $white-3;
          }
        }
      }
    }

    svg {
      fill: $blue-1;
      width: 2rem;
      height: 2rem;
    }
  }

  a:visited {
    text-decoration: none;
    color: $blue-1;
  }

  a:focus {
    text-decoration: none;
    color: $blue-1;
  }

  a:any-link {
    text-decoration: none;
    color: $blue-1;
  }

  a:hover,
  a:active {
    text-decoration: none;
    color: $white-3;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    max-height: 14rem;
    margin: 0rem;
    gap: 1rem;
    background-color: $white;
    padding-top: 1rem;

    @media screen and (max-width: 1224px) {

      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      max-height: 100%;
    }



    .client-info {

      border-radius: 1rem;
      border: 1px solid #cfd9ee;
      max-height: 20rem;
      min-height: 10rem;
      width: 100%;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      .not-data-client {
        font-size: 1.4rem;

        @include themify($themes) {
          color: themed('main');
        }

        text-align: center;
        margin: 1rem;
      }

      & .client-title {
        font-size: 1.4rem;

        @include themify($themes) {
          color: themed('main');
        }

        font-weight: bold;
        text-align: center;
        margin-top: 0.5rem;
      }

      .financial-info-client {
        display: flex;
        flex-direction: row;
        justify-content: center;
        justify-content: space-around;

        @media screen and (max-width: 768px) {
          flex-direction: column;
        }

        .row-client {
          display: flex;
          flex-direction: column;
          padding: 0.5rem;
          text-align: center;

          @media screen and (max-width: 768px) {
            align-items: center;
          }

          .label-client {
            font-size: 1.3rem;

            @include themify($themes) {
              color: themed('main');
            }

            font-weight: bold;
            text-align: center;
          }

          .label-number {
            font-size: 1.3rem;

            @include themify($themes) {
              color: themed('main');
            }

            padding: 0.5rem;
            width: max-content;

            .label-number-year {
              display: flex;
              flex-direction: row;
              justify-content: space-around;

              .label-year {
                display: flex;
                flex-direction: column;
                padding: 0rem 0.5rem 0rem 0.5rem;
                width: max-content;
              }
            }
          }
        }

        .gap {
          gap: 1.5rem
        }
      }
    }

    .navbar-col-child {
      width: 40rem;

      @media screen and (max-width: 768px) {
        width: 100%;
        padding: 0rem 1rem;
        box-sizing: border-box;
      }
    }

    &__catalog-btn {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      gap: 1rem;
      right: 0rem;
      top: 5rem;
      width: 36rem;

      @media screen and (max-width: 768px) {
        top: 8.5rem;

      }

      @media screen and (max-width: 440px) {
        top: 8rem;

      }

      .DefaultIcon.file-button {
        background-color: #cedaee;
        color: #27509f;
      }
    }

    .floating-btn-group {
      position: absolute;
      width: auto;
      height: auto;

      @media screen and (max-width: 1440px) {


        right: 1rem;

      }

    }



  }
}