@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.suggested-maintenance {
  margin-bottom: 5rem;

  .steps-container {
    display: flex;
    flex-direction: row;
    gap: 4rem;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 2rem;
    }
  }
}

/* Responsive CSS Styles */

.app-layout-mobile,
.app-layout-tablet {
  .suggested-maintenance {
    padding: 0 2rem;
  }
}
