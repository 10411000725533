@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.add-reference-modal {
  padding: 1rem;
  box-sizing: border-box;
  width: 96rem;
  @media screen and (max-width: 990px) {
    width: 100%;
  }
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $blue-4;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    @include themify($themes) {
      color: themed('main');
    }
    &-title {
      font-size: 1.6rem;
    }
    &-close {
      font-size: 1.4rem;
      cursor: pointer;
      &:hover {
        opacity: 75%;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    &-table {
      width: 100%;
      &.is-loading {
        min-height: 5rem;
      }
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    &-btn {
      max-width: 20rem;
      width: 100%;
      & .red {
        border-radius: 0.5rem;
        @include themify($themes) {
          background-color: themed('secondary');
        }
        border: 0.1rem solid $blue-text-color;
        @include themify($themes) {
          color: themed('main');
        }

        &:hover {
          background-color: $red;
          border: 0.1rem solid $red;
          @include themify($themes) {
    color: themed('secondary');
}
        }
      }

      & .blue {
        border-radius: 0.5rem;
        @include themify($themes) {
          background-color: themed('secondary');
        }
        border: 0.1rem solid $blue-text-color;
        @include themify($themes) {
          color: themed('main');
        }

        &:hover {
          @include themify($themes) {
            color: themed('main');
          }
          @include themify($themes) {
    color: themed('secondary');
}
        }
      }
    }
  }
}
