@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.providers {
  padding: 5rem 0rem;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 132rem;
  width: 100%;
  margin: auto;

  @media screen and (max-width: 768px) {
    width: auto;
  }

  .providers-filter {
    width: 100%;
    padding-bottom: 1rem;
    margin-bottom: 3rem;
    border-bottom: 1px solid $blue-14;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 768px) {
      justify-content: center;
    }

    & .filter-btn {
      min-width: 20rem;

      .providers-filter-button {
        &.active {
          pointer-events: none;
        }
      }
    }
  }

  .providers-container {
    position: relative;
    width: 100%;

    .provider-spinner {
      align-items: flex-start;
      top: 25rem;
    }

    .provider-items {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(31.5rem, auto));
      width: 100%;
      justify-content: center;
      gap: 2rem;

      &.is-loading {
        filter: blur(0.5rem);
        pointer-events: none;
      }

      &.no-items {
        display: flex;
        grid-template-columns: none;
        justify-content: center;
        align-items: center;
      }

      & .provider-no-data {
        @include themify($themes) {
          color: themed('main');
        }

        font-size: 2rem;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
}