@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.order-clients-view-page-retailer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 2.7rem 2rem;
  position: relative;

  @media screen and (max-width: 768px) {
    padding: 0rem;
    width: 100%;
  }

  @media screen and (max-width: 990px) and (min-width: 768px) {
    padding: 0rem;
    width: 100%;
  }

  .order-clients-back {
    position: absolute;
    top: 2em;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 14px;
    cursor: pointer;
    padding: 1rem;
    @include themify($themes) {
      color: themed('main');
    }

    @media screen and (max-width: 768px) {
      position: static;
      background-color: $white;
      justify-content: left;
      border-top: 1px solid #e7e7e7;
      margin-top: 6rem;
      padding: 2rem 2rem 2rem 2rem;
      z-index: 2;
    }

    @media screen and (max-width: 990px) and (min-width: 768px) {
      position: static;
      background-color: $white;
      justify-content: left;
      border-top: 1px solid #e7e7e7;
      margin-top: 6rem;
      padding: 2rem 2rem 2rem 2rem;
      z-index: 2;
    }
  }

  .order-clients-title-view {
    display: flex;
    justify-content: center;
    font-size: 3rem;
    @include themify($themes) {
      color: themed('main');
    }

    @media screen and (max-width: 768px) {
      font-size: 2rem;
      background-color: $white;
      padding: 2rem 0rem 0rem 0rem;
      height: 12rem;
      width: 100%;
      position: absolute;
      top: 0px;
    }

    @media screen and (max-width: 990px) and (min-width: 768px) {
      font-size: 2rem;
      background-color: $white;
      padding: 2rem 0rem 0rem 0rem;
      height: 12rem;
      width: 100%;
      position: absolute;
      top: 0px;
    }
  }

  .order-info-retailer {
    @include themify($themes) {
      background: themed('secondary-2');
    }

    @media screen and (max-width: 768px) {
      min-height: 2rem;
      background-color: $white;
      padding: 2rem;
    }

    @media screen and (max-width: 990px) and (min-width: 768px) {
      min-height: 2rem;
      background-color: $white;
      padding: 2rem;
    }
  }

  hr {
    border: 1px solid $grey-5;
    width: 100%;
  }

  & .order-view-body {
    display: flex;
    gap: 2.2rem;

    @media screen and (max-width: 768px) {
      padding: 0rem;
      gap: 0rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @media screen and (max-width: 990px) and (min-width: 768px) {
      padding: 0rem;
      gap: 0rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  & .order-table {
    @include themify($themes) {
      background: themed('secondary-2');
    }
    border-radius: 1rem;
    padding: 3rem 2.5rem;
    max-width: 81.6rem;
    width: 90%;

    @media screen and (max-width: 768px) {
      padding: 0px;
      border-radius: 0rem;
    }

    @media screen and (max-width: 990px) and (min-width: 768px) {
      width: 95%;
      padding: 0px;
      border-radius: 0rem;
      max-width: 100%;
    }
  }

  & .chat {
    flex: 1;
    padding-right: 2rem;

    @media screen and (max-width: 768px) {
      padding: 0px;
      width: 100%;
    }

    @media screen and (max-width: 990px) and (min-width: 768px) {
      padding: 0px;
      width: 100%;
    }
  }
}
