@import "src/assets/sass/variables";
@import "src/assets/sass/themes";

.catalog-oil-item {
  width: 30rem;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include themify($themes) {
    background: themed("secondary");
  }

  padding-top: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  max-height: 25rem;

  .catalog-oil-item-favorite {
    align-self: flex-end;
    width: 100%;
    z-index: 2;
    position: relative;

    .favorite-button {
      position: absolute;
      right: 0;

      .iconButton {
        background: none;
      }

      svg {
        cursor: pointer;
      }

      .spinner-container {
        width: 2rem !important;
        height: 2rem !important;
        position: relative;

        .spinner {
          position: absolute;
          right: 1rem;
          top: 0rem;
          left: unset;
          background-color: inherit;
        }
      }

      button {
        svg {
          position: absolute;
          width: 2rem !important;
          height: 2rem !important;
          right: 1rem;
          top: 0rem;
          left: unset;

          @include themify($themes) {
            fill: themed("main");
          }
        }
      }
    }
  }

  .oil-header {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin-bottom: 2.2rem;

    .img-zoom-container {
      position: relative;
      display: flex;
      gap: 1rem;
      z-index: 10;

      & .img-zoom {
        position: absolute !important;
        left: 6.5rem;
        background-color: #ffffff;
        border: 1px solid #dad8d8;
        // img {
        //   width: 100% !important;
        // }
      }
    }

    .img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 6.4rem;
      height: 6.4rem;
      border: 0.1rem solid $gray;

      img {
        max-width: 6.4rem;
        max-height: 6.4rem;
        margin: auto;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      margin-left: 1rem;

      @include themify($themes) {
        color: themed("main");
      }

      .oil-category-name {
        text-align: left;
        margin-bottom: 0.3rem;
        font-size: 1.2rem;
        font-weight: 600;
      }

      .oil-title {
        text-align: left;
        margin-bottom: 0.3rem;
        font-size: 1.3rem;
        font-weight: 600;

        &:hover {
          opacity: 80%;
          cursor: pointer;
        }
      }

      .oil-description {
        text-align: left;
        margin-bottom: 0.5rem;
        font-size: 1.1rem;

        @include themify($themes) {
          color: themed("main");
        }
      }

      .oil-quantity {
        font-weight: 600;
        text-align: left;
        margin-bottom: 0.5rem;
        font-size: 1.1rem;

        @include themify($themes) {
          color: themed("main");
        }

        & span {
          text-transform: capitalize;
        }
      }
    }
  }

  .oil-body {
    margin-top: 0.8rem;
    display: flex;
    flex-direction: column;
    width: 90%;
  }

  .oil-footer {
    display: flex;
    flex-direction: row;
    border-top: 0.1rem solid $gray;
    align-items: center;
    margin-top: auto;
    width: 27.8rem;
    height: 8.85rem;
    justify-content: space-between;

    .barcode {
      width: 10.1rem;
      object-fit: contain;
      display: block;
      margin: 1rem auto 0 0;
      float: left;

      & img {
        max-width: 10.1rem;
        height: 5.2rem;
      }

      & .barcode-number {
        text-align: center;
      }
    }

    .quantity {
      width: 100%;
      flex-direction: column;
      margin: 1rem;
      display: flex;
      gap: 1rem;
    }

    .cart {
      text-align: center;
      margin-bottom: 0.2rem;
      font-size: 1.8rem;
      font-weight: 600;

      .cart-price {
        font-size: 1.8rem;

        @include themify($themes) {
          color: themed("main");
        }
      }

      .cart-button {
        display: flex;
        justify-content: flex-end;

        .cirrus-contact-us {
          white-space: nowrap;

          @include themify($themes) {
            color: themed("main");
          }

          font-size: 1.2rem;
          text-decoration: underline;
          cursor: pointer;
          min-width: 6.2rem;
          height: 1.6rem;
          margin-top: 0.2rem;

          & .extra-small {
            margin: auto;
          }

          &:hover {
            opacity: 80%;
          }
        }

        .button {
          padding: 0.3rem;
        }
      }

      .oem-price_per_unit {
        @include themify($themes) {
          color: themed("main");
        }

        font-size: 1.2rem;
        white-space: nowrap;
      }
    }
  }
}

/* Responsive CSS Styles */

.app-layout-desktop {
}

.app-layout-tablet {
}

.app-layout-tablet,
.app-layout-mobile {
  .catalog-oil-item {
  }
}

.app-layout-mobile {
  @media screen and (max-width: 480px) {
    .catalog-oil-item {
      width: 100%;

      .oil-footer {
        width: 90%;
      }
    }
  }
}
