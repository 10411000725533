@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.car-part-card {
  display: flex;
  flex-direction: column;
  background-color: #eaf0fb;
  width: 100%;
  height: 20rem;
  border-radius: 1rem;
  align-items: center;
  max-width: 24rem;
  min-width: 16rem;
  cursor: pointer;

  &:hover {
    opacity: 75%;
  }

  & .image-holder {
    background-color: $blue-14;
    width: 90%;
    height: 14rem;
    margin-top: 1.6rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    & .car-part-card-img {
      width: 100%;
      max-width: 16rem;
      max-height: 14rem;
    }
  }

  & .car-part-name {
    @include themify($themes) {
      color: themed('main');
    }
    font-size: 1.6rem;
    margin-top: 1rem;
    padding: 0 1rem;
    max-width: 15rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
