@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.vehicle-selector-tabs-container {
  width: 100%;

  & .vehicle-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.7rem;
    gap: 0.938rem;

    & .tab-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 5rem;
      height: 2.7rem;
      border-radius: 0;

      @include themify($themes) {
        color: themed('tabInactiveColor');
        background-color: themed('tabInactive');
      }

      font-size: 2rem;
      border-radius: 0.938rem;
      cursor: pointer;

      &.disabled {
        pointer-events: none;
      }

      &.active {
        @include themify($themes) {
          color: themed('tabActiveColor');
          background-color: themed('main');
        }
      }
    }
  }
}