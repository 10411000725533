@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.automotor-new-reference {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 40rem;
  position: relative;
  align-items: center;

  @media screen and (max-width: 768px) {
    margin-bottom: 2rem;
  }

  & .label {
    @include themify($themes) {
      color: themed('main');
    }
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 2.5rem;
    text-align: center;
    @include themify($themes) {
      color: themed('main');
    }
    @media screen and (max-width: 768px) {
      font-size: 1.6rem;
    }
  }

  & .input {
    width: 32rem;

    @media screen and (max-width: 768px) {
      padding: 0 2rem;
    }
  }
}
